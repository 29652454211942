export const testCasinoInfo = {
  casinoId: 5,
  casinoName: "Vulpin Casino",
  logoUrl: "https://fiat1.securebet.xyz/bot/logo/5-logo_vulpin.png",
  logoColor: "rgb(255, 193, 32)",
  buttonColor: "rgb(255, 193, 32)",
  headerColor: "#FFFFFF",
  groupId: 21,
  telegramWidget: true,
  langList: "ru,en,uz",
  langDefault: "ru",
  supportChat: "VulpinBotSupport2",
  channelUrl: "https://t.me/VulpinCasinoChannel",
  reviewUrl: "https://t.me/+wwQiO2u2rXc1NTI6",
  langLink: {
    ru: {
      supportChat: "VulpinBotSupport2",
      channelUrl: "https://t.me/VulpinCasinoChannel",
      reviewUrl: "https://t.me/+wwQiO2u2rXc1NTI6",
      chatUrl: "https://t.me/+j9GgEuoWuqhhYWM8",
      tgPostUrl: "https://t.me/VulpinCasinoChannel/350"
    },
    uz: {
      supportChat: "",
      channelUrl: "",
      reviewUrl: "",
      chatUrl: "https://t.me/+Q2U531fVFFBjMGIy",
      tgPostUrl: ""
    }
  },
  botName: "VulpinCasinoBot",
  htmlHead: "",
  htmlEndBody: "",
  excludeFromDemo: ["PGSoft", "Qilin"],
  gameCategories: [
    {
      id: 100,
      name: "3Оакс",
      providerId: 16,
      providerName: "3Oaks",
      games: [
        {
          game_id: 816,
          name: "15 Dragon Pearls",
          uuid: "6a6c1eb6155443449208f7fcfb76bc7c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/6a6c1eb6155443449208f7fcfb76bc7c.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 817,
          name: "3 Coins",
          uuid: "a7be71ce60f34c78b72bb728c6cf2e0c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/a7be71ce60f34c78b72bb728c6cf2e0c.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 818,
          name: "3 Coins: Egypt",
          uuid: "ba550520d30c47ccaef2ebf29b732024",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/ba550520d30c47ccaef2ebf29b732024.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 819,
          name: "777 Gems Respin",
          uuid: "5d0172dc92bc41ed8e3bda001f014ba8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/5d0172dc92bc41ed8e3bda001f014ba8.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 820,
          name: "Aztec Fire",
          uuid: "f7b7ff1ffdc34866aa1dfe8ce1e28572",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/f7b7ff1ffdc34866aa1dfe8ce1e28572.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 821,
          name: "Aztec Pyramid",
          uuid: "0e5e44bf96274580969635e44f8b66bd",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0e5e44bf96274580969635e44f8b66bd.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 822,
          name: "Aztec Sun",
          uuid: "9d32bc54c037484fa97ca16d90a2a1f2",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/9d32bc54c037484fa97ca16d90a2a1f2.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 823,
          name: "Big Heist",
          uuid: "cf5c8513949c496787b65f7941e16ff8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/cf5c8513949c496787b65f7941e16ff8.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 824,
          name: "Black Wolf",
          uuid: "a2a262af2f3943c781a9d8382addbfa8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/a2a262af2f3943c781a9d8382addbfa8.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 825,
          name: "Book of Sun",
          uuid: "4c951129301d437b832bc0916ff23ebf",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/4c951129301d437b832bc0916ff23ebf.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 826,
          name: "Book of Sun: Multichance",
          uuid: "a54215d0cf4c485b9ead8e8fb5752c7d",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/a54215d0cf4c485b9ead8e8fb5752c7d.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 827,
          name: "Book of Wizard",
          uuid: "e0936b843aa84e98aed5e9c83d9a14ff",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/e0936b843aa84e98aed5e9c83d9a14ff.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 828,
          name: "Boom! Boom! Gold!",
          uuid: "b3f2557a241349aebe9591727a4f3c51",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/b3f2557a241349aebe9591727a4f3c51.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 829,
          name: "Buddha Fortune",
          uuid: "f1fe950e9df041a591a5db2c07d66fa4",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/f1fe950e9df041a591a5db2c07d66fa4.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 830,
          name: "Caishen Wealth",
          uuid: "522d7563846a4b7891942148b6d8d1b0",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/522d7563846a4b7891942148b6d8d1b0.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 831,
          name: "Candy Boom",
          uuid: "8898d30571df4420af6597d06a5d4d56",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/8898d30571df4420af6597d06a5d4d56.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 832,
          name: "Dragon Pearls",
          uuid: "7d44b636437945d480d188a4679fa46b",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/7d44b636437945d480d188a4679fa46b.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 833,
          name: "Dragon Wealth",
          uuid: "6505087834d24065b577a3cf90c21279",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/6505087834d24065b577a3cf90c21279.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 834,
          name: "Eggs of Gold",
          uuid: "e6562e11fe064231880a6ad8438f818c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/e6562e11fe064231880a6ad8438f818c.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 775,
          name: "Egypt Fire",
          uuid: "3d3feb82eacc498ca981ea1fff950eb0",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/3d3feb82eacc498ca981ea1fff950eb0.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 776,
          name: "Eye of Gold",
          uuid: "fee4de8a629d44a6804bb19bbc5fe94e",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/fee4de8a629d44a6804bb19bbc5fe94e.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 777,
          name: "Fish Reef",
          uuid: "73b9bce08e1b4d29b34bb61ed9296dfd",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/73b9bce08e1b4d29b34bb61ed9296dfd.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 778,
          name: "Goddess of Egypt",
          uuid: "49f0af51a6044fbb9d1b0a041df4c4ec",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/49f0af51a6044fbb9d1b0a041df4c4ec.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 779,
          name: "Gold Express",
          uuid: "95f07b28084e4cf7a5f8cd476a5bca00",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/95f07b28084e4cf7a5f8cd476a5bca00.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 780,
          name: "Grab the Gold!",
          uuid: "720d9f49d05f451fb1436f1bef029f75",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/720d9f49d05f451fb1436f1bef029f75.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 781,
          name: "Great Panda",
          uuid: "34e4ef639f064fccb56bbf29de934d83",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/34e4ef639f064fccb56bbf29de934d83.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 782,
          name: "Green Chilli",
          uuid: "e02057240c5c49feb41f5f7f2798217a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/e02057240c5c49feb41f5f7f2798217a.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 783,
          name: "Hit more Gold!",
          uuid: "81828bcb3f8545039f16e90258356797",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/81828bcb3f8545039f16e90258356797.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 784,
          name: "Hit the Gold!",
          uuid: "9482f0a219e84a4c8b2c556742963583",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/9482f0a219e84a4c8b2c556742963583.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 786,
          name: "Lord Fortune 2",
          uuid: "e5723f3e74d743a99b04b83f90880fab",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/e5723f3e74d743a99b04b83f90880fab.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 787,
          name: "Lotus Charm",
          uuid: "6da706c8e18842cc9cffc6b11f69786c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/6da706c8e18842cc9cffc6b11f69786c.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 788,
          name: "Magic Apple",
          uuid: "0ea917de9d33420c8a5280a5fec21407",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0ea917de9d33420c8a5280a5fec21407.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 789,
          name: "Magic Apple 2",
          uuid: "a233d2cabf5441359403fdd6cdf2179a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/a233d2cabf5441359403fdd6cdf2179a.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 790,
          name: "Magic Ball: Multichance",
          uuid: "79cd0ec7ef68493ca8e0c89185ad98e1",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/79cd0ec7ef68493ca8e0c89185ad98e1.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 791,
          name: "Maya Sun",
          uuid: "ebef270054b34ad296c1ca90ac226f26",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/ebef270054b34ad296c1ca90ac226f26.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 792,
          name: "Moon Sisters",
          uuid: "93dec0dbde874bfbbcd1815eafb5aaec",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/93dec0dbde874bfbbcd1815eafb5aaec.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 793,
          name: "More Magic Apple",
          uuid: "8aa413b95f3748d38adf8fcf53abd138",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/8aa413b95f3748d38adf8fcf53abd138.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 794,
          name: "Olympian Gods",
          uuid: "2ec3ef2eb7714d128bd4fdd0541b3413",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/2ec3ef2eb7714d128bd4fdd0541b3413.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 795,
          name: "Pearl Diver",
          uuid: "af5337e3efd04aa4af4f6f5958ff293e",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/af5337e3efd04aa4af4f6f5958ff293e.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 796,
          name: "Pearl Diver 2: Treasure Chest",
          uuid: "2c989ce267864817a21745adc7e01f3a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/2c989ce267864817a21745adc7e01f3a.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 797,
          name: "Queen of the Sun",
          uuid: "0e64cfd8cb444a099f83acc9ab56fad6",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0e64cfd8cb444a099f83acc9ab56fad6.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 798,
          name: "Rio Gems",
          uuid: "83dfda22840b43d5a8ee9b862d6802af",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/83dfda22840b43d5a8ee9b862d6802af.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 799,
          name: "Scarab Boost",
          uuid: "c19dff883d8241a78937627667e35297",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/c19dff883d8241a78937627667e35297.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 800,
          name: "Scarab Riches",
          uuid: "28b6bf9585a24b5c8959b6c8dc83b16a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/28b6bf9585a24b5c8959b6c8dc83b16a.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 801,
          name: "Scarab Temple",
          uuid: "1b1f5174c8b74de189e3e269ae292da2",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/1b1f5174c8b74de189e3e269ae292da2.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 802,
          name: "Sticky Piggy",
          uuid: "1e5e042292d240fd827cee79720ddc65",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/1e5e042292d240fd827cee79720ddc65.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 803,
          name: "Sun of Egypt",
          uuid: "0e555e02213542159b153848c33b3642",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0e555e02213542159b153848c33b3642.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 804,
          name: "Sun of Egypt 2",
          uuid: "46b06ba21c4e495abae007e9589f245d",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/46b06ba21c4e495abae007e9589f245d.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 805,
          name: "Sun of Egypt 3",
          uuid: "0cdf02b8242842e8bdc8e6022b69a443",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0cdf02b8242842e8bdc8e6022b69a443.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 806,
          name: "Sunlight Princess",
          uuid: "2826c2f8830346cfb7b63da427f29345",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/2826c2f8830346cfb7b63da427f29345.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 807,
          name: "Super Marble",
          uuid: "95dba0e61df048c5a6c90303cd10e66c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/95dba0e61df048c5a6c90303cd10e66c.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 808,
          name: "Super Rich GOD",
          uuid: "0b1b5bf147fa4a83b86c09248f69fbaf",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0b1b5bf147fa4a83b86c09248f69fbaf.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 785,
          name: "The King of Heroes",
          uuid: "d05ef5b1f084494984de0ef8dad8beb0",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/d05ef5b1f084494984de0ef8dad8beb0.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 809,
          name: "Thunder of Olympus",
          uuid: "b2a65bdc62774dd7beef0341a95ca647",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/b2a65bdc62774dd7beef0341a95ca647.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 810,
          name: "Tiger Jungle",
          uuid: "3c64f6afbaae43be8ec5bb6ef21b1f6e",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/3c64f6afbaae43be8ec5bb6ef21b1f6e.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 811,
          name: "Tiger Stone",
          uuid: "bb9d4952c9d3439395f1cf81db36c0a5",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/bb9d4952c9d3439395f1cf81db36c0a5.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 812,
          name: "Tiger's Gold",
          uuid: "08ec077730e94e19a7518d03b6e9e574",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/08ec077730e94e19a7518d03b6e9e574.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 813,
          name: "Wolf Night",
          uuid: "f3ade42652f04aafa1ed2ca53ccd9d7d",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/f3ade42652f04aafa1ed2ca53ccd9d7d.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 814,
          name: "Wolf Saga",
          uuid: "d6a7fac791b8443d8a89ddba7d596dc9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/d6a7fac791b8443d8a89ddba7d596dc9.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 815,
          name: "Wukong",
          uuid: "7d06f98541734ca9b0065adbfed864e8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/7d06f98541734ca9b0065adbfed864e8.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 101,
      name: "Аматик",
      providerId: 13,
      providerName: "Amatic",
      games: [
        {
          game_id: 577,
          name: "Admiral Nelson",
          uuid: "71f8a9c71b524e34bab494ab71d89b34",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/71f8a9c71b524e34bab494ab71d89b34.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 578,
          name: "Allways Fruits",
          uuid: "66a1cdbc66ec4847835e4de699cedd60",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/66a1cdbc66ec4847835e4de699cedd60.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 580,
          name: "Bells on Fire Hot",
          uuid: "0ec8ecec1db44451866b03ec38026a15",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/0ec8ecec1db44451866b03ec38026a15.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 579,
          name: "Bells On Fire Rombo",
          uuid: "e7e603227b734916aca0a1401cfa56c7",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/e7e603227b734916aca0a1401cfa56c7.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 581,
          name: "Blue Dolphin",
          uuid: "c072cfc44de7427794dc7db6a4742cd2",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/c072cfc44de7427794dc7db6a4742cd2.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 62,
          name: "Book Of Aztec",
          uuid: "2650a7e0a78a41fbb0e3d4cdabc417b9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/2650a7e0a78a41fbb0e3d4cdabc417b9.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 63,
          name: "Book Of Fortune",
          uuid: "c6cc3d15e663467ab176bf6c9c5fa9ff",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/c6cc3d15e663467ab176bf6c9c5fa9ff.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 64,
          name: "Book of Fruits",
          uuid: "afb9bb140a24472e8583f21b26c27d97",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/afb9bb140a24472e8583f21b26c27d97.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 65,
          name: "Book of Lords",
          uuid: "8aa5dfb21a6e4b4ead9dc99846d5d687",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/8aa5dfb21a6e4b4ead9dc99846d5d687.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 66,
          name: "Book of Queen",
          uuid: "6624a0c2cbb44af3b91ec155748676d7",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/6624a0c2cbb44af3b91ec155748676d7.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 582,
          name: "Casanova",
          uuid: "72197acec4ff4065a0c67a061396f9fd",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/72197acec4ff4065a0c67a061396f9fd.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 583,
          name: "Cool Diamonds II",
          uuid: "0fd32163627a488a9285c11cf8cc09d4",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/0fd32163627a488a9285c11cf8cc09d4.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 584,
          name: "Diamond Cats",
          uuid: "8285bb67dffb4d778387918644a6f0ed",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/8285bb67dffb4d778387918644a6f0ed.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 585,
          name: "Fortune Girl",
          uuid: "218ec377832941d4927627963009d948",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/218ec377832941d4927627963009d948.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 586,
          name: "Golden Book",
          uuid: "260e1429f2aa46f8b858f0c974252bf9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/260e1429f2aa46f8b858f0c974252bf9.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 587,
          name: "Grand Casanova",
          uuid: "b3313c6105c7431bbd333f97df4a8595",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/b3313c6105c7431bbd333f97df4a8595.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 151,
          name: "Hot 7",
          uuid: "ab23ba46f3fc4a2e9bf01875e73dc121",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/ab23ba46f3fc4a2e9bf01875e73dc121.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 612,
          name: "Hot Diamonds",
          uuid: "40a4617180664b6ead7e1269dec70522",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/40a4617180664b6ead7e1269dec70522.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 154,
          name: "Hot Fruits 20",
          uuid: "fb4c8efd3e2a479987f4eea743d2accb",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/fb4c8efd3e2a479987f4eea743d2accb.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 611,
          name: "Hot Neon",
          uuid: "846f30021e20422e83cd093df6d0a775",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/846f30021e20422e83cd093df6d0a775.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 610,
          name: "Hot Scatter",
          uuid: "5898a1393d6c46228fe450ff3674d975",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/5898a1393d6c46228fe450ff3674d975.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 153,
          name: "Hot Star",
          uuid: "dba888f18231473c8670372fa28970ff",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/dba888f18231473c8670372fa28970ff.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 609,
          name: "Lovely Lady",
          uuid: "f47557c650e74187a150c7986cf9497c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/f47557c650e74187a150c7986cf9497c.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 608,
          name: "Lovely Lady Deluxe",
          uuid: "cc8dd9bdd83645b6acb83eddc38b4ccb",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/cc8dd9bdd83645b6acb83eddc38b4ccb.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 607,
          name: "Lovely Lady X-Mas",
          uuid: "b0087b4be1e74b4aa5011373bfc6ba07",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/b0087b4be1e74b4aa5011373bfc6ba07.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 606,
          name: "Lucky Coin",
          uuid: "e687bcf6766f40dbb549576e8ac3a5b9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/e687bcf6766f40dbb549576e8ac3a5b9.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 152,
          name: "Lucky Joker 5",
          uuid: "d11f4094e06f4a7787cb7d3e8acca3f6",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/d11f4094e06f4a7787cb7d3e8acca3f6.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 605,
          name: "Magic Idol",
          uuid: "1f6c126d84fe4db2ac06b2ad27a04668",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/1f6c126d84fe4db2ac06b2ad27a04668.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 604,
          name: "Merry Fruits",
          uuid: "48f5f2d8c63e42acbb1385b66bcd3420",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/48f5f2d8c63e42acbb1385b66bcd3420.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 603,
          name: "Red Chilli",
          uuid: "98165c5c75f34c5fb1b16f677cbc4196",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/98165c5c75f34c5fb1b16f677cbc4196.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 602,
          name: "Royal Unicorn",
          uuid: "e9368eb68f8b4fcd855e68b7887ae5e9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/e9368eb68f8b4fcd855e68b7887ae5e9.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 601,
          name: "Wild Dragon",
          uuid: "071cfc095cb447309b08792aeef3950a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/071cfc095cb447309b08792aeef3950a.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 102,
      name: "Аппарат",
      providerId: 59,
      providerName: "Apparat",
      games: [
        {
          game_id: 2038,
          name: "1-2-3 Wilds on Fire",
          uuid: "e66147aed56740438dc30cb2b962aa59",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/e66147aed56740438dc30cb2b962aa59.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2027,
          name: "100 Sevens",
          uuid: "183edaaad3e34390a91de43e76715542",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/183edaaad3e34390a91de43e76715542.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2013,
          name: "40 Sevens",
          uuid: "c48388f9a5a0404b8e3b3f4b1775d585",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/c48388f9a5a0404b8e3b3f4b1775d585.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2035,
          name: "40 Sevens - Buy Feature",
          uuid: "0abc13b1160842c8aa3e74b474372066",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/0abc13b1160842c8aa3e74b474372066.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2037,
          name: "5 Moon Wolf",
          uuid: "227a165877f54ad68eb97d70ca27beb5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/227a165877f54ad68eb97d70ca27beb5.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2024,
          name: "7 Supernova Fruits",
          uuid: "68653311d5814fe89ad42b5538d33cbf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/68653311d5814fe89ad42b5538d33cbf.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2039,
          name: "Atobahn Alarm",
          uuid: "f9e39222522649e1b7110b6715e54a91",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/f9e39222522649e1b7110b6715e54a91.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2032,
          name: "Banger! Firework Fruits",
          uuid: "6a09cf9d53d08761fe99fa0fb3dfc9b0f00d6368",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Apparat/6a09cf9d53d08761fe99fa0fb3dfc9b0f00d6368.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2030,
          name: "Caesar’s Legions",
          uuid: "1fc15a20f017242627e5834d20264d835e125214",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Apparat/1fc15a20f017242627e5834d20264d835e125214.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2045,
          name: "Dragonheart - The Nibelung Legends",
          uuid: "e01ad2807bc642a881138dc755d75520",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/e01ad2807bc642a881138dc755d75520.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2020,
          name: "Eggciting Fruits - Hold & Spin",
          uuid: "a74bd6c2b82a49dba3c0b703818aeb20",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/a74bd6c2b82a49dba3c0b703818aeb20.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2022,
          name: "Finest Fruits",
          uuid: "ce63c0a75dc437d2210a4f44406f64aba2152e13",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Apparat/ce63c0a75dc437d2210a4f44406f64aba2152e13.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2041,
          name: "Fishin' The Biggest",
          uuid: "61576769f9274d9c8e8e8613a18f0423",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/61576769f9274d9c8e8e8613a18f0423.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2009,
          name: "Fruit Storm",
          uuid: "14949de844144fc29a7caee49d5c239f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/14949de844144fc29a7caee49d5c239f.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2015,
          name: "Fruits First",
          uuid: "ce97b6c4ca9d47ceb789447edb140078",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/ce97b6c4ca9d47ceb789447edb140078.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2042,
          name: "Fruity Diamonds - Hold & Spin",
          uuid: "6358faa6237d437386096eff21b2837d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/6358faa6237d437386096eff21b2837d.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2036,
          name: "Garden Gnomes",
          uuid: "3348d48e1c3a4e579a552f39bf6125ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/3348d48e1c3a4e579a552f39bf6125ae.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2049,
          name: "Hold & Spin Multiplier Rush",
          uuid: "717f1f2082244d46bec2287475512a0e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/717f1f2082244d46bec2287475512a0e.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2018,
          name: "Jack Potter & The Book of Dynasties",
          uuid: "0a1cd8f3d900497ea1eff5dba2b594f4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/0a1cd8f3d900497ea1eff5dba2b594f4.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2033,
          name: "Jack Potter & The Book of Dynasties - Buy Feature",
          uuid: "9364c9f3d56a940d67b0940cb37f34365fe8e2aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Apparat/9364c9f3d56a940d67b0940cb37f34365fe8e2aa.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2021,
          name: "Jack Potter & The Book of Dynasties 6",
          uuid: "fb43a172a6103aee5dcc170eaeeef40aa3af4c2a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Apparat/fb43a172a6103aee5dcc170eaeeef40aa3af4c2a.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2044,
          name: "Jack Potter & The Book of Football",
          uuid: "f5a4cc08a1c148a98c68ffb5375615ef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/f5a4cc08a1c148a98c68ffb5375615ef.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2028,
          name: "Jack Potter & The Book of Nubia",
          uuid: "489ae85b067573a6ebb826789a3332f32f091054",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Apparat/489ae85b067573a6ebb826789a3332f32f091054.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2043,
          name: "Jack Potter & The Book of Teos",
          uuid: "f07056497adc48a4af0ecc440e943e5d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/f07056497adc48a4af0ecc440e943e5d.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2048,
          name: "Joker's Wild Crown",
          uuid: "b87e8a62f4034185975b3423d84aa84d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/b87e8a62f4034185975b3423d84aa84d.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2012,
          name: "King of the Vikings",
          uuid: "60f751ec236545ed868929213af291c1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/60f751ec236545ed868929213af291c1.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2023,
          name: "Mallorca Wilds",
          uuid: "5f80f5e04c78a021828e3cad0c7931b566eee977",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Apparat/5f80f5e04c78a021828e3cad0c7931b566eee977.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2029,
          name: "Mystic Forest",
          uuid: "293432c2e6d841ed87ce78c169f27636",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/293432c2e6d841ed87ce78c169f27636.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2026,
          name: "October Bier Frenzy",
          uuid: "c9c333a6156cf37ff3f0a1f5c965397acb0b3aec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Apparat/c9c333a6156cf37ff3f0a1f5c965397acb0b3aec.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2008,
          name: "Pharaoh Princess - Daughter of the Nile",
          uuid: "0ee3ee5e109e4ad0bb89b6d5a37d59ec",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/0ee3ee5e109e4ad0bb89b6d5a37d59ec.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2046,
          name: "Pharaoh Princess - Daughter of the Nile - Buy Feature",
          uuid: "e4c2654e9e0745bcb6baaf9ffb8f22e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/e4c2654e9e0745bcb6baaf9ffb8f22e4.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2016,
          name: "Plenty Pumpkins",
          uuid: "e70b050252bb4a3b81a0be839bf94afe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/e70b050252bb4a3b81a0be839bf94afe.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2034,
          name: "Sticky Star Fruits",
          uuid: "2417893273ec44ad9272c8e7d265d7d6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/2417893273ec44ad9272c8e7d265d7d6.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2014,
          name: "The Black Book of Pirates",
          uuid: "80395adbfd054351a3ba3ce4e4b86003",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/80395adbfd054351a3ba3ce4e4b86003.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2019,
          name: "The Griffin",
          uuid: "92aa3c163b53429fb5fc25e8cdf98d49",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/92aa3c163b53429fb5fc25e8cdf98d49.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2017,
          name: "The Guardian God Heimdall's Horn",
          uuid: "16cdc9fcf5aa409d9232ac488546dac8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/16cdc9fcf5aa409d9232ac488546dac8.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2011,
          name: "The Warlock's Book",
          uuid: "5b4ad38ee5e844cc88a1d34c5ff947bb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/5b4ad38ee5e844cc88a1d34c5ff947bb.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2047,
          name: "The Warlock's Book - Buy Feature",
          uuid: "ec269bc9a36e4af08c267e9e9cf07a1e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/ec269bc9a36e4af08c267e9e9cf07a1e.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2010,
          name: "Total Eclipse",
          uuid: "80c8217fb4524766b09ddcfec022651d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/80c8217fb4524766b09ddcfec022651d.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2040,
          name: "Total Eclipse - Buy Feature",
          uuid: "fdc8c8d423484e21a3be174648a48d4a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/fdc8c8d423484e21a3be174648a48d4a.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2031,
          name: "Total Eclipse XXL",
          uuid: "d6667ea087cb4c23b3ae6a7a4d14547f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/d6667ea087cb4c23b3ae6a7a4d14547f.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2025,
          name: "Valkyries",
          uuid: "2a6ea3df3413a744b84ad2f538d8c8b42afbb708",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Apparat/2a6ea3df3413a744b84ad2f538d8c8b42afbb708.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        }
      ]
    },
    {
      id: 103,
      name: "Аватар",
      providerId: 64,
      providerName: "AvatarUX",
      games: [
        {
          game_id: 2077,
          name: "80sPOP",
          uuid: "8eb58826e3794642b14dd3473a58ecab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/8eb58826e3794642b14dd3473a58ecab.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2081,
          name: "ArcanaPop",
          uuid: "ff7f821f874e40528c7651969dbb23ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/ff7f821f874e40528c7651969dbb23ea.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2083,
          name: "Beastly Burglars",
          uuid: "6ce0d8404d904ae48d8d30f6cbc93069",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/6ce0d8404d904ae48d8d30f6cbc93069.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2050,
          name: "BountyPop",
          uuid: "0c55c7d6b17004c38b48c00cdb1817c68e4059ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/0c55c7d6b17004c38b48c00cdb1817c68e4059ef.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2079,
          name: "Cherry Pop",
          uuid: "75c1b7a144de473d9509dcdbd492a82b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/75c1b7a144de473d9509dcdbd492a82b.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2051,
          name: "CherryPop Deluxe",
          uuid: "802517f28a6e66659a1b90db3b496b01f3e358a6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/802517f28a6e66659a1b90db3b496b01f3e358a6.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2065,
          name: "CritterPOP",
          uuid: "0f0fa2fb877a4a30b457a05bbc37f232",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/0f0fa2fb877a4a30b457a05bbc37f232.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2082,
          name: "Depths of Fortune",
          uuid: "e2513991afaf837e9baee8e8c5ae8c012c2dc232",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/e2513991afaf837e9baee8e8c5ae8c012c2dc232.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2084,
          name: "Donkey & the GOATS",
          uuid: "a1df61dc551e4480a4706c7b93c79d2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/a1df61dc551e4480a4706c7b93c79d2c.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2055,
          name: "Donkey and The GOATs",
          uuid: "9690b5eb9ed040aeaed00d5108d5ee96",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/9690b5eb9ed040aeaed00d5108d5ee96.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2080,
          name: "Floridaman",
          uuid: "990ead523489495186ee6685fd271d4f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/990ead523489495186ee6685fd271d4f.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2053,
          name: "GemPOPs",
          uuid: "b3e635472f914a0e92dd2cf88a22a71c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/b3e635472f914a0e92dd2cf88a22a71c.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2061,
          name: "HelioPOPolis",
          uuid: "2c6cd14f7cfc4d2bb1beb15d9324d5d0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/2c6cd14f7cfc4d2bb1beb15d9324d5d0.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2063,
          name: "HipHopPOP",
          uuid: "7ac2ca98d4044235b19578eb2e18107c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/7ac2ca98d4044235b19578eb2e18107c.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2071,
          name: "HippoPOP",
          uuid: "771a06750eb64583a6b354c60fae4668",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/771a06750eb64583a6b354c60fae4668.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2052,
          name: "JinglePOP",
          uuid: "b4b5747e496542d7adc8d79963ffe280",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/b4b5747e496542d7adc8d79963ffe280.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2319,
          name: "JuicyPop",
          uuid: "361ee1bc44ac41b1aa48f67b2b791b70",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/361ee1bc44ac41b1aa48f67b2b791b70.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2057,
          name: "Kitty POPpins",
          uuid: "60f0316523994526ba720c744b0ace45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/60f0316523994526ba720c744b0ace45.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2062,
          name: "KokeshiPOP",
          uuid: "cef48074c39a47ef9f98c1ad4d5c07c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/cef48074c39a47ef9f98c1ad4d5c07c6.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2068,
          name: "LolliPOP",
          uuid: "a44a06ac9c4f496a9d9ac8fdc259e77a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/a44a06ac9c4f496a9d9ac8fdc259e77a.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2060,
          name: "LooneyPOP",
          uuid: "20dbcc206f0b4a9993be05de92bd3489",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/20dbcc206f0b4a9993be05de92bd3489.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2067,
          name: "MonkeyPOP",
          uuid: "c8e0ce90955247a9b48b02834473f53f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/c8e0ce90955247a9b48b02834473f53f.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2075,
          name: "Nugget",
          uuid: "a37c5672dd7d4d6d8d31e61065e02255",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/a37c5672dd7d4d6d8d31e61065e02255.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2070,
          name: "PapayaPOP",
          uuid: "252b605e22c645c49b865130461840cd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/252b605e22c645c49b865130461840cd.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2069,
          name: "PiggyPOP",
          uuid: "b0cc1ce209554b25a0671fbdca2c2ee4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/b0cc1ce209554b25a0671fbdca2c2ee4.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2056,
          name: "PixiePOP",
          uuid: "92f9f7d3ec7147f784a7d3eff49abbbe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/92f9f7d3ec7147f784a7d3eff49abbbe.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2078,
          name: "Pop Noir",
          uuid: "f9f67ac67e274968b2dbd030ef99d0fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/f9f67ac67e274968b2dbd030ef99d0fe.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2058,
          name: "POP O'Gold",
          uuid: "0c06208a244947fba9d63a8fe1befa3b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/0c06208a244947fba9d63a8fe1befa3b.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2074,
          name: "POPRocks",
          uuid: "99c6c8ed407243b380bc35a138d1ff5a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/99c6c8ed407243b380bc35a138d1ff5a.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2066,
          name: "RagingPOP",
          uuid: "b67ccf07b4054ea68537de39c88c91b3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/b67ccf07b4054ea68537de39c88c91b3.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2059,
          name: "ReefPOP",
          uuid: "52942e4b5de74b4f82cd146c723f0d99",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/52942e4b5de74b4f82cd146c723f0d99.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2318,
          name: "Sweet Gummy",
          uuid: "0cade00ae22643ca8f530c7c2a45d1c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/0cade00ae22643ca8f530c7c2a45d1c6.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2072,
          name: "TikiPOP",
          uuid: "463e2973e6b64b598e9641b125de5a47",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/463e2973e6b64b598e9641b125de5a47.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2073,
          name: "WildPOPs",
          uuid: "4819c164e7544f779b138ddb1e1882fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/4819c164e7544f779b138ddb1e1882fe.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2054,
          name: "Witchy POPpins",
          uuid: "c0654d53da2d4774b1abcdc14a2d6569",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/c0654d53da2d4774b1abcdc14a2d6569.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2076,
          name: "Worms Of Valor",
          uuid: "319d998059b849958fa204b191448003",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/319d998059b849958fa204b191448003.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2064,
          name: "Zombie APOPlypse",
          uuid: "59108cdbc3bc4b129062dde760f31602",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/59108cdbc3bc4b129062dde760f31602.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 104,
      name: "Авиатрикс",
      providerId: 60,
      providerName: "Aviatrix",
      games: [
        {
          game_id: 942,
          name: "Aviatrix",
          uuid: "9d9b5b34389337d4e43568b4ba2d56be97de447a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Aviatrix/9d9b5b34389337d4e43568b4ba2d56be97de447a.png",
          type: "crash",
          provider: "Aviatrix",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        }
      ]
    },
    {
      id: 105,
      name: "БФГеймс",
      providerId: 47,
      providerName: "BFGames",
      games: [
        {
          game_id: 2207,
          name: "Alice In Wonderland",
          uuid: "9859b5135dc04af19fb718c721151a0f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/9859b5135dc04af19fb718c721151a0f.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2200,
          name: "Ancient Secrets",
          uuid: "d6387a11f4a046feb0e942ed7fcd960e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/d6387a11f4a046feb0e942ed7fcd960e.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2212,
          name: "Aztec Adventure",
          uuid: "cdb5acd0cbf14d27b3afc3b77d669916",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/cdb5acd0cbf14d27b3afc3b77d669916.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2189,
          name: "Best New York Food",
          uuid: "056400c69dc84af29dd0d522a630db40",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/056400c69dc84af29dd0d522a630db40.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2252,
          name: "Bonnie & Clyde",
          uuid: "10fdf1f610ed2caef394c6b05a5e2519afed084d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/10fdf1f610ed2caef394c6b05a5e2519afed084d.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2220,
          name: "Bonnie and Clyde",
          uuid: "b07964d0cf4240218a529fb5bda2caf8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/b07964d0cf4240218a529fb5bda2caf8.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2221,
          name: "Book of Gates",
          uuid: "9716af348dc34375afacd4f05c20074e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/9716af348dc34375afacd4f05c20074e.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2222,
          name: "Book of Gods",
          uuid: "515cbd3d87a3402b8a08f5a452fd64cd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/515cbd3d87a3402b8a08f5a452fd64cd.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 2233,
          name: "Book of Ming",
          uuid: "5c5c45942636492d8b6eb363babc2eeb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/5c5c45942636492d8b6eb363babc2eeb.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2275,
          name: "Book Of Wisdom",
          uuid: "92476cbcb5b54650ad4eec11cdb54d63",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/92476cbcb5b54650ad4eec11cdb54d63.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2223,
          name: "Buffalo Trail",
          uuid: "587cb56074f14f4a96e71f15c4804747",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/587cb56074f14f4a96e71f15c4804747.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2259,
          name: "Buffalo Trail Ultra",
          uuid: "a7f28421fb874b089fe4d4ae5bfc1ce7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/a7f28421fb874b089fe4d4ae5bfc1ce7.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2224,
          name: "Burning Slots",
          uuid: "0675fb19ffa0485da85237999613a165",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/0675fb19ffa0485da85237999613a165.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2272,
          name: "Burning Slots 100",
          uuid: "806f18eaeaea42cb886107e4059926f5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/806f18eaeaea42cb886107e4059926f5.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2255,
          name: "Burning Slots 20",
          uuid: "a2bda06e61ada5b76955a4003fc41bf7bd4516eb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a2bda06e61ada5b76955a4003fc41bf7bd4516eb.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2257,
          name: "Burning Slots 40",
          uuid: "75ccd1e56f4313a523d8968360c00cc6c2b4443d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/75ccd1e56f4313a523d8968360c00cc6c2b4443d.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2264,
          name: "Burning Slots Cash Mesh",
          uuid: "1e7d8841dd124c4ba106071c9f7edb6b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/1e7d8841dd124c4ba106071c9f7edb6b.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2279,
          name: "Burning Slots Cash Mesh Ultra",
          uuid: "a20c66952f7a48be9bfb133cc8a9030c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/a20c66952f7a48be9bfb133cc8a9030c.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2226,
          name: "Cave of Fortune",
          uuid: "1662c2faab6642fe8fcef4b2f46145f3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/1662c2faab6642fe8fcef4b2f46145f3.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2283,
          name: "Cave Of Gems",
          uuid: "b571c7fe12ff4542901fd250ff1da627",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/b571c7fe12ff4542901fd250ff1da627.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2256,
          name: "Cave of Gold",
          uuid: "fecbb44a41aceb3858e408025286fb57ee8fd950",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fecbb44a41aceb3858e408025286fb57ee8fd950.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2261,
          name: "Cave of Xmas",
          uuid: "e6be239db7914fdc872ca6ed9de791b3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/e6be239db7914fdc872ca6ed9de791b3.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2254,
          name: "Chicken Madness",
          uuid: "c50a22816074cc207081cf804c8d5a651621a2f5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c50a22816074cc207081cf804c8d5a651621a2f5.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2266,
          name: "Chicken Madness Ultra",
          uuid: "d2df599a10104767a03fcd5491b4acc8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/d2df599a10104767a03fcd5491b4acc8.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2196,
          name: "Cold As Ice",
          uuid: "3864324aa6954a01b933946d3e9e8588",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/3864324aa6954a01b933946d3e9e8588.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2216,
          name: "Continent Africa",
          uuid: "a61dcafaa53549b3895e453bd878c52c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/a61dcafaa53549b3895e453bd878c52c.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2229,
          name: "Cosmic Dream",
          uuid: "6013794d8f9046e4bc1fed1cf888ffc9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/6013794d8f9046e4bc1fed1cf888ffc9.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2230,
          name: "Crystal Mania",
          uuid: "87c5de77f72541d9b2eb9040e62ed054",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/87c5de77f72541d9b2eb9040e62ed054.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2227,
          name: "Dark Carnivale",
          uuid: "43b0d58d71db48efa9461349f1e9bb6c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/43b0d58d71db48efa9461349f1e9bb6c.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2267,
          name: "Disco Lights",
          uuid: "70389acc370e48e8848caf3e41ab9121",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/70389acc370e48e8848caf3e41ab9121.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2265,
          name: "Dragon's Chance",
          uuid: "54fd476bca37422ab0970123d2b5cb1f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/54fd476bca37422ab0970123d2b5cb1f.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2217,
          name: "Dragons Power",
          uuid: "bac65a71c70e4925a7d139b08f27cf28",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/bac65a71c70e4925a7d139b08f27cf28.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2276,
          name: "El Dorado Totems",
          uuid: "c3ba6d2523bd4645acc4c8f17ef76559",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/c3ba6d2523bd4645acc4c8f17ef76559.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2282,
          name: "El Dorado Totems Dice",
          uuid: "1a45e1effb684cf1ba455a4de60594fe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/1a45e1effb684cf1ba455a4de60594fe.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2236,
          name: "Energy Fruits",
          uuid: "30b823e805c545fb9385ad0096b8cd59",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/30b823e805c545fb9385ad0096b8cd59.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2234,
          name: "Energy Stars",
          uuid: "f8d9afb0cbc74c2c93fa79cbac1acd48",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/f8d9afb0cbc74c2c93fa79cbac1acd48.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2237,
          name: "Fibonacci",
          uuid: "506ad1c25ef94116a1ac88ae7ea8fedc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/506ad1c25ef94116a1ac88ae7ea8fedc.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2268,
          name: "Fiery Slots",
          uuid: "b98a1cef147f4d02a7dd687d2a7fe9f9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/b98a1cef147f4d02a7dd687d2a7fe9f9.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2273,
          name: "Fiery Slots Cash Mesh Ultra",
          uuid: "9e5402463a554174be28ff63c317fdf2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/9e5402463a554174be28ff63c317fdf2.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2218,
          name: "Fresh Fortune",
          uuid: "ba57b85c90984720b214c37a9d20ee45",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/ba57b85c90984720b214c37a9d20ee45.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2277,
          name: "Frozen Yeti",
          uuid: "8967fb3df56047b89ced59eff4aaa2c8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/8967fb3df56047b89ced59eff4aaa2c8.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2190,
          name: "Fruitastic",
          uuid: "da2ab445432b4e54bf54707d38d72703",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/da2ab445432b4e54bf54707d38d72703.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2206,
          name: "Golden Chance",
          uuid: "12fe4824b51948ceb65a18d9e000d105",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/12fe4824b51948ceb65a18d9e000d105.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2193,
          name: "Golden Lamp",
          uuid: "eae501bca9ed473c89d2671fba53efa6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/eae501bca9ed473c89d2671fba53efa6.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2188,
          name: "Golden New World",
          uuid: "735bff2934104e76a79ef918b7a93a93",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/735bff2934104e76a79ef918b7a93a93.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2320,
          name: "Golem Wildwoods",
          uuid: "93534b8daf0a4bcaa946e78e3aa52e0c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/93534b8daf0a4bcaa946e78e3aa52e0c.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2269,
          name: "Horizon Hunters",
          uuid: "da4686d24f884087b290d9fec726337c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/da4686d24f884087b290d9fec726337c.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2238,
          name: "Hot Classic",
          uuid: "c820b8842ae14865b9644a700a07632e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/c820b8842ae14865b9644a700a07632e.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2239,
          name: "Hot Sunrise",
          uuid: "dafc8ca8b4504df4979ff7b39e6ce4c7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/dafc8ca8b4504df4979ff7b39e6ce4c7.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2214,
          name: "Jazz Spin",
          uuid: "aecdd2c5acbd47ceb0d401e055993e54",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/aecdd2c5acbd47ceb0d401e055993e54.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2199,
          name: "Jewels World",
          uuid: "12e2251b61a741f9a2c25fd90faefa23",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/12e2251b61a741f9a2c25fd90faefa23.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2225,
          name: "Joker Spin",
          uuid: "0546e85d589142bea2a4a2159120c2ea",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/0546e85d589142bea2a4a2159120c2ea.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2208,
          name: "Koi Kingdom",
          uuid: "c16c3cd577fc4304a2fbca989d480bbb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/c16c3cd577fc4304a2fbca989d480bbb.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2271,
          name: "Legacy of Tombs",
          uuid: "dbcc36e72a862d921f348b00e45f4cbb8fcbbe70",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/BFGames/dbcc36e72a862d921f348b00e45f4cbb8fcbbe70.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2240,
          name: "Lucky Symbols",
          uuid: "76e6f94869444cb588376ad1491a98dd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/76e6f94869444cb588376ad1491a98dd.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2260,
          name: "Lucky Ticket 81",
          uuid: "f29d7d78340e941120a14caef4de155c43414608",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/BFGames/f29d7d78340e941120a14caef4de155c43414608.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2241,
          name: "Lucky Tropics",
          uuid: "09f0034f46a145f6aabc1f3407e0efde",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/09f0034f46a145f6aabc1f3407e0efde.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2215,
          name: "Mad Men",
          uuid: "0390e2e2dc214f8b805d7c82da29d992",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/0390e2e2dc214f8b805d7c82da29d992.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2192,
          name: "Magic Hunter",
          uuid: "56aa69f874ac477bb2f6424bcbcda28c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/56aa69f874ac477bb2f6424bcbcda28c.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2213,
          name: "Magic Queens",
          uuid: "66ebe691cdf54364804f0148e1e21d24",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/66ebe691cdf54364804f0148e1e21d24.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2274,
          name: "Megacity",
          uuid: "2502e7d40d2579ea03fad79fe8aaaa4c1f7c819c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/BFGames/2502e7d40d2579ea03fad79fe8aaaa4c1f7c819c.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2280,
          name: "Megacity Football Fever",
          uuid: "8a4436c068c14d89a27ce5f941d968b7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/8a4436c068c14d89a27ce5f941d968b7.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2235,
          name: "Ocean Reef",
          uuid: "bcc82441f5934f31a4c23359cb970df2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/bcc82441f5934f31a4c23359cb970df2.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2194,
          name: "Pagoda of Fortune",
          uuid: "740a2514ad6042d4bf7f7f7dddf29fa1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/740a2514ad6042d4bf7f7f7dddf29fa1.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2202,
          name: "Pharaohs And Aliens",
          uuid: "0656f2b188524e659b7b60ebaff6e03b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/0656f2b188524e659b7b60ebaff6e03b.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2201,
          name: "Pyramid Treasure",
          uuid: "c402dc461e5447068ee0014069c51db2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/c402dc461e5447068ee0014069c51db2.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2278,
          name: "Raiders of the north",
          uuid: "906b2ebf2637474dbb729a6a917ebcd8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/906b2ebf2637474dbb729a6a917ebcd8.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2242,
          name: "Ramses Rising",
          uuid: "5e00373709924858831d4de22506f381",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/5e00373709924858831d4de22506f381.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2198,
          name: "Rich Castle",
          uuid: "988fa9b8c3314fab901762be4d1cae7b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/988fa9b8c3314fab901762be4d1cae7b.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2232,
          name: "Riches From the Deep",
          uuid: "c6dd4e180c3745f2b0aaaa4a04764de4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/c6dd4e180c3745f2b0aaaa4a04764de4.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2284,
          name: "Road 2 Cash",
          uuid: "274362b2d50040ff8f3e9121c55ea6d9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/274362b2d50040ff8f3e9121c55ea6d9.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2228,
          name: "Rome Warrior",
          uuid: "cba17a46da69410caa23ba0ed283d028",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/cba17a46da69410caa23ba0ed283d028.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2191,
          name: "Royal Win",
          uuid: "3fe037fe87a942b683390bab1b728a93",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/3fe037fe87a942b683390bab1b728a93.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2243,
          name: "Royal Win Remastered",
          uuid: "d35c84afef2a45ea9c1365d973f73eae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/d35c84afef2a45ea9c1365d973f73eae.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2253,
          name: "Squid from the Deep",
          uuid: "06b2e9ef6ba92fef9eee4c54d12d1e0f5d344707",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/06b2e9ef6ba92fef9eee4c54d12d1e0f5d344707.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2211,
          name: "Star Fortune",
          uuid: "ed2c6a23c4ed446d917853cfa69c2ca3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/ed2c6a23c4ed446d917853cfa69c2ca3.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2244,
          name: "Star Settler",
          uuid: "88e6eb06f2e444b2a18ea355129f3928",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/88e6eb06f2e444b2a18ea355129f3928.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2231,
          name: "Steampunk Big City",
          uuid: "6873c86b265b46bc9ad4e16d1921854b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/6873c86b265b46bc9ad4e16d1921854b.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2203,
          name: "Stunning 27",
          uuid: "44e7eee1293043f58c2bc444e00a3bc9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/44e7eee1293043f58c2bc444e00a3bc9.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2245,
          name: "Stunning 27 Remastered",
          uuid: "55107db5ae1d4b3892bb35429f70ceba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/55107db5ae1d4b3892bb35429f70ceba.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2258,
          name: "Stunning Cash",
          uuid: "2417c8520c9de57c4d23342888938031fd0d3421",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2417c8520c9de57c4d23342888938031fd0d3421.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2262,
          name: "Stunning Cash Ultra",
          uuid: "6bd14180056842e98793dff63a283e14",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/6bd14180056842e98793dff63a283e14.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2270,
          name: "Stunning Crown",
          uuid: "fce12b758354489780e87af5169c0012",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/fce12b758354489780e87af5169c0012.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2281,
          name: "Stunning Crown Cash Mesh",
          uuid: "3101fea4c6f4426a98c48e2ac3631197",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/3101fea4c6f4426a98c48e2ac3631197.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2246,
          name: "Stunning Cube",
          uuid: "31a13cdade1040bbb2c738dc740a4906",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/31a13cdade1040bbb2c738dc740a4906.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2210,
          name: "Stunning Hot",
          uuid: "e4308575544f4a7ab7d576b490f7698d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/e4308575544f4a7ab7d576b490f7698d.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2205,
          name: "Stunning Hot 20 Deluxe",
          uuid: "b4c672b3816d4506b577cfb492f4b7f0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/b4c672b3816d4506b577cfb492f4b7f0.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2247,
          name: "Stunning Hot 20 Deluxe Remastered",
          uuid: "d1a7f6aa32514437b9aee98e4d76e004",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/d1a7f6aa32514437b9aee98e4d76e004.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2248,
          name: "Stunning Hot Remastered",
          uuid: "ee45fa342a7c45f5a0948fe8714c257b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/ee45fa342a7c45f5a0948fe8714c257b.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2204,
          name: "Stunning Snow",
          uuid: "a7db3fe63d5f44d69da1d2f800843912",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/a7db3fe63d5f44d69da1d2f800843912.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2249,
          name: "Stunning Snow Remastered",
          uuid: "514cb425235a4f80b1805f377a730f84",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/514cb425235a4f80b1805f377a730f84.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2263,
          name: "Sweet Reward",
          uuid: "9394aaf73a574d378a966a20f1cea059",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/9394aaf73a574d378a966a20f1cea059.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2250,
          name: "Taste of China",
          uuid: "f598d4dc00dc4846a0b0f1c883f81723",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/f598d4dc00dc4846a0b0f1c883f81723.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2209,
          name: "Tequila Fiesta",
          uuid: "9acc3c513806415f8841d51acb95bad3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/9acc3c513806415f8841d51acb95bad3.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2197,
          name: "Transylvanian Beauty",
          uuid: "10b20fc4902841208cd7431fb01ba118",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/10b20fc4902841208cd7431fb01ba118.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2195,
          name: "Voodoo Candy Shop",
          uuid: "06ab6d1484cd4b5f9bd6ad411f019768",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/06ab6d1484cd4b5f9bd6ad411f019768.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2219,
          name: "Wild Jack",
          uuid: "64603b6758044b5fb0fa5ce882dbfe2d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/64603b6758044b5fb0fa5ce882dbfe2d.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2251,
          name: "Wild Jack Remastered",
          uuid: "571ce592ece14afe90128ad29f98ad35",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/571ce592ece14afe90128ad29f98ad35.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 106,
      name: "Белатра Геймс",
      providerId: 32,
      providerName: "Belatra Games",
      games: [
        {
          game_id: 2117,
          name: "#buybonus New York",
          uuid: "954eb970119c67043bd42b6a8d7c9b6534a352e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/954eb970119c67043bd42b6a8d7c9b6534a352e4.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2122,
          name: "#buyBonus of Maya",
          uuid: "5d1a91552da35f331a479e58bcae9f4e95b94ba4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5d1a91552da35f331a479e58bcae9f4e95b94ba4.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2121,
          name: "20 Super Stars",
          uuid: "24d49b27c4532e470cf9b65180ca41b53d78aaba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/24d49b27c4532e470cf9b65180ca41b53d78aaba.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2163,
          name: "3x Super Peppers",
          uuid: "3312b16a818f4cca8e273609423a0f77",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/3312b16a818f4cca8e273609423a0f77.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2173,
          name: "4 Secrets of Aladdin",
          uuid: "edcee9cbc66246279035e5ecfc269d60",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/edcee9cbc66246279035e5ecfc269d60.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2157,
          name: "5 Wild Wild Peppers",
          uuid: "68f02a5469a492976b7ad4dfe9d691cc013335a6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/68f02a5469a492976b7ad4dfe9d691cc013335a6.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2142,
          name: "500 Juicy Fruits",
          uuid: "1e4b73265abdc819e7d9d1cd32e5c47408f89af7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1e4b73265abdc819e7d9d1cd32e5c47408f89af7.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2085,
          name: "7 Days Anotherland",
          uuid: "a5ac7ae965dbd19a6f7d9e0254452c1d0ae095e8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a5ac7ae965dbd19a6f7d9e0254452c1d0ae095e8.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2086,
          name: "7 Days Spanish armada",
          uuid: "b824d322e406783d95b0532f2b92de4f6aac338b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b824d322e406783d95b0532f2b92de4f6aac338b.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2123,
          name: "7 Fruits",
          uuid: "16e052082e546654d9c21cf6c00c9644da9b650e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/16e052082e546654d9c21cf6c00c9644da9b650e.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 2087,
          name: "88 Bingo 88",
          uuid: "6638a5399b4f641e0e1371fd329ab6cafbb66ee8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6638a5399b4f641e0e1371fd329ab6cafbb66ee8.png",
          type: "bingo",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2127,
          name: "88 Dragons Bounty",
          uuid: "f557c69de35e7169d58fc93fa0750b959ac0de02",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f557c69de35e7169d58fc93fa0750b959ac0de02.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2088,
          name: "88 Golden 88",
          uuid: "978d1205293b05e213506f406c446ae88d4a3b2f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/978d1205293b05e213506f406c446ae88d4a3b2f.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2089,
          name: "Africa",
          uuid: "9012e270ff235b872ca65a94110d3680ae447497",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9012e270ff235b872ca65a94110d3680ae447497.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2138,
          name: "Africa Gold 2",
          uuid: "26ee376a574865e9e0ce0ccbcd7cd29d194fa884",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/26ee376a574865e9e0ce0ccbcd7cd29d194fa884.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2090,
          name: "American Roulette",
          uuid: "29e6dcde1cd11b432106af0937cb9e4e558b8689",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/29e6dcde1cd11b432106af0937cb9e4e558b8689.png",
          type: "roulette",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2159,
          name: "Ancient Temple Gems",
          uuid: "064df1d6e3127bc05bd65d53e976290f1731723c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/064df1d6e3127bc05bd65d53e976290f1731723c.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2115,
          name: "Bank Robbers",
          uuid: "08bd4ac09d11fa60bbde9fdbdecfee8d1ef934f2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/08bd4ac09d11fa60bbde9fdbdecfee8d1ef934f2.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2111,
          name: "Beauty and the Beast",
          uuid: "dad6d9f3d14f107d611197ee7c205c6b1df4ffee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dad6d9f3d14f107d611197ee7c205c6b1df4ffee.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2162,
          name: "Big Bang",
          uuid: "26d15bb3e79d4598840366a9ec745b42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/26d15bb3e79d4598840366a9ec745b42.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2158,
          name: "Big Wild Buffalo",
          uuid: "c25cf91e087c2212d265f49723cb2ad8324fba08",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/c25cf91e087c2212d265f49723cb2ad8324fba08.jpeg",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2169,
          name: "Bingo Power",
          uuid: "a9c04237f35ddc51db40893e6a7ccd8e18de730d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/a9c04237f35ddc51db40893e6a7ccd8e18de730d.png",
          type: "bingo",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2116,
          name: "Bingo Soccer",
          uuid: "be9e4cb4e2d12f58599a569e2aabf09546ca5a4e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/be9e4cb4e2d12f58599a569e2aabf09546ca5a4e.png",
          type: "bingo",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2091,
          name: "BlueBeard",
          uuid: "ba5934a5acffb1e56823fc35f5646c9f7bf43062",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ba5934a5acffb1e56823fc35f5646c9f7bf43062.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2126,
          name: "Book of Doom",
          uuid: "8e8eef6c0adbbea86219219209715533190c048b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8e8eef6c0adbbea86219219209715533190c048b.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2092,
          name: "Carousel",
          uuid: "d4b51cf9b249a53f686a941b2575b57872d6ef30",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d4b51cf9b249a53f686a941b2575b57872d6ef30.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2152,
          name: "Catch & Snatch",
          uuid: "7c6c04a5d624cba0f78aa15b0b151724cbb4e530",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7c6c04a5d624cba0f78aa15b0b151724cbb4e530.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2118,
          name: "Chicago Bang, Bang",
          uuid: "4b5d0766065277626fb60b5d21230b3afe3b67d4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4b5d0766065277626fb60b5d21230b3afe3b67d4.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2182,
          name: "Chief's Sticky Fruits",
          uuid: "0bac989ec23742438198ff6ea553c240",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/0bac989ec23742438198ff6ea553c240.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2133,
          name: "Christmas Jackpot",
          uuid: "8c176a5073ad091582348532db14db7932c05e80",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8c176a5073ad091582348532db14db7932c05e80.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2160,
          name: "Cleo’s Book",
          uuid: "3c6951924ce42f10fe1f9c35ecfbbc79bbb9fe72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/3c6951924ce42f10fe1f9c35ecfbbc79bbb9fe72.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2144,
          name: "Crystals Digger",
          uuid: "7ed9e079ddb755e89be7bf4544c341ed1cb8c3fa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7ed9e079ddb755e89be7bf4544c341ed1cb8c3fa.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2093,
          name: "Dracula Riches",
          uuid: "daa8f8158923131fc6477910e320da85f47388e9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/daa8f8158923131fc6477910e320da85f47388e9.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2171,
          name: "Dragon's Bonanza",
          uuid: "714ca4aa6a5a444b979c52b8d37cad53",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/714ca4aa6a5a444b979c52b8d37cad53.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2094,
          name: "Elusive Gonzales",
          uuid: "0782dd2aba4877b3cfa0acd8a18ba9df5386bb92",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0782dd2aba4877b3cfa0acd8a18ba9df5386bb92.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2095,
          name: "Escape from Alcatraz",
          uuid: "8a489bee21fec0a0e4ec0780497a0893bb284ebe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8a489bee21fec0a0e4ec0780497a0893bb284ebe.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2096,
          name: "European Roulette",
          uuid: "5f60f72027f3650108907d92c55702953674befd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5f60f72027f3650108907d92c55702953674befd.png",
          type: "roulette",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2141,
          name: "Fortune Craft",
          uuid: "d45c4024bebe00c9f8fb7b3241a814d888ec591e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d45c4024bebe00c9f8fb7b3241a814d888ec591e.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2097,
          name: "Frog Creek",
          uuid: "237790e673f12ea69307f47f2d760d33ae3f2840",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/237790e673f12ea69307f47f2d760d33ae3f2840.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2170,
          name: "Full Moon Magic",
          uuid: "01786d69e7ac2f1d3aa94721a1a15c5fb8f2d0a2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/01786d69e7ac2f1d3aa94721a1a15c5fb8f2d0a2.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2177,
          name: "Golden Lantern",
          uuid: "ada348f865ab40aca01ad8755e69691a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/ada348f865ab40aca01ad8755e69691a.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2112,
          name: "Golden Lemon",
          uuid: "a246af089903bfcf7de8c64d051905deb7b6a1fc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a246af089903bfcf7de8c64d051905deb7b6a1fc.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2120,
          name: "Golden Lemon DeLuxe",
          uuid: "96cd2750a0b5b3d8269a425d10dbabe061d340f0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/96cd2750a0b5b3d8269a425d10dbabe061d340f0.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2183,
          name: "Golden Plinko",
          uuid: "55de7404dfef46cea7b8257f6d3a326c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/55de7404dfef46cea7b8257f6d3a326c.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2181,
          name: "Golden øks",
          uuid: "d5d6fd2c22244b6c85ef663e00694527",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/d5d6fd2c22244b6c85ef663e00694527.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2098,
          name: "Greengrocery",
          uuid: "a26ab59aebefc750a66ae516502687650233f5fa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a26ab59aebefc750a66ae516502687650233f5fa.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2099,
          name: "Halloween",
          uuid: "c98981d6588fc3b83543e890320263a0573c82d6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c98981d6588fc3b83543e890320263a0573c82d6.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2172,
          name: "Halloween Bingo",
          uuid: "35daedc87a9ad8b26a3d3ba59138fceb2171503f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/35daedc87a9ad8b26a3d3ba59138fceb2171503f.png",
          type: "bingo",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2146,
          name: "Halloween Crystals",
          uuid: "2159250c4a524576559d57d15ffc9789d2e64787",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2159250c4a524576559d57d15ffc9789d2e64787.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2128,
          name: "Halloween Jackpot",
          uuid: "c34a7c3a1c0c034c0d99be6a41296f4203627920",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c34a7c3a1c0c034c0d99be6a41296f4203627920.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2151,
          name: "Hot Wild Pepper",
          uuid: "e4f26af7f461ad0c63f2112eae3cd6758acf7b97",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e4f26af7f461ad0c63f2112eae3cd6758acf7b97.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2175,
          name: "Ice Princess",
          uuid: "e7949c528ba8f465bb085aa6271c5c5f5ed425b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/e7949c528ba8f465bb085aa6271c5c5f5ed425b8.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2132,
          name: "Icy Fruits",
          uuid: "9ce06fbe78e5db25cccadda295de3b961cf3041c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9ce06fbe78e5db25cccadda295de3b961cf3041c.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2154,
          name: "Irish Thunder",
          uuid: "d15277499684b27b637a4d9fc67274def068efe3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d15277499684b27b637a4d9fc67274def068efe3.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2114,
          name: "J.Monsters",
          uuid: "943514a2ec488d635870bab3833f9e5daaa6fe10",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/943514a2ec488d635870bab3833f9e5daaa6fe10.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2180,
          name: "Jackpot Mines",
          uuid: "1cf5635bd6474cc2b82aa76edac6eb9b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/1cf5635bd6474cc2b82aa76edac6eb9b.png",
          type: "instant win",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2100,
          name: "Jewels",
          uuid: "ced11e28c52f42f8e895637d313eb7551bd6f3bf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ced11e28c52f42f8e895637d313eb7551bd6f3bf.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2101,
          name: "Just A Bingo",
          uuid: "79fa40baa209370530a06338143ef5fa492c96f0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/79fa40baa209370530a06338143ef5fa492c96f0.png",
          type: "bingo",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2135,
          name: "King of Jumping Scratch",
          uuid: "bc3a08d83f402e1331ebff9ebd6c9e7c871a9cd4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/bc3a08d83f402e1331ebff9ebd6c9e7c871a9cd4.jpeg",
          type: "scratch card",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2131,
          name: "Lavish Joker",
          uuid: "53e6e44f27508a18015196d80c3507e4d974db65",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/53e6e44f27508a18015196d80c3507e4d974db65.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2134,
          name: "Lazy Monkey",
          uuid: "463fe383578fb6992c877f8607ea3b582c79c911",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/463fe383578fb6992c877f8607ea3b582c79c911.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2153,
          name: "Legacy of Doom",
          uuid: "dd7f67fdeed34a8292ea6b149674183070629029",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dd7f67fdeed34a8292ea6b149674183070629029.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2102,
          name: "Love Magic",
          uuid: "76c36fd0a78f9c7536eb44986fe1420841c77341",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/76c36fd0a78f9c7536eb44986fe1420841c77341.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2145,
          name: "Lucie's cats",
          uuid: "990df649cf6cd63d381531919ae8da6e9f30f98d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/990df649cf6cd63d381531919ae8da6e9f30f98d.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2321,
          name: "Lucky Bandits",
          uuid: "7cbcaae5b4304d6991dda01eb655f282",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/7cbcaae5b4304d6991dda01eb655f282.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2185,
          name: "Lucky Barrel Tavern",
          uuid: "09880fff05a24f7aad26570c7a0c6a6c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/09880fff05a24f7aad26570c7a0c6a6c.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2103,
          name: "Lucky Drink",
          uuid: "bed28c76ee188ddfca99796e441a76337aedbf4e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bed28c76ee188ddfca99796e441a76337aedbf4e.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2104,
          name: "Lucky Drink In Egypt",
          uuid: "24c791c385cc2eb16d930309256005624fb6c3a1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/24c791c385cc2eb16d930309256005624fb6c3a1.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2129,
          name: "Lucky Roulette",
          uuid: "9085932146ecb38f7256b31fa4aacf54fe89bd49",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9085932146ecb38f7256b31fa4aacf54fe89bd49.png",
          type: "roulette",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2137,
          name: "Master Of Gold",
          uuid: "c0eb1f2181a0c40b0cae45e2faad308011359e66",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c0eb1f2181a0c40b0cae45e2faad308011359e66.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2148,
          name: "Master of Xmas",
          uuid: "42b1f20a911ad1e2983603fe97567f7837ffa6ea",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/42b1f20a911ad1e2983603fe97567f7837ffa6ea.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2139,
          name: "Mayan Book",
          uuid: "e4c16c6b0d180d351476d123be85031aa27c6160",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e4c16c6b0d180d351476d123be85031aa27c6160.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2174,
          name: "Merry Hog",
          uuid: "28b87f16fbb9f4361ada0f7733d4098da76aac75",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/28b87f16fbb9f4361ada0f7733d4098da76aac75.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2125,
          name: "Mix Fruits",
          uuid: "604badb9a301ee856b9501490f81e9d0cebcaa38",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/604badb9a301ee856b9501490f81e9d0cebcaa38.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2124,
          name: "Monkey Jackpot",
          uuid: "8b8505002ad9a1e2637816af860b9839fca3252a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8b8505002ad9a1e2637816af860b9839fca3252a.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2164,
          name: "Mummyland Treasures",
          uuid: "a35ba034d66e0acfd1046961fa1b5242f219a347",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/a35ba034d66e0acfd1046961fa1b5242f219a347.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2150,
          name: "Northern Boom",
          uuid: "5a4975b51ffa4fbd0ee2305b047ab845e568f74b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5a4975b51ffa4fbd0ee2305b047ab845e568f74b.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2105,
          name: "Piggy Bank",
          uuid: "f49243bc3fe766d6ad6cc1510a440cc92f34a65d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f49243bc3fe766d6ad6cc1510a440cc92f34a65d.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2130,
          name: "Piggy Bank Scratch",
          uuid: "9b7e7f404bef2c57beb02e45ae0ccf52a660490b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9b7e7f404bef2c57beb02e45ae0ccf52a660490b.png",
          type: "scratch card",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2147,
          name: "Pirate JackPots",
          uuid: "2b99c2c5b01f0c3c686a0d929c0db72d59461faa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2b99c2c5b01f0c3c686a0d929c0db72d59461faa.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2119,
          name: "Prehistoric Story",
          uuid: "40de890ae4334d29f05216944462931587cbb70f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/40de890ae4334d29f05216944462931587cbb70f.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2106,
          name: "Princess of swamp",
          uuid: "549fbf1ca1a50e9d5112381b4db8bb36db78d77a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/549fbf1ca1a50e9d5112381b4db8bb36db78d77a.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2167,
          name: "Princess Suki",
          uuid: "05d8ad4a449c9676d064861e67a55e94e387a7be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/05d8ad4a449c9676d064861e67a55e94e387a7be.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2168,
          name: "Richy Hog",
          uuid: "e624cc410998dedd13d006c791bc21b681f94685",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/e624cc410998dedd13d006c791bc21b681f94685.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2179,
          name: "Rise of Zeus",
          uuid: "39e901b2e0cc6a29cb38e2d68278fe4ee7467495",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/39e901b2e0cc6a29cb38e2d68278fe4ee7467495.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2156,
          name: "Rockets",
          uuid: "a93de137a059328fdb564beae967c942902ee7a9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a93de137a059328fdb564beae967c942902ee7a9.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2176,
          name: "Santa Mummy",
          uuid: "386a6b63a0e7f38929c5f5c2a76fcfde28e0d9cd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/386a6b63a0e7f38929c5f5c2a76fcfde28e0d9cd.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2107,
          name: "Sevens",
          uuid: "64cbdd33cde19dd186fe4b185bf02a95e17011d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/64cbdd33cde19dd186fe4b185bf02a95e17011d7.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2140,
          name: "Sic Bo",
          uuid: "2a32b65b9fe6dd29b7e72c7755cc47ed9ee2ed3a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2a32b65b9fe6dd29b7e72c7755cc47ed9ee2ed3a.png",
          type: "sic bo",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2108,
          name: "Super Sevens",
          uuid: "ffbefa169ed73dbf76bd762ad242018d931760be",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ffbefa169ed73dbf76bd762ad242018d931760be.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2178,
          name: "Sweet Lotto",
          uuid: "67af0e142b834e9fa0aeb2309d708475",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/67af0e142b834e9fa0aeb2309d708475.png",
          type: "lottery",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2109,
          name: "The Ghost Walks",
          uuid: "843223658b2e16ea4fac2f34ffae5456e43d376f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/843223658b2e16ea4fac2f34ffae5456e43d376f.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2113,
          name: "The Moneymania",
          uuid: "aa66405431bf99c65132679ddcbb0501edf95623",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aa66405431bf99c65132679ddcbb0501edf95623.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2136,
          name: "The Night Racing",
          uuid: "9f24dabed78697de846065a08bd7f981d4985cfe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9f24dabed78697de846065a08bd7f981d4985cfe.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2110,
          name: "The Smart Rabbit",
          uuid: "31180d38d38f55fa1aa518578a190b8f3f9749d3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/31180d38d38f55fa1aa518578a190b8f3f9749d3.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2184,
          name: "Tony Gambler",
          uuid: "4dae5c9f7a954246827c7dcd3ea02956",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/4dae5c9f7a954246827c7dcd3ea02956.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2155,
          name: "Triple X Hot Pepper",
          uuid: "a6913eebaa3e63d2c809da9129d976454de5fa71",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a6913eebaa3e63d2c809da9129d976454de5fa71.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2166,
          name: "Wild Fruit Jam",
          uuid: "fe9e8798476bdb076e5b9ed2ac75dae7f1186fac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/fe9e8798476bdb076e5b9ed2ac75dae7f1186fac.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2165,
          name: "Witch School",
          uuid: "a2b7214b3999520b3287010e7a208f1bb45a15ca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/a2b7214b3999520b3287010e7a208f1bb45a15ca.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2186,
          name: "Wolf Thunder",
          uuid: "8dcea18563e84d168099051bde765b61",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/8dcea18563e84d168099051bde765b61.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2187,
          name: "X Towers",
          uuid: "81f18b246b124494bdb633e3ec6221f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/81f18b246b124494bdb633e3ec6221f8.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2161,
          name: "X-Mas Buffalo",
          uuid: "54c74353e6f8405a9767f7fb34bcf2dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/fdc4364a3eae2f06260e2f95ce9571ed/Belatra Games/54c74353e6f8405a9767f7fb34bcf2dd.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2149,
          name: "X-Mas Gifts",
          uuid: "edeff401d1943a1eb2485ef662a41fbe6466c5c8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/edeff401d1943a1eb2485ef662a41fbe6466c5c8.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2143,
          name: "Zombie Town",
          uuid: "9ac950fabf0180eb4d582c9d1e6599b0fa684b0b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9ac950fabf0180eb4d582c9d1e6599b0fa684b0b.png",
          type: "slots",
          provider: "Belatra Games",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 107,
      name: "Бетсоюшн",
      providerId: 28,
      providerName: "Betsolutions",
      games: [
        {
          game_id: 2324,
          name: "Dice",
          uuid: "c8afa9b2a85bf5e9770eb8406c15e8e79b91d752",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c8afa9b2a85bf5e9770eb8406c15e8e79b91d752.png",
          type: "provably fair",
          provider: "Betsolutions",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2322,
          name: "High low",
          uuid: "8d6e0bc0c7fd4e54e0e12003aa73ec875e36a699",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8d6e0bc0c7fd4e54e0e12003aa73ec875e36a699.png",
          type: "provably fair",
          provider: "Betsolutions",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2325,
          name: "Mines",
          uuid: "454d59f1a8eb1546433bf2440519f89644880f4c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/454d59f1a8eb1546433bf2440519f89644880f4c.png",
          type: "provably fair",
          provider: "Betsolutions",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2326,
          name: "Plinko",
          uuid: "bced615f3ff1482972e7246597e3230f0b0b67be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/bced615f3ff1482972e7246597e3230f0b0b67be.jpeg",
          type: "provably fair",
          provider: "Betsolutions",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2323,
          name: "Zeppelin",
          uuid: "cd0128cf16a2fd94d1b7546657f64d94080956be",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cd0128cf16a2fd94d1b7546657f64d94080956be.png",
          type: "crash",
          provider: "Betsolutions",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 108,
      name: "Болдплей",
      providerId: 57,
      providerName: "Boldplay",
      games: [
        {
          game_id: 2363,
          name: "1 Don House Supersweep",
          uuid: "e34c3c24802542b3bd198df6d0869ecf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/e34c3c24802542b3bd198df6d0869ecf.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2364,
          name: "1 Don House Supersweep Scratch",
          uuid: "815459d5e75149cca67b2d6337aee83d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/815459d5e75149cca67b2d6337aee83d.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2375,
          name: "10-Hand Video Poker",
          uuid: "2dc7abc4ac0a4d368bfa16b79470688e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/2dc7abc4ac0a4d368bfa16b79470688e.png",
          type: "video poker",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2339,
          name: "1000 Rainbows Superpot",
          uuid: "aed974ff1fa548e29f5b95922c56b776",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/aed974ff1fa548e29f5b95922c56b776.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2348,
          name: "1000 Rainbows Superpot Scratch",
          uuid: "e97a157b3e044eca9e63475a4718e75f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/e97a157b3e044eca9e63475a4718e75f.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2351,
          name: "500 BC Sparta Supersweep",
          uuid: "7404848b81a54d0e8f44a51c88de53de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/7404848b81a54d0e8f44a51c88de53de.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2353,
          name: "500 BC Sparta Supersweep Scratch",
          uuid: "a1e63f2c194e428dacf2243d9a4cf090",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/a1e63f2c194e428dacf2243d9a4cf090.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2428,
          name: "80 Day Escapade",
          uuid: "ef047dab26244aec82af74958abeff42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/ef047dab26244aec82af74958abeff42.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2329,
          name: "9 Planet Shockers",
          uuid: "4fa63fc5128040cb8c6981729fe38a4d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/4fa63fc5128040cb8c6981729fe38a4d.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2342,
          name: "9 Planet Shockers Scratch",
          uuid: "bdb62f1e9d524028ab6f79e9c96367d4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/bdb62f1e9d524028ab6f79e9c96367d4.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2330,
          name: "Aapo's Quest",
          uuid: "573655522a584dbaac031a0eae342a66",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/573655522a584dbaac031a0eae342a66.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2343,
          name: "Aapo's Quest Scratch",
          uuid: "128b62ff1daf4616a2a70a3ea40db787",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/128b62ff1daf4616a2a70a3ea40db787.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2414,
          name: "American Roulette",
          uuid: "0e582d93143017cfc22050b2c30e8188185d9b9d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/0e582d93143017cfc22050b2c30e8188185d9b9d.png",
          type: "table",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2350,
          name: "Arriba",
          uuid: "92a653385f134a9582c9625d71a57375",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/92a653385f134a9582c9625d71a57375.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2352,
          name: "Arriba Scratch",
          uuid: "81e4fa1262e74be7923dfdeb8cef85bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/81e4fa1262e74be7923dfdeb8cef85bc.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2335,
          name: "Baccarat",
          uuid: "e79c77b5f6384406abb7f87b71394247",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/e79c77b5f6384406abb7f87b71394247.png",
          type: "baccarat",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2408,
          name: "Biker Santa: Bells Angels",
          uuid: "e440abc894bcac0c58e4d836fc494a0fa324d247",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/e440abc894bcac0c58e4d836fc494a0fa324d247.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2409,
          name: "Biker Santa: Bells Angels Scratch",
          uuid: "2838f10f9be5d8ceb4a304b4a5d3437b9735a742",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/2838f10f9be5d8ceb4a304b4a5d3437b9735a742.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2336,
          name: "Blackjack",
          uuid: "52d12614989e45cf9ee6beb0b7639364",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/52d12614989e45cf9ee6beb0b7639364.png",
          type: "blackjack",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2327,
          name: "Book of Amduat",
          uuid: "d16f83f202404bea8d7e4e34d1d3c506",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/d16f83f202404bea8d7e4e34d1d3c506.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2340,
          name: "Book of Amduat Scratch",
          uuid: "207eb8f3865349fb970409499eb7894f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/207eb8f3865349fb970409499eb7894f.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2376,
          name: "Bow Wow",
          uuid: "df06a7eb23524aacbafadd70d457fc8e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/df06a7eb23524aacbafadd70d457fc8e.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2377,
          name: "Bow Wow Scratch",
          uuid: "d7990cf87b774696be72af9f5637e55f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/d7990cf87b774696be72af9f5637e55f.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2420,
          name: "Brute Force",
          uuid: "82874a6a0bc4450caab131717c3b91a7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/82874a6a0bc4450caab131717c3b91a7.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2421,
          name: "Brute Force Scratch",
          uuid: "b681fb4e1c1043e89efb1311f376b3be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/b681fb4e1c1043e89efb1311f376b3be.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2416,
          name: "Cakeshop Cascade",
          uuid: "4176444be8d089c9b43c33fae447396fe4eb39e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/4176444be8d089c9b43c33fae447396fe4eb39e7.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2415,
          name: "Cakeshop Cascade",
          uuid: "b6e57e573a0b7e75241855a5e19978f6d0c6bae2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/b6e57e573a0b7e75241855a5e19978f6d0c6bae2.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2419,
          name: "Cam Carter & the Wheel of Wonder",
          uuid: "d9d78c9f39bf84986eed9b2d562027c12330b855",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/d9d78c9f39bf84986eed9b2d562027c12330b855.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2402,
          name: "Cam Carter and the Cursed Caves",
          uuid: "b1b5eb2c710c9f96322deeabc7507d7b11f1074a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/b1b5eb2c710c9f96322deeabc7507d7b11f1074a.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2403,
          name: "Cam Carter and the Cursed Caves Scratch",
          uuid: "ea47f8d99815fc8f8ac8b101a7212a2ad2c03ab5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/ea47f8d99815fc8f8ac8b101a7212a2ad2c03ab5.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2386,
          name: "Carnaval do Rio",
          uuid: "2a538e7c9dfb470daa745cbbd8660e1e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/2a538e7c9dfb470daa745cbbd8660e1e.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2387,
          name: "Carnaval do Rio Scratch",
          uuid: "1ee4a61e9200430e850f2c7ea6243fca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/1ee4a61e9200430e850f2c7ea6243fca.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2337,
          name: "Christmas Fairies",
          uuid: "a3a5ba258e4d4b38bc9dcf912625a75c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/a3a5ba258e4d4b38bc9dcf912625a75c.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2349,
          name: "Christmas Fairies Scratch",
          uuid: "7f7291ec8188422da00cdfe345a3b0da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/7f7291ec8188422da00cdfe345a3b0da.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2398,
          name: "Classic Spins",
          uuid: "a47576b9941c3b91061dc77c41cdd43757d74b87",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/a47576b9941c3b91061dc77c41cdd43757d74b87.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2399,
          name: "Classic Spins Scratch",
          uuid: "5c4b84a46508fd8e903d2ca46107d9d2b9d04099",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/5c4b84a46508fd8e903d2ca46107d9d2b9d04099.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2369,
          name: "Creatures of Atlantis",
          uuid: "feffdc01fadd46a992c9197a39ab8ff1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/feffdc01fadd46a992c9197a39ab8ff1.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2370,
          name: "Creatures of Atlantis Scratch",
          uuid: "09b34f0f60f84c97b71e5bf2987a6a26",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/09b34f0f60f84c97b71e5bf2987a6a26.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2410,
          name: "Cyborg City",
          uuid: "ed45de3a70b307e77c2e59d8b4649a6bf081e68f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/ed45de3a70b307e77c2e59d8b4649a6bf081e68f.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2380,
          name: "Dark Potions",
          uuid: "147f73d3e8f5429c8f5a8500e40f7bed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/147f73d3e8f5429c8f5a8500e40f7bed.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2381,
          name: "Dark Potions Scratch",
          uuid: "671f62e913e9447fa83d3f22d8751cd4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/671f62e913e9447fa83d3f22d8751cd4.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2332,
          name: "Eirik the Viking",
          uuid: "e47fd58a042243229d5d23241a2159a3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/e47fd58a042243229d5d23241a2159a3.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2346,
          name: "Eirik the Viking Scratch",
          uuid: "decbb81bba2f4c16b5ab96edaafc5b7a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/decbb81bba2f4c16b5ab96edaafc5b7a.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2331,
          name: "Fabulous Fairies",
          uuid: "5f25f58c34ac47e3989c6532b97e35fc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/5f25f58c34ac47e3989c6532b97e35fc.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2344,
          name: "Fabulous Fairies Scratch",
          uuid: "d3854195264e45869126f21c6f5d9269",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/d3854195264e45869126f21c6f5d9269.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2333,
          name: "Fruit Bar",
          uuid: "f55e31d29f394c57a835cbd7c74d7d79",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/f55e31d29f394c57a835cbd7c74d7d79.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2347,
          name: "Fruit Bar Scratch",
          uuid: "c8b73e042f4d4583b5e2f5b44fb93a03",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/c8b73e042f4d4583b5e2f5b44fb93a03.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2338,
          name: "Gold Creek Superpays",
          uuid: "1935aef7f8364f20a91ccd0962f29681",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/1935aef7f8364f20a91ccd0962f29681.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2345,
          name: "Gold Creek Superpays Scratch",
          uuid: "cce337a930f54110a8558c4b4cc9e8fc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/cce337a930f54110a8558c4b4cc9e8fc.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2356,
          name: "Gold Metal Ox",
          uuid: "b7119cb122b84926af9ef13965117b50",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/b7119cb122b84926af9ef13965117b50.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2357,
          name: "Gold Metal Ox Scratch",
          uuid: "dcddbe2c73d64018bc33f4f30f919cd7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/dcddbe2c73d64018bc33f4f30f919cd7.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2359,
          name: "Gold Saloon Superpot",
          uuid: "5df8a41ca16e436ea07747c377b5241e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/5df8a41ca16e436ea07747c377b5241e.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2360,
          name: "Gold Saloon Superpot Scratch",
          uuid: "fe641e40ac4b43d49848620660bdaba2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/fe641e40ac4b43d49848620660bdaba2.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2404,
          name: "Holmes and Moriarty",
          uuid: "f646d96c0970bd709f84f6befa8454d92823c7bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/f646d96c0970bd709f84f6befa8454d92823c7bc.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2405,
          name: "Holmes and Moriarty Scratch",
          uuid: "49c047fa1957c2539728de4d2da6bb2b246e5beb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/49c047fa1957c2539728de4d2da6bb2b246e5beb.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2422,
          name: "Icy Cool",
          uuid: "c2411fe82c938c9b54af9d4a291092ae18d97b13",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/c2411fe82c938c9b54af9d4a291092ae18d97b13.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2358,
          name: "Keno",
          uuid: "a25c471688bb4cb1b7d1d506d83fb84c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/a25c471688bb4cb1b7d1d506d83fb84c.png",
          type: "keno",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2365,
          name: "Knights of Alturia",
          uuid: "0104167bedd24b4c9160dd9549417ad6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/0104167bedd24b4c9160dd9549417ad6.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2366,
          name: "Knights of Alturia Scratch",
          uuid: "9a9d7600950849aaaac6cef8c139032c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/9a9d7600950849aaaac6cef8c139032c.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2427,
          name: "Lucky Cushions",
          uuid: "d478c85a8e154bc88e87a1f54743f200",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/d478c85a8e154bc88e87a1f54743f200.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2388,
          name: "Mad Scientist",
          uuid: "3392ec6df6804fcd8b95d9d79b1efdff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/3392ec6df6804fcd8b95d9d79b1efdff.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2389,
          name: "Mad Scientist Scratch",
          uuid: "a1c2bf00299e41419d7cf44f6cf5cf26",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/a1c2bf00299e41419d7cf44f6cf5cf26.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2371,
          name: "Mummified Mysteries",
          uuid: "1d360b84792d42e0b022c8aac2b53a41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/1d360b84792d42e0b022c8aac2b53a41.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2372,
          name: "Mummified Mysteries Scratch",
          uuid: "6573788ec17e420096e724e9e8503373",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/6573788ec17e420096e724e9e8503373.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2426,
          name: "Paddy's Pints",
          uuid: "4b170e7f03e74f418758d1af79d6f6f9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/4b170e7f03e74f418758d1af79d6f6f9.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2373,
          name: "Pretty Diamonds",
          uuid: "1f48315304724652ade2801f8e80752b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/1f48315304724652ade2801f8e80752b.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2374,
          name: "Pretty Diamonds Scratch",
          uuid: "fc579454deba4a0e8a329d74235cf7fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/fc579454deba4a0e8a329d74235cf7fe.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2384,
          name: "Queen Hera",
          uuid: "1f39d225746746a0a041007f5b6cbd8e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/1f39d225746746a0a041007f5b6cbd8e.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2385,
          name: "Queen Hera Scratch",
          uuid: "1b4ded84c0c14526a020a802c9b9f4fc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/1b4ded84c0c14526a020a802c9b9f4fc.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2392,
          name: "Reef Adventures",
          uuid: "9e3f9ec3adb14212a128ecffa7973b7b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/9e3f9ec3adb14212a128ecffa7973b7b.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2393,
          name: "Reef Adventures Scratch",
          uuid: "c7b828119ed0485a9a77829719725e5e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/c7b828119ed0485a9a77829719725e5e.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2334,
          name: "Roulette",
          uuid: "f3578cb5383246a8a7b20e99a89adda2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/f3578cb5383246a8a7b20e99a89adda2.png",
          type: "roulette",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2354,
          name: "Shinobi SuperSweep",
          uuid: "f7b532c62d2d49c6b7a2fba7668db736",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/f7b532c62d2d49c6b7a2fba7668db736.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2355,
          name: "Shinobi Supersweep Scratch",
          uuid: "4ac6611687c744d3aca474f8420a7d1a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/4ac6611687c744d3aca474f8420a7d1a.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2394,
          name: "Silver Hook",
          uuid: "b1d9d4925d054ee9bb7266773b8c891e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/b1d9d4925d054ee9bb7266773b8c891e.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2395,
          name: "Silver Hook Scratch",
          uuid: "6b29db6723904f00aa66e0d393ae7d63",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/6b29db6723904f00aa66e0d393ae7d63.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2417,
          name: "Speed Heist",
          uuid: "384ce9d1d2e943188ecf2cf01ccb0b12",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/384ce9d1d2e943188ecf2cf01ccb0b12.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2418,
          name: "Splatzz",
          uuid: "5fd9c000b20543a46f7ab557579f77b0f7189c94",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/5fd9c000b20543a46f7ab557579f77b0f7189c94.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2396,
          name: "Star Dragon",
          uuid: "dcfad9d655414aebbc7d22a2d7ee82a7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/dcfad9d655414aebbc7d22a2d7ee82a7.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2397,
          name: "Star Dragon Scratch",
          uuid: "3579f219701d439da4fe073cd76dff5a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/3579f219701d439da4fe073cd76dff5a.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2400,
          name: "Surfing Beauties",
          uuid: "12f5c4766b427bcfa21fcc43f9cc165453e544a4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/12f5c4766b427bcfa21fcc43f9cc165453e544a4.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2401,
          name: "Surfing Beauties Scratch",
          uuid: "24a08a51b2e5264cd41f23232ef5d6486ba454c9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/24a08a51b2e5264cd41f23232ef5d6486ba454c9.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2413,
          name: "Surfing Beauties Video Bingo",
          uuid: "743c8a5e00916ae6543231856d2fa27d97a5e2dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/743c8a5e00916ae6543231856d2fa27d97a5e2dd.png",
          type: "bingo",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2328,
          name: "Swan House",
          uuid: "a1abbbf4edb24d7780d4391414226432",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/a1abbbf4edb24d7780d4391414226432.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2341,
          name: "Swan House Scratch",
          uuid: "34da091b5f2b451a9d306ddf206a68a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/34da091b5f2b451a9d306ddf206a68a9.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2429,
          name: "Sweet Splash",
          uuid: "049aba0aaea644f6bae2b715838bd1f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/049aba0aaea644f6bae2b715838bd1f6.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2367,
          name: "Swift Blades",
          uuid: "08ac92dae4874711a51bad0fbe5389f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/08ac92dae4874711a51bad0fbe5389f6.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2368,
          name: "Swift Blades Scratch",
          uuid: "cf4e4b11f9ab4fd58e69d1c38a0493d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/cf4e4b11f9ab4fd58e69d1c38a0493d2.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2425,
          name: "Three Card Poker",
          uuid: "b5797d886059430c9ec7c1e204dab65c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/b5797d886059430c9ec7c1e204dab65c.png",
          type: "table",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2382,
          name: "Tiger Fortune",
          uuid: "6e4b32b0020847c7b67744a9476722cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/6e4b32b0020847c7b67744a9476722cf.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2383,
          name: "Tiger Fortune Scratch",
          uuid: "c3fbf7d47a934e14b1fe39e3385abcf1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/c3fbf7d47a934e14b1fe39e3385abcf1.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2362,
          name: "Toro Bravo",
          uuid: "133e40ddfa9b40cab9da5bff9864125b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/133e40ddfa9b40cab9da5bff9864125b.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2361,
          name: "Toro Bravo Scratch",
          uuid: "0d1090d8e55f468ab7dc04de8c985f3a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/0d1090d8e55f468ab7dc04de8c985f3a.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2412,
          name: "Warriors & Warlocks",
          uuid: "763c650a6b68c2728b0fb5443e6511fdfd3d1263",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/763c650a6b68c2728b0fb5443e6511fdfd3d1263.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2411,
          name: "Warriors & Warlocks",
          uuid: "b747379a4c1ec9866d33115f60dc47cb9e5106f1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/b747379a4c1ec9866d33115f60dc47cb9e5106f1.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2390,
          name: "White Buffalo Miracles",
          uuid: "0b8b38a69fb84f12b2d03e5510aca99d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/0b8b38a69fb84f12b2d03e5510aca99d.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2391,
          name: "White Buffalo Miracles Scratch",
          uuid: "47c27c8397dd41bca853a9c59b3c906f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/47c27c8397dd41bca853a9c59b3c906f.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2378,
          name: "Wild Joker",
          uuid: "433bbf6bedd544b5b08819563ab973e2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/433bbf6bedd544b5b08819563ab973e2.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2379,
          name: "Wild Joker Scratch",
          uuid: "d54df4a94edd487c961cf124610637fc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/d54df4a94edd487c961cf124610637fc.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2406,
          name: "Wildscapes",
          uuid: "1e4b8fc36c4bf3b785f47b616348244db037ced4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/1e4b8fc36c4bf3b785f47b616348244db037ced4.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2407,
          name: "Wildscapes Scratch",
          uuid: "245df34df1a5d7528c81223c9d4e110059191a28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/245df34df1a5d7528c81223c9d4e110059191a28.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2424,
          name: "Wood Dragon",
          uuid: "2e8615e69bf5466cab324740053de5c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/2e8615e69bf5466cab324740053de5c2.png",
          type: "slots",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2423,
          name: "Wood Dragon Scratch",
          uuid: "079b39d358564e34afce22982a3b93d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Boldplay/079b39d358564e34afce22982a3b93d7.png",
          type: "scratch card",
          provider: "Boldplay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 109,
      name: "БрэндГеймс",
      providerId: 6,
      providerName: "BrandGames",
      games: [
        {
          game_id: 706,
          name: "Avengers",
          uuid: "181b3eb397deb851f9fb3c51c63fb18d5a524961",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/181b3eb397deb851f9fb3c51c63fb18d5a524961.jpg",
          type: "slots",
          provider: "BrandGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 694,
          name: "Captain Marvel",
          uuid: "1ca46c650d00c665d89c16f485987f7eb3b2a84a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1ca46c650d00c665d89c16f485987f7eb3b2a84a.jpg",
          type: "slots",
          provider: "BrandGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 704,
          name: "hellboy",
          uuid: "dfab0e4de0281dcac9525783c5cf527d02ad31e1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/dfab0e4de0281dcac9525783c5cf527d02ad31e1.jpeg",
          type: "slots",
          provider: "BrandGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 707,
          name: "John Wick",
          uuid: "9fa9657dc6370b29a020c4a49aba67a71e858f01",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9fa9657dc6370b29a020c4a49aba67a71e858f01.jpg",
          type: "slots",
          provider: "BrandGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 705,
          name: "Shazam",
          uuid: "befb00240dcb3153054e4818356e1db423b51a7e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/befb00240dcb3153054e4818356e1db423b51a7e.jpg",
          type: "slots",
          provider: "BrandGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 110,
      name: "БГейминг",
      providerId: 24,
      providerName: "BGaming",
      games: [
        {
          game_id: 2698,
          name: "3 Kings Scratch",
          uuid: "d1fb13f8e2624fe1beb61110da194dc9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d1fb13f8e2624fe1beb61110da194dc9.png",
          type: "scratch card",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2699,
          name: "3 Kings Scratch Mobile",
          uuid: "66b9af2662e345fda6cae185ad601af5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/66b9af2662e345fda6cae185ad601af5.png",
          type: "scratch card",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2521,
          name: "Alien Fruits",
          uuid: "26e736d59e9841a693e1fed7a5b301fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/26e736d59e9841a693e1fed7a5b301fe.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2716,
          name: "Alien Fruits 2",
          uuid: "48280d54a8a54b4f81895325b2fb35ac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/48280d54a8a54b4f81895325b2fb35ac.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2717,
          name: "Alien Fruits 2 Mobile",
          uuid: "7afe5c2db2ae423cb1849a1f09aedfe9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/7afe5c2db2ae423cb1849a1f09aedfe9.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2618,
          name: "Alien Fruits Mobile",
          uuid: "abc8d6d57a984fdda886ad2f476dab80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/abc8d6d57a984fdda886ad2f476dab80.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2466,
          name: "All Lucky Clovers",
          uuid: "6e8c33cdd067afec4fd646d0171f804d4410921a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6e8c33cdd067afec4fd646d0171f804d4410921a.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2467,
          name: "All Lucky Clovers 100",
          uuid: "da605c5056ebafdc316e62403d583cc593e64dad",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/da605c5056ebafdc316e62403d583cc593e64dad.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2565,
          name: "All Lucky Clovers 100 Mobile",
          uuid: "f538354f00f84c5fb6b2de5e448a5e06",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f538354f00f84c5fb6b2de5e448a5e06.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2468,
          name: "All Lucky Clovers 20",
          uuid: "56707a6ec4ece3dda7cbcbf73961279b27bc4d46",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/56707a6ec4ece3dda7cbcbf73961279b27bc4d46.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2566,
          name: "All Lucky Clovers 20 Mobile",
          uuid: "8053e6b295bb4f97b43cf5b94c42456c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/8053e6b295bb4f97b43cf5b94c42456c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2469,
          name: "All Lucky Clovers 40",
          uuid: "0a0534787f93e2c8d219dfb2e3691f6eed310e41",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0a0534787f93e2c8d219dfb2e3691f6eed310e41.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2567,
          name: "All Lucky Clovers 40 Mobile",
          uuid: "3f4baff9b84f47699d62d270fbe57c6b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/3f4baff9b84f47699d62d270fbe57c6b.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2470,
          name: "All Lucky Clovers 5",
          uuid: "9cbf1e80529498f5ec5d6263ceff758d83fc3628",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9cbf1e80529498f5ec5d6263ceff758d83fc3628.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2568,
          name: "All Lucky Clovers 5 Mobile",
          uuid: "d1fbd4e5105f4a78a91a79d350fd351b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d1fbd4e5105f4a78a91a79d350fd351b.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2564,
          name: "All Lucky Clovers Mobile",
          uuid: "10427d61d78742569f105fbdf6bc58d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/10427d61d78742569f105fbdf6bc58d2.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2479,
          name: "Aloha King Elvis",
          uuid: "f89cf2158f762d3cbe93df486e7c21f424c8e178",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f89cf2158f762d3cbe93df486e7c21f424c8e178.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2577,
          name: "Aloha King Elvis Mobile",
          uuid: "ca9c9bab1735419cafc59a3395b97e59",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ca9c9bab1735419cafc59a3395b97e59.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2430,
          name: "American Roulette",
          uuid: "97b44e87f1ae3b71dc5c7e92913cc77234a1580e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/97b44e87f1ae3b71dc5c7e92913cc77234a1580e.png",
          type: "roulette",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2528,
          name: "American Roulette Mobile",
          uuid: "ea3ac0ca8a9949b9abf348f2ee4dee11",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ea3ac0ca8a9949b9abf348f2ee4dee11.png",
          type: "roulette",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2462,
          name: "Avalon: The Lost Kingdom",
          uuid: "8be41c82035e9a5a2789dd63cd06b57cb5558288",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8be41c82035e9a5a2789dd63cd06b57cb5558288.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2560,
          name: "Avalon: The Lost Kingdom Mobile",
          uuid: "148d562ebdfb402097a29e373f2c4471",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/148d562ebdfb402097a29e373f2c4471.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2718,
          name: "AVIAMASTERS",
          uuid: "eb3536b2a243416e9bbc27530c0cc9c7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/eb3536b2a243416e9bbc27530c0cc9c7.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2719,
          name: "AVIAMASTERS Mobile",
          uuid: "7b01b6f4eaa7401c8236ca7676877b94",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/7b01b6f4eaa7401c8236ca7676877b94.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2688,
          name: "Aztec Clusters",
          uuid: "53acf06b4ea640e087c30485ff528316",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/53acf06b4ea640e087c30485ff528316.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2689,
          name: "Aztec Clusters Mobile",
          uuid: "2ec3f1e1f641490b88a30cfd43168c91",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2ec3f1e1f641490b88a30cfd43168c91.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2431,
          name: "Aztec Magic",
          uuid: "cb20167bb2b8b1b87d6eebf9c977e45eb2fc71c3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cb20167bb2b8b1b87d6eebf9c977e45eb2fc71c3.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2489,
          name: "Aztec Magic Bonanza",
          uuid: "8b761e07965a35d799dc9476d66eb9189fe9431e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8b761e07965a35d799dc9476d66eb9189fe9431e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2586,
          name: "Aztec Magic Bonanza Mobile",
          uuid: "b0af123d908146c28415a7b966063f73",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b0af123d908146c28415a7b966063f73.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2432,
          name: "Aztec Magic Deluxe",
          uuid: "33182334cd83b0e10e19629f4fa4ac71132f9943",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/33182334cd83b0e10e19629f4fa4ac71132f9943.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2530,
          name: "Aztec Magic Deluxe Mobile",
          uuid: "aa65e63814fb4230951170c290b7679f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/aa65e63814fb4230951170c290b7679f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2488,
          name: "Aztec Magic Megaways",
          uuid: "522de2ce56c756d540d022128ececaaae686328b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/522de2ce56c756d540d022128ececaaae686328b.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2585,
          name: "Aztec Magic Megaways Mobile",
          uuid: "905e425f969f44af90f318be5fab3718",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/905e425f969f44af90f318be5fab3718.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2529,
          name: "Aztec Magic Mobile",
          uuid: "5fa5690ea2fb44f4b57c136c7ec3dcd6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/5fa5690ea2fb44f4b57c136c7ec3dcd6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2748,
          name: "AZTEC'S CLAW WILD DICE",
          uuid: "05fd20d3061a43838140fc93634dc6e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/05fd20d3061a43838140fc93634dc6e3.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2749,
          name: "AZTEC'S CLAW WILD DICE Mobile",
          uuid: "90571f90fb924183817f1d6d3025d171",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/90571f90fb924183817f1d6d3025d171.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2625,
          name: "Beast Band",
          uuid: "2aeaad78b84e201a4ef3ea28ebe9f2eb22baa38c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2aeaad78b84e201a4ef3ea28ebe9f2eb22baa38c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2626,
          name: "Beast Band Mobile",
          uuid: "2a88406c9cd3cfad9f691daec75693d990f16289",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2a88406c9cd3cfad9f691daec75693d990f16289.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2506,
          name: "Beer Bonanza",
          uuid: "ac437469dda9d4e0a82059d405540eec85d111d2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ac437469dda9d4e0a82059d405540eec85d111d2.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 2603,
          name: "Beer Bonanza Mobile",
          uuid: "45c65dc12af4427292a24b74ab24e0bd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/45c65dc12af4427292a24b74ab24e0bd.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2499,
          name: "Big Atlantis Frenzy",
          uuid: "0471e200f9004a81ec547ac7b4260debfd0c7356",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0471e200f9004a81ec547ac7b4260debfd0c7356.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2596,
          name: "Big Atlantis Frenzy Mobile",
          uuid: "6fa13ce36a3c4bf0949cc93ab4a7c875",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/6fa13ce36a3c4bf0949cc93ab4a7c875.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2486,
          name: "Bonanza Billion",
          uuid: "9bbe1dec074937e5f32e807af3aae69048429da8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/9bbe1dec074937e5f32e807af3aae69048429da8.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2583,
          name: "Bonanza Billion Mobile",
          uuid: "b145602a36c142abb0fe1b63c74e4702",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b145602a36c142abb0fe1b63c74e4702.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2640,
          name: "Bone Bonanza",
          uuid: "388de75167cb4c24b9a9b0b2f77aa451",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/388de75167cb4c24b9a9b0b2f77aa451.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2641,
          name: "Bone Bonanza Mobile",
          uuid: "4cca5b0f114044ca9ac4b5ef18e44c9c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/4cca5b0f114044ca9ac4b5ef18e44c9c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2463,
          name: "Book Of Cats",
          uuid: "82ba708b3ad7ca797a215110072264591fc0657f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/82ba708b3ad7ca797a215110072264591fc0657f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2509,
          name: "Book of Cats Megaways",
          uuid: "795ccbc413c872fcc6640b3cf8f91dc72e2bc475",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/795ccbc413c872fcc6640b3cf8f91dc72e2bc475.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2606,
          name: "Book of Cats Megaways Mobile",
          uuid: "737415a11c364f3689ab242a0cdd5ee7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/737415a11c364f3689ab242a0cdd5ee7.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2561,
          name: "Book Of Cats Mobile",
          uuid: "711cb6bf320b4c61b8299ffa56599740",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/711cb6bf320b4c61b8299ffa56599740.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2518,
          name: "Book of Kemet",
          uuid: "d3475f29ae4a4163b85ac2617948f96e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d3475f29ae4a4163b85ac2617948f96e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2615,
          name: "Book of Kemet Mobile",
          uuid: "00edf128cc704a6baaf52b3fe8b76d7c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/00edf128cc704a6baaf52b3fe8b76d7c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2656,
          name: "Book of Panda Megaways",
          uuid: "3d0911379f8b4020b24870c252578b4e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/3d0911379f8b4020b24870c252578b4e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2657,
          name: "Book of Panda Megaways Mobile",
          uuid: "0a03e32d0002433db01ae9a7599f25ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/0a03e32d0002433db01ae9a7599f25ea.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2434,
          name: "Book of Pyramids",
          uuid: "d0af379433a4b7394215a5b65709df6d9b7deb30",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d0af379433a4b7394215a5b65709df6d9b7deb30.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2532,
          name: "Book of Pyramids Mobile",
          uuid: "6db85d00063d47e7a0487bfb4f35e727",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/6db85d00063d47e7a0487bfb4f35e727.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2435,
          name: "Brave Viking",
          uuid: "99ec3ef58d75f31e47e3b8916886f0320b0fa6ca",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/99ec3ef58d75f31e47e3b8916886f0320b0fa6ca.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2533,
          name: "Brave Viking Mobile",
          uuid: "f486a150f62c4e2a84eb5fe9cbb971e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f486a150f62c4e2a84eb5fe9cbb971e5.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2511,
          name: "Burning Chilli X",
          uuid: "bbf81c29ebaf4be8a0830a72c5910dbc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/bbf81c29ebaf4be8a0830a72c5910dbc.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2608,
          name: "Burning Chilli X Mobile",
          uuid: "c2b0b132afd34e61bc569096648d3ac9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/c2b0b132afd34e61bc569096648d3ac9.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2483,
          name: "Candy Monsta",
          uuid: "d1bae6d38b5929684cc9f0a11b621c534911836e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d1bae6d38b5929684cc9f0a11b621c534911836e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2580,
          name: "Candy Monsta Mobile",
          uuid: "c517be76e4a14c4587384953354f7711",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/c517be76e4a14c4587384953354f7711.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2674,
          name: "Capymania Green",
          uuid: "bf02805c71ed43bd9934d939cf24ef0e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/bf02805c71ed43bd9934d939cf24ef0e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2675,
          name: "Capymania Green Mobile",
          uuid: "60390709c7f641e4b69bd74f45802bc3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/60390709c7f641e4b69bd74f45802bc3.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2676,
          name: "Capymania Orange",
          uuid: "0a6fdc0808604660a73eca4e2856e59f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/0a6fdc0808604660a73eca4e2856e59f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2677,
          name: "Capymania Orange Mobile",
          uuid: "3f80612bfdc64bf7b91f52ec01906c44",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/3f80612bfdc64bf7b91f52ec01906c44.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2678,
          name: "Capymania Yellow",
          uuid: "cec0ed36a5d44b4094b98559e10d3f8c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/cec0ed36a5d44b4094b98559e10d3f8c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2679,
          name: "Capymania Yellow Mobile",
          uuid: "22e500a0458d455e8e4cebf526e0d5a3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/22e500a0458d455e8e4cebf526e0d5a3.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2750,
          name: "Carnival Bonanza",
          uuid: "a2133ac2cb014f68816be7cca8d269e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a2133ac2cb014f68816be7cca8d269e7.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2751,
          name: "Carnival Bonanza Mobile",
          uuid: "57633d08e1e2460c904d4d3e30d250d6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/57633d08e1e2460c904d4d3e30d250d6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2731,
          name: "Catch The Gold Hold And Win",
          uuid: "e4309e6b58ef4f39bd0cc8ce5a3f427e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e4309e6b58ef4f39bd0cc8ce5a3f427e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2730,
          name: "Catch The Gold Hold And Win Mobile",
          uuid: "6a600d01e35a4d37b3ae627395b7df59",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/6a600d01e35a4d37b3ae627395b7df59.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2436,
          name: "Cherry Fiesta",
          uuid: "abcf20a14103523712dddbb195faeb2124642e7c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/abcf20a14103523712dddbb195faeb2124642e7c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2534,
          name: "Cherry Fiesta Mobile",
          uuid: "bc4f30765cab4c6c97480f1159837f8d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/bc4f30765cab4c6c97480f1159837f8d.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2706,
          name: "Chicken Rush",
          uuid: "9ead37ea619546ecb588a5003c49e9d4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/9ead37ea619546ecb588a5003c49e9d4.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2707,
          name: "Chicken Rush Mobile",
          uuid: "e14914bd92fd4baa97731b08fc5ffbec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e14914bd92fd4baa97731b08fc5ffbec.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2490,
          name: "Clover Bonanza",
          uuid: "c98e32522db4504201cb16d39198c376745375e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c98e32522db4504201cb16d39198c376745375e4.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2587,
          name: "Clover Bonanza Mobile",
          uuid: "a20a1a669b4045c18b7ae187a24eec09",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a20a1a669b4045c18b7ae187a24eec09.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2437,
          name: "Crazy Starter",
          uuid: "6b6216ec968d4913f4a66e9465a5241579ee6099",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6b6216ec968d4913f4a66e9465a5241579ee6099.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2535,
          name: "Crazy Starter Mobile",
          uuid: "10646d1d2c284a04a102dd68536d9847",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/10646d1d2c284a04a102dd68536d9847.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2476,
          name: "Deep Sea",
          uuid: "2f0fb5e0b7bab62f4dda026f928ec3393cae524c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2f0fb5e0b7bab62f4dda026f928ec3393cae524c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2574,
          name: "Deep Sea Mobile",
          uuid: "cf85f98611ee44278959129f069afa98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/cf85f98611ee44278959129f069afa98.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2438,
          name: "Desert Treasure",
          uuid: "561b65a789f76625d1d26b75a292323bfcc7a1f1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/561b65a789f76625d1d26b75a292323bfcc7a1f1.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2536,
          name: "Desert Treasure Mobile",
          uuid: "e35a70b939034d3699ccd2c0cee71392",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e35a70b939034d3699ccd2c0cee71392.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2696,
          name: "Diamond of Jungle",
          uuid: "ca5c54306981438cb4864ed695d75115",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ca5c54306981438cb4864ed695d75115.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2697,
          name: "Diamond of Jungle Mobile",
          uuid: "b4c3481a0bf44ef18a09b155b2e04983",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b4c3481a0bf44ef18a09b155b2e04983.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2628,
          name: "Dice Bonanza",
          uuid: "01cb7d5e74b211bcbb50ada83c79aa7729d07f1e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/01cb7d5e74b211bcbb50ada83c79aa7729d07f1e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2629,
          name: "Dice Bonanza Mobile",
          uuid: "31829504cd9fe996d9ffb724cd4764e051ab3095",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/31829504cd9fe996d9ffb724cd4764e051ab3095.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2632,
          name: "Dice Million",
          uuid: "aadaba76f97c4889a0ed364edea7b7bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/aadaba76f97c4889a0ed364edea7b7bc.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2633,
          name: "Dice Million Mobile",
          uuid: "2e88ade3a0ca4905bd1d2a3c423d78f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2e88ade3a0ca4905bd1d2a3c423d78f6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2478,
          name: "Dig Dig Digger",
          uuid: "9027da48fd5f0953f766c7f2c6d5fff8bbb4d836",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9027da48fd5f0953f766c7f2c6d5fff8bbb4d836.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2576,
          name: "Dig Dig Digger Mobile",
          uuid: "37ebda87a82a4830b8df96f39ed4871f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/37ebda87a82a4830b8df96f39ed4871f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2514,
          name: "Domnitor's Treasure",
          uuid: "a329ffb617b248ca8c5b402377851b04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a329ffb617b248ca8c5b402377851b04.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2611,
          name: "Domnitor's Treasure Mobile",
          uuid: "487151fde6bb4f7097c27b45e97c09f3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/487151fde6bb4f7097c27b45e97c09f3.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2439,
          name: "Domnitors",
          uuid: "7b7aeec50eb387ca54bddc85b50591c7fc873427",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7b7aeec50eb387ca54bddc85b50591c7fc873427.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2440,
          name: "Domnitors Deluxe",
          uuid: "2e2a8a4e421b4f5c1924eba1378b39749a584e8f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2e2a8a4e421b4f5c1924eba1378b39749a584e8f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2538,
          name: "Domnitors Deluxe Mobile",
          uuid: "a2629e0fb2364bd48eb78d7dc0cc1dd3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a2629e0fb2364bd48eb78d7dc0cc1dd3.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2537,
          name: "Domnitors Mobile",
          uuid: "35cf8797c8d44f8e850c5db0a017861e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/35cf8797c8d44f8e850c5db0a017861e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2714,
          name: "Dragon Age Hold & Win",
          uuid: "982fb81529bb4df096ae26c3e063121f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/982fb81529bb4df096ae26c3e063121f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2715,
          name: "Dragon Age Hold & Win Mobile",
          uuid: "fb9ea87cea184e72aff33ee644cbce5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/fb9ea87cea184e72aff33ee644cbce5d.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2694,
          name: "DRAGON'S CRASH",
          uuid: "2b77f20b9003476e9939daa6d2792cce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2b77f20b9003476e9939daa6d2792cce.png",
          type: "crash",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2695,
          name: "DRAGON'S CRASH Mobile",
          uuid: "0a6dcde4e3b146f5b35341b0e8a954e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/0a6dcde4e3b146f5b35341b0e8a954e9.png",
          type: "crash",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2481,
          name: "Dragon’s Gold 100",
          uuid: "7db9d9f7be07080cf54afdeef387f95b74c2b3c1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7db9d9f7be07080cf54afdeef387f95b74c2b3c1.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2627,
          name: "Dragon’s Gold 100 Mobile",
          uuid: "021e167f5f072411fd2b0123e90b246ba86fb022",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/021e167f5f072411fd2b0123e90b246ba86fb022.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2520,
          name: "Easter Heist",
          uuid: "f9b1af9bd2cd9fa30f3067f157c9595f144e2bd7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f9b1af9bd2cd9fa30f3067f157c9595f144e2bd7.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2617,
          name: "Easter Heist Mobile",
          uuid: "5f6bc6d445774ceba3718c268dc28d72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/5f6bc6d445774ceba3718c268dc28d72.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2519,
          name: "Easter Plinko",
          uuid: "b0b5133729ad04360a1c33bce7f2c4febc5ef993",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b0b5133729ad04360a1c33bce7f2c4febc5ef993.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2616,
          name: "Easter Plinko Mobile",
          uuid: "2c848eb302c74befa150ff956326bd81",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2c848eb302c74befa150ff956326bd81.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2461,
          name: "Elvis Frog in Vegas",
          uuid: "fb8c9b57190792d68dbffc17b084c0cbb096a9ff",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fb8c9b57190792d68dbffc17b084c0cbb096a9ff.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2559,
          name: "Elvis Frog in Vegas Mobile",
          uuid: "186a9de1068e41f5805821d8ab9aefa6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/186a9de1068e41f5805821d8ab9aefa6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2508,
          name: "Elvis Frog TRUEWAYS",
          uuid: "dd42a59c51b342781078c433864f262b9d120808",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/dd42a59c51b342781078c433864f262b9d120808.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2605,
          name: "Elvis Frog TRUEWAYS Mobile",
          uuid: "a4dcc29c5310432bb4e33cf12309697f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a4dcc29c5310432bb4e33cf12309697f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2441,
          name: "European Roulette",
          uuid: "c732011a78e064eebb47e077cbfb6c8e8283843d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c732011a78e064eebb47e077cbfb6c8e8283843d.png",
          type: "roulette",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2539,
          name: "European Roulette Mobile",
          uuid: "48b1afbe4689455888e19badee2c78bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/48b1afbe4689455888e19badee2c78bb.png",
          type: "roulette",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2442,
          name: "Fantasy Park",
          uuid: "7be106162eed80dadc1e13cc64309ad680e09c89",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7be106162eed80dadc1e13cc64309ad680e09c89.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2540,
          name: "Fantasy Park Mobile",
          uuid: "57a2248127c6493398d410e605dd160f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/57a2248127c6493398d410e605dd160f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2443,
          name: "Fire Lightning",
          uuid: "9cb7f656b7f27607f7dc6163dd5c95e031abed32",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9cb7f656b7f27607f7dc6163dd5c95e031abed32.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2541,
          name: "Fire Lightning Mobile",
          uuid: "6456805124b7483b91d86fc6ddc79146",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/6456805124b7483b91d86fc6ddc79146.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2712,
          name: "FISHING CLUB",
          uuid: "26c98ee7a2c847cfb19afb563ee89c2e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/26c98ee7a2c847cfb19afb563ee89c2e.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2713,
          name: "FISHING CLUB Mobile",
          uuid: "69c4427933b747809345c2539aa37eb4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/69c4427933b747809345c2539aa37eb4.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2746,
          name: "FORTUNA TRUEWAYS",
          uuid: "765b2ea33cf243e99760af0fc0ee6dfc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/765b2ea33cf243e99760af0fc0ee6dfc.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2747,
          name: "FORTUNA TRUEWAYS Mobile",
          uuid: "ad5b5400af034ac996f132ec9f219130",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ad5b5400af034ac996f132ec9f219130.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2505,
          name: "Forty Fruity Million",
          uuid: "b0b245f5009a2dfe5dcfce1ae49f139432860cfa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b0b245f5009a2dfe5dcfce1ae49f139432860cfa.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2602,
          name: "Forty Fruity Million Mobile",
          uuid: "2ad8f3baa5614630887d4db6005751bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2ad8f3baa5614630887d4db6005751bb.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2464,
          name: "Four Lucky Clover",
          uuid: "6d23ba79e856a878723e1f98b2afdf26b0e8a235",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6d23ba79e856a878723e1f98b2afdf26b0e8a235.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2562,
          name: "Four Lucky Clover Mobile",
          uuid: "a53c130ad6344343ae03fb977b3cafec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a53c130ad6344343ae03fb977b3cafec.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2480,
          name: "Four Lucky Diamonds",
          uuid: "40407848f3fe2f5f8493be28edf62b07f580f47d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/40407848f3fe2f5f8493be28edf62b07f580f47d.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2578,
          name: "Four Lucky Diamonds Mobile",
          uuid: "b7943b23abb54fe29adf362ba56ccce1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b7943b23abb54fe29adf362ba56ccce1.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2444,
          name: "French Roulette",
          uuid: "5026e867f9101af117d2fb0ca192e1730f7f737a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5026e867f9101af117d2fb0ca192e1730f7f737a.png",
          type: "roulette",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2542,
          name: "French Roulette Mobile",
          uuid: "514c28453b8f4c3cb47d8e8d947b470e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/514c28453b8f4c3cb47d8e8d947b470e.png",
          type: "roulette",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2473,
          name: "Fruit Million",
          uuid: "12817e8feccf80a0e65692de712a3ceb6384e488",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/12817e8feccf80a0e65692de712a3ceb6384e488.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2571,
          name: "Fruit Million Mobile",
          uuid: "4f1877e649e34de683110fd608877d68",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/4f1877e649e34de683110fd608877d68.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2512,
          name: "Gangsterz",
          uuid: "292e4e91fc734437b69205c6cb3c654e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/292e4e91fc734437b69205c6cb3c654e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2609,
          name: "Gangsterz Mobile",
          uuid: "768acf216bc54548bb03f48c5458912a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/768acf216bc54548bb03f48c5458912a.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2526,
          name: "Gemhalla",
          uuid: "b454e6c1b367461585e68848f7d29ab6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b454e6c1b367461585e68848f7d29ab6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2623,
          name: "Gemhalla Mobile",
          uuid: "7b16ceae4b2643fc8de4491dd7f4e6a2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/7b16ceae4b2643fc8de4491dd7f4e6a2.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2664,
          name: "Gemza",
          uuid: "42ccf957783e4fc8af2a97312e336490",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/42ccf957783e4fc8af2a97312e336490.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2665,
          name: "Gemza Mobile",
          uuid: "2ad3c2eca58147c1be321349146a64e2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2ad3c2eca58147c1be321349146a64e2.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2513,
          name: "Gift Rush",
          uuid: "d0b96f5b0f0f4d839058b89a2581bb76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d0b96f5b0f0f4d839058b89a2581bb76.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2610,
          name: "Gift Rush Mobile",
          uuid: "5f61aca4a0fd4986a801dc11aa91b305",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/5f61aca4a0fd4986a801dc11aa91b305.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2660,
          name: "Gift X",
          uuid: "08904a940dee4b37a33090a50f64af8c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/08904a940dee4b37a33090a50f64af8c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2661,
          name: "Gift X Mobile",
          uuid: "9e3169eb440e46c5b9798b426da8a923",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/9e3169eb440e46c5b9798b426da8a923.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2684,
          name: "God of Wealth Hold And Win",
          uuid: "e533c2f0ce224ce2b36edc14cbe01ba0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e533c2f0ce224ce2b36edc14cbe01ba0.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2685,
          name: "God of Wealth Hold And Win Mobile",
          uuid: "21ab4985720843ce80f1bf4317515aea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/21ab4985720843ce80f1bf4317515aea.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2722,
          name: "GOLD MAGNATE",
          uuid: "d11b3ed5e2344ccab3e699a592285faa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d11b3ed5e2344ccab3e699a592285faa.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2723,
          name: "GOLD MAGNATE Mobile",
          uuid: "59f1b37c032f409dabfd85b630c5c2f9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/59f1b37c032f409dabfd85b630c5c2f9.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2710,
          name: "GOLD of MINOS",
          uuid: "9e1c73cbbe424aa29b6979c4793632a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/9e1c73cbbe424aa29b6979c4793632a1.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2711,
          name: "GOLD of MINOS Mobile",
          uuid: "92e3ea43f5b2432d9ee74ab55ac72224",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/92e3ea43f5b2432d9ee74ab55ac72224.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2500,
          name: "Gold Rush with Johnny Cash",
          uuid: "d6769fcc63f7783f62f51ca3fd1d14f70cdb77c9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d6769fcc63f7783f62f51ca3fd1d14f70cdb77c9.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2597,
          name: "Gold Rush with Johnny Cash Mobile",
          uuid: "2bd3b2cb742445c3a825e8ec7fdf920f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2bd3b2cb742445c3a825e8ec7fdf920f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2720,
          name: "GRAND PATRON",
          uuid: "fe04e2aeab0342ad90a8f1d052fed9c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/fe04e2aeab0342ad90a8f1d052fed9c6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2721,
          name: "GRAND PATRON Mobile",
          uuid: "832db9247c414ab687f25a92e86279f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/832db9247c414ab687f25a92e86279f4.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2507,
          name: "Halloween Bonanza",
          uuid: "49ff80a3ff8e46d99d2c1c86ab8bca29",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/49ff80a3ff8e46d99d2c1c86ab8bca29.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2604,
          name: "Halloween Bonanza Mobile",
          uuid: "ea156905dd954e01b8ed5c84c6a05d04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ea156905dd954e01b8ed5c84c6a05d04.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2752,
          name: "Haunted Reels",
          uuid: "2f80a9da1eca41e2a35f95763533f5c4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2f80a9da1eca41e2a35f95763533f5c4.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2753,
          name: "Haunted Reels Mobile",
          uuid: "6787227cd7be4ac089dcf7001333f784",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/6787227cd7be4ac089dcf7001333f784.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2445,
          name: "Hawaii Cocktails",
          uuid: "a134cbe88726f82c8e14d9b3a0ea40e9c3076b27",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a134cbe88726f82c8e14d9b3a0ea40e9c3076b27.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2543,
          name: "Hawaii Cocktails Mobile",
          uuid: "6947a3805d564e22a1c951d635b2984c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/6947a3805d564e22a1c951d635b2984c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2446,
          name: "Heads and Tails",
          uuid: "a273119050eb1ff5ec84997066e93f3b478c762e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a273119050eb1ff5ec84997066e93f3b478c762e.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2544,
          name: "Heads and Tails Mobile",
          uuid: "ad5179c0acba48f3bedfb10a85d7c493",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ad5179c0acba48f3bedfb10a85d7c493.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2493,
          name: "Heads and Tails XY",
          uuid: "8a9799c0cdfec1834ef0023b38fc21b07aa71bc6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8a9799c0cdfec1834ef0023b38fc21b07aa71bc6.png",
          type: "instant win",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2590,
          name: "Heads and Tails XY Mobile",
          uuid: "d85dd3851f1841ecba4f523318b6c120",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d85dd3851f1841ecba4f523318b6c120.png",
          type: "instant win",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2447,
          name: "Hello Easter",
          uuid: "0a5acb8757c87fa3d4780494fba8e9e7043eeafd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0a5acb8757c87fa3d4780494fba8e9e7043eeafd.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2545,
          name: "Hello Easter Mobile",
          uuid: "924a353a01614d70b7187d814b731dba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/924a353a01614d70b7187d814b731dba.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2472,
          name: "Hit The Route",
          uuid: "290b80f84e0817aa8ef08cd8d9bdb61473180c47",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/290b80f84e0817aa8ef08cd8d9bdb61473180c47.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2570,
          name: "Hit The Route Mobile",
          uuid: "18bb7664878d4f75a8136ca19ffa7ac6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/18bb7664878d4f75a8136ca19ffa7ac6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2672,
          name: "Hottest 666",
          uuid: "9491e4a28afe44bdb3b78de74ea145f3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/9491e4a28afe44bdb3b78de74ea145f3.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2673,
          name: "Hottest 666 Mobile",
          uuid: "8dde6f238b344d3b8c7020497c7a83f1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/8dde6f238b344d3b8c7020497c7a83f1.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2670,
          name: "Ice Scratch Bronze",
          uuid: "8b75faa75d124c709dfc781e53778d23",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/8b75faa75d124c709dfc781e53778d23.png",
          type: "scratch card",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2671,
          name: "Ice Scratch Bronze Mobile",
          uuid: "0ff5f18ffb2f4493a34e9294a149c341",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/0ff5f18ffb2f4493a34e9294a149c341.png",
          type: "scratch card",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2666,
          name: "Ice Scratch Gold",
          uuid: "c0948062a87e46b0826ec981da4ac04e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/c0948062a87e46b0826ec981da4ac04e.png",
          type: "scratch card",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2667,
          name: "Ice Scratch Gold Mobile",
          uuid: "5f9015f3485e405eb026f7bd5051dce2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/5f9015f3485e405eb026f7bd5051dce2.png",
          type: "scratch card",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2668,
          name: "Ice Scratch Silver",
          uuid: "c8d0a7eea6c0405bafbc8cdec97bc69c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/c8d0a7eea6c0405bafbc8cdec97bc69c.png",
          type: "scratch card",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2669,
          name: "Ice Scratch Silver Mobile",
          uuid: "a2ff89d0d4d74df9b3e6f7d726c7ba39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a2ff89d0d4d74df9b3e6f7d726c7ba39.png",
          type: "scratch card",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2448,
          name: "Jogo Do Bicho",
          uuid: "a1332b8d0bff44b8f75d366c248a02c719ce42cd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a1332b8d0bff44b8f75d366c248a02c719ce42cd.png",
          type: "lottery",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2546,
          name: "Jogo Do Bicho Mobile",
          uuid: "8517737ddd9f4e81b09e1bd63e5d6a82",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/8517737ddd9f4e81b09e1bd63e5d6a82.png",
          type: "lottery",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2471,
          name: "Johnny Cash",
          uuid: "b636ddadc0061919e14b054f9a6d0f0e15a13e0c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b636ddadc0061919e14b054f9a6d0f0e15a13e0c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2569,
          name: "Johnny Cash Mobile",
          uuid: "9098a5c240734a5ca9cce00848927fe2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/9098a5c240734a5ca9cce00848927fe2.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2491,
          name: "Joker Queen",
          uuid: "5a0f40840fe3baad8822fef922f3b5bc64b1c6c0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5a0f40840fe3baad8822fef922f3b5bc64b1c6c0.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2588,
          name: "Joker Queen Mobile",
          uuid: "e031d39bc9794c09977aba3d1dd28b50",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e031d39bc9794c09977aba3d1dd28b50.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2449,
          name: "Journey Flirt",
          uuid: "5c1d2bade9a6125fb4d997c8c912b368e15d65ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5c1d2bade9a6125fb4d997c8c912b368e15d65ae.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2547,
          name: "Journey Flirt Mobile",
          uuid: "e1baecc2b3c3427ebb552e0450a41057",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e1baecc2b3c3427ebb552e0450a41057.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2683,
          name: "Keepers Of The Secret",
          uuid: "2e4ff2da7ed54df1a80a76772d351c11",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2e4ff2da7ed54df1a80a76772d351c11.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2682,
          name: "Keepers Of The Secret Mobile",
          uuid: "e8a69037b6804111b7416f7b71679a47",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e8a69037b6804111b7416f7b71679a47.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2734,
          name: "KRAKEN’S HUNGER",
          uuid: "14e7cc8e63484e11a123d668d4bed412",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/14e7cc8e63484e11a123d668d4bed412.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2735,
          name: "KRAKEN’S HUNGER Mobile",
          uuid: "9d3036e168cd4c9298f63585641e6eee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/9d3036e168cd4c9298f63585641e6eee.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2474,
          name: "Lady Wolf Moon",
          uuid: "f9c3a1c079e058f17f1c3cca44de7bf5ec58ce1c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f9c3a1c079e058f17f1c3cca44de7bf5ec58ce1c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2501,
          name: "Lady Wolf Moon Megaways",
          uuid: "f85e6f269fa8d9bd73ccc3f3555d8b4323eaaa13",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f85e6f269fa8d9bd73ccc3f3555d8b4323eaaa13.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2598,
          name: "Lady Wolf Moon Megaways Mobile",
          uuid: "e2c68c11a67e4e0f908aa31692b944f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e2c68c11a67e4e0f908aa31692b944f4.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2572,
          name: "Lady Wolf Moon Mobile",
          uuid: "1795783ea6df4b9caae497afe4dfeea7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/1795783ea6df4b9caae497afe4dfeea7.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2525,
          name: "Limbo XY",
          uuid: "3faff623986242c4a54dd8ac68e013e2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/3faff623986242c4a54dd8ac68e013e2.png",
          type: "instant game",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2622,
          name: "Limbo XY Mobile",
          uuid: "a419081c5246488092d7ac9e5d14e910",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a419081c5246488092d7ac9e5d14e910.png",
          type: "instant game",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2630,
          name: "Luck & Magic",
          uuid: "554aa26e65bf41a892e311e8cd21ca9b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/554aa26e65bf41a892e311e8cd21ca9b.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2631,
          name: "Luck & Magic Mobile",
          uuid: "d1c8db7ae9124f649bdf8c43811731b3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d1c8db7ae9124f649bdf8c43811731b3.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2705,
          name: "LUCK & MAGIC SCRATCH",
          uuid: "ea4f95993c504b56abf0ea46cca0ea69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ea4f95993c504b56abf0ea46cca0ea69.png",
          type: "scratch card",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2704,
          name: "LUCK & MAGIC SCRATCH Mobile",
          uuid: "7b6328cd69684da6b806f0af16fc292a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/7b6328cd69684da6b806f0af16fc292a.png",
          type: "scratch card",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2692,
          name: "Lucky 8 Merge Up",
          uuid: "e4f72731823b499bb53dc0879c711ba5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e4f72731823b499bb53dc0879c711ba5.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2693,
          name: "Lucky 8 Merge Up Mobile",
          uuid: "ab58a119569f4fa1b6f64794625be389",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ab58a119569f4fa1b6f64794625be389.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2450,
          name: "Lucky Blue",
          uuid: "00346d451fe046d2302e76409cbf6e5bc386a173",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/00346d451fe046d2302e76409cbf6e5bc386a173.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2548,
          name: "Lucky Blue Mobile",
          uuid: "b99a5f0edf31482a850614a70170498f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b99a5f0edf31482a850614a70170498f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2523,
          name: "Lucky Crew",
          uuid: "f97984decf1766535f7b92fd7e0763d57566d5be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f97984decf1766535f7b92fd7e0763d57566d5be.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2620,
          name: "Lucky Crew Mobile",
          uuid: "f4479957966c4fd6bfdcf4df5ca7fc81",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f4479957966c4fd6bfdcf4df5ca7fc81.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2484,
          name: "Lucky Dama Muerta",
          uuid: "80341a1808be2c79d8e7dd2c55f69393b99a10d3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/80341a1808be2c79d8e7dd2c55f69393b99a10d3.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2581,
          name: "Lucky Dama Muerta Mobile",
          uuid: "dd1f7bc2929d43f7a1999d966da28e50",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/dd1f7bc2929d43f7a1999d966da28e50.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2700,
          name: "Lucky Dragon MultiDice X",
          uuid: "dd3490159b134d1f9e00c92373a7763c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/dd3490159b134d1f9e00c92373a7763c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2701,
          name: "Lucky Dragon MultiDice X Mobile",
          uuid: "3f16af850a564f2ebd6348d951a98b5e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/3f16af850a564f2ebd6348d951a98b5e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2492,
          name: "Lucky Farm Bonanza",
          uuid: "19e1043386ad9d774f27a5b7ee377f1d9291dd55",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/19e1043386ad9d774f27a5b7ee377f1d9291dd55.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2589,
          name: "Lucky Farm Bonanza Mobile",
          uuid: "9df401f2f8944c2286166737c32b6ae7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/9df401f2f8944c2286166737c32b6ae7.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2451,
          name: "Lucky Lady's Clover",
          uuid: "ab24bd6272c956a8ed98dba469b6f05d3b4faf2f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ab24bd6272c956a8ed98dba469b6f05d3b4faf2f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2549,
          name: "Lucky Lady's Clover Mobile",
          uuid: "0440fedda20c4480a756d019bb69032f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/0440fedda20c4480a756d019bb69032f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2517,
          name: "Lucky Oak",
          uuid: "8029c0740c674414b695db7e23d24d30",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/8029c0740c674414b695db7e23d24d30.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2614,
          name: "Lucky Oak Mobile",
          uuid: "e72da93026b74541afa38b9470a94a2a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e72da93026b74541afa38b9470a94a2a.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2638,
          name: "Maneki 88 Fortunes",
          uuid: "04136d934a58493aa142778e3a8f1694",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/04136d934a58493aa142778e3a8f1694.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2639,
          name: "Maneki 88 Fortunes Mobile",
          uuid: "6fb817b939b24d0382f03d639a608f9e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/6fb817b939b24d0382f03d639a608f9e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2503,
          name: "Maneki 88 Gold",
          uuid: "ee53f9bd211cd06f43375a55eb203be60f6718a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ee53f9bd211cd06f43375a55eb203be60f6718a1.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2600,
          name: "Maneki 88 Gold Mobile",
          uuid: "36d779de7e0d4a49a43c419259de189c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/36d779de7e0d4a49a43c419259de189c.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2475,
          name: "Mechanical Clover",
          uuid: "ec4d3b3eed970b798f247eb1b6566e0fbc692a03",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ec4d3b3eed970b798f247eb1b6566e0fbc692a03.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2573,
          name: "Mechanical Clover Mobile",
          uuid: "412e56b903324395a5e2f17c5d28e127",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/412e56b903324395a5e2f17c5d28e127.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2652,
          name: "Merge Up",
          uuid: "31f871bb64834a52955d2ebc8e714b09",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/31f871bb64834a52955d2ebc8e714b09.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2653,
          name: "Merge Up Mobile",
          uuid: "62b95c905ede475d96e9d6317d69a6da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/62b95c905ede475d96e9d6317d69a6da.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2642,
          name: "Mice and Magic Wonder Spin",
          uuid: "fbdf6f3ef8224070a950fe29c0132bd5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/fbdf6f3ef8224070a950fe29c0132bd5.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2643,
          name: "Mice and Magic Wonder Spin Mobile",
          uuid: "af1722b963724396becbd785561a12c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/af1722b963724396becbd785561a12c2.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2732,
          name: "MINE GEMS",
          uuid: "d861634fb6584b8b9eb489c9dfc10fcf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d861634fb6584b8b9eb489c9dfc10fcf.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2733,
          name: "MINE GEMS Mobile",
          uuid: "b036b0f7d315428995a1db5c7547dad8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b036b0f7d315428995a1db5c7547dad8.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2452,
          name: "Minesweeper",
          uuid: "34e4dad686571ec3c91fba121215fbc98f117a47",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/34e4dad686571ec3c91fba121215fbc98f117a47.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2550,
          name: "Minesweeper Mobile",
          uuid: "a3b51e91f45e4a0da91b5a9c04b675e2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a3b51e91f45e4a0da91b5a9c04b675e2.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2495,
          name: "Minesweeper XY",
          uuid: "b8d010004f84d5de112ee2ef9bf9badf6e4d31d9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b8d010004f84d5de112ee2ef9bf9badf6e4d31d9.png",
          type: "instant win",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2592,
          name: "Minesweeper XY Mobile",
          uuid: "3dee1a06d89a4438ba5addc9375df69d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/3dee1a06d89a4438ba5addc9375df69d.png",
          type: "instant win",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2482,
          name: "Miss Cherry Fruits",
          uuid: "a84177c80055b8f45bbc8969434d280f5005c797",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a84177c80055b8f45bbc8969434d280f5005c797.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2502,
          name: "Miss Cherry Fruits Jackpot Party",
          uuid: "b674240117e969fb9c2cf43644eb602140e8d34f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b674240117e969fb9c2cf43644eb602140e8d34f.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2599,
          name: "Miss Cherry Fruits Jackpot Party Mobile",
          uuid: "ede37aa52a5540e4b23c07af02c270a6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ede37aa52a5540e4b23c07af02c270a6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2579,
          name: "Miss Cherry Fruits Mobile",
          uuid: "4b98d5a44d0e421e8aff7ac653a893dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/4b98d5a44d0e421e8aff7ac653a893dd.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2644,
          name: "Monster Hunt",
          uuid: "6cef99e9765945fe82f11dfdd87291cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/6cef99e9765945fe82f11dfdd87291cf.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2645,
          name: "Monster Hunt Mobile",
          uuid: "869d14133641461a8e4f557100d6ef57",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/869d14133641461a8e4f557100d6ef57.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2453,
          name: "Multihand Blackjack",
          uuid: "c82de21cc34b6d1e388bba87b0b2b4b2d05c7e69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/c82de21cc34b6d1e388bba87b0b2b4b2d05c7e69.png",
          type: "blackjack",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2551,
          name: "Multihand Blackjack Mobile",
          uuid: "aa5d6a23fff34c399d58cbcc15f94a6a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/aa5d6a23fff34c399d58cbcc15f94a6a.png",
          type: "blackjack",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2433,
          name: "Multihand Blackjack Pro",
          uuid: "27edf85d499c935982b9ced360157b2569a012dc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/27edf85d499c935982b9ced360157b2569a012dc.png",
          type: "blackjack",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2531,
          name: "Multihand Blackjack Pro Mobile",
          uuid: "d4cb89fca0ac4fb1b1bfedbe05676566",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d4cb89fca0ac4fb1b1bfedbe05676566.png",
          type: "blackjack",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2654,
          name: "Mummy's Gold",
          uuid: "b9296855e9444d7e96b3eb67a0c485a4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b9296855e9444d7e96b3eb67a0c485a4.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2655,
          name: "Mummy's Gold Mobile",
          uuid: "4580f1f37f574d59b68c3d606b737465",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/4580f1f37f574d59b68c3d606b737465.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2702,
          name: "OOF The Goldmine Planet",
          uuid: "a5256208ab184f0989e796328ae08d3d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a5256208ab184f0989e796328ae08d3d.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2703,
          name: "OOF The Goldmine Planet Mobile",
          uuid: "5cbb53ad23ff4cb68c9834e4c69ca35e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/5cbb53ad23ff4cb68c9834e4c69ca35e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2708,
          name: "PANDA LUCK",
          uuid: "da1d932b31c540098a94eef5e5dbbe31",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/da1d932b31c540098a94eef5e5dbbe31.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2709,
          name: "PANDA LUCK Mobile",
          uuid: "a76c205355664579aae9c6f6985d1127",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a76c205355664579aae9c6f6985d1127.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2498,
          name: "Penny Pelican",
          uuid: "04bf612d7627c7d26a626c8d70fb99aedac68695",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/04bf612d7627c7d26a626c8d70fb99aedac68695.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2595,
          name: "Penny Pelican Mobile",
          uuid: "b2001dc6da02481e9d8db919bbb432a4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b2001dc6da02481e9d8db919bbb432a4.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2454,
          name: "Platinum Lightning",
          uuid: "463114f1ceab3bc594719226fbd88ab3bfadcb70",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/463114f1ceab3bc594719226fbd88ab3bfadcb70.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2455,
          name: "Platinum Lightning Deluxe",
          uuid: "a3102d669e4265b8068d649f4926b4c9bf800a03",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a3102d669e4265b8068d649f4926b4c9bf800a03.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2553,
          name: "Platinum Lightning Deluxe Mobile",
          uuid: "fffe47c01ced4893b68d97f2039714d0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/fffe47c01ced4893b68d97f2039714d0.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2552,
          name: "Platinum Lightning Mobile",
          uuid: "f727ca1a7a9a489c99530fef9c8c0849",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f727ca1a7a9a489c99530fef9c8c0849.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2456,
          name: "Plinko",
          uuid: "9eee9764948c4c300c1c575b7fdb36d0478a016e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9eee9764948c4c300c1c575b7fdb36d0478a016e.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2554,
          name: "Plinko Mobile",
          uuid: "450639a84701438cb735fd424d2276d9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/450639a84701438cb735fd424d2276d9.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2496,
          name: "Plinko XY",
          uuid: "2dba096b6001e9230e46a4bfa13577f17ebcaf43",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2dba096b6001e9230e46a4bfa13577f17ebcaf43.png",
          type: "instant win",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2593,
          name: "Plinko XY Mobile",
          uuid: "29ffac83089a47abb59e352043e8aaf9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/29ffac83089a47abb59e352043e8aaf9.png",
          type: "instant win",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2690,
          name: "Pop Zen",
          uuid: "1dddeeee58ff4a148ea077279838246a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/1dddeeee58ff4a148ea077279838246a.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2691,
          name: "Pop Zen Mobile",
          uuid: "db59e352f7104763a849ffa3097bce2a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/db59e352f7104763a849ffa3097bce2a.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2515,
          name: "Potion Spells",
          uuid: "f0d9fce0cd9a43dbacf26dd7827f8723",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f0d9fce0cd9a43dbacf26dd7827f8723.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2612,
          name: "Potion Spells Mobile",
          uuid: "190ad3a5aa354a6eadb460178143ed51",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/190ad3a5aa354a6eadb460178143ed51.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2457,
          name: "Princess of Sky",
          uuid: "72e4c5161b98c14e4d5adcd7d1c9b7d910d06dcc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/72e4c5161b98c14e4d5adcd7d1c9b7d910d06dcc.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2555,
          name: "Princess of Sky Mobile",
          uuid: "2af9831cbb1d4dbbb0dae56b90bf7888",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2af9831cbb1d4dbbb0dae56b90bf7888.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2485,
          name: "Road 2 Riches",
          uuid: "c5814c69e2b19e1bc781aed44554c4f4d319e6de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/c5814c69e2b19e1bc781aed44554c4f4d319e6de.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2582,
          name: "Road 2 Riches Mobile",
          uuid: "75c074d54dfd44af905ddd7d3414457e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/75c074d54dfd44af905ddd7d3414457e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2739,
          name: "ROBOSPIN",
          uuid: "356856f76643570e2fe2d474717c3997d1e723c7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/356856f76643570e2fe2d474717c3997d1e723c7.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2738,
          name: "ROBOSPIN Mobile",
          uuid: "53c5639c5473d1beb5b5a7514a1ab62580e07053",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/53c5639c5473d1beb5b5a7514a1ab62580e07053.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2458,
          name: "Rocket Dice",
          uuid: "198afd5d577beee0c6dc9fc91adb82347b18bb83",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/198afd5d577beee0c6dc9fc91adb82347b18bb83.png",
          type: "craps",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2556,
          name: "Rocket Dice Mobile",
          uuid: "880360b2acad4c41a5a7160e66a81fbe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/880360b2acad4c41a5a7160e66a81fbe.png",
          type: "craps",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2494,
          name: "Rocket Dice XY",
          uuid: "bae542065f1bc52a41f9362e0eb161c4814c7c5d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bae542065f1bc52a41f9362e0eb161c4814c7c5d.png",
          type: "instant win",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2591,
          name: "Rocket Dice XY Mobile",
          uuid: "1a89bb9e68e9492b8e4345f8751ed5b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/1a89bb9e68e9492b8e4345f8751ed5b4.png",
          type: "instant win",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2740,
          name: "ROYAL FRUITS MULTILINES",
          uuid: "7d1ba21a53294e808915873c9cc3113a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/7d1ba21a53294e808915873c9cc3113a.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2741,
          name: "ROYAL FRUITS MULTILINES Mobile",
          uuid: "713aa5866ad54c5e9f6e3ed13372b0d8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/713aa5866ad54c5e9f6e3ed13372b0d8.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2522,
          name: "Royal High-Road",
          uuid: "77f520846b60c1bfc4063e7dcb4ed64007a229f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/77f520846b60c1bfc4063e7dcb4ed64007a229f6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2619,
          name: "Royal High-Road Mobile",
          uuid: "d2d1886446f241afb01fe24466aeadbc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d2d1886446f241afb01fe24466aeadbc.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2524,
          name: "Savage Buffalo Spirit",
          uuid: "d1413e8dbb9042c5a54dd8b6be9d5940",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d1413e8dbb9042c5a54dd8b6be9d5940.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2634,
          name: "Savage Buffalo Spirit Megaways",
          uuid: "f833d788553e4bb791bc58e161f9df06",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f833d788553e4bb791bc58e161f9df06.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2635,
          name: "Savage Buffalo Spirit Megaways Mobile",
          uuid: "e061ac27b2a54a06a2a00be988e850a7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/e061ac27b2a54a06a2a00be988e850a7.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2621,
          name: "Savage Buffalo Spirit Mobile",
          uuid: "9f536cb4338a40a499a625503f4d5c29",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/9f536cb4338a40a499a625503f4d5c29.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2650,
          name: "Scratch Alpaca Bronze",
          uuid: "083caf1bcec14a78bc8d5021f9026dd0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/083caf1bcec14a78bc8d5021f9026dd0.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2651,
          name: "Scratch Alpaca Bronze Mobile",
          uuid: "27243599f19441cbaa3f5ecce2e131f5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/27243599f19441cbaa3f5ecce2e131f5.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2646,
          name: "Scratch Alpaca Gold",
          uuid: "337dfc37765e4157a5d5c72982aebd6a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/337dfc37765e4157a5d5c72982aebd6a.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2647,
          name: "Scratch Alpaca Gold Mobile",
          uuid: "4a9b6ac101f74950a259df430de43cfc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/4a9b6ac101f74950a259df430de43cfc.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2648,
          name: "Scratch Alpaca Silver",
          uuid: "21822dddc6cf407fa6618589017d34e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/21822dddc6cf407fa6618589017d34e5.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2649,
          name: "Scratch Alpaca Silver Mobile",
          uuid: "a1ca5c7055ec472b9dbd5c92a36b45ae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a1ca5c7055ec472b9dbd5c92a36b45ae.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2459,
          name: "Scratch Dice",
          uuid: "9360a6055380bf3a837cf5910c993a8734ebafda",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9360a6055380bf3a837cf5910c993a8734ebafda.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2557,
          name: "Scratch Dice Mobile",
          uuid: "957262ffeb154c119c8215aa12dc451c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/957262ffeb154c119c8215aa12dc451c.png",
          type: "casual",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2724,
          name: "Secret Bar Multidice X",
          uuid: "de00afc6ff334eb387364e3b1a071005",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/de00afc6ff334eb387364e3b1a071005.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2725,
          name: "Secret Bar Multidice X Mobile",
          uuid: "f5506cd796d94393accfb232ade297e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f5506cd796d94393accfb232ade297e6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2662,
          name: "Slot Machine",
          uuid: "a3ac391ca11043309899cfac28b85781",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a3ac391ca11043309899cfac28b85781.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2663,
          name: "Slot Machine Mobile",
          uuid: "a08ed262e192422dae672d1dc8ef3693",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a08ed262e192422dae672d1dc8ef3693.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2510,
          name: "Soccermania",
          uuid: "50d4e38fdc4f4f5a8259a80f893c42eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/50d4e38fdc4f4f5a8259a80f893c42eb.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2607,
          name: "Soccermania Mobile",
          uuid: "f85055db22604c9a8b11fe1185f251f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f85055db22604c9a8b11fe1185f251f0.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2487,
          name: "Space XY",
          uuid: "f10da42ab4693d959775180a33b5c7ff51e3cde9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f10da42ab4693d959775180a33b5c7ff51e3cde9.png",
          type: "crash",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2584,
          name: "Space XY Mobile",
          uuid: "4c5660493a8d447ab96361b6425d8a68",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/4c5660493a8d447ab96361b6425d8a68.png",
          type: "crash",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2465,
          name: "Spin and Spell",
          uuid: "2e9eeb62f85b97d63c1a4e21789169cc47ac3021",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2e9eeb62f85b97d63c1a4e21789169cc47ac3021.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2563,
          name: "Spin and Spell Mobile",
          uuid: "93cab26b14fb44b4a92314ffa857b632",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/93cab26b14fb44b4a92314ffa857b632.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2728,
          name: "Street Power",
          uuid: "1b9f0c8d29834c0c81b045d7031554f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/1b9f0c8d29834c0c81b045d7031554f6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2729,
          name: "Street Power Mobile",
          uuid: "a062c9bddea041c2ad326b1d199b3b5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a062c9bddea041c2ad326b1d199b3b5d.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2516,
          name: "Sweet Rush Megaways",
          uuid: "2553d3e6591e4d5ebf2066f5326e91f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2553d3e6591e4d5ebf2066f5326e91f4.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2613,
          name: "Sweet Rush Megaways Mobile",
          uuid: "32fd8a6af21a4847b0faec7e48295c84",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/32fd8a6af21a4847b0faec7e48295c84.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2742,
          name: "Top Eagle",
          uuid: "2cce5a1468e54b29ae4af6e814b76619",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2cce5a1468e54b29ae4af6e814b76619.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2743,
          name: "Top Eagle Mobile",
          uuid: "4f07d401dca64460abd53b848af6f453",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/4f07d401dca64460abd53b848af6f453.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2736,
          name: "TRAIN TO RIO GRANDE",
          uuid: "32fb489136034f359e995aeabe3dd93d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/32fb489136034f359e995aeabe3dd93d.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2737,
          name: "TRAIN TO RIO GRANDE Mobile",
          uuid: "b558339072c3408b9fffa30ac2c80e7e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/b558339072c3408b9fffa30ac2c80e7e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2658,
          name: "Tramp Day",
          uuid: "324ca440d86a4c50a9ce1e9048539dc7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/324ca440d86a4c50a9ce1e9048539dc7.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2659,
          name: "Tramp Day Mobile",
          uuid: "d3ce98616fac4372b77d131846e6fff3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/d3ce98616fac4372b77d131846e6fff3.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2744,
          name: "VOODOO PEOPLE",
          uuid: "37e8319614364842bb3bccb5561ad589",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/37e8319614364842bb3bccb5561ad589.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2745,
          name: "VOODOO PEOPLE Mobile",
          uuid: "ca25399f68d54a2da9ce0b8b753c19b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ca25399f68d54a2da9ce0b8b753c19b8.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2477,
          name: "WBC Ring of Riches",
          uuid: "948aed1bd075aa1ed5760ddfde3d1a51afaa4478",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/948aed1bd075aa1ed5760ddfde3d1a51afaa4478.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2575,
          name: "WBC Ring of Riches Mobile",
          uuid: "73c2fc07d4f7464caab9a342566d4be9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/73c2fc07d4f7464caab9a342566d4be9.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2460,
          name: "West Town",
          uuid: "fdf003ed00a77f12ab7e2a50ec2b4dcf786ddc86",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fdf003ed00a77f12ab7e2a50ec2b4dcf786ddc86.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2558,
          name: "West Town Mobile",
          uuid: "9cbf7c2d4c0b403a9d07ca97b535e6cb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/9cbf7c2d4c0b403a9d07ca97b535e6cb.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2497,
          name: "Wild Cash",
          uuid: "de78435a51512fbbc79c47a3bfea990c26a4ec9e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/de78435a51512fbbc79c47a3bfea990c26a4ec9e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2636,
          name: "Wild Cash Dice",
          uuid: "8d99f44766bfc05144c50361bfcf85e75ef2dce8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/8d99f44766bfc05144c50361bfcf85e75ef2dce8.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2637,
          name: "Wild Cash Dice Mobile",
          uuid: "2e3c0a9a7485c871798c9b2143e7663d4f3bffef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/2e3c0a9a7485c871798c9b2143e7663d4f3bffef.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2594,
          name: "Wild Cash Mobile",
          uuid: "ac9412a199d94b3ebf645c902270cfa1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ac9412a199d94b3ebf645c902270cfa1.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2504,
          name: "Wild Cash X9990",
          uuid: "ff27475b13130665f52993ba939d092bc53b8a60",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ff27475b13130665f52993ba939d092bc53b8a60.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2601,
          name: "Wild Cash X9990 Mobile",
          uuid: "64c66cdafd8141c596bc0286d6ba803a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/64c66cdafd8141c596bc0286d6ba803a.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2527,
          name: "Wild Chicago",
          uuid: "fa3a7a3de0434788dc42459414bcad3950fbb692",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/fa3a7a3de0434788dc42459414bcad3950fbb692.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2624,
          name: "Wild Chicago Mobile",
          uuid: "5adfdb3538034bbb8906978df093431e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/5adfdb3538034bbb8906978df093431e.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2687,
          name: "Wild Heart",
          uuid: "a74d32b104014a0b958c8d8d8ce68b2b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/a74d32b104014a0b958c8d8d8ce68b2b.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2686,
          name: "Wild Heart Mobile",
          uuid: "3afeedf69fb14e19b0f2e8b7270206ab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/3afeedf69fb14e19b0f2e8b7270206ab.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2680,
          name: "Wild Tiger",
          uuid: "3291607b2d4f442bbd38adb1b28003bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/3291607b2d4f442bbd38adb1b28003bb.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2681,
          name: "Wild Tiger Mobile",
          uuid: "f01f531395d24bb2bdf5cb8cb16499a6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f01f531395d24bb2bdf5cb8cb16499a6.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2727,
          name: "WILD WEST TRUEWAYS",
          uuid: "ebe66d39d0af2cbbce8891423b081dca2fa1d3aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/ebe66d39d0af2cbbce8891423b081dca2fa1d3aa.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2726,
          name: "WILD WEST TRUEWAYS Mobile",
          uuid: "f7bb9da60ebd01af38c1fa7d1dbc4d204f8cf5a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/772c50b6e70a91f89f0266e2ebfb9992/BGaming/f7bb9da60ebd01af38c1fa7d1dbc4d204f8cf5a5.png",
          type: "slots",
          provider: "BGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 111,
      name: "Блюпринт",
      providerId: 21,
      providerName: "Blueprint",
      games: [
        {
          game_id: 2960,
          name: "5 Pots O’Riches",
          uuid: "d415474668a113a5c98b619699444f1790692ad6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d415474668a113a5c98b619699444f1790692ad6.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2961,
          name: "5 Pots O’Riches Mobile",
          uuid: "25ff038ecd413546a96bba03f3a1c8da7dbb8be9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/25ff038ecd413546a96bba03f3a1c8da7dbb8be9.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3059,
          name: "777 Deluxe",
          uuid: "515fc4e58e8e4084b46f23d50f355ecb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/515fc4e58e8e4084b46f23d50f355ecb.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 3058,
          name: "777 Deluxe Mobile",
          uuid: "497c93bb07954dd89a2fc52227b9384b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/497c93bb07954dd89a2fc52227b9384b.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2892,
          name: "7s Deluxe",
          uuid: "5b540f80a6011d8acde7f2e93ecb7fe121d5a9e9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5b540f80a6011d8acde7f2e93ecb7fe121d5a9e9.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2893,
          name: "7s Deluxe Mobile",
          uuid: "8fda0f541a81834fe1d97de3ab04e911efc4527a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8fda0f541a81834fe1d97de3ab04e911efc4527a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2972,
          name: "7s Deluxe Wild Fortune Play",
          uuid: "efac1aa99452cf343ad159fbbf0347c76fcfd00e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/efac1aa99452cf343ad159fbbf0347c76fcfd00e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2973,
          name: "7s Deluxe Wild Fortune Play Mobile",
          uuid: "8ab5c1c4ddf87a1076a3eecc198350fc26f1fc01",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8ab5c1c4ddf87a1076a3eecc198350fc26f1fc01.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3082,
          name: "Almighty Bear Megaways",
          uuid: "16057ee206254c6c9870ba8e5b12c65e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/16057ee206254c6c9870ba8e5b12c65e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3083,
          name: "Almighty Bear Megaways Mobile",
          uuid: "937ba04cd3a74151b4c13ec371e3ef85",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/937ba04cd3a74151b4c13ec371e3ef85.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2998,
          name: "Ballin",
          uuid: "22502fa69e2543289de8b943874493cd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/22502fa69e2543289de8b943874493cd.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2999,
          name: "Ballin Mobile",
          uuid: "38113ae89f88465ea39f3b56cfd5f68d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/38113ae89f88465ea39f3b56cfd5f68d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2942,
          name: "Bankin' Bacon",
          uuid: "64fe8c660a030a523dc6f9ba8e631ec5b5205808",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/64fe8c660a030a523dc6f9ba8e631ec5b5205808.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2943,
          name: "Bankin' Bacon Mobile",
          uuid: "516bfde38815308b0df1b37c755baa9414551468",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/516bfde38815308b0df1b37c755baa9414551468.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3057,
          name: "Bankin’ More Bacon",
          uuid: "f42f9940f9a022dbc6994aef95d6c43438183616",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/f42f9940f9a022dbc6994aef95d6c43438183616.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3056,
          name: "Bankin’ More Bacon Mobile",
          uuid: "ee2e0a95a435a2a779b17474056cc18aa968130c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/ee2e0a95a435a2a779b17474056cc18aa968130c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2858,
          name: "Bar X Safe Cracker Megaways",
          uuid: "4563f89aefcd2e5a7d1d9aa7b705ba226758525f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4563f89aefcd2e5a7d1d9aa7b705ba226758525f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2859,
          name: "Bar X Safe Cracker Megaways Mobile",
          uuid: "b2ac89593f13cf19074d02f9cf25d18ae1a4bedd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b2ac89593f13cf19074d02f9cf25d18ae1a4bedd.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2912,
          name: "Big Cat King Megaways",
          uuid: "36a474047d0f47f413e7ca60f2c6a12838f9d331",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/36a474047d0f47f413e7ca60f2c6a12838f9d331.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2913,
          name: "Big Cat King Megaways Mobile",
          uuid: "a4f576ca22c127221f7e8005cb65fb5a1b7f4f38",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a4f576ca22c127221f7e8005cb65fb5a1b7f4f38.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3022,
          name: "Big Catch Bass Fishing",
          uuid: "1bbd040600d64014b824c43a48efe33a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/1bbd040600d64014b824c43a48efe33a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3036,
          name: "Big Catch Bass Fishing Megaways",
          uuid: "444dbc95364045be8ab766902d8c486e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/444dbc95364045be8ab766902d8c486e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3037,
          name: "Big Catch Bass Fishing Megaways Mobile",
          uuid: "810be0ff31184ccb82cceb66a50d6e92",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/810be0ff31184ccb82cceb66a50d6e92.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3023,
          name: "Big Catch Bass Fishing Mobile",
          uuid: "fe78a0b2687e425496c0f19b643630bd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/fe78a0b2687e425496c0f19b643630bd.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2920,
          name: "Big Money Frenzy",
          uuid: "f1876876af30d1a0dc96437c333a985d41a8d73f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f1876876af30d1a0dc96437c333a985d41a8d73f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2921,
          name: "Big Money Frenzy Mobile",
          uuid: "d6349047a0691d8daa2e2e482d74911d137eace8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d6349047a0691d8daa2e2e482d74911d137eace8.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3051,
          name: "Big Money Megaways",
          uuid: "1d8593aea3e34ec854e0b555a4f9fc005c00e814",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/1d8593aea3e34ec854e0b555a4f9fc005c00e814.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3050,
          name: "Big Money Megaways Mobile",
          uuid: "e287525f33eb782e5a99c1c88607c96a3409b6ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/e287525f33eb782e5a99c1c88607c96a3409b6ef.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3086,
          name: "Bigger Catch Bass Fishing",
          uuid: "99388324c71d401a91250db80fa68a15",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/99388324c71d401a91250db80fa68a15.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3087,
          name: "Bigger Catch Bass Fishing Mobile",
          uuid: "650434b2215a410d899a353e2ad91115",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/650434b2215a410d899a353e2ad91115.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2956,
          name: "Bison Bonanza",
          uuid: "d1d04d625106ff4987dc79e688eb1882711ec579",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d1d04d625106ff4987dc79e688eb1882711ec579.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2957,
          name: "Bison Bonanza Mobile",
          uuid: "4a0b036aa9e46d56b7f5d4f8f3f77d956694d9a0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4a0b036aa9e46d56b7f5d4f8f3f77d956694d9a0.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3008,
          name: "Bison Rising Megaways Build a Bonus",
          uuid: "818883397aed4407a62303e1d84d0ba2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/818883397aed4407a62303e1d84d0ba2.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3009,
          name: "Bison Rising Megaways Build a Bonus Mobile",
          uuid: "ce64c3f38a66410b8af318ca170601fc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/ce64c3f38a66410b8af318ca170601fc.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3046,
          name: "Bison Rising: Reloaded",
          uuid: "83afd2a7e41446a79e27a070604940f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/83afd2a7e41446a79e27a070604940f0.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3047,
          name: "Bison Rising: Reloaded Mobile",
          uuid: "08d03b3f4ba94c32a85a5da074cfb164",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/08d03b3f4ba94c32a85a5da074cfb164.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2754,
          name: "Blazing Star",
          uuid: "f5993665d2383a4b4305405d46c7a069f8a2e15e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f5993665d2383a4b4305405d46c7a069f8a2e15e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2817,
          name: "Blazing Star Mobile",
          uuid: "630a21e253a77b16f7486aec22da65951b204be4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/630a21e253a77b16f7486aec22da65951b204be4.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2968,
          name: "Bon Bomb Luxpots Megaways",
          uuid: "b1db5a2fc188606312178b30cc8ac4ba7cd8cfb9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b1db5a2fc188606312178b30cc8ac4ba7cd8cfb9.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2969,
          name: "Bon Bomb Luxpots Megaways Mobile",
          uuid: "49990ac9e5727491f2b5d635b65732eff9562943",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/49990ac9e5727491f2b5d635b65732eff9562943.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3055,
          name: "Bounty Hunter Unchained",
          uuid: "b02d02f467ecc5c15545ef0a20b25f216640a2a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/b02d02f467ecc5c15545ef0a20b25f216640a2a5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3054,
          name: "Bounty Hunter Unchained Mobile",
          uuid: "2a321be162ba8a9a54014ed2e78e6385b6f96648",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/2a321be162ba8a9a54014ed2e78e6385b6f96648.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2824,
          name: "Buffalo Rising Megaways",
          uuid: "70aa6c3a7fd1d43a2b4bb6eb8886804258ff9c90",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/70aa6c3a7fd1d43a2b4bb6eb8886804258ff9c90.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2882,
          name: "Buffalo Rising Megaways All Action",
          uuid: "06ec380375d5ebcc963033582bdc1051172d0ea1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/06ec380375d5ebcc963033582bdc1051172d0ea1.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2883,
          name: "Buffalo Rising Megaways All Action Mobile",
          uuid: "5f886cab38872dc37292337114828a8f50eb95b8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5f886cab38872dc37292337114828a8f50eb95b8.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2825,
          name: "Buffalo Rising Megaways Mobile",
          uuid: "e9113643bea6e644b5f04f4772d78460d32d2cda",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e9113643bea6e644b5f04f4772d78460d32d2cda.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3053,
          name: "Cash Strike",
          uuid: "961f6f5e15cced63c948820df030f21bc622cd09",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/961f6f5e15cced63c948820df030f21bc622cd09.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3075,
          name: "Cash Strike Hotstepper",
          uuid: "01ccda2230963c04141e0a21b4651cb936d90564",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/01ccda2230963c04141e0a21b4651cb936d90564.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3074,
          name: "Cash Strike Hotstepper Mobile",
          uuid: "2cc7253b058cd7b548cdec7a12f2544477324483",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/2cc7253b058cd7b548cdec7a12f2544477324483.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3052,
          name: "Cash Strike Mobile",
          uuid: "5a63c973a9e5e073313d70f8d1a1a9d8fe986faf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/5a63c973a9e5e073313d70f8d1a1a9d8fe986faf.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3093,
          name: "Cash Strike Power 5",
          uuid: "db03ab23e14dbef3265efcfd5d762a0787ce82ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/db03ab23e14dbef3265efcfd5d762a0787ce82ec.jpg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3092,
          name: "Cash Strike Power 5 Mobile",
          uuid: "5e3aaaa37cdd285212069c295ae624da5a3bd90f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/5e3aaaa37cdd285212069c295ae624da5a3bd90f.jpg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3073,
          name: "Cashpots Blazinator",
          uuid: "a89d9164435648b6adc97c7fcb24f206",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/a89d9164435648b6adc97c7fcb24f206.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3072,
          name: "Cashpots Blazinator Mobile",
          uuid: "821d38d86db6467b880035aa1a2ac7dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/821d38d86db6467b880035aa1a2ac7dc.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2952,
          name: "Chilli Picante Megaways",
          uuid: "359c8891e24a89d925d240aba9c3b56c8618cb29",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/359c8891e24a89d925d240aba9c3b56c8618cb29.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2953,
          name: "Chilli Picante Megaways Mobile",
          uuid: "b04831c14f454b44c8168c1cdde3ee0a6de7190d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b04831c14f454b44c8168c1cdde3ee0a6de7190d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2755,
          name: "Convertus Aurum",
          uuid: "a3034aff97f4975a4a5ee9cac1e1b0c32e665110",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a3034aff97f4975a4a5ee9cac1e1b0c32e665110.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2803,
          name: "Convertus Aurum Mobile",
          uuid: "fad7e9d2c2a8b14228c531a4544b2148579bbff9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fad7e9d2c2a8b14228c531a4544b2148579bbff9.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3010,
          name: "Crabbin For Cash Extra Big Catch",
          uuid: "e304bcf18fe54d7795effad9bb326866",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/e304bcf18fe54d7795effad9bb326866.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3011,
          name: "Crabbin For Cash Extra Big Catch Mobile",
          uuid: "4133663f064a4cb98f63f6a222f269bd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/4133663f064a4cb98f63f6a222f269bd.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3060,
          name: "Crabbin' for Cash Megaways",
          uuid: "4c4e92b60af0fc3790c665832b31f5aaa93da9a7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/4c4e92b60af0fc3790c665832b31f5aaa93da9a7.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3061,
          name: "Crabbin' for Cash Megaways Mobile",
          uuid: "e80bf2eb03fcdb81c33874c7e8b6b61731e2a393",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/e80bf2eb03fcdb81c33874c7e8b6b61731e2a393.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3032,
          name: "Crabbin’ for Cash: Extra Big Splash",
          uuid: "f29025297504431ab219437de55d4f46",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/f29025297504431ab219437de55d4f46.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3033,
          name: "Crabbin’ for Cash: Extra Big Splash Mobile",
          uuid: "57b36005e898414482c338bacdd13b16",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/57b36005e898414482c338bacdd13b16.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3044,
          name: "Crabbin’ for Christmas",
          uuid: "a713784aaf2644308fc59c5e9b5ae708",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/a713784aaf2644308fc59c5e9b5ae708.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3045,
          name: "Crabbin’ for Christmas Mobile",
          uuid: "e3148efd98bf4fa3af13d3e5b80fef26",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/e3148efd98bf4fa3af13d3e5b80fef26.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2896,
          name: "Crown Gems",
          uuid: "813a3c6f48015d14377c21bea228dcfee324e347",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/813a3c6f48015d14377c21bea228dcfee324e347.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2897,
          name: "Crown Gems Mobile",
          uuid: "6a88b12bc42126cc0d9538807d29b5d746336f7f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6a88b12bc42126cc0d9538807d29b5d746336f7f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2928,
          name: "Crypt of the Dead",
          uuid: "14b4118abf097692d6e911dd15a3e3e6550567ca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/14b4118abf097692d6e911dd15a3e3e6550567ca.jpeg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2929,
          name: "Crypt of the Dead Mobile",
          uuid: "d8bd41df406221301588c4a5b6896b9886eb274d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/d8bd41df406221301588c4a5b6896b9886eb274d.jpeg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2997,
          name: "D10s Maradona",
          uuid: "0ef6ddfea82744ee9537fd4e74893dfa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/0ef6ddfea82744ee9537fd4e74893dfa.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2996,
          name: "D10s Maradona Mobile",
          uuid: "9fa194776aee4d73a4e4a84efbe9a6d1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/9fa194776aee4d73a4e4a84efbe9a6d1.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3024,
          name: "Diamond Mine 2",
          uuid: "e7695e34cc2344ad8ebd9217c2a3bbbb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/e7695e34cc2344ad8ebd9217c2a3bbbb.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3025,
          name: "Diamond Mine 2 Mobile",
          uuid: "013fd1940c4041c7a8a2d577e1682d63",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/013fd1940c4041c7a8a2d577e1682d63.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2936,
          name: "Diamond Mine Boost Mode",
          uuid: "a98956ca113b7dd3c7d36e46d6f8e0df029a6be9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a98956ca113b7dd3c7d36e46d6f8e0df029a6be9.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2937,
          name: "Diamond Mine Boost Mode Mobile",
          uuid: "42a63993bd98fa7348b2ae989471822da7810ca5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/42a63993bd98fa7348b2ae989471822da7810ca5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2886,
          name: "Diamond Mine Extra Gold",
          uuid: "f97554a7e482889bc27c2ee5a092f0f37d16a076",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f97554a7e482889bc27c2ee5a092f0f37d16a076.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2884,
          name: "Diamond Mine Extra Gold All Action",
          uuid: "64c734716177bd48a490c9c7bda541b1a61b9d44",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/64c734716177bd48a490c9c7bda541b1a61b9d44.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2885,
          name: "Diamond Mine Extra Gold All Action Mobile",
          uuid: "e1e34ba91bd12cc46335f21bc802db2cf6e2f0df",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e1e34ba91bd12cc46335f21bc802db2cf6e2f0df.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2887,
          name: "Diamond Mine Extra Gold Mobile",
          uuid: "aa4e42e6b1b007f588f14d52062751142a444f42",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aa4e42e6b1b007f588f14d52062751142a444f42.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2756,
          name: "Diamond Mine Megaways",
          uuid: "c8ec93ebbb0ea1269cb7046149d68217e76b93c1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c8ec93ebbb0ea1269cb7046149d68217e76b93c1.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2809,
          name: "Diamond Mine Megaways Mobile",
          uuid: "e059343dd1ca57115355339680bc0d375dec93ef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e059343dd1ca57115355339680bc0d375dec93ef.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2757,
          name: "Double Triple Chance",
          uuid: "9ac9f73177bf3098c1ad10e0daca3b220306fa8c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9ac9f73177bf3098c1ad10e0daca3b220306fa8c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2802,
          name: "Double Triple Chance Mobile",
          uuid: "ef0a664a3fc37203452dea43a8a033271c4c74da",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ef0a664a3fc37203452dea43a8a033271c4c74da.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2866,
          name: "Dragon Fall",
          uuid: "89aeec32a9b0b3bb75b96f70f779079b156bba80",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/89aeec32a9b0b3bb75b96f70f779079b156bba80.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2867,
          name: "Dragon Fall mobile",
          uuid: "9d1d2dd7ce882db8b2eeaaa3a4ebdb00e1d7425a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9d1d2dd7ce882db8b2eeaaa3a4ebdb00e1d7425a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2758,
          name: "Dragons Treasure",
          uuid: "6d0e160446631fdc0aed0974c9c2563d4f5afdd5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6d0e160446631fdc0aed0974c9c2563d4f5afdd5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2795,
          name: "Dragons Treasure Mobile",
          uuid: "f170407ca0eec8444898396040d8bc25f774d7f8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f170407ca0eec8444898396040d8bc25f774d7f8.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3088,
          name: "Eagle Storm",
          uuid: "14f66e4925594a229e6c8aadb921170c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/14f66e4925594a229e6c8aadb921170c.jpg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3089,
          name: "Eagle Storm Mobile",
          uuid: "18158a361b054cf4ae9d463b5af49f51",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/18158a361b054cf4ae9d463b5af49f51.jpg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2759,
          name: "El Jackpotto",
          uuid: "7ed494f3b0e0134dde10e6ae061142e753cd5d8a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7ed494f3b0e0134dde10e6ae061142e753cd5d8a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2799,
          name: "El Jackpotto Mobile",
          uuid: "32af43876192dda1297b26fb2f5e5753bcbc2363",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/32af43876192dda1297b26fb2f5e5753bcbc2363.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2826,
          name: "El Torero",
          uuid: "a6509427dfd81b3cbff0d74b565ebaafc95a01dc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a6509427dfd81b3cbff0d74b565ebaafc95a01dc.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2827,
          name: "El Torero Mobile",
          uuid: "3695826321ddaed1e42aa35dfb5a725a7e2451fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3695826321ddaed1e42aa35dfb5a725a7e2451fb.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2940,
          name: "Eternal Phoenix Megaways",
          uuid: "4dc3a1c60ea206c522b5121206461caf9a54218a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4dc3a1c60ea206c522b5121206461caf9a54218a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2941,
          name: "Eternal Phoenix Megaways Mobile",
          uuid: "c0a8e448390d381d98ded7dd3b53db52e1b08900",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c0a8e448390d381d98ded7dd3b53db52e1b08900.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2761,
          name: "Extra Wild",
          uuid: "83b0ddcf989725478eea243c691d9d0716ddfff1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/83b0ddcf989725478eea243c691d9d0716ddfff1.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2801,
          name: "Extra Wild Mobile",
          uuid: "e81999dba688f4e0a1836440d3688b9eab9ca427",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e81999dba688f4e0a1836440d3688b9eab9ca427.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2926,
          name: "Eye of Dead",
          uuid: "f92cd02c73f7c7441ba99836c7a0a1a18de4a4b4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f92cd02c73f7c7441ba99836c7a0a1a18de4a4b4.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2927,
          name: "Eye of Dead Mobile",
          uuid: "aa172d8180390d90349b365b6898c48929d6c95c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aa172d8180390d90349b365b6898c48929d6c95c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2760,
          name: "Eye Of Horus",
          uuid: "c418dba0db9b5b9de0d39add9f3aa4d9c76530c2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c418dba0db9b5b9de0d39add9f3aa4d9c76530c2.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2962,
          name: "Eye of Horus Fortune Play",
          uuid: "a5474819a03f2736a654182dd376732cc4e599e8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a5474819a03f2736a654182dd376732cc4e599e8.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2963,
          name: "Eye of Horus Fortune Play Mobile",
          uuid: "18a619353c54d2a6ddc2acb060c9281e1032e8c8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/18a619353c54d2a6ddc2acb060c9281e1032e8c8.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2864,
          name: "Eye of Horus Megaways",
          uuid: "02a8c7220689ecbbcd715998dc9299b504292512",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/02a8c7220689ecbbcd715998dc9299b504292512.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2865,
          name: "Eye of Horus Megaways Mobile",
          uuid: "51096a4bffa66b0c1f3a6ddd50d3d51325270e89",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/51096a4bffa66b0c1f3a6ddd50d3d51325270e89.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2807,
          name: "Eye Of Horus Mobile",
          uuid: "608d720a0c7d408d024105e2e1c899d307252945",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/608d720a0c7d408d024105e2e1c899d307252945.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3014,
          name: "Eye of Horus The Golden Tablet Megaways",
          uuid: "4e9ddc5f4a8a4efda17dc22695ca28c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/4e9ddc5f4a8a4efda17dc22695ca28c6.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3015,
          name: "Eye of Horus The Golden Tablet Megaways Mobile",
          uuid: "68b1267aad2340388fea0c8140f56e04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/68b1267aad2340388fea0c8140f56e04.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3038,
          name: "Eye of Horus: Rise of Egypt",
          uuid: "757506f38e7a4560a2d99d369c3cc550",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/757506f38e7a4560a2d99d369c3cc550.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3039,
          name: "Eye of Horus: Rise of Egypt Mobile",
          uuid: "ae7d1cb4fe8d4d70b190193183150afe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/ae7d1cb4fe8d4d70b190193183150afe.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3004,
          name: "Fishin Frenzy Even Bigger Catch",
          uuid: "4f8d5f079e7e42839069bf6a92ae8213",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/4f8d5f079e7e42839069bf6a92ae8213.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3005,
          name: "Fishin Frenzy Even Bigger Catch Mobile",
          uuid: "b08443a1c6474063927220a82dc8c80f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/b08443a1c6474063927220a82dc8c80f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2848,
          name: "Fishin Frenzy Megaways",
          uuid: "cb1928188895c5a7fa72ad8017b0d6eda6996153",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cb1928188895c5a7fa72ad8017b0d6eda6996153.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2849,
          name: "Fishin Frenzy Megaways Mob",
          uuid: "1bc68644228f8f20710d01aad4b759ff21d30985",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1bc68644228f8f20710d01aad4b759ff21d30985.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2910,
          name: "Fishin Frenzy Power 4 Slots",
          uuid: "6334ee900e44b9eac14efd2c846cf94c11edfe2c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6334ee900e44b9eac14efd2c846cf94c11edfe2c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2911,
          name: "Fishin Frenzy Power 4 Slots Mobile",
          uuid: "050f50ec9e3ded1e889ce9477368a98e341f0fe1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/050f50ec9e3ded1e889ce9477368a98e341f0fe1.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2981,
          name: "Fishin Frenzy Reel Time Fortune Play",
          uuid: "5130a6d8d43a8e582ea7e00935339a9c83346237",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/5130a6d8d43a8e582ea7e00935339a9c83346237.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2980,
          name: "Fishin Frenzy Reel Time Fortune Play Mobile",
          uuid: "294d8a4d18b398e5c309c4c5b370bf26ba7a5585",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/294d8a4d18b398e5c309c4c5b370bf26ba7a5585.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2932,
          name: "Fishin' Frenzy Prize Lines",
          uuid: "836076ab768c0052ce0f1aa8e3301c6ccb98d94b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/836076ab768c0052ce0f1aa8e3301c6ccb98d94b.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2933,
          name: "Fishin' Frenzy Prize Lines Mobile",
          uuid: "d3cf1774eab84b16388396c1cd33bb5c24d6108c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d3cf1774eab84b16388396c1cd33bb5c24d6108c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2762,
          name: "Fishing Frenzy",
          uuid: "3c8f0ec0a4b2811ab6f4bbc0c9f83773c765ed9a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3c8f0ec0a4b2811ab6f4bbc0c9f83773c765ed9a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2790,
          name: "Fishing Frenzy Mobile",
          uuid: "06d813d9575b5b9179bf8df6a7a73ad3f3c140d2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/06d813d9575b5b9179bf8df6a7a73ad3f3c140d2.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2970,
          name: "Fishing Frenzy The Big Catch",
          uuid: "9f929732e226baf93d02c8c8f875f73c1de1cef6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9f929732e226baf93d02c8c8f875f73c1de1cef6.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2971,
          name: "Fishing Frenzy The Big Catch Mobile",
          uuid: "7d9cd0e084725e46a4b0148cc5a1a0c26fe724e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7d9cd0e084725e46a4b0148cc5a1a0c26fe724e3.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2950,
          name: "Fishin’ Frenzy The Big Catch Megaways",
          uuid: "3651dd5fe575a937cd2a3130068c976ae5883b12",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3651dd5fe575a937cd2a3130068c976ae5883b12.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2951,
          name: "Fishin’ Frenzy The Big Catch Megaways Mobile",
          uuid: "4e2320ec89ada3e1e13f00e2380125d230933e7e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4e2320ec89ada3e1e13f00e2380125d230933e7e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3018,
          name: "Fishin’ Frenzy The Big Splash",
          uuid: "0aa0b3465989406688108c40bf5905c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/0aa0b3465989406688108c40bf5905c6.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3019,
          name: "Fishin’ Frenzy The Big Splash Mobile",
          uuid: "63df8e8640394bd09e8279bd91e701f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/63df8e8640394bd09e8279bd91e701f0.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2764,
          name: "Fortunes of Sparta",
          uuid: "ad15c2d880009f34827c08216cc335831891343c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ad15c2d880009f34827c08216cc335831891343c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2804,
          name: "Fortunes of Sparta Mobile",
          uuid: "881cdb66a0443bbc0acc5a02febe361e98416056",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/881cdb66a0443bbc0acc5a02febe361e98416056.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2954,
          name: "Fruitopolis Fortune Play",
          uuid: "6e06ccc0e8eb8f7a72136638361d225063673657",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6e06ccc0e8eb8f7a72136638361d225063673657.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2955,
          name: "Fruitopolis Fortune Play Mobile",
          uuid: "3296a48e9640d292f250dabe0f3c20a8988e0dab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3296a48e9640d292f250dabe0f3c20a8988e0dab.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3040,
          name: "Full Moon Fever",
          uuid: "67ea115399ce410a9980374b11188ea8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/67ea115399ce410a9980374b11188ea8.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3041,
          name: "Full Moon Fever Mobile",
          uuid: "c9e7d9812cde46888137ac055755d07d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/c9e7d9812cde46888137ac055755d07d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3034,
          name: "Funky Buddha",
          uuid: "537c47df822f491bac9c4ba450149dfc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/537c47df822f491bac9c4ba450149dfc.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3035,
          name: "Funky Buddha Mobile",
          uuid: "2134ec1554bc47c5961bdfee6bcc1ada",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/2134ec1554bc47c5961bdfee6bcc1ada.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2765,
          name: "Genie Jackpots Megaways",
          uuid: "c1ea9f50907ad04b2b1ccf6320f1d97b9a71537e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c1ea9f50907ad04b2b1ccf6320f1d97b9a71537e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2806,
          name: "Genie Jackpots Megaways Mobile",
          uuid: "b40b40020df14f088765cc4dba6addf2e6d61eb6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b40b40020df14f088765cc4dba6addf2e6d61eb6.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2908,
          name: "Genie Jackpots Wishmaker",
          uuid: "db81382589cb6c96b93b69fcc62465f02bc48079",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/db81382589cb6c96b93b69fcc62465f02bc48079.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2909,
          name: "Genie Jackpots Wishmaker Mobile",
          uuid: "6893bfdb16c02242956acd8b46a759b1ce0704c5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6893bfdb16c02242956acd8b46a759b1ce0704c5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2977,
          name: "Genie Jackpots: Big Spin Frenzy",
          uuid: "e388ff3f3faa485283187eba104c4198",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/e388ff3f3faa485283187eba104c4198.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2976,
          name: "Genie Jackpots: Big Spin Frenzy Mobile",
          uuid: "6660d1420142429098b499cf9a026d65",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/6660d1420142429098b499cf9a026d65.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2868,
          name: "Ghost Slider",
          uuid: "a810bf018b206260b53d6491f9bf76512ee45311",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a810bf018b206260b53d6491f9bf76512ee45311.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2869,
          name: "Ghost Slider Mobile",
          uuid: "3c7322188452eba3b73b69aded9ca2993e2431e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3c7322188452eba3b73b69aded9ca2993e2431e4.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2766,
          name: "Gold Cup",
          uuid: "82c75fe9580230cfbd01d10597d851399d133157",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/82c75fe9580230cfbd01d10597d851399d133157.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2800,
          name: "Gold Cup Mobile",
          uuid: "b228f12eafc8fbc625b6a0d87720d464091b19dc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b228f12eafc8fbc625b6a0d87720d464091b19dc.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2870,
          name: "Gold Frenzy",
          uuid: "0c440d3ce8449c83b0edeffa40c351bbc0725dcc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0c440d3ce8449c83b0edeffa40c351bbc0725dcc.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2871,
          name: "Gold Frenzy Mobile",
          uuid: "457f8ae5c631095455af2fdac0b41933ea66d4c1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/457f8ae5c631095455af2fdac0b41933ea66d4c1.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2995,
          name: "Gold Strike Bonanza",
          uuid: "97c06b2225c74e4b83d8d6e9095ad1d9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/97c06b2225c74e4b83d8d6e9095ad1d9.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2966,
          name: "Gold Strike Bonanza Fortune Play",
          uuid: "6361795c80af6a84e7ff5751f3460d5a2c74db27",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6361795c80af6a84e7ff5751f3460d5a2c74db27.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2967,
          name: "Gold Strike Bonanza Fortune Play Mobile",
          uuid: "ef79e9d940a39e5b32113954a037907fd2d6933a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ef79e9d940a39e5b32113954a037907fd2d6933a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2994,
          name: "Gold Strike Bonanza Mobile",
          uuid: "9e763576c58f406a8f48baf153793bee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/9e763576c58f406a8f48baf153793bee.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2876,
          name: "Gong-Hei Gong-Hei",
          uuid: "10ed320be97b4fe5700f71d665ca04d048d7fdc5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/10ed320be97b4fe5700f71d665ca04d048d7fdc5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2877,
          name: "Gong-Hei Gong-Hei Mobile",
          uuid: "fa25309bfcda2afe24bf9abbe2bc4a86c9e038ad",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fa25309bfcda2afe24bf9abbe2bc4a86c9e038ad.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2888,
          name: "Gorilla Gold Megaways",
          uuid: "0d8328e53e99092414648c16b04bbe4ba0f7418e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0d8328e53e99092414648c16b04bbe4ba0f7418e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2889,
          name: "Gorilla Gold Megaways Mobile",
          uuid: "4e0269cc252757c4f7e3ba878fea39e67ce7df11",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4e0269cc252757c4f7e3ba878fea39e67ce7df11.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2828,
          name: "Grizzly Gold",
          uuid: "be304ee46f8d8df1662d33f31f5647542c20a0a2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/be304ee46f8d8df1662d33f31f5647542c20a0a2.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2829,
          name: "Grizzly Gold Mobile",
          uuid: "2e607bcd2812fc9a6f0388dcbb51f4b72bf78698",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2e607bcd2812fc9a6f0388dcbb51f4b72bf78698.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2840,
          name: "Gun Slinger",
          uuid: "5fda383a703aad360cc49426c96a5e9d37a063a2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5fda383a703aad360cc49426c96a5e9d37a063a2.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2841,
          name: "Gun Slinger Mobile",
          uuid: "5df8644ec935e8337473d1b4eb5f7f13d38a3e40",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5df8644ec935e8337473d1b4eb5f7f13d38a3e40.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2914,
          name: "Hope Diamond",
          uuid: "7a575c9c4f924d5cb7e65d5850e8dff9a538fc1e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7a575c9c4f924d5cb7e65d5850e8dff9a538fc1e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2915,
          name: "Hope Diamond Mobile",
          uuid: "985bc6963276d3244154f2d714b951e82fe95526",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/985bc6963276d3244154f2d714b951e82fe95526.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2898,
          name: "Hot Frootastic",
          uuid: "8841c0c686f44db5984b39a09733f948e5551ca6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8841c0c686f44db5984b39a09733f948e5551ca6.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2899,
          name: "Hot Frootastic Mobile",
          uuid: "24f1899357904954e2fdbfabc5f293cbe3c4f2f3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/24f1899357904954e2fdbfabc5f293cbe3c4f2f3.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2830,
          name: "Hot Zone",
          uuid: "9a8248dbbd5fef96ddabfddc033a8ed14eaf82e1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9a8248dbbd5fef96ddabfddc033a8ed14eaf82e1.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2831,
          name: "Hot Zone Mobile",
          uuid: "275592dc882dd7c9f9411c07f08988ec7d8b9472",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/275592dc882dd7c9f9411c07f08988ec7d8b9472.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2776,
          name: "Imperial Dragon",
          uuid: "4385466ecd7309d44c2fef00f681302b7b748455",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4385466ecd7309d44c2fef00f681302b7b748455.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2791,
          name: "Imperial Dragon Mobile",
          uuid: "6df0de30ea268ef74090a4eec8c5f530ff142285",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6df0de30ea268ef74090a4eec8c5f530ff142285.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2964,
          name: "Into The Wild Megaways",
          uuid: "35855a3941192043fc14643f3ddd6fd6b125bb04",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/35855a3941192043fc14643f3ddd6fd6b125bb04.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2965,
          name: "Into The Wild Megaways Mobile",
          uuid: "8f533cecffd6576b8d5cf020f8eeccdd4eaa817a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8f533cecffd6576b8d5cf020f8eeccdd4eaa817a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3028,
          name: "Jackpot in the Box",
          uuid: "06fc3609418e42878e4ba9b32a34af8f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/06fc3609418e42878e4ba9b32a34af8f.png",
          type: "crash",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3029,
          name: "Jackpot in the Box Mobile",
          uuid: "a5aaf41a7fb5415f8fd2379f423af012",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/a5aaf41a7fb5415f8fd2379f423af012.png",
          type: "crash",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2773,
          name: "Jewel Strike",
          uuid: "53885613f56a4c3ee95fd42303cd5b036c732ccf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/53885613f56a4c3ee95fd42303cd5b036c732ccf.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2793,
          name: "Jewel Strike Mobile",
          uuid: "3d34cff6874d9304d8e73d47d89f74030b26478c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3d34cff6874d9304d8e73d47d89f74030b26478c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2774,
          name: "Jewel Twist",
          uuid: "4ccd3c5fa5e59efdd375c4d9d342ea94d2adcbd8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4ccd3c5fa5e59efdd375c4d9d342ea94d2adcbd8.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2792,
          name: "Jewel Twist Mobile",
          uuid: "fe5f6b36ae76a1a79ac5101cb90269443e2001d0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fe5f6b36ae76a1a79ac5101cb90269443e2001d0.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3020,
          name: "Joker Lux",
          uuid: "7fc64c38acb34295946457bea1bf8c4d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/7fc64c38acb34295946457bea1bf8c4d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3021,
          name: "Joker Lux Mobile",
          uuid: "167778eeb69e4b3da6fe11c5e3666079",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/167778eeb69e4b3da6fe11c5e3666079.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2781,
          name: "Journey of the Gods",
          uuid: "75a67013a15a152fefb67aee4dd5bd04ab1f08c7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/75a67013a15a152fefb67aee4dd5bd04ab1f08c7.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2796,
          name: "Journey of the Gods Mobile",
          uuid: "11ae2e67314ecae75815abc36f9348854c24826e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/11ae2e67314ecae75815abc36f9348854c24826e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3002,
          name: "Jungle Fortune",
          uuid: "c4deb8ea3fe34a54be66fadcc38fc1fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/c4deb8ea3fe34a54be66fadcc38fc1fa.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3003,
          name: "Jungle Fortune Mobile",
          uuid: "6a9a3b6ac0764510a4e5b43fefd3c7c1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/6a9a3b6ac0764510a4e5b43fefd3c7c1.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2775,
          name: "Jungle Jackpots",
          uuid: "f40409f250c2ee0ba709bebd9d71a4324d0f668f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f40409f250c2ee0ba709bebd9d71a4324d0f668f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2816,
          name: "Jungle Jackpots Mobile",
          uuid: "1b58ba125b23ecb2eb2c0f8e5ba8c77c157a4cc4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1b58ba125b23ecb2eb2c0f8e5ba8c77c157a4cc4.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2767,
          name: "King Kong Cash",
          uuid: "febfcdfdba74a061d1cd138edea43e181948e1a9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/febfcdfdba74a061d1cd138edea43e181948e1a9.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3077,
          name: "King Kong Cash DJ Prime8",
          uuid: "2bc0e340dfe03291faa224351ef17d6899c7722c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/2bc0e340dfe03291faa224351ef17d6899c7722c.jpg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3076,
          name: "King Kong Cash DJ Prime8 Mobile",
          uuid: "cc20ef07befc86c2cfe843d0e51d8f8e28f2c1b1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/cc20ef07befc86c2cfe843d0e51d8f8e28f2c1b1.jpg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3090,
          name: "King kong cash even bigger bananas 2",
          uuid: "b92d3402e42145ecb0365f96dd1c5b85",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/b92d3402e42145ecb0365f96dd1c5b85.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3091,
          name: "King kong cash even bigger bananas 2 Mobile",
          uuid: "f16e58f3c343449880df5d701fff7def",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/f16e58f3c343449880df5d701fff7def.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3042,
          name: "King Kong Cash Even Bigger Bananas Megaways",
          uuid: "ebb48f5c8df54c988da46540d5d4ee83",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/ebb48f5c8df54c988da46540d5d4ee83.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3043,
          name: "King Kong Cash Even Bigger Bananas Megaways Mobile",
          uuid: "66f82ca75a014a678cd8d7e5bd1bfa79",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/66f82ca75a014a678cd8d7e5bd1bfa79.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3069,
          name: "King Kong Cash Full House",
          uuid: "69f5a5a24260f43817d3825281f77c6fd993b9db",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/69f5a5a24260f43817d3825281f77c6fd993b9db.png",
          type: "lottery",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3068,
          name: "King Kong Cash Full House Mobile",
          uuid: "82f8526a35a81525e362429e287b78fc5370f2c9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/82f8526a35a81525e362429e287b78fc5370f2c9.png",
          type: "lottery",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2819,
          name: "King Kong Cash Mobile",
          uuid: "6f17d5cdc5b931e2c2d321576d95c171372bd1b9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6f17d5cdc5b931e2c2d321576d95c171372bd1b9.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2938,
          name: "King Kong Cash Prize Lines",
          uuid: "8e4550508a572b33d65b5b924235bdb2809c1a13",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8e4550508a572b33d65b5b924235bdb2809c1a13.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2939,
          name: "King Kong Cash Prize Lines Mobile",
          uuid: "8747b36b89fff420b68ec8d48ff866eb755e4416",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8747b36b89fff420b68ec8d48ff866eb755e4416.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2993,
          name: "King Kong Cashpots",
          uuid: "b4294dda0a384916bf5b3a2ea3e4e928",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/b4294dda0a384916bf5b3a2ea3e4e928.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2992,
          name: "King Kong Cashpots Mobile",
          uuid: "ab1d1d71cdec4cecbfb7363b39159f29",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/ab1d1d71cdec4cecbfb7363b39159f29.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2930,
          name: "King of the West",
          uuid: "f4b154eef758172d4fcf11e870dcbf1ef64a36ef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f4b154eef758172d4fcf11e870dcbf1ef64a36ef.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2931,
          name: "King of the West Mobile",
          uuid: "d08d2454b20bf1ff823843c50ae81f9f18b5eafb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d08d2454b20bf1ff823843c50ae81f9f18b5eafb.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2832,
          name: "Kingdom Of Fortune",
          uuid: "39dfdb33329742106de6783a132349a0d3e6416d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/39dfdb33329742106de6783a132349a0d3e6416d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2833,
          name: "Kingdom Of Fortune Mobile",
          uuid: "957dbd84d4cde764f26899ae41c495c152020489",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/957dbd84d4cde764f26899ae41c495c152020489.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2890,
          name: "Kongs Temple",
          uuid: "84bc73c4b2888d096e73b7f392ba2debff75c067",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/84bc73c4b2888d096e73b7f392ba2debff75c067.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2891,
          name: "Kongs Temple Mobile",
          uuid: "2ff55dc87eac927d9909355c7362b789c667f738",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2ff55dc87eac927d9909355c7362b789c667f738.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2900,
          name: "Legacy of Gods Megaways",
          uuid: "96caa45088ac4074970cf7071fbcba223146b382",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/96caa45088ac4074970cf7071fbcba223146b382.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2901,
          name: "Legacy of Gods Megaways Mobile",
          uuid: "60ba4f6be14795e76e6af73999333d3c2d358056",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/60ba4f6be14795e76e6af73999333d3c2d358056.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2846,
          name: "Legacy of Ra Megaways",
          uuid: "f659ac01c0fd250560275d9bead48bfef01f352a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f659ac01c0fd250560275d9bead48bfef01f352a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2847,
          name: "Legacy of Ra Megaways Mob",
          uuid: "a19869183a75d26c29109a5f1071e93de529fe61",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a19869183a75d26c29109a5f1071e93de529fe61.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2786,
          name: "Lightning Strike Megaways",
          uuid: "ce25fd3036b8091b9a6298a4ba2afb28c27ceefb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ce25fd3036b8091b9a6298a4ba2afb28c27ceefb.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2810,
          name: "Lightning Strike Megaways Mobile",
          uuid: "47ec962463376610930534df4c443ced5738ec7e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/47ec962463376610930534df4c443ced5738ec7e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2983,
          name: "Limerick Lightning",
          uuid: "fce26d57bf01fee72d00d2f7fff192d7676e0a2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/fce26d57bf01fee72d00d2f7fff192d7676e0a2d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2982,
          name: "Limerick Lightning Mobile",
          uuid: "eeddd2eb526ada92ab9e45fc8a4154624dcc9088",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/eeddd2eb526ada92ab9e45fc8a4154624dcc9088.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2916,
          name: "Lion Thunder",
          uuid: "94e210c6458465afcb7e316f9fefb73b868fdaf6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/94e210c6458465afcb7e316f9fefb73b868fdaf6.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2917,
          name: "Lion Thunder Mobile",
          uuid: "1ac800023066ba148654483365f3a028d0b7c988",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1ac800023066ba148654483365f3a028d0b7c988.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2946,
          name: "Luck O' The Irish Gold Spins",
          uuid: "0bb8807ada83b9abd6560a6224960ab423fe35d0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0bb8807ada83b9abd6560a6224960ab423fe35d0.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2947,
          name: "Luck O' The Irish Gold Spins Mobile",
          uuid: "2c98ed54929175ff556a331a7faae9a17cadf05a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2c98ed54929175ff556a331a7faae9a17cadf05a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3012,
          name: "Luck O' The Irish Mystery Ways Fortune Play",
          uuid: "a470e210f85d4d7f9986a6e4f241dda4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/a470e210f85d4d7f9986a6e4f241dda4.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3013,
          name: "Luck O' The Irish Mystery Ways Fortune Play Mobile",
          uuid: "d2cb29d0535648e4b5ab293095694276",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/d2cb29d0535648e4b5ab293095694276.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2934,
          name: "Luck O'The Irish Fortune Spins II",
          uuid: "e18a5f13ba17b882d43d35b47f8a2f4295328207",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e18a5f13ba17b882d43d35b47f8a2f4295328207.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2935,
          name: "Luck O'The Irish Fortune Spins II Mobile",
          uuid: "be99d6bb9d2d4b42083d2bf8f892e2fbf57ec669",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/be99d6bb9d2d4b42083d2bf8f892e2fbf57ec669.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2853,
          name: "Luck of the Irish Megaways",
          uuid: "17be7200f3f80a71d7e504d06821441ecc2a691c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/17be7200f3f80a71d7e504d06821441ecc2a691c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2852,
          name: "Luck of the Irish Megaways Mobile",
          uuid: "fe01878e8fcdfe6468177853d787a4710f84d84f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fe01878e8fcdfe6468177853d787a4710f84d84f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2991,
          name: "Luck O’ The Irish Big Bonus",
          uuid: "4c0035af700b4fb389471f7b42d4e47f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/4c0035af700b4fb389471f7b42d4e47f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2990,
          name: "Luck O’ The Irish Big Bonus Mobile",
          uuid: "9732bedb0435415085dd469fbd0154b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/9732bedb0435415085dd469fbd0154b4.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2763,
          name: "Luck O’ the Irish Fortune Spins",
          uuid: "f6d59ea00872a66fcdd2487d535d34b1ecab90eb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f6d59ea00872a66fcdd2487d535d34b1ecab90eb.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2818,
          name: "Luck O’ the Irish Fortune Spins Mobile",
          uuid: "b1117f817f6fac97d053d3b4b8c8d2be3a9a709e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b1117f817f6fac97d053d3b4b8c8d2be3a9a709e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2768,
          name: "Lucky 8 Fortune Cat",
          uuid: "2c0b83232d42129d687d1b3eb4ff44b7ecc6f311",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2c0b83232d42129d687d1b3eb4ff44b7ecc6f311.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2789,
          name: "Lucky 8 Fortune Cat Mobile",
          uuid: "b3dd3a33edcd7baee55268334f96f54b54e9feb6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b3dd3a33edcd7baee55268334f96f54b54e9feb6.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2958,
          name: "Lucky Nuggets Megaways",
          uuid: "44f6f55e5f740cfb102969324eb3959f2282ff1d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/44f6f55e5f740cfb102969324eb3959f2282ff1d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2959,
          name: "Lucky Nuggets Megaways Mobile",
          uuid: "05d5f60dda7d8160ffbe0c455e22a5df685fe571",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/05d5f60dda7d8160ffbe0c455e22a5df685fe571.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2769,
          name: "Lucky Pharaoh",
          uuid: "bc5a18772d4e38ed018369c18ce4568f8b6a437f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bc5a18772d4e38ed018369c18ce4568f8b6a437f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2805,
          name: "Lucky Pharaoh Mobile",
          uuid: "c0fda0e0d2f69ce19d07cda3fd32083acb4f8743",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c0fda0e0d2f69ce19d07cda3fd32083acb4f8743.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3063,
          name: "Madame of Mystic Manor",
          uuid: "df240a53a076d85d3d0c196626c9f79988861d79",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/df240a53a076d85d3d0c196626c9f79988861d79.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3062,
          name: "Madame of Mystic Manor Mobile",
          uuid: "f4c91a6c5ccf072f4757179d82385b13e6875d10",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/f4c91a6c5ccf072f4757179d82385b13e6875d10.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2770,
          name: "Magic Mirror Deluxe II",
          uuid: "d58af7641ee95354e991c272b9ed65600a50ef04",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d58af7641ee95354e991c272b9ed65600a50ef04.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2811,
          name: "Magic Mirror Deluxe II Mobile",
          uuid: "7fbff1ce2afa09e5418b695de2e54ea644463d10",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7fbff1ce2afa09e5418b695de2e54ea644463d10.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3085,
          name: "Majestic Fury Megaways",
          uuid: "afea13ecea33edf0f71de68556f90b4598085714",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/afea13ecea33edf0f71de68556f90b4598085714.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3084,
          name: "Majestic Fury Megaways Mobile",
          uuid: "b675efa27fdee394877b2461f6161d7a9c7a9b41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/b675efa27fdee394877b2461f6161d7a9c7a9b41.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3065,
          name: "Midas King of Gold",
          uuid: "db7e95a1708a5c463bdad04f28911e743a9a5f73",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/db7e95a1708a5c463bdad04f28911e743a9a5f73.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3064,
          name: "Midas King of Gold Mobile",
          uuid: "76c566bee5f675f889b1d6a08c55e72b0ed48f40",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/76c566bee5f675f889b1d6a08c55e72b0ed48f40.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2771,
          name: "Mistress of Fortune",
          uuid: "2a581e6c012f57d49402d593b3c1adcf868cb204",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2a581e6c012f57d49402d593b3c1adcf868cb204.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2788,
          name: "Mistress of Fortune Mobile",
          uuid: "836352da6dcfa12485934b3847039af04592c835",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/836352da6dcfa12485934b3847039af04592c835.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2772,
          name: "Multi Wild",
          uuid: "eb1d03d01929273c8116a67fa7c80e4a07c75396",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/eb1d03d01929273c8116a67fa7c80e4a07c75396.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2815,
          name: "Multi Wild Mobile",
          uuid: "25f589b83e6d23cbfd37a9319bc2b875c9e87f8e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/25f589b83e6d23cbfd37a9319bc2b875c9e87f8e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2850,
          name: "Mystery Spin Deluxe Megaways",
          uuid: "4fb75e7af400fe61c672e62892587d86191cbb7b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4fb75e7af400fe61c672e62892587d86191cbb7b.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2851,
          name: "Mystery Spin Deluxe Megaways Mobile",
          uuid: "c3265a37d2befcc01c77040611b858149f42226d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c3265a37d2befcc01c77040611b858149f42226d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2822,
          name: "Napoleon",
          uuid: "2292fdf35232283717edf0efda1b3c1ce46e4c6f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2292fdf35232283717edf0efda1b3c1ce46e4c6f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3071,
          name: "Napoleon Deluxe",
          uuid: "5a7f428a58ad9b3d5f8d994f935eb27ddc7a2ae5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/5a7f428a58ad9b3d5f8d994f935eb27ddc7a2ae5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3070,
          name: "Napoleon Deluxe Mobile",
          uuid: "ec8c79c37033772b99993f58bb82e2d1e590454c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/ec8c79c37033772b99993f58bb82e2d1e590454c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2823,
          name: "Napoleon Mobile",
          uuid: "b299c9174aaaee95c152695cd62fd0315c29f037",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b299c9174aaaee95c152695cd62fd0315c29f037.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2880,
          name: "Pirates Frenzy",
          uuid: "3cbd12bc5b2c9db9bfe6fb7a7090af779b822ab8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3cbd12bc5b2c9db9bfe6fb7a7090af779b822ab8.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2881,
          name: "Pirates Frenzy Mobile",
          uuid: "d4cb384ab8842e762b200205140227fa3ca38ad2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d4cb384ab8842e762b200205140227fa3ca38ad2.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3067,
          name: "Plenty O’ Fish",
          uuid: "7c8e3a49390e47d561c2c56cff5fed98e7af1657",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/7c8e3a49390e47d561c2c56cff5fed98e7af1657.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3066,
          name: "Plenty O’ Fish Mobile",
          uuid: "8b60f9229d21e3ddd69de609ebf053a66ef1677d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/8b60f9229d21e3ddd69de609ebf053a66ef1677d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2782,
          name: "Primal Megaways",
          uuid: "29f7f0aa7af6e83474580fb6e649ca2c491e4a31",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/29f7f0aa7af6e83474580fb6e649ca2c491e4a31.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2787,
          name: "Primal Megaways Mobile",
          uuid: "444cd8577da989df47ca78befe86e5fd6cc2e182",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/444cd8577da989df47ca78befe86e5fd6cc2e182.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2834,
          name: "Red Hot Repeater",
          uuid: "c42e36f0a96096e5983d95c19d0c5b6bec4d5f8d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c42e36f0a96096e5983d95c19d0c5b6bec4d5f8d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2835,
          name: "Red Hot Repeater Mobile",
          uuid: "3b8236199a48efdbce8ec354846757f5ce7fe47f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3b8236199a48efdbce8ec354846757f5ce7fe47f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2855,
          name: "Return of Kong Megaways",
          uuid: "f9ab8726066d0214cb1bf60a12d00231f685333c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f9ab8726066d0214cb1bf60a12d00231f685333c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2854,
          name: "Return of Kong Megaways Mobile",
          uuid: "64b9ee9d699bfa7ac5da23498b31ed2502b563ce",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/64b9ee9d699bfa7ac5da23498b31ed2502b563ce.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2904,
          name: "Revolution Patriot’s Fortune",
          uuid: "c07bcb6bd3ca1ebdd42b5f4f17f72a10e37855b4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c07bcb6bd3ca1ebdd42b5f4f17f72a10e37855b4.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2905,
          name: "Revolution Patriot’s Fortune Mobile",
          uuid: "c0b705ceadacbd0f5ef4000943deffdbafd3e1c5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c0b705ceadacbd0f5ef4000943deffdbafd3e1c5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2902,
          name: "Rhino Rampage",
          uuid: "de4bc8ad39f084581fbdd151e34c196aa3f66c64",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/de4bc8ad39f084581fbdd151e34c196aa3f66c64.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2903,
          name: "Rhino Rampage Mobile",
          uuid: "98da4a3edb3e121fae482cc71805f6d23120f073",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/98da4a3edb3e121fae482cc71805f6d23120f073.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3000,
          name: "Rise of Atlantis",
          uuid: "99c3f70f501f42be9ee80d5fab22c445",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/99c3f70f501f42be9ee80d5fab22c445.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3026,
          name: "Rise of Atlantis 2",
          uuid: "283eac1fc084495c80c0488e9b8191ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/283eac1fc084495c80c0488e9b8191ce.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3027,
          name: "Rise of Atlantis 2 Mobile",
          uuid: "41a055eaa04b46c6b08ca71b5cd17794",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/41a055eaa04b46c6b08ca71b5cd17794.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3001,
          name: "Rise of Atlantis Mobile",
          uuid: "13b7e2839acf41848164d43d8077f297",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/13b7e2839acf41848164d43d8077f297.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2924,
          name: "Rolling in Gold",
          uuid: "2697cd0d7c355d902580187f93ba67ed2e7a3694",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2697cd0d7c355d902580187f93ba67ed2e7a3694.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2925,
          name: "Rolling in Gold Mobile",
          uuid: "aa620b4e7074e0695cc44a9b84d546ae992df196",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aa620b4e7074e0695cc44a9b84d546ae992df196.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2784,
          name: "Rome Rise of an Empire",
          uuid: "c5a6a0d12509772c10a505fff7d6f8d6c9f3ed89",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c5a6a0d12509772c10a505fff7d6f8d6c9f3ed89.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2808,
          name: "Rome Rise of an Empire Mobile",
          uuid: "47e9240999f55547643b1559b7748cc5b146dc3c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/47e9240999f55547643b1559b7748cc5b146dc3c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2860,
          name: "Safari Gold Megaways",
          uuid: "e7393c8898c6020a6ff40167dffd121ca93017e6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e7393c8898c6020a6ff40167dffd121ca93017e6.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2861,
          name: "Safari Gold Megaways Mobile",
          uuid: "21cae600410f62c34fe78da275266ec42c31c041",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/21cae600410f62c34fe78da275266ec42c31c041.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2785,
          name: "Savanna Stampede",
          uuid: "da324b9350769521fb44ccf005e8979d37d0c337",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/da324b9350769521fb44ccf005e8979d37d0c337.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2812,
          name: "Savanna Stampede Mobile",
          uuid: "feb2c21c31ae71a8bb1de93d81964989e6a54172",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/feb2c21c31ae71a8bb1de93d81964989e6a54172.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2878,
          name: "Scribes of Thebes",
          uuid: "4d9465f68041616f13675c966e52138dd35935f7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4d9465f68041616f13675c966e52138dd35935f7.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2879,
          name: "Scribes of Thebes Mobile",
          uuid: "1e18897207f9f8802cc597ed4df95f13698ddceb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1e18897207f9f8802cc597ed4df95f13698ddceb.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2985,
          name: "Sinful 7s",
          uuid: "e9ab891ee5e7af2ad6a8b989d0ee2384e887b85e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/e9ab891ee5e7af2ad6a8b989d0ee2384e887b85e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2984,
          name: "Sinful 7s Mobile",
          uuid: "95c0029d6a61d00462b6caa07ac91b651eab22b1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/95c0029d6a61d00462b6caa07ac91b651eab22b1.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2975,
          name: "Spins O' Gold Fortune Play",
          uuid: "4a75e6a784a446fa95efda9b09af7292",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/4a75e6a784a446fa95efda9b09af7292.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2974,
          name: "Spins O' Gold Fortune Play Mobile",
          uuid: "d3daddd3940f42078dbf2fbc040f0e1d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/d3daddd3940f42078dbf2fbc040f0e1d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3081,
          name: "Stickermania Wild Rumble",
          uuid: "77b391b716fe114d9783d78cf7d923e6856ee0a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/77b391b716fe114d9783d78cf7d923e6856ee0a1.jpg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3080,
          name: "Stickermania Wild Rumble Mobile",
          uuid: "e1b2c4b0119118de1c46a41551d1e751d161998f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/e1b2c4b0119118de1c46a41551d1e751d161998f.jpg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2779,
          name: "Super Fortune Dragon",
          uuid: "79e3baecd409bb41f6b00c5188199d37083e866f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/79e3baecd409bb41f6b00c5188199d37083e866f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2798,
          name: "Super Fortune Dragon Mobile",
          uuid: "90bb7fbcdc7e338ea3522e236b38c700221077c3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/90bb7fbcdc7e338ea3522e236b38c700221077c3.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2836,
          name: "Super Jewel Repeater",
          uuid: "ff7cd49d880108bd892c8c1031a39886c930a3db",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ff7cd49d880108bd892c8c1031a39886c930a3db.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2837,
          name: "Super Jewel Repeater Mobile",
          uuid: "e0d5082cf991e0d77cd1d7b9aadcfe4b2cf0f480",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e0d5082cf991e0d77cd1d7b9aadcfe4b2cf0f480.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2857,
          name: "Super Lucky Charms",
          uuid: "1888c4d2839685e31c0c8be0354e2e3957013c37",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1888c4d2839685e31c0c8be0354e2e3957013c37.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2856,
          name: "Super Lucky Charms Mobile",
          uuid: "de839323c9e77ee95cb34267e21b331514b23b80",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/de839323c9e77ee95cb34267e21b331514b23b80.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2780,
          name: "Super Lucky Frog",
          uuid: "78d686d38643cc9fc476f57ff38d48de379c31fa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/78d686d38643cc9fc476f57ff38d48de379c31fa.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2797,
          name: "Super Lucky Frog Mobile",
          uuid: "bb2f4c67df70a4902828d067d4e2725aae02f22e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bb2f4c67df70a4902828d067d4e2725aae02f22e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2838,
          name: "Super Wild Diamonds",
          uuid: "a5ac021721423da75f4dde9bef0cc42bbc0fd7ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a5ac021721423da75f4dde9bef0cc42bbc0fd7ae.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2839,
          name: "Super Wild Diamonds Mobile",
          uuid: "0d1092676a5ad1dd8779057c9ff5d676db994628",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0d1092676a5ad1dd8779057c9ff5d676db994628.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2872,
          name: "Sweet Success Megaways",
          uuid: "64b6a1a7205ea3a69e1e36e86c9b5d23012fe97b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/64b6a1a7205ea3a69e1e36e86c9b5d23012fe97b.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2873,
          name: "Sweet Success Megaways Mobile",
          uuid: "324b2dd62078acf992394df2d4eb7da8c33a3714",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/324b2dd62078acf992394df2d4eb7da8c33a3714.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3049,
          name: "Tasty Trailz",
          uuid: "cda80ca511895ea08f7617672256676d4dc305aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/cda80ca511895ea08f7617672256676d4dc305aa.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3048,
          name: "Tasty Trailz Mobile",
          uuid: "61cd373c107c4e165a9c6f2f02da62aadf0922cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/61cd373c107c4e165a9c6f2f02da62aadf0922cc.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2979,
          name: "Temple of Riches Spin Boost",
          uuid: "9a1655edd9f94b90a8d352e077f58447",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/9a1655edd9f94b90a8d352e077f58447.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2978,
          name: "Temple of Riches Spin Boost Mobile",
          uuid: "00650cd79d6f406191ea040d8e135831",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/00650cd79d6f406191ea040d8e135831.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2820,
          name: "Temple of Treasure Megaways",
          uuid: "575cee4d149d698ee0b4e6393b4828c48344a7e2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/575cee4d149d698ee0b4e6393b4828c48344a7e2.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2821,
          name: "Temple of Treasure Megaways Mobile",
          uuid: "8b502933b527d3d3ed8c4a5ccf03dd0a751c0ee4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8b502933b527d3d3ed8c4a5ccf03dd0a751c0ee4.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2894,
          name: "Thai Flower",
          uuid: "3f0a4464a11a9d83142ebe0b3cdbddc067672d63",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3f0a4464a11a9d83142ebe0b3cdbddc067672d63.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2944,
          name: "Thai Flower Megaways",
          uuid: "4e8e5743aaf9f87a8717c8867d2e087cf3d10d32",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4e8e5743aaf9f87a8717c8867d2e087cf3d10d32.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2945,
          name: "Thai Flower Megaways Mobile",
          uuid: "5528e14ee5bc3f874376456341e1b3a2ac24e511",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5528e14ee5bc3f874376456341e1b3a2ac24e511.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2895,
          name: "Thai Flower Mobile",
          uuid: "faa96eaf5b2289e98543c9c7211d1cd30ec59610",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/faa96eaf5b2289e98543c9c7211d1cd30ec59610.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2948,
          name: "The G.O.A.T",
          uuid: "65a8aced7028258ff5ff5f30bb24e161207f94b5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/65a8aced7028258ff5ff5f30bb24e161207f94b5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2949,
          name: "The G.O.A.T Mobile",
          uuid: "6442e9d6e9928969ed47bc858b6eb5ea37dd6b55",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6442e9d6e9928969ed47bc858b6eb5ea37dd6b55.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3006,
          name: "The Stash",
          uuid: "ec371acd3c2f40efb6c999c6878fb509",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/ec371acd3c2f40efb6c999c6878fb509.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3007,
          name: "The Stash Mobile",
          uuid: "095b929137de4258bb95c15f06099f2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/095b929137de4258bb95c15f06099f2d.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2918,
          name: "Tomb of Dead Power 4 slots",
          uuid: "b913b8e631c83ac1eadf0e83e4180db6305c5c94",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b913b8e631c83ac1eadf0e83e4180db6305c5c94.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2919,
          name: "Tomb of Dead Power 4 slots Mobile",
          uuid: "3ba510004cb837d03852b7b88bae61bbec8e23be",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3ba510004cb837d03852b7b88bae61bbec8e23be.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3079,
          name: "Trailblazer",
          uuid: "865fa82e59c0e4b0ff4507939bf44a91abb1a63c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/865fa82e59c0e4b0ff4507939bf44a91abb1a63c.jpg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3078,
          name: "Trailblazer Mobile",
          uuid: "7fd6dc49593bccd12c24311720db6415d7a26a9d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/7fd6dc49593bccd12c24311720db6415d7a26a9d.jpg",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2874,
          name: "Triple Flame",
          uuid: "be8a70a31383cb2bea232a5b5629873269c0e9f5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/be8a70a31383cb2bea232a5b5629873269c0e9f5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2875,
          name: "Triple Flame Mobile",
          uuid: "fa75f9bf4add3d3801d4cdb061c50903d88b5aab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fa75f9bf4add3d3801d4cdb061c50903d88b5aab.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2844,
          name: "Valletta Megaways",
          uuid: "a367f8328c818126f7bc0cfb205e88be6fce1711",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a367f8328c818126f7bc0cfb205e88be6fce1711.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2845,
          name: "Valletta Megaways Mob",
          uuid: "97b9929fe6e830f3c753aa040f607be2b89a6027",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/97b9929fe6e830f3c753aa040f607be2b89a6027.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2989,
          name: "Viking Fall",
          uuid: "ba1a9e8e337d41f689983ece2c6db389",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/ba1a9e8e337d41f689983ece2c6db389.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2988,
          name: "Viking Fall Mobile",
          uuid: "394fed453524426fbd222f0b854e9e80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/394fed453524426fbd222f0b854e9e80.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3016,
          name: "Viking Fury Spinfinity",
          uuid: "1e8eb2148b1c420ea98dd1dfe16afe85",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/1e8eb2148b1c420ea98dd1dfe16afe85.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3017,
          name: "Viking Fury Spinfinity Mobile",
          uuid: "8b0086ae058941399fc726f1d6c097e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/8b0086ae058941399fc726f1d6c097e5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2783,
          name: "Vikings Unleashed Megaways",
          uuid: "e2e48587237d7c1e7e57233fcb97c71b15994780",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e2e48587237d7c1e7e57233fcb97c71b15994780.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2813,
          name: "Vikings Unleashed Megaways Mobile",
          uuid: "30e8b3572c3ae9b99b039ff4dc30d01eaa8d785b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/30e8b3572c3ae9b99b039ff4dc30d01eaa8d785b.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3030,
          name: "Vikings Unleashed Reloaded",
          uuid: "781f9225117d4a908d8f5c16e42c6f88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/781f9225117d4a908d8f5c16e42c6f88.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3031,
          name: "Vikings Unleashed Reloaded Mobile",
          uuid: "0cca89f4aac5417697b0b1fc1ff6895e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/0cca89f4aac5417697b0b1fc1ff6895e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2777,
          name: "Wild Antics",
          uuid: "57c2bf518f0976ce590916a5308da55d2242becb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/57c2bf518f0976ce590916a5308da55d2242becb.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2794,
          name: "Wild Antics Mobile",
          uuid: "0dfbb12f5f63dfdb2124302e2ab6942e30ee8864",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0dfbb12f5f63dfdb2124302e2ab6942e30ee8864.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2987,
          name: "Wish Upon A Cashpot",
          uuid: "39cf22dc16d945d19b2e5398ba450dc7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/39cf22dc16d945d19b2e5398ba450dc7.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2986,
          name: "Wish Upon A Cashpot Mobile",
          uuid: "0695722309874003a6ec18bee4f86702",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/0695722309874003a6ec18bee4f86702.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2778,
          name: "Wish Upon A Jackpot",
          uuid: "e7eeaac893457956501786aad82cbd735be08c9b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e7eeaac893457956501786aad82cbd735be08c9b.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2906,
          name: "Wish Upon A Jackpot Megaways",
          uuid: "d88b5dba8325a12d822584007827747dc6b912f4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d88b5dba8325a12d822584007827747dc6b912f4.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2907,
          name: "Wish Upon A Jackpot Megaways Mobile",
          uuid: "e41c0c362e67ee86b4892f0d4945a72f5188454e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e41c0c362e67ee86b4892f0d4945a72f5188454e.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2814,
          name: "Wish Upon A Jackpot Mobile",
          uuid: "34a1ac8993623b93d4fc0caae3486dfdde0cfecf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/34a1ac8993623b93d4fc0caae3486dfdde0cfecf.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2862,
          name: "Wish Upon A Leprechaun Megaways",
          uuid: "734067fa1cf9638a9eb5e528f1cfd3a6c07b46e8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/734067fa1cf9638a9eb5e528f1cfd3a6c07b46e8.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2863,
          name: "Wish Upon A Leprechaun Megaways Mobile",
          uuid: "e9a5210248cc498ee5ce3779c7bc60fb7179de7c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e9a5210248cc498ee5ce3779c7bc60fb7179de7c.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2842,
          name: "Wolf Legend Megaways",
          uuid: "cafa3cd70c77bf10f5b390fad10ce0fe5642adf5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cafa3cd70c77bf10f5b390fad10ce0fe5642adf5.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2843,
          name: "Wolf Legend Megaways Mobile",
          uuid: "82c825e057a146176d286290c1f723fec8f9efce",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/82c825e057a146176d286290c1f723fec8f9efce.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2922,
          name: "World Darts Championship",
          uuid: "c27a204aa81cca1bbdd3919c3c28e1f70fecbc9a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c27a204aa81cca1bbdd3919c3c28e1f70fecbc9a.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2923,
          name: "World Darts Championship Mobile",
          uuid: "b90bf256fc5c66841e891c8a1ab80f330a2b464f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b90bf256fc5c66841e891c8a1ab80f330a2b464f.png",
          type: "slots",
          provider: "Blueprint",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 112,
      name: "CTInteractive",
      providerId: 56,
      providerName: "CTInteractive",
      games: [
        {
          game_id: 3312,
          name: "10 Fruitata Wins",
          uuid: "5651ca9280516f63107937e264d392d04565ddad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/5651ca9280516f63107937e264d392d04565ddad.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3243,
          name: "20 Clovers Hot",
          uuid: "565bae0f1f8f4aae8676e85cd20d431a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/565bae0f1f8f4aae8676e85cd20d431a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3324,
          name: "20 Dice Clovers",
          uuid: "e50ac3209508b6931ed82102339df36f66c3958f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e50ac3209508b6931ed82102339df36f66c3958f.png",
          type: "dice",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3266,
          name: "20 Dice Party",
          uuid: "c8de8a2c0ee148708627039d3d99d69e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c8de8a2c0ee148708627039d3d99d69e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3292,
          name: "20 Dice Roosters",
          uuid: "144693f03d074799810699bd5ce7ac05",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/144693f03d074799810699bd5ce7ac05.png",
          type: "dice slot",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3310,
          name: "20 Fruitata Wins",
          uuid: "f08330d872b6dcbab1738fee5661736152700581",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/f08330d872b6dcbab1738fee5661736152700581.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3267,
          name: "20 Mega Dice",
          uuid: "04d8dea384fc4c7bba00e81c3dfdf5ae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/04d8dea384fc4c7bba00e81c3dfdf5ae.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3248,
          name: "20 Mega Fresh",
          uuid: "f4ebc2eb9d014cdda51974533d9da2e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/f4ebc2eb9d014cdda51974533d9da2e3.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3210,
          name: "20 Mega Slot",
          uuid: "70c10fa9d2e140adaa1e6eae4366ac44",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/70c10fa9d2e140adaa1e6eae4366ac44.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3211,
          name: "20 Roosters",
          uuid: "56844975612346038c5bc602eb3d3db9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/56844975612346038c5bc602eb3d3db9.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3212,
          name: "20 Shining Coins",
          uuid: "592dc245e0824319abf0ffa7c9337623",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/592dc245e0824319abf0ffa7c9337623.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3094,
          name: "20 Star Party",
          uuid: "d69b194af35441698548127e94ce3dc9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/d69b194af35441698548127e94ce3dc9.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3214,
          name: "3 Towers",
          uuid: "e9be0dd4af7b42168c421709c233d50b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e9be0dd4af7b42168c421709c233d50b.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3314,
          name: "30 fruitata wins",
          uuid: "254c9febaa1547f5ab9f614f5d646a50",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/254c9febaa1547f5ab9f614f5d646a50.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3213,
          name: "30 Treasures",
          uuid: "3d0dc418c2044b9781add38c674ef189",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3d0dc418c2044b9781add38c674ef189.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3227,
          name: "40 Brilliants",
          uuid: "ed32cfb63cb24d5fbe9c992672229ed5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/ed32cfb63cb24d5fbe9c992672229ed5.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3095,
          name: "40 Diamond Treasures",
          uuid: "9e44024fdd334b449e746d1e2d880633",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/9e44024fdd334b449e746d1e2d880633.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3293,
          name: "40 Dice Treasures",
          uuid: "2ed0470e28a442a4897929b6a3655610",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/2ed0470e28a442a4897929b6a3655610.png",
          type: "dice slot",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3306,
          name: "40 Fruitata Wins",
          uuid: "2daa0ba5c68eb839747aa86883df674a9b3d7a01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/2daa0ba5c68eb839747aa86883df674a9b3d7a01.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3268,
          name: "40 Mega Dice",
          uuid: "5bda9d98f67e4e7d80f721e1cd572c16",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/5bda9d98f67e4e7d80f721e1cd572c16.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3096,
          name: "40 Mega Slot",
          uuid: "e7de1ca1a3544b03aa41429b886f5c97",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e7de1ca1a3544b03aa41429b886f5c97.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3288,
          name: "40 Roosters",
          uuid: "031e9c3ab81c249e56f164b2b93a590dad6fb3e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/031e9c3ab81c249e56f164b2b93a590dad6fb3e4.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3215,
          name: "40 Shining Coins",
          uuid: "b4534f99d3c94a4c9abb9584dfc58533",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/b4534f99d3c94a4c9abb9584dfc58533.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3097,
          name: "40 Shining jewels",
          uuid: "6bab0e2c1af54a0fbe3723dcc78f332d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/6bab0e2c1af54a0fbe3723dcc78f332d.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3098,
          name: "40 Treasures",
          uuid: "7360e3b081ee409b85e3f2b9b9655d63",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/7360e3b081ee409b85e3f2b9b9655d63.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3308,
          name: "5 Fruitata Wins",
          uuid: "a25a904792e91cad47fb2c5c6ed5ed9cdddeb516",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a25a904792e91cad47fb2c5c6ed5ed9cdddeb516.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3294,
          name: "50 Dice Treasures",
          uuid: "6c759a0fede642ea944fe215d2078699",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/6c759a0fede642ea944fe215d2078699.png",
          type: "dice slot",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3099,
          name: "50 Shining jewels",
          uuid: "ee7af44cf9c945ef8cdf7a418d382330",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/ee7af44cf9c945ef8cdf7a418d382330.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3100,
          name: "50 Treasures",
          uuid: "59ff8c5aaedd4217ac2184298a82e21b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/59ff8c5aaedd4217ac2184298a82e21b.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3242,
          name: "5x Cherry Party",
          uuid: "abb23658bb3a4ee0a33c4993cb298be5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/abb23658bb3a4ee0a33c4993cb298be5.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3325,
          name: "5x Dice Party",
          uuid: "6bf66970d0e44d1d57a87c8991e7e5df89126d7b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/6bf66970d0e44d1d57a87c8991e7e5df89126d7b.png",
          type: "dice",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3101,
          name: "African Magic",
          uuid: "40cddcecaa0441a09d1c45ff75960a8f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/40cddcecaa0441a09d1c45ff75960a8f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3102,
          name: "Alaska Wild",
          uuid: "a803a2da429f4d768640348ad43e696f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a803a2da429f4d768640348ad43e696f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3103,
          name: "Amazons Spear",
          uuid: "87b95f0694da48adb64431771062f9bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/87b95f0694da48adb64431771062f9bf.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3104,
          name: "American Gigolo",
          uuid: "e824c38bd23c49589828f9304fe0fd3a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e824c38bd23c49589828f9304fe0fd3a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3105,
          name: "Australian Magic",
          uuid: "90a42ea75748485cb0a5d4af8943eb58",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/90a42ea75748485cb0a5d4af8943eb58.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3106,
          name: "Aztec Empress",
          uuid: "e1674d38525b4e6880b0ca6796f4cb9a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e1674d38525b4e6880b0ca6796f4cb9a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3300,
          name: "Aztec Gold 20",
          uuid: "719474be468b00fe3946c1676f73ab927b14a655",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/719474be468b00fe3946c1676f73ab927b14a655.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3295,
          name: "Banana Dice Party",
          uuid: "e8846bdc08784e92877df05d1cf0b2d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e8846bdc08784e92877df05d1cf0b2d3.png",
          type: "dice slot",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3321,
          name: "Banana Merge",
          uuid: "47be326367360ab4a761426d2e561ed2de47936c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/47be326367360ab4a761426d2e561ed2de47936c.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3107,
          name: "Banana Party",
          uuid: "ef2fcac665254c4689d746c4d67dd628",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/ef2fcac665254c4689d746c4d67dd628.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3108,
          name: "Bavarian Forest",
          uuid: "180981533329456dae94bc18e9e07e73",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/180981533329456dae94bc18e9e07e73.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3269,
          name: "Beetle Dice",
          uuid: "9087cfb5932448c3964cb23c58df13e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/9087cfb5932448c3964cb23c58df13e3.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3109,
          name: "Beetle Star",
          uuid: "19001091ccd94731856954a69b5e1940",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/19001091ccd94731856954a69b5e1940.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3110,
          name: "Big Joker",
          uuid: "abf84f9e541c4e0282a6e46ae22ac45f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/abf84f9e541c4e0282a6e46ae22ac45f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3296,
          name: "Big Joker Dice",
          uuid: "03d340d5749c4f0cbd76486a10c7dc1e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/03d340d5749c4f0cbd76486a10c7dc1e.png",
          type: "dice slot",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3111,
          name: "Black Pharaoh",
          uuid: "0f667905482849ed87fdaeaedc5c956d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/0f667905482849ed87fdaeaedc5c956d.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3315,
          name: "Bloody Princess",
          uuid: "d9fc3a117c354e9fa64ac68a486536c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/d9fc3a117c354e9fa64ac68a486536c2.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3112,
          name: "Bombay Gems",
          uuid: "336fb54e787341338a989c4768dfd09c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/336fb54e787341338a989c4768dfd09c.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3113,
          name: "Brilliants Hot",
          uuid: "16bdbc2cf21f48c7863e5c09bf0793ba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/16bdbc2cf21f48c7863e5c09bf0793ba.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3114,
          name: "Brilliants On Fire",
          uuid: "b4d3b81b63394a4c87df308d341f71dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/b4d3b81b63394a4c87df308d341f71dd.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3216,
          name: "BRITANIA",
          uuid: "ea45bc729b414bf58029084c6518bebf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/ea45bc729b414bf58029084c6518bebf.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3115,
          name: "Bye Bye Spy Guy",
          uuid: "a80d41c8d9e84f84ad95080721eca83f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a80d41c8d9e84f84ad95080721eca83f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3252,
          name: "Cara Mia Carmen",
          uuid: "c12d6666b098426bb426ee816ff833f2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c12d6666b098426bb426ee816ff833f2.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3116,
          name: "Carats Whisper",
          uuid: "2e0b7b48501c4315b904038601c17d1e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/2e0b7b48501c4315b904038601c17d1e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3117,
          name: "Caribbean Adventure",
          uuid: "1c8118acd93c412eb78d5d053cdbc7ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/1c8118acd93c412eb78d5d053cdbc7ad.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3246,
          name: "Caribbean Diver",
          uuid: "d72f3744f53842d7bb97f9f8472c5ec0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/d72f3744f53842d7bb97f9f8472c5ec0.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3331,
          name: "Celectial Dragon",
          uuid: "c78c16c5f7f243f783e0d8422536ea43",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c78c16c5f7f243f783e0d8422536ea43.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3118,
          name: "Celestial Ruler",
          uuid: "1a6eefe8190742fbbe6bea93b7957e79",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/1a6eefe8190742fbbe6bea93b7957e79.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3261,
          name: "Champagne and Fruits",
          uuid: "86893f66672f46a1bfa2948fde035cc2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/86893f66672f46a1bfa2948fde035cc2.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3228,
          name: "Cherry Crown",
          uuid: "f2979fa6dc51400bacdf2a5f0ff97cd5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/f2979fa6dc51400bacdf2a5f0ff97cd5.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3119,
          name: "Chili Baby",
          uuid: "cc329ebc87ae4b95a7ee0a9c9fcd5cd0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/cc329ebc87ae4b95a7ee0a9c9fcd5cd0.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3217,
          name: "Chilli Bomb",
          uuid: "c0616fe684c842aa8a71bd848596afb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c0616fe684c842aa8a71bd848596afb3.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3120,
          name: "Chilli Fruits",
          uuid: "3cf41b8aba87472fbbbe8563a67e5943",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3cf41b8aba87472fbbbe8563a67e5943.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3270,
          name: "Chilli Jester",
          uuid: "f2bbfaae64e043e89d7cb570bb34a12b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/f2bbfaae64e043e89d7cb570bb34a12b.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3336,
          name: "Chilli Madness",
          uuid: "45ec1f7bd00e4299b7b6962ea70d0c89",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/45ec1f7bd00e4299b7b6962ea70d0c89.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3311,
          name: "Christmas Storm",
          uuid: "bce5814a1474b4f8ae5602354dbbfd6b70c440c4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/bce5814a1474b4f8ae5602354dbbfd6b70c440c4.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3271,
          name: "Clover Dice",
          uuid: "4323fcdfbc854d259ac333557737cfce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/4323fcdfbc854d259ac333557737cfce.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3251,
          name: "Clover Gems",
          uuid: "e607ee1af6a74aa3a7b84e9f7804883a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e607ee1af6a74aa3a7b84e9f7804883a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3250,
          name: "Clover Joker",
          uuid: "9baa7710db4c4302b8e23aaaef67963f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/9baa7710db4c4302b8e23aaaef67963f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3121,
          name: "Clover Party",
          uuid: "34f3acd83c2341ef966c7ab767bbd37d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/34f3acd83c2341ef966c7ab767bbd37d.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3333,
          name: "Clover Queen",
          uuid: "0d6827243abceec318a1386408b7f60801a1c690",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/0d6827243abceec318a1386408b7f60801a1c690.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3239,
          name: "Clover Wheel",
          uuid: "9f022b47f7754a33b0343a468feb770a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/9f022b47f7754a33b0343a468feb770a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3122,
          name: "Coffee Magic",
          uuid: "61230ade29d441dda39a5f03157e6bcc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/61230ade29d441dda39a5f03157e6bcc.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3123,
          name: "Colibri Wild",
          uuid: "c2ba3113c7dc4da78828a11c5f211cf5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c2ba3113c7dc4da78828a11c5f211cf5.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3124,
          name: "Columbus Treasure",
          uuid: "c0c643e81ea74191b3e5c59fa8e8ca6a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c0c643e81ea74191b3e5c59fa8e8ca6a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3125,
          name: "Combat Romance",
          uuid: "24abdce056384b0d81197836efb43238",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/24abdce056384b0d81197836efb43238.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3126,
          name: "Crazy Caterpillar",
          uuid: "7d35154e18344d8f90b69eda6ff60d9e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/7d35154e18344d8f90b69eda6ff60d9e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3127,
          name: "Dancing Bananas",
          uuid: "d37a70a8934649749b9a8f0af0d7f0d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/d37a70a8934649749b9a8f0af0d7f0d7.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3218,
          name: "Dancing Dragons",
          uuid: "316bf0bbd10d47ee8cd2aa45f3b4bcc5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/316bf0bbd10d47ee8cd2aa45f3b4bcc5.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3272,
          name: "Dancing Jokers",
          uuid: "23cff369cc8242f0ab69210223ea488b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/23cff369cc8242f0ab69210223ea488b.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3229,
          name: "Dark Woods",
          uuid: "c1dc9710bf4042fe9008d942309ebff7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c1dc9710bf4042fe9008d942309ebff7.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3128,
          name: "Desert Tales",
          uuid: "e85ca9646b8946649ace9368c9d31575",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e85ca9646b8946649ace9368c9d31575.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3129,
          name: "Devil's Fruits",
          uuid: "602f0370c04a48c785e92038ddbd1556",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/602f0370c04a48c785e92038ddbd1556.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3274,
          name: "Dice Quest",
          uuid: "b282b0b8caeb417bbc35abdca43a5dd0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/b282b0b8caeb417bbc35abdca43a5dd0.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3275,
          name: "Dice Valhalla",
          uuid: "e3065e54e2b24c1ab73f66318acdb864",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e3065e54e2b24c1ab73f66318acdb864.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3276,
          name: "Dice Wizzard",
          uuid: "c3d16b906279415ba626c860b3bab1d5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c3d16b906279415ba626c860b3bab1d5.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3277,
          name: "Dice Woods",
          uuid: "387bed06bccf4b11a31d03bd8f6158ed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/387bed06bccf4b11a31d03bd8f6158ed.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3273,
          name: "Dice'o Luck",
          uuid: "b63f26f9b20543159b7a3feeca5fb038",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/b63f26f9b20543159b7a3feeca5fb038.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3130,
          name: "Disco Babylon",
          uuid: "cd60d32e777549f2842199802a1ae037",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/cd60d32e777549f2842199802a1ae037.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3283,
          name: "Doctor Winstein",
          uuid: "7beb3a123c50241498801cb13d4236358ec11b05",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/7beb3a123c50241498801cb13d4236358ec11b05.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3316,
          name: "Doctor Winstein Buy Bonus",
          uuid: "bb2f799d4f85487d9bc03dee59836bac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/bb2f799d4f85487d9bc03dee59836bac.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3131,
          name: "Double Hot Habanero",
          uuid: "5691e6e3294249a1b80427973d2616e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/5691e6e3294249a1b80427973d2616e3.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3132,
          name: "Duck Of Luck",
          uuid: "ac4df2b471c942e3849bcac320951178",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/ac4df2b471c942e3849bcac320951178.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3133,
          name: "Duck of Luck returns",
          uuid: "8c015b2e8066432d905d084e99369b5f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/8c015b2e8066432d905d084e99369b5f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3225,
          name: "Duck Spell",
          uuid: "8d9fe9933dff46d8a0f0cdae2d98aa0a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/8d9fe9933dff46d8a0f0cdae2d98aa0a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3301,
          name: "Easter Bonanza",
          uuid: "0328f11820f72e475b8ed1946a8b17ff306c1018",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/0328f11820f72e475b8ed1946a8b17ff306c1018.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3134,
          name: "Egg and Rooster",
          uuid: "336cdbe5e2f64146b14e7014740bc55e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/336cdbe5e2f64146b14e7014740bc55e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3289,
          name: "Enchanted Fruits",
          uuid: "231d9ad7cb592ec8b08a9099dd4f512fe46680b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/231d9ad7cb592ec8b08a9099dd4f512fe46680b8.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3135,
          name: "English Rose",
          uuid: "93d0c83d947d412cbb9b9118b4bae0e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/93d0c83d947d412cbb9b9118b4bae0e3.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3337,
          name: "FC MAGIC",
          uuid: "6dcf6f22714b4421b71b619cb138be39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/6dcf6f22714b4421b71b619cb138be39.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3136,
          name: "Fire Dozen",
          uuid: "25d6fabf95854098ba8f5fe9d80a4564",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/25d6fabf95854098ba8f5fe9d80a4564.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3284,
          name: "Fire Egg",
          uuid: "f93c178b6662b240a9fe50edb0af74e5bdec07b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/f93c178b6662b240a9fe50edb0af74e5bdec07b0.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3326,
          name: "Fire Egg Dice",
          uuid: "33edf7700744bb82b56183dac891724cbb666ada",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/33edf7700744bb82b56183dac891724cbb666ada.png",
          type: "dice",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3253,
          name: "Fire King",
          uuid: "acedcd0e7b204de0b6c0d3a2318a26c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/acedcd0e7b204de0b6c0d3a2318a26c0.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3317,
          name: "Fire Lord",
          uuid: "7ef194c6fe8f4f929074b3c57b4d48ab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/7ef194c6fe8f4f929074b3c57b4d48ab.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3137,
          name: "Fluf Tails",
          uuid: "eec4b8c2b0f940d1842692f4a70de783",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/eec4b8c2b0f940d1842692f4a70de783.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3138,
          name: "Forest Nymph",
          uuid: "cf61bbd03346417f82ca2603f086f5f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/cf61bbd03346417f82ca2603f086f5f8.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3230,
          name: "Forest Wedding",
          uuid: "eaf7590f72d2450c8c67035409f58cb1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/eaf7590f72d2450c8c67035409f58cb1.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3139,
          name: "Fortune Fish",
          uuid: "cae654255b5c4a1f8c42664a5fb4347a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/cae654255b5c4a1f8c42664a5fb4347a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3259,
          name: "Fortune Pig",
          uuid: "17ca50a72b794ef5ae37c9e82f8fa4b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/17ca50a72b794ef5ae37c9e82f8fa4b7.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3219,
          name: "Fortune Pyramid",
          uuid: "f10b0ad8e0714a098b336f1b28268f3b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/f10b0ad8e0714a098b336f1b28268f3b.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3235,
          name: "Fruit Feast",
          uuid: "0f429fb7b4e44428848c7b8bc9abf682",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/0f429fb7b4e44428848c7b8bc9abf682.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3245,
          name: "Fruit Galaxy",
          uuid: "34fe2354fae04a05a86cc0fd0297f787",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/34fe2354fae04a05a86cc0fd0297f787.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3263,
          name: "Fruit Galaxy The Wheel",
          uuid: "38d509eb6e424e06bdff4d85328903d0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/38d509eb6e424e06bdff4d85328903d0.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3286,
          name: "Fruitball Heroes",
          uuid: "f731367001b64637a67e41d4f9c62680",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/f731367001b64637a67e41d4f9c62680.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3260,
          name: "Fruits and Bells",
          uuid: "71b00435f5ae47bb8432d74ba3059343",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/71b00435f5ae47bb8432d74ba3059343.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3303,
          name: "Fruits and Sweets",
          uuid: "876324de096269dbf42d81421f939a1680ade712",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/876324de096269dbf42d81421f939a1680ade712.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3140,
          name: "Fruits of Desire",
          uuid: "c6424363c7224124aff68ba385097e8e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c6424363c7224124aff68ba385097e8e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3220,
          name: "Fruity Hot",
          uuid: "fcabdfb9c2374508b790e962a7caff41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/fcabdfb9c2374508b790e962a7caff41.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3141,
          name: "Full Of Luck",
          uuid: "e4483483d9a54ebd97a8f525356e9aa6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e4483483d9a54ebd97a8f525356e9aa6.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3142,
          name: "Full Of Treasures",
          uuid: "8d3d160d640d414c9b472de464da4a3b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/8d3d160d640d414c9b472de464da4a3b.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3143,
          name: "Fusion Fruit Beat",
          uuid: "a7d4be27903743c294fc5aed142706dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a7d4be27903743c294fc5aed142706dd.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3144,
          name: "Gaelic Warrior",
          uuid: "98236cbf6c2c4aceb740676cd887a37a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/98236cbf6c2c4aceb740676cd887a37a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3304,
          name: "Giraffe Wild",
          uuid: "25c932d04a822ffeaf903209f42fad921c1aff99",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/25c932d04a822ffeaf903209f42fad921c1aff99.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3145,
          name: "Goblin's Gold",
          uuid: "c18d73f69ea04f36897cc3b271295107",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c18d73f69ea04f36897cc3b271295107.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3146,
          name: "Golden Acorn",
          uuid: "fad6f31023b34927b4d0cc236aec5542",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/fad6f31023b34927b4d0cc236aec5542.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3147,
          name: "Golden Amulet",
          uuid: "6b382c7aa9324dac97370389a8d55a4c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/6b382c7aa9324dac97370389a8d55a4c.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3148,
          name: "Golden Flower Of Life",
          uuid: "dd62884c4bce4d4b9d1c654d03e13ec4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/dd62884c4bce4d4b9d1c654d03e13ec4.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3149,
          name: "Golden Hand",
          uuid: "9163534191da4761916c8b156fa9752a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/9163534191da4761916c8b156fa9752a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3290,
          name: "GOTHIC QUEEN RETURNS",
          uuid: "8b6aed9a7c24677c837a4a4649e305d1ce95f772",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/8b6aed9a7c24677c837a4a4649e305d1ce95f772.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3150,
          name: "Great Queen Bee",
          uuid: "b886f1bcf99d4c95af9994b9bc7f3ea9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/b886f1bcf99d4c95af9994b9bc7f3ea9.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3151,
          name: "Groovy Automat",
          uuid: "50447f344af844aba23ddb4aae651d56",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/50447f344af844aba23ddb4aae651d56.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3152,
          name: "Groovy Powers",
          uuid: "c5533c6d37924a1db5860b53644d00e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c5533c6d37924a1db5860b53644d00e6.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3332,
          name: "Guardian of Asgard",
          uuid: "3aa8c65e14f449789da26bea3322831b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3aa8c65e14f449789da26bea3322831b.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3256,
          name: "Halloween Double Hot",
          uuid: "48a4c90d74f045da96f62a07713c5862",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/48a4c90d74f045da96f62a07713c5862.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3153,
          name: "Halloween Fruits",
          uuid: "7883015201ae4f29ae76d77fb24cd6ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/7883015201ae4f29ae76d77fb24cd6ff.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3255,
          name: "Halloween Hot",
          uuid: "99b0f9f78b3c4e1fa54804c9251f35c5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/99b0f9f78b3c4e1fa54804c9251f35c5.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3313,
          name: "Hearts&Love",
          uuid: "2d246e744ded44febfbc1c1912a83d89",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/2d246e744ded44febfbc1c1912a83d89.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3154,
          name: "Hell's Sevens",
          uuid: "3e1dd791e10e4ee780ea31dda6f8c184",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3e1dd791e10e4ee780ea31dda6f8c184.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3236,
          name: "Hit the Hot",
          uuid: "a3c6ca98616c4fc19923e53de09eb1ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a3c6ca98616c4fc19923e53de09eb1ea.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3278,
          name: "Hot 7's Dice",
          uuid: "06b5e3dd177544c8a6a29154b5748f6e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/06b5e3dd177544c8a6a29154b5748f6e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3155,
          name: "HOT 7's X 2",
          uuid: "d8e38d0feb9946bea79cb148840d5fc9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/d8e38d0feb9946bea79cb148840d5fc9.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3285,
          name: "Hyper Cuber",
          uuid: "e120f91698d15d50c74a23e85e6a90b3a02af23d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e120f91698d15d50c74a23e85e6a90b3a02af23d.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3240,
          name: "Ice Rubies",
          uuid: "6683c84aa50646958631f6afa2d69b3f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/6683c84aa50646958631f6afa2d69b3f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3156,
          name: "Island Vacation",
          uuid: "3e51d293998543dcbc8ce908426d0650",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3e51d293998543dcbc8ce908426d0650.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3157,
          name: "Jade Heaven",
          uuid: "789e2fd0a2e349b68773290f1d5e7d81",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/789e2fd0a2e349b68773290f1d5e7d81.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3158,
          name: "Jaguar Warrior",
          uuid: "fe9a338d23d04ae38a00c281ff247b47",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/fe9a338d23d04ae38a00c281ff247b47.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3322,
          name: "Jester Jack",
          uuid: "0b54463292774e9296ddcdd2f8266c48",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/0b54463292774e9296ddcdd2f8266c48.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3159,
          name: "Jolly Beluga Whales",
          uuid: "690ebd323e644b47830434502ec50757",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/690ebd323e644b47830434502ec50757.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3254,
          name: "Kingdom Treasures",
          uuid: "90d1fbcc82974a6aa8ff99f03629f2fb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/90d1fbcc82974a6aa8ff99f03629f2fb.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3241,
          name: "Lady Emerald",
          uuid: "c14b3f5ccaf74f16893bd409a253efea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c14b3f5ccaf74f16893bd409a253efea.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3258,
          name: "Lord of Fortune",
          uuid: "4e54032a2bbb43bcbbd20a5b3b2bda95",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/4e54032a2bbb43bcbbd20a5b3b2bda95.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3237,
          name: "Lord of Luck",
          uuid: "e316ec3947014933958f5ac9a28dee82",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e316ec3947014933958f5ac9a28dee82.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3262,
          name: "Lord of Luck The Wheel",
          uuid: "4d0d18717dee4596adfa5443f13f2a7c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/4d0d18717dee4596adfa5443f13f2a7c.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3160,
          name: "Lucky 3 Penguins",
          uuid: "3b55dc8495384fc486571d7ba5696f10",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3b55dc8495384fc486571d7ba5696f10.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3161,
          name: "Lucky Clover",
          uuid: "42f1c68a9f4748b5a65282aba57af817",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/42f1c68a9f4748b5a65282aba57af817.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3221,
          name: "Lucky Dollar",
          uuid: "30cad4d0332e4fddb40107517342efd5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/30cad4d0332e4fddb40107517342efd5.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3279,
          name: "Lucky Dots",
          uuid: "da339b66be374613ad55d1c8e7ec5a26",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/da339b66be374613ad55d1c8e7ec5a26.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3318,
          name: "Lucky John",
          uuid: "00f288994f924bd9a04836280f5a93d9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/00f288994f924bd9a04836280f5a93d9.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3329,
          name: "Lucky Kiwi",
          uuid: "a6624ac1c04c4276ac1ba4657e0cc10a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a6624ac1c04c4276ac1ba4657e0cc10a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3222,
          name: "Magic Crown",
          uuid: "c46a6cbcefcc44e483639902ec20483e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c46a6cbcefcc44e483639902ec20483e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3280,
          name: "Magic Crystal Dice",
          uuid: "0ec1fbc2e42a40beb72bdf47ff000e83",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/0ec1fbc2e42a40beb72bdf47ff000e83.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3162,
          name: "Magic Hammer",
          uuid: "a7c755f9389243f8810b1c52654b8bdb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a7c755f9389243f8810b1c52654b8bdb.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3244,
          name: "Magic Jewels",
          uuid: "2f12384bc7674d4e8b20248c731faf0a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/2f12384bc7674d4e8b20248c731faf0a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3163,
          name: "Magician Dreaming",
          uuid: "a6608d836d4d4429b4a97834b8987295",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a6608d836d4d4429b4a97834b8987295.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3338,
          name: "Mega Fairies",
          uuid: "f392c5ef191c461d9ba912d4e2f33857",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/f392c5ef191c461d9ba912d4e2f33857.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3320,
          name: "Mega Gnome",
          uuid: "9d1d58316120a3c49c16b5e3de1f9c3b75ece5ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/9d1d58316120a3c49c16b5e3de1f9c3b75ece5ec.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3164,
          name: "Mighty Kraken",
          uuid: "02b1bb195dc84b16a62cc45c501fc12a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/02b1bb195dc84b16a62cc45c501fc12a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3249,
          name: "Mighty Moon",
          uuid: "5be6498b0bb64633b9f28c07dc0530ae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/5be6498b0bb64633b9f28c07dc0530ae.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3165,
          name: "Mighty Rex",
          uuid: "c5f2487fbe9846abbe3353492629d23a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c5f2487fbe9846abbe3353492629d23a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3166,
          name: "Milady x2",
          uuid: "79c2174168484ac5a06b26ff590282c9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/79c2174168484ac5a06b26ff590282c9.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3167,
          name: "Misty Forest",
          uuid: "3bcc58f0c673440fb901630cb8df360a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3bcc58f0c673440fb901630cb8df360a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3231,
          name: "Money Pipe",
          uuid: "fa3bf1f39c5342d996537f27d49d6f69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/fa3bf1f39c5342d996537f27d49d6f69.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3265,
          name: "Monkey Kingdom",
          uuid: "bcd25042e89343688f793c6fea81ba40",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/bcd25042e89343688f793c6fea81ba40.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3168,
          name: "Monkey Sevens",
          uuid: "7a10235e655c466f8eec16a5b10eb7f3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/7a10235e655c466f8eec16a5b10eb7f3.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3169,
          name: "Moon Lord",
          uuid: "b78bc81aa192411e91df93597cd13413",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/b78bc81aa192411e91df93597cd13413.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3226,
          name: "More Dragons",
          uuid: "cc5008373c084aacbf44fe2f7c4ed4f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/cc5008373c084aacbf44fe2f7c4ed4f6.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3297,
          name: "More Dragons Dice",
          uuid: "765132e1d2864d5597211e014ef6320e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/765132e1d2864d5597211e014ef6320e.png",
          type: "dice slot",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3257,
          name: "Mountain Heroes",
          uuid: "4a68d3113ddb45e2bc7cd0a31a8a0d7d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/4a68d3113ddb45e2bc7cd0a31a8a0d7d.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3170,
          name: "Mountain Song Quechua",
          uuid: "31a8bc59ec8248aface5e58e8992eb38",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/31a8bc59ec8248aface5e58e8992eb38.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3291,
          name: "Mysterious Drakkar",
          uuid: "3c20e822bb0093d77af719740f3c12660e23635e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3c20e822bb0093d77af719740f3c12660e23635e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3171,
          name: "Mystic Moon",
          uuid: "e081c64d89b5450eb768f19720eeef4c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e081c64d89b5450eb768f19720eeef4c.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3172,
          name: "Mystic Wreck",
          uuid: "42f62c1ce5164232ac3460af32ae8a8e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/42f62c1ce5164232ac3460af32ae8a8e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3173,
          name: "Nanook the White Ghost",
          uuid: "7d1ba5bcb0864505950f3f01f53d8e8f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/7d1ba5bcb0864505950f3f01f53d8e8f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3174,
          name: "Navy Girl",
          uuid: "cc61a98680e14a25a48d69fa3218f46a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/cc61a98680e14a25a48d69fa3218f46a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3247,
          name: "Neon Bananas",
          uuid: "ee33b10aec8142c782c28eef1460c069",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/ee33b10aec8142c782c28eef1460c069.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3175,
          name: "Nordic Song",
          uuid: "e8c4e9d92ced48c8b098c11319662602",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e8c4e9d92ced48c8b098c11319662602.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3176,
          name: "Norse Queen",
          uuid: "ebb6700059494840bf3bb30e9175e595",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/ebb6700059494840bf3bb30e9175e595.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3177,
          name: "Ocean Legends",
          uuid: "8c2c9d8cefbc4e1f9ac2147d9cf7cbc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/8c2c9d8cefbc4e1f9ac2147d9cf7cbc8.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3178,
          name: "Penguin Party",
          uuid: "02c1cf26c64e446c9cb19a724dd6275f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/02c1cf26c64e446c9cb19a724dd6275f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3179,
          name: "Pick The Pig",
          uuid: "8a763cb3b0e24166b42caaffbfb9168e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/8a763cb3b0e24166b42caaffbfb9168e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3327,
          name: "Pick The Pig Dice",
          uuid: "e3bef6bef8af6a853c9366aa0bf9e93bf62875cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e3bef6bef8af6a853c9366aa0bf9e93bf62875cc.png",
          type: "dice",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3232,
          name: "Piece of Treasure",
          uuid: "9eca75b68b2d4e588a1db2c326fbb73c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/9eca75b68b2d4e588a1db2c326fbb73c.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3223,
          name: "Pot of Jewels",
          uuid: "e485fef2da9948039a3311764436eb41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e485fef2da9948039a3311764436eb41.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3180,
          name: "Pot'o Luck",
          uuid: "fca3c05709c6450da454667fecdf296f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/fca3c05709c6450da454667fecdf296f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3302,
          name: "Power Storm",
          uuid: "b033e0bb495049269f1b5504ab159b3a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/b033e0bb495049269f1b5504ab159b3a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3181,
          name: "Purple Fruits",
          uuid: "1d75f89978f64a70bc4769e48a705701",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/1d75f89978f64a70bc4769e48a705701.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3182,
          name: "Purple Hot 2",
          uuid: "8e872d926b374462a0ac8ba3627a1c2b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/8e872d926b374462a0ac8ba3627a1c2b.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3183,
          name: "Pyramid of Gold",
          uuid: "1458d3349299436b801880cfb254f869",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/1458d3349299436b801880cfb254f869.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3234,
          name: "Queen of Flames",
          uuid: "d5cad5835ca44739af7ce4f7cc18684c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/d5cad5835ca44739af7ce4f7cc18684c.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3264,
          name: "Queen of Flames the Wheel",
          uuid: "b9dd398767244b079396f217da52030f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/b9dd398767244b079396f217da52030f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3339,
          name: "RAINBOW CHARM",
          uuid: "6a9cacc7f52f437cb2bbef08a1af8c5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/6a9cacc7f52f437cb2bbef08a1af8c5d.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3184,
          name: "Ramesses the Great",
          uuid: "570975f532ba4afbbc04e0f9da195af6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/570975f532ba4afbbc04e0f9da195af6.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3185,
          name: "Royal Jewels",
          uuid: "80e4694d9e754e798f5dfcdfa394e106",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/80e4694d9e754e798f5dfcdfa394e106.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3186,
          name: "Sapphire Lagoon",
          uuid: "78e801ee69a743358223c3350b4c8074",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/78e801ee69a743358223c3350b4c8074.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3233,
          name: "Satyr and Nymph",
          uuid: "377f0152081e4d64b1bdf29e79634ef1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/377f0152081e4d64b1bdf29e79634ef1.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3298,
          name: "Satyr And Nymph Dice",
          uuid: "0867241577284f4bb36b12a51f428f06",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/0867241577284f4bb36b12a51f428f06.png",
          type: "dice slot",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3187,
          name: "Scarab Stone",
          uuid: "9aa6c61c22564cc5b99015a1e3c9a595",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/9aa6c61c22564cc5b99015a1e3c9a595.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3188,
          name: "Shining Treasures",
          uuid: "296ed6a9f4ab423e9b7bb5b61db8b9d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/296ed6a9f4ab423e9b7bb5b61db8b9d2.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3189,
          name: "Silver Creek Giant",
          uuid: "8699934de4ee4b2b8d915abbefecac69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/8699934de4ee4b2b8d915abbefecac69.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3190,
          name: "Smiley X Wild",
          uuid: "71fc997f32ab4321a831c1a44099b27f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/71fc997f32ab4321a831c1a44099b27f.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3191,
          name: "Space Fruits",
          uuid: "f6344449e409426c9f8ec185abb76e1a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/f6344449e409426c9f8ec185abb76e1a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3335,
          name: "Star Girl",
          uuid: "cea21b367fff436e96722e5b120ec8e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/cea21b367fff436e96722e5b120ec8e4.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3192,
          name: "Tesla Power",
          uuid: "031341101606418880e1da60bda88faa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/031341101606418880e1da60bda88faa.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3309,
          name: "The Big Chili",
          uuid: "2034c0d8d4e023a58c9fc4c1c13d0fabab213a9d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/2034c0d8d4e023a58c9fc4c1c13d0fabab213a9d.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3282,
          name: "The Golden Duck",
          uuid: "bf591d4d00244feb9321c109f8f7cf13",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/bf591d4d00244feb9321c109f8f7cf13.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3193,
          name: "The Great Cabaret",
          uuid: "d021c4005e41419bae6d8dbd1a54c0ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/d021c4005e41419bae6d8dbd1a54c0ce.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3334,
          name: "The Magic Goblet",
          uuid: "549cd16190655c41f5ac459e7bdd93ab5427672e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/549cd16190655c41f5ac459e7bdd93ab5427672e.jpg",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3323,
          name: "The Magic Web",
          uuid: "3fec51f5e5934f49a9e82ec741ef59cd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3fec51f5e5934f49a9e82ec741ef59cd.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3330,
          name: "The New Queen of Fruits",
          uuid: "9ab3fb0713fe4622974c49f5cf7988cd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/9ab3fb0713fe4622974c49f5cf7988cd.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3319,
          name: "The Oldest Oak",
          uuid: "b87d3c77bf49483f89e2a5c16a6fca02",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/b87d3c77bf49483f89e2a5c16a6fca02.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3194,
          name: "The Power of Ankh",
          uuid: "c133a14d927142d389dcc79c28ac7baa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/c133a14d927142d389dcc79c28ac7baa.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3195,
          name: "The Power Of Ramesses",
          uuid: "3ce3a567970642b6a1cb2183b5afa979",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3ce3a567970642b6a1cb2183b5afa979.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3196,
          name: "The Red Temple",
          uuid: "1af5881c57e14e7b8058ef7988f69936",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/1af5881c57e14e7b8058ef7988f69936.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3197,
          name: "The Temple Of Astarta",
          uuid: "a3077a87801943dab2bbf6bf9de7930c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a3077a87801943dab2bbf6bf9de7930c.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3340,
          name: "THE WILD RHINO",
          uuid: "6c0b02c76d354090ad6c97419de22efe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/6c0b02c76d354090ad6c97419de22efe.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3198,
          name: "Three Nymphs",
          uuid: "5452b686c91940c585a81003306c125c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/5452b686c91940c585a81003306c125c.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3199,
          name: "Tibetan Song",
          uuid: "990f08b5e7e54cb881ca15788c9d462a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/990f08b5e7e54cb881ca15788c9d462a.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3307,
          name: "TransilWinia",
          uuid: "a50a6a63a8b462c1c4c6d3f5d96708d0576dd732",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a50a6a63a8b462c1c4c6d3f5d96708d0576dd732.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3287,
          name: "Treasure Chase",
          uuid: "09f61652e3324412ae3b74d615ec7a94",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/09f61652e3324412ae3b74d615ec7a94.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3200,
          name: "Treasure Hill",
          uuid: "856254d150e74f3982cc68b53fd42ad0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/856254d150e74f3982cc68b53fd42ad0.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3201,
          name: "Treasure Kingdom",
          uuid: "6a403c70040a4bf4ba5b43403c423cdf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/6a403c70040a4bf4ba5b43403c423cdf.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3224,
          name: "Treasures of Persia",
          uuid: "8c23fb5704cb45059d380919aad37eea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/8c23fb5704cb45059d380919aad37eea.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3202,
          name: "Tropic Dancer",
          uuid: "9f2a50f29d714903920654a6180c80e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/9f2a50f29d714903920654a6180c80e9.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3203,
          name: "Urban Lady",
          uuid: "92907da1d6f14114a86073c45fded4cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/92907da1d6f14114a86073c45fded4cc.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3204,
          name: "Urban Lady Love Story",
          uuid: "16ca3a4ff6a94bf7a3c3a8f70c0323b9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/16ca3a4ff6a94bf7a3c3a8f70c0323b9.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3205,
          name: "Viking's Fun",
          uuid: "6317d29ccffd4527881b427eddd82e2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/6317d29ccffd4527881b427eddd82e2d.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3206,
          name: "WET & JUICY",
          uuid: "3f71bcdee2784e62807922fe4915e2c5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/3f71bcdee2784e62807922fe4915e2c5.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3207,
          name: "Wild Clover",
          uuid: "1cf56ac2ef794210a98b5403fae2e46d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/1cf56ac2ef794210a98b5403fae2e46d.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3281,
          name: "Wild Clover Dice",
          uuid: "a51b4cfbf51e402f8762a4cde054b82c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/a51b4cfbf51e402f8762a4cde054b82c.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3208,
          name: "Wild Hills",
          uuid: "2a6a3010d6da483c887e4a6c8ff29f4e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/2a6a3010d6da483c887e4a6c8ff29f4e.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3299,
          name: "Win Feast",
          uuid: "814879d28da04aa89991e97cf29ce9c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/814879d28da04aa89991e97cf29ce9c6.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3238,
          name: "Win Storm",
          uuid: "97ef2583ae9e475f95af6f84b1c9ec67",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/97ef2583ae9e475f95af6f84b1c9ec67.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3328,
          name: "Win Storm Dice",
          uuid: "e042a9c11b8320325f3438a4428ede1997026bb5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/e042a9c11b8320325f3438a4428ede1997026bb5.png",
          type: "dice",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3209,
          name: "Wizard Blizzard",
          uuid: "4825144c804b4fc5ab5183c0d304fc28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/4825144c804b4fc5ab5183c0d304fc28.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3305,
          name: "Wizard Blizzardx5",
          uuid: "51e01909c11c2f0d675a63f928a6e1912d6c47b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/CTInteractive/51e01909c11c2f0d675a63f928a6e1912d6c47b0.png",
          type: "slots",
          provider: "CTInteractive",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 113,
      name: "Caleta",
      providerId: 36,
      providerName: "Caleta",
      games: [
        {
          game_id: 3489,
          name: "Astro Wild",
          uuid: "061eeb690d20ac7bd9ef22923bd44029f161c69e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/061eeb690d20ac7bd9ef22923bd44029f161c69e.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3490,
          name: "Astro Wild Mobile",
          uuid: "d359a22c8c6708155f1424b75e4ad9d68aba0d36",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d359a22c8c6708155f1424b75e4ad9d68aba0d36.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3511,
          name: "Atlantis Bingo",
          uuid: "5bfce53de914d5216eb019430f2525e24522a035",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5bfce53de914d5216eb019430f2525e24522a035.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3512,
          name: "Atlantis Bingo Mobile",
          uuid: "7c10198ed772f7c53f4ea17bade11e92811078b6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7c10198ed772f7c53f4ea17bade11e92811078b6.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3459,
          name: "Atomico Lotto",
          uuid: "32c25da658cf44b888f36329128af8b8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/32c25da658cf44b888f36329128af8b8.png",
          type: "lottery",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3460,
          name: "Atomico Lotto Mobile",
          uuid: "7ceb5a4ac36749c4bf17976017dd9c44",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7ceb5a4ac36749c4bf17976017dd9c44.png",
          type: "lottery",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3421,
          name: "Banana Bingo",
          uuid: "942502f84acc42f7a426a5d668d70d0e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/942502f84acc42f7a426a5d668d70d0e.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3422,
          name: "Banana Bingo Mobile",
          uuid: "083695f1db424974801777f7d9e779b0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/083695f1db424974801777f7d9e779b0.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3555,
          name: "Banana Boom",
          uuid: "989d45bb1403a6289316df2a6aac318adf5392e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/989d45bb1403a6289316df2a6aac318adf5392e3.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3556,
          name: "Banana Boom Mobile",
          uuid: "c30d94c30dc9314a9bb785104e0982d5bc3dde23",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/c30d94c30dc9314a9bb785104e0982d5bc3dde23.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3427,
          name: "Banana Keno",
          uuid: "6b00a42469b44121945882ca8f8e9ec7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/6b00a42469b44121945882ca8f8e9ec7.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3428,
          name: "Banana Keno Mobile",
          uuid: "b4c0f029026b4d46affe04340bf8bcf1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/b4c0f029026b4d46affe04340bf8bcf1.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3365,
          name: "Basketball Pro",
          uuid: "1ffe3503aac84481a8d25acc94509ba6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/1ffe3503aac84481a8d25acc94509ba6.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3366,
          name: "Basketball Pro Mobile",
          uuid: "4f302a934af741e4a113e5e0d6d65f88",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/4f302a934af741e4a113e5e0d6d65f88.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3471,
          name: "Beach Tennis",
          uuid: "353b7b37c76249e4b4def5fc8f53c3a3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/353b7b37c76249e4b4def5fc8f53c3a3.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3472,
          name: "Beach Tennis Mobile",
          uuid: "a2767204219c4eaca909dc9bd39a009b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/a2767204219c4eaca909dc9bd39a009b.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3455,
          name: "Betina Bingo",
          uuid: "2897f0ce6703408dbeaf15fda936d106",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/2897f0ce6703408dbeaf15fda936d106.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3456,
          name: "Betina Bingo Mobile",
          uuid: "252283e71f634536bd519f60aaad80eb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/252283e71f634536bd519f60aaad80eb.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3570,
          name: "Beto Bingo",
          uuid: "3673889474fa23778ce8bd7d23be21880e7deeea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/3673889474fa23778ce8bd7d23be21880e7deeea.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3569,
          name: "Beto Bingo Mobile",
          uuid: "2bbc85a8889eb0a6d0ae134be79b7e4eb5bfb9bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/2bbc85a8889eb0a6d0ae134be79b7e4eb5bfb9bf.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3509,
          name: "Big Bad Beasts",
          uuid: "9227c0ccb4331d3f29d3f01e8b8b2b421ab3c2eb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9227c0ccb4331d3f29d3f01e8b8b2b421ab3c2eb.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3510,
          name: "Big Bad Beasts Mobile",
          uuid: "928080ab082efd7e5ce708f0753688cc3d4f263f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/928080ab082efd7e5ce708f0753688cc3d4f263f.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3501,
          name: "Billion Llama in Vegas",
          uuid: "b26eb792fd0d4fcd8fafd945fbe646ad7c51c305",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b26eb792fd0d4fcd8fafd945fbe646ad7c51c305.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3502,
          name: "Billion Llama in Vegas Mobile",
          uuid: "0048755fc51da46d9e7887c37ff4c79b155188f6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0048755fc51da46d9e7887c37ff4c79b155188f6.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3497,
          name: "Bingo Billion Llama",
          uuid: "5c1eca9eb8a9f224937e058aa763c0bdcae65469",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5c1eca9eb8a9f224937e058aa763c0bdcae65469.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3498,
          name: "Bingo Billion Llama Mobile",
          uuid: "a5789cfcd000e859308e5c49472ddafa87b1b007",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a5789cfcd000e859308e5c49472ddafa87b1b007.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3351,
          name: "Bingo Bruxaria",
          uuid: "16552ac8c2b8439cb01e60cbd9d6b52a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/16552ac8c2b8439cb01e60cbd9d6b52a.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3352,
          name: "Bingo Bruxaria Mobile",
          uuid: "25c0fced1287404483f52ae18762e6eb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/25c0fced1287404483f52ae18762e6eb.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3405,
          name: "Bingo Cientista Doidão",
          uuid: "af19e14315dc4b0ea6de24307b87ecb3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/af19e14315dc4b0ea6de24307b87ecb3.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3406,
          name: "Bingo Cientista Doidão Mobile",
          uuid: "35bdc4b4df064fda9d6a6dace6ab56e2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/35bdc4b4df064fda9d6a6dace6ab56e2.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3463,
          name: "Bingo Fada da Fortuna",
          uuid: "cebcf6adf69441229436d672e62f5e08",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/cebcf6adf69441229436d672e62f5e08.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3464,
          name: "Bingo Fada da Fortuna Mobile",
          uuid: "1682361f02d044d2867f20af349b427d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/1682361f02d044d2867f20af349b427d.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3503,
          name: "Bingo Gatinho",
          uuid: "f05e9d15a8b0973cafd1524eb37d628082171f38",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f05e9d15a8b0973cafd1524eb37d628082171f38.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3504,
          name: "Bingo Gatinho Mobile",
          uuid: "98bc674d027b5f1e32a547de24ee0901ff0d31fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/98bc674d027b5f1e32a547de24ee0901ff0d31fb.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3417,
          name: "Bingo Gênio",
          uuid: "7d75af44c492417a88d00ec1b042f9c1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7d75af44c492417a88d00ec1b042f9c1.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3418,
          name: "Bingo Gênio Mobile",
          uuid: "29cae2a27bf04dcfb1bbed680eb24431",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/29cae2a27bf04dcfb1bbed680eb24431.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3491,
          name: "Bingo Halloween",
          uuid: "c5a7f2fab933a23b9735e36b13680a6c6f98b112",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c5a7f2fab933a23b9735e36b13680a6c6f98b112.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3492,
          name: "Bingo Halloween Mobile",
          uuid: "add8807bac04d523fd89a5691a6f64f8f2ee0f41",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/add8807bac04d523fd89a5691a6f64f8f2ee0f41.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3433,
          name: "Bingo Hortinha",
          uuid: "5589ccfc86eb41e4800b2261a31927ad",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/5589ccfc86eb41e4800b2261a31927ad.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3434,
          name: "Bingo Hortinha Mobile",
          uuid: "d368a51b3b664d42986ef5489ac8cd0b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/d368a51b3b664d42986ef5489ac8cd0b.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3419,
          name: "Bingo Iglu",
          uuid: "0ba542ee16d1449cbf7cf3aa8bdb5073",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/0ba542ee16d1449cbf7cf3aa8bdb5073.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3420,
          name: "Bingo Iglu Mobile",
          uuid: "44003b0bbb404670a3b8adc3e60b9a22",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/44003b0bbb404670a3b8adc3e60b9a22.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3547,
          name: "Bingo Pescaria",
          uuid: "90106b6bf7177a619fcef7ae22d81094f30df6cb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/90106b6bf7177a619fcef7ae22d81094f30df6cb.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3548,
          name: "Bingo Pescaria Mobile",
          uuid: "97dfeedb37eb88fa3d7b53d01f167b151afc5b60",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/97dfeedb37eb88fa3d7b53d01f167b151afc5b60.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3437,
          name: "Bingo Pirata",
          uuid: "292ca59e0d8e4f20a23ffdf3a1570ff5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/292ca59e0d8e4f20a23ffdf3a1570ff5.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3438,
          name: "Bingo Pirata Mobile",
          uuid: "e1da001de09149aabc87328a96e4adef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/e1da001de09149aabc87328a96e4adef.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3517,
          name: "Bingo Royale",
          uuid: "25cfe6aabd146c4b0b86dfcd04d731fff7711814",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/25cfe6aabd146c4b0b86dfcd04d731fff7711814.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3518,
          name: "Bingo Royale Mobile",
          uuid: "8e77d2a3efdfee83bd10e02bfd0e2c72519f012a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8e77d2a3efdfee83bd10e02bfd0e2c72519f012a.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3435,
          name: "Bingo Saga Loca",
          uuid: "1abba795b6b944ca921340ef1ab0e4d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/1abba795b6b944ca921340ef1ab0e4d7.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3436,
          name: "Bingo Saga Loca Mobile",
          uuid: "b64a16eda2ff4ca8bf0820e2e2ecaafd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/b64a16eda2ff4ca8bf0820e2e2ecaafd.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3413,
          name: "Bingo Samba Rio",
          uuid: "a09dc0ad25ef4d20b7969d57e3de96b9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/a09dc0ad25ef4d20b7969d57e3de96b9.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3414,
          name: "Bingo Samba Rio Mobile",
          uuid: "f5dff5a789204060aa5637af26d437bd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/f5dff5a789204060aa5637af26d437bd.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3439,
          name: "Bingo Señor Taco",
          uuid: "71ab2b84d9b245b882f0dd4745a8a134",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/71ab2b84d9b245b882f0dd4745a8a134.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3440,
          name: "Bingo Señor Taco Mobile",
          uuid: "000a2e331d854b218fe7d17073755a4c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/000a2e331d854b218fe7d17073755a4c.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3441,
          name: "Bingo Señorita Calavera",
          uuid: "8546e4b3c21540608bb7d9bdae7730cc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/8546e4b3c21540608bb7d9bdae7730cc.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3442,
          name: "Bingo Señorita Calavera Mobile",
          uuid: "4b1711c97c1f4ecea298fbc15bbbcad6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/4b1711c97c1f4ecea298fbc15bbbcad6.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3467,
          name: "Bingo Tornado",
          uuid: "486fc82723ee43219a8cb0b5b3255f4d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/486fc82723ee43219a8cb0b5b3255f4d.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3468,
          name: "Bingo Tornado Mobile",
          uuid: "a77742861aee40069db3160812a5bb6e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/a77742861aee40069db3160812a5bb6e.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3399,
          name: "Bingo Trevo da Sorte",
          uuid: "f5b6b5435c364885a187f6a7328e77a9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/f5b6b5435c364885a187f6a7328e77a9.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3400,
          name: "Bingo Trevo da Sorte Mobile",
          uuid: "19492b65822d437e8b835427a2d1cc47",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/19492b65822d437e8b835427a2d1cc47.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3443,
          name: "Bingolaço",
          uuid: "58b56a1fc5a74d138972b62f719ad648",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/58b56a1fc5a74d138972b62f719ad648.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3444,
          name: "Bingolaço Mobile",
          uuid: "ab7b56162f134d7ca4b200068b6d37ac",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/ab7b56162f134d7ca4b200068b6d37ac.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3445,
          name: "Bingolícia",
          uuid: "b10dfd8b82964c6a8cd133d1dab6423f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/b10dfd8b82964c6a8cd133d1dab6423f.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3446,
          name: "Bingolícia Mobile",
          uuid: "d780fe3a4a9944f0b6ca0cc597401117",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/d780fe3a4a9944f0b6ca0cc597401117.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3577,
          name: "Blackjack Royale",
          uuid: "cfa004176ab64885918dd36296babd36",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/cfa004176ab64885918dd36296babd36.png",
          type: "table",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3578,
          name: "Blackjack Royale Mobile",
          uuid: "e9358181fc7647dc90c7dc0767a14790",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/e9358181fc7647dc90c7dc0767a14790.png",
          type: "table",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3359,
          name: "Bollywood Nights",
          uuid: "a77e79143d964246b17a6189e5b3e4be",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/a77e79143d964246b17a6189e5b3e4be.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3360,
          name: "Bollywood Nights Mobile",
          uuid: "e989ecaf803545cab458fb7c94e8933d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/e989ecaf803545cab458fb7c94e8933d.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3513,
          name: "Bonus Only",
          uuid: "088943f5b433feb52c47685e2f1620b42f50e9e5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/088943f5b433feb52c47685e2f1620b42f50e9e5.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3514,
          name: "Bonus Only Mobile",
          uuid: "d080235b49da71e73ba5e902f654c458bcde2dc1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d080235b49da71e73ba5e902f654c458bcde2dc1.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3487,
          name: "Boto Bingo",
          uuid: "165f23673900994baa3311260e957a9725e224eb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/165f23673900994baa3311260e957a9725e224eb.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3488,
          name: "Boto Bingo Mobile",
          uuid: "876d3101797c8a87ae1fb08582ee8b6e5e901d6a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/876d3101797c8a87ae1fb08582ee8b6e5e901d6a.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3543,
          name: "Caipirinha Keno",
          uuid: "0022b534413c4c3c8ac112b9befc68b8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/0022b534413c4c3c8ac112b9befc68b8.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3544,
          name: "Caipirinha Keno Mobile",
          uuid: "92ba7b86ac05478d91ce261602c5a5f3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/92ba7b86ac05478d91ce261602c5a5f3.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3505,
          name: "Carnaval Scratchcard",
          uuid: "c5a88c2f4fc5fbfd4a6a3a155f26a8d56229f481",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c5a88c2f4fc5fbfd4a6a3a155f26a8d56229f481.png",
          type: "scratch card",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3506,
          name: "Carnaval Scratchcard Mobile",
          uuid: "7f7b45282c04bc6e59ce719d67697ac33f0bc5c9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7f7b45282c04bc6e59ce719d67697ac33f0bc5c9.png",
          type: "scratch card",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3507,
          name: "Carnival Beauties",
          uuid: "67c83e3cca024cd64b280367c8b345c74707cca3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/67c83e3cca024cd64b280367c8b345c74707cca3.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3508,
          name: "Carnival Beauties Mobile",
          uuid: "d8052ee11f3381263d3da4ecc77f1cf33ba02c05",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d8052ee11f3381263d3da4ecc77f1cf33ba02c05.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3485,
          name: "Catch a Fish",
          uuid: "cb3613e282af75e9726227677a6833a93c2150f9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cb3613e282af75e9726227677a6833a93c2150f9.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3486,
          name: "Catch a Fish Mobile",
          uuid: "8a0257d21073c603178bea59c5f548e042406c3c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8a0257d21073c603178bea59c5f548e042406c3c.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3389,
          name: "Caves & Treasures",
          uuid: "6b742b3be7054333a8057af8488c7aca",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/6b742b3be7054333a8057af8488c7aca.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3390,
          name: "Caves & Treasures Mobile",
          uuid: "c209e3cdcd1a4524ad272aa0540787db",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/c209e3cdcd1a4524ad272aa0540787db.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3483,
          name: "Cherry Cherry",
          uuid: "ff0b53669b01658ac9cf7f9f7c1c61d9040035b2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ff0b53669b01658ac9cf7f9f7c1c61d9040035b2.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3484,
          name: "Cherry Cherry Mobile",
          uuid: "331442b95b4dfe4ad85b7174c967f1d568a2fe29",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/331442b95b4dfe4ad85b7174c967f1d568a2fe29.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3561,
          name: "Cherry Christmas",
          uuid: "ddbdee9113e98a89f45a58d7dbb0eb22c5cda7b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/ddbdee9113e98a89f45a58d7dbb0eb22c5cda7b7.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3562,
          name: "Cherry Christmas Mobile",
          uuid: "96d1d4b936126c1d5fdbbbd1e78a7896e5a5f54b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/96d1d4b936126c1d5fdbbbd1e78a7896e5a5f54b.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3387,
          name: "China Charms",
          uuid: "cd387d15c9374f9fa30170e18ec59dc4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/cd387d15c9374f9fa30170e18ec59dc4.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3388,
          name: "China Charms Mobile",
          uuid: "0e4e25cbb0754047afbc4f76754fdc9a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/0e4e25cbb0754047afbc4f76754fdc9a.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3411,
          name: "Circus Bingo",
          uuid: "960f1b855dce4e5eaccbf02b31abac0f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/960f1b855dce4e5eaccbf02b31abac0f.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3412,
          name: "Circus Bingo Mobile",
          uuid: "b8141418069e471e89de1ba3c506434c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/b8141418069e471e89de1ba3c506434c.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3541,
          name: "Classic Royals",
          uuid: "8144c541bda24bf8bcd991a269993bb4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/8144c541bda24bf8bcd991a269993bb4.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3542,
          name: "Classic Royals Mobile",
          uuid: "40238e9922044242b9dfbb3d5d28c08f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/40238e9922044242b9dfbb3d5d28c08f.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3537,
          name: "Coliseu Bingo",
          uuid: "d459a06e49a27ccfc3ec37da9f19bcccd3a12bcc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/d459a06e49a27ccfc3ec37da9f19bcccd3a12bcc.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3538,
          name: "Coliseu Bingo Mobile",
          uuid: "5f17275b21f95097cf232187d47b826435b4c93e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/5f17275b21f95097cf232187d47b826435b4c93e.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3345,
          name: "Crazy Lab",
          uuid: "4e276a6ecb2f4c6c8e8d56a5ac403a15",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/4e276a6ecb2f4c6c8e8d56a5ac403a15.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3346,
          name: "Crazy Lab Mobile",
          uuid: "d34bd835d9424690ba39252f63afc286",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/d34bd835d9424690ba39252f63afc286.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3379,
          name: "Cruise of Fortune",
          uuid: "1a5aa7898366481289334e91a324e3af",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/1a5aa7898366481289334e91a324e3af.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3380,
          name: "Cruise of Fortune Mobile",
          uuid: "55a2ee8b52c142ce9a39a6f256449ada",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/55a2ee8b52c142ce9a39a6f256449ada.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3431,
          name: "Crystal Unicorn",
          uuid: "4ed772b5967e404c871ad68980ff41ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/4ed772b5967e404c871ad68980ff41ae.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3432,
          name: "Crystal Unicorn Mobile",
          uuid: "1487059eb17540a9ba442b9f29c51c03",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/1487059eb17540a9ba442b9f29c51c03.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3407,
          name: "Cutey Cats",
          uuid: "cca364f99d6e4fdb8ff89c5c55867b38",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/cca364f99d6e4fdb8ff89c5c55867b38.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3408,
          name: "Cutey Cats Mobile",
          uuid: "662c11e7984f48319763a2e4deac7fda",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/662c11e7984f48319763a2e4deac7fda.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3539,
          name: "Dama da Fortuna Bingo",
          uuid: "7c053cdf8d904d148341dd4a984a90e8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7c053cdf8d904d148341dd4a984a90e8.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3540,
          name: "Dama da Fortuna Bingo Mobile",
          uuid: "0ad8669caf684cea8948cce21bc4bb9f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/0ad8669caf684cea8948cce21bc4bb9f.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3385,
          name: "Diwali Lights",
          uuid: "0319db2232c54ed3a4b977f07ad9f3a0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/0319db2232c54ed3a4b977f07ad9f3a0.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3386,
          name: "Diwali Lights Mobile",
          uuid: "0434064b6a634c09bbdcdcdad899b0cb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/0434064b6a634c09bbdcdcdad899b0cb.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3353,
          name: "Dragon Rising",
          uuid: "8d945826e1304ff9880ebc588947012d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/8d945826e1304ff9880ebc588947012d.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3354,
          name: "Dragon Rising Mobile",
          uuid: "40241f56516941a8a4506bc0dd406ed0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/40241f56516941a8a4506bc0dd406ed0.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3449,
          name: "Dressing Room",
          uuid: "9effcb243a194bccabc0a59e233167b7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/9effcb243a194bccabc0a59e233167b7.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3450,
          name: "Dressing Room Mobile",
          uuid: "27193103f03d426d80afaca61a838650",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/27193103f03d426d80afaca61a838650.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3393,
          name: "Enchanted Cash",
          uuid: "c130fbf9621b46ce9fee75923a6cb5ed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/c130fbf9621b46ce9fee75923a6cb5ed.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3394,
          name: "Enchanted Cash Mobile",
          uuid: "99424afd34fc4e73ab032c6d57808707",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/99424afd34fc4e73ab032c6d57808707.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3403,
          name: "Feel The Music",
          uuid: "7919f2380b064a80aff9bc29e6ef6443",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7919f2380b064a80aff9bc29e6ef6443.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3404,
          name: "Feel The Music Mobile",
          uuid: "4ef06b7509d1403aab13509f16b91493",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/4ef06b7509d1403aab13509f16b91493.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3535,
          name: "Fishing Season",
          uuid: "febb8c69fe893f5db6e6dd3de0cd99df27514fe4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/febb8c69fe893f5db6e6dd3de0cd99df27514fe4.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3536,
          name: "Fishing Season Mobile",
          uuid: "8192ff55c8344fae422cb469ed2b6e2acda21d2e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/8192ff55c8344fae422cb469ed2b6e2acda21d2e.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3533,
          name: "Football Lotto",
          uuid: "32d46bd830e04b26be7234aa3166a8c9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/32d46bd830e04b26be7234aa3166a8c9.png",
          type: "lottery",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3534,
          name: "Football Lotto Mobile",
          uuid: "a0c1e25132bd44f1b994314550fa3c02",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/a0c1e25132bd44f1b994314550fa3c02.png",
          type: "lottery",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3341,
          name: "Football Pro",
          uuid: "89d4b370c69a4f118db1293654cb4589",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/89d4b370c69a4f118db1293654cb4589.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3342,
          name: "Football Pro Mobile",
          uuid: "3a60a76d4e76439b8a1be474011dc39e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/3a60a76d4e76439b8a1be474011dc39e.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3349,
          name: "Fright Night",
          uuid: "2493b523d01f4c4fab269c5d2297e533",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/2493b523d01f4c4fab269c5d2297e533.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3350,
          name: "Fright Night Mobile",
          uuid: "862c345582fa40cab37cc63c1921fd1c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/862c345582fa40cab37cc63c1921fd1c.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3369,
          name: "Frozen Fluffies",
          uuid: "756b4140c7c54ecca7e40e91e5b927ed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/756b4140c7c54ecca7e40e91e5b927ed.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3370,
          name: "Frozen Fluffies Mobile",
          uuid: "8ab50f9072d145f2869b5502f3a58f88",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/8ab50f9072d145f2869b5502f3a58f88.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3521,
          name: "Fruitverse",
          uuid: "99166c085feeb3d9b6321bb8ff0d7a4b98a29bbe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/99166c085feeb3d9b6321bb8ff0d7a4b98a29bbe.png",
          type: "videobingos",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3522,
          name: "Fruitverse Mobile",
          uuid: "f81799dd4b063d7e2d48759d6e2cd9a5e31ae4d1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/f81799dd4b063d7e2d48759d6e2cd9a5e31ae4d1.png",
          type: "videobingos",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3559,
          name: "Garoto de Ipanema Bingo",
          uuid: "96e2ac53f820456bbaa4a93514f4e9ba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/96e2ac53f820456bbaa4a93514f4e9ba.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3560,
          name: "Garoto de Ipanema Bingo Mobile",
          uuid: "35270ae92af54341a0f7aa99eb0928f4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/35270ae92af54341a0f7aa99eb0928f4.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3401,
          name: "Gold Fever",
          uuid: "74acb9a193ad4fc89e6620b6373fa94b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/74acb9a193ad4fc89e6620b6373fa94b.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3402,
          name: "Gold Fever Mobile",
          uuid: "8036599c3ec84b56ab6595eb498b7160",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/8036599c3ec84b56ab6595eb498b7160.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3576,
          name: "Golden Dragon Bingo",
          uuid: "cecba1e8cb224c818e8aed6676acc30d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/cecba1e8cb224c818e8aed6676acc30d.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3575,
          name: "Golden Dragon Bingo Mobile",
          uuid: "7b927941abc541b4a0d564495f9516db",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7b927941abc541b4a0d564495f9516db.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3572,
          name: "Grandma Fortune",
          uuid: "284dedcc74cebfbdbd5c4a0685118c327fe292f2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/284dedcc74cebfbdbd5c4a0685118c327fe292f2.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3571,
          name: "Grandma Fortune Mobile",
          uuid: "9abbd1b3817d60fdc8fb211460487dc416814bf2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/9abbd1b3817d60fdc8fb211460487dc416814bf2.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3493,
          name: "Halloween Keno",
          uuid: "55a68abddf3a8e7eab0afa47294b2fed5fc02aa9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/55a68abddf3a8e7eab0afa47294b2fed5fc02aa9.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3494,
          name: "Halloween Keno Mobile",
          uuid: "8c121c895bff4e76429942161a64617861a69d26",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8c121c895bff4e76429942161a64617861a69d26.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3499,
          name: "Halloween Lotto",
          uuid: "f32e946f5e04fd941cc7ca50ce45c9360981a780",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f32e946f5e04fd941cc7ca50ce45c9360981a780.png",
          type: "lottery",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3500,
          name: "Halloween Lotto Mobile",
          uuid: "9043ecf850d6f0da79b9c0d78fcee52a3507c4cb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9043ecf850d6f0da79b9c0d78fcee52a3507c4cb.png",
          type: "lottery",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3495,
          name: "Halloween Pick'em",
          uuid: "33ed38c6a61fa66a076bc5fa92eb960fd9278796",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/33ed38c6a61fa66a076bc5fa92eb960fd9278796.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3496,
          name: "Halloween Pickem Mobile",
          uuid: "98c9b0ae52253810133d8562c2f925c98d33dd84",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/98c9b0ae52253810133d8562c2f925c98d33dd84.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3479,
          name: "Halloween Scratchcard",
          uuid: "339723924d7d54601a353304ee67a5c9d97d53b5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/339723924d7d54601a353304ee67a5c9d97d53b5.png",
          type: "scratch card",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3480,
          name: "Halloween Scratchcard Mobile",
          uuid: "fca36ae4c6f9b2c08b2ce05ead41bb633810baf4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fca36ae4c6f9b2c08b2ce05ead41bb633810baf4.png",
          type: "scratch card",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3409,
          name: "Hallowinner",
          uuid: "54411c48617446b797269cf848149550",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/54411c48617446b797269cf848149550.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3410,
          name: "Hallowinner Mobile",
          uuid: "a4679ab4249d4c5fbd435d71a4334d2a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/a4679ab4249d4c5fbd435d71a4334d2a.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3383,
          name: "Heroes Empire",
          uuid: "a0e1f713b16c4b098693e6ba8f2d45ed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/a0e1f713b16c4b098693e6ba8f2d45ed.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3384,
          name: "Heroes Empire Mobile",
          uuid: "1e983db621b94300b45106eb8e3fac96",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/1e983db621b94300b45106eb8e3fac96.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3375,
          name: "Hidden Kingdom",
          uuid: "50d9c96500af49428e0b763d60150662",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/50d9c96500af49428e0b763d60150662.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3376,
          name: "Hidden Kingdom Mobile",
          uuid: "edc4f839297a471597eb63b09859ac26",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/edc4f839297a471597eb63b09859ac26.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3397,
          name: "Hungry Chef",
          uuid: "7f895915dba04626aacfe4f0d70cec64",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7f895915dba04626aacfe4f0d70cec64.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3398,
          name: "Hungry Chef Mobile",
          uuid: "46be703797aa4580b9875f97f5590146",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/46be703797aa4580b9875f97f5590146.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3475,
          name: "Hungry Chef Pickem",
          uuid: "0cffc2e7b3e54808bcdf881d3726cf95",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/0cffc2e7b3e54808bcdf881d3726cf95.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3476,
          name: "Hungry Chef Pickem Mobile",
          uuid: "9b730b5ff1fd4ffab0b23ba53199e9d4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/9b730b5ff1fd4ffab0b23ba53199e9d4.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3371,
          name: "HurriCash",
          uuid: "1f01967076cf4bc08dcdf4fa8d520106",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/1f01967076cf4bc08dcdf4fa8d520106.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3372,
          name: "HurriCash Mobile",
          uuid: "013ef71a3ee049d08de77b96f99efc0e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/013ef71a3ee049d08de77b96f99efc0e.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3563,
          name: "Jingle Bell Bingo",
          uuid: "49d20a2b3157dd7115356390b4fe2ccff6e87e6d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/49d20a2b3157dd7115356390b4fe2ccff6e87e6d.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3564,
          name: "Jingle Bell Bingo Mobile",
          uuid: "bd9040b489693f81adf26800722054225c0f91a4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/bd9040b489693f81adf26800722054225c0f91a4.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3453,
          name: "Jungle Keno",
          uuid: "ed48dc251974466a9a7939a17863a18c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/ed48dc251974466a9a7939a17863a18c.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3454,
          name: "Jungle Keno Mobile",
          uuid: "f5928086d117475ebffeed622e173363",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/f5928086d117475ebffeed622e173363.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3343,
          name: "Kabaddi Raiders",
          uuid: "7f0e043adc67434b8c16363acfdece00",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7f0e043adc67434b8c16363acfdece00.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3344,
          name: "Kabaddi Raiders Mobile",
          uuid: "3a81674b035a44c380e82e0e490ae493",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/3a81674b035a44c380e82e0e490ae493.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3531,
          name: "Keno Goal",
          uuid: "7c16968cf079437abbeaefef7911f8be",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7c16968cf079437abbeaefef7911f8be.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3532,
          name: "Keno Goal Mobile",
          uuid: "dc0947c7d77348489ad0d4a7d6f93ad3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/dc0947c7d77348489ad0d4a7d6f93ad3.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3457,
          name: "Lords Balcony",
          uuid: "40712412141948138197390b92bbe71a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/40712412141948138197390b92bbe71a.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3458,
          name: "Lords Balcony Mobile",
          uuid: "f6234742538d4e9db6a97d7827ca1ba3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/f6234742538d4e9db6a97d7827ca1ba3.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3395,
          name: "Lost Saga",
          uuid: "67c593180a8846f1b53b3a46c3fdd3af",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/67c593180a8846f1b53b3a46c3fdd3af.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3396,
          name: "Lost Saga Mobile",
          uuid: "c00077c13c93407fbca3934848c7bef7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/c00077c13c93407fbca3934848c7bef7.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3373,
          name: "Lucky Express",
          uuid: "ac2a1b3aeb8b4f2585b5716f73170e50",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/ac2a1b3aeb8b4f2585b5716f73170e50.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3374,
          name: "Lucky Express Mobile",
          uuid: "7b1e904d141547e2ad4c5c79c744ba37",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7b1e904d141547e2ad4c5c79c744ba37.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3451,
          name: "Lucky K",
          uuid: "c90475d30dde4158ab804be3d3d89edc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/c90475d30dde4158ab804be3d3d89edc.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3452,
          name: "Lucky K Mobile",
          uuid: "d4a749f5dba44497bb0491bd82f5abb5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/d4a749f5dba44497bb0491bd82f5abb5.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3367,
          name: "Madame Fortune",
          uuid: "2d1b7c9813b74b1a9e9f1be99d5fbfd9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/2d1b7c9813b74b1a9e9f1be99d5fbfd9.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3368,
          name: "Madame Fortune Mobile",
          uuid: "ef90fc8959e54d70ae3ab1b1d98748af",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/ef90fc8959e54d70ae3ab1b1d98748af.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3391,
          name: "Magic Forest",
          uuid: "ac54a0c18da440539828fa54a03d32de",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/ac54a0c18da440539828fa54a03d32de.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3392,
          name: "Magic Forest Mobile",
          uuid: "4f66b552e05d4f17ac57cf449c243cee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/4f66b552e05d4f17ac57cf449c243cee.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3429,
          name: "Magical Keno",
          uuid: "bea225dcc64b48f4af4d012e5251d364",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/bea225dcc64b48f4af4d012e5251d364.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3430,
          name: "Magical Keno Mobile",
          uuid: "7316625583ff4ff18ef492b93b888d29",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7316625583ff4ff18ef492b93b888d29.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3557,
          name: "Mega Bingo",
          uuid: "853ddf86dfe5499bbeb87cfe74ca44f5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/853ddf86dfe5499bbeb87cfe74ca44f5.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3558,
          name: "Mega Bingo Mobile",
          uuid: "6126a12c1e4042ceb28d2081ff0b768e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/6126a12c1e4042ceb28d2081ff0b768e.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3573,
          name: "Mine Medal",
          uuid: "f157728115d34c2a9a132885b27619af",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/f157728115d34c2a9a132885b27619af.png",
          type: "instant win",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3574,
          name: "Mine Medal Mobile",
          uuid: "d3aaf6b5184d415f8da06748d83fec61",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/d3aaf6b5184d415f8da06748d83fec61.png",
          type: "instant game",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3461,
          name: "Ocean Richies",
          uuid: "df543d319e12469dadeda940e290dc5b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/df543d319e12469dadeda940e290dc5b.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3462,
          name: "Ocean Richies Mobile",
          uuid: "177f801d2dff41a1a2e35ef60ee0138a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/177f801d2dff41a1a2e35ef60ee0138a.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3515,
          name: "Paradise Trippies Bingo",
          uuid: "57fd346f70cda4dc34f74eebbac84c9f02f18287",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/57fd346f70cda4dc34f74eebbac84c9f02f18287.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3516,
          name: "Paradise Trippies Bingo Mobile",
          uuid: "6060618c2965206d599c78f46ef7b3cd9d464fa5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6060618c2965206d599c78f46ef7b3cd9d464fa5.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3519,
          name: "Paradise Trippies Slot",
          uuid: "9aa61399f4efbc0dc99558b03cc10a7de7cb15be",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9aa61399f4efbc0dc99558b03cc10a7de7cb15be.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3520,
          name: "Paradise Trippies Slot Mobile",
          uuid: "a2ca9e5cab76d856e4a47d9617fe656e6375ab97",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a2ca9e5cab76d856e4a47d9617fe656e6375ab97.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3473,
          name: "Pickem Fruits",
          uuid: "f3b06bbf585b4f90b82a0fdbcdc20d35",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/f3b06bbf585b4f90b82a0fdbcdc20d35.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3474,
          name: "Pickem Fruits Mobile",
          uuid: "f08b99dc0ff54ebc83f9e22895689c60",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/f08b99dc0ff54ebc83f9e22895689c60.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3545,
          name: "Pìggy Show Bingo",
          uuid: "cd62f8506864443488fa17e091a4e10a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/cd62f8506864443488fa17e091a4e10a.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3546,
          name: "Pìggy Show Bingo Mobile",
          uuid: "0f8853c8ffce42b3804918eb5e0b34d0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/0f8853c8ffce42b3804918eb5e0b34d0.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3355,
          name: "Pirates of Fortune",
          uuid: "0c240ce39c334633a80cbee9136acaaf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/0c240ce39c334633a80cbee9136acaaf.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3356,
          name: "Pirates of Fortune Mobile",
          uuid: "6318e0b623ca492e9b1a3b2c90a2bf37",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/6318e0b623ca492e9b1a3b2c90a2bf37.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3361,
          name: "Premier League Cricket",
          uuid: "d9683dc534ff4afb9b672e648e3355ad",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/d9683dc534ff4afb9b672e648e3355ad.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3362,
          name: "Premier League Cricket Mobile",
          uuid: "5e583eb993e34b6e85d1f62840c76f5e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/5e583eb993e34b6e85d1f62840c76f5e.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3469,
          name: "Princess of the Ocean",
          uuid: "1651a80c661c4527ad50eb1e2a284ff9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/1651a80c661c4527ad50eb1e2a284ff9.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3470,
          name: "Princess of the Ocean Mobile",
          uuid: "6d629a8461024cf58c9dd91487e4d034",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/6d629a8461024cf58c9dd91487e4d034.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3527,
          name: "Rainbow Keno",
          uuid: "d5d8bac546554141bd58fc7f70746ba9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/d5d8bac546554141bd58fc7f70746ba9.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3528,
          name: "Rainbow Keno Mobile",
          uuid: "15b27f7aa4484d1ea0a3adec967f17ef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/15b27f7aa4484d1ea0a3adec967f17ef.png",
          type: "keno",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3447,
          name: "RCT - Halloween",
          uuid: "8563c360a5114919a23caffd1594f7f1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/8563c360a5114919a23caffd1594f7f1.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3448,
          name: "RCT - Halloween Mobile",
          uuid: "7ac899565e6844ef8b5042ec990d648f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7ac899565e6844ef8b5042ec990d648f.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3425,
          name: "RCT - New Fruit",
          uuid: "e7cb5c6a92e645d3a3c84d7c4ee6287a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/e7cb5c6a92e645d3a3c84d7c4ee6287a.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3426,
          name: "RCT - New Fruit Mobile",
          uuid: "7935e95008044d08b5fdfa7bf66c25ad",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/7935e95008044d08b5fdfa7bf66c25ad.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3529,
          name: "RCT - Red Sheriff",
          uuid: "3677dc3a2c0245339e711a7dcabef249",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/3677dc3a2c0245339e711a7dcabef249.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3530,
          name: "RCT - Red Sheriff Mobile",
          uuid: "e19e8983542f4b6fb1cedf728e06b423",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/e19e8983542f4b6fb1cedf728e06b423.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3423,
          name: "RCT - Reis do Egito",
          uuid: "2f3a92575aea42369498cd9f19f133f8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/2f3a92575aea42369498cd9f19f133f8.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3424,
          name: "RCT - Reis do Egito Mobile",
          uuid: "73c1dcf1477d4eb3b943c6920c6cb045",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/73c1dcf1477d4eb3b943c6920c6cb045.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3415,
          name: "RCT - Rio de Janeiro",
          uuid: "5088e0c9edc5435f82b6e340eb1f2351",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/5088e0c9edc5435f82b6e340eb1f2351.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3416,
          name: "RCT - Rio de Janeiro Mobile",
          uuid: "8c0f0fa34ade482583a1204ce8dc25c5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/8c0f0fa34ade482583a1204ce8dc25c5.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3377,
          name: "RCT - Samba",
          uuid: "74c0aced5e9c4989b7caee94c9071381",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/74c0aced5e9c4989b7caee94c9071381.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3378,
          name: "RCT - Samba Mobile",
          uuid: "421e96d713d94a4eaaabb71801ec036d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/421e96d713d94a4eaaabb71801ec036d.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3477,
          name: "RCT - Talismanes",
          uuid: "b876201308c3cf8f6710330bd2f6a8f692aa5f45",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b876201308c3cf8f6710330bd2f6a8f692aa5f45.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3478,
          name: "RCT - Talismanes Mobile",
          uuid: "33f4382b9b79bf6f77310b95654f96f4d52d9e4f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/33f4382b9b79bf6f77310b95654f96f4d52d9e4f.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3523,
          name: "Remember Remember",
          uuid: "887f0571b78e97df10aac8ff11b81fce5d1cbd32",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/887f0571b78e97df10aac8ff11b81fce5d1cbd32.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3524,
          name: "Remember Remember Mobile",
          uuid: "220ae0ca18583a6280b24ddefa2713fd255b20d0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/220ae0ca18583a6280b24ddefa2713fd255b20d0.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3549,
          name: "Rock n' Reels",
          uuid: "734df9bfb81799987825fbc7235199cea718da70",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/734df9bfb81799987825fbc7235199cea718da70.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3550,
          name: "Rock n' Reels Mobile",
          uuid: "022af7db1ef7ac9a199701a5ae34d313902c155f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/022af7db1ef7ac9a199701a5ae34d313902c155f.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3551,
          name: "Tesoro Maya",
          uuid: "f12d5b1d4efe4896b3988dc58046cc0a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/f12d5b1d4efe4896b3988dc58046cc0a.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3552,
          name: "Tesoro Maya Mobile",
          uuid: "b1b08b5aa3f043e889e99618a1036da3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/b1b08b5aa3f043e889e99618a1036da3.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3363,
          name: "Torch Of Fire",
          uuid: "ec8eaf6cca204e1fb24f9e62cd6ea5a8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/ec8eaf6cca204e1fb24f9e62cd6ea5a8.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3364,
          name: "Torch Of Fire Mobile",
          uuid: "dbf9690fe26340688893d0555070eeab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/dbf9690fe26340688893d0555070eeab.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3481,
          name: "Trevo da Sorte Scratchcard",
          uuid: "fe7e1a2afed56456287a4509d488381818aae9a9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fe7e1a2afed56456287a4509d488381818aae9a9.png",
          type: "scratch card",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3482,
          name: "Trevo da Sorte Scratchcard Mobile",
          uuid: "046af34490436a9464fb8aa8b1363eef0ffefed5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/046af34490436a9464fb8aa8b1363eef0ffefed5.png",
          type: "scratch card",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3579,
          name: "Trial of the Gods",
          uuid: "d9dd838d6616412bbd25cdd668dd7dbe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/d9dd838d6616412bbd25cdd668dd7dbe.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3580,
          name: "Trial of the Gods Mobile",
          uuid: "0ea076157c7549c6963d8a2bd657723e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/0ea076157c7549c6963d8a2bd657723e.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3357,
          name: "Vegas Baby",
          uuid: "4ee4eb33ebff4dbcbae6181912badb85",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/4ee4eb33ebff4dbcbae6181912badb85.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3358,
          name: "Vegas Baby Mobile",
          uuid: "80bba1772f83417fb9819d3e5b59b05e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/80bba1772f83417fb9819d3e5b59b05e.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3465,
          name: "Viking Madness",
          uuid: "29846ecc957142428896c97f8fa1f80a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/29846ecc957142428896c97f8fa1f80a.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3466,
          name: "Viking Madness Mobile",
          uuid: "5b1f395a83a446ca99bbf0c55ba5c65e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/5b1f395a83a446ca99bbf0c55ba5c65e.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3567,
          name: "Viking Madness Xtreme",
          uuid: "05748e421ca248f5a5c83ad5d85e6bb5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/05748e421ca248f5a5c83ad5d85e6bb5.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3568,
          name: "Viking Madness Xtreme Mobile",
          uuid: "f2ad1cae82754389a3d4f105ef25977c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/f2ad1cae82754389a3d4f105ef25977c.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3347,
          name: "Village Brewery",
          uuid: "e5375206e18f4be8ba3de1da1df908c5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/e5375206e18f4be8ba3de1da1df908c5.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3348,
          name: "Village Brewery Mobile",
          uuid: "10d56807430d43e0aad7ecb8c18dec5d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/10d56807430d43e0aad7ecb8c18dec5d.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3553,
          name: "Whale Bingo",
          uuid: "18e64bb0a3272b9b792697c2fbd45ccb8a484d34",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/18e64bb0a3272b9b792697c2fbd45ccb8a484d34.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3554,
          name: "Whale Bingo Mobile",
          uuid: "33d30e35868ffb695353573b3d8b037affd0dad5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/33d30e35868ffb695353573b3d8b037affd0dad5.png",
          type: "bingo",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3565,
          name: "Whale of Fortune",
          uuid: "19c38d839f7aca2c4b7f35c5936fb78f31e622f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/19c38d839f7aca2c4b7f35c5936fb78f31e622f4.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3566,
          name: "Whale of Fortune Mobile",
          uuid: "2817a1960e22d4acffd30b6ad44760a25e4cf061",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/2817a1960e22d4acffd30b6ad44760a25e4cf061.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3381,
          name: "Wild Fruit",
          uuid: "ebf3f952bcd9448ca7b598c57c95c419",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/ebf3f952bcd9448ca7b598c57c95c419.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3382,
          name: "Wild Fruit Mobile",
          uuid: "2b698ca32b26472fbd63b2ed9c0fc127",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Caleta/2b698ca32b26472fbd63b2ed9c0fc127.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3525,
          name: "World Wild Cup",
          uuid: "4d7d80f42273b0e3a5f90e23034c14096f1686de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/4d7d80f42273b0e3a5f90e23034c14096f1686de.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3526,
          name: "World Wild Cup Mobile",
          uuid: "a1bff3ccf6c9bd61e99eb0379f85b6bbe692a78a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Caleta/a1bff3ccf6c9bd61e99eb0379f85b6bbe692a78a.png",
          type: "slots",
          provider: "Caleta",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 114,
      name: "ConceptGaming",
      providerId: 45,
      providerName: "ConceptGaming",
      games: [
        {
          game_id: 3592,
          name: "1 In A Million",
          uuid: "18197f5b2171491e8122810b75841a35",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/18197f5b2171491e8122810b75841a35.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3667,
          name: "5 Handed American Blackjack",
          uuid: "e035199a344b4fbf832680ecffe55c01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/e035199a344b4fbf832680ecffe55c01.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3686,
          name: "50:50 Blackjack",
          uuid: "0fd6583f58fe9b258874a200e0c0b27586f2ece3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/0fd6583f58fe9b258874a200e0c0b27586f2ece3.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3668,
          name: "7 Handed Blackjack (American)",
          uuid: "7b4fad6dc8624f0885c41255e4499c18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/7b4fad6dc8624f0885c41255e4499c18.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3669,
          name: "7 Handed Blackjack (European)",
          uuid: "beb15076a0e34ed7ba90e35244536039",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/beb15076a0e34ed7ba90e35244536039.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3660,
          name: "Abandoned Park",
          uuid: "4af60ff741854d138d87acd45367884b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/4af60ff741854d138d87acd45367884b.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3582,
          name: "ACE X",
          uuid: "9c532d026290418d9806f4fe75228261",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/9c532d026290418d9806f4fe75228261.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3683,
          name: "Alien Adventure",
          uuid: "33257c2dd86c4de8bc5217a239a2ab0c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/33257c2dd86c4de8bc5217a239a2ab0c.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3604,
          name: "American Roulette",
          uuid: "35694e22c2cb4033846ea521309bbe9b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/35694e22c2cb4033846ea521309bbe9b.png",
          type: "roulette",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3635,
          name: "Ancient China",
          uuid: "96685df21c76478090e7fe06b7738775",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/96685df21c76478090e7fe06b7738775.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3662,
          name: "Andar Bahar",
          uuid: "bfe5a475ff584ab7881b4094a19b1046",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/bfe5a475ff584ab7881b4094a19b1046.png",
          type: "table",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3596,
          name: "Area 51",
          uuid: "8248699d24ca441bbfecc3e9c6ba9be2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/8248699d24ca441bbfecc3e9c6ba9be2.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3633,
          name: "Atlantis",
          uuid: "f2328676ef49470abe5e80988b7fdf1c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/f2328676ef49470abe5e80988b7fdf1c.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3626,
          name: "Baccarat",
          uuid: "5d42d220384b49ad8444348b7219037c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/5d42d220384b49ad8444348b7219037c.png",
          type: "baccarat",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3648,
          name: "BC Bonus",
          uuid: "f25a54cec4c6401bb569ff26fae70b36",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/f25a54cec4c6401bb569ff26fae70b36.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3599,
          name: "Beautiful Asia",
          uuid: "b91ec4a80e614c8fa5b3ae084e010ff6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/b91ec4a80e614c8fa5b3ae084e010ff6.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3645,
          name: "Big Top Circus",
          uuid: "fbe716fc925c439693f903eaabf44689",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/fbe716fc925c439693f903eaabf44689.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3598,
          name: "Big Vegas",
          uuid: "eb0c90cc8abd49fa94df635dca17308b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/eb0c90cc8abd49fa94df635dca17308b.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3653,
          name: "Bikini Beach",
          uuid: "14bd75e0e0a04be98a4a8ad3a7d1b63c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/14bd75e0e0a04be98a4a8ad3a7d1b63c.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3610,
          name: "Blood Money",
          uuid: "2cac9569eeeb4142adb20887b5b27c30",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/2cac9569eeeb4142adb20887b5b27c30.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3670,
          name: "Book of Treasures",
          uuid: "387a9307ab614e7d8b8187b2283f925f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/387a9307ab614e7d8b8187b2283f925f.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3665,
          name: "Book of Treasures - Returns",
          uuid: "8b02f2e76564f1d2fab7f6a00bca6d5d1baa3868",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/8b02f2e76564f1d2fab7f6a00bca6d5d1baa3868.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3650,
          name: "Bucanieros Max",
          uuid: "4505aa5370754a93816564c3df8077c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/4505aa5370754a93816564c3df8077c0.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3644,
          name: "Cash Detective",
          uuid: "c8720f57020d46349e3de6d788e5f0dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/c8720f57020d46349e3de6d788e5f0dd.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3671,
          name: "Chamber of Treasures",
          uuid: "784b5d40ad5749518548d0523da7578b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/784b5d40ad5749518548d0523da7578b.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3600,
          name: "China Fortunes",
          uuid: "96bebdb471e74f6fa220cdcf80086a12",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/96bebdb471e74f6fa220cdcf80086a12.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3602,
          name: "Classic Multi-Hand Blackjack (Black)",
          uuid: "16a81a508c3b44a9a3f4ce1ebf47680d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/16a81a508c3b44a9a3f4ce1ebf47680d.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3603,
          name: "Classic Multi-Hand Blackjack (Red)",
          uuid: "37051c1d0e6c483da43590a7af5a139a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/37051c1d0e6c483da43590a7af5a139a.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3640,
          name: "Club Spin",
          uuid: "be9fcd72107e4570a92a659146d2a661",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/be9fcd72107e4570a92a659146d2a661.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3589,
          name: "Craps",
          uuid: "a021a48a168747d8927a1d52e1e1cdd5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/a021a48a168747d8927a1d52e1e1cdd5.png",
          type: "craps",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3618,
          name: "Cretaceous Park",
          uuid: "ff36ed4373b04839b369c6bd297fcb27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/ff36ed4373b04839b369c6bd297fcb27.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3672,
          name: "Deuces Wild Gamble",
          uuid: "7d317d105d594ef1851fe6a98ab64e5a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/7d317d105d594ef1851fe6a98ab64e5a.png",
          type: "poker",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3611,
          name: "Diamond Times",
          uuid: "0522664e83594639ac58428e048b9063",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/0522664e83594639ac58428e048b9063.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3583,
          name: "Diego Dollars",
          uuid: "c93bbb1e90b14654ba97582be09c81dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/c93bbb1e90b14654ba97582be09c81dd.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3609,
          name: "Double Diamond Times",
          uuid: "f2aadde721474c70ade399dbe4bd55da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/f2aadde721474c70ade399dbe4bd55da.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3642,
          name: "Eastern Promises",
          uuid: "9680de6ec5054f9495f1e7ed6b18e248",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/9680de6ec5054f9495f1e7ed6b18e248.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3615,
          name: "Firestorm",
          uuid: "7954a356518e43a0b0bacd22e521e502",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/7954a356518e43a0b0bacd22e521e502.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3630,
          name: "Five Hand Vegas Blackjack",
          uuid: "96f6c1cc41924f52b0f502a8f950a223",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/96f6c1cc41924f52b0f502a8f950a223.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3631,
          name: "Five Hand Vegas Blackjack V2",
          uuid: "f0cbebf07d37499da9817c0dc2d964ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/f0cbebf07d37499da9817c0dc2d964ea.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3614,
          name: "Fortunes of China",
          uuid: "a116c38a5db24587841e34d9de60104f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/a116c38a5db24587841e34d9de60104f.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3591,
          name: "Frozen Fortunes",
          uuid: "150a5c5f4c234b52ae3fed884184fdfd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/150a5c5f4c234b52ae3fed884184fdfd.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3595,
          name: "Fruit Crush",
          uuid: "7fc5a504dc8f4c41901f83a22a60bb18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/7fc5a504dc8f4c41901f83a22a60bb18.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3624,
          name: "Fruit Loot",
          uuid: "2252a17cc9ac48b9b0c4cc913a6fda25",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/2252a17cc9ac48b9b0c4cc913a6fda25.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3625,
          name: "Fruit Loot Reboot",
          uuid: "9914247b6278472eba5ab800899bfbd5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/9914247b6278472eba5ab800899bfbd5.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3593,
          name: "Gangsters of New York",
          uuid: "35809140639d44808f0d91504d4ac44f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/35809140639d44808f0d91504d4ac44f.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3622,
          name: "Gold n Gems",
          uuid: "9d975d7a6d35408dba91fdd9d8f077e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/9d975d7a6d35408dba91fdd9d8f077e0.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3623,
          name: "Gold n Gems Instant",
          uuid: "ee09ea277e14403cb00af3f64cbb4f74",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/ee09ea277e14403cb00af3f64cbb4f74.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3641,
          name: "Gold Vault",
          uuid: "e274364989f04a989bccc0c21ad9a080",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/e274364989f04a989bccc0c21ad9a080.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3673,
          name: "Greek Gods",
          uuid: "5ce5de53ff824f059a88814aa0b746fb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/5ce5de53ff824f059a88814aa0b746fb.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3684,
          name: "Heroes Of Fortunes",
          uuid: "b0e3215db6395ffde6b17dc975689a4154a34519",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/b0e3215db6395ffde6b17dc975689a4154a34519.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3649,
          name: "High Noon Saloon",
          uuid: "fd05340316e74b54862b535c69a8f49d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/fd05340316e74b54862b535c69a8f49d.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3636,
          name: "Hollywood Reels",
          uuid: "f8187568b5f94e38a4e7d3562cc6c300",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/f8187568b5f94e38a4e7d3562cc6c300.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3590,
          name: "Jackpot Blackjack",
          uuid: "59bce598eebf465b95f27b6b2e4c48fb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/59bce598eebf465b95f27b6b2e4c48fb.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3616,
          name: "Keno Fortunes",
          uuid: "ea7fdfefddf54deb9c818a448b57a8bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/ea7fdfefddf54deb9c818a448b57a8bb.png",
          type: "keno",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3654,
          name: "Keno Jackpot",
          uuid: "851756e7f27c4156ae63d3b4b661123a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/851756e7f27c4156ae63d3b4b661123a.png",
          type: "keno",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3617,
          name: "Keno Vegas",
          uuid: "8ed3e10f544043e28b826f24ef990f81",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/8ed3e10f544043e28b826f24ef990f81.png",
          type: "keno",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3659,
          name: "King Arthurs Riches",
          uuid: "5971951476564adfb9edb626fa94849f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/5971951476564adfb9edb626fa94849f.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3619,
          name: "Koi Fortunes",
          uuid: "89a677a5d72b49caa26991fef44be166",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/89a677a5d72b49caa26991fef44be166.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3646,
          name: "Kung Fu Cash",
          uuid: "64d5305208c642c4ae1af9dab4caf54e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/64d5305208c642c4ae1af9dab4caf54e.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3585,
          name: "Lanna The Lucky",
          uuid: "cd8c783517b14221bb20e5afda203e5f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/cd8c783517b14221bb20e5afda203e5f.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3674,
          name: "Lucky Gems",
          uuid: "03ec3522ded74008b5dca8fabc3be56f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/03ec3522ded74008b5dca8fabc3be56f.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3666,
          name: "Mega Football",
          uuid: "fe02efe78eda4c109b10a16ba3289cd5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/fe02efe78eda4c109b10a16ba3289cd5.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3601,
          name: "Merry Christmas",
          uuid: "f0d8a0b8139141e89365c881424bc26f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/f0d8a0b8139141e89365c881424bc26f.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3656,
          name: "Money Machine",
          uuid: "7f0b1d5f523f4046b96a3e3c99419dc6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/7f0b1d5f523f4046b96a3e3c99419dc6.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3629,
          name: "Multi-Hand Blackjack",
          uuid: "ba5bbbde506c46df886056345100e052",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/ba5bbbde506c46df886056345100e052.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3628,
          name: "Multi-Hand Blackjack V2",
          uuid: "f7e49ce160fe449d9fbda555f6b2f51f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/f7e49ce160fe449d9fbda555f6b2f51f.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3586,
          name: "New Orleans Millions",
          uuid: "def3f8a892ff48d48130cdbf3743551f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/def3f8a892ff48d48130cdbf3743551f.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3613,
          name: "Pharoah's Gold",
          uuid: "1e6cacd8a4cb40d981797827887b6b2e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/1e6cacd8a4cb40d981797827887b6b2e.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3608,
          name: "Pharoah's Gold II",
          uuid: "400310c8008446019cc625bf336fef42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/400310c8008446019cc625bf336fef42.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3597,
          name: "Pirates - The Lost Treasure",
          uuid: "2a3431f971314ff081b88ef667fbb674",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/2a3431f971314ff081b88ef667fbb674.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3620,
          name: "Pompeii",
          uuid: "f9a2f8118c074b0cbe9030dcc4dadf23",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/f9a2f8118c074b0cbe9030dcc4dadf23.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3612,
          name: "Rainbow Farm",
          uuid: "a9e536711499433884f5a13c455b3b92",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/a9e536711499433884f5a13c455b3b92.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3607,
          name: "Rainbow Returns",
          uuid: "fcb81b6614d947b79151463f66ad61ed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/fcb81b6614d947b79151463f66ad61ed.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3651,
          name: "Reels Of Rome",
          uuid: "08c39d0d38a544c992af83ca9def935b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/08c39d0d38a544c992af83ca9def935b.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3627,
          name: "Roulette",
          uuid: "93c2ef957b3b4948bf37cece000b560f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/93c2ef957b3b4948bf37cece000b560f.png",
          type: "roulette",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3675,
          name: "Roulette II",
          uuid: "1f6501d74eec4f419fe5613041114972",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/1f6501d74eec4f419fe5613041114972.png",
          type: "roulette",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3682,
          name: "Runes of Valhalla",
          uuid: "91d4377ffb914e7ead5c8447b7c8a400",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/91d4377ffb914e7ead5c8447b7c8a400.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3605,
          name: "Samurai Gold",
          uuid: "8dda25d1261440e3877821e9616f6a0e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/8dda25d1261440e3877821e9616f6a0e.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3606,
          name: "Samurai Gold 2",
          uuid: "73ec57c23edf4d42be320bbe24e74c25",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/73ec57c23edf4d42be320bbe24e74c25.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3655,
          name: "Secret Agent",
          uuid: "746285abc1be4055a92e0d64ce210139",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/746285abc1be4055a92e0d64ce210139.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3632,
          name: "Single Hand Blackjack",
          uuid: "e0ff765dc1f44effa2a96a8043b55567",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/e0ff765dc1f44effa2a96a8043b55567.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3639,
          name: "Slot Contraption",
          uuid: "bfe3efa876604a638aa52bf87a17747c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/bfe3efa876604a638aa52bf87a17747c.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3657,
          name: "Slot Monsters",
          uuid: "9c9609edf53944e5abdf0c53877aa041",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/9c9609edf53944e5abdf0c53877aa041.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3594,
          name: "Star Gems",
          uuid: "a045fc4fb590405baa21a85beb0d28a0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/a045fc4fb590405baa21a85beb0d28a0.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3584,
          name: "Star X",
          uuid: "9a3a3d6fe4ff4345afa86a80dcd2a37e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/9a3a3d6fe4ff4345afa86a80dcd2a37e.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3638,
          name: "Super Campeonato Brasileiro",
          uuid: "7d914941b4d844b881488218b93496e2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/7d914941b4d844b881488218b93496e2.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3637,
          name: "Super Halloween",
          uuid: "0c027182e3f240f893c814ab34a3080e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/0c027182e3f240f893c814ab34a3080e.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3663,
          name: "The Lost Symbols",
          uuid: "b93dfaad31194d81b098a54df6c47b41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/b93dfaad31194d81b098a54df6c47b41.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3661,
          name: "The Mining Machine",
          uuid: "7b3d6f3d92924ac9ae88576a98df260c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/7b3d6f3d92924ac9ae88576a98df260c.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3581,
          name: "The Vault",
          uuid: "8e5fb7d2c7294e179edfa9b735c4b02f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/8e5fb7d2c7294e179edfa9b735c4b02f.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3621,
          name: "Toy Box",
          uuid: "f0a7179fb6b44e5c949dda61e9296841",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/f0a7179fb6b44e5c949dda61e9296841.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3652,
          name: "Transylmania",
          uuid: "35ceb7d0b3c34e078c1dd308b1203250",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/35ceb7d0b3c34e078c1dd308b1203250.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3647,
          name: "Treasure of the Nile",
          uuid: "55cbc20ae4c545f690afc038a85a9aa4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/55cbc20ae4c545f690afc038a85a9aa4.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3685,
          name: "Triple Card Gamble",
          uuid: "35376dd773e0f999eb6ff809d63f0062ddadac96",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/35376dd773e0f999eb6ff809d63f0062ddadac96.png",
          type: "card",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3658,
          name: "Twisted Fairytales",
          uuid: "59dc3cb14f1d41478e0871cb1b0f8edc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/59dc3cb14f1d41478e0871cb1b0f8edc.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3676,
          name: "Ultimate Baccarat",
          uuid: "7922dbd4be8a45a4a82815c186a3f9b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/7922dbd4be8a45a4a82815c186a3f9b2.png",
          type: "baccarat",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3588,
          name: "Ultimate Blackjack",
          uuid: "6677946dd5a44d70bc0ddeadadb97477",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/6677946dd5a44d70bc0ddeadadb97477.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3587,
          name: "Ultimate Blackjack II",
          uuid: "fa93dde12ca54881bfe06c81089c7af8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/fa93dde12ca54881bfe06c81089c7af8.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3677,
          name: "Ultra One Hand Blackjack",
          uuid: "381f3811a9204903a6d43ccc47133339",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/381f3811a9204903a6d43ccc47133339.png",
          type: "blackjack",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3664,
          name: "Vegas Heaven",
          uuid: "a3754bb78ca147d3a16eb03f0e98a35a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/a3754bb78ca147d3a16eb03f0e98a35a.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3634,
          name: "Vegas II",
          uuid: "f557c7475a694a01972ba2a55e04e60a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/f557c7475a694a01972ba2a55e04e60a.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3678,
          name: "Vegas Party",
          uuid: "e89f5f0fe54b438482b2984b63a483fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/e89f5f0fe54b438482b2984b63a483fa.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3679,
          name: "Vegas Roulette",
          uuid: "4181777a4e6445cc888868b873cfc4ee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/4181777a4e6445cc888868b873cfc4ee.png",
          type: "roulette",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3643,
          name: "Vegas Slot III",
          uuid: "a55de09608e94a9080ac07cc5f1e065b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/a55de09608e94a9080ac07cc5f1e065b.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3680,
          name: "Vikings Hammer",
          uuid: "9e117f6266654a4aad175f5ee6f3971f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/9e117f6266654a4aad175f5ee6f3971f.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3681,
          name: "Vikings Hammer Returns",
          uuid: "80a2573f3f1548338c9ebb25eb310bad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ConceptGaming/80a2573f3f1548338c9ebb25eb310bad.png",
          type: "slots",
          provider: "ConceptGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 115,
      name: "Dlv",
      providerId: 22,
      providerName: "Dlv",
      games: [
        {
          game_id: 3724,
          name: "AKVARICO",
          uuid: "fa9dc1db6280617edcf87df5b65ae16d1360d0bf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fa9dc1db6280617edcf87df5b65ae16d1360d0bf.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3736,
          name: "ALCHEMY ELEMENTS",
          uuid: "d6527e8cd19e9fb4a344ef60a31dad815dfec670",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d6527e8cd19e9fb4a344ef60a31dad815dfec670.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3780,
          name: "Alchemy's Mystery",
          uuid: "573ec2a4055f8fcaa29c53313bdc96c2f14a001d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/573ec2a4055f8fcaa29c53313bdc96c2f14a001d.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3722,
          name: "ALTER GODS",
          uuid: "29d066073166d9523fd8ea341c67111a1edfc94c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/29d066073166d9523fd8ea341c67111a1edfc94c.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3703,
          name: "AMECAR FORCE",
          uuid: "e8c14c9d0b691b969aba948c79bdb5bb091df19e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e8c14c9d0b691b969aba948c79bdb5bb091df19e.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3791,
          name: "Animal Power",
          uuid: "d312cafc747a49749c3b957573457404",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Dlv/d312cafc747a49749c3b957573457404.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3794,
          name: "Animal Power Dragon Jackpot",
          uuid: "0f5ac640fded4c1a9063faac6a100073",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Dlv/0f5ac640fded4c1a9063faac6a100073.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3792,
          name: "Animal Power Fruits Jackpot",
          uuid: "7737e84595f5492eb7578e520c6c6cd2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Dlv/7737e84595f5492eb7578e520c6c6cd2.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3793,
          name: "Animal Power Jewel Jackpot",
          uuid: "308a73a82c584c92b1ae307e6a5c1f2e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Dlv/308a73a82c584c92b1ae307e6a5c1f2e.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3755,
          name: "ARHATEOS",
          uuid: "a2f6b66da35fb2256eeafb0cad067db9c330704d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a2f6b66da35fb2256eeafb0cad067db9c330704d.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3701,
          name: "BEARS CORNER",
          uuid: "4bf5660d9b8a9c412056173c5321f84172e9e7e1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4bf5660d9b8a9c412056173c5321f84172e9e7e1.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3754,
          name: "BRICK FRUITS",
          uuid: "65aac572080b4ad05011008999378b3c353a2faf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/65aac572080b4ad05011008999378b3c353a2faf.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3782,
          name: "Brick Fruits 40 lines",
          uuid: "c496cc230d506266655debc38b63f0ccbc7f669b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c496cc230d506266655debc38b63f0ccbc7f669b.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3725,
          name: "BRIDE DESIRE",
          uuid: "616044c04fec57905e11626d252a791a16b6d25d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/616044c04fec57905e11626d252a791a16b6d25d.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3770,
          name: "Bugaboo",
          uuid: "2743462e838c9be6fa2fe316770df61c5fb5266e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2743462e838c9be6fa2fe316770df61c5fb5266e.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3709,
          name: "BUKUBORA",
          uuid: "bc03165dff608e16e3009fa5afa4ebeb5a02c585",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bc03165dff608e16e3009fa5afa4ebeb5a02c585.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3687,
          name: "BURNING FRUITS",
          uuid: "413e10dcbf4b74b645db20fac8f2eff31de98cd4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/413e10dcbf4b74b645db20fac8f2eff31de98cd4.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3774,
          name: "Burning Fruits V",
          uuid: "2b044f20220556f21ef5209a7878a771df8534e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2b044f20220556f21ef5209a7878a771df8534e3.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3716,
          name: "BUZIGUZI",
          uuid: "5169bd7aa948baae5ed9355b5757d6e6959bab06",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5169bd7aa948baae5ed9355b5757d6e6959bab06.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3697,
          name: "CAI SHEN",
          uuid: "036983bd5c877183501e622fc4e92179555045e2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/036983bd5c877183501e622fc4e92179555045e2.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3712,
          name: "CHAMBOHUA",
          uuid: "c915d9fbf03fa6b73fdc4f2b9c4b20a4038c4c15",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c915d9fbf03fa6b73fdc4f2b9c4b20a4038c4c15.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3779,
          name: "Chica Gato",
          uuid: "9bfe9da6d063d35ef52c21d8cabeb93afc5ba547",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9bfe9da6d063d35ef52c21d8cabeb93afc5ba547.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3721,
          name: "COFETY",
          uuid: "1e1c1fa80355efec5bd525967955ae9d7331714c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1e1c1fa80355efec5bd525967955ae9d7331714c.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3773,
          name: "Cool Cracker",
          uuid: "a01049481ef93f0b94a60bd1cb5e101e093d301f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a01049481ef93f0b94a60bd1cb5e101e093d301f.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3766,
          name: "Cool Gambling",
          uuid: "bb8d9ebc9e1d5f56c757dd55a4529281d89a77ba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bb8d9ebc9e1d5f56c757dd55a4529281d89a77ba.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3749,
          name: "COOL PLACE",
          uuid: "93f96eb71ba30a84ec1c64bbfe88cf3124597f17",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/93f96eb71ba30a84ec1c64bbfe88cf3124597f17.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3743,
          name: "COSMOS JUMPING",
          uuid: "5a89685881e42030d6c7bb18674b45850f5ec468",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5a89685881e42030d6c7bb18674b45850f5ec468.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3734,
          name: "CRANKY FLAVOR",
          uuid: "cb94af80be8e497d2fda44981d2ef6dac156b130",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cb94af80be8e497d2fda44981d2ef6dac156b130.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3752,
          name: "DOUBLE RICH",
          uuid: "a5963bcdd5b0e181937b33713ca7a5e508364839",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a5963bcdd5b0e181937b33713ca7a5e508364839.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3739,
          name: "DYZONE",
          uuid: "b5564bfea59e90299d8fab679a864f40e49e5a8b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b5564bfea59e90299d8fab679a864f40e49e5a8b.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3705,
          name: "ELDARIO",
          uuid: "3d4c07674abe3a7d9392ec02e7e971e8555b5603",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3d4c07674abe3a7d9392ec02e7e971e8555b5603.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3769,
          name: "Fantasy Fish",
          uuid: "743294d8a4e2ed636095c32bc6023c0669af9aab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/743294d8a4e2ed636095c32bc6023c0669af9aab.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3696,
          name: "FAST COLT",
          uuid: "3337242ba17b0d057bac6a809471bd98481a13c6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3337242ba17b0d057bac6a809471bd98481a13c6.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3737,
          name: "FILIBUSTERS GHOST",
          uuid: "ed4c7db7cdc531546026fd42f5ae642785355f13",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ed4c7db7cdc531546026fd42f5ae642785355f13.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3795,
          name: "FISHMAN’s GOLD",
          uuid: "f59ddf4dbac744969b1eaa70e8806458",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Dlv/f59ddf4dbac744969b1eaa70e8806458.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3738,
          name: "FOREVER HOT",
          uuid: "153d5e42ac22474884e836fad983fe304cbb09d1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/153d5e42ac22474884e836fad983fe304cbb09d1.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3784,
          name: "Frozen Gold",
          uuid: "9e8ac92bff56b12531e99f4a8d0a680bec997e21",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9e8ac92bff56b12531e99f4a8d0a680bec997e21.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3756,
          name: "FRUITILES",
          uuid: "1479a7baea9b31ea511df48a9bb6448bbea20500",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1479a7baea9b31ea511df48a9bb6448bbea20500.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3787,
          name: "Fruitong",
          uuid: "aedb839b74696dcf5872c637ea88f5c3686f86b0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aedb839b74696dcf5872c637ea88f5c3686f86b0.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3704,
          name: "FRUTON DON",
          uuid: "7b740ee09c4624acb1bb14c47cd05e700e7558de",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7b740ee09c4624acb1bb14c47cd05e700e7558de.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3757,
          name: "GLASS FRUITS",
          uuid: "73b4e9ce8421aaac2fe7f7ca8c0b1e31ac84026b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/73b4e9ce8421aaac2fe7f7ca8c0b1e31ac84026b.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3715,
          name: "GOLD AND HORSE",
          uuid: "654326fdfd64305d8b5ecb3ea28387198ce96daa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/654326fdfd64305d8b5ecb3ea28387198ce96daa.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3781,
          name: "Golden Bijou",
          uuid: "9d1936b29668f1ad08be2b94dcb84bb212950df0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9d1936b29668f1ad08be2b94dcb84bb212950df0.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3772,
          name: "Golden China",
          uuid: "5649936a62bf87153b3126a9f8e56664bb65e82f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5649936a62bf87153b3126a9f8e56664bb65e82f.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3711,
          name: "GOLDEN FLEECE",
          uuid: "039b24411706a5ff68a00854b331090cd1676a0e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/039b24411706a5ff68a00854b331090cd1676a0e.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3758,
          name: "GREEN FOG",
          uuid: "dc6e08490a2dcc2f176dc0ee0afe90148918a509",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dc6e08490a2dcc2f176dc0ee0afe90148918a509.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3689,
          name: "HIDDEN CHARM",
          uuid: "45609f5dd1c1559642a736a0eba67c9da7aee62d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/45609f5dd1c1559642a736a0eba67c9da7aee62d.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3740,
          name: "HISTORY OF AVIATOR",
          uuid: "dfa77bab89649b9768852720733fb36924c714d3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dfa77bab89649b9768852720733fb36924c714d3.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3702,
          name: "HONG LONG",
          uuid: "c7409bf848358f0484d708c140041b697412b191",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c7409bf848358f0484d708c140041b697412b191.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3727,
          name: "HOT CRUISE",
          uuid: "aac9efa58f0e54ba6150b85104391fb666874860",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aac9efa58f0e54ba6150b85104391fb666874860.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3693,
          name: "IHTIADA",
          uuid: "8ab663d4f1b7b601c23935bb327b2791aa2d28bb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8ab663d4f1b7b601c23935bb327b2791aa2d28bb.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3759,
          name: "INKY JINGLE",
          uuid: "d0e22959209a73f99b78e0e2f2426886307887aa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d0e22959209a73f99b78e0e2f2426886307887aa.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3695,
          name: "JACK SAROBOS",
          uuid: "7333a7a7b3e3fba49425f79aa62aba7e057764e7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7333a7a7b3e3fba49425f79aa62aba7e057764e7.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3706,
          name: "JAN JAN",
          uuid: "6ee67a18787d7c993f5c36c4125dfe78f367490a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6ee67a18787d7c993f5c36c4125dfe78f367490a.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3753,
          name: "JOYS OF LIFE",
          uuid: "dd0440ccac09960c635a89210262c2a9c4df4636",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dd0440ccac09960c635a89210262c2a9c4df4636.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3692,
          name: "KIN HOUSE",
          uuid: "dc3b5372a3b6a24bc06c386b82bf9171046976be",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dc3b5372a3b6a24bc06c386b82bf9171046976be.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3688,
          name: "KING TUT",
          uuid: "dee71a44d9fa212f99a8c84fa7c13c11e4067153",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dee71a44d9fa212f99a8c84fa7c13c11e4067153.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3776,
          name: "King Tut V",
          uuid: "6449ca9dfc6c8ce4ce481ca87c161143db78338e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6449ca9dfc6c8ce4ce481ca87c161143db78338e.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3761,
          name: "KITTENPULT",
          uuid: "a20e65ffbcb4532984228cd0665506545e26f257",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a20e65ffbcb4532984228cd0665506545e26f257.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3720,
          name: "KOTE MATE",
          uuid: "42de99e1d5ab1c5d084fc3aada518f0a30a7b0e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/42de99e1d5ab1c5d084fc3aada518f0a30a7b0e4.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3698,
          name: "LUCKY SEARCH",
          uuid: "15e49d0a1b94531bf4a7aba29b0739b44052ed6c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/15e49d0a1b94531bf4a7aba29b0739b44052ed6c.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3690,
          name: "LUCKY SEVEN",
          uuid: "56257ff7ab44c85f017dddef4913adc5006a767c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/56257ff7ab44c85f017dddef4913adc5006a767c.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3778,
          name: "Lucky Seven V",
          uuid: "e92f1c1d3afc5f1acfcad20bfc0537a3c60717b3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e92f1c1d3afc5f1acfcad20bfc0537a3c60717b3.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3694,
          name: "LUCKY WITCH",
          uuid: "913ad4024fa9fa1f8891ffeec4f833ba469b8746",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/913ad4024fa9fa1f8891ffeec4f833ba469b8746.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3788,
          name: "Lucky Witch 777",
          uuid: "6767242df9d243d3b3b139a9cb24d666",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/DLV/6767242df9d243d3b3b139a9cb24d666.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3771,
          name: "Magic Tale",
          uuid: "74f63b2445644437ce446289753a4d14e2634942",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/74f63b2445644437ce446289753a4d14e2634942.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3744,
          name: "MESOZOIC TALES",
          uuid: "6f7bcfd1d86dc7d2f9fe33efd868da214d03689c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6f7bcfd1d86dc7d2f9fe33efd868da214d03689c.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3760,
          name: "MIGHTY ZOO",
          uuid: "82296f68a4a4e5f1f9006d1eaeae30f3a69c3c51",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/82296f68a4a4e5f1f9006d1eaeae30f3a69c3c51.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3731,
          name: "MIRMILON",
          uuid: "1d58b36fc0568ef37a46988457dcc65fb715301f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1d58b36fc0568ef37a46988457dcc65fb715301f.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3745,
          name: "MUSHROOM FABLE",
          uuid: "c45f3631f9f444a1ab5c80eb1b88a371b6ed29cc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c45f3631f9f444a1ab5c80eb1b88a371b6ed29cc.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3726,
          name: "MYSTERIOUS INDIA",
          uuid: "53d91314c2248cb14b94b19f14f63f257a0c9e2a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/53d91314c2248cb14b94b19f14f63f257a0c9e2a.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3710,
          name: "MYSTIC JUNGLE",
          uuid: "43b2498719229e2a7392fbf5a8040e8275c6be65",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/43b2498719229e2a7392fbf5a8040e8275c6be65.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3746,
          name: "NEANDERTHIDA",
          uuid: "8d3306cafa96d2c57c0f559cfd14d1765a642266",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8d3306cafa96d2c57c0f559cfd14d1765a642266.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3728,
          name: "NY GANGSTERS",
          uuid: "9ec0f13dc64be9c4e2ee329950f601a4de729dad",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9ec0f13dc64be9c4e2ee329950f601a4de729dad.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3719,
          name: "OROKORN",
          uuid: "098e903c70193cd8409d6d21dcbb7b96de6a924c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/098e903c70193cd8409d6d21dcbb7b96de6a924c.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3786,
          name: "Papyrus Tales",
          uuid: "531e93105add442bb9f8584400d6292ac738baab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/531e93105add442bb9f8584400d6292ac738baab.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3713,
          name: "POSEIDON TREASURE",
          uuid: "790045684e8f4497df158c48fcebd956d83d0992",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/790045684e8f4497df158c48fcebd956d83d0992.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3762,
          name: "QUICK STAMP",
          uuid: "d1644dd0a3943f56c58eaf40cebae0a45df36da2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d1644dd0a3943f56c58eaf40cebae0a45df36da2.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3764,
          name: "Royal Jewel De Lux",
          uuid: "0551abf7213598a2f919427c2b590d70ccd35cb1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0551abf7213598a2f919427c2b590d70ccd35cb1.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3691,
          name: "ROYAL JEWELS",
          uuid: "100d0641ad037361fdd917700a4b03b81bdcb94e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/100d0641ad037361fdd917700a4b03b81bdcb94e.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3708,
          name: "RUDAKOP",
          uuid: "e41e9735df10c9bc2fa548f0997d84fe1bd6500b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e41e9735df10c9bc2fa548f0997d84fe1bd6500b.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3742,
          name: "SCANDY GOLD",
          uuid: "f45952b7f9baee17d185e17f4d8e5778e082b565",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f45952b7f9baee17d185e17f4d8e5778e082b565.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3729,
          name: "SECRETS OF ALCHEMY",
          uuid: "246e65e7265667357b3837ab8ef3975742071439",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/246e65e7265667357b3837ab8ef3975742071439.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3785,
          name: "Shrizzle Band",
          uuid: "95952c6a315b78aac15f24c5036004a42e707aef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/95952c6a315b78aac15f24c5036004a42e707aef.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3747,
          name: "SILVER HAUBERK",
          uuid: "136e068b256784779c9cf93c50bce87033192562",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/136e068b256784779c9cf93c50bce87033192562.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3767,
          name: "Skeleton Fortune",
          uuid: "a4a2fc9c8bb630e84fcf79ab456c291c73bac453",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a4a2fc9c8bb630e84fcf79ab456c291c73bac453.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3714,
          name: "SMAKY BOO",
          uuid: "4257fede2af8c7025191bf2c650c60d04f348bc9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4257fede2af8c7025191bf2c650c60d04f348bc9.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3783,
          name: "Snow Luck",
          uuid: "c8b35cf3272a66f62456ade2c71be5b9981a020c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c8b35cf3272a66f62456ade2c71be5b9981a020c.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3707,
          name: "SPICY FLEUR",
          uuid: "521f33701c7a953d3d3745a8af455a095c1faa41",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/521f33701c7a953d3d3745a8af455a095c1faa41.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3765,
          name: "Star Jong De Lux",
          uuid: "9e18743f1d806884b94cd3619d13cdae5b00a388",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9e18743f1d806884b94cd3619d13cdae5b00a388.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3735,
          name: "STARJONG",
          uuid: "8fa238c5ee547cc160a45488f3803b63a16133a5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8fa238c5ee547cc160a45488f3803b63a16133a5.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3768,
          name: "Stones of Endylite",
          uuid: "625c4333b7cf06e5fb386a7e60309765b1fb8c86",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/625c4333b7cf06e5fb386a7e60309765b1fb8c86.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3700,
          name: "SYMPHOMAGIC",
          uuid: "89c52250b4607bb6934dd8ac731e786c8e1fdea7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/89c52250b4607bb6934dd8ac731e786c8e1fdea7.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3777,
          name: "The Monk’s Fable",
          uuid: "1fb1c13b34c02dcab3ab54f12e8272b6c6f1b0dd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1fb1c13b34c02dcab3ab54f12e8272b6c6f1b0dd.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3751,
          name: "TIMBER TALE",
          uuid: "341ac8239728795510d99a96b0b054ac6efcca81",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/341ac8239728795510d99a96b0b054ac6efcca81.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3750,
          name: "TITANIUM FRUITS",
          uuid: "a6ce8182a863d1f0925436aa3a3d9cd691908389",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a6ce8182a863d1f0925436aa3a3d9cd691908389.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3718,
          name: "TOPATOO",
          uuid: "e76afed1faa0bf2e636c5459ce257b7eca27117e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e76afed1faa0bf2e636c5459ce257b7eca27117e.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3730,
          name: "TOROS",
          uuid: "d45b5eb001e2d1a817e932f16fc7f947bfc7dec0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d45b5eb001e2d1a817e932f16fc7f947bfc7dec0.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3732,
          name: "TUMATOP",
          uuid: "b9f13ed2ea6ec0c2bb1493d802d254fa79150966",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b9f13ed2ea6ec0c2bb1493d802d254fa79150966.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3741,
          name: "TYNOOCHKY",
          uuid: "cfd60deca31df88aa8d960cdb99ae6f8c0740b56",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cfd60deca31df88aa8d960cdb99ae6f8c0740b56.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3775,
          name: "VALHALLA WARRIORS",
          uuid: "4b30c3d9b7ddabdbcbfcb92ff463ea62dd352d88",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4b30c3d9b7ddabdbcbfcb92ff463ea62dd352d88.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3723,
          name: "VAMPEON",
          uuid: "2689ce95176ed84bd7b013f5388d601f8381e4e9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2689ce95176ed84bd7b013f5388d601f8381e4e9.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3790,
          name: "Vampeon 2",
          uuid: "0fd25f5d1144b3b8e2ce0b19b07dbd3c4549bfae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Dlv/0fd25f5d1144b3b8e2ce0b19b07dbd3c4549bfae.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3763,
          name: "VIKINGAR",
          uuid: "156327d9e93493e7d74ea3e366282833638a3966",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/156327d9e93493e7d74ea3e366282833638a3966.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3733,
          name: "WELKIN GUARDS",
          uuid: "093de74a4aac1ac6e47b935ca53e1785948f651c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/093de74a4aac1ac6e47b935ca53e1785948f651c.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3748,
          name: "WINCH AND WHEELS",
          uuid: "c7dcdbfd7decb0d04870deab34a24128d1fe68c2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c7dcdbfd7decb0d04870deab34a24128d1fe68c2.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3699,
          name: "WINE TREASURY",
          uuid: "8459d6457fb45ddc4ec73b5c97ad086d835cb534",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8459d6457fb45ddc4ec73b5c97ad086d835cb534.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3789,
          name: "Zahmelusha",
          uuid: "46ac9d620ae0c3976bd201c93ad95afacb7365cb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Dlv/46ac9d620ae0c3976bd201c93ad95afacb7365cb.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3717,
          name: "ZUMBAZI",
          uuid: "1f365a37cdab086f20636fbd8108b25d2fd84750",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1f365a37cdab086f20636fbd8108b25d2fd84750.png",
          type: "slots",
          provider: "Dlv",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 116,
      name: "EGT",
      providerId: 2,
      providerName: "EGT",
      games: [
        {
          game_id: 133,
          name: "10 Burning Heart",
          uuid: "c171398de47a084ac7a84615d5f6280b388189aa",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c171398de47a084ac7a84615d5f6280b388189aa.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 131,
          name: "100 Burning Hot",
          uuid: "6456aeb10010daba0f5fc19230427654006dbe73",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6456aeb10010daba0f5fc19230427654006dbe73.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 36,
          name: "100 Cats",
          uuid: "e6a140082fa71a5e72b6fe2904bc6f0ce101a7a4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e6a140082fa71a5e72b6fe2904bc6f0ce101a7a4.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 298,
          name: "100 Dice",
          uuid: "8c2ff5c68566bc1b4a9f6a6289fe9f7977afa3cb",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8c2ff5c68566bc1b4a9f6a6289fe9f7977afa3cb.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 299,
          name: "100 Super Dice",
          uuid: "f57e0be51fbdd89680a1cf69a9138e67aec5c9ad",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f57e0be51fbdd89680a1cf69a9138e67aec5c9ad.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 132,
          name: "100 Super Hot",
          uuid: "cc86e61fea81e2749221491fa0b833b1db5c6f56",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cc86e61fea81e2749221491fa0b833b1db5c6f56.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 40,
          name: "2 Dragons",
          uuid: "7faf11ad0cd31ecf0d0997d9bad2eae1261e00fa",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7faf11ad0cd31ecf0d0997d9bad2eae1261e00fa.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 300,
          name: "20 Burning Dice",
          uuid: "0be61dbd9e8845b6febb5761f708e7861a3742ab",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0be61dbd9e8845b6febb5761f708e7861a3742ab.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 134,
          name: "20 Burning Hot",
          uuid: "1743393fbf975b76e80fc747c8270609dab9daa2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1743393fbf975b76e80fc747c8270609dab9daa2.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 135,
          name: "20 Dazzling Hot",
          uuid: "3b8e66c89a41206e46aa895c2580dff4a07c6edd",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3b8e66c89a41206e46aa895c2580dff4a07c6edd.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 37,
          name: "20 Diamonds",
          uuid: "7334848f8b6604162d40338a2e9217098b08ecc5",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7334848f8b6604162d40338a2e9217098b08ecc5.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 136,
          name: "20 Joker Reels",
          uuid: "203475551a9e31e88602298b9b854f09beb628a9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/203475551a9e31e88602298b9b854f09beb628a9.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 38,
          name: "20 Super Dice",
          uuid: "420bd4478c0683075230a81a256507ad8de3fbe4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/420bd4478c0683075230a81a256507ad8de3fbe4.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 39,
          name: "20 Super Hot",
          uuid: "34d8d5ba08385bf6293361195e24fb8eaad1ac7c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/34d8d5ba08385bf6293361195e24fb8eaad1ac7c.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 301,
          name: "30 Spicy Dice",
          uuid: "b6458ec2f70d8c0fbb12a76adb42c062637b7113",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b6458ec2f70d8c0fbb12a76adb42c062637b7113.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 137,
          name: "30 Spicy Fruits",
          uuid: "3e246f145aef23b3652a88a61f240dd99fa1e2db",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3e246f145aef23b3652a88a61f240dd99fa1e2db.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 302,
          name: "40 Burning Dice",
          uuid: "772d2d113d9712cfd5fd7b4469f880e7cdb37d47",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/772d2d113d9712cfd5fd7b4469f880e7cdb37d47.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 138,
          name: "40 Burning Hot",
          uuid: "cb1df72cfd1c4df303c3f8db3b05e289c4ffff99",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cb1df72cfd1c4df303c3f8db3b05e289c4ffff99.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 319,
          name: "40 Lucky King",
          uuid: "140653f8a025ac83acddeeb36cef7e92cbae6d14",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/140653f8a025ac83acddeeb36cef7e92cbae6d14.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 303,
          name: "40 Mega Clover",
          uuid: "082d270d7f5e190a8ded6c135d4a4a71a59e7478",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/082d270d7f5e190a8ded6c135d4a4a71a59e7478.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 41,
          name: "40 Super Dice",
          uuid: "d8166dddc40096e2ca602abb88e07754a573c713",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d8166dddc40096e2ca602abb88e07754a573c713.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 42,
          name: "40 Super Hot",
          uuid: "a8413f8d7215ad6d8e3ce7cfc26e3c563ecf6966",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a8413f8d7215ad6d8e3ce7cfc26e3c563ecf6966.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 139,
          name: "5 Burning Heart",
          uuid: "50fdb1326ffa6d2b4af218fd46c3dac7695045da",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/50fdb1326ffa6d2b4af218fd46c3dac7695045da.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 44,
          name: "5 Dazzling Hot",
          uuid: "1dd37ed413ec14f0dc2501c92d17680124d7aa76",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1dd37ed413ec14f0dc2501c92d17680124d7aa76.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 304,
          name: "5 Great Star",
          uuid: "b9aa36bcdb34938912dba4c200bc00e3c9ba2e8f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b9aa36bcdb34938912dba4c200bc00e3c9ba2e8f.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 45,
          name: "5 Hot Dice",
          uuid: "1ff1dcb3e9ab9db5524d6300bc2069218a9c0b6c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1ff1dcb3e9ab9db5524d6300bc2069218a9c0b6c.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 320,
          name: "5 Juggle Fruits",
          uuid: "b95b1ffb95ffaa3d480f07e3184e0592ed533d45",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b95b1ffb95ffaa3d480f07e3184e0592ed533d45.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 43,
          name: "50 Horses",
          uuid: "6727a55f7d116b31073c865ed10c22c366338782",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6727a55f7d116b31073c865ed10c22c366338782.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 46,
          name: "Age Of Troy",
          uuid: "6bc31d64605f866ba28863856a762f4a8c19131f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6bc31d64605f866ba28863856a762f4a8c19131f.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 47,
          name: "Amazing Amazonia",
          uuid: "c72f0d25829341a1dacb9c3b27b0da0314cd98e7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c72f0d25829341a1dacb9c3b27b0da0314cd98e7.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 305,
          name: "Amazons Battle",
          uuid: "651f090c618223a301b5ec05cfecef7f3e3c54c5",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/651f090c618223a301b5ec05cfecef7f3e3c54c5.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 306,
          name: "Amazons Story",
          uuid: "dc2890e94595b6e3dc6e472a8277ac184cacc172",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/dc2890e94595b6e3dc6e472a8277ac184cacc172.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 307,
          name: "Aztec Glory",
          uuid: "5bd809494a42fc7f52653ebf1d1b2046a08564ec",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5bd809494a42fc7f52653ebf1d1b2046a08564ec.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 48,
          name: "Blue Heart",
          uuid: "bfd13224f060cacc8f5f72677e4f05dbd517623b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bfd13224f060cacc8f5f72677e4f05dbd517623b.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 49,
          name: "Book Of Magic",
          uuid: "e0c218e83780c954d8a97c14c574f2223e9c3773",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e0c218e83780c954d8a97c14c574f2223e9c3773.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 50,
          name: "Burning Dice",
          uuid: "b62e1233509ca4671db2dc8f3aac344561617b3d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b62e1233509ca4671db2dc8f3aac344561617b3d.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 51,
          name: "Burning Hot",
          uuid: "7bb11a10a66a6ee30533f6e2c219268390398039",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7bb11a10a66a6ee30533f6e2c219268390398039.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 308,
          name: "Caramel Dice",
          uuid: "72e0887757a480b0b9d0bab65838768a921b0da9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/72e0887757a480b0b9d0bab65838768a921b0da9.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 309,
          name: "Caramel Hot",
          uuid: "43d8cbe078898a8ed96aecdfb1d8e4e35834410d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/43d8cbe078898a8ed96aecdfb1d8e4e35834410d.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 167,
          name: "Casino Mania",
          uuid: "ae6621349a81948ed50f28882bc68a4cefab77a4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ae6621349a81948ed50f28882bc68a4cefab77a4.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 310,
          name: "Coral Island",
          uuid: "9b700ae29b46a2171540735d3c7dba6bf7021869",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9b700ae29b46a2171540735d3c7dba6bf7021869.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 321,
          name: "Dark Queen",
          uuid: "56953990fb7d21d61c6da3b9938fc120957d2c3c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/56953990fb7d21d61c6da3b9938fc120957d2c3c.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 322,
          name: "Dice And Roll",
          uuid: "6bbb66cacc95649bc98b28334d1ad05cea69b7f5",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6bbb66cacc95649bc98b28334d1ad05cea69b7f5.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 52,
          name: "Dice Of Ra",
          uuid: "057c66d7ab05538209d750ee837a62374fbda60d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/057c66d7ab05538209d750ee837a62374fbda60d.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 355,
          name: "Dragon Reels",
          uuid: "8faca9bfa476d9740c13ec367a9859ffad856422",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8faca9bfa476d9740c13ec367a9859ffad856422.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 354,
          name: "Egypt Sky",
          uuid: "3cde98e815d9292248a6a5a373c7dd13170ad82e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3cde98e815d9292248a6a5a373c7dd13170ad82e.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 53,
          name: "Extra Joker",
          uuid: "e64c6effd494172a4a72b766e142732563fd9bd7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e64c6effd494172a4a72b766e142732563fd9bd7.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 168,
          name: "Extra Stars",
          uuid: "2c48ca0ab1574d882aed0e4c3ef260ddc6e9bb05",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2c48ca0ab1574d882aed0e4c3ef260ddc6e9bb05.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 169,
          name: "Extremely Hot",
          uuid: "da0cbde856c69107a88f4bca5ffc2b6440c23388",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/da0cbde856c69107a88f4bca5ffc2b6440c23388.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 311,
          name: "Fast Money",
          uuid: "bab9afc0b15a42d1103a066b8857496490a1ed8f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bab9afc0b15a42d1103a066b8857496490a1ed8f.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 312,
          name: "Flaming Dice",
          uuid: "7685870df82b86c2d81af22f6f9e8efc193846df",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7685870df82b86c2d81af22f6f9e8efc193846df.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 170,
          name: "Flaming Hot",
          uuid: "da4ef84aec53b2372673ec1d236e95b1c9d66c00",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/da4ef84aec53b2372673ec1d236e95b1c9d66c00.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 323,
          name: "Forest Band",
          uuid: "4f896819a52a6b946a1021be648f77efaa759e47",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4f896819a52a6b946a1021be648f77efaa759e47.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 171,
          name: "Fortune Spells",
          uuid: "0ba2caf261e43f5bc1b53ec67b82e5df3585e72d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0ba2caf261e43f5bc1b53ec67b82e5df3585e72d.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 313,
          name: "Fruits Kingdom",
          uuid: "47855bcbf79e72fad5044f0faf6778c93b3cd443",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/47855bcbf79e72fad5044f0faf6778c93b3cd443.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 172,
          name: "Game Of Luck",
          uuid: "c84adf9f4f4193392c937b7aa60bdc526e1a72c8",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c84adf9f4f4193392c937b7aa60bdc526e1a72c8.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 173,
          name: "Grace Of Cleopatra",
          uuid: "5f4ae0014f65355f365631d95372c7b59edce76d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5f4ae0014f65355f365631d95372c7b59edce76d.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 324,
          name: "Halloween",
          uuid: "f60cae6da86eff6cbfe9a58a77b3990e48d552a5",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f60cae6da86eff6cbfe9a58a77b3990e48d552a5.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 325,
          name: "Imperial Wars",
          uuid: "d79e7d0c286f02540590cb1e8a65b87786b53a57",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d79e7d0c286f02540590cb1e8a65b87786b53a57.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 174,
          name: "Inca Gold II",
          uuid: "aa2e5406c83e798f6d32514d9204cf3fea1e224c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/aa2e5406c83e798f6d32514d9204cf3fea1e224c.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 358,
          name: "Kangaroo Land",
          uuid: "bad3457d9ea55fe97e15e3d7762bbf60b4d502d4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bad3457d9ea55fe97e15e3d7762bbf60b4d502d4.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 175,
          name: "Kashmir Gold",
          uuid: "5c1b18fcb1d1bb62fe222416ec53f00fcf33037a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5c1b18fcb1d1bb62fe222416ec53f00fcf33037a.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 176,
          name: "Lucky And Wild",
          uuid: "7e4c39ef49a6cef85d5923d8e3c79f60c4bb2c57",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7e4c39ef49a6cef85d5923d8e3c79f60c4bb2c57.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 326,
          name: "Lucky Buzz",
          uuid: "207562877584826520fd1146812d1fe62b6e0b06",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/207562877584826520fd1146812d1fe62b6e0b06.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 128,
          name: "Lucky Hot",
          uuid: "e02350c55cd748b7429ccbd5cacedbac3fcde573",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e02350c55cd748b7429ccbd5cacedbac3fcde573.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 314,
          name: "Magellan",
          uuid: "90ee5885ad68249c366f28846de33b137d4dda12",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/90ee5885ad68249c366f28846de33b137d4dda12.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 177,
          name: "Majestic Forest",
          uuid: "1d5d025238a79b1345bf464727cc851761e200b7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1d5d025238a79b1345bf464727cc851761e200b7.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 327,
          name: "Mayan Spirit",
          uuid: "994e588297d89a702e0e48bb4bfa7cdcdfeca92c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/994e588297d89a702e0e48bb4bfa7cdcdfeca92c.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 328,
          name: "More Dice And Roll",
          uuid: "d7f608a8192ecd1fb7737183e6b563cf71d5dd46",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d7f608a8192ecd1fb7737183e6b563cf71d5dd46.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 329,
          name: "More Lucky And Wild",
          uuid: "093c61f8a8c80a7391596c70bedec4d396eb27b1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/093c61f8a8c80a7391596c70bedec4d396eb27b1.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 178,
          name: "Neon Dice",
          uuid: "3f79d49d701890c3a06108ac5d52daf5c371abdb",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3f79d49d701890c3a06108ac5d52daf5c371abdb.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 179,
          name: "Oil Company II",
          uuid: "d0b33e3d5389b7ecb29aa7afaeb6dd40934719ec",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d0b33e3d5389b7ecb29aa7afaeb6dd40934719ec.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 180,
          name: "Olympus Glory",
          uuid: "a25911da1766ab7af55ecdc8c9d1ac8fbce94731",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a25911da1766ab7af55ecdc8c9d1ac8fbce94731.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 315,
          name: "Queen Of Rio",
          uuid: "b25dc9cbffbf30a1c033a208449f0eb9d8356595",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b25dc9cbffbf30a1c033a208449f0eb9d8356595.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 357,
          name: "Retro Style",
          uuid: "b1f4d49f605cc91fca70684f333c048f0a0837ca",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b1f4d49f605cc91fca70684f333c048f0a0837ca.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 316,
          name: "Rich World",
          uuid: "e20f38f529c44e5c2fd6e9eeb207559cb8e34ad9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e20f38f529c44e5c2fd6e9eeb207559cb8e34ad9.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 181,
          name: "Rise Of Ra",
          uuid: "557c4887ea2692bf47ed2bc7e4b80e586bdaee96",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/557c4887ea2692bf47ed2bc7e4b80e586bdaee96.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 330,
          name: "Rolling Dice",
          uuid: "aee813a8bbb53c40c09cf480a26b1c9913335def",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/aee813a8bbb53c40c09cf480a26b1c9913335def.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 331,
          name: "Route Of Mexico",
          uuid: "dc47a10cc53875f963668e13130f2b69c2292d8a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/dc47a10cc53875f963668e13130f2b69c2292d8a.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 317,
          name: "Royal Gardens",
          uuid: "f1a35b520e02078f9af29cbc07c40997a9194e12",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f1a35b520e02078f9af29cbc07c40997a9194e12.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 182,
          name: "Royal Secrets",
          uuid: "ea45784e5406be037d47149fb4e48c53371a4853",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ea45784e5406be037d47149fb4e48c53371a4853.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 183,
          name: "Shining Crown",
          uuid: "846db8b1ea3c0d639cc069618b01b73638e951d1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/846db8b1ea3c0d639cc069618b01b73638e951d1.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 332,
          name: "Spanish Passion",
          uuid: "0dfdbbdb6afc9f5810b9be59f9bd26f29b6e8b78",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0dfdbbdb6afc9f5810b9be59f9bd26f29b6e8b78.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 333,
          name: "Summer Bliss",
          uuid: "a8f4abc9ad5b64eb92f393cd8fab2a0ba0ed604c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a8f4abc9ad5b64eb92f393cd8fab2a0ba0ed604c.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 318,
          name: "Super 20",
          uuid: "ec34fe7e03c06396299af1d78ae4edb1f5f8ebd4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ec34fe7e03c06396299af1d78ae4edb1f5f8ebd4.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 356,
          name: "The Big Journey",
          uuid: "8084e935500439935990dd023827ed16ada5e419",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8084e935500439935990dd023827ed16ada5e419.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 184,
          name: "The Great Egypt",
          uuid: "9c5d6bf88df3f89f57e593231cddd6b01ad07bf7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9c5d6bf88df3f89f57e593231cddd6b01ad07bf7.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 334,
          name: "The Story Of Alexander",
          uuid: "386f804facc73b941af5e03c2c20aa9b04c3f914",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/386f804facc73b941af5e03c2c20aa9b04c3f914.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 185,
          name: "The White Wolf",
          uuid: "1f383dbd6bbd30d13606da078fa42c57f380ade6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1f383dbd6bbd30d13606da078fa42c57f380ade6.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 186,
          name: "Ultimate Hot",
          uuid: "2368f8e700eade7827336827bc27da30c3619c99",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2368f8e700eade7827336827bc27da30c3619c99.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 187,
          name: "Versailles Gold",
          uuid: "b4868de7e40f03235352b11e22ada9f6e4e31c8c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b4868de7e40f03235352b11e22ada9f6e4e31c8c.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 188,
          name: "Witches Charm",
          uuid: "302d923360a73a263834079c494d113fc6c54d38",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/302d923360a73a263834079c494d113fc6c54d38.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 336,
          name: "Wonder Tree",
          uuid: "4ed59128cdf5ecf9645f59c31c10d0ddd9d49923",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4ed59128cdf5ecf9645f59c31c10d0ddd9d49923.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 335,
          name: "Wonderheart",
          uuid: "b719fb542cddf3bf300da00067b884b7d8696184",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b719fb542cddf3bf300da00067b884b7d8696184.png",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 189,
          name: "Zodiac Wheel",
          uuid: "2a31ed845f9ca5fc607793c8c110b5526d89ad21",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2a31ed845f9ca5fc607793c8c110b5526d89ad21.jpg",
          type: "slots",
          provider: "EGT",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 117,
      name: "Espressogames",
      providerId: 46,
      providerName: "Espressogames",
      games: [
        {
          game_id: 3805,
          name: "#90Spins",
          uuid: "a2575dce6ce94a08ad516b7cd6c22106",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/a2575dce6ce94a08ad516b7cd6c22106.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3797,
          name: "12 Number Roulette",
          uuid: "fbe3bb44f00f406ba034ced6808656fc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/fbe3bb44f00f406ba034ced6808656fc.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3883,
          name: "1st Avenue Panther Club",
          uuid: "9c66311e9692451fbb6855c1e9f65596",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9c66311e9692451fbb6855c1e9f65596.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3806,
          name: "2050 Escape From Mars",
          uuid: "d4029ca2407445d391aff89c980cb59d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/d4029ca2407445d391aff89c980cb59d.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3807,
          name: "3Diamonds fashiontv",
          uuid: "842681fa97df4c21b5402a297cb50ac9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/842681fa97df4c21b5402a297cb50ac9.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3862,
          name: "4H All American",
          uuid: "9a5c5f695cbe4c5aa699cfed8b4e63e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/9a5c5f695cbe4c5aa699cfed8b4e63e0.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3863,
          name: "4H Deuces Wild",
          uuid: "63a435fc377d4f9c81bc5e8257d6855f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/63a435fc377d4f9c81bc5e8257d6855f.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3864,
          name: "4H Jacks or Better",
          uuid: "a2786734227940ee9815f65fd0e2eebe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/a2786734227940ee9815f65fd0e2eebe.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3865,
          name: "4H Joker Poker",
          uuid: "eb4d71954e1f4f92bf74bfd48ea0807f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/eb4d71954e1f4f92bf74bfd48ea0807f.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3866,
          name: "4H Steam Joker Poker",
          uuid: "de8f71c150b14261a670f290d38e4cf8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/de8f71c150b14261a670f290d38e4cf8.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3808,
          name: "7 & Co",
          uuid: "6be8d38f37c8457dad239abc498dd753",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/6be8d38f37c8457dad239abc498dd753.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3809,
          name: "7th Sense",
          uuid: "e9be1afb24cb4782bccb020e0b10a786",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/e9be1afb24cb4782bccb020e0b10a786.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3880,
          name: "Alfredo's Easter",
          uuid: "7d92c21f5e0443ee9462a15958989b5b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7d92c21f5e0443ee9462a15958989b5b.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3889,
          name: "Alfredo's Halloween",
          uuid: "075bb41e8e156181e7a8a78b67d992d1839c4226",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/075bb41e8e156181e7a8a78b67d992d1839c4226.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3897,
          name: "Alfredo's Summer",
          uuid: "a7c9556812a349b682a38c3008d33581",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/a7c9556812a349b682a38c3008d33581.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3881,
          name: "Alfredo's Valentine",
          uuid: "8cf0592ea7c646e981fea2df07990ccc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8cf0592ea7c646e981fea2df07990ccc.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3882,
          name: "Alfredo's Xmas",
          uuid: "084509a2ab8d4cc89c56cc4b5a8f3f7d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/084509a2ab8d4cc89c56cc4b5a8f3f7d.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3867,
          name: "All American",
          uuid: "19023dcb7de943d9bf4f0be1d012a026",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/19023dcb7de943d9bf4f0be1d012a026.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3798,
          name: "American Roulette",
          uuid: "1b79cdc96c034ea8ae5c5d3b261c43ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/1b79cdc96c034ea8ae5c5d3b261c43ce.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3810,
          name: "Amun Ra",
          uuid: "162ae39fdcfe45b79b0c0b198eb9c563",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/162ae39fdcfe45b79b0c0b198eb9c563.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3811,
          name: "Angel or Joker",
          uuid: "63bc087f5a2d412da445a3653050281d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/63bc087f5a2d412da445a3653050281d.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3899,
          name: "Apokalipsia Latina",
          uuid: "aeaf55a37fad4795a72d66d46f3e9412",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/aeaf55a37fad4795a72d66d46f3e9412.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3895,
          name: "Apple Temptation",
          uuid: "5e4f6fa608bc1a5c1041cdb34a7255d8a5aad8e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/5e4f6fa608bc1a5c1041cdb34a7255d8a5aad8e8.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3892,
          name: "Armonah",
          uuid: "039f357a10274c57ab22407047524404",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/039f357a10274c57ab22407047524404.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3796,
          name: "Baccarat",
          uuid: "7339a6dbe71f449d9c6070b742056bc4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/7339a6dbe71f449d9c6070b742056bc4.png",
          type: "card",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3812,
          name: "Bad Granny",
          uuid: "4f65c827cd0d4510bcbc34b039e37a79",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/4f65c827cd0d4510bcbc34b039e37a79.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3813,
          name: "Better Sound",
          uuid: "8f16d57fd1ae4953b30a803732fa42f2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/8f16d57fd1ae4953b30a803732fa42f2.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3884,
          name: "Black & Seven in Vegas",
          uuid: "d4df7a8676c151c50f5fee5e490d7df2a7a3ad71",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d4df7a8676c151c50f5fee5e490d7df2a7a3ad71.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3814,
          name: "Boobies & Booties",
          uuid: "2859ffa9abbb45a8b82b80165e8e2815",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/2859ffa9abbb45a8b82b80165e8e2815.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3815,
          name: "Bubble Rama",
          uuid: "b5471df5b4724950b046281e3a3efaf6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/b5471df5b4724950b046281e3a3efaf6.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3816,
          name: "Bullets for Money",
          uuid: "0d75b141c2544828a060843450bfb37c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/0d75b141c2544828a060843450bfb37c.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3817,
          name: "Cafelito",
          uuid: "75896184e22e411f9af1a00f71b7f96e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/75896184e22e411f9af1a00f71b7f96e.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3818,
          name: "Cash Busters",
          uuid: "4c42d491fab548909aeb1e5f4258cdfd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/4c42d491fab548909aeb1e5f4258cdfd.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3819,
          name: "Caveman",
          uuid: "ddf6d7d831ef42c5a44cd853b30f08da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/ddf6d7d831ef42c5a44cd853b30f08da.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3820,
          name: "Chakra",
          uuid: "281b5daf4a0a4b5085307f14b7874217",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/281b5daf4a0a4b5085307f14b7874217.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3821,
          name: "Cinema",
          uuid: "6909c62ef71f488bb6e4be56827ae139",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/6909c62ef71f488bb6e4be56827ae139.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3822,
          name: "Cinema One",
          uuid: "b73294470f0c4edab95bf19d507be411",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/b73294470f0c4edab95bf19d507be411.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3875,
          name: "Classic Blackjack",
          uuid: "4197d45cf903493daaa7a5861634a0a8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4197d45cf903493daaa7a5861634a0a8.png",
          type: "card",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3823,
          name: "Cluck Bucks",
          uuid: "dbe7fe2efdd74cb39c7df2e7cb36a0b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/dbe7fe2efdd74cb39c7df2e7cb36a0b7.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3868,
          name: "Color Champion",
          uuid: "7d9d5fbc365b4b2cb9128f16e83a3add",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/7d9d5fbc365b4b2cb9128f16e83a3add.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3824,
          name: "Crazy Faces",
          uuid: "a481525db1c7464b8a3201fb448f6681",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/a481525db1c7464b8a3201fb448f6681.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3869,
          name: "Deuces Wild",
          uuid: "4f4c2d2638784562a42d0133590576cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/4f4c2d2638784562a42d0133590576cf.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3891,
          name: "Euro MultiX Roulette",
          uuid: "af343e9dc575f7836185e7db6c0db21777466cca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/af343e9dc575f7836185e7db6c0db21777466cca.png",
          type: "table",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3799,
          name: "Euro Roulette",
          uuid: "4e4d6cf0df52478aafd3d7d4493303b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/4e4d6cf0df52478aafd3d7d4493303b7.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3888,
          name: "Euro Twins Roulette",
          uuid: "47c67ed8edc80d5c6b9fa262c6d3fc8ca8ac6dcd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/47c67ed8edc80d5c6b9fa262c6d3fc8ca8ac6dcd.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3873,
          name: "European Blackjack",
          uuid: "dd30fa8dc83842c9826d471a58e8962a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dd30fa8dc83842c9826d471a58e8962a.png",
          type: "card",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3825,
          name: "Fluo Party",
          uuid: "008102189776499997892d390f55d233",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/008102189776499997892d390f55d233.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3890,
          name: "Fortune Piggy",
          uuid: "c22b969196331772cca65b09008cd619dd7bd290",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/c22b969196331772cca65b09008cd619dd7bd290.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3826,
          name: "Fortune Race Deluxe",
          uuid: "0ab2713cca894926b8b2937b01739ac7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/0ab2713cca894926b8b2937b01739ac7.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3827,
          name: "Fruit Race Deluxe",
          uuid: "8b738afc085d4faebf067f4b729d2d9d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/8b738afc085d4faebf067f4b729d2d9d.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3828,
          name: "Genie's Treasure",
          uuid: "8577e83611cf4f4ca5338b3ead6a1524",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/8577e83611cf4f4ca5338b3ead6a1524.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3800,
          name: "Global 12 Numbers",
          uuid: "c61e1c398a6041f1817714b10641b9f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/c61e1c398a6041f1817714b10641b9f8.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3801,
          name: "Global American Roulette",
          uuid: "6cdd60a21a0a47e3a7e1c4f73aff86d5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/6cdd60a21a0a47e3a7e1c4f73aff86d5.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3802,
          name: "Global Euro Roulette",
          uuid: "c17a64a8feda4cc2870f8653e0e4f4a0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/c17a64a8feda4cc2870f8653e0e4f4a0.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3803,
          name: "Global Poker Roulette",
          uuid: "5db301660c554d51b8e66609b4ce0184",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/5db301660c554d51b8e66609b4ce0184.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3829,
          name: "Gold Race Deluxe",
          uuid: "9bcc600d19874c87a93912834dc6618d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/9bcc600d19874c87a93912834dc6618d.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3830,
          name: "Golden Casino",
          uuid: "6a9520e8d9594e3893dfffb8f71f85bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/6a9520e8d9594e3893dfffb8f71f85bc.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3898,
          name: "Golden Time Roulette",
          uuid: "68790c3667714fa1932f2e21fead7a96",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/68790c3667714fa1932f2e21fead7a96.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3831,
          name: "Good & Evil",
          uuid: "457f73bef8474c189ce46d758ab2d0d6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/457f73bef8474c189ce46d758ab2d0d6.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3878,
          name: "Jackpot Stud Poker",
          uuid: "651a1e6323fe4802bde1937dec84788b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/651a1e6323fe4802bde1937dec84788b.png",
          type: "card",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3870,
          name: "Jacks or Better",
          uuid: "44bf1e21480c47ef8090f1d13cc7ff7e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/44bf1e21480c47ef8090f1d13cc7ff7e.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3871,
          name: "Joker Poker",
          uuid: "8b1bdd7202a54ee2b7af5f800039b35f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/8b1bdd7202a54ee2b7af5f800039b35f.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3832,
          name: "Leprechaun Rama",
          uuid: "a22b3e6c0ee841b5aab57e9ca5afff68",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/a22b3e6c0ee841b5aab57e9ca5afff68.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3833,
          name: "Lucky 7",
          uuid: "9e0a5474c94f48d89d33ad2ea808af43",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/9e0a5474c94f48d89d33ad2ea808af43.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3874,
          name: "Lucky 7 Blackjack",
          uuid: "03239dfc81f6403399fe41cd88b1b2c0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/03239dfc81f6403399fe41cd88b1b2c0.png",
          type: "card",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3834,
          name: "Mad 4 Easter",
          uuid: "4c609e074a56480a9d32c9f87f10d984",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/4c609e074a56480a9d32c9f87f10d984.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3835,
          name: "Mad 4 Halloween",
          uuid: "328d9cba91b6488e988a6e4b0f79cf9e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/328d9cba91b6488e988a6e4b0f79cf9e.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3836,
          name: "Mad 4 Lotto",
          uuid: "43ef5bca7709480182f471051f282c3b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/43ef5bca7709480182f471051f282c3b.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3837,
          name: "Mad 4 Summer",
          uuid: "f1dafd35730d4ef9820e09421e93764f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/f1dafd35730d4ef9820e09421e93764f.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3838,
          name: "Mad 4 Valentine's",
          uuid: "4428036eb12c4035bd861c56c30117f3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/4428036eb12c4035bd861c56c30117f3.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3839,
          name: "Mad 4 Xmas",
          uuid: "3add6482fb6c41758922a8a3bd15b122",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/3add6482fb6c41758922a8a3bd15b122.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3858,
          name: "Magic Rush Deluxe",
          uuid: "e97bf6e2b74c4fa1aea245d99d0db2de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/e97bf6e2b74c4fa1aea245d99d0db2de.png",
          type: "other",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3859,
          name: "Magic Rush Win",
          uuid: "290b4914f24b4ad8afe8a053f7697218",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/290b4914f24b4ad8afe8a053f7697218.png",
          type: "other",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3893,
          name: "Maracanã Jackpot",
          uuid: "4a01e65005e7ce2ed706a47315afc083b96e5d91",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/4a01e65005e7ce2ed706a47315afc083b96e5d91.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3840,
          name: "Mermaid",
          uuid: "482bb410a5474152a6b56a6382e0b4e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/482bb410a5474152a6b56a6382e0b4e5.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3841,
          name: "Miss Liberty",
          uuid: "df0566c39a2247f695353e93c9587877",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/df0566c39a2247f695353e93c9587877.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3842,
          name: "Mu vs. Atlantis",
          uuid: "2f5c999dbf684688adfc7cb0fc6ecdce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/2f5c999dbf684688adfc7cb0fc6ecdce.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3843,
          name: "Nebula",
          uuid: "0871bf977882446bb3932ba8251a6cb5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/0871bf977882446bb3932ba8251a6cb5.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3844,
          name: "Ninja Cats",
          uuid: "538e7fa229984f37abfd4e121d413c36",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/538e7fa229984f37abfd4e121d413c36.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3887,
          name: "One More Poker",
          uuid: "af6918b2cb7f4030c16c603df9879fd22fd04aa4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/af6918b2cb7f4030c16c603df9879fd22fd04aa4.png",
          type: "card",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3845,
          name: "Pogo's Circus",
          uuid: "5a0fb0a442a948bbba9e2ade1f7483c1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/5a0fb0a442a948bbba9e2ade1f7483c1.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3804,
          name: "Poker Roulette",
          uuid: "d9f71d5200e14ff68c6020dbcf69bc2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/d9f71d5200e14ff68c6020dbcf69bc2c.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3846,
          name: "Potion Trick",
          uuid: "06a753fc46da49b4b69a87cf4fe3ccc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/06a753fc46da49b4b69a87cf4fe3ccc8.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3860,
          name: "Power Balls",
          uuid: "a5afa3098f6c4c08bad80a32c53d2bf0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/a5afa3098f6c4c08bad80a32c53d2bf0.png",
          type: "other",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3861,
          name: "Power Balls Light",
          uuid: "096035d4863643f8af04665246a6ee68",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/096035d4863643f8af04665246a6ee68.png",
          type: "other",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3847,
          name: "Pyramid Quest",
          uuid: "275f2f82b2114a2db0854380b102a426",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/275f2f82b2114a2db0854380b102a426.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3848,
          name: "Rabbit's Crown",
          uuid: "05a60e9b065c4d37943e8afb61923c98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/05a60e9b065c4d37943e8afb61923c98.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3894,
          name: "Rooster Booster",
          uuid: "0adc2b25924885a0bda3aedbdde57bc294652777",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/0adc2b25924885a0bda3aedbdde57bc294652777.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3886,
          name: "Roulette American Pro",
          uuid: "341519daee586d87d6068a5f6c2a23bb81d7cd5a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/341519daee586d87d6068a5f6c2a23bb81d7cd5a.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3885,
          name: "Roulette Euro Pro",
          uuid: "2b080933106c6a89bb377523bdc5f2163bc6e176",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2b080933106c6a89bb377523bdc5f2163bc6e176.png",
          type: "roulette",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3849,
          name: "Sex on the Beach",
          uuid: "8eac514c39264ec28ff0a2fd212c668f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/8eac514c39264ec28ff0a2fd212c668f.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3876,
          name: "Single Deck BJ",
          uuid: "c944f0852ab443aeb41e8338ea83c8ab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c944f0852ab443aeb41e8338ea83c8ab.png",
          type: "card",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3877,
          name: "Six Card Charlie Blackjack",
          uuid: "1c99a4f954654d7c8d510f1bf0aad05a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1c99a4f954654d7c8d510f1bf0aad05a.png",
          type: "card",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3850,
          name: "Soccereels",
          uuid: "3685598351dc452cab716299df59b2f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/3685598351dc452cab716299df59b2f7.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3851,
          name: "Sphinx Race Deluxe",
          uuid: "a080c804b9f04404b23eed8c93606afa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/a080c804b9f04404b23eed8c93606afa.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3872,
          name: "Steam Joker Poker",
          uuid: "08c240c55cfc4e6da57618956d1cae1c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/08c240c55cfc4e6da57618956d1cae1c.png",
          type: "video poker",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3852,
          name: "Steam Joker Slot",
          uuid: "f7ec07ac40c24cb38dec77795c54e39b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/f7ec07ac40c24cb38dec77795c54e39b.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3879,
          name: "Texas Hold'em Poker",
          uuid: "e67bee1ca09d4b5da01b0b5f4a909165",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e67bee1ca09d4b5da01b0b5f4a909165.png",
          type: "card",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3853,
          name: "The Creeps",
          uuid: "43e299dc50cc4697b606b8d8148f7f72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/43e299dc50cc4697b606b8d8148f7f72.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3854,
          name: "The Midnight Jackpot",
          uuid: "88eb454b1e954460b57ef041298047d6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/88eb454b1e954460b57ef041298047d6.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3855,
          name: "Treasure Island",
          uuid: "3045d8742f6740dd89a2d675e54f15e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/3045d8742f6740dd89a2d675e54f15e6.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3856,
          name: "Van Penguin",
          uuid: "da12f5e56ca14a2891691a8f01132ac5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/da12f5e56ca14a2891691a8f01132ac5.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3896,
          name: "Wild Japan",
          uuid: "4b978c20d07643e180909012ce93930f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/4b978c20d07643e180909012ce93930f.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3857,
          name: "Winter Win",
          uuid: "f07e80f7a98b4c44a5c883747a5f10e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Espressogames/f07e80f7a98b4c44a5c883747a5f10e9.png",
          type: "slots",
          provider: "Espressogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 118,
      name: "EurasianGamingSlots",
      providerId: 42,
      providerName: "EurasianGamingSlots",
      games: [
        {
          game_id: 3929,
          name: "4 Tigers",
          uuid: "40a96e02a6c44aceabf69537d4282953",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/40a96e02a6c44aceabf69537d4282953.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3928,
          name: "4 Tigers Mobile",
          uuid: "9a2b06a2df6946ff85b60cfcc158cdb9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/9a2b06a2df6946ff85b60cfcc158cdb9.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3963,
          name: "Ancient Artifacts",
          uuid: "e78eb93e94174fdda2bf580a298136da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/e78eb93e94174fdda2bf580a298136da.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3962,
          name: "Ancient Artifacts Mobile",
          uuid: "d09a52960eb0419d93126b4d0c592036",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/d09a52960eb0419d93126b4d0c592036.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4024,
          name: "Ancient Rome",
          uuid: "866415b996d60d90abdb30b89d669d864e3ac64e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/866415b996d60d90abdb30b89d669d864e3ac64e.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4072,
          name: "Ancient Rome Deluxe",
          uuid: "eb883a093b1c4e10ad8fbbf89848019e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/eb883a093b1c4e10ad8fbbf89848019e.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4073,
          name: "Ancient Rome Deluxe Mobile",
          uuid: "3d4b3d69d47f4f51a2f2e0c37a51df76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/3d4b3d69d47f4f51a2f2e0c37a51df76.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4025,
          name: "Ancient Rome Mobile",
          uuid: "6333852e5c74c134bd52d76170aee8e66cd6416a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/6333852e5c74c134bd52d76170aee8e66cd6416a.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4112,
          name: "Aztec Riches",
          uuid: "c782d31bbb69409ba157da80aedd90e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/c782d31bbb69409ba157da80aedd90e6.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4113,
          name: "Aztec Riches Mobile",
          uuid: "4e565e800f1c455db73856c6c1c33b37",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/4e565e800f1c455db73856c6c1c33b37.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3905,
          name: "Bagua",
          uuid: "38de8faa129b47c9be7150c310993f0c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/38de8faa129b47c9be7150c310993f0c.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3967,
          name: "Bagua 2",
          uuid: "996acbc149fa404eb1ce045c7d5fc6e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/996acbc149fa404eb1ce045c7d5fc6e1.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3966,
          name: "Bagua 2 Mobile",
          uuid: "e2c5edb1f0ae4f7b9f3419fae745a5a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/e2c5edb1f0ae4f7b9f3419fae745a5a1.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3904,
          name: "Bagua Mobile",
          uuid: "1d75aa1897c941fcb8f88892e860a9fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/1d75aa1897c941fcb8f88892e860a9fe.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4036,
          name: "Bali",
          uuid: "293948db40097f0121f9d273b1ffb8da33aae269",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/293948db40097f0121f9d273b1ffb8da33aae269.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4037,
          name: "Bali Mobile",
          uuid: "89f37748e894e41d2f628f2003dce8e57bca6ada",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/89f37748e894e41d2f628f2003dce8e57bca6ada.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3990,
          name: "Big Game Safari",
          uuid: "a6eddd606e6c41c3a9cc18f200afc146",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/a6eddd606e6c41c3a9cc18f200afc146.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3991,
          name: "Big Game Safari Mobile",
          uuid: "cc639d402a90476c918773b0be806ef3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/cc639d402a90476c918773b0be806ef3.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3977,
          name: "Blackbeard Legacy",
          uuid: "fdfc465470274d7b8aa753296bbd04ed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/fdfc465470274d7b8aa753296bbd04ed.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3976,
          name: "Blackbeard Legacy Mobile",
          uuid: "e916b3d562214756b2e898e9281321b3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/e916b3d562214756b2e898e9281321b3.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4007,
          name: "Bounty Hunter",
          uuid: "120d793772ad48f493127b7140628ce4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/120d793772ad48f493127b7140628ce4.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4006,
          name: "Bounty Hunter Mobile",
          uuid: "d24ecbea2bae45f7846e8f6a1ed0f0ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/d24ecbea2bae45f7846e8f6a1ed0f0ce.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4064,
          name: "Buccaneer Deluxe",
          uuid: "94587f935cc94fe091ad3a82fa0cce1e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/94587f935cc94fe091ad3a82fa0cce1e.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4065,
          name: "Buccaneer Deluxe Mobile",
          uuid: "d4e3c2c433364a0fb3b4a15185938659",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/d4e3c2c433364a0fb3b4a15185938659.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3931,
          name: "Burning Pearl",
          uuid: "1a0b4390e0b44cd4b4413c0313cc594b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/1a0b4390e0b44cd4b4413c0313cc594b.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3930,
          name: "Burning Pearl Mobile",
          uuid: "33a19c46fddd4c81852d1c0dfa837950",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/33a19c46fddd4c81852d1c0dfa837950.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3935,
          name: "Bushido Blade",
          uuid: "f059051fedf94a4996481a445db6eff1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/f059051fedf94a4996481a445db6eff1.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3934,
          name: "Bushido Blade Mobile",
          uuid: "1a183aac36da4ed99b951c98f8dab372",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/1a183aac36da4ed99b951c98f8dab372.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3941,
          name: "Caishen Riches",
          uuid: "3872baac31854e17a96377a36c928f8e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/3872baac31854e17a96377a36c928f8e.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3940,
          name: "Caishen Riches Mobile",
          uuid: "0dbc274236fa486d9223765c4e19874f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/0dbc274236fa486d9223765c4e19874f.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3901,
          name: "Chilli Hunter",
          uuid: "4ab2213b2ca44e24b2d9ef8947216f84",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/4ab2213b2ca44e24b2d9ef8947216f84.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3900,
          name: "Chilli Hunter Mobile",
          uuid: "4dcec2e4dc034cf8ad5014eb16c37873",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/4dcec2e4dc034cf8ad5014eb16c37873.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3943,
          name: "China",
          uuid: "72fc7f4de68f4c749a4bc67a80aeac4d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/72fc7f4de68f4c749a4bc67a80aeac4d.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3942,
          name: "China Mobile",
          uuid: "e1ab087f914f4d539f2293ef3b0f8b5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/e1ab087f914f4d539f2293ef3b0f8b5c.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3947,
          name: "Chinese Boss",
          uuid: "ac3933c63c2e488dbe08381baa8ad75e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ac3933c63c2e488dbe08381baa8ad75e.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3946,
          name: "Chinese Boss Mobile",
          uuid: "097d1e4a5be344e28b9a93b7abb73fd6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/097d1e4a5be344e28b9a93b7abb73fd6.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3975,
          name: "Cluster Mania",
          uuid: "03a87e5668a04c59af2fe548f903b1bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/03a87e5668a04c59af2fe548f903b1bb.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3974,
          name: "Cluster Mania Mobile",
          uuid: "8d6c9d7ca22c4af0980b0f0456c1022d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/8d6c9d7ca22c4af0980b0f0456c1022d.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4066,
          name: "Critter Mania Deluxe",
          uuid: "8df0f3f3d55743ad97481dee2ca719c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/8df0f3f3d55743ad97481dee2ca719c0.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4067,
          name: "Critter Mania Deluxe Mobile",
          uuid: "de31953b78144ce2af1cfad999cbd537",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/de31953b78144ce2af1cfad999cbd537.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4044,
          name: "Crittermania",
          uuid: "6ab4fa2ce060bb91d4a26dd48d174130c53563a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/6ab4fa2ce060bb91d4a26dd48d174130c53563a5.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4045,
          name: "Crittermania Mobile",
          uuid: "8b1471fdb7144c4627e0769eadedfb5f789d65ed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/8b1471fdb7144c4627e0769eadedfb5f789d65ed.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3921,
          name: "Cryptomania",
          uuid: "24c6257827d6494abb78e3b54f816604",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/24c6257827d6494abb78e3b54f816604.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3920,
          name: "Cryptomania Mobile",
          uuid: "c3dcf7c570674e948e865096d59edc8b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/c3dcf7c570674e948e865096d59edc8b.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4068,
          name: "Cursed Deluxe",
          uuid: "00221f4ecd124db7b381e4e05db91f30",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/00221f4ecd124db7b381e4e05db91f30.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4069,
          name: "Cursed Deluxe Mobile",
          uuid: "b939ef2f4dd0419a89d3829f2debe527",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/b939ef2f4dd0419a89d3829f2debe527.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4005,
          name: "Cyber Race",
          uuid: "352c434a5c194e199df7abd43235727a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/352c434a5c194e199df7abd43235727a.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4004,
          name: "Cyber Race Mobile",
          uuid: "dd7022fe4fa646f98c50af9d1357eaf4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/dd7022fe4fa646f98c50af9d1357eaf4.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4076,
          name: "Date With Miyo",
          uuid: "b308719c86d648e9a7c55b0117886bbd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/b308719c86d648e9a7c55b0117886bbd.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4077,
          name: "Date With Miyo Mobile",
          uuid: "6905ab139daf4ddda1ad6ba11deac481",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/6905ab139daf4ddda1ad6ba11deac481.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4015,
          name: "Dia De Los Muertos",
          uuid: "d5733a41841d4aefb549e84216a78cdf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/d5733a41841d4aefb549e84216a78cdf.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4058,
          name: "Dia De Los Muertos Deluxe",
          uuid: "4143cd5778b840d99f5d6ed877e62dca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/4143cd5778b840d99f5d6ed877e62dca.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4059,
          name: "Dia De Los Muertos Deluxe Mobile",
          uuid: "e7c5486e35c44df0a63de11f1aefd556",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/e7c5486e35c44df0a63de11f1aefd556.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4014,
          name: "Dia De Los Muertos Mobile",
          uuid: "2d85ae8bacbd48c18968b86dc371cb65",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/2d85ae8bacbd48c18968b86dc371cb65.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3971,
          name: "Dragon of The Eastern Sea",
          uuid: "7abf5252656d4e2fb220f1d3f10aa0ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/7abf5252656d4e2fb220f1d3f10aa0ef.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3970,
          name: "Dragon of The Eastern Sea Mobile",
          uuid: "6e4a8c3de7db4e79b0dbe5d6cdf46cec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/6e4a8c3de7db4e79b0dbe5d6cdf46cec.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3939,
          name: "Dragon Powerflame",
          uuid: "d89cbe04d9ff4233b9f74ff38e8620e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/d89cbe04d9ff4233b9f74ff38e8620e0.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3938,
          name: "Dragon Powerflame Mobile",
          uuid: "22a1a44e04d14b57b83138f21eae1bc5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/22a1a44e04d14b57b83138f21eae1bc5.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3927,
          name: "Dynamite Reels",
          uuid: "4710cc2a95b94c60b094ae076b48ebb9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/4710cc2a95b94c60b094ae076b48ebb9.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3926,
          name: "Dynamite Reels Mobile",
          uuid: "8cd4840682bd485e9ba9980db192b41d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/8cd4840682bd485e9ba9980db192b41d.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3953,
          name: "Empress Regnant",
          uuid: "e61a8034fa734c3495f2c1b64ccc5cee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/e61a8034fa734c3495f2c1b64ccc5cee.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3952,
          name: "Empress Regnant Mobile",
          uuid: "f52933c332bd452a8bd7dccfaec6cc74",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/f52933c332bd452a8bd7dccfaec6cc74.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3945,
          name: "Enter The KTV",
          uuid: "1663e94a937d4de6aad4b8a0696ce1f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/1663e94a937d4de6aad4b8a0696ce1f6.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3944,
          name: "Enter The KTV Mobile",
          uuid: "9a6a105af8784b18acf1148e75192f36",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/9a6a105af8784b18acf1148e75192f36.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3992,
          name: "Feng Huang",
          uuid: "adaeba190e804adf843e6697cdab6c53",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/adaeba190e804adf843e6697cdab6c53.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3993,
          name: "Feng Huang Mobile",
          uuid: "f42c8384291946c287dd5ae9c082bd54",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/f42c8384291946c287dd5ae9c082bd54.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4109,
          name: "Feng Shen",
          uuid: "2a4252c47fea64b7b04de4a1ef7281d099e68287",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/2a4252c47fea64b7b04de4a1ef7281d099e68287.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4108,
          name: "Feng Shen Mobile",
          uuid: "910c37ed72b64c3f4bbf6f09ff375f90c2c704e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/910c37ed72b64c3f4bbf6f09ff375f90c2c704e8.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4013,
          name: "Forest Treasure",
          uuid: "f9225a6d742d4ec08336167ffdb7dfbc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/f9225a6d742d4ec08336167ffdb7dfbc.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4012,
          name: "Forest Treasure Mobile",
          uuid: "303bee57ac4e4dc2b5e2ead0c8514c5e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/303bee57ac4e4dc2b5e2ead0c8514c5e.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3997,
          name: "Fortune Festival",
          uuid: "26cfdbe47d6648a7b0f2559012211d42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/26cfdbe47d6648a7b0f2559012211d42.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3996,
          name: "Fortune Festival Mobile",
          uuid: "533aaef02ed74158bd4d10c0fac3df1d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/533aaef02ed74158bd4d10c0fac3df1d.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4110,
          name: "Fortune Koi",
          uuid: "ef0a23f6b106455794189ec4c86e3557",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ef0a23f6b106455794189ec4c86e3557.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4111,
          name: "Fortune Koi Mobile",
          uuid: "c914351717cd4d5782f12b7a1afeccdf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/c914351717cd4d5782f12b7a1afeccdf.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3951,
          name: "Four Dragons",
          uuid: "ee31383dcd6b47f49a6207b6d386840d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ee31383dcd6b47f49a6207b6d386840d.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3950,
          name: "Four Dragons Mobile",
          uuid: "bea34aedb0bf48e3b533da7176b34886",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/bea34aedb0bf48e3b533da7176b34886.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4104,
          name: "Gates of Wealth",
          uuid: "97c7c7d1d5c54920ad9568b7e1162e15",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/97c7c7d1d5c54920ad9568b7e1162e15.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4105,
          name: "Gates of Wealth Mobile",
          uuid: "9f758c6dbdd0416fadf6b8a1bf45038f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/9f758c6dbdd0416fadf6b8a1bf45038f.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4038,
          name: "Genie 2",
          uuid: "e5f069091a22200c3ff9d7b79999736bbda785a0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/e5f069091a22200c3ff9d7b79999736bbda785a0.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4039,
          name: "Genie 2 Mobile",
          uuid: "700eb6e64ebc0ed05890c7fe0f32d1acc2a69fc0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/700eb6e64ebc0ed05890c7fe0f32d1acc2a69fc0.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4017,
          name: "Gold Trail",
          uuid: "4d300e4f9b6841078171c09e18548495",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/4d300e4f9b6841078171c09e18548495.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4016,
          name: "Gold Trail Mobile",
          uuid: "377962da4ee140fd958c138a7fe48853",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/377962da4ee140fd958c138a7fe48853.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4098,
          name: "Golden Sea",
          uuid: "f34c2fd7a44542a49b8ad45061a70236",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/f34c2fd7a44542a49b8ad45061a70236.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4099,
          name: "Golden Sea Mobile",
          uuid: "a7fa051ed12b44b6a7072522af5ebc73",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/a7fa051ed12b44b6a7072522af5ebc73.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4011,
          name: "Haunted House",
          uuid: "c277fa60d151436e8582cae0333cf1cb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/c277fa60d151436e8582cae0333cf1cb.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4010,
          name: "Haunted House Mobile",
          uuid: "e86263b1cc104f5b82a7e92cb6a3fa7e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/e86263b1cc104f5b82a7e92cb6a3fa7e.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4028,
          name: "Heist",
          uuid: "2afd0f8679a40a6adfddd9a769f516093ac2ed9e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2afd0f8679a40a6adfddd9a769f516093ac2ed9e.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4054,
          name: "Heist Deluxe",
          uuid: "fcf67725f9c949bcb7ed2346d55bdf8d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/fcf67725f9c949bcb7ed2346d55bdf8d.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4055,
          name: "Heist Deluxe Mobile",
          uuid: "5de9c0f3b240468da5bdd3143eb417e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/5de9c0f3b240468da5bdd3143eb417e0.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4029,
          name: "Heist Mobile",
          uuid: "75ecefe175b878beffacab53009f9b0b8fdee5b2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/75ecefe175b878beffacab53009f9b0b8fdee5b2.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3903,
          name: "Horus Eye",
          uuid: "db9981ec8962477bbcd85b7c256d6801",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/db9981ec8962477bbcd85b7c256d6801.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3902,
          name: "Horus Eye Mobile",
          uuid: "9aac6161de4f49b488616afafb17cc88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/9aac6161de4f49b488616afafb17cc88.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3923,
          name: "Hot Fruits",
          uuid: "ad9cbce41dc64c51984c146e79f948ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ad9cbce41dc64c51984c146e79f948ef.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3922,
          name: "Hot Fruits Mobile",
          uuid: "6aaaa41fc06540ee966147680d505edc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/6aaaa41fc06540ee966147680d505edc.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3981,
          name: "Joker Madness",
          uuid: "02b9afbabd774ffcab104f9f0deebb4e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/02b9afbabd774ffcab104f9f0deebb4e.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3980,
          name: "Joker Madness Mobile",
          uuid: "71337c94084740e9afd8c2d26c06e5af",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/71337c94084740e9afd8c2d26c06e5af.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4100,
          name: "Koi Bingo",
          uuid: "7b54c2ecd50c4d8ba82bc948bb0c7ed2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/7b54c2ecd50c4d8ba82bc948bb0c7ed2.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4101,
          name: "Koi Bingo Mobile",
          uuid: "825dd6f14bf04571bd94bf43a8623132",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/825dd6f14bf04571bd94bf43a8623132.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4074,
          name: "Kraken Hunter",
          uuid: "5d5a72d4b79d4b0f888e6db5e9412eb7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/5d5a72d4b79d4b0f888e6db5e9412eb7.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4075,
          name: "Kraken Hunter Mobile",
          uuid: "30a1edb27f814d16a61b7324a35ce0b3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/30a1edb27f814d16a61b7324a35ce0b3.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3909,
          name: "Lady Hawk",
          uuid: "39696399b8274bf2a1a4c0d13dc0ba9a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/39696399b8274bf2a1a4c0d13dc0ba9a.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3908,
          name: "Lady Hawk Mobile",
          uuid: "8f4a0f6ac1ad4aafa255fd02646cb353",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/8f4a0f6ac1ad4aafa255fd02646cb353.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4048,
          name: "Legend of White Snake",
          uuid: "e3f54069c00e172b411eda99e14b78366ca360df",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/e3f54069c00e172b411eda99e14b78366ca360df.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4049,
          name: "Legend of White Snake Mobile",
          uuid: "219539fc0b5f9609556f072811e2481ef59d13fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/219539fc0b5f9609556f072811e2481ef59d13fa.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4078,
          name: "Leprechaun",
          uuid: "2100d65b84a3a211b052ae92bc5ca034a811ccce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/2100d65b84a3a211b052ae92bc5ca034a811ccce.png",
          type: "other",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4079,
          name: "Leprechaun Mobile",
          uuid: "5432be27d8f49094b94247ffcd4b3734d757bb95",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/5432be27d8f49094b94247ffcd4b3734d757bb95.png",
          type: "other",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3933,
          name: "Lightning God",
          uuid: "935d4949e14145fb8c4d2fd0a1b03465",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/935d4949e14145fb8c4d2fd0a1b03465.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3932,
          name: "Lightning God Mobile",
          uuid: "5c09c90d3d7349329f12a8344ba6c2ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/5c09c90d3d7349329f12a8344ba6c2ad.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4091,
          name: "Lion of the East",
          uuid: "5e6a36c71a1cbd824caa7c84ababe0c96c07867f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/5e6a36c71a1cbd824caa7c84ababe0c96c07867f.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4090,
          name: "Lion of the East Mobile",
          uuid: "7aea6806fc15a284f550dfeeac415fff496faae2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/7aea6806fc15a284f550dfeeac415fff496faae2.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3937,
          name: "Lions Dance",
          uuid: "47682552d6ea4044ba5f6bc3802235e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/47682552d6ea4044ba5f6bc3802235e0.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3936,
          name: "Lions Dance Mobile",
          uuid: "fd683095475645569055abc036644892",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/fd683095475645569055abc036644892.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4089,
          name: "Love letter",
          uuid: "5d13d10c4e6ce167d09c8f39b96af43728c7b9a0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/5d13d10c4e6ce167d09c8f39b96af43728c7b9a0.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4088,
          name: "Love letter Mobile",
          uuid: "86e8d25348dd74fa612ba532388ab06c5f73b369",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/86e8d25348dd74fa612ba532388ab06c5f73b369.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3919,
          name: "Lucky rooster",
          uuid: "b22a64b2e026441cb773c1fa57ed0361",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/b22a64b2e026441cb773c1fa57ed0361.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3918,
          name: "Lucky rooster Mobile",
          uuid: "02db63693111470eb43297c924de62d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/02db63693111470eb43297c924de62d3.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3999,
          name: "Lucky Streak",
          uuid: "a711a24b541b4203b77b741bb142ee47",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/a711a24b541b4203b77b741bb142ee47.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3998,
          name: "Lucky Streak Mobile",
          uuid: "e94b0c10b75340ddbfb90cbfe821deb7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/e94b0c10b75340ddbfb90cbfe821deb7.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4106,
          name: "Mahjong Reactor",
          uuid: "e04a94355fab45b89db26acb5d60e435",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/e04a94355fab45b89db26acb5d60e435.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4107,
          name: "Mahjong Reactor Mobile",
          uuid: "ee4653c0464a4f638d3c361a7755122f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ee4653c0464a4f638d3c361a7755122f.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4102,
          name: "Masquerade",
          uuid: "5ae1d4d1a2654292928ac8da35691fc0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/5ae1d4d1a2654292928ac8da35691fc0.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4103,
          name: "Masquerade Mobile",
          uuid: "25f93b0ecf0f443994f2d72ef3a6d022",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/25f93b0ecf0f443994f2d72ef3a6d022.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4093,
          name: "Mermaid Treasure",
          uuid: "4e1fd519a06608f463284703946bc7e76dc7614d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/4e1fd519a06608f463284703946bc7e76dc7614d.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4092,
          name: "Mermaid Treasure Mobile",
          uuid: "1e9496fabf33de89f1ec54d2ebae1e86e7fd6e4c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/1e9496fabf33de89f1ec54d2ebae1e86e7fd6e4c.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3917,
          name: "Money Vault",
          uuid: "e10777925fc540be84ce9e45023dec9c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/e10777925fc540be84ce9e45023dec9c.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3916,
          name: "Money Vault Mobile",
          uuid: "c9778214306e425fbfb5cae9f2b8f4d0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/c9778214306e425fbfb5cae9f2b8f4d0.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3979,
          name: "Mythical Sand",
          uuid: "9b99195671e74c4e8d9511851830c2f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/9b99195671e74c4e8d9511851830c2f6.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3978,
          name: "Mythical Sand Mobile",
          uuid: "53f91f2881a6414ea21996e3b221624c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/53f91f2881a6414ea21996e3b221624c.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4019,
          name: "Mythological",
          uuid: "cee5fc75192448d6850adc6c32819538",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/cee5fc75192448d6850adc6c32819538.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4018,
          name: "Mythological Mobile",
          uuid: "284f071adb3245e18b954c12d80063ee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/284f071adb3245e18b954c12d80063ee.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3925,
          name: "Neptune Treasure",
          uuid: "10899f77f7394aff864a9a5b7790268e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/10899f77f7394aff864a9a5b7790268e.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3924,
          name: "Neptune Treasure Mobile",
          uuid: "cb0972a134214eec9118f35b9a06411e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/cb0972a134214eec9118f35b9a06411e.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3911,
          name: "Nugget Hunters",
          uuid: "0bd6aae0feec421a9df808e23e716282",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/0bd6aae0feec421a9df808e23e716282.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3910,
          name: "Nugget Hunters Mobile",
          uuid: "c3c05b135ddf444e8a06295cfa1e0853",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/c3c05b135ddf444e8a06295cfa1e0853.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4003,
          name: "Ocean Spray",
          uuid: "458aa4db3d094b768977ac60802e07d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/458aa4db3d094b768977ac60802e07d7.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4002,
          name: "Ocean Spray Mobile",
          uuid: "9b2d117f2de6445dbbd79f1c2e8fb7cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/9b2d117f2de6445dbbd79f1c2e8fb7cf.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3907,
          name: "Octagon Gem",
          uuid: "ed6fb068b92c42a68d93a4ef2698ba76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ed6fb068b92c42a68d93a4ef2698ba76.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3969,
          name: "Octagon Gem 2",
          uuid: "adb58a4a970f40bf9445088204571c16",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/adb58a4a970f40bf9445088204571c16.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3968,
          name: "Octagon Gem 2 Mobile",
          uuid: "43bf3e618d914fd283241fbaa5e6ff47",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/43bf3e618d914fd283241fbaa5e6ff47.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3906,
          name: "Octagon Gem Mobile",
          uuid: "2401a99a12794525bc0a3e4acb971a5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/2401a99a12794525bc0a3e4acb971a5d.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3987,
          name: "Ong Bak",
          uuid: "52e84801e98149e7b64216303233c592",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/52e84801e98149e7b64216303233c592.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3994,
          name: "Ong Bak 2",
          uuid: "f7005d8aaef042198094de9aaea60e19",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/f7005d8aaef042198094de9aaea60e19.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3995,
          name: "Ong Bak 2 Mobile",
          uuid: "95e78f4192a5449aad0ecea4947c1494",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/95e78f4192a5449aad0ecea4947c1494.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4070,
          name: "Ong Bak Deluxe",
          uuid: "446984c79a89496abecefc9258f7d999",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/446984c79a89496abecefc9258f7d999.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4071,
          name: "Ong Bak Deluxe Mobile",
          uuid: "6518658f661d477286dacb4c49cb2bc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/6518658f661d477286dacb4c49cb2bc8.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3986,
          name: "Ong Bak Mobile",
          uuid: "b6189c1faa214af181936cd8ccdaf27e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/b6189c1faa214af181936cd8ccdaf27e.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4050,
          name: "Pan Jin Lian 2",
          uuid: "725a134dfc8a90c0ede4f3817fdbe4cccdc414d0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/725a134dfc8a90c0ede4f3817fdbe4cccdc414d0.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4051,
          name: "Pan Jin Lian 2 Mobile",
          uuid: "a016b9e88aee029762cc46091d8157fd7f91dd96",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/a016b9e88aee029762cc46091d8157fd7f91dd96.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4085,
          name: "Panda Chef",
          uuid: "bf994378a8dd1bcfc42c48d2ad1d2a83b8d77e42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/bf994378a8dd1bcfc42c48d2ad1d2a83b8d77e42.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4084,
          name: "Panda Chef Mobile",
          uuid: "1cf73ec2c43f4b20b2e0ccffd63f0bb30b071db1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/1cf73ec2c43f4b20b2e0ccffd63f0bb30b071db1.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3959,
          name: "Peach Banquet",
          uuid: "7ee6e4f9cb2c46a78c3277e3f8b9627a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/7ee6e4f9cb2c46a78c3277e3f8b9627a.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3958,
          name: "Peach Banquet Mobile",
          uuid: "a99cf914e38a4643bbea647e3469cbad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/a99cf914e38a4643bbea647e3469cbad.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3955,
          name: "Phoenix 888",
          uuid: "52be1960b836487eba3027b990fabc1a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/52be1960b836487eba3027b990fabc1a.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3954,
          name: "Phoenix 888 Mobile",
          uuid: "66a03f80cb804145b2eee7cae93bd1ae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/66a03f80cb804145b2eee7cae93bd1ae.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4052,
          name: "Qatar 2022",
          uuid: "44d76d92890a0a421aaeb3e18d74956aafd3105c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/44d76d92890a0a421aaeb3e18d74956aafd3105c.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4053,
          name: "Qatar 2022 Mobile",
          uuid: "bb67e3320c115c0f9f40fd3f0ccdd02a79780b29",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/bb67e3320c115c0f9f40fd3f0ccdd02a79780b29.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4032,
          name: "Queen 2",
          uuid: "bfc484da48182a32efcd340c55a4f7268903f113",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/bfc484da48182a32efcd340c55a4f7268903f113.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4033,
          name: "Queen 2 Mobile",
          uuid: "b86a6363eac3834426443fe438be3a04063ccb27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/b86a6363eac3834426443fe438be3a04063ccb27.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4087,
          name: "Safari Life 2",
          uuid: "f2185bfb1c57b3ef78ec95e1dbad148b436ee79a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/f2185bfb1c57b3ef78ec95e1dbad148b436ee79a.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4086,
          name: "Safari Life 2 Mobile",
          uuid: "cc7ce72dab1c9f095271856a73202ad01af6f425",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/cc7ce72dab1c9f095271856a73202ad01af6f425.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4009,
          name: "Shaolin",
          uuid: "1b3de09046fa470e92882a986a6b737a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/1b3de09046fa470e92882a986a6b737a.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4008,
          name: "Shaolin Mobile",
          uuid: "488e69244faf4a22884ba36b8713bcfb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/488e69244faf4a22884ba36b8713bcfb.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4094,
          name: "SOUR CANDY",
          uuid: "3b1454a480af4a2a86bc4ce2a282ca98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/3b1454a480af4a2a86bc4ce2a282ca98.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4095,
          name: "SOUR CANDY Mobile",
          uuid: "9f22b6ac17c8447ea3984b01c9accf02",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/9f22b6ac17c8447ea3984b01c9accf02.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4035,
          name: "Streets of Chicago",
          uuid: "223b5c8426730510a8e1bc7012d8e20f8fac2ed8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/223b5c8426730510a8e1bc7012d8e20f8fac2ed8.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4034,
          name: "Streets of Chicago Mobile",
          uuid: "ffabd235890f1a1941eda8a225760d44b4d39558",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ffabd235890f1a1941eda8a225760d44b4d39558.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4082,
          name: "Sweet Tooth",
          uuid: "ff8ff0136770cdc18e64bdc1994b9f70c6d5f708",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ff8ff0136770cdc18e64bdc1994b9f70c6d5f708.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4083,
          name: "Sweet Tooth Mobile",
          uuid: "0df851e5b786d53e7b45c2f731c71d3d5c808c98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/0df851e5b786d53e7b45c2f731c71d3d5c808c98.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4097,
          name: "Sweetie Crush",
          uuid: "9d8d6ce49b3f4ee6a463fdf2f3145a72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/9d8d6ce49b3f4ee6a463fdf2f3145a72.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4096,
          name: "Sweetie Crush Mobile",
          uuid: "7ab6929cfc644f04951330b2d0ae1f3c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/7ab6929cfc644f04951330b2d0ae1f3c.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3961,
          name: "Tai Shang Lao Jun",
          uuid: "8f469cd0763f4074b6f1853f198a5882",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/8f469cd0763f4074b6f1853f198a5882.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3960,
          name: "Tai Shang Lao Jun Mobile",
          uuid: "38b9d21612654f58addadd9ab3fe5514",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/38b9d21612654f58addadd9ab3fe5514.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4026,
          name: "Talisman",
          uuid: "c949a5c6427acd182be1571f3c325533e3f54f28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/c949a5c6427acd182be1571f3c325533e3f54f28.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4027,
          name: "Talisman Mobile",
          uuid: "8fadc958b0e8de652ed06f786717a7c21f9dcc04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/8fadc958b0e8de652ed06f786717a7c21f9dcc04.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3983,
          name: "The Four Inventions",
          uuid: "15fcdc520bc444a59a28ddb11cd828bd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/15fcdc520bc444a59a28ddb11cd828bd.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3982,
          name: "The Four Inventions Mobile",
          uuid: "ed9ca47abbbd49039f43a46c71ef94fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ed9ca47abbbd49039f43a46c71ef94fe.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3973,
          name: "The Wild Protectors",
          uuid: "811f6f5e514942318a9b7490bb0c0c20",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/811f6f5e514942318a9b7490bb0c0c20.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3972,
          name: "The Wild Protectors Mobile",
          uuid: "70c84b1053a74f769f2afc326e067709",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/70c84b1053a74f769f2afc326e067709.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3957,
          name: "Third Prince's Journey",
          uuid: "1566d8d7819d4ea8b7fdc15f7fcbfbf9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/1566d8d7819d4ea8b7fdc15f7fcbfbf9.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3956,
          name: "Third Prince's Journey Mobile",
          uuid: "06572b11f52e48ebb5beb3169d63ce1a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/06572b11f52e48ebb5beb3169d63ce1a.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4030,
          name: "Three Kingdoms 2",
          uuid: "362a084b303be0cdd5bf112fc14df3b4eed623d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/362a084b303be0cdd5bf112fc14df3b4eed623d7.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4031,
          name: "Three Kingdoms 2 Mobile",
          uuid: "aa2c08128f478676d445cf3c7873ae2184c2268b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/aa2c08128f478676d445cf3c7873ae2184c2268b.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3985,
          name: "Thug Life",
          uuid: "20fa613ea09a4c6c8bd8a354c5df7302",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/20fa613ea09a4c6c8bd8a354c5df7302.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3984,
          name: "Thug Life Mobile",
          uuid: "aa03f9e6dae84e24b38fe32a55755769",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/aa03f9e6dae84e24b38fe32a55755769.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4114,
          name: "Trick or Treat",
          uuid: "a7557b2cf0ec467d805966a612d9db36",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/a7557b2cf0ec467d805966a612d9db36.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4115,
          name: "Trick or Treat Mobile",
          uuid: "0b483d93a79343eaa44d4434fc788e79",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/0b483d93a79343eaa44d4434fc788e79.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4023,
          name: "Tropical Crush",
          uuid: "3f394d1c9b9e4394bea67a233499a27f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3f394d1c9b9e4394bea67a233499a27f.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4022,
          name: "Tropical Crush Mobile",
          uuid: "eeecb72dca07430aafc2698547ad373c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/eeecb72dca07430aafc2698547ad373c.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4080,
          name: "Warrior",
          uuid: "ec5f0a0058d4a39a7b0d68bbaa251cc5e2232f52",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ec5f0a0058d4a39a7b0d68bbaa251cc5e2232f52.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4081,
          name: "Warrior Mobile",
          uuid: "4b87a711f731f8a1fde30b00d5d6cf61c472d768",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/4b87a711f731f8a1fde30b00d5d6cf61c472d768.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4047,
          name: "Wealth God",
          uuid: "f01cfad96841fd15a52807f4b21f015302e64900",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f01cfad96841fd15a52807f4b21f015302e64900.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4046,
          name: "Wealth God Mobile",
          uuid: "aba0976cfd98d3f1a1faa5873bac1816e48e7da8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/aba0976cfd98d3f1a1faa5873bac1816e48e7da8.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3965,
          name: "Wild Fairies",
          uuid: "062ba4e563c64ab49693527cd5a1dfba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/062ba4e563c64ab49693527cd5a1dfba.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3964,
          name: "Wild Fairies Mobile",
          uuid: "fc3776c3acae4495a5c87a11ed1b6030",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/fc3776c3acae4495a5c87a11ed1b6030.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3949,
          name: "Wild Giant Panda",
          uuid: "c911ac1111f7498d891cc58a21d41810",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/c911ac1111f7498d891cc58a21d41810.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3948,
          name: "Wild Giant Panda Mobile",
          uuid: "5a14841c33444ccfba5420a920289b9e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/5a14841c33444ccfba5420a920289b9e.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3915,
          name: "Witch's Brew",
          uuid: "65a75eebc54b457faa26dd0e27acb92a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/65a75eebc54b457faa26dd0e27acb92a.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3914,
          name: "Witch's Brew Mobile",
          uuid: "6791be24d52748178019726b840011e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/6791be24d52748178019726b840011e0.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4021,
          name: "Wizard",
          uuid: "f3cc13cf940e4b7f82794afad08dfaeb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/f3cc13cf940e4b7f82794afad08dfaeb.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4060,
          name: "Wizard Deluxe",
          uuid: "2f50d0c6b4954971bd5436b4300e414a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/2f50d0c6b4954971bd5436b4300e414a.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4061,
          name: "Wizard Deluxe Mobile",
          uuid: "a89a7f70d76f48f38e2dd97b252f63cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/a89a7f70d76f48f38e2dd97b252f63cc.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4020,
          name: "Wizard Mobile",
          uuid: "ad58a1fed6e64f939000e159a534c102",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ad58a1fed6e64f939000e159a534c102.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4040,
          name: "WuKong",
          uuid: "8a0c0da18e62d16aa6e3ccd813fc411946b86f49",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/8a0c0da18e62d16aa6e3ccd813fc411946b86f49.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4041,
          name: "WuKong Mobile",
          uuid: "f70540c82e0303a21c5c6eef0043d28ff910e3cd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f70540c82e0303a21c5c6eef0043d28ff910e3cd.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4042,
          name: "Yakuza",
          uuid: "40a16bc3c9a814d04c84354346552b4fa7ce8459",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/40a16bc3c9a814d04c84354346552b4fa7ce8459.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4043,
          name: "Yakuza Mobile",
          uuid: "8b9862e43d474a90b1d966b1ebbb5bb60fc956cc9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/8b9862e43d474a90b1d966b1ebbb5bb60fc956cc9.jpeg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3989,
          name: "Yeh Hsien",
          uuid: "4725d854472d4f72ab4d73ebfbc1b8d0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/4725d854472d4f72ab4d73ebfbc1b8d0.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4056,
          name: "Yeh Hsien Deluxe",
          uuid: "cb40dfac0b984b2cadf38e9ab4dc49d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/cb40dfac0b984b2cadf38e9ab4dc49d7.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4057,
          name: "Yeh Hsien Deluxe Mobile",
          uuid: "9d7515bf75e947daa252605316ee5374",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/9d7515bf75e947daa252605316ee5374.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3988,
          name: "Yeh Hsien Mobile",
          uuid: "dd26f277a5f345c49c12285a0724be43",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/dd26f277a5f345c49c12285a0724be43.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3913,
          name: "Yggdrasil",
          uuid: "b25ef1ea4e0e4dbb8e6f8490cd64c426",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/b25ef1ea4e0e4dbb8e6f8490cd64c426.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3912,
          name: "Yggdrasil Mobile",
          uuid: "2416f0eb13d54ee8b55f0a5182cc6c01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/2416f0eb13d54ee8b55f0a5182cc6c01.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4001,
          name: "Zodiac",
          uuid: "a53eaddf0ef047edac058d284dd745c7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/a53eaddf0ef047edac058d284dd745c7.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4062,
          name: "Zodiac Deluxe",
          uuid: "ec2862118f704f7b8eb71a46ab43aed6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/ec2862118f704f7b8eb71a46ab43aed6.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4063,
          name: "Zodiac Deluxe Mobile",
          uuid: "6d28eea44dd7498992fc5977048781dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/6d28eea44dd7498992fc5977048781dd.jpg",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4000,
          name: "Zodiac Mobile",
          uuid: "fb3df71192eb42b0b9d4406879b0149a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f624ed082b9280306e3f8daaaf4bc5f1/EurasianGamingSlots/fb3df71192eb42b0b9d4406879b0149a.png",
          type: "slots",
          provider: "EurasianGamingSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 119,
      name: "FBastards",
      providerId: 65,
      providerName: "FBastards",
      games: [
        {
          game_id: 4132,
          name: "Anubis Curse",
          uuid: "fc654e016c1c48e2bdf409116c17f061",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/fc654e016c1c48e2bdf409116c17f061.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4117,
          name: "Bad Babushkas",
          uuid: "842ba957297f42b18377a546dcf5c42f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/842ba957297f42b18377a546dcf5c42f.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4123,
          name: "Book of Wizardry",
          uuid: "e92548755cec4cd0accb9a95f169e4dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/e92548755cec4cd0accb9a95f169e4dd.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4126,
          name: "Cartel Chase",
          uuid: "5bda5ef5482b474dae80062427c059b3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/5bda5ef5482b474dae80062427c059b3.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4135,
          name: "Cyber slot",
          uuid: "386639205eac4987bcbb6a82de015062",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/386639205eac4987bcbb6a82de015062.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4124,
          name: "Demon Slayer",
          uuid: "8e82f13a7cfd4ed7b4e414fb04c6511b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/8e82f13a7cfd4ed7b4e414fb04c6511b.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4131,
          name: "Dia De Los Muertos",
          uuid: "5def716178724ce5ac691b915bd35d23",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/5def716178724ce5ac691b915bd35d23.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4139,
          name: "Elves",
          uuid: "31104f94184a4e24a68c90ce9c1bca36",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/31104f94184a4e24a68c90ce9c1bca36.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4134,
          name: "Fire dragon",
          uuid: "1560f2cf9eb84418a45ca610b646fc24",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/1560f2cf9eb84418a45ca610b646fc24.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 4127,
          name: "Football Hooligans",
          uuid: "6e25a51bc78548fe8bef47bb0f42f042",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/6e25a51bc78548fe8bef47bb0f42f042.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4143,
          name: "Fred-Nfire",
          uuid: "1c1a394c52a04935a8ea2952566ce133",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/1c1a394c52a04935a8ea2952566ce133.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4142,
          name: "GoldWins",
          uuid: "f10e4a00d28f4bce952837442c44bd97",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/f10e4a00d28f4bce952837442c44bd97.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4144,
          name: "Jack win",
          uuid: "cb1ae9cb1d024b38baa87b0c5bb6fa92",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/cb1ae9cb1d024b38baa87b0c5bb6fa92.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4128,
          name: "JokersOnTop",
          uuid: "340fe696abb942d593290860be32fca8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/340fe696abb942d593290860be32fca8.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4137,
          name: "Jolly casino",
          uuid: "b68a9e8785f549f8a04ed0497edad740",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/b68a9e8785f549f8a04ed0497edad740.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4120,
          name: "Level20",
          uuid: "cb1205b859484207bdd366eb930a6a21",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/cb1205b859484207bdd366eb930a6a21.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4141,
          name: "Lucky Farm",
          uuid: "46402819edc248209bff97556e9897f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/46402819edc248209bff97556e9897f8.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4140,
          name: "Mafia",
          uuid: "925d3b2e85f945d98800e3dacbf0c41b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/925d3b2e85f945d98800e3dacbf0c41b.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 4118,
          name: "Mindiana Jones",
          uuid: "d12966866e924b98972447d39ffe59bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/d12966866e924b98972447d39ffe59bf.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4136,
          name: "Ocean party",
          uuid: "206d91515f9b47d5858822b893cf08d6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/206d91515f9b47d5858822b893cf08d6.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4138,
          name: "Quest",
          uuid: "900a460e9877445082395d4df8dd65f2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/900a460e9877445082395d4df8dd65f2.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4145,
          name: "Reels",
          uuid: "61676348e8ba4dc685e9dada33733f53",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/61676348e8ba4dc685e9dada33733f53.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4121,
          name: "ReturnToValhalla",
          uuid: "5a0064d3227f46088e8823880a876c7b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/5a0064d3227f46088e8823880a876c7b.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4122,
          name: "SkaterBoy",
          uuid: "1160901f31554d95a26c9a08a54843c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/1160901f31554d95a26c9a08a54843c0.png",
          type: "arcade",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4116,
          name: "Swindler",
          uuid: "11b98582505044b182895b5cef035eda",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/11b98582505044b182895b5cef035eda.png",
          type: "instant",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4133,
          name: "Thimbles",
          uuid: "5b0dd18d267448fea338eb1cde1d0835",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/5b0dd18d267448fea338eb1cde1d0835.png",
          type: "instant",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4130,
          name: "Treasure Hunter",
          uuid: "d765347b786943efbd6cac60122aa752",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/d765347b786943efbd6cac60122aa752.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4125,
          name: "WildNDice",
          uuid: "5786aa06c14f4722ad41b2c74ff6df93",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/5786aa06c14f4722ad41b2c74ff6df93.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4129,
          name: "Wonder Machine",
          uuid: "ca8c095478a34c4e92d183687d87c08c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/ca8c095478a34c4e92d183687d87c08c.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4119,
          name: "Zombie Knight",
          uuid: "5101e8a05ebe4873881ad8efad2e6b57",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/FBastards/5101e8a05ebe4873881ad8efad2e6b57.png",
          type: "slots",
          provider: "FBastards",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 120,
      name: "Fugaso",
      providerId: 54,
      providerName: "Fugaso",
      games: [
        {
          game_id: 1002,
          name: "9 DRAGON BALLS: CASH UP",
          uuid: "7842cd8cdc1bc11f82ba77789ae2c3f6692b3f11",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/7842cd8cdc1bc11f82ba77789ae2c3f6692b3f11.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 999,
          name: "Almighty Hot Wins",
          uuid: "c02571f98e7e30efb79bd6436fe43015c7ed06c3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/c02571f98e7e30efb79bd6436fe43015c7ed06c3.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 987,
          name: "ARCTIC COINS: RUNNING WINS",
          uuid: "6ddf358f71284d58ae0dbb4de3f4c7cd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/6ddf358f71284d58ae0dbb4de3f4c7cd.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 952,
          name: "Book Of Anime",
          uuid: "9d3995c3bdfb4bb8816f06f774195cb7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/9d3995c3bdfb4bb8816f06f774195cb7.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 976,
          name: "Book of Plutus",
          uuid: "4a0e9874e92644d6b2f1881848971749",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/4a0e9874e92644d6b2f1881848971749.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 946,
          name: "Book Of Tattoo 2",
          uuid: "b0387e7fcde84a31baf728d3f9db69d8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/b0387e7fcde84a31baf728d3f9db69d8.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 989,
          name: "CASH BUSTERS",
          uuid: "9b4e6ebf3d6642bc916c9c6acb49f7a0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/9b4e6ebf3d6642bc916c9c6acb49f7a0.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 973,
          name: "City Pop: Hawaii Running Wins",
          uuid: "c38c526c4ed04ecfb244e1f2e3e2e877",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/c38c526c4ed04ecfb244e1f2e3e2e877.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 947,
          name: "Clash Of Gods",
          uuid: "efb0b86c183a4490b51d38598d42beb1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/efb0b86c183a4490b51d38598d42beb1.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 992,
          name: "COIN RUSH: ELEPHANT STRIKE RUNNING WINS",
          uuid: "5b8bae23b6264300bd1124063e32371c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/5b8bae23b6264300bd1124063e32371c.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4147,
          name: "COIN RUSH: GORILLA BOOST RUNNING WINS™",
          uuid: "dbf024061a8b7ff814de0eaad2f66594a225258d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/dbf024061a8b7ff814de0eaad2f66594a225258d.jpg",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 986,
          name: "COIN RUSH: RHINO RUNNING WINS",
          uuid: "710b65ca9fdf434d858fe768f0d40a5f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/710b65ca9fdf434d858fe768f0d40a5f.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 994,
          name: "Deluxe Fruits 100",
          uuid: "402813d414aa4888a0f2573130ec9674",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/402813d414aa4888a0f2573130ec9674.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 988,
          name: "Devil's Diamond Rush",
          uuid: "04eeedabb69649fca4f1a83b97a83a40",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/04eeedabb69649fca4f1a83b97a83a40.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 961,
          name: "Diamond Blitz 100",
          uuid: "d9a69294e126eadc49c5adb6a7f8f25023bc5a64",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/d9a69294e126eadc49c5adb6a7f8f25023bc5a64.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 955,
          name: "Diamond Blitz 40",
          uuid: "0c3403bc682b47179d91b1ad59be909d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/0c3403bc682b47179d91b1ad59be909d.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 995,
          name: "EGYPT COIN LINK: RUNNING WINS",
          uuid: "d977b9fc3e024e9e9d2c9de414e25bdd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/d977b9fc3e024e9e9d2c9de414e25bdd.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1000,
          name: "EGYPT LINKS: RUNNING WINS",
          uuid: "3da694f738e642e087b002f1e57455bc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/3da694f738e642e087b002f1e57455bc.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 956,
          name: "Fat Mama's Wheel",
          uuid: "8f3fa7a34984404da2bbc6975d3364b7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/8f3fa7a34984404da2bbc6975d3364b7.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 951,
          name: "Fortune Circus",
          uuid: "6c150400633246c29923aa64983e7d8c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/6c150400633246c29923aa64983e7d8c.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 998,
          name: "Fortune Coin Link: RUNNING WINS",
          uuid: "a9f0b2e790484f12b2024c8507436ab6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/a9f0b2e790484f12b2024c8507436ab6.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 974,
          name: "Fruits Royale",
          uuid: "fc3a9666ebe945cf956ac5e40a9d0640",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/fc3a9666ebe945cf956ac5e40a9d0640.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 979,
          name: "Fruits Royale 100",
          uuid: "5e0f8eed623445f0a0f5f9b9b47993dd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/5e0f8eed623445f0a0f5f9b9b47993dd.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 977,
          name: "Fruits Royale 5",
          uuid: "3235464fde2f0d47696ca7a5217f234bd6557744",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/3235464fde2f0d47696ca7a5217f234bd6557744.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 957,
          name: "Hit The Diamond",
          uuid: "e8851961bdab487c996c8a2f8ec22f88",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/e8851961bdab487c996c8a2f8ec22f88.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 980,
          name: "Inferno 777 Re-Spins",
          uuid: "61ed18908b9a9a22bd12123a1919134d038295b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/61ed18908b9a9a22bd12123a1919134d038295b4.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 971,
          name: "Inferno Devil 100",
          uuid: "815a75de91c8418cb7d9b9f451ec3ab3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/815a75de91c8418cb7d9b9f451ec3ab3.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 965,
          name: "Inferno Diamonds",
          uuid: "337369f904fe482aa802959d7969eeb2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/337369f904fe482aa802959d7969eeb2.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 968,
          name: "Inferno Diamonds 100",
          uuid: "2845483930ab4bc8b13fd24e96dbfde4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/2845483930ab4bc8b13fd24e96dbfde4.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1001,
          name: "INFERNO FIREBALLS 2: RUNNING WINS",
          uuid: "ce246685bb87f16de4d4ba9f5756db81b3a48b1c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/ce246685bb87f16de4d4ba9f5756db81b3a48b1c.jpg",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 985,
          name: "INFERNO FIREBALLS: RUNNING WINS",
          uuid: "b9949ed92b634b3a9d19087da58e97cf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/b9949ed92b634b3a9d19087da58e97cf.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 967,
          name: "Into The Jungle",
          uuid: "e502181968c14493a22564ce37473cbc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/e502181968c14493a22564ce37473cbc.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 969,
          name: "Into The Jungle Bonus Buy",
          uuid: "abf7fd19dadc49da9e3eeca9dfe31683",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/abf7fd19dadc49da9e3eeca9dfe31683.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 953,
          name: "Jewel Sea Pirate Riches",
          uuid: "907b6a0fbba64d03a93ad1514c682df1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/907b6a0fbba64d03a93ad1514c682df1.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 960,
          name: "King Of The Ring",
          uuid: "b8ead58d81704067f8094dee1a2b8ec0b8748f4d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/b8ead58d81704067f8094dee1a2b8ec0b8748f4d.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 962,
          name: "Lil' Santa",
          uuid: "750dda1469f74fec911801f8483dfed6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/750dda1469f74fec911801f8483dfed6.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 964,
          name: "Lil' Santa Bonus Buy",
          uuid: "6cc87060f83b4241915ee2e219d0b170",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/6cc87060f83b4241915ee2e219d0b170.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 945,
          name: "Magic Spinners",
          uuid: "2133f997d69044b19da558c6bde02b0b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/2133f997d69044b19da558c6bde02b0b.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 958,
          name: "Magnify Man",
          uuid: "d4c520af428e48799aa7a2c2ab2687bb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/d4c520af428e48799aa7a2c2ab2687bb.png",
          type: "crash",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 991,
          name: "Mega Rich Fruits",
          uuid: "49879e282a554ca0a22123bfe012a04c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/49879e282a554ca0a22123bfe012a04c.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 996,
          name: "Mexican Coins: CASH UP",
          uuid: "e43ebad95f2c4242b1ef5785dd691b7b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/e43ebad95f2c4242b1ef5785dd691b7b.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 993,
          name: "MIDAS LINKS: RUNNING WINS",
          uuid: "0ee1f65a4170440d94119b1279ab90c2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/0ee1f65a4170440d94119b1279ab90c2.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1005,
          name: "MOON OF EGYPT: RUNNING WINS",
          uuid: "f90bb21ce5ee4adc8c7b4a0186ac54d5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/f90bb21ce5ee4adc8c7b4a0186ac54d5.jpg",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 978,
          name: "Moon of Ra: Running Wins",
          uuid: "89611c14af0a9ff11f8e85f102b2539c814be7fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/89611c14af0a9ff11f8e85f102b2539c814be7fa.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 970,
          name: "Pop&Drop",
          uuid: "95fe29a60e044a2a960605784cc09a76",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/95fe29a60e044a2a960605784cc09a76.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1004,
          name: "Power Boost™: Money Express",
          uuid: "da3d0e326b0f4619b5a3bfccc2810b99",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/da3d0e326b0f4619b5a3bfccc2810b99.jpg",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 975,
          name: "Power Wildz: Fruit Saga",
          uuid: "40068e631a744406bc7b24c44d61a0f9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/40068e631a744406bc7b24c44d61a0f9.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 954,
          name: "Santa's Jingle Wheel",
          uuid: "bf0f853e909746e4bb1cf4a0a9e18e8f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/bf0f853e909746e4bb1cf4a0a9e18e8f.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 943,
          name: "Stoned Joker",
          uuid: "41186e35f9a34ec9a75286c5d66e3c96",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/41186e35f9a34ec9a75286c5d66e3c96.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 944,
          name: "Stoned Joker 5",
          uuid: "ee25dd27e06c46a88b5e93f2b33b6c12",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/ee25dd27e06c46a88b5e93f2b33b6c12.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1003,
          name: "Striking Diamond Link: RUNNING WINS",
          uuid: "9a31bf96f29943ca93d3630b9b235289",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/9a31bf96f29943ca93d3630b9b235289.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 990,
          name: "STRIKING DIAMOND: RUNNING WINS",
          uuid: "fdae8ff3cd454a68bde5f0d77f1d1392",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/fdae8ff3cd454a68bde5f0d77f1d1392.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 959,
          name: "Sugar Drop",
          uuid: "c30cb931bae34098aebe745797d0b465",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/c30cb931bae34098aebe745797d0b465.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 963,
          name: "Sugar Drop XMAS",
          uuid: "3cac7cf5e7cc4557b668cf4506d8856b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/3cac7cf5e7cc4557b668cf4506d8856b.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 972,
          name: "Sugar Paradise",
          uuid: "b1308a6554224b598ebc56323370895e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/b1308a6554224b598ebc56323370895e.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 997,
          name: "SWEET FLIPS SUGAR",
          uuid: "b309440c375644c19ac1a62d2072e5f1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/b309440c375644c19ac1a62d2072e5f1.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 950,
          name: "The Mummy Win Hunters",
          uuid: "ea2a2763bc7b4fcb99cc0fcee02c5487",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/ea2a2763bc7b4fcb99cc0fcee02c5487.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 949,
          name: "The Mummy Win Hunters EPICWAYS",
          uuid: "54ee395c4dd74ab7aa48913fccc5d254",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/54ee395c4dd74ab7aa48913fccc5d254.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4146,
          name: "THUNDER WOLF LINK: RUNNING WINS",
          uuid: "96a3dcffdd708178203839cfbad7da0533f2181c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/96a3dcffdd708178203839cfbad7da0533f2181c.jpg",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 966,
          name: "Tropical Gold",
          uuid: "560b3123b2ab7745766abe1e3e27ddb06485b613",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/560b3123b2ab7745766abe1e3e27ddb06485b613.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 948,
          name: "Trump It Deluxe EPICWAYS",
          uuid: "d130091abb664b11a0e1b0bb31f452fc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/d130091abb664b11a0e1b0bb31f452fc.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 984,
          name: "Vegas Starstruck: RUNNING WINS",
          uuid: "47f04c0bd53c4e7ab71ae9fe12c0d396",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Fugaso/47f04c0bd53c4e7ab71ae9fe12c0d396.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 983,
          name: "XMAS COINS: RUNNING WINS",
          uuid: "f1dab17bee795565622ace75ab632315231c90ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/f1dab17bee795565622ace75ab632315231c90ea.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 981,
          name: "Xmas Royale",
          uuid: "79cc37ac62a5bb0de860a17460be9b7e7a88cb7d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/79cc37ac62a5bb0de860a17460be9b7e7a88cb7d.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 982,
          name: "XMAS ROYALE 100",
          uuid: "ef333de260477e313a712156802b0e3d73694dd2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Fugaso/ef333de260477e313a712156802b0e3d73694dd2.png",
          type: "slots",
          provider: "Fugaso",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 121,
      name: "Gamebeat",
      providerId: 40,
      providerName: "Gamebeat",
      games: [
        {
          game_id: 4172,
          name: "Book Of Ancients",
          uuid: "d996ee8cad7fab56f466bbdd7b85c8b9e497921d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d996ee8cad7fab56f466bbdd7b85c8b9e497921d.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4173,
          name: "Book Of Ancients Mobile",
          uuid: "8d69aa53fb2b4554a76ae2c9de8ebc5cdfb327a5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8d69aa53fb2b4554a76ae2c9de8ebc5cdfb327a5.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4154,
          name: "Book of Elixir",
          uuid: "16e5fa172e954821a1a3bda5cdec9571",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/16e5fa172e954821a1a3bda5cdec9571.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4155,
          name: "Book of Elixir Mobile",
          uuid: "b182a765cf944176a51686a894adbf6d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/b182a765cf944176a51686a894adbf6d.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4156,
          name: "Book of Hor",
          uuid: "7cd49666962147c99263b09f07d1b6c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/7cd49666962147c99263b09f07d1b6c2.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4157,
          name: "Book of Hor Mobile",
          uuid: "41f640b3d89e209f0304ca44e8dae9b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/41f640b3d89e209f0304ca44e8dae9b7.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4182,
          name: "Buffalo Dale: GrandWays",
          uuid: "087e816a2780414fa90ca2686aabdfeb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/087e816a2780414fa90ca2686aabdfeb.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4183,
          name: "Buffalo Dale: GrandWays Mobile",
          uuid: "54543b9b5a324dd0b1a3dd7bd537bd36",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/54543b9b5a324dd0b1a3dd7bd537bd36.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4164,
          name: "Buffalo Trail",
          uuid: "3b3d48cc170e4a39b80bb8efabe84d86",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/3b3d48cc170e4a39b80bb8efabe84d86.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4165,
          name: "Buffalo Trail Mobile",
          uuid: "2b5fc2b51b45af987c9203b2703fa554",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/2b5fc2b51b45af987c9203b2703fa554.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4206,
          name: "CapyPark",
          uuid: "ace2af1520d1419cbd1780150b5bae62",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/ace2af1520d1419cbd1780150b5bae62.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4207,
          name: "CapyPark Mobile",
          uuid: "3d202b5e7b064d00bc895b9c834bf304",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/3d202b5e7b064d00bc895b9c834bf304.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4162,
          name: "ConquestEra",
          uuid: "e43333f86588467ba6af8a1d8622f97c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e43333f86588467ba6af8a1d8622f97c.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4163,
          name: "ConquestEra Mobile",
          uuid: "93ae997ae4e185fcce9ba73ce6c8bff9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/93ae997ae4e185fcce9ba73ce6c8bff9.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4148,
          name: "Dangerous Monster",
          uuid: "799dc84948a34d509952e3f0a8f00650",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/799dc84948a34d509952e3f0a8f00650.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4149,
          name: "Dangerous Monster Mobile",
          uuid: "ceaba83b1343506617e69bd5e8b0a657",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ceaba83b1343506617e69bd5e8b0a657.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4160,
          name: "Fortune Five",
          uuid: "e0e98562d5544ad09768c1dd01ada9cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/e0e98562d5544ad09768c1dd01ada9cc.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4174,
          name: "Fortune Five Double",
          uuid: "1c44248cb23fb90e8fd00eba12a7b639ad7d9e99",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/1c44248cb23fb90e8fd00eba12a7b639ad7d9e99.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4175,
          name: "Fortune Five Double Mobile",
          uuid: "44c47fc9ba3f4a98c966181f4f3c2bd8ae9a1529",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/44c47fc9ba3f4a98c966181f4f3c2bd8ae9a1529.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4161,
          name: "Fortune Five Mobile",
          uuid: "05d4ca83c864f5a43e8b87c442e5e1c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/05d4ca83c864f5a43e8b87c442e5e1c6.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4158,
          name: "Fortune Three",
          uuid: "3ca63b0fad9c457998a8fe068a1382a7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/3ca63b0fad9c457998a8fe068a1382a7.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4159,
          name: "Fortune Three Mobile",
          uuid: "79b188c9433254f4d7e1bc3250f0e96f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/79b188c9433254f4d7e1bc3250f0e96f.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4166,
          name: "Fortune Three Xmas",
          uuid: "0551d093378383d19a0de3a60dbd7198745acd2d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0551d093378383d19a0de3a60dbd7198745acd2d.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4167,
          name: "Fortune Three Xmas Mobile",
          uuid: "c8c50f6819891247af6e9762b90717d1f2562fdb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c8c50f6819891247af6e9762b90717d1f2562fdb.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4190,
          name: "Frog Space Program",
          uuid: "ced0ec4b5d09b93dfc2aa746033f3aef77d2efbe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/ced0ec4b5d09b93dfc2aa746033f3aef77d2efbe.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4191,
          name: "Frog Space Program Mobile",
          uuid: "5a8f48d6f5b3c0814149fabf5589981e69ee0fe5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/5a8f48d6f5b3c0814149fabf5589981e69ee0fe5.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4194,
          name: "Fruitfellas Fortune Wheel",
          uuid: "a630dc131e12c8a2db763809a7cf17a37a0ac189",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/a630dc131e12c8a2db763809a7cf17a37a0ac189.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4195,
          name: "Fruitfellas Fortune Wheel Mobile",
          uuid: "012459884fa2cf6e78a67dd005068a36158538db",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/012459884fa2cf6e78a67dd005068a36158538db.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4198,
          name: "Fruitfellas Xmas Wheel",
          uuid: "0745f3338eab472396c58d2f68234243",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/0745f3338eab472396c58d2f68234243.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4199,
          name: "Fruitfellas Xmas Wheel Mobile",
          uuid: "a55001f775ce44a5a548bb652467f7dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/a55001f775ce44a5a548bb652467f7dd.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4200,
          name: "Giggly Greedy Story",
          uuid: "c2c8d74c587c41cebda528c9c6bb3da9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/c2c8d74c587c41cebda528c9c6bb3da9.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4201,
          name: "Giggly Greedy Story Mobile",
          uuid: "ce34a8e8301c4a28832a24841066cba3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/ce34a8e8301c4a28832a24841066cba3.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4186,
          name: "Juicy Do Five",
          uuid: "543b5218368445ec8c404daa500f84f1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/543b5218368445ec8c404daa500f84f1.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4187,
          name: "Juicy Do Five Mobile",
          uuid: "feab4abeef7f4ecdba6509a4832709dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/feab4abeef7f4ecdba6509a4832709dc.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4188,
          name: "Juicy Do Three",
          uuid: "d2998f37625f4e81952ea38e054cf53e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/d2998f37625f4e81952ea38e054cf53e.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4189,
          name: "Juicy Do Three Mobile",
          uuid: "beefe6d837d145368709ed61609d0cdc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/beefe6d837d145368709ed61609d0cdc.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4208,
          name: "Kawaii cafe",
          uuid: "0fc9579792c146e79e1f48f6467b269e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/0fc9579792c146e79e1f48f6467b269e.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4209,
          name: "Kawaii café Mobile",
          uuid: "afdb5c365a58492d9b9c42116990b41c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/afdb5c365a58492d9b9c42116990b41c.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4176,
          name: "Max Miner",
          uuid: "d07c8e23dc83f461020ad5a0d97a5c391147576a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d07c8e23dc83f461020ad5a0d97a5c391147576a.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4177,
          name: "Max Miner Mobile",
          uuid: "64957e9e198833ea9b2d18aae6d2c57687249ffc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/64957e9e198833ea9b2d18aae6d2c57687249ffc.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4180,
          name: "Odin's Tree",
          uuid: "b034c61fbe891460c18dfb32b5bdc9c6128eca33",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b034c61fbe891460c18dfb32b5bdc9c6128eca33.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4181,
          name: "Odin's Tree Mobile",
          uuid: "2110aae64536b4407c22a7661e26c820a38d2fab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2110aae64536b4407c22a7661e26c820a38d2fab.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4152,
          name: "Raging Lion",
          uuid: "7f0d78a7dcd945df81b68d2c2e86da71",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/7f0d78a7dcd945df81b68d2c2e86da71.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4153,
          name: "Raging Lion Mobile",
          uuid: "e32402a2b6e1aeb1bb542f8b16634b8f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/e32402a2b6e1aeb1bb542f8b16634b8f.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4178,
          name: "Sea Secret",
          uuid: "34148f6d7a7fb0074f834dc59a9cd2ef3e979d32",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/34148f6d7a7fb0074f834dc59a9cd2ef3e979d32.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4179,
          name: "Sea Secret Mobile",
          uuid: "9df099a1cb76aa744089af9e7b3889407b2c8a57",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9df099a1cb76aa744089af9e7b3889407b2c8a57.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4205,
          name: "Slotout",
          uuid: "ee80f87274d62080a3df3beb9fe0b0b6d75eaa72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/ee80f87274d62080a3df3beb9fe0b0b6d75eaa72.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4204,
          name: "Slotout Mobile",
          uuid: "ee8b87360f4d4c4754c1756f687a3baffd1ac9c3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/ee8b87360f4d4c4754c1756f687a3baffd1ac9c3.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4212,
          name: "Spin 4 Dead",
          uuid: "a0bf35038be9439faed989a818457c43",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/a0bf35038be9439faed989a818457c43.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4213,
          name: "Spin 4 Dead Mobile",
          uuid: "832304003bbb4fd78138f926611884c8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/832304003bbb4fd78138f926611884c8.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4192,
          name: "STOLBIK 777",
          uuid: "7a0e1acaaa8c6fe49e542a3a15d6cea0966f32d1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/7a0e1acaaa8c6fe49e542a3a15d6cea0966f32d1.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4193,
          name: "STOLBIK 777 Mobile",
          uuid: "ff6869b6259f8a67b7e3c91bdd21d92602563c04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/ff6869b6259f8a67b7e3c91bdd21d92602563c04.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4150,
          name: "Throne of Camelot",
          uuid: "b9be704dc44a4520b6a0f9b85a305507",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b9be704dc44a4520b6a0f9b85a305507.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4151,
          name: "Throne of Camelot Mobile",
          uuid: "1914f3bddf085a6947ed5d5061e15535",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1914f3bddf085a6947ed5d5061e15535.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4203,
          name: "Totem de Oro",
          uuid: "cb3ed728f7534619b4c33f9364283cdf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/cb3ed728f7534619b4c33f9364283cdf.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4202,
          name: "Totem de Oro Mobile",
          uuid: "25addd83616b4d59967a499a9837a140",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/25addd83616b4d59967a499a9837a140.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4168,
          name: "Triton’s Realm",
          uuid: "b512888097e61dff7897a16816204a1c3b64dfc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/b512888097e61dff7897a16816204a1c3b64dfc8.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4169,
          name: "Triton’s Realm Mobile",
          uuid: "41deccf7e1d2ae46d696383b3ad7d5f47dae3862",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/41deccf7e1d2ae46d696383b3ad7d5f47dae3862.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4210,
          name: "WildStock",
          uuid: "6bccca52d3d54dceb2710d821c801361",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/6bccca52d3d54dceb2710d821c801361.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4211,
          name: "WildStock Mobile",
          uuid: "844aa9e1fac1416fa7c650ac3ab5eeb7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/844aa9e1fac1416fa7c650ac3ab5eeb7.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4170,
          name: "Witch Treasures",
          uuid: "5cd4b30497f47697ab1bd8bc4cd49c39786f0b03",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5cd4b30497f47697ab1bd8bc4cd49c39786f0b03.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4171,
          name: "Witch Treasures Mobile",
          uuid: "a20c1b243622d19dd7b509d710f7c6952321ad2f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a20c1b243622d19dd7b509d710f7c6952321ad2f.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4196,
          name: "Wolf of Wild Street",
          uuid: "e556ae7919264f759e0fcbb3027d164a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/e556ae7919264f759e0fcbb3027d164a.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4197,
          name: "Wolf of Wild Street Mobile",
          uuid: "88d6b6c25647408bbb74394c1475b482",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/88d6b6c25647408bbb74394c1475b482.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4184,
          name: "Xiao's Treasures",
          uuid: "c16d13d6039740cd8dead7e024a88b85",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/c16d13d6039740cd8dead7e024a88b85.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4185,
          name: "Xiao's Treasures Mobile",
          uuid: "d13ad120e3434725aced4cf36ea67e10",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Gamebeat/d13ad120e3434725aced4cf36ea67e10.png",
          type: "slots",
          provider: "Gamebeat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 122,
      name: "Greentube",
      providerId: 1,
      providerName: "Greentube",
      games: [
        {
          game_id: 288,
          name: "Action Joker",
          uuid: "cb8a9babc88382729e4f12e6f057d772258047df",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cb8a9babc88382729e4f12e6f057d772258047df.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 266,
          name: "African Simba",
          uuid: "88b0692ecea987a05b7096754e42f0a14b395c50",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/88b0692ecea987a05b7096754e42f0a14b395c50.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 280,
          name: 'Almighty Reels: "Garden of Persephone"',
          uuid: "bc834b28631dbb491be3b2492dc0696a49a4521b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bc834b28631dbb491be3b2492dc0696a49a4521b.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 282,
          name: 'Almighty Reels: "Realm of Poseidon"',
          uuid: "c23520b4414dcb29cf816d838174f7be29722d81",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c23520b4414dcb29cf816d838174f7be29722d81.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 25,
          name: "Always Hot Cubes",
          uuid: "68a3f676ac76cda89a2113f58802fe3db18cdb28",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/68a3f676ac76cda89a2113f58802fe3db18cdb28.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 14,
          name: "Always Hot Deluxe",
          uuid: "dd416dd20f5ac16f554cda1b8c572bd171c86a7c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/dd416dd20f5ac16f554cda1b8c572bd171c86a7c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 267,
          name: "Amazons Diamonds",
          uuid: "4b783adeedf9d65fdd02ad7dfb60c91c14c51b3f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4b783adeedf9d65fdd02ad7dfb60c91c14c51b3f.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 268,
          name: "Ancient Goddess",
          uuid: "00f70a97ae2419070da2ff5999a211d9a098d4e0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/00f70a97ae2419070da2ff5999a211d9a098d4e0.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 363,
          name: "Apollo God Of The Sun",
          uuid: "8fd43ce5d0f11a3f67a2a6cc9c04f04c0d3e8b4f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8fd43ce5d0f11a3f67a2a6cc9c04f04c0d3e8b4f.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 287,
          name: "Autumn Queen",
          uuid: "7e3cb7add18a4550d95c716d1ca10d68705d0a59",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7e3cb7add18a4550d95c716d1ca10d68705d0a59.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 194,
          name: "Bars and Sevens",
          uuid: "f190a15c1dfb9d48823e116f2cf6b3363beaf432",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f190a15c1dfb9d48823e116f2cf6b3363beaf432.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 161,
          name: "Beetle Mania Deluxe",
          uuid: "99952b6e607fe32b15ca3ba5cceca06c17595906",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/99952b6e607fe32b15ca3ba5cceca06c17595906.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 59,
          name: "Book Of Fate",
          uuid: "b70061ea67934b68c125dba0f218ecf3bd8c3900",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b70061ea67934b68c125dba0f218ecf3bd8c3900.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 56,
          name: "Book Of Ra",
          uuid: "b7019ffb9b1f4579a0ca79f11c9ec90ea1d999eb",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b7019ffb9b1f4579a0ca79f11c9ec90ea1d999eb.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 836,
          name: "Book of Ra 10 Deluxe",
          uuid: "6236262d6f3bb6c847dd63d77bc2c45104eb3d98",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6236262d6f3bb6c847dd63d77bc2c45104eb3d98.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 835,
          name: "Book of Ra 10 Deluxe [Mobile]",
          uuid: "5f2d90faf98551276458a06767f3336019834c32",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5f2d90faf98551276458a06767f3336019834c32.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 55,
          name: "Book Of Ra Deluxe",
          uuid: "23ca989db9ca5ad94dbca17fa54a123f3f7efc9d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/23ca989db9ca5ad94dbca17fa54a123f3f7efc9d.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 57,
          name: "Book of Ra Deluxe 6",
          uuid: "e4e7cdaf74c7bdbb902f7db9c48e46d80763387c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e4e7cdaf74c7bdbb902f7db9c48e46d80763387c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 265,
          name: "Book of Ra Dice",
          uuid: "4405edf26e6d282b4f862180d88c2f64414e09ce",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4405edf26e6d282b4f862180d88c2f64414e09ce.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 60,
          name: "Book of Ra Magic",
          uuid: "05c280a3df41996be9283ee0668d6c1cb8070fec",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/05c280a3df41996be9283ee0668d6c1cb8070fec.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 58,
          name: "Book of Stars",
          uuid: "b0b2996bf689cdcd1c73665eb63be66f00ef23ef",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b0b2996bf689cdcd1c73665eb63be66f00ef23ef.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 269,
          name: "Captain Venture",
          uuid: "79dc87adc807e74c15cadef936ba993e19527e52",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/79dc87adc807e74c15cadef936ba993e19527e52.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 221,
          name: "Cash Runner",
          uuid: "c3e21daa0b74f1e760412a0eb3793047d2dfb440",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c3e21daa0b74f1e760412a0eb3793047d2dfb440.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 286,
          name: "Changing Fate 40",
          uuid: "c5439221e5123563df528a8e328c066d1307cd1f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c5439221e5123563df528a8e328c066d1307cd1f.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 195,
          name: "Chicago",
          uuid: "0554a88cae8624a735492fb1a454d21f9aaa1f0b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0554a88cae8624a735492fb1a454d21f9aaa1f0b.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 270,
          name: "Cindereela",
          uuid: "7a7ef9a5ab7d5389b6996943512b0086a1cd2b64",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7a7ef9a5ab7d5389b6996943512b0086a1cd2b64.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 222,
          name: "Coin Of Apollo",
          uuid: "47846c21f55845fd08564ac1172b9c1afbdf7c94",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/47846c21f55845fd08564ac1172b9c1afbdf7c94.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 162,
          name: "Columbus Deluxe",
          uuid: "d9793225e6e19ec68aa690d691a7ca1840118a0c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d9793225e6e19ec68aa690d691a7ca1840118a0c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 196,
          name: "Dazzling Diamonds",
          uuid: "f25fcc13b3dce9eb12c1993e99b9d05fb5be1496",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f25fcc13b3dce9eb12c1993e99b9d05fb5be1496.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 337,
          name: "Deuces Wilde",
          uuid: "9e07302c1dba27c7f312ea0ffa731a9ac5602b04",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9e07302c1dba27c7f312ea0ffa731a9ac5602b04.png",
          type: "poker",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 15,
          name: "Dolphins' Pearl",
          uuid: "a8e77ed3fa064bda3fd4974ecfc393e92d1b3574",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a8e77ed3fa064bda3fd4974ecfc393e92d1b3574.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 16,
          name: "Dolphins' Pearl Deluxe",
          uuid: "f7287c153d4de55e8eaec2c2246753729e87e4d1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f7287c153d4de55e8eaec2c2246753729e87e4d1.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 271,
          name: "Dragon Warrior",
          uuid: "79d8b339d2a65aa64f008f29a952fcdbcec7dd7d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/79d8b339d2a65aa64f008f29a952fcdbcec7dd7d.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 216,
          name: "Dragons Deep",
          uuid: "30dae8835a8a67cf444a3a393a08bdd55482cde4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/30dae8835a8a67cf444a3a393a08bdd55482cde4.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 190,
          name: "Dynasty of Ra",
          uuid: "f5960a53ba3de6acd2c700cea65382fbfff7c5e4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f5960a53ba3de6acd2c700cea65382fbfff7c5e4.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 223,
          name: "Empire V",
          uuid: "3550e31cc347aa2ce9849f1cfc9f07e393448fe2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3550e31cc347aa2ce9849f1cfc9f07e393448fe2.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 219,
          name: "Eye Of The Dragon",
          uuid: "ac3498a5452b757853d62b01b8e6d68be4455841",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ac3498a5452b757853d62b01b8e6d68be4455841.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 272,
          name: "Fairy Queen",
          uuid: "6def6e210d014022f9b1ce1749018a79fa939f30",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6def6e210d014022f9b1ce1749018a79fa939f30.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 17,
          name: "Faust",
          uuid: "de2ef7608a3110bd9b41575e769cc77ef2653399",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/de2ef7608a3110bd9b41575e769cc77ef2653399.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 273,
          name: "Feather Frenzy",
          uuid: "e8b316448ec3af8a370767ea23e7ca6b64065746",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e8b316448ec3af8a370767ea23e7ca6b64065746.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 274,
          name: "Flame Dancer",
          uuid: "c1eaf15b47e08c3374b0ac3305437d696b7aed24",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c1eaf15b47e08c3374b0ac3305437d696b7aed24.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 191,
          name: "Flamenco Roses",
          uuid: "a24fd9ee18b51ac0c4e609c6210c182867e70c74",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a24fd9ee18b51ac0c4e609c6210c182867e70c74.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 360,
          name: "Freibier",
          uuid: "76c6904515550dab7623cea6a841f4a83f0cf73e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/76c6904515550dab7623cea6a841f4a83f0cf73e.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 218,
          name: "Fruit Farm",
          uuid: "878a47d8b0e01b0cf066fc804e805d101b3a3ad0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/878a47d8b0e01b0cf066fc804e805d101b3a3ad0.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 27,
          name: "Fruit Sensation",
          uuid: "89dcfe43a473f60d15f1d1fc5709843e31858ca9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/89dcfe43a473f60d15f1d1fc5709843e31858ca9.jpeg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 275,
          name: "Fruitilicious",
          uuid: "a438e8881205921c610ccd447860b9411ce5dd06",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a438e8881205921c610ccd447860b9411ce5dd06.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 28,
          name: "Fruits'n Royals",
          uuid: "7c036bf9d632e0c9f0ef16d53a90319a5f266d8a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7c036bf9d632e0c9f0ef16d53a90319a5f266d8a.jpeg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 263,
          name: "Gangster Paradise",
          uuid: "e05ed1a4b03ea15dab5c4b4b7c50329167c443e6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e05ed1a4b03ea15dab5c4b4b7c50329167c443e6.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 18,
          name: "Golden Ark",
          uuid: "f505223df7f8e62673c893a26eadb28131bbca3a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f505223df7f8e62673c893a26eadb28131bbca3a.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 197,
          name: "Golden Cobras Deluxe",
          uuid: "93a4a61cb9a167268cf7b0af284271e51b537d80",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/93a4a61cb9a167268cf7b0af284271e51b537d80.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 276,
          name: "Gorilla",
          uuid: "718e380cb7a107e5a7ac6f47a6e31353cffa322e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/718e380cb7a107e5a7ac6f47a6e31353cffa322e.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 277,
          name: "Grand slam casino",
          uuid: "c192d9d933653e0e4a52cd96a321d18964adbd95",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c192d9d933653e0e4a52cd96a321d18964adbd95.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 26,
          name: "Gryphons' Gold Deluxe",
          uuid: "e6601195efc82f389ecd755ddefcffce6da483d6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e6601195efc82f389ecd755ddefcffce6da483d6.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 32,
          name: "Helena",
          uuid: "79469230a2a71b3bc64110ee7adb3c70f1989449",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/79469230a2a71b3bc64110ee7adb3c70f1989449.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 126,
          name: "Hot Chance",
          uuid: "aecc90d2cd1fd3abc84327434d4a8de6710c6863",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/aecc90d2cd1fd3abc84327434d4a8de6710c6863.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 289,
          name: "Hot Cubes",
          uuid: "d2b4bc63ea9a882a56defab624d99ed7f1d54497",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d2b4bc63ea9a882a56defab624d99ed7f1d54497.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 31,
          name: "Indian Spirit",
          uuid: "72c255efa5e1d9ce0756e18331842b697ea4f713",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/72c255efa5e1d9ce0756e18331842b697ea4f713.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 278,
          name: "Island Heat",
          uuid: "652917d9d13501b4be58b352a95776ff15508e51",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/652917d9d13501b4be58b352a95776ff15508e51.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 338,
          name: "Jacks Or Better",
          uuid: "c792300ffcbc4789e1f9e45a5deaa7127bb60780",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/a9e556675295b7c72362861203812dd3/Greentube/c792300ffcbc4789e1f9e45a5deaa7127bb60780.png",
          type: "poker",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 140,
          name: "Joker Wild",
          uuid: "c703de366afbcbc44102b9ebe8449f817c7d5f54",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/a9e556675295b7c72362861203812dd3/Greentube/c703de366afbcbc44102b9ebe8449f817c7d5f54.png",
          type: "poker",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 163,
          name: "Just Jewels Deluxe",
          uuid: "c592803bf830ac8b273880301b41d587dc08aa3d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c592803bf830ac8b273880301b41d587dc08aa3d.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 19,
          name: "Katana",
          uuid: "941bfed586a89b8bce0af5f4457a247617a6c932",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/941bfed586a89b8bce0af5f4457a247617a6c932.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 198,
          name: "King of Cards",
          uuid: "a5420d5302c9cca5cecae053732fba2c45cc5d1c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a5420d5302c9cca5cecae053732fba2c45cc5d1c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 361,
          name: "Kingdom Of Legends",
          uuid: "dc468941f45a700c930c83d1f08c943cdbaf0c4b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/dc468941f45a700c930c83d1f08c943cdbaf0c4b.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 199,
          name: "Leonardo's Code",
          uuid: "ca9191e4169adf8b732e31eb2a5105f775a15d61",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ca9191e4169adf8b732e31eb2a5105f775a15d61.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 165,
          name: "Lord Of The Ocean",
          uuid: "7be298e0101c8f246ffe0eb958a2a7ab861d31d7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7be298e0101c8f246ffe0eb958a2a7ab861d31d7.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 164,
          name: "Lucky Lady's Charm Deluxe",
          uuid: "b37dd1f8ef878128094f900be2b9002d93bda2c9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b37dd1f8ef878128094f900be2b9002d93bda2c9.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 362,
          name: "Lucky Lady's Charm Deluxe 10",
          uuid: "204cafd239d57808f86dc7d0f23454201ecb7fa0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/204cafd239d57808f86dc7d0f23454201ecb7fa0.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 215,
          name: "Lucky Ladys Charm Deluxe 6",
          uuid: "855ef3995e10ace8f26081a525ef0aa931b8aa7f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/855ef3995e10ace8f26081a525ef0aa931b8aa7f.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 130,
          name: "Magic 27",
          uuid: "b50287eb8bc3ed3e1a4959b2a56660fa049db369",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b50287eb8bc3ed3e1a4959b2a56660fa049db369.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 291,
          name: "Magic 81",
          uuid: "07836517d898a76c9a28723a35b75e21232dde8c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/07836517d898a76c9a28723a35b75e21232dde8c.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 290,
          name: "Mayan Moons",
          uuid: "f2be833f1d1f67b0a8b7685111a7789d06ef8272",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f2be833f1d1f67b0a8b7685111a7789d06ef8272.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 33,
          name: "Mega Joker",
          uuid: "2dc5dc94ce6c23ce80f146affa26bba5fb4536a8",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2dc5dc94ce6c23ce80f146affa26bba5fb4536a8.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 220,
          name: "Music Island",
          uuid: "8b2d41554724614c0a5fbc6e9359e37b56786bac",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8b2d41554724614c0a5fbc6e9359e37b56786bac.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 20,
          name: "Mystic Secrets",
          uuid: "bcb1dbafe39aaf444a24f15eebf177eaf0537c09",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bcb1dbafe39aaf444a24f15eebf177eaf0537c09.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 279,
          name: "Orca",
          uuid: "f4c29b1f9edb129c0a8bde626d6d412d64a9d1f2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f4c29b1f9edb129c0a8bde626d6d412d64a9d1f2.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 192,
          name: "Pharaohs Ring",
          uuid: "708d86a2c129ce5aaef41eb3ef2d1f28abb89902",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/708d86a2c129ce5aaef41eb3ef2d1f28abb89902.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 281,
          name: "Pharaohs Tomb",
          uuid: "1ca1561250b80d6f96131e30e66362fa52561902",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1ca1561250b80d6f96131e30e66362fa52561902.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 200,
          name: "Plenty of Fruit 20",
          uuid: "beba5843acc38c1b22dc7f85c10f1da115b68cb9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/beba5843acc38c1b22dc7f85c10f1da115b68cb9.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 213,
          name: "Plenty Of Fruit 20 Hot",
          uuid: "234ca50fc628e41ab75e56c01aaa31cb5ae9e8a3",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/234ca50fc628e41ab75e56c01aaa31cb5ae9e8a3.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 201,
          name: "Plenty of Fruit 40",
          uuid: "996b883906932df4577e247142749d1ae905ce31",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/996b883906932df4577e247142749d1ae905ce31.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 214,
          name: "Plenty Of Jewels 20 Hot",
          uuid: "de6a04e9d231c2ea6dba965a11bd8643e484160c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/de6a04e9d231c2ea6dba965a11bd8643e484160c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 30,
          name: "Plenty on Twenty",
          uuid: "ea1144127de78e90c8af400b8c644c00e49388f9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ea1144127de78e90c8af400b8c644c00e49388f9.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 212,
          name: "Plenty On Twenty II Hot",
          uuid: "0be71fab844eeff742c241a287af5d12fd50c34e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0be71fab844eeff742c241a287af5d12fd50c34e.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 193,
          name: "Power Stars",
          uuid: "4118ea3f37c8f11d60f314573860409cabf52d24",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4118ea3f37c8f11d60f314573860409cabf52d24.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 217,
          name: "Queen Cleopatra",
          uuid: "2fa92810c2e0849e722b92eec5e87112a6c84d83",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2fa92810c2e0849e722b92eec5e87112a6c84d83.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 166,
          name: "Queen Of Hearts",
          uuid: "74f290e053b1f6aa5daa3bc940ef7e4a16061b12",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/74f290e053b1f6aa5daa3bc940ef7e4a16061b12.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 21,
          name: "Red Lady",
          uuid: "8adcc1588a1057cee38bb404c888374e6979499b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8adcc1588a1057cee38bb404c888374e6979499b.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 202,
          name: "Reel King",
          uuid: "c3d7d347015d89fe66bad7ec9255f66f404f0898",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c3d7d347015d89fe66bad7ec9255f66f404f0898.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 29,
          name: "Rex",
          uuid: "d646b5ea39f59a0a6097261ac0edc3fd9ae7c792",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d646b5ea39f59a0a6097261ac0edc3fd9ae7c792.jpeg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 22,
          name: "Roaring Forties",
          uuid: "76cf2fbcc350239a56b8fd9a99381fadf05f129c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/76cf2fbcc350239a56b8fd9a99381fadf05f129c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 203,
          name: "Roaring Wilds",
          uuid: "24c4e30e1fd05d824793011c4e7f6b881e276223",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/24c4e30e1fd05d824793011c4e7f6b881e276223.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 359,
          name: "Royal Dynasty",
          uuid: "17662a158dc9a7aa6803aff10616343c548c458f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/17662a158dc9a7aa6803aff10616343c548c458f.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 283,
          name: "Royal Lotus",
          uuid: "2dc458a70c0b4c5205b93a739221f348f1ba3b2c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2dc458a70c0b4c5205b93a739221f348f1ba3b2c.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 262,
          name: "Sea Sirens (LLC)",
          uuid: "f8741facae241a49f1360b98ebb0c0a6a3cff92f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f8741facae241a49f1360b98ebb0c0a6a3cff92f.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 23,
          name: "Secret Elixir",
          uuid: "01ec16986feaa93a49b5343c97f7898055b4fa08",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/01ec16986feaa93a49b5343c97f7898055b4fa08.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 34,
          name: "Sevens",
          uuid: "690ef18bbae9b5e9d17a6edb306f5b31b4fe3d2e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/690ef18bbae9b5e9d17a6edb306f5b31b4fe3d2e.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 204,
          name: "Sharky",
          uuid: "4476405774a3c048d3cc4d3b83dc5774c5b3a6d1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4476405774a3c048d3cc4d3b83dc5774c5b3a6d1.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 205,
          name: "Shooting Stars",
          uuid: "0b11f97a8e5d52a21fd0de470e699fffdb9cbc91",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0b11f97a8e5d52a21fd0de470e699fffdb9cbc91.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 127,
          name: "Sizzling 6",
          uuid: "e355fffe8405129a5ef8b6a5e77ff83d0a118de4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e355fffe8405129a5ef8b6a5e77ff83d0a118de4.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 35,
          name: "Sizzling Gems",
          uuid: "270250f30cf3959189c9ce837ba1725c42c09873",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/270250f30cf3959189c9ce837ba1725c42c09873.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 125,
          name: "Sizzling Hot 6 Extra Gold",
          uuid: "85c1535bc203f3d5a7d746b47b7e84f8955299d0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/85c1535bc203f3d5a7d746b47b7e84f8955299d0.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 124,
          name: "Sizzling Hot Deluxe",
          uuid: "557af416d9831dccc0bd5fdfe47eaa41c60015ad",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/557af416d9831dccc0bd5fdfe47eaa41c60015ad.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 206,
          name: "Star Nova",
          uuid: "67e5014209183f93e5309b6935893c21f793a612",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/67e5014209183f93e5309b6935893c21f793a612.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 264,
          name: "Stein Haus",
          uuid: "8aa1a282b0def9fa69fe4ca918b0b714d8cd90d7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8aa1a282b0def9fa69fe4ca918b0b714d8cd90d7.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 129,
          name: "Supra Hot",
          uuid: "053082eac539723eefae50a5a5417afeee16148a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/053082eac539723eefae50a5a5417afeee16148a.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 365,
          name: "Tales of Darkness: Lunar Eclipse",
          uuid: "c440cceb495beeb0ea733c42296be315ea7202d0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c440cceb495beeb0ea733c42296be315ea7202d0.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 207,
          name: "The Real King Gold Records",
          uuid: "8386859402a54ce577364495498fedced152dfd0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8386859402a54ce577364495498fedced152dfd0.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 261,
          name: "The Real King™ Aloha Hawaii (LLC)",
          uuid: "174d66511afb3ca826cbd757768f4c69a96d6956",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/174d66511afb3ca826cbd757768f4c69a96d6956.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 284,
          name: "Totally Wild",
          uuid: "0e66fbaab204d75080283a067f34f7e0f02971ad",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0e66fbaab204d75080283a067f34f7e0f02971ad.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 24,
          name: "Ultra Hot Deluxe",
          uuid: "2cf9b4cca516307581e0a237971c69fc086304ca",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2cf9b4cca516307581e0a237971c69fc086304ca.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 364,
          name: "Viking Dragon",
          uuid: "cac364fd87110b771ef1e954d14982e3a009d16d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cac364fd87110b771ef1e954d14982e3a009d16d.png",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 285,
          name: "Wild Country",
          uuid: "ee56e70f4f82d6bc149e8814fa2f1fe829f14ae7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ee56e70f4f82d6bc149e8814fa2f1fe829f14ae7.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 208,
          name: "Xtra Hot",
          uuid: "609b1fb18daf01b95ca2876dd7bd384023f20a87",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/609b1fb18daf01b95ca2876dd7bd384023f20a87.jpg",
          type: "slots",
          provider: "Greentube",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 123,
      name: "HoGaming",
      providerId: 53,
      providerName: "HoGaming",
      games: [
        {
          game_id: 4222,
          name: "C1 Speed Baccarat",
          uuid: "7581867adce539b241063bb86258adb865154f0a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7581867adce539b241063bb86258adb865154f0a.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4223,
          name: "C2 Speed Baccarat",
          uuid: "a27fc52631c8a1479bceaf3fa6694b3707c628e1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a27fc52631c8a1479bceaf3fa6694b3707c628e1.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4224,
          name: "C3 Speed Baccarat",
          uuid: "7e11482f89bd1117cbfeb6b4fe86cd291375f16c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7e11482f89bd1117cbfeb6b4fe86cd291375f16c.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4226,
          name: "C4 Immersive Speed Baccarat",
          uuid: "849d4b97d90ef3ee1c79b235c933271bc7e0bc66",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/849d4b97d90ef3ee1c79b235c933271bc7e0bc66.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4227,
          name: "C5 Immersive Speed Baccarat",
          uuid: "92f92810c660566b3d707b7d6589ea30499d3481",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/92f92810c660566b3d707b7d6589ea30499d3481.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4217,
          name: "C6 Speed Baccarat",
          uuid: "2f8ccdc504264b3aba982e1ed4d488f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/2f8ccdc504264b3aba982e1ed4d488f7.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4228,
          name: "C8 Baccarat",
          uuid: "2a7c9c1447ec3381a54d3b40a7756b4751eac05a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2a7c9c1447ec3381a54d3b40a7756b4751eac05a.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4225,
          name: "C9 Baccarat",
          uuid: "3f7f7221223ef095679e017c60d90d416705efeb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3f7f7221223ef095679e017c60d90d416705efeb.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4234,
          name: "DT2 Dragon Tiger",
          uuid: "a3069435ab749712cc74bf7e07e233e03923bed8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/a3069435ab749712cc74bf7e07e233e03923bed8.png",
          type: "dragon tiger",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4216,
          name: "J1 Blackjack",
          uuid: "6be21d2f503a4524bb4cdbaa097b4f17",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/6be21d2f503a4524bb4cdbaa097b4f17.png",
          type: "blackjack",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4220,
          name: "J2 Blackjack",
          uuid: "a5d1b369edc82381f536b4f77a435858e8ae4046",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a5d1b369edc82381f536b4f77a435858e8ae4046.png",
          type: "blackjack",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4230,
          name: "N1 Baccarat",
          uuid: "f63334d0c008bee6fa7b4afb90e0945fb59a973c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/f63334d0c008bee6fa7b4afb90e0945fb59a973c.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4215,
          name: "N1 Blackjack",
          uuid: "b322b0c7f1df4298a00cbc5e2dfaf4be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/b322b0c7f1df4298a00cbc5e2dfaf4be.png",
          type: "blackjack",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4233,
          name: "N1 Speed Roulette",
          uuid: "2fdfa0b91267f2e0be2f32a1d582d4b8df5070b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/2fdfa0b91267f2e0be2f32a1d582d4b8df5070b2.png",
          type: "roulette",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4235,
          name: "N2 Blackjack",
          uuid: "06481c14b46a633a1e70204cf1e1fb56b1a5cc46",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/06481c14b46a633a1e70204cf1e1fb56b1a5cc46.png",
          type: "blackjack",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4231,
          name: "N2 Speed Baccarat",
          uuid: "8af15478e9adef9c4fc681e8c8117f31d7f44406",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/8af15478e9adef9c4fc681e8c8117f31d7f44406.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4232,
          name: "N3 Baccarat",
          uuid: "fe8ae880134c71a7f0f65a7003220bdcf6bfb515",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/fe8ae880134c71a7f0f65a7003220bdcf6bfb515.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4221,
          name: "N3 Blackjack",
          uuid: "812c0456cec629bb642053fbe83cae8c055dc3c6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/812c0456cec629bb642053fbe83cae8c055dc3c6.png",
          type: "blackjack",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4218,
          name: "N4 Immersive Baccarat",
          uuid: "c2ffed0b5fac43bd8b75bfc0cca93b7c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/c2ffed0b5fac43bd8b75bfc0cca93b7c.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4229,
          name: "N5 Speed Baccarat",
          uuid: "2d0e1c72be4dd3012fbf4013c302868a20aff930",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2d0e1c72be4dd3012fbf4013c302868a20aff930.png",
          type: "baccarat",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4214,
          name: "S1 Roulette",
          uuid: "24eaad8f641248ba95375d3459523290",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/HoGaming/24eaad8f641248ba95375d3459523290.png",
          type: "roulette",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4219,
          name: "S2 Roulette",
          uuid: "0a1ba6dcb218bedb8794f9092b9aa18a0c8c9f0a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0a1ba6dcb218bedb8794f9092b9aa18a0c8c9f0a.png",
          type: "roulette",
          provider: "HoGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 124,
      name: "HolleGames Standard",
      providerId: 61,
      providerName: "HolleGames Standard",
      games: [
        {
          game_id: 4257,
          name: "Bells",
          uuid: "d9975435df13713dff6f08ed7952be9b2b506a76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/d9975435df13713dff6f08ed7952be9b2b506a76.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4253,
          name: "Bells 10",
          uuid: "6d25e7532d5a4b04a5d5187bd4b01a80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/6d25e7532d5a4b04a5d5187bd4b01a80.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4252,
          name: "Bells XL",
          uuid: "b3b9a514ed1447cba1d79bad03cabd7c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/b3b9a514ed1447cba1d79bad03cabd7c.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4240,
          name: "Drunken Sailor",
          uuid: "c0dc867789c1482eb9cbf1e978930636",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/c0dc867789c1482eb9cbf1e978930636.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4243,
          name: "Eye of Nazar",
          uuid: "56bae9bc26fd4d84b6d6592f5e00962d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/56bae9bc26fd4d84b6d6592f5e00962d.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4256,
          name: "Fruits",
          uuid: "4b7af28186eba1e81e19f22d5c34f73910d9e5c1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/4b7af28186eba1e81e19f22d5c34f73910d9e5c1.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4255,
          name: "Fruits 20",
          uuid: "4c1b6cc9ac0b4cd6be2fd92692ede9ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/4c1b6cc9ac0b4cd6be2fd92692ede9ea.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4237,
          name: "Fruits 20 Deluxe",
          uuid: "40d3c5b45acf4817901c871f8fb004cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/40d3c5b45acf4817901c871f8fb004cc.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4244,
          name: "Fruits 6 DELUXE",
          uuid: "407556afb7554dad99837222b19ed80c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/407556afb7554dad99837222b19ed80c.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4254,
          name: "Fruits XL",
          uuid: "3b833dad7b63404cb28263d1f317e35b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/3b833dad7b63404cb28263d1f317e35b.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4249,
          name: "Höllische Sieben",
          uuid: "bfca6d3bffa749fe8b6a7a649544c992",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/bfca6d3bffa749fe8b6a7a649544c992.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4241,
          name: "Höllische Sieben (Hellish Seven) 100",
          uuid: "48128dc13f5f4e9d97249759ff12dc37",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/48128dc13f5f4e9d97249759ff12dc37.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4236,
          name: "Höllische Sieben (Hellish Seven) Multi 100",
          uuid: "01d02a77d6404fada7a5f33b1305def9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/01d02a77d6404fada7a5f33b1305def9.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4259,
          name: "Hollische Sieben (Hellish Seven) RESPIN",
          uuid: "cd04318a38144b9382cb00cb5f110cf9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/cd04318a38144b9382cb00cb5f110cf9.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4245,
          name: "Höllische Sieben DELUXE",
          uuid: "af6f9b4224df4689af895cb523a4d360",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/af6f9b4224df4689af895cb523a4d360.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4246,
          name: "Jolly Wild",
          uuid: "7007c545dffc46c79c2ed4a547fea53a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/7007c545dffc46c79c2ed4a547fea53a.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4238,
          name: "Multi Jolly Wild",
          uuid: "90068c6358434ae8be9a5c24b2fc2ac4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/90068c6358434ae8be9a5c24b2fc2ac4.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4251,
          name: "Ring the Wild Bell",
          uuid: "6ba06e31982e4a4c99ca77da3ef06742",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/6ba06e31982e4a4c99ca77da3ef06742.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4250,
          name: "Ring the Wild Bell XL",
          uuid: "c8863358d6a14423b4a9163620fe6522",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/c8863358d6a14423b4a9163620fe6522.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4258,
          name: "Roter Baron",
          uuid: "80cb33a08e6970e50aa78fd900c20ae5bc92c05a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/80cb33a08e6970e50aa78fd900c20ae5bc92c05a.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4247,
          name: "Super Gems (Steinreich)",
          uuid: "d1e044a0509b47828b640bc6e0bfb1ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/d1e044a0509b47828b640bc6e0bfb1ad.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4242,
          name: "Super Gems (Steinreich) XL",
          uuid: "1471f7b7492343c48f253153f613d401",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/1471f7b7492343c48f253153f613d401.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4239,
          name: "Triple Eggs 100",
          uuid: "f14a9472d4a748159114a1661711905f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/f14a9472d4a748159114a1661711905f.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4248,
          name: "Winter Bells",
          uuid: "161ddc92e0df464db8910027e7cc972a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1adf421e6eb186476f4e959e0a7270c2/HolleGames Standard/161ddc92e0df464db8910027e7cc972a.png",
          type: "slots",
          provider: "HolleGames Standard",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 125,
      name: "Igrosoft",
      providerId: 3,
      providerName: "Igrosoft",
      games: [
        {
          game_id: 1,
          name: "Crazy Monkey",
          uuid: "f0523e9ecde5ad8b11164fad48d4fe158acdee47",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f0523e9ecde5ad8b11164fad48d4fe158acdee47.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2,
          name: "Crazy Monkey 2",
          uuid: "3752692a14c19c1e73bcddb25f3fecd3742d7b58",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3752692a14c19c1e73bcddb25f3fecd3742d7b58.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 209,
          name: "Fruit Cocktail",
          uuid: "67894f8b7229ac27f2fecb00af254e75681d3090",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/67894f8b7229ac27f2fecb00af254e75681d3090.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 210,
          name: "Fruit Cocktail 2",
          uuid: "4283be45875772f6eaace1d2eeaca1c3d8a115f2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4283be45875772f6eaace1d2eeaca1c3d8a115f2.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 211,
          name: "Garage",
          uuid: "8e78dbfa1338a1a00d71aeeb660ac3bdc2a648cd",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8e78dbfa1338a1a00d71aeeb660ac3bdc2a648cd.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 13,
          name: "Gnome",
          uuid: "7fb98fe62dd21b5961a8370911d2a465207f0ca4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7fb98fe62dd21b5961a8370911d2a465207f0ca4.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 11,
          name: "Island",
          uuid: "28f65ac960d93b7d1ee570072cd8352938e079d6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/28f65ac960d93b7d1ee570072cd8352938e079d6.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 12,
          name: "Island 2",
          uuid: "213898fa027de5e27ba7d2767370e5d707c69390",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/213898fa027de5e27ba7d2767370e5d707c69390.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 10,
          name: "Keks",
          uuid: "602f21fa5d9f51beaccf663edfd1ded3afb1d6fd",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/602f21fa5d9f51beaccf663edfd1ded3afb1d6fd.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 9,
          name: "Lucky Haunter",
          uuid: "fe70f27c749c0e9e9e8eb61255838a3a836a8837",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/fe70f27c749c0e9e9e8eb61255838a3a836a8837.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7,
          name: "Pirate",
          uuid: "b0f20c5641b8fbf68aad9cf91054efc521aa422b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b0f20c5641b8fbf68aad9cf91054efc521aa422b.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8,
          name: "Pirate 2",
          uuid: "1e0353efced20ac46e2820fc2d51a65188a9499a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1e0353efced20ac46e2820fc2d51a65188a9499a.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 3,
          name: "Resident",
          uuid: "d2332b8b4e9bbccaf91eff385adbcba319c2a7cd",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d2332b8b4e9bbccaf91eff385adbcba319c2a7cd.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4,
          name: "Rock Climber",
          uuid: "ce46d073f6267e47c55bebcab4d46ba905c775f7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ce46d073f6267e47c55bebcab4d46ba905c775f7.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5,
          name: "Sweet Life",
          uuid: "83cb65f35f1eb670574779509464d51533ee01a4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/83cb65f35f1eb670574779509464d51533ee01a4.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6,
          name: "Sweet Life 2",
          uuid: "cbcda7f65f39e06cdc27bbe2d933fa53771ed1cf",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cbcda7f65f39e06cdc27bbe2d933fa53771ed1cf.jpg",
          type: "slots",
          provider: "Igrosoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 126,
      name: "KAGaming",
      providerId: 35,
      providerName: "KAGaming",
      games: [
        {
          game_id: 4315,
          name: "7 Heroines",
          uuid: "f4c51d9571164c60ac04f5358809eca9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f4c51d9571164c60ac04f5358809eca9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4314,
          name: "7 Heroines Mobile",
          uuid: "eebc44e10b5746c5adfad9a03bc5ffb5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/eebc44e10b5746c5adfad9a03bc5ffb5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5394,
          name: "777 Gems Fusion Reels",
          uuid: "d96354f044a144fca7a84b9e5b3a9deb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d96354f044a144fca7a84b9e5b3a9deb.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5395,
          name: "777 Gems Fusion Reels Mobile",
          uuid: "1cdb4f44dc3e4d06920c8b680ea12780",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1cdb4f44dc3e4d06920c8b680ea12780.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4433,
          name: "777 Vegas",
          uuid: "4c9c52aa1f1149aca2e07ab36703910e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4c9c52aa1f1149aca2e07ab36703910e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4432,
          name: "777 Vegas Mobile",
          uuid: "ddc560b77ea9457fae0ebd9b44f97dc2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ddc560b77ea9457fae0ebd9b44f97dc2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4657,
          name: "A Girl's Best Friend",
          uuid: "464332d4a7fc4fa68ace923ec23bf45e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/464332d4a7fc4fa68ace923ec23bf45e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4656,
          name: "A Girl's Best Friend Mobile",
          uuid: "93dcfee1e88e40fb90d597bb1a012c9c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/93dcfee1e88e40fb90d597bb1a012c9c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4537,
          name: "A Thirsty Crow",
          uuid: "23f06a32db0f4f84b425237ed7af92b9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/23f06a32db0f4f84b425237ed7af92b9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4536,
          name: "A Thirsty Crow Mobile",
          uuid: "234eef04ad4347ea807253f4ddc01abb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/234eef04ad4347ea807253f4ddc01abb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5194,
          name: "Aces And Eights",
          uuid: "90156c1c002f4ec4a91e6cdade16ac22",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/90156c1c002f4ec4a91e6cdade16ac22.png",
          type: "table",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5195,
          name: "Aces And Eights Mobile",
          uuid: "d76f36748cfc40b49101b31ed4612975",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d76f36748cfc40b49101b31ed4612975.png",
          type: "table",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5258,
          name: "Acrobats",
          uuid: "ab5b4281a4fa402ca6a74465e55138f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ab5b4281a4fa402ca6a74465e55138f6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5259,
          name: "Acrobats Mobile",
          uuid: "06376e5f8ce3431caa09635caedab38e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/06376e5f8ce3431caa09635caedab38e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4623,
          name: "Africa Run",
          uuid: "796d2f84847e435f94e402af6a2cdc5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/796d2f84847e435f94e402af6a2cdc5c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4622,
          name: "Africa Run Mobile",
          uuid: "72c71f06b33f4c4780f254b6aefaa8bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/72c71f06b33f4c4780f254b6aefaa8bf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4661,
          name: "Age of Vikings",
          uuid: "1bc12b38f3ad4e76b9f33a309c41b4ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1bc12b38f3ad4e76b9f33a309c41b4ad.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4660,
          name: "Age of Vikings Mobile",
          uuid: "26d9e4368cbb4523880be4492df7104c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/26d9e4368cbb4523880be4492df7104c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4894,
          name: "Agent Angels",
          uuid: "5aef7a935dfd6da9ecffc992d4af5ee07afeca20",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5aef7a935dfd6da9ecffc992d4af5ee07afeca20.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4895,
          name: "Agent Angels Mobile",
          uuid: "8044bb76ecc03a675435f59ab5dfc5444ec56862",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8044bb76ecc03a675435f59ab5dfc5444ec56862.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4267,
          name: "AirCombat 1942",
          uuid: "709d80f13d4b4d3d9a4955ffbdf5d1c7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/709d80f13d4b4d3d9a4955ffbdf5d1c7.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4266,
          name: "AirCombat 1942 Mobile",
          uuid: "6bb069383c894c31984c51c95e6e036a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6bb069383c894c31984c51c95e6e036a.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4301,
          name: "Aladdin",
          uuid: "81a34cdbabe04cc7a0794b1e5ac0d480",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/81a34cdbabe04cc7a0794b1e5ac0d480.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4300,
          name: "Aladdin Mobile",
          uuid: "f6df6be79d004a15840bd1a2f620f41c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f6df6be79d004a15840bd1a2f620f41c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4361,
          name: "Alexander the Great",
          uuid: "9b562e67150248ac8df698611c6c19c7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9b562e67150248ac8df698611c6c19c7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4360,
          name: "Alexander the Great Mobile",
          uuid: "dfc3db76574f4d1faffec06d6db0c89b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/dfc3db76574f4d1faffec06d6db0c89b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5144,
          name: "Alice In MegaLand",
          uuid: "225cf43bfdf84db9be8bc198ad5baf2f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/225cf43bfdf84db9be8bc198ad5baf2f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5145,
          name: "Alice In MegaLand Mobile",
          uuid: "60cf3077a2f348f3b06c9e8df18f8c50",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/60cf3077a2f348f3b06c9e8df18f8c50.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4757,
          name: "Alice In Wonderland",
          uuid: "5f815cddde514b4d95a6327ca4b16f89",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5f815cddde514b4d95a6327ca4b16f89.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4756,
          name: "Alice In Wonderland Mobile",
          uuid: "f3108e9347144f8d9adedd7335cd8252",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f3108e9347144f8d9adedd7335cd8252.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5328,
          name: "Alien Invasion",
          uuid: "44ce82ac175f4feab640a32d7f003a46",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/44ce82ac175f4feab640a32d7f003a46.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5329,
          name: "Alien Invasion Mobile",
          uuid: "05e6d029706748aead6720dec017251c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/05e6d029706748aead6720dec017251c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5572,
          name: "Alien Tour",
          uuid: "067a718073d240afbe3b643c77a091b1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/067a718073d240afbe3b643c77a091b1.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5573,
          name: "Alien Tour Mobile",
          uuid: "e0fcd1bfd0b24593964e0cd96fd5c0f3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e0fcd1bfd0b24593964e0cd96fd5c0f3.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4874,
          name: "American Burger",
          uuid: "031ae9a6b15bc67ba7ccdef81b07afd4cbff4ab0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/031ae9a6b15bc67ba7ccdef81b07afd4cbff4ab0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4875,
          name: "American Burger Mobile",
          uuid: "e6606a7eef538a24d80c559395fd1016c506e977",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e6606a7eef538a24d80c559395fd1016c506e977.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4351,
          name: "Angry Piggies",
          uuid: "499a8df93d5f48bb8ed97c42dad14bb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/499a8df93d5f48bb8ed97c42dad14bb3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4350,
          name: "Angry Piggies Mobile",
          uuid: "f9f91e92c8544831a18bc46c14cf7f3c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f9f91e92c8544831a18bc46c14cf7f3c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5024,
          name: "Animal Dojo",
          uuid: "6b10cae4bec4a9888b9e5131f06948a7640f3c20",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6b10cae4bec4a9888b9e5131f06948a7640f3c20.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5025,
          name: "Animal Dojo Mobile",
          uuid: "469617223339ad15d2b479a83ba436fa3abc6a66",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/469617223339ad15d2b479a83ba436fa3abc6a66.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4273,
          name: "Animal Fishing",
          uuid: "e510c0efe0004749881eff5d9ea45a62",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e510c0efe0004749881eff5d9ea45a62.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4272,
          name: "Animal Fishing Mobile",
          uuid: "7843f670412b4d109a9b24f131e2dac2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7843f670412b4d109a9b24f131e2dac2.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5210,
          name: "Animal Land",
          uuid: "166ddfb28c594ac09c9b0d32e10ba336",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/166ddfb28c594ac09c9b0d32e10ba336.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5211,
          name: "Animal Land Mobile",
          uuid: "f1ee28862d0e4ac9a0359acbff8b2403",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f1ee28862d0e4ac9a0359acbff8b2403.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4964,
          name: "Apollo And Artemis",
          uuid: "037a7b763d80a02bb51dda126a16cd340f211b72",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/037a7b763d80a02bb51dda126a16cd340f211b72.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4965,
          name: "Apollo And Artemis Mobile",
          uuid: "7c9c2f9700b89d3e849b810a9af033d0b7fe5554",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7c9c2f9700b89d3e849b810a9af033d0b7fe5554.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5632,
          name: "Aquatic Adventurer",
          uuid: "22ac94b4cc574064ac34bdca6f28bbcb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/22ac94b4cc574064ac34bdca6f28bbcb.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5633,
          name: "Aquatic Adventurer Mobile",
          uuid: "352b73dea3cf48fb9147a691a575079b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/352b73dea3cf48fb9147a691a575079b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4495,
          name: "Archer Robin Hood",
          uuid: "6700fe76fc04428ea77a6ebfd16886ac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6700fe76fc04428ea77a6ebfd16886ac.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4494,
          name: "Archer Robin Hood Mobile",
          uuid: "d8de8f979e924850bcedbecd584cb2fd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d8de8f979e924850bcedbecd584cb2fd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4826,
          name: "Arctic Storm",
          uuid: "17f3d0365b3f49a398a9d3b851dc7ec6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/17f3d0365b3f49a398a9d3b851dc7ec6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4827,
          name: "Arctic Storm Mobile",
          uuid: "82b32778b6de425ba17cabef47630b81",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/82b32778b6de425ba17cabef47630b81.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4689,
          name: "Ares God of War",
          uuid: "889d7a1bb3be4d5d92c3ad36ba70c0cd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/889d7a1bb3be4d5d92c3ad36ba70c0cd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4688,
          name: "Ares God of War Mobile",
          uuid: "ece31118422845cd9d66ae639f091b58",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ece31118422845cd9d66ae639f091b58.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4385,
          name: "Artificial Intelligence",
          uuid: "2b0261c5462a4105b156998234ecb8d9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2b0261c5462a4105b156998234ecb8d9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4384,
          name: "Artificial Intelligence Mobile",
          uuid: "9074b9610f36422985a3263ceba525d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9074b9610f36422985a3263ceba525d3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4621,
          name: "Artist Studio",
          uuid: "8b88c338a8f646dc97cab4ac876cb7a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8b88c338a8f646dc97cab4ac876cb7a1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4620,
          name: "Artist Studio Mobile",
          uuid: "c9254c269e644550a52c14d4faac17f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c9254c269e644550a52c14d4faac17f7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5496,
          name: "Assassin Mission",
          uuid: "7f0992a708a246dab08a419e30cffd69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7f0992a708a246dab08a419e30cffd69.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5497,
          name: "Assassin Mission Mobile",
          uuid: "7727efd82b4141ceb6a4f2de15bc1d86",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7727efd82b4141ceb6a4f2de15bc1d86.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5503,
          name: "Attack on Crabs",
          uuid: "9ce1f63ee26f484d85d8c87ddb245344",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9ce1f63ee26f484d85d8c87ddb245344.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5502,
          name: "Attack on Crabs Mobile",
          uuid: "288dd209a2d7489c86875c34fede5e18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/288dd209a2d7489c86875c34fede5e18.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4759,
          name: "Aurora",
          uuid: "7c5e2accdde14bcf9cfcd451aff046b9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7c5e2accdde14bcf9cfcd451aff046b9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4758,
          name: "Aurora Mobile",
          uuid: "318b40b75b0f4e4f87d3fae8c8dd3e07",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/318b40b75b0f4e4f87d3fae8c8dd3e07.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4713,
          name: "Ba Wang Bie Ji",
          uuid: "8c43bd6742694c8abb6f1b5d707cdba8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8c43bd6742694c8abb6f1b5d707cdba8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4712,
          name: "Ba Wang Bie Ji Mobile",
          uuid: "69c8374f65e54cddb1d990cb210679d5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/69c8374f65e54cddb1d990cb210679d5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4601,
          name: "Baccarat",
          uuid: "fb5d85130a4d4d50bc70b7128b80dbf0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fb5d85130a4d4d50bc70b7128b80dbf0.png",
          type: "table",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4600,
          name: "Baccarat Mobile",
          uuid: "54a421fa87f741a6b432401ae90608d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/54a421fa87f741a6b432401ae90608d3.png",
          type: "table",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4669,
          name: "Bakery Sweetness",
          uuid: "fd3911332dd24673bedc3f5163e900f1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fd3911332dd24673bedc3f5163e900f1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4668,
          name: "Bakery Sweetness Mobile",
          uuid: "c920f1cea65e4ab896dc57008cecbf7f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c920f1cea65e4ab896dc57008cecbf7f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5376,
          name: "Bang Bang",
          uuid: "5093670c88b945a4b2aa3159bdeefe74",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5093670c88b945a4b2aa3159bdeefe74.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5377,
          name: "Bang Bang Mobile",
          uuid: "6930e6be857f486087b17ad5c316ab6e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6930e6be857f486087b17ad5c316ab6e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4844,
          name: "Baseball Fever",
          uuid: "d75615ff2ab74c6bb0137f79c1e540e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d75615ff2ab74c6bb0137f79c1e540e6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4845,
          name: "Baseball Fever Mobile",
          uuid: "3b1d2af2745544fba993afd3c17cc94f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3b1d2af2745544fba993afd3c17cc94f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5554,
          name: "Beach Life",
          uuid: "6fabcba037474b0a8f7341aff58af956",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6fabcba037474b0a8f7341aff58af956.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5555,
          name: "Beach Life Mobile",
          uuid: "a4364b1aebbf40ee99c1777e0f10b67b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a4364b1aebbf40ee99c1777e0f10b67b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5180,
          name: "Bear Run",
          uuid: "5057b8fc27014845bb980c624ba3e1d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5057b8fc27014845bb980c624ba3e1d2.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5181,
          name: "Bear Run Mobile",
          uuid: "5beaab4d67604aae9b3c57765999ee53",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5beaab4d67604aae9b3c57765999ee53.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5110,
          name: "Beautiful Lady",
          uuid: "a6f086077b3841a6aa232cf8a7c57151",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a6f086077b3841a6aa232cf8a7c57151.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5111,
          name: "Beautiful Lady Mobile",
          uuid: "fae1d93d8c5d41a390eb952147152f7f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fae1d93d8c5d41a390eb952147152f7f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5473,
          name: "Beauty Pageant",
          uuid: "3fe8af4683271d3cddcd177badbb7319bf547eff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3fe8af4683271d3cddcd177badbb7319bf547eff.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5472,
          name: "Beauty Pageant Mobile",
          uuid: "13c72714d19725dc61690e211fede286c7b99220",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/13c72714d19725dc61690e211fede286c7b99220.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5138,
          name: "Beauty Trap",
          uuid: "8f4db405b41c449ba9ed0efb0b0bf63e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8f4db405b41c449ba9ed0efb0b0bf63e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5139,
          name: "Beauty Trap Mobile",
          uuid: "a59a15cedd6947ceababadb2cbccc611",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a59a15cedd6947ceababadb2cbccc611.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4469,
          name: "Big Apple",
          uuid: "c27a5fbd331140228167aea7aa049ea7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c27a5fbd331140228167aea7aa049ea7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4468,
          name: "Big Apple Mobile",
          uuid: "4077964e52c84eb18087db4286d15bdc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4077964e52c84eb18087db4286d15bdc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4920,
          name: "Bigfoot Yeti",
          uuid: "57d9e817388c05d44b19d756574ff4d4d670a590",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/57d9e817388c05d44b19d756574ff4d4d670a590.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4921,
          name: "Bigfoot Yeti Mobile",
          uuid: "16aeb6fbc0eeb06867b04674d0cb5f549836356f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/16aeb6fbc0eeb06867b04674d0cb5f549836356f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5160,
          name: "Bionic Human",
          uuid: "c590d903ed9c4b32a834a2a018bd6f98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c590d903ed9c4b32a834a2a018bd6f98.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5161,
          name: "Bionic Human Mobile",
          uuid: "ec16644f71664ccca11b8371cb470b2e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ec16644f71664ccca11b8371cb470b2e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5628,
          name: "Bizarre Barber",
          uuid: "c02765d139454543abf8ca8925a3433f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c02765d139454543abf8ca8925a3433f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5629,
          name: "Bizarre Barber Mobile",
          uuid: "aebfb5be82524577a44228871b5161dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/aebfb5be82524577a44228871b5161dc.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4381,
          name: "Black Rider",
          uuid: "316cbdcf3b7e4eaa81b7c4db892d491b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/316cbdcf3b7e4eaa81b7c4db892d491b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4380,
          name: "Black Rider Mobile",
          uuid: "ac4ce0c6f1c24880ba98fe8e75f577d8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ac4ce0c6f1c24880ba98fe8e75f577d8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5334,
          name: "Blast Man",
          uuid: "5b7d6b99456d439aa63b09eb8d769efd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5b7d6b99456d439aa63b09eb8d769efd.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5335,
          name: "Blast Man Mobile",
          uuid: "e41dd6d4b286470e991b99be0f56e58a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e41dd6d4b286470e991b99be0f56e58a.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4459,
          name: "Blocky Block",
          uuid: "6a9f6823d0994d839ee2c7145454d0d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6a9f6823d0994d839ee2c7145454d0d3.png",
          type: "cluster",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4373,
          name: "Blocky Block 2",
          uuid: "fcd6f92ce7044fdfad2db48121e01383",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fcd6f92ce7044fdfad2db48121e01383.png",
          type: "cluster",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4372,
          name: "Blocky Block 2 Mobile",
          uuid: "efb28fb9c88e48a6804a51d64854935c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/efb28fb9c88e48a6804a51d64854935c.png",
          type: "cluster",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4458,
          name: "Blocky Block Mobile",
          uuid: "30042cf6ee3649f4908a24a1490df861",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/30042cf6ee3649f4908a24a1490df861.png",
          type: "cluster",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4529,
          name: "Bombing Fruit",
          uuid: "84ed33a769544a548410454e8f96da50",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/84ed33a769544a548410454e8f96da50.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4528,
          name: "Bombing Fruit Mobile",
          uuid: "87bd43cbd89842ce9dd4a856217979b5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/87bd43cbd89842ce9dd4a856217979b5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5346,
          name: "Bombing Kraken",
          uuid: "01a0fe3e1dad4512a10fc8dd2f2d7810",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/01a0fe3e1dad4512a10fc8dd2f2d7810.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5347,
          name: "Bombing Kraken Mobile",
          uuid: "527e4223b07340f3a081750cecf0fca4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/527e4223b07340f3a081750cecf0fca4.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4277,
          name: "Bonus Mania",
          uuid: "7331648c778c4c06821beceecbd67f27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7331648c778c4c06821beceecbd67f27.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4487,
          name: "Bonus Mania Deluxe",
          uuid: "92cf40a611d041ba987465c96c70aab2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/92cf40a611d041ba987465c96c70aab2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4486,
          name: "Bonus Mania Deluxe Mobile",
          uuid: "94b2e4c25972405d8e31f9fd103930e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/94b2e4c25972405d8e31f9fd103930e4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4276,
          name: "Bonus Mania Mobile",
          uuid: "5daec11814c846a1ad071f5c13d32e40",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5daec11814c846a1ad071f5c13d32e40.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5477,
          name: "Bonus Mania Plinko",
          uuid: "cdf7331f1dd07292ee32d62939b0e050dae5e1cb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cdf7331f1dd07292ee32d62939b0e050dae5e1cb.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5476,
          name: "Bonus Mania Plinko Mobile",
          uuid: "eeffa6fa59f1dab6cc849fa602a460c294428be2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/eeffa6fa59f1dab6cc849fa602a460c294428be2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5034,
          name: "Bonus Poker",
          uuid: "7c1b4168d08465dbef567a559297de81184bdc76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7c1b4168d08465dbef567a559297de81184bdc76.png",
          type: "table",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5035,
          name: "Bonus Poker Mobile",
          uuid: "f3ccb3264d00dcd521152eef0de9f7a289d9ec68",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f3ccb3264d00dcd521152eef0de9f7a289d9ec68.png",
          type: "table",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4976,
          name: "Book Of Moon",
          uuid: "e354c9a8a0f1f48046e4c7659acaa1a7fb0024d1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e354c9a8a0f1f48046e4c7659acaa1a7fb0024d1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4977,
          name: "Book Of Moon Mobile",
          uuid: "3e169c9df4a1f33cc8db0238ef4cae319c7a33b2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3e169c9df4a1f33cc8db0238ef4cae319c7a33b2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5462,
          name: "Book Of Skull",
          uuid: "bbba9ce4fabc489093dc6a502317cd6d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bbba9ce4fabc489093dc6a502317cd6d.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5463,
          name: "Book Of Skull Mobile",
          uuid: "c199f54f713d4b8b9230497b6fd4081b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c199f54f713d4b8b9230497b6fd4081b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5228,
          name: "Boom X",
          uuid: "6264110eae9245f19cb200a9f1329f0f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6264110eae9245f19cb200a9f1329f0f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5229,
          name: "Boom X Mobile",
          uuid: "86bf8c4d7b7645b69ba881a8842de850",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/86bf8c4d7b7645b69ba881a8842de850.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5396,
          name: "Borderland",
          uuid: "2e81ae48e07f4456b5ff6500a27d0a2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2e81ae48e07f4456b5ff6500a27d0a2c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5397,
          name: "Borderland Mobile",
          uuid: "90cb7b635a5d4632a8b55e1a0399d42a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/90cb7b635a5d4632a8b55e1a0399d42a.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4287,
          name: "Boxing Roo",
          uuid: "dba7a6ddabbb4b4ba91ed834293fcf10",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/dba7a6ddabbb4b4ba91ed834293fcf10.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4286,
          name: "Boxing Roo Mobile",
          uuid: "492f97fb933d432bb7998d8ce9049b67",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/492f97fb933d432bb7998d8ce9049b67.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4633,
          name: "Boy Toys",
          uuid: "c0841900db2e4c769c74f52555253c0c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c0841900db2e4c769c74f52555253c0c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4632,
          name: "Boy Toys Mobile",
          uuid: "2f54a9fca76d4e62ad9ba34c2faee5d0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2f54a9fca76d4e62ad9ba34c2faee5d0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5354,
          name: "Break Through",
          uuid: "e4021695264a47ca87553ba083508aa8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e4021695264a47ca87553ba083508aa8.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5355,
          name: "Break Through Mobile",
          uuid: "142cf16270ad437f951f25b1706d55de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/142cf16270ad437f951f25b1706d55de.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4589,
          name: "Bubble Double",
          uuid: "b42b436be2074ac2afed91897d0b7207",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b42b436be2074ac2afed91897d0b7207.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4588,
          name: "Bubble Double Mobile",
          uuid: "93ec860004d4478790960411232774e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/93ec860004d4478790960411232774e6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5406,
          name: "Bubble Shooter",
          uuid: "25db1b1c5a874927bcdda8c3ba25ffcc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/25db1b1c5a874927bcdda8c3ba25ffcc.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5407,
          name: "Bubble Shooter Mobile",
          uuid: "596e5e474eac42baa7ae949746b0f9c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/596e5e474eac42baa7ae949746b0f9c0.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4547,
          name: "Bull Stampede",
          uuid: "a436fe5f5443484d837beabbae1734ba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a436fe5f5443484d837beabbae1734ba.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4546,
          name: "Bull Stampede Mobile",
          uuid: "798b747435ba48d687cd09ecf96b8c92",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/798b747435ba48d687cd09ecf96b8c92.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4493,
          name: "Bumble Bee",
          uuid: "241652f8af784a8aa26c253e3726f872",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/241652f8af784a8aa26c253e3726f872.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4492,
          name: "Bumble Bee Mobile",
          uuid: "98043fa74e824abf9ff90fd4ff9fc4c3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/98043fa74e824abf9ff90fd4ff9fc4c3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5112,
          name: "Bunny Girl",
          uuid: "e204d80a446a40c788b7c4300a27b945",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e204d80a446a40c788b7c4300a27b945.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5113,
          name: "Bunny Girl Mobile",
          uuid: "d3f1316527c8405c81a1ead22f361f46",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d3f1316527c8405c81a1ead22f361f46.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4860,
          name: "Cai Shen Dao",
          uuid: "f10fb0a5e114a89d4eb29793a7810dc80f59effb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f10fb0a5e114a89d4eb29793a7810dc80f59effb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4861,
          name: "Cai Shen Dao Mobile",
          uuid: "c5cbbea0ecf647a866469db9b593b9290a4d263e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c5cbbea0ecf647a866469db9b593b9290a4d263e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4631,
          name: "Cai Yuan Guang Jin",
          uuid: "2e65eef06fbc4ef5a11eb20ce7686a4e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2e65eef06fbc4ef5a11eb20ce7686a4e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4630,
          name: "Cai Yuan Guang Jin Mobile",
          uuid: "bd2dc79b3eba4f7c91c3f3054fe97803",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bd2dc79b3eba4f7c91c3f3054fe97803.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4729,
          name: "California Gold Rush",
          uuid: "b4dbb49f684b49e0854dcda3dbd2bb54",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b4dbb49f684b49e0854dcda3dbd2bb54.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4728,
          name: "California Gold Rush Mobile",
          uuid: "45313c8c18d24c15ae932167b105f83a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/45313c8c18d24c15ae932167b105f83a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5250,
          name: "Calorie Killer",
          uuid: "06ec85218b554f53a5f838aa10f1023b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/06ec85218b554f53a5f838aa10f1023b.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5251,
          name: "Calorie Killer Mobile",
          uuid: "f1372b00189b4ae29946b552a86f00ee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f1372b00189b4ae29946b552a86f00ee.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4395,
          name: "Candy Mania",
          uuid: "eb7dc75493ea430a9df34bc1286af0c4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/eb7dc75493ea430a9df34bc1286af0c4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4394,
          name: "Candy Mania Mobile",
          uuid: "de52a1d8e6704c9aa02cec8a7bd42554",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/de52a1d8e6704c9aa02cec8a7bd42554.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4327,
          name: "Candy Storm",
          uuid: "484f313758424f0fb16f29b289b6aa50",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/484f313758424f0fb16f29b289b6aa50.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4326,
          name: "Candy Storm Mobile",
          uuid: "523b4ef6cdc940509a2f9b496545eb34",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/523b4ef6cdc940509a2f9b496545eb34.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5236,
          name: "Cantonese Fried Noodles",
          uuid: "e7b2db04105d4ccdb3041cad4e3bdc5b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e7b2db04105d4ccdb3041cad4e3bdc5b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5237,
          name: "Cantonese Fried Noodles Mobile",
          uuid: "4cba3de196f04e4a9c0a812f60e8d089",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4cba3de196f04e4a9c0a812f60e8d089.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4769,
          name: "Captain Pirate",
          uuid: "1d534d79b58e4a04b49e592118a66f2a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1d534d79b58e4a04b49e592118a66f2a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4768,
          name: "Captain Pirate Mobile",
          uuid: "7f31c6ef92474635965c60c5d94592ed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7f31c6ef92474635965c60c5d94592ed.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5427,
          name: "Carnival Of Venice",
          uuid: "2cc189a4867847d4b2806bfd3470fa83",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2cc189a4867847d4b2806bfd3470fa83.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5426,
          name: "Carnival Of Venice Mobile",
          uuid: "61e4408c541c4600b3b62040dcc93e20",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/61e4408c541c4600b3b62040dcc93e20.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5435,
          name: "Carnival Queen",
          uuid: "116ca2f789680234a686ff2735894cb2a7236bd0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/116ca2f789680234a686ff2735894cb2a7236bd0.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5434,
          name: "Carnival Queen Mobile",
          uuid: "adea999174fe14c9491ef3c8d1dfabd56481b100",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/adea999174fe14c9491ef3c8d1dfabd56481b100.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5528,
          name: "Carp Princess",
          uuid: "feedf78c4605472d86124ebe4cd1ddf7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/feedf78c4605472d86124ebe4cd1ddf7.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5529,
          name: "Carp Princess Mobile",
          uuid: "8297481745de43028ee87d311cb17dd1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8297481745de43028ee87d311cb17dd1.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5398,
          name: "Cash Coming",
          uuid: "864875af5a0841dc85187895179dd3aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/864875af5a0841dc85187895179dd3aa.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5399,
          name: "Cash Coming Mobile",
          uuid: "e23dd002ff84446685dc331ea9b02425",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e23dd002ff84446685dc331ea9b02425.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5150,
          name: "Cat And Mouse",
          uuid: "b9a14999ae6d452cbbb9aa2e72cbb313",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b9a14999ae6d452cbbb9aa2e72cbb313.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5151,
          name: "Cat And Mouse Mobile",
          uuid: "24bcb6f6ae6245a19c14b0d4bc483045",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/24bcb6f6ae6245a19c14b0d4bc483045.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5006,
          name: "Cat Museum",
          uuid: "d352873b357644efbef17df9a6a2d207",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d352873b357644efbef17df9a6a2d207.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5007,
          name: "Cat Museum Mobile",
          uuid: "98854735a3664a0793996a76d5be8a61",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/98854735a3664a0793996a76d5be8a61.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4523,
          name: "Catch The Thief",
          uuid: "fedf75cf87964b798a1842b7c6103816",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fedf75cf87964b798a1842b7c6103816.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4522,
          name: "Catch The Thief Mobile",
          uuid: "a1e506778eb24f9daa758238db4c162c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a1e506778eb24f9daa758238db4c162c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4962,
          name: "Chai Gong",
          uuid: "b7cd6245de2e6f193bc64e50d44bdf849e002ee6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b7cd6245de2e6f193bc64e50d44bdf849e002ee6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4963,
          name: "Chai Gong Mobile",
          uuid: "5526eef9dc3ffae8c5f4a9bd05f4f2642171d5e0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5526eef9dc3ffae8c5f4a9bd05f4f2642171d5e0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4980,
          name: "Chain Of Wild",
          uuid: "d24dd67c09f8baf95fb6d27129329704a6395751",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d24dd67c09f8baf95fb6d27129329704a6395751.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4981,
          name: "Chain Of Wild Mobile",
          uuid: "1d545af744841b7bb7e32c78a46dee29017919c2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1d545af744841b7bb7e32c78a46dee29017919c2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4800,
          name: "Charlottes Web",
          uuid: "f1d6ccad4d834e5f8bd3adee7dad3588",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f1d6ccad4d834e5f8bd3adee7dad3588.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4801,
          name: "Charlottes Web Mobile",
          uuid: "be89c95413424578af37f0ebf7f513b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/be89c95413424578af37f0ebf7f513b0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4397,
          name: "Charming Sorceress",
          uuid: "dd87a03e3d94443ba41aba52cecd2eed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/dd87a03e3d94443ba41aba52cecd2eed.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4396,
          name: "Charming Sorceress Mobile",
          uuid: "7ba72b980d4f4833af99a479a196b07b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7ba72b980d4f4833af99a479a196b07b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5260,
          name: "Cheer Up",
          uuid: "997fa8f1761349ccb17ca32194b5f870",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/997fa8f1761349ccb17ca32194b5f870.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5261,
          name: "Cheer Up Mobile",
          uuid: "fd3c20658b634fdabe4b80a0f73f736e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fd3c20658b634fdabe4b80a0f73f736e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5041,
          name: "Cheerleading Team",
          uuid: "a0329b974a6597ebf6f4ac9394d2a417e71a827f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a0329b974a6597ebf6f4ac9394d2a417e71a827f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5040,
          name: "Cheerleading Team Mobile",
          uuid: "96e7a253ab107db9d4a21332e2114a370f195398",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/96e7a253ab107db9d4a21332e2114a370f195398.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5414,
          name: "Chess King",
          uuid: "82ab5fd2382648beb7250d37c3941945",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/82ab5fd2382648beb7250d37c3941945.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5415,
          name: "Chess King Mobile",
          uuid: "5a89ec50e3d9405e8d2bb3b2fcc24683",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5a89ec50e3d9405e8d2bb3b2fcc24683.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4603,
          name: "Chi You",
          uuid: "28da65fe13924fa0b96ccfd7905644f1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/28da65fe13924fa0b96ccfd7905644f1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4602,
          name: "Chi You Mobile",
          uuid: "94e4074a7ff84d0493de9397a3f72928",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/94e4074a7ff84d0493de9397a3f72928.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5544,
          name: "Chihuahua Party",
          uuid: "b10ef28ce3344211ac759c0d37a1048d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b10ef28ce3344211ac759c0d37a1048d.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5545,
          name: "Chihuahua Party Mobile",
          uuid: "d642ecf1d5184c19b15be3a3a33193cb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d642ecf1d5184c19b15be3a3a33193cb.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4699,
          name: "Chinese Opera",
          uuid: "4cd530c1b6604f9dae916639703bf4a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4cd530c1b6604f9dae916639703bf4a1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4698,
          name: "Chinese Opera Mobile",
          uuid: "04b1983b0c1443ca95f20bd7baa59069",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/04b1983b0c1443ca95f20bd7baa59069.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5416,
          name: "Chinese Phoenix",
          uuid: "f37f94c5b7c1450ebda4b938bdd1b61b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f37f94c5b7c1450ebda4b938bdd1b61b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5417,
          name: "Chinese Phoenix Mobile",
          uuid: "2e381016f7714f08a5a05df5b953dd98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2e381016f7714f08a5a05df5b953dd98.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5128,
          name: "Chinese Quyi",
          uuid: "23ed238887e94a929f398ae9ca5b111f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/23ed238887e94a929f398ae9ca5b111f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5129,
          name: "Chinese Quyi Mobile",
          uuid: "1009d4d9caa14145b45edc470800b40a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1009d4d9caa14145b45edc470800b40a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4419,
          name: "Chinese Valentines Day",
          uuid: "0c1c5e8f807a4dbabb2facea8480e22f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0c1c5e8f807a4dbabb2facea8480e22f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4418,
          name: "Chinese Valentines Day Mobile",
          uuid: "15ab88518901449ab6b6cb051d61708e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/15ab88518901449ab6b6cb051d61708e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4992,
          name: "Chivalrous Girl",
          uuid: "d0c300463c1a2559515e2cc7fc3af40c1345e1d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d0c300463c1a2559515e2cc7fc3af40c1345e1d7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4993,
          name: "Chivalrous Girl Mobile",
          uuid: "acaa4889c055757602a9fd23c17253e620f53a34",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/acaa4889c055757602a9fd23c17253e620f53a34.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4367,
          name: "Christmas Candy",
          uuid: "4981bee599d2406c8a5fb23528b06f47",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4981bee599d2406c8a5fb23528b06f47.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4366,
          name: "Christmas Candy Mobile",
          uuid: "7684cd0a7d52429e9d826c847c0e972f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7684cd0a7d52429e9d826c847c0e972f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5300,
          name: "Clean House",
          uuid: "5ae54a8502aa473caaa5928de92c4814",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5ae54a8502aa473caaa5928de92c4814.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5301,
          name: "Clean House Mobile",
          uuid: "19173c5f099c48549265e02cedcc5044",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/19173c5f099c48549265e02cedcc5044.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4281,
          name: "Cocorico",
          uuid: "83980719600d4ff89407ef1da44aea15",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/83980719600d4ff89407ef1da44aea15.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4280,
          name: "Cocorico Mobile",
          uuid: "0ca939bed417491595a9b7598a807eda",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0ca939bed417491595a9b7598a807eda.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5196,
          name: "Coffee Wild",
          uuid: "58af673d72e44107a663523d78f04989",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/58af673d72e44107a663523d78f04989.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5197,
          name: "Coffee Wild Mobile",
          uuid: "d1dff296c4894502aa0a451b8a4413f5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d1dff296c4894502aa0a451b8a4413f5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4483,
          name: "Come On Rhythm",
          uuid: "2dc22709bf5c4e03898501218734bcb2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2dc22709bf5c4e03898501218734bcb2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4482,
          name: "Come On Rhythm Mobile",
          uuid: "34da473d61b742f1980ac56494fbb3b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/34da473d61b742f1980ac56494fbb3b4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4942,
          name: "County Seat",
          uuid: "ad54d93fee7be02cbf1b0c096a2ed3fe6244cafb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ad54d93fee7be02cbf1b0c096a2ed3fe6244cafb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4943,
          name: "County Seat Mobile",
          uuid: "157eec8eca14e28c139f2ee261773a8679f90968",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/157eec8eca14e28c139f2ee261773a8679f90968.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4579,
          name: "Cowboys",
          uuid: "31c5fa9fa02f4cd3bd321140ee700677",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/31c5fa9fa02f4cd3bd321140ee700677.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4578,
          name: "Cowboys Mobile",
          uuid: "2134512b051e4e0f9d72e8a6ac7e7414",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2134512b051e4e0f9d72e8a6ac7e7414.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5487,
          name: "Crazy Blackbeard",
          uuid: "b8326cb4cda940852502a490af543a9068e25333",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b8326cb4cda940852502a490af543a9068e25333.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5486,
          name: "Crazy Blackbeard Mobile",
          uuid: "29c3d7872e3c257a92dbc405880a12cbd00f125a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/29c3d7872e3c257a92dbc405880a12cbd00f125a.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4649,
          name: "Crazy Circus",
          uuid: "6e4fe1ebe1f449d2af472f74e2312a5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6e4fe1ebe1f449d2af472f74e2312a5d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4648,
          name: "Crazy Circus Mobile",
          uuid: "f9182e4e4ee14d348e8c93ee7c6e5b78",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f9182e4e4ee14d348e8c93ee7c6e5b78.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5412,
          name: "Crazy Guava",
          uuid: "7e38288e0ff6411e8f020ed21c66fe5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7e38288e0ff6411e8f020ed21c66fe5c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5413,
          name: "Crazy Guava Mobile",
          uuid: "36aceb2c8d6b413780ec839905adfa39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/36aceb2c8d6b413780ec839905adfa39.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5182,
          name: "Crazy Gym",
          uuid: "f774b0a34d8a4e938ccb33159f2d50f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f774b0a34d8a4e938ccb33159f2d50f6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5183,
          name: "Crazy Gym Mobile",
          uuid: "0c5b4f0399cb45d3b996c24f3e100bc5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0c5b4f0399cb45d3b996c24f3e100bc5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5521,
          name: "Crazy Killer",
          uuid: "4388e8f34f5344e6bc9c7ec84118f026",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4388e8f34f5344e6bc9c7ec84118f026.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5520,
          name: "Crazy Killer Mobile",
          uuid: "fb19cfd0fd394728a9ee928fc9eb1543",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fb19cfd0fd394728a9ee928fc9eb1543.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5614,
          name: "Crazy Oktoberfest",
          uuid: "b4044c7cb9a945c390519411e1e61a9f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b4044c7cb9a945c390519411e1e61a9f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5615,
          name: "Crazy Oktoberfest Mobile",
          uuid: "26f1f0234baf466987b892557c4bf976",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/26f1f0234baf466987b892557c4bf976.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4968,
          name: "Creator Ra",
          uuid: "2130684c71a57b9ed07358ea2386f10bb1b853b1b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/2130684c71a57b9ed07358ea2386f10bb1b853b1b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4969,
          name: "Creator Ra Mobile",
          uuid: "f81f709b7b77b7962b8bdf32589d2c38b331ec60",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f81f709b7b77b7962b8bdf32589d2c38b331ec60.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5200,
          name: "Cricket Winner",
          uuid: "2359d6d10f9d45b59b9911735e8e0466",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2359d6d10f9d45b59b9911735e8e0466.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5201,
          name: "Cricket Winner Mobile",
          uuid: "abf742c05e57461b802c2bf25727b8a2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/abf742c05e57461b802c2bf25727b8a2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5358,
          name: "Crush Pirate Ship",
          uuid: "de5ad4fccff24443950e03b0d99d57c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/de5ad4fccff24443950e03b0d99d57c0.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5359,
          name: "Crush Pirate Ship Mobile",
          uuid: "ddf2ccfed56a445a9df775b50803e46c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ddf2ccfed56a445a9df775b50803e46c.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4932,
          name: "Cthulhu Secret",
          uuid: "ea7ec383d4a4e8ba2868cdb833a7829fba60608a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ea7ec383d4a4e8ba2868cdb833a7829fba60608a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4933,
          name: "Cthulhu Secret Mobile",
          uuid: "ab250f6c89d03f84a3364e5f8f293a695b8fb3e7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ab250f6c89d03f84a3364e5f8f293a695b8fb3e7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4409,
          name: "Cu Ju",
          uuid: "09040ca0723d4e7982a17e0dd192c66e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/09040ca0723d4e7982a17e0dd192c66e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4408,
          name: "Cu Ju Mobile",
          uuid: "bc186626d076476c8415419778aa042f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bc186626d076476c8415419778aa042f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4804,
          name: "Cupid And Psyche",
          uuid: "7e2d310c38394bd59cced63bdb2d2f45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7e2d310c38394bd59cced63bdb2d2f45.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4805,
          name: "Cupid And Psyche Mobile",
          uuid: "07a9c5db7ecf4778a46f5df4eb0c225f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/07a9c5db7ecf4778a46f5df4eb0c225f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5574,
          name: "Cursed Seas",
          uuid: "0053251242ff4261b893ccd3e7331e74",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0053251242ff4261b893ccd3e7331e74.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5575,
          name: "Cursed Seas Mobile",
          uuid: "04276c97d2cb471ebd6dc66cf56f20eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/04276c97d2cb471ebd6dc66cf56f20eb.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4727,
          name: "da Vinci",
          uuid: "1c894336633947ce9612eff2351596f5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1c894336633947ce9612eff2351596f5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4726,
          name: "da Vinci Mobile",
          uuid: "f06335d760f548258d8e1568d0633a5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f06335d760f548258d8e1568d0633a5d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4577,
          name: "Daji",
          uuid: "756d827cb16c453386e2d361c1d6592e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/756d827cb16c453386e2d361c1d6592e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4576,
          name: "Daji Mobile",
          uuid: "c3989f96d1fa42caba945d31fbc1b4cd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c3989f96d1fa42caba945d31fbc1b4cd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5622,
          name: "Danger Zone",
          uuid: "67047327a7324202af88b9b7d9741332",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/67047327a7324202af88b9b7d9741332.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5623,
          name: "Danger Zone Mobile",
          uuid: "ab013d9849794856bd12d8efb9217041",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ab013d9849794856bd12d8efb9217041.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5030,
          name: "Dark Family",
          uuid: "abdcd4d7ca9131496ef6042499021b322a2fb6fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/abdcd4d7ca9131496ef6042499021b322a2fb6fa.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5031,
          name: "Dark Family Mobile",
          uuid: "f47f74c3eafff429bdd29b3e5276e5eaeb53a5c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f47f74c3eafff429bdd29b3e5276e5eaeb53a5c2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4321,
          name: "Dark Fortress",
          uuid: "0ae004cf91454d8394c478237f19715f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0ae004cf91454d8394c478237f19715f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4320,
          name: "Dark Fortress Mobile",
          uuid: "49cddcba28dd4a4685c299d283a068f9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/49cddcba28dd4a4685c299d283a068f9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5492,
          name: "Dark Magic War",
          uuid: "b86423a25e4745228642bacfae539be9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b86423a25e4745228642bacfae539be9.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5493,
          name: "Dark Magic War Mobile",
          uuid: "f922d18121f645ceba2e02411bcc2aa6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f922d18121f645ceba2e02411bcc2aa6.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5324,
          name: "Darts Champion",
          uuid: "1b77e533ee6c4426b7fcfd6d816a2fbd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1b77e533ee6c4426b7fcfd6d816a2fbd.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5325,
          name: "Darts Champion Mobile",
          uuid: "86fbf518fc3840e1ade3be696c12d8b5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/86fbf518fc3840e1ade3be696c12d8b5.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5454,
          name: "Deep Beast",
          uuid: "909c321b053f47769204653d1712f1fb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/909c321b053f47769204653d1712f1fb.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5455,
          name: "Deep Beast Mobile",
          uuid: "80a3d280a1004b719c4853f8f796b4a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/80a3d280a1004b719c4853f8f796b4a5.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5490,
          name: "Deep Fishing",
          uuid: "e28e688b203848e4a44582d76ca8758f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e28e688b203848e4a44582d76ca8758f.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5491,
          name: "Deep Fishing Mobile",
          uuid: "c6f737b99beb49ef999f6da46027cdad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c6f737b99beb49ef999f6da46027cdad.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5469,
          name: "Deep Overlord",
          uuid: "300ee284a1bd0c6eb0ad79378b90a3a187ab3210",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/300ee284a1bd0c6eb0ad79378b90a3a187ab3210.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5468,
          name: "Deep Overlord Mobile",
          uuid: "b01f3a0f249f22546362483c9286800ccd8c582e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b01f3a0f249f22546362483c9286800ccd8c582e.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4663,
          name: "Deep Sea Adventure",
          uuid: "2c81dc12c6a847589429aa7847bc9e01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2c81dc12c6a847589429aa7847bc9e01.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4662,
          name: "Deep Sea Adventure Mobile",
          uuid: "fbe24ec12a4f4a31bff2e94f4567e057",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fbe24ec12a4f4a31bff2e94f4567e057.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5586,
          name: "Deluxe Dice",
          uuid: "a801e151224b4af39538552416382076",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a801e151224b4af39538552416382076.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5587,
          name: "Deluxe Dice Mobile",
          uuid: "41e7444e2ca44492b41b249e41062e04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/41e7444e2ca44492b41b249e41062e04.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4928,
          name: "Demon Fox",
          uuid: "513a05b73ef97401fdc1a3c3ff204bde521501de",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/513a05b73ef97401fdc1a3c3ff204bde521501de.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4929,
          name: "Demon Fox Mobile",
          uuid: "ab6e3565cbf767f540cc0925afa6fb7c02a69908",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ab6e3565cbf767f540cc0925afa6fb7c02a69908.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4697,
          name: "Dia De Muertos",
          uuid: "aaed6c396faf43e89450fbb76b262b27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/aaed6c396faf43e89450fbb76b262b27.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4696,
          name: "Dia De Muertos Mobile",
          uuid: "2e7ae4eda96a4524a08ac745d66676b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2e7ae4eda96a4524a08ac745d66676b4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4848,
          name: "Diamond Power",
          uuid: "bdb21696bdac49e8907d3e328b9fb684",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bdb21696bdac49e8907d3e328b9fb684.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4849,
          name: "Diamond Power Mobile",
          uuid: "c5cffd6cd01943a7939459d86afbe5f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c5cffd6cd01943a7939459d86afbe5f0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4741,
          name: "Dim Sum",
          uuid: "0eb581d8fb9a4d81ba170890adf3d741",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0eb581d8fb9a4d81ba170890adf3d741.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4740,
          name: "Dim Sum Mobile",
          uuid: "a1269a7d608d46df94bb668e1d5f80e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a1269a7d608d46df94bb668e1d5f80e0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4453,
          name: "Don Quixote",
          uuid: "68c5f7b86ed94ca4ad4b5346eeccd1ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/68c5f7b86ed94ca4ad4b5346eeccd1ce.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4452,
          name: "Don Quixote Mobile",
          uuid: "036f8b7b4025498d811dd6f08c87187e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/036f8b7b4025498d811dd6f08c87187e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5400,
          name: "Donut City",
          uuid: "a09009dd1589402bbe30da2c44496eb4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a09009dd1589402bbe30da2c44496eb4.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5401,
          name: "Donut City Mobile",
          uuid: "52cd3bb371a541db8ab8b537489a5bc5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/52cd3bb371a541db8ab8b537489a5bc5.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4341,
          name: "Double Fortune",
          uuid: "74566dc08645446f95f1bf859252b3c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/74566dc08645446f95f1bf859252b3c6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4340,
          name: "Double Fortune Mobile",
          uuid: "914a8fbce3404452b5deda0648960b91",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/914a8fbce3404452b5deda0648960b91.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5284,
          name: "Double Happiness",
          uuid: "eb63e8e390594b2e80b422fb4901ff8e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/eb63e8e390594b2e80b422fb4901ff8e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5285,
          name: "Double Happiness Mobile",
          uuid: "cd151cc3d2134bceb17af55a39ac2b10",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cd151cc3d2134bceb17af55a39ac2b10.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4283,
          name: "Dr. Geek",
          uuid: "0ffe3f91d1124fc7b27a8dc1438cffec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0ffe3f91d1124fc7b27a8dc1438cffec.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4282,
          name: "Dr. Geek Mobile",
          uuid: "02bc94348fb24f6e8c5226f0e13abaa3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/02bc94348fb24f6e8c5226f0e13abaa3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4379,
          name: "Dracula",
          uuid: "a8527018f31342e08fccc189551fc4ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a8527018f31342e08fccc189551fc4ec.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4378,
          name: "Dracula Mobile",
          uuid: "76e14c6423b34c9bb7941b1ff72416ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/76e14c6423b34c9bb7941b1ff72416ad.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4415,
          name: "Dragon Ball",
          uuid: "36e9c6669b8f4182ae353d620b40367a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/36e9c6669b8f4182ae353d620b40367a.png",
          type: "other",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4414,
          name: "Dragon Ball Mobile",
          uuid: "1baf8bdc0bd64e9e9b63f43369c6a59a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1baf8bdc0bd64e9e9b63f43369c6a59a.png",
          type: "other",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4609,
          name: "Dragon Boat",
          uuid: "72b7d9f1300a44ed9939efbe53cc36b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/72b7d9f1300a44ed9939efbe53cc36b4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4918,
          name: "Dragon Boat 2",
          uuid: "399f956c80f0113951a2d4dee40bdfe3f06712fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/399f956c80f0113951a2d4dee40bdfe3f06712fb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4919,
          name: "Dragon Boat 2 Mobile",
          uuid: "38488275916ac47a5284b6d9064e60dcd9630a56",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/38488275916ac47a5284b6d9064e60dcd9630a56.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4608,
          name: "Dragon Boat Mobile",
          uuid: "adcbb48c2ac347c8bea8d1976b8e606d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/adcbb48c2ac347c8bea8d1976b8e606d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5402,
          name: "Dragon Boom",
          uuid: "868112cd76e04c00a1d933e5513be38f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/868112cd76e04c00a1d933e5513be38f.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5403,
          name: "Dragon Boom Mobile",
          uuid: "d31b02ac7b8a403aa2db51134a7079f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d31b02ac7b8a403aa2db51134a7079f6.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5370,
          name: "Dragon City",
          uuid: "0f95c820c04f45a5924a6d814c70c1d5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0f95c820c04f45a5924a6d814c70c1d5.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5371,
          name: "Dragon City Mobile",
          uuid: "04d4926c736a4103b1e922087313fa65",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/04d4926c736a4103b1e922087313fa65.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4723,
          name: "Dragon Gate",
          uuid: "aa08fe26caba4a8fbe130a7eb4929893",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/aa08fe26caba4a8fbe130a7eb4929893.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4722,
          name: "Dragon Gate Mobile",
          uuid: "8a41457c74804a798dab0a0386d5744e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8a41457c74804a798dab0a0386d5744e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5366,
          name: "Dragon Hunter",
          uuid: "05c4276244804f10973f970ad38de36f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/05c4276244804f10973f970ad38de36f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5367,
          name: "Dragon Hunter Mobile",
          uuid: "3a68084b76284960948f7cdaee116662",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3a68084b76284960948f7cdaee116662.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5421,
          name: "Dragon Ingot",
          uuid: "506070190b524151b2ab9b0ff255dfc2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/506070190b524151b2ab9b0ff255dfc2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5420,
          name: "Dragon Ingot Mobile",
          uuid: "bb77c875a0194533af5723c0888ba37c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bb77c875a0194533af5723c0888ba37c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5054,
          name: "Dragon Inn",
          uuid: "33f6fa0fabd249e68f18fdf9a8d16459",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/33f6fa0fabd249e68f18fdf9a8d16459.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5055,
          name: "Dragon Inn Mobile",
          uuid: "c14c3b54fff647269cc81444b9392a01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c14c3b54fff647269cc81444b9392a01.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5316,
          name: "Dragon Pearl",
          uuid: "093beeaa47724b08b914f807003a66f3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/093beeaa47724b08b914f807003a66f3.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5317,
          name: "Dragon Pearl Mobile",
          uuid: "bf12ed5c98a34f409d3c9ff5bdadeb1e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bf12ed5c98a34f409d3c9ff5bdadeb1e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5610,
          name: "Dragon Treasure",
          uuid: "7108a9121090459db9db672dcf888c55",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7108a9121090459db9db672dcf888c55.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5611,
          name: "Dragon Treasure Mobile",
          uuid: "572bad3cfbda47e8b6d68201e71e0546",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/572bad3cfbda47e8b6d68201e71e0546.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4998,
          name: "Dragon Turtle",
          uuid: "a9025eb00682e765a79a473af1846bd6797ed67e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a9025eb00682e765a79a473af1846bd6797ed67e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4999,
          name: "Dragon Turtle Mobile",
          uuid: "71277718bc237f4f368aadd3f95f87a5e0069a0a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/71277718bc237f4f368aadd3f95f87a5e0069a0a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5616,
          name: "Dragon's Hoard",
          uuid: "f562c5a740494f20be39b983ca0e8e81",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f562c5a740494f20be39b983ca0e8e81.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5617,
          name: "Dragon's Hoard Mobile",
          uuid: "14eb19342d4d4b24a9fea715519edd59",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/14eb19342d4d4b24a9fea715519edd59.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4309,
          name: "Dragon's Way",
          uuid: "fe41c0d083764dfe8cda9ce43d1e1d27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fe41c0d083764dfe8cda9ce43d1e1d27.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4308,
          name: "Dragon's Way Mobile",
          uuid: "6a8fb1beafc743d386cb52928dec58bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6a8fb1beafc743d386cb52928dec58bb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4747,
          name: "Dreamcatcher",
          uuid: "8dc8ea9db0f948568b0e00a889152c5a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8dc8ea9db0f948568b0e00a889152c5a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4746,
          name: "Dreamcatcher Mobile",
          uuid: "c1395762a33f4191b2927a092075179e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c1395762a33f4191b2927a092075179e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5016,
          name: "Dwarf Miner",
          uuid: "4f066c93cd07e015adb4fdceac08c9e26ce5b5e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4f066c93cd07e015adb4fdceac08c9e26ce5b5e8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5017,
          name: "Dwarf Miner Mobile",
          uuid: "c6d5b32e006f5e5a582b5842478568edd08549af",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c6d5b32e006f5e5a582b5842478568edd08549af.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4820,
          name: "Earth God",
          uuid: "0b5a8f0d8ad64d04b156d414590ed74b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0b5a8f0d8ad64d04b156d414590ed74b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4821,
          name: "Earth God Mobile",
          uuid: "8967832e4e60457ca91f4f4d5a6b4431",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8967832e4e60457ca91f4f4d5a6b4431.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5608,
          name: "Egyptian Deity",
          uuid: "80151ec143fa480b9093ddfe6995f11d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/80151ec143fa480b9093ddfe6995f11d.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5609,
          name: "Egyptian Deity Mobile",
          uuid: "9c435d1d1dc14864be5096a55c76b897",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9c435d1d1dc14864be5096a55c76b897.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4587,
          name: "Egyptian Empress",
          uuid: "c8f8b5427ae94b2ab83ce74d335cce31",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c8f8b5427ae94b2ab83ce74d335cce31.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4586,
          name: "Egyptian Empress Mobile",
          uuid: "38d847dbe5ae4a558628fe0c262060ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/38d847dbe5ae4a558628fe0c262060ea.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4573,
          name: "Egyptian Mythology",
          uuid: "99cc1088c2d74cba8ee35f058fd6af1c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/99cc1088c2d74cba8ee35f058fd6af1c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4572,
          name: "Egyptian Mythology Mobile",
          uuid: "5c170994871048c783dbb3d35d9109da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5c170994871048c783dbb3d35d9109da.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5505,
          name: "Electric Energy",
          uuid: "b29cf172f2f5447bbe07277cb9002d95",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b29cf172f2f5447bbe07277cb9002d95.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5504,
          name: "Electric Energy Mobile",
          uuid: "d7f5fd1343344bd88f249ca6387ec705",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d7f5fd1343344bd88f249ca6387ec705.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5513,
          name: "Element Shaman",
          uuid: "faeff4b520114cd4a5d96b4b3d2b1ac7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/faeff4b520114cd4a5d96b4b3d2b1ac7.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5512,
          name: "Element Shaman Mobile",
          uuid: "a58c57d87fb24c1f8939c9c450dfc051",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a58c57d87fb24c1f8939c9c450dfc051.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5172,
          name: "Elite Games",
          uuid: "8cfd1ea5e4e64319abdc2e6a108afeca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8cfd1ea5e4e64319abdc2e6a108afeca.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5173,
          name: "Elite Games Mobile",
          uuid: "0d02271d567942b494bc73952fb70dd1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0d02271d567942b494bc73952fb70dd1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4403,
          name: "Emoji",
          uuid: "962278b4ac4346ee9b3851badc976b2b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/962278b4ac4346ee9b3851badc976b2b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4402,
          name: "Emoji Mobile",
          uuid: "3f57d170077c49c0baae46b8eec4ed32",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3f57d170077c49c0baae46b8eec4ed32.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5076,
          name: "Empty City",
          uuid: "985c05ce4db04af9ad679de676087827",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/985c05ce4db04af9ad679de676087827.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5077,
          name: "Empty City Mobile",
          uuid: "73838c7c998c4fe79a6cdcbaa60f27c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/73838c7c998c4fe79a6cdcbaa60f27c6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4761,
          name: "Enchanted",
          uuid: "9708e7562c1344168faa3f19b8041b34",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9708e7562c1344168faa3f19b8041b34.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4760,
          name: "Enchanted Mobile",
          uuid: "7be2146a28dc4af897467c4c1af24b05",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7be2146a28dc4af897467c4c1af24b05.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5582,
          name: "Enchantress Area",
          uuid: "18512fa6c0cb44648023486a4216d63b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/18512fa6c0cb44648023486a4216d63b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5583,
          name: "Enchantress Area Mobile",
          uuid: "576c0cf529af497bbd2d0add671dbde9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/576c0cf529af497bbd2d0add671dbde9.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5252,
          name: "Endorser Of Thor",
          uuid: "10dd71a39e844d15af6523252c5b4f95",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/10dd71a39e844d15af6523252c5b4f95.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5253,
          name: "Endorser Of Thor Mobile",
          uuid: "3ca138c7fcba46e79562a96a65124635",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3ca138c7fcba46e79562a96a65124635.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4525,
          name: "Erlang Shen",
          uuid: "a5105c8ce5a74252899b23ea5a21a86f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a5105c8ce5a74252899b23ea5a21a86f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4524,
          name: "Erlang Shen Mobile",
          uuid: "e24872ff4b274965970555ca1fc23811",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e24872ff4b274965970555ca1fc23811.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5140,
          name: "European Roulette",
          uuid: "bd179a3132e74cd0aca1ed69fe3e8589",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bd179a3132e74cd0aca1ed69fe3e8589.png",
          type: "other",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5141,
          name: "European Roulette Mobile",
          uuid: "b1b444c8fee044adb6ed8f12260e6c1a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b1b444c8fee044adb6ed8f12260e6c1a.png",
          type: "other",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5028,
          name: "Evil Pumpkin",
          uuid: "303090b13dcb8549bda1b30770792a27a5337c80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/303090b13dcb8549bda1b30770792a27a5337c80.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5029,
          name: "Evil Pumpkin Mobile",
          uuid: "6ef8dfe503ec39ffe33b6b348fe1116ab0ce69d8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6ef8dfe503ec39ffe33b6b348fe1116ab0ce69d8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5636,
          name: "Explosion Magic",
          uuid: "4258efed212a4839b654df1f5550b5b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4258efed212a4839b654df1f5550b5b8.jpg",
          type: "other",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5637,
          name: "Explosion Magic Mobile",
          uuid: "0294edb092864373afbb7e353aef5947",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0294edb092864373afbb7e353aef5947.jpg",
          type: "other",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4788,
          name: "Fa Cai Destiny",
          uuid: "f778ff2858144bfca85464e840b4599c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f778ff2858144bfca85464e840b4599c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4789,
          name: "Fa Cai Destiny Mobile",
          uuid: "bb80b59be4cd45d6b67534c950c99c00",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bb80b59be4cd45d6b67534c950c99c00.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4675,
          name: "Fairy Dust",
          uuid: "3813408252624ac0b2f64f4eeefb2e4c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3813408252624ac0b2f64f4eeefb2e4c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4674,
          name: "Fairy Dust Mobile",
          uuid: "54940267a83140bf9d45697ddceac72a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/54940267a83140bf9d45697ddceac72a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4355,
          name: "Fairy Forest Tale",
          uuid: "9e665c7c6451405591be429972f1fb59",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9e665c7c6451405591be429972f1fb59.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4354,
          name: "Fairy Forest Tale Mobile",
          uuid: "76486404c52e40ffbf44a6c0a26d1ca4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/76486404c52e40ffbf44a6c0a26d1ca4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4441,
          name: "Fantasy 777",
          uuid: "f57d61c756cd4e95ae834581bc6506df",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f57d61c756cd4e95ae834581bc6506df.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4440,
          name: "Fantasy 777 Mobile",
          uuid: "abd5031ccf9a42cfa3e242c889138908",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/abd5031ccf9a42cfa3e242c889138908.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4914,
          name: "Fantasy Dragons",
          uuid: "4c5d2e6786dc3904802b1c77cc395018204d6c60",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4c5d2e6786dc3904802b1c77cc395018204d6c60.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4915,
          name: "Fantasy Dragons Mobile",
          uuid: "24da9e3947f4a6b80af60bf17ed7386c2a567793",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/24da9e3947f4a6b80af60bf17ed7386c2a567793.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4717,
          name: "Fantasy Park",
          uuid: "edbd80b306c64c3282109582084f7553",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/edbd80b306c64c3282109582084f7553.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4716,
          name: "Fantasy Park Mobile",
          uuid: "7f4d864cd8784a4abf71844447ce9d56",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7f4d864cd8784a4abf71844447ce9d56.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5475,
          name: "Fantasy Universe",
          uuid: "aa0308e01f105c3a1f551a7ba80b2207994506f2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/aa0308e01f105c3a1f551a7ba80b2207994506f2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5474,
          name: "Fantasy Universe Mobile",
          uuid: "fc7dbf7a8f022e573c8ce17b58a512bb5374c3f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fc7dbf7a8f022e573c8ce17b58a512bb5374c3f6.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5344,
          name: "Fantasy War",
          uuid: "3db8e479188147cc802afd9dc482bcd5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3db8e479188147cc802afd9dc482bcd5.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5345,
          name: "Fantasy War Mobile",
          uuid: "34bcd6d9ec7f4b9fa00cb40f199efdd8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/34bcd6d9ec7f4b9fa00cb40f199efdd8.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4653,
          name: "Farm Mania",
          uuid: "978e9f43829c41ebb189437b2ed4e9a3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/978e9f43829c41ebb189437b2ed4e9a3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4652,
          name: "Farm Mania Mobile",
          uuid: "b991d3fb57724d389f56bfcf98065cd1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b991d3fb57724d389f56bfcf98065cd1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5026,
          name: "Fashion Bones",
          uuid: "b8d68a70517c038ab9f5d8be2a3e8c47393469db",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b8d68a70517c038ab9f5d8be2a3e8c47393469db.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5027,
          name: "Fashion Bones Mobile",
          uuid: "a5950d82a14fe86632bc11da3ada3cdf1943892c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a5950d82a14fe86632bc11da3ada3cdf1943892c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5640,
          name: "Fashion Life",
          uuid: "ebae20a27f43484ab574660d9a1dda79",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ebae20a27f43484ab574660d9a1dda79.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5641,
          name: "Fashion Life Mobile",
          uuid: "38fbeb53eba640f2aa5c1746aa1bfe8a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/38fbeb53eba640f2aa5c1746aa1bfe8a.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4617,
          name: "Fast Blast",
          uuid: "5750f01ea474477bb1a481b7b2ed6ae1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5750f01ea474477bb1a481b7b2ed6ae1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4616,
          name: "Fast Blast Mobile",
          uuid: "08f9caa7eb4f481cbfd2c7dfc80790dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/08f9caa7eb4f481cbfd2c7dfc80790dc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4297,
          name: "Fastbreak",
          uuid: "abd3973b700c45b19080d13f6d300cfb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/abd3973b700c45b19080d13f6d300cfb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4296,
          name: "Fastbreak Mobile",
          uuid: "19c4eb9924d7449f9dd9e93f4717374d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/19c4eb9924d7449f9dd9e93f4717374d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5548,
          name: "Fat Guy",
          uuid: "7c7fdf50b20243b89922f7ba91d8d49b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7c7fdf50b20243b89922f7ba91d8d49b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5549,
          name: "Fat Guy Mobile",
          uuid: "690a10871ab5420281b654b15b9edcca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/690a10871ab5420281b654b15b9edcca.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4934,
          name: "Fighting Girls",
          uuid: "55e11fbbf13f45d80ac1b41d3f973b6f1201e21d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/55e11fbbf13f45d80ac1b41d3f973b6f1201e21d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4935,
          name: "Fighting Girls Mobile",
          uuid: "69f35f8e842ab7d1287d4eb495a80a7d66e9691f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/69f35f8e842ab7d1287d4eb495a80a7d66e9691f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4303,
          name: "Fire Dragons",
          uuid: "e9c7ee50f7fc43b492ead286b3444981",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e9c7ee50f7fc43b492ead286b3444981.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4302,
          name: "Fire Dragons Mobile",
          uuid: "ab98e117593347ff89ad1caa2bc868b1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ab98e117593347ff89ad1caa2bc868b1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4784,
          name: "Fire Hit",
          uuid: "df05650d2a744c228e20144ca7d12091",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/df05650d2a744c228e20144ca7d12091.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4785,
          name: "Fire Hit Mobile",
          uuid: "0db47258e96c44a9bcf9d8632880a08d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0db47258e96c44a9bcf9d8632880a08d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5256,
          name: "Firefighters",
          uuid: "cd2a171225634d0eb54a8b395b2a6d3f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cd2a171225634d0eb54a8b395b2a6d3f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5257,
          name: "Firefighters Mobile",
          uuid: "3aa26d27a50047ce8cb6788194d14c09",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3aa26d27a50047ce8cb6788194d14c09.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4275,
          name: "Fishing Expedition",
          uuid: "cdcde38c67b84e2fa4526b84c85eec45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cdcde38c67b84e2fa4526b84c85eec45.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4274,
          name: "Fishing Expedition Mobile",
          uuid: "957adcf7dca24450ab354f1b53d78720",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/957adcf7dca24450ab354f1b53d78720.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5601,
          name: "Fishing Tycoon",
          uuid: "1ad06496325e4d9291153214b764e09f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1ad06496325e4d9291153214b764e09f.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5600,
          name: "Fishing Tycoon Mobile",
          uuid: "70f1d648d59841d4a7f0b10e3e108240",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/70f1d648d59841d4a7f0b10e3e108240.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5100,
          name: "Five Fortune Gods",
          uuid: "4f6322368ff147c283f72f5b30541e2f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4f6322368ff147c283f72f5b30541e2f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5101,
          name: "Five Fortune Gods Mobile",
          uuid: "7fc2036309f8469a8583b5733b41c073",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7fc2036309f8469a8583b5733b41c073.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5120,
          name: "Five Heroes",
          uuid: "ec44dd2317e9419ca03a13ded6fee9a8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ec44dd2317e9419ca03a13ded6fee9a8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5121,
          name: "Five Heroes Mobile",
          uuid: "b3f8d40267e449f3b9110368ce575a25",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b3f8d40267e449f3b9110368ce575a25.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5086,
          name: "Five Sound Fortune",
          uuid: "c4f71dacd3fd413090525a7a57ece3a6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c4f71dacd3fd413090525a7a57ece3a6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5087,
          name: "Five Sound Fortune Mobile",
          uuid: "ffdd4ca424f44219991dc9bc74d71103",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ffdd4ca424f44219991dc9bc74d71103.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4703,
          name: "Flaming 7's",
          uuid: "2fa6c38b6b9f44f892cbc53c66e12106",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2fa6c38b6b9f44f892cbc53c66e12106.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4702,
          name: "Flaming 7's Mobile",
          uuid: "9b883e4a0c1b40e8be633c3f2314a208",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9b883e4a0c1b40e8be633c3f2314a208.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5362,
          name: "Flaming Monkey Classic",
          uuid: "74ddcdd76ad84aba9cc48f69202f27b6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/74ddcdd76ad84aba9cc48f69202f27b6.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5363,
          name: "Flaming Monkey Classic Mobile",
          uuid: "6827e88db29749f8a3db98d95574cce2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6827e88db29749f8a3db98d95574cce2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4830,
          name: "Flower Goddess Festival",
          uuid: "24dec2fedfff4b7db612d8beefa74fe0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/24dec2fedfff4b7db612d8beefa74fe0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4831,
          name: "Flower Goddess Festival Mobile",
          uuid: "ede218517e844e3dbe94ee8386029177",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ede218517e844e3dbe94ee8386029177.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4431,
          name: "Fluffy Buddy",
          uuid: "f0795332a7054e0d80bf742f46a3f012",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f0795332a7054e0d80bf742f46a3f012.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4430,
          name: "Fluffy Buddy Mobile",
          uuid: "7aa79493f903437b8e6e984f443ddfae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7aa79493f903437b8e6e984f443ddfae.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5456,
          name: "Fly To Universe",
          uuid: "96e7427a66d744b6926f9234e81128b6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/96e7427a66d744b6926f9234e81128b6.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5457,
          name: "Fly To Universe Mobile",
          uuid: "319fcfeeab944e83b6523ca181b0ef97",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/319fcfeeab944e83b6523ca181b0ef97.jpg",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5044,
          name: "Food Coma",
          uuid: "c014125929928f1dea16d63a62fe8d0e1719f1b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c014125929928f1dea16d63a62fe8d0e1719f1b7.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5045,
          name: "Food Coma Mobile",
          uuid: "6ec392682d6b880d0856afa330840481f2806759",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6ec392682d6b880d0856afa330840481f2806759.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5060,
          name: "Football Mania",
          uuid: "e07c18eb0f10431fbe9181944e18ff8a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e07c18eb0f10431fbe9181944e18ff8a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5061,
          name: "Football Mania Mobile",
          uuid: "113fcf0ae5a7453bbf5141496aa72e70",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/113fcf0ae5a7453bbf5141496aa72e70.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4755,
          name: "Formosan Birds",
          uuid: "10489563b1db443fa1acfce596032920",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/10489563b1db443fa1acfce596032920.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4754,
          name: "Formosan Birds Mobile",
          uuid: "823b06ec67354bcb856c9957a7a523a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/823b06ec67354bcb856c9957a7a523a5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5248,
          name: "Fortuna",
          uuid: "c94a84be68db4b0abdc893b988e0be10",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c94a84be68db4b0abdc893b988e0be10.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5249,
          name: "Fortuna Mobile",
          uuid: "4f3a4b5c224947f6a530b61e724505bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4f3a4b5c224947f6a530b61e724505bc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4872,
          name: "Fortune Beauty",
          uuid: "7006804f559802c118bb3a384d54c852d0c00d45",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7006804f559802c118bb3a384d54c852d0c00d45.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4873,
          name: "Fortune Beauty Mobile",
          uuid: "49dc6d739cb3b96c0558cd25dd2d44dc93b50c8c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/49dc6d739cb3b96c0558cd25dd2d44dc93b50c8c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5624,
          name: "Fortune Code",
          uuid: "a2bf8a62809f43179491a10014e14e34",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a2bf8a62809f43179491a10014e14e34.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5625,
          name: "Fortune Code Mobile",
          uuid: "04f008148cea4506912c302621cfd162",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/04f008148cea4506912c302621cfd162.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5038,
          name: "Fortune Feast Fusion Reels",
          uuid: "2199e37e4f62fbd50b9ea2e0708db5e8509f4dd1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2199e37e4f62fbd50b9ea2e0708db5e8509f4dd1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5039,
          name: "Fortune Feast Fusion Reels Mobile",
          uuid: "1365d5bda1ea8768ab5a5b3a38e108a799baa283",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1365d5bda1ea8768ab5a5b3a38e108a799baa283.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5523,
          name: "Fortune Funland",
          uuid: "22ea559d113946ff8c439bc9c77966ba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/22ea559d113946ff8c439bc9c77966ba.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5522,
          name: "Fortune Funland Mobile",
          uuid: "f3a61bad096c44f88be404a2cbc68451",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f3a61bad096c44f88be404a2cbc68451.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4780,
          name: "Fortune Ganesha",
          uuid: "ace09ac1709948c8becf68c661a1a97d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ace09ac1709948c8becf68c661a1a97d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4781,
          name: "Fortune Ganesha Mobile",
          uuid: "0d413a8a20e54982baa123666458d4d9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0d413a8a20e54982baa123666458d4d9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4333,
          name: "Fortune God",
          uuid: "38a50725069449a5a913cdb1878065fc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/38a50725069449a5a913cdb1878065fc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4332,
          name: "Fortune God Mobile",
          uuid: "e307a8a953d44de5b9927f1b6f80ae41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e307a8a953d44de5b9927f1b6f80ae41.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4517,
          name: "Fortune Lions",
          uuid: "9cd5a9301da14c3abc1cb88f1087994e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9cd5a9301da14c3abc1cb88f1087994e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4516,
          name: "Fortune Lions Mobile",
          uuid: "f34d0420354c47199f74de2cc06dcb58",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f34d0420354c47199f74de2cc06dcb58.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4305,
          name: "Fortune Piggy Bank",
          uuid: "811636704a15484a8a5fcd433298ed88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/811636704a15484a8a5fcd433298ed88.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4304,
          name: "Fortune Piggy Bank Mobile",
          uuid: "5c9e95935fe440f5978600698099e328",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5c9e95935fe440f5978600698099e328.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5098,
          name: "Fortune Rabbit",
          uuid: "4b62147dcf7049338b86a16106faf8ee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4b62147dcf7049338b86a16106faf8ee.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5099,
          name: "Fortune Rabbit Mobile",
          uuid: "6edd8b3aad9f4a96bd14b258408be7c5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6edd8b3aad9f4a96bd14b258408be7c5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5174,
          name: "Fortune Star",
          uuid: "eb54af20df1b4c109e80589f3e62856d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/eb54af20df1b4c109e80589f3e62856d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5175,
          name: "Fortune Star Mobile",
          uuid: "6db060c8137a456195a9e401cfc0c6b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6db060c8137a456195a9e401cfc0c6b0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5517,
          name: "Fortune Wheel",
          uuid: "aead4b1e0b9244cea902fe28e56bee9c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/aead4b1e0b9244cea902fe28e56bee9c.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5516,
          name: "Fortune Wheel Mobile",
          uuid: "1d762938982f4d44a73c5104765edfdc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1d762938982f4d44a73c5104765edfdc.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5576,
          name: "Fortune's Number",
          uuid: "cd032edd270f449fbf6c9a47958432a0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cd032edd270f449fbf6c9a47958432a0.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5577,
          name: "Fortune's Number Mobile",
          uuid: "e45ff0c880064864a6d0cc6dcfddc94d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e45ff0c880064864a6d0cc6dcfddc94d.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4695,
          name: "Four Beauties",
          uuid: "3383e6de3c124f74af101d56aec32f63",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3383e6de3c124f74af101d56aec32f63.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4694,
          name: "Four Beauties Mobile",
          uuid: "7ee69161ef7a41778ee859a4ee5eb6f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7ee69161ef7a41778ee859a4ee5eb6f7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5644,
          name: "Four Seasons",
          uuid: "9ad658f8ecbc486f988ce4f8be81904f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9ad658f8ecbc486f988ce4f8be81904f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5645,
          name: "Four Seasons Mobile",
          uuid: "a9bc437466e847d08637b6507e21babc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a9bc437466e847d08637b6507e21babc.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5220,
          name: "Foxy Mama",
          uuid: "1499d3fa43ea4635bd46c31a61fb1ae1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1499d3fa43ea4635bd46c31a61fb1ae1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5221,
          name: "Foxy Mama Mobile",
          uuid: "d085fbdab8ba4fb5abb8f821c41716d8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d085fbdab8ba4fb5abb8f821c41716d8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4501,
          name: "Frankenstein",
          uuid: "e4b8caa3409c44a4afc3d551b2ff1406",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e4b8caa3409c44a4afc3d551b2ff1406.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4500,
          name: "Frankenstein Mobile",
          uuid: "485c386b0b4b442588dddc7607c14a04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/485c386b0b4b442588dddc7607c14a04.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5560,
          name: "Frogs Ball",
          uuid: "3b2936c96a1c4972b501fe3ce1c5dbe4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3b2936c96a1c4972b501fe3ce1c5dbe4.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5561,
          name: "Frogs Ball Mobile",
          uuid: "3f4d280a206b4a17ad14b458f711e5a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3f4d280a206b4a17ad14b458f711e5a9.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4739,
          name: "Fruit Mountain",
          uuid: "c06ac51a026046ba8daf965d993a0101",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c06ac51a026046ba8daf965d993a0101.png",
          type: "fruit game",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4738,
          name: "Fruit Mountain Mobile",
          uuid: "f3bbd52fde9f4403b78c351d5ddde561",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f3bbd52fde9f4403b78c351d5ddde561.png",
          type: "fruit game",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4763,
          name: "Fruit Party",
          uuid: "f7782554f02240b49f5e1af8152c2671",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f7782554f02240b49f5e1af8152c2671.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4762,
          name: "Fruit Party Mobile",
          uuid: "5ba72166ebb64b069a7430a6dfe621a6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5ba72166ebb64b069a7430a6dfe621a6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4870,
          name: "Fu Lu Shou",
          uuid: "7c15feb22109606c732eeadd8f3232f0ef86d9bf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7c15feb22109606c732eeadd8f3232f0ef86d9bf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4871,
          name: "Fu Lu Shou Mobile",
          uuid: "6e7722ed0b6794928212aa74ed60fdc3596d44a3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6e7722ed0b6794928212aa74ed60fdc3596d44a3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5612,
          name: "Full Throttle",
          uuid: "5f3ee91b6dac4fbd85178048805a527f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5f3ee91b6dac4fbd85178048805a527f.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5613,
          name: "Full Throttle Mobile",
          uuid: "4526b8ef1f2f47a882992c3ee66d95da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4526b8ef1f2f47a882992c3ee66d95da.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5550,
          name: "Galactic Bounty Hunter",
          uuid: "94becb90ee234e5a85c21a10d45f932a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/94becb90ee234e5a85c21a10d45f932a.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5551,
          name: "Galactic Bounty Hunter Mobile",
          uuid: "0324258bbbd14e64a67aeac915e2b719",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0324258bbbd14e64a67aeac915e2b719.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5234,
          name: "Gangster",
          uuid: "68fbf3de93e04573969ef51fd789edb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/68fbf3de93e04573969ef51fd789edb3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5235,
          name: "Gangster Mobile",
          uuid: "48d69a9dd3844c3c9a292280d8851cb4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/48d69a9dd3844c3c9a292280d8851cb4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5080,
          name: "Geisha",
          uuid: "639d02f16e774c3a9e50f937866fa7bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/639d02f16e774c3a9e50f937866fa7bf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5081,
          name: "Geisha Mobile",
          uuid: "e14ee297efe84757908dbaeb6aa69141",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e14ee297efe84757908dbaeb6aa69141.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4445,
          name: "Genghis Khan",
          uuid: "7d0a1872a14446b88b5e6c8f8d41270d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7d0a1872a14446b88b5e6c8f8d41270d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4444,
          name: "Genghis Khan Mobile",
          uuid: "a439e59358dd4e4f913ca712e1928353",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a439e59358dd4e4f913ca712e1928353.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4946,
          name: "Ghost Festival",
          uuid: "aa89c274d362e447a44ebb58e8f86e7f92c81348",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aa89c274d362e447a44ebb58e8f86e7f92c81348.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4947,
          name: "Ghost Festival Mobile",
          uuid: "fa58b202eea6a5bd9a84381bbe0255de253e964d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fa58b202eea6a5bd9a84381bbe0255de253e964d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5404,
          name: "Ghost Hunter",
          uuid: "91fc3d4ef7d2486b943915ce8cd1cc45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/91fc3d4ef7d2486b943915ce8cd1cc45.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5405,
          name: "Ghost Hunter Mobile",
          uuid: "3112d880b9f84772a763d37f92f84082",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3112d880b9f84772a763d37f92f84082.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4417,
          name: "Ghostbuster",
          uuid: "451fd8c2a06543049ad4ffa1555e6bcc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/451fd8c2a06543049ad4ffa1555e6bcc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4416,
          name: "Ghostbuster Mobile",
          uuid: "d647b549382e41e0a6da88cea85f18be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d647b549382e41e0a6da88cea85f18be.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4954,
          name: "Ghosts Fortune",
          uuid: "c2729da4178451d67a3524ff1f721ad708f36833",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c2729da4178451d67a3524ff1f721ad708f36833.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4955,
          name: "Ghosts Fortune Mobile",
          uuid: "877a96975b90089fee07214bba1ecd89822712ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/877a96975b90089fee07214bba1ecd89822712ae.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5536,
          name: "Ghosts Night Walk Lock 2 Spin",
          uuid: "1410717b69c14eabab15011bee70b464",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1410717b69c14eabab15011bee70b464.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5537,
          name: "Ghosts Night Walk Lock 2 Spin Mobile",
          uuid: "a8ea102c402249d59306c65463d55f05",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a8ea102c402249d59306c65463d55f05.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4924,
          name: "Giant Fish Hunter",
          uuid: "4fd1495b4985a8c91485563935b9fb146ad3777b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4fd1495b4985a8c91485563935b9fb146ad3777b.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4925,
          name: "Giant Fish Hunter Mobile",
          uuid: "5e19fe5fa2614eb5dbe2bdfdaf9e71c121101931",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5e19fe5fa2614eb5dbe2bdfdaf9e71c121101931.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4437,
          name: "Giants",
          uuid: "f8f06a23ade0421380e14f75ede23c8a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f8f06a23ade0421380e14f75ede23c8a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4436,
          name: "Giants Mobile",
          uuid: "46a909d65dce4fe98b0445a5d7eb280e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/46a909d65dce4fe98b0445a5d7eb280e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4427,
          name: "Glacial Epoch",
          uuid: "59dae4813ce14536bbde22790620ead6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/59dae4813ce14536bbde22790620ead6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4426,
          name: "Glacial Epoch Mobile",
          uuid: "ec390bbcc62e49ec9f27f280ed6a7759",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ec390bbcc62e49ec9f27f280ed6a7759.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4671,
          name: "Glass Slipper",
          uuid: "f04c2492b2194a188e00022c14fb781e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f04c2492b2194a188e00022c14fb781e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4670,
          name: "Glass Slipper Mobile",
          uuid: "68af888538214a80ab5c3fada3cc7bb7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/68af888538214a80ab5c3fada3cc7bb7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5246,
          name: "Go Escape",
          uuid: "f6e5aaa0df2d400587bf87505434ec18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f6e5aaa0df2d400587bf87505434ec18.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5247,
          name: "Go Escape Mobile",
          uuid: "4689703287e142579a71f4ac6dd0a06b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4689703287e142579a71f4ac6dd0a06b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5515,
          name: "Go Go Fishing",
          uuid: "3d233d892ecd4414a5da8150efcaede9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3d233d892ecd4414a5da8150efcaede9.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5514,
          name: "Go Go Fishing Mobile",
          uuid: "5648741956cd4e21abbe6c96d0c84b31",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5648741956cd4e21abbe6c96d0c84b31.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4912,
          name: "Go Go Magic Dog",
          uuid: "8362d0d9ca6b04454a1c46560e85e62fe0fab7ec",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8362d0d9ca6b04454a1c46560e85e62fe0fab7ec.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4913,
          name: "Go Go Magic Dog Mobile",
          uuid: "a98a2a20b1e70a159a4dfd812f0ff873a18cd6da",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a98a2a20b1e70a159a4dfd812f0ff873a18cd6da.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5166,
          name: "God Of Gamblers",
          uuid: "59de4dad34e94bb5a87086e4e85a60ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/59de4dad34e94bb5a87086e4e85a60ad.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5167,
          name: "God Of Gamblers Mobile",
          uuid: "0764128ac2b74e218a4dda9e9c4d1f4c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0764128ac2b74e218a4dda9e9c4d1f4c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4497,
          name: "God of Love",
          uuid: "f98ada38d34e45ad99eba108b6adba3d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f98ada38d34e45ad99eba108b6adba3d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4496,
          name: "God of Love Mobile",
          uuid: "0681ddd7b59c465cb50cb09a93bd0b27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0681ddd7b59c465cb50cb09a93bd0b27.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5308,
          name: "Gokudo Bentou",
          uuid: "bbfa924152ab4c04b851c48e5b31f40c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bbfa924152ab4c04b851c48e5b31f40c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5309,
          name: "Gokudo Bentou Mobile",
          uuid: "65502dc4356549b48813f5a6cc53247a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/65502dc4356549b48813f5a6cc53247a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4507,
          name: "Gold Magic ",
          uuid: "1a53c2efc9d04689bd2d2b3acd596a18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1a53c2efc9d04689bd2d2b3acd596a18.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4506,
          name: "Gold Magic  Mobile",
          uuid: "ccce669af8cf4a76b1e0b7e9762a14ae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ccce669af8cf4a76b1e0b7e9762a14ae.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5439,
          name: "Golden And Silver Horn",
          uuid: "5ce557bd798c75b8f0ec275cfb3c78751afafdb8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5ce557bd798c75b8f0ec275cfb3c78751afafdb8.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5438,
          name: "Golden And Silver Horn Mobile",
          uuid: "29055dda973bc3c957c3553a9dd8a8b8d668c9e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/29055dda973bc3c957c3553a9dd8a8b8d668c9e7.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4285,
          name: "Golden Ball",
          uuid: "85a6346689c84b01b4b586102fbeb746",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/85a6346689c84b01b4b586102fbeb746.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4284,
          name: "Golden Ball Mobile",
          uuid: "8bf68cbf17fe43d88125058ad21ac103",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8bf68cbf17fe43d88125058ad21ac103.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4349,
          name: "Golden Bull",
          uuid: "98df7821998244889ed4e0fb574081b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/98df7821998244889ed4e0fb574081b2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4348,
          name: "Golden Bull Mobile",
          uuid: "4d0a32bb3af2447cb975324286a39c4c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4d0a32bb3af2447cb975324286a39c4c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5479,
          name: "Golden Crab",
          uuid: "b2c1cb145bbe8480aadee76f9ca2ea163783fe65",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b2c1cb145bbe8480aadee76f9ca2ea163783fe65.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5478,
          name: "Golden Crab Mobile",
          uuid: "e5f97d2a33a10e1b0a5d8616dd28a624efee59e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e5f97d2a33a10e1b0a5d8616dd28a624efee59e3.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4265,
          name: "Golden Dragon",
          uuid: "4176204002ac4d9493495928133306bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4176204002ac4d9493495928133306bf.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4264,
          name: "Golden Dragon Mobile",
          uuid: "d7cc265e81574b4f94d03e366532edd3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d7cc265e81574b4f94d03e366532edd3.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4806,
          name: "Golden Fish",
          uuid: "191e6af84e6846fe8f42329b2a43b637",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/191e6af84e6846fe8f42329b2a43b637.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5154,
          name: "Golden Fish Hunter",
          uuid: "45cca38cbba54054af9b2ecc7c9debda",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/45cca38cbba54054af9b2ecc7c9debda.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5155,
          name: "Golden Fish Hunter Mobile",
          uuid: "4a6d3353f8ce4e5abbc2d03d85c1b189",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4a6d3353f8ce4e5abbc2d03d85c1b189.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4807,
          name: "Golden Fish Mobile",
          uuid: "d0f9a884effd4572a2e741779e9c41b5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d0f9a884effd4572a2e741779e9c41b5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4637,
          name: "Golden Shanghai",
          uuid: "06c21efe3bcd4c8597e619d74765b606",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/06c21efe3bcd4c8597e619d74765b606.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4636,
          name: "Golden Shanghai Mobile",
          uuid: "6781cfba572045eeac852edbc09eab72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6781cfba572045eeac852edbc09eab72.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4900,
          name: "Golden777",
          uuid: "c8dca3f5cef9b03fe4a0b0c0bfd79520a1d161e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c8dca3f5cef9b03fe4a0b0c0bfd79520a1d161e4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4901,
          name: "Golden777 Mobile",
          uuid: "cc9476849ebb8fc7e1609e915a7ab57c3864e804",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cc9476849ebb8fc7e1609e915a7ab57c3864e804.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5312,
          name: "Golf Master",
          uuid: "a92b0307f64747efb378d4df64c3aee2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a92b0307f64747efb378d4df64c3aee2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5313,
          name: "Golf Master Mobile",
          uuid: "2c8571d0aff14c2c8da9e34ee8ed8a2a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2c8571d0aff14c2c8da9e34ee8ed8a2a.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5552,
          name: "Gorilla Style",
          uuid: "6f9e43667c58409fa61fc8c5d1640a2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6f9e43667c58409fa61fc8c5d1640a2d.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5553,
          name: "Gorilla Style Mobile",
          uuid: "f7d3fc76b91749b68e01c727c5d8943c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f7d3fc76b91749b68e01c727c5d8943c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5018,
          name: "Great Courtyard",
          uuid: "a7e7a1d59d6a3ebb8ba4025a84f311da24d9d6ac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a7e7a1d59d6a3ebb8ba4025a84f311da24d9d6ac.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5019,
          name: "Great Courtyard Mobile",
          uuid: "fcc7bab478fc816a526e1b115ad159f03e1f1dcd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fcc7bab478fc816a526e1b115ad159f03e1f1dcd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5090,
          name: "Great Doctor",
          uuid: "e68790cd72eb4bf4990c74ac3697a0e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e68790cd72eb4bf4990c74ac3697a0e8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5091,
          name: "Great Doctor Mobile",
          uuid: "fb8314fe64784b0eb3103fdb3a5d5f1a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fb8314fe64784b0eb3103fdb3a5d5f1a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5106,
          name: "Great Luck",
          uuid: "df789376137948f9888257798d76c356",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/df789376137948f9888257798d76c356.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5107,
          name: "Great Luck Mobile",
          uuid: "44d49f6cff8641829de2948cd3758860",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/44d49f6cff8641829de2948cd3758860.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5270,
          name: "Greedy Night",
          uuid: "5d02826b49ad43f085b7a89adf11375c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5d02826b49ad43f085b7a89adf11375c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5271,
          name: "Greedy Night Mobile",
          uuid: "5bb11401be334ed08629cc08e601fd30",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5bb11401be334ed08629cc08e601fd30.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5224,
          name: "Green Party",
          uuid: "c3ee22147b3b4397be3ba16885954a3b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c3ee22147b3b4397be3ba16885954a3b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5225,
          name: "Green Party Mobile",
          uuid: "dff554db59064eb890d6836881579ee8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/dff554db59064eb890d6836881579ee8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5184,
          name: "Guan Yun Chang",
          uuid: "53202e5efde546018f3f9f325af31163",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/53202e5efde546018f3f9f325af31163.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5185,
          name: "Guan Yun Chang Mobile",
          uuid: "a418298f3cff4b7a96e340045b26b406",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a418298f3cff4b7a96e340045b26b406.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5320,
          name: "Gypsy",
          uuid: "89a8797309444d2e8d2b5fb88863c44c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/89a8797309444d2e8d2b5fb88863c44c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5321,
          name: "Gypsy Mobile",
          uuid: "20471edf0392449f968ad2caf94c6b2f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/20471edf0392449f968ad2caf94c6b2f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5542,
          name: "Hail the Judge",
          uuid: "22c40ac350ef4ec685e1bdd95d443aa4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/22c40ac350ef4ec685e1bdd95d443aa4.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5543,
          name: "Hail the Judge Mobile",
          uuid: "f1c8d2e557684848b838ca9bd03b5b80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f1c8d2e557684848b838ca9bd03b5b80.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4982,
          name: "Hamlet",
          uuid: "ef2df0597ec372c7d21732a3cfb8c6167198fee6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ef2df0597ec372c7d21732a3cfb8c6167198fee6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4983,
          name: "Hamlet Mobile",
          uuid: "ab6a9ca95a222b5cf83dfa891ed979edaa63ce6b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ab6a9ca95a222b5cf83dfa891ed979edaa63ce6b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5254,
          name: "Hanabi",
          uuid: "b564b0b9952a450890abb5923d959c62",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b564b0b9952a450890abb5923d959c62.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5255,
          name: "Hanabi Mobile",
          uuid: "974e31cf50d54aa8996fb4796ae9a79a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/974e31cf50d54aa8996fb4796ae9a79a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4908,
          name: "Hansel And Gretel",
          uuid: "b30d09d0e2f73ea75fc4aea56bac179044b54205",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b30d09d0e2f73ea75fc4aea56bac179044b54205.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4909,
          name: "Hansel And Gretel Mobile",
          uuid: "f8a8829380178f65f9b90c4e93953df106810b41",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f8a8829380178f65f9b90c4e93953df106810b41.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5214,
          name: "Happy Animal Farm",
          uuid: "0832256e214e4938a886d1dcce3bfec0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0832256e214e4938a886d1dcce3bfec0.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5215,
          name: "Happy Animal Farm Mobile",
          uuid: "d07de618936f4706b884463e25d209a6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d07de618936f4706b884463e25d209a6.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4974,
          name: "Happy Food Hunter",
          uuid: "d81b37c6a2557ba4c4eeb0112eb1d718ed17a101",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d81b37c6a2557ba4c4eeb0112eb1d718ed17a101.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4975,
          name: "Happy Food Hunter Mobile",
          uuid: "4f6ac746f24474de3f0b16fd7c1c3dfa2610eab9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4f6ac746f24474de3f0b16fd7c1c3dfa2610eab9.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4880,
          name: "Happy Indian Chef",
          uuid: "1bcac60b8233d48189d623e803e8a2954a191164",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1bcac60b8233d48189d623e803e8a2954a191164.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4881,
          name: "Happy Indian Chef Mobile",
          uuid: "8a38ead17a622559bc99a47b03b241d49c9292b9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8a38ead17a622559bc99a47b03b241d49c9292b9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4978,
          name: "Hare VS Tortoise",
          uuid: "70d196ed618f550d440e8506a2de0afbb017202f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/70d196ed618f550d440e8506a2de0afbb017202f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4979,
          name: "Hare VS Tortoise Mobile",
          uuid: "d39d6eeba095dd0077c6ced0e25aecbca6776229",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d39d6eeba095dd0077c6ced0e25aecbca6776229.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4477,
          name: "Hat Seller",
          uuid: "18e670f196164970a7bf37e9a88d18dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/18e670f196164970a7bf37e9a88d18dd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4476,
          name: "Hat Seller Mobile",
          uuid: "b92037968f294b5aa7430b0feb67863d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b92037968f294b5aa7430b0feb67863d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4449,
          name: "Heng and Ha",
          uuid: "c49ef887c0ca49e29788279efc378efd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c49ef887c0ca49e29788279efc378efd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4448,
          name: "Heng and Ha Mobile",
          uuid: "c7bf6a8f1df94df887d065c06487b8dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c7bf6a8f1df94df887d065c06487b8dd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5010,
          name: "Heroines Of The Marsh",
          uuid: "eedad8a794c747c3a398b26736d22577",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/eedad8a794c747c3a398b26736d22577.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5011,
          name: "Heroines Of The Marsh Mobile",
          uuid: "79f86969a9e644779ee9bcd86ece9a49",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/79f86969a9e644779ee9bcd86ece9a49.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5008,
          name: "Hip Hop",
          uuid: "1cbc20f3bf2043088c05d4d2dd2c86f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1cbc20f3bf2043088c05d4d2dd2c86f0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5009,
          name: "Hip Hop Mobile",
          uuid: "8839a55d106740299fb1b954c2355cee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8839a55d106740299fb1b954c2355cee.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5507,
          name: "Hole In One",
          uuid: "e6d5a4e990024c2b8ebc1f96618369cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e6d5a4e990024c2b8ebc1f96618369cc.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5506,
          name: "Hole In One Mobile",
          uuid: "2f385d67bde645009047b3281c111a9d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2f385d67bde645009047b3281c111a9d.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5072,
          name: "Holiday Carol",
          uuid: "4aa12a19de9b4c1e8ab6e4d7a8a03815",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4aa12a19de9b4c1e8ab6e4d7a8a03815.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5073,
          name: "Holiday Carol Mobile",
          uuid: "cee9f4f60517442bb3ee4699dfcf583a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cee9f4f60517442bb3ee4699dfcf583a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4842,
          name: "Holy Beast",
          uuid: "e9e0e28e7c4044598ab3f5064677b658",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e9e0e28e7c4044598ab3f5064677b658.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4843,
          name: "Holy Beast Mobile",
          uuid: "11218b4214a74fe381122a1bcedc1cec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/11218b4214a74fe381122a1bcedc1cec.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5264,
          name: "Home Run X",
          uuid: "9fa155ae250544f98697004d6983367f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9fa155ae250544f98697004d6983367f.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5265,
          name: "Home Run X Mobile",
          uuid: "701962877c8f4363b28b254989d1b2c3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/701962877c8f4363b28b254989d1b2c3.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4455,
          name: "Honey Money",
          uuid: "116b34dc1208486ba78feca66abf99e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/116b34dc1208486ba78feca66abf99e8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4454,
          name: "Honey Money Mobile",
          uuid: "ca137e4be781450480dee1d85f71a1e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ca137e4be781450480dee1d85f71a1e5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4629,
          name: "Horoscope",
          uuid: "b832560aca6d40849c0c419f47f194a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b832560aca6d40849c0c419f47f194a9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4628,
          name: "Horoscope Mobile",
          uuid: "05d746ec762a4a8ea61262577d2b198a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/05d746ec762a4a8ea61262577d2b198a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5356,
          name: "Hot Coin",
          uuid: "638f05823691435c84a66354a707924b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/638f05823691435c84a66354a707924b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5357,
          name: "Hot Coin Mobile",
          uuid: "3331dc006cd645d387e2756554efc558",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3331dc006cd645d387e2756554efc558.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4443,
          name: "Hou Yi",
          uuid: "df6a84f986ec4c7aae6515cfaa966e3c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/df6a84f986ec4c7aae6515cfaa966e3c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4442,
          name: "Hou Yi Mobile",
          uuid: "7fe7d03050fc4f5994823e095312b75b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7fe7d03050fc4f5994823e095312b75b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4371,
          name: "Hu Hu Fighting",
          uuid: "12ece66cb7a949959170e4d386392f97",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/12ece66cb7a949959170e4d386392f97.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4370,
          name: "Hu Hu Fighting Mobile",
          uuid: "f7ae8e78e04f406896acdd8fbed43e0b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f7ae8e78e04f406896acdd8fbed43e0b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4473,
          name: "Hu Yeh",
          uuid: "af8639fd19b04abd87c5ef4f102c6df8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/af8639fd19b04abd87c5ef4f102c6df8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4472,
          name: "Hu Yeh Mobile",
          uuid: "0814f24830034c26b2633a28e258a94d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0814f24830034c26b2633a28e258a94d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4737,
          name: "Hua Mulan",
          uuid: "df5761f6b4dc49a3a469efbb9b8b660e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/df5761f6b4dc49a3a469efbb9b8b660e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4736,
          name: "Hua Mulan Mobile",
          uuid: "4915f863ec4c45a4baf2c701fc0b7b41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4915f863ec4c45a4baf2c701fc0b7b41.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5012,
          name: "Hungry Shark",
          uuid: "ba92e1e5f2ab2d9dfe97fbce8e55c80cb3080aae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ba92e1e5f2ab2d9dfe97fbce8e55c80cb3080aae.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5118,
          name: "Hungry Shark Cthulhu",
          uuid: "fa62800c964145f3ba41108bc26159eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fa62800c964145f3ba41108bc26159eb.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5119,
          name: "Hungry Shark Cthulhu Mobile",
          uuid: "a74a308d9efe48ef9ae38bd5faa67d96",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a74a308d9efe48ef9ae38bd5faa67d96.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5013,
          name: "Hungry Shark Mobile",
          uuid: "a5dffce66d0b7d82eadfc9e23a43875a2e3fec52",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a5dffce66d0b7d82eadfc9e23a43875a2e3fec52.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4922,
          name: "Hunter Helibu",
          uuid: "e6c6baf8565e8155d51a4cbdbf78beedfc8ab233",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e6c6baf8565e8155d51a4cbdbf78beedfc8ab233.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4923,
          name: "Hunter Helibu Mobile",
          uuid: "0f57669d146757c18d60bcbfb6806fc53499c5a2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0f57669d146757c18d60bcbfb6806fc53499c5a2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5216,
          name: "Hypersonic X",
          uuid: "b038aa6aab7640d684b64a8f3593802a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b038aa6aab7640d684b64a8f3593802a.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5217,
          name: "Hypersonic X Mobile",
          uuid: "c426fff51712444ea9616a6cec55f092",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c426fff51712444ea9616a6cec55f092.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5342,
          name: "Ice Cream Truck",
          uuid: "c563750d225941c68668ed04a048b3f2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c563750d225941c68668ed04a048b3f2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5343,
          name: "Ice Cream Truck Mobile",
          uuid: "598458d0c0ab4bdc84035e20abf7eef9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/598458d0c0ab4bdc84035e20abf7eef9.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5306,
          name: "Iduns Garden",
          uuid: "eb3c3bae73204134b048b85b47225ab3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/eb3c3bae73204134b048b85b47225ab3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5307,
          name: "Iduns Garden Mobile",
          uuid: "28647f60e1da47a48fc6b0bd0ab06816",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/28647f60e1da47a48fc6b0bd0ab06816.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4421,
          name: "Imperial Girls",
          uuid: "72e2769e90a0411ebcca8351f63b277b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/72e2769e90a0411ebcca8351f63b277b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4420,
          name: "Imperial Girls Mobile",
          uuid: "7bf5cc6f8b81497ca032977618fb6167",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7bf5cc6f8b81497ca032977618fb6167.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5204,
          name: "Impossible X",
          uuid: "17d97717f87d49f8a990f576a457ceb9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/17d97717f87d49f8a990f576a457ceb9.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5205,
          name: "Impossible X Mobile",
          uuid: "395b232806cb40e08c63b474807e7fa3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/395b232806cb40e08c63b474807e7fa3.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5442,
          name: "Inca Lost Treasure",
          uuid: "170d1050d7b54a909e391585a8517497",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/170d1050d7b54a909e391585a8517497.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5443,
          name: "Inca Lost Treasure Mobile",
          uuid: "4e77948f3fae4318999847136a524ec0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4e77948f3fae4318999847136a524ec0.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5240,
          name: "Infinity X",
          uuid: "0bcfad1a1912497d85445f54b5c65aab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0bcfad1a1912497d85445f54b5c65aab.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5241,
          name: "Infinity X Mobile",
          uuid: "20e4bff3e1dd4ff6bce0ab0460b7d897",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/20e4bff3e1dd4ff6bce0ab0460b7d897.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5132,
          name: "Irish Coins",
          uuid: "553000ad92ff4b7aa73eac11622b1616",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/553000ad92ff4b7aa73eac11622b1616.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5133,
          name: "Irish Coins Mobile",
          uuid: "871af472448a4834af8dec8f3dd25095",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/871af472448a4834af8dec8f3dd25095.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4926,
          name: "Iron Chicken Hunter",
          uuid: "ba6296d92ccbc3c2a79601825d13f63abb96b41c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ba6296d92ccbc3c2a79601825d13f63abb96b41c.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4927,
          name: "Iron Chicken Hunter Mobile",
          uuid: "19be8eac412e40b4e73e2b69ab4d1ff403592c3e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/19be8eac412e40b4e73e2b69ab4d1ff403592c3e.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5382,
          name: "Iron Hero",
          uuid: "0c82e9b448aa4621b6fb1227cb601712",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0c82e9b448aa4621b6fb1227cb601712.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5383,
          name: "Iron Hero Mobile",
          uuid: "597ada4221a7443d9b04c2709e174766",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/597ada4221a7443d9b04c2709e174766.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5158,
          name: "James Bond",
          uuid: "4bec9b999ba041f3a852d66c84099f9e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4bec9b999ba041f3a852d66c84099f9e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5159,
          name: "James Bond Mobile",
          uuid: "f3a6e573517045308b1f553472af8600",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f3a6e573517045308b1f553472af8600.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4850,
          name: "Japanese 7 Heroes",
          uuid: "f37d668df7c7492183e721a240652c26",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f37d668df7c7492183e721a240652c26.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4851,
          name: "Japanese 7 Heroes Mobile",
          uuid: "c3858de1979346138869b899d9801b91",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c3858de1979346138869b899d9801b91.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4615,
          name: "Jellymania",
          uuid: "b480c97d241f444280774dd0c340737b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b480c97d241f444280774dd0c340737b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4614,
          name: "Jellymania Mobile",
          uuid: "ff5ea6f5acc54a639e6b66105a8e5b0d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ff5ea6f5acc54a639e6b66105a8e5b0d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4359,
          name: "Jingwei",
          uuid: "3ff9f79692034f5eaeb02cf885ddb6a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3ff9f79692034f5eaeb02cf885ddb6a1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4358,
          name: "Jingwei Mobile",
          uuid: "9f5f065f5c514415839618e51e9c50b6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9f5f065f5c514415839618e51e9c50b6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4786,
          name: "Joker Fruit",
          uuid: "c93cf1bdf9994cdaab0039f1ff39caab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c93cf1bdf9994cdaab0039f1ff39caab.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4787,
          name: "Joker Fruit Mobile",
          uuid: "3e2b820bdff24a25b7cd619e5787db1a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3e2b820bdff24a25b7cd619e5787db1a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4569,
          name: "Joker Slot",
          uuid: "63e006cb99904a58a12126a90b11042b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/63e006cb99904a58a12126a90b11042b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4568,
          name: "Joker Slot Mobile",
          uuid: "9862de3e1f294ee680191ee1b6895497",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9862de3e1f294ee680191ee1b6895497.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4325,
          name: "Journey to the West",
          uuid: "f4f5d25a46e94825b2e4f0d4a8f2985b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f4f5d25a46e94825b2e4f0d4a8f2985b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4324,
          name: "Journey to the West Mobile",
          uuid: "b44e40990a2b4ecf9dc7a9656c5b034a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b44e40990a2b4ecf9dc7a9656c5b034a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5352,
          name: "Jumping Fat Guy",
          uuid: "40ccd3f03c1b41f8bcc0239cb3b5024f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/40ccd3f03c1b41f8bcc0239cb3b5024f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5353,
          name: "Jumping Fat Guy Mobile",
          uuid: "36fe62e7d80c49ebb1dad218c0dfe8fb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/36fe62e7d80c49ebb1dad218c0dfe8fb.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5630,
          name: "Jumping Frog",
          uuid: "6f16c9459fdc4c16a27a449060af8032",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6f16c9459fdc4c16a27a449060af8032.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5631,
          name: "Jumping Frog Mobile",
          uuid: "dc546d3f033348048790e70a43a06b08",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/dc546d3f033348048790e70a43a06b08.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4691,
          name: "Jungle",
          uuid: "b55babd53b5346008f0fd87133ac4b45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b55babd53b5346008f0fd87133ac4b45.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5588,
          name: "Jungle Adventure",
          uuid: "f2b444ee31f24c02a57eba849de8f53e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f2b444ee31f24c02a57eba849de8f53e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5589,
          name: "Jungle Adventure Mobile",
          uuid: "10a4b1b4fb7e47aeb9b5c2d2c39eedf3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/10a4b1b4fb7e47aeb9b5c2d2c39eedf3.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4690,
          name: "Jungle Mobile",
          uuid: "2ccafad9d4c3496d839d4944f0ac0804",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2ccafad9d4c3496d839d4944f0ac0804.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5566,
          name: "KA All Star Sports Day",
          uuid: "3391894ccd6e4ceb99f5a2c0010a2ece",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3391894ccd6e4ceb99f5a2c0010a2ece.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5567,
          name: "KA All Star Sports Day Mobile",
          uuid: "095effadda9341f18a4f0d06ebf650a2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/095effadda9341f18a4f0d06ebf650a2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4271,
          name: "KA Fish Hunter",
          uuid: "cec94918486c4de399efc78841c60724",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cec94918486c4de399efc78841c60724.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4270,
          name: "KA Fish Hunter Mobile",
          uuid: "c7cc9966b9b34148bf0985eafd32b9f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c7cc9966b9b34148bf0985eafd32b9f0.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5202,
          name: "KA Fish Party",
          uuid: "63f9ea5d682e428e9dc19b91ab71eb6c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/63f9ea5d682e428e9dc19b91ab71eb6c.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5203,
          name: "KA Fish Party Mobile",
          uuid: "f1f3ab79b8b9427b909999a9412fb593",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f1f3ab79b8b9427b909999a9412fb593.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5124,
          name: "Kai Feng Fu",
          uuid: "54d982c9b154405a8847ccf47057f434",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/54d982c9b154405a8847ccf47057f434.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5125,
          name: "Kai Feng Fu Mobile",
          uuid: "79661b4743c0462b85894125bf87fd2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/79661b4743c0462b85894125bf87fd2c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5590,
          name: "Kick Cash Llama",
          uuid: "99a492533e864c5f92328aa0bd75cbd8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/99a492533e864c5f92328aa0bd75cbd8.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5591,
          name: "Kick Cash Llama Mobile",
          uuid: "f424b4c7450f44bbb726b5172c8a4a32",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f424b4c7450f44bbb726b5172c8a4a32.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5498,
          name: "Kick Cash Panda",
          uuid: "b05707de17e44f1fad26edb976cc7763",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b05707de17e44f1fad26edb976cc7763.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5499,
          name: "Kick Cash Panda Mobile",
          uuid: "f2fb8bebbc5749dcb4dfdd1bf6099faa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f2fb8bebbc5749dcb4dfdd1bf6099faa.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5274,
          name: "Kick Pumpkin",
          uuid: "c00030827c67494b8711f3e37f71e770",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c00030827c67494b8711f3e37f71e770.jpg",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5275,
          name: "Kick Pumpkin Mobile",
          uuid: "9c27e42b08b34da0a05f06d3188c69c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9c27e42b08b34da0a05f06d3188c69c0.jpg",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4261,
          name: "King Octopus",
          uuid: "1ba1b920bc824c538e44756bed9f6667",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1ba1b920bc824c538e44756bed9f6667.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4260,
          name: "King Octopus Mobile",
          uuid: "4311c66bf0714574a439685c0c5c475b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4311c66bf0714574a439685c0c5c475b.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4782,
          name: "King Of Dragon",
          uuid: "8f13115d8ab340d097614e2cede2e670",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8f13115d8ab340d097614e2cede2e670.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4783,
          name: "King Of Dragon Mobile",
          uuid: "942bfb241d594b83b03e0872c527499a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/942bfb241d594b83b03e0872c527499a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5483,
          name: "King Of The God Zeus",
          uuid: "58b9aac89bbfeb34423157732e1f05b85d038594",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/58b9aac89bbfeb34423157732e1f05b85d038594.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5482,
          name: "King Of The God Zeus Mobile",
          uuid: "c20473042e817de3093c99877ad6550a9e73ff7f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c20473042e817de3093c99877ad6550a9e73ff7f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5519,
          name: "King's Sword",
          uuid: "300222c3c6b14c67a4a1e3bbcc5981da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/300222c3c6b14c67a4a1e3bbcc5981da.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5518,
          name: "King's Sword Mobile",
          uuid: "b18a2a35d06c4c289f7d86dfc64e7d5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b18a2a35d06c4c289f7d86dfc64e7d5c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4687,
          name: "Kitty Living",
          uuid: "e0fb843abff946ed80f6ca41b47deab7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e0fb843abff946ed80f6ca41b47deab7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4686,
          name: "Kitty Living Mobile",
          uuid: "e8fedeeb5e6a47548a110f6b83da16fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e8fedeeb5e6a47548a110f6b83da16fe.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4317,
          name: "KungFu Kaga",
          uuid: "3f75ad93f76b4ae6b50b25b73c8b8605",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3f75ad93f76b4ae6b50b25b73c8b8605.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4316,
          name: "KungFu Kaga Mobile",
          uuid: "5a8200678509493b951462c5e2e9cd61",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5a8200678509493b951462c5e2e9cd61.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4683,
          name: "KungFu Kash",
          uuid: "7a589a78fdba42b8b3f5f81bbd41591d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7a589a78fdba42b8b3f5f81bbd41591d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4682,
          name: "KungFu Kash Mobile",
          uuid: "9e72454a36654cf7b87a54c039d6f37e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9e72454a36654cf7b87a54c039d6f37e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5278,
          name: "La Tomatina",
          uuid: "9ae8dab2a615435dbaa621c24265c76c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9ae8dab2a615435dbaa621c24265c76c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5279,
          name: "La Tomatina Mobile",
          uuid: "6b53c63856cb4339addb8c1d3d3f61ac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6b53c63856cb4339addb8c1d3d3f61ac.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5126,
          name: "Lady KAKA",
          uuid: "c019dfb6372f46b0982f1d1651968610",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c019dfb6372f46b0982f1d1651968610.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5127,
          name: "Lady KAKA Mobile",
          uuid: "e9fd361c8ce046e7a5d1027414d3b9c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e9fd361c8ce046e7a5d1027414d3b9c6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5152,
          name: "Lady Racer",
          uuid: "24d02d05ae3348689cc8f1e08967d7b1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/24d02d05ae3348689cc8f1e08967d7b1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5153,
          name: "Lady Racer Mobile",
          uuid: "c2c34058f0894ab19e57ab53ccedf5fd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c2c34058f0894ab19e57ab53ccedf5fd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5050,
          name: "Land Of Dwarfs",
          uuid: "f8b56011c98046ca80bb2efcfd72834a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f8b56011c98046ca80bb2efcfd72834a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5051,
          name: "Land Of Dwarfs Mobile",
          uuid: "ec8e1f837cce4572b6f704c47223f555",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ec8e1f837cce4572b6f704c47223f555.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4765,
          name: "Lands of Gold",
          uuid: "7a7740052eae4fa3a3ec0c4642d00b9a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7a7740052eae4fa3a3ec0c4642d00b9a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4764,
          name: "Lands of Gold Mobile",
          uuid: "c234995e39c8486f9e9589b3afd6fbbd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c234995e39c8486f9e9589b3afd6fbbd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4347,
          name: "Lantern Festival",
          uuid: "de3c3b123f3846528f9a1dd9f7e63be8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/de3c3b123f3846528f9a1dd9f7e63be8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4346,
          name: "Lantern Festival Mobile",
          uuid: "8475544b5f7c49dabac4c0e428e7712b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8475544b5f7c49dabac4c0e428e7712b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4767,
          name: "Last Fantasy",
          uuid: "cd29982b0e26437a91e0ff0e9fe3c98f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cd29982b0e26437a91e0ff0e9fe3c98f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4766,
          name: "Last Fantasy Mobile",
          uuid: "22a7982aef554e9ebfc57e6a633a97f2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/22a7982aef554e9ebfc57e6a633a97f2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5078,
          name: "Lazy Rich",
          uuid: "cb078e3987f241da811abeac72c38eb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cb078e3987f241da811abeac72c38eb3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5079,
          name: "Lazy Rich Mobile",
          uuid: "1fa73754d18e4c14b4ce7085f616da2e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1fa73754d18e4c14b4ce7085f616da2e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4938,
          name: "Legend Of Akihiro",
          uuid: "c18547f9b3824596435d6acc3d60bd2f7a0a3a81",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c18547f9b3824596435d6acc3d60bd2f7a0a3a81.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4939,
          name: "Legend Of Akihiro Mobile",
          uuid: "92b8ea5f501560fe0149cf8979c8bc71b6f1d5ee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/92b8ea5f501560fe0149cf8979c8bc71b6f1d5ee.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4331,
          name: "Legend of Dragons",
          uuid: "113bfc47712e457a8170e58720bd955d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/113bfc47712e457a8170e58720bd955d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4330,
          name: "Legend of Dragons Mobile",
          uuid: "b3f27978b3934138b616f3559f873294",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b3f27978b3934138b616f3559f873294.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4902,
          name: "Legend Of Erlang",
          uuid: "d0f458effab8e6898556b6a864a8faf20c8c33b3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d0f458effab8e6898556b6a864a8faf20c8c33b3.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4903,
          name: "Legend Of Erlang Mobile",
          uuid: "8c319752a8075121d72fecddb59140af372d90b8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8c319752a8075121d72fecddb59140af372d90b8.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4834,
          name: "Legend of Fox Spirit",
          uuid: "f59197cc95124daca325acc417d0f9ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f59197cc95124daca325acc417d0f9ce.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4835,
          name: "Legend of Fox Spirit Mobile",
          uuid: "716f950d7a0e4e938614c1fbc30a79c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/716f950d7a0e4e938614c1fbc30a79c6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5146,
          name: "Legend Of Legends",
          uuid: "09c0f7c1de564b7d9096a01381776dd5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/09c0f7c1de564b7d9096a01381776dd5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5147,
          name: "Legend Of Legends Mobile",
          uuid: "ca68c38ae49048caa79f6ed142684389",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ca68c38ae49048caa79f6ed142684389.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4447,
          name: "Legend of Paladin",
          uuid: "8e05b8bd81a44327b96b490e5975a63c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8e05b8bd81a44327b96b490e5975a63c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4446,
          name: "Legend of Paladin Mobile",
          uuid: "e0e2cc943b5d495c9d874d25a4e105a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e0e2cc943b5d495c9d874d25a4e105a1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5350,
          name: "Legend Of Sword",
          uuid: "41492a4585c7478d8be54cecc5e92b68",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/41492a4585c7478d8be54cecc5e92b68.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5351,
          name: "Legend Of Sword Mobile",
          uuid: "e3a19e6a00314024b65412bec8fb0203",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e3a19e6a00314024b65412bec8fb0203.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4611,
          name: "Legend of the White Snake",
          uuid: "234d4ccb6fbf445db7f7411155a7e9f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/234d4ccb6fbf445db7f7411155a7e9f0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4610,
          name: "Legend of the White Snake Mobile",
          uuid: "c1b96f3862a2410d864558f7de0ead4e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c1b96f3862a2410d864558f7de0ead4e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5526,
          name: "Leprechaun and Animals",
          uuid: "ac1f3a3c57f14e2f82c7c6b6d92137e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ac1f3a3c57f14e2f82c7c6b6d92137e6.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5527,
          name: "Leprechaun and Animals Mobile",
          uuid: "34a70c15bca84aa4aa0e566fb53cc9a2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/34a70c15bca84aa4aa0e566fb53cc9a2.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4715,
          name: "Leprechauns",
          uuid: "286bf05762d743df83e469754421ddc3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/286bf05762d743df83e469754421ddc3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4714,
          name: "Leprechauns Mobile",
          uuid: "0f0756f2778e478a81ed08f1af6f8c40",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0f0756f2778e478a81ed08f1af6f8c40.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5232,
          name: "Lightning Goddess",
          uuid: "20c3c7478d3646c7874b756e0717fbd6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/20c3c7478d3646c7874b756e0717fbd6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5233,
          name: "Lightning Goddess Mobile",
          uuid: "bf70971cce254218aacdcd290e8b67d4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bf70971cce254218aacdcd290e8b67d4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5282,
          name: "Lilith",
          uuid: "0378aab0e618415eaf18c34f0e76f18c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0378aab0e618415eaf18c34f0e76f18c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5283,
          name: "Lilith Mobile",
          uuid: "a04a252f78f5457982396d66ba6b1eb1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a04a252f78f5457982396d66ba6b1eb1.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4499,
          name: "Lion Dance",
          uuid: "aa7c1e7069a845ee8f35aba1feabb036",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/aa7c1e7069a845ee8f35aba1feabb036.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4498,
          name: "Lion Dance Mobile",
          uuid: "36944aa54ec2409188186f74c3b11e9b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/36944aa54ec2409188186f74c3b11e9b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5423,
          name: "Lion on Ridge",
          uuid: "ddc9840f040c442cb17ed1fafb78e7c4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ddc9840f040c442cb17ed1fafb78e7c4.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5422,
          name: "Lion on Ridge Mobile",
          uuid: "af5347d6ef1346839d722d699439e359",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/af5347d6ef1346839d722d699439e359.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5136,
          name: "Lion VS Shark",
          uuid: "7e2b37f878fd41068497c9a814cd41c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7e2b37f878fd41068497c9a814cd41c6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5137,
          name: "Lion VS Shark Mobile",
          uuid: "b3c600a271034d25922fcc596e0dee36",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b3c600a271034d25922fcc596e0dee36.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5294,
          name: "Little Monk",
          uuid: "b0196fd9f30c44579b35e8eb8fe94241",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b0196fd9f30c44579b35e8eb8fe94241.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5295,
          name: "Little Monk Mobile",
          uuid: "b63a530b39144975bec41ce1ebbdb50f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b63a530b39144975bec41ce1ebbdb50f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4635,
          name: "Live Streaming Star",
          uuid: "1a56468e005340f1ac7f86f3e6fc024b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1a56468e005340f1ac7f86f3e6fc024b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4634,
          name: "Live Streaming Star Mobile",
          uuid: "9d60ecab28a54c82b66fd67647c90458",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9d60ecab28a54c82b66fd67647c90458.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4735,
          name: "Lost Realm",
          uuid: "0edf1b906a8d44cc8c3aa765e8bd9755",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0edf1b906a8d44cc8c3aa765e8bd9755.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4734,
          name: "Lost Realm Mobile",
          uuid: "34dc4e06097449eba5012cee17c63769",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/34dc4e06097449eba5012cee17c63769.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4511,
          name: "Lounge Club",
          uuid: "f31ce6ceb53a42bfb43388c22589775e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f31ce6ceb53a42bfb43388c22589775e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4510,
          name: "Lounge Club Mobile",
          uuid: "6f1ec9e79e10498d88ca2389100e7fee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6f1ec9e79e10498d88ca2389100e7fee.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5116,
          name: "Love Amongst War",
          uuid: "abca5b257b5346c091a8a8da532a412d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/abca5b257b5346c091a8a8da532a412d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5117,
          name: "Love Amongst War Mobile",
          uuid: "4298348433d7462095194d1fa9adf8e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4298348433d7462095194d1fa9adf8e7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5276,
          name: "Love Game",
          uuid: "cf86d67cb97946078191c79c0709cdb9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cf86d67cb97946078191c79c0709cdb9.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5277,
          name: "Love Game Mobile",
          uuid: "103170dd454949eca2cdaa91c57336dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/103170dd454949eca2cdaa91c57336dc.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4866,
          name: "Love In Memory",
          uuid: "10f9437a225dd7a559ae8435bd7f2dbc030178fc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/10f9437a225dd7a559ae8435bd7f2dbc030178fc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4867,
          name: "Love In Memory Mobile",
          uuid: "6208689879ad1467506e62ef62bd8bb793959e84",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6208689879ad1467506e62ef62bd8bb793959e84.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4289,
          name: "Luck88",
          uuid: "540e6d3e63204ea9b752f6849563848d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/540e6d3e63204ea9b752f6849563848d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4288,
          name: "Luck88 Mobile",
          uuid: "94318e9f1b034ebc86daf17d8aa1dc12",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/94318e9f1b034ebc86daf17d8aa1dc12.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5292,
          name: "Lucky Ailurus",
          uuid: "68dfb57837c44cf9945c4084734fbf1d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/68dfb57837c44cf9945c4084734fbf1d.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5293,
          name: "Lucky Ailurus Mobile",
          uuid: "d4af0f701a764a679b7cd4c2f5cd83a4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d4af0f701a764a679b7cd4c2f5cd83a4.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4399,
          name: "Lucky Casino",
          uuid: "d2ffc75fa13d4ac39fe2bfec41100684",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d2ffc75fa13d4ac39fe2bfec41100684.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4398,
          name: "Lucky Casino Mobile",
          uuid: "6d376c4994574e78a27fca862e06f40e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6d376c4994574e78a27fca862e06f40e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4375,
          name: "Lucky Cat",
          uuid: "dbbefc2e72fb4417991372ea1ed6f77e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/dbbefc2e72fb4417991372ea1ed6f77e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4374,
          name: "Lucky Cat Mobile",
          uuid: "97634dbed328462c9c26e6ab8187fa95",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/97634dbed328462c9c26e6ab8187fa95.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5084,
          name: "Lucky God",
          uuid: "d51b42d07e5642de82b307defc4c438a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d51b42d07e5642de82b307defc4c438a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5085,
          name: "Lucky God Mobile",
          uuid: "592f80cb50ad41cb8976e735d8c29ae6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/592f80cb50ad41cb8976e735d8c29ae6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5425,
          name: "Lucky Golden Dragon Lock 2 Spin",
          uuid: "6656e73789c44855ab01e5f6170d5136",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6656e73789c44855ab01e5f6170d5136.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5424,
          name: "Lucky Golden Dragon Lock 2 Spin Mobile",
          uuid: "f7943e5479fe430fb467d9cfffcadd61",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f7943e5479fe430fb467d9cfffcadd61.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4343,
          name: "Lucky Lucky",
          uuid: "2817c5026a60467e868128032050101a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2817c5026a60467e868128032050101a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4342,
          name: "Lucky Lucky Mobile",
          uuid: "9eb98f90887d4c5e956f75f186a06d69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9eb98f90887d4c5e956f75f186a06d69.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4451,
          name: "Lucky Penguins",
          uuid: "e6cfcb9ef9444ae6a2fd697b02ef3629",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e6cfcb9ef9444ae6a2fd697b02ef3629.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4450,
          name: "Lucky Penguins Mobile",
          uuid: "6e35ca3266db49b7b76e873c9c7d5c6d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6e35ca3266db49b7b76e873c9c7d5c6d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5186,
          name: "Lucky Star",
          uuid: "b0889228258241cd8681111566f3939c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b0889228258241cd8681111566f3939c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5187,
          name: "Lucky Star Mobile",
          uuid: "d68d7423130946539b829c5f10faa016",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d68d7423130946539b829c5f10faa016.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4896,
          name: "Lucky Tumbler",
          uuid: "7c93f23f33b2f17fb10ee61c7acb005f20c23bab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7c93f23f33b2f17fb10ee61c7acb005f20c23bab.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4897,
          name: "Lucky Tumbler Mobile",
          uuid: "fd91961599b18f83246fff56e1933a9f15daf1b4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fd91961599b18f83246fff56e1933a9f15daf1b4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4988,
          name: "Lucky Video Poker",
          uuid: "4d46a841ddbc0531452f334b49af2488f43ab577",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4d46a841ddbc0531452f334b49af2488f43ab577.png",
          type: "poker",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4989,
          name: "Lucky Video Poker Mobile",
          uuid: "a4b78a91c2152a288a2822dee0453ed6a451fae4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a4b78a91c2152a288a2822dee0453ed6a451fae4.png",
          type: "poker",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4775,
          name: "Luxury Garage",
          uuid: "1694e94bb9004e1c919d30ba8213ffdf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1694e94bb9004e1c919d30ba8213ffdf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4774,
          name: "Luxury Garage Mobile",
          uuid: "1321191746344d11984f9f363d273ff3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1321191746344d11984f9f363d273ff3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4353,
          name: "Magic Apprentice",
          uuid: "f1eeae7149814ece80a733d1c547c2bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f1eeae7149814ece80a733d1c547c2bf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4352,
          name: "Magic Apprentice Mobile",
          uuid: "3d14f2480ecd43da9be2c62386e0abb9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3d14f2480ecd43da9be2c62386e0abb9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5592,
          name: "Magic Carpet",
          uuid: "e0de8a25da91461db960c88e7e632d65",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e0de8a25da91461db960c88e7e632d65.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5593,
          name: "Magic Carpet Mobile",
          uuid: "78e336a0d2694687afd5d37edbdcd7d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/78e336a0d2694687afd5d37edbdcd7d2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5032,
          name: "Magic Witches",
          uuid: "47a2cbd726c56c4522a15f3db41c37a5b00c90af",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/47a2cbd726c56c4522a15f3db41c37a5b00c90af.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5033,
          name: "Magic Witches Mobile",
          uuid: "b5745bff2c1f97d80a0925c305b73df3b36ece55",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b5745bff2c1f97d80a0925c305b73df3b36ece55.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5594,
          name: "Magical Ocean",
          uuid: "e5c37856df814727b58c6a8eb1476247",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e5c37856df814727b58c6a8eb1476247.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5595,
          name: "Magical Ocean Mobile",
          uuid: "e579372182e142d49fc426395674a4f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e579372182e142d49fc426395674a4f6.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4906,
          name: "Magician House",
          uuid: "765a179b5cf4f2bd594803dea7f4238129cd7d78",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/765a179b5cf4f2bd594803dea7f4238129cd7d78.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4907,
          name: "Magician House Mobile",
          uuid: "9b7279166bb252a26844949490b78c337333fae1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9b7279166bb252a26844949490b78c337333fae1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4749,
          name: "Mahjong Master",
          uuid: "d25e942e70e84b0abfefdba4e8894a54",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d25e942e70e84b0abfefdba4e8894a54.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4748,
          name: "Mahjong Master Mobile",
          uuid: "81c0e38f5c70496180860fd8aa9cbc55",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/81c0e38f5c70496180860fd8aa9cbc55.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5458,
          name: "Maleficent",
          uuid: "6b9cccdad8f44e8693523c148f7e6a24",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6b9cccdad8f44e8693523c148f7e6a24.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5459,
          name: "Maleficent Mobile",
          uuid: "ab500d4b02cc428ab68bf095115bd6b1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ab500d4b02cc428ab68bf095115bd6b1.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5378,
          name: "Mania Lotto",
          uuid: "af38817ad86148b194ae68fcf7d410f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/af38817ad86148b194ae68fcf7d410f6.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5379,
          name: "Mania Lotto Mobile",
          uuid: "051b7919bfa24169b45540fd52441e7e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/051b7919bfa24169b45540fd52441e7e.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4319,
          name: "Masquerade",
          uuid: "f7dae6af698f4e40a28f7eb0cb120e9d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f7dae6af698f4e40a28f7eb0cb120e9d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4318,
          name: "Masquerade Mobile",
          uuid: "2894cc59ee25404883f55b6b8ff11b44",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2894cc59ee25404883f55b6b8ff11b44.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5268,
          name: "Master Of Wulin",
          uuid: "0e4f778918f84ae995aebaafe50487e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0e4f778918f84ae995aebaafe50487e5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5269,
          name: "Master Of Wulin Mobile",
          uuid: "09c8c5bb421b48d29098fa120f363224",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/09c8c5bb421b48d29098fa120f363224.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5467,
          name: "Mayan Civilization",
          uuid: "76a41e1830e6bdc66476e8c1b6e977a563e09ffb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/76a41e1830e6bdc66476e8c1b6e977a563e09ffb.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5466,
          name: "Mayan Civilization Mobile",
          uuid: "b99b6772f145b683cd5d123c0574d65f154fbe18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b99b6772f145b683cd5d123c0574d65f154fbe18.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4677,
          name: "Mayan Gold",
          uuid: "8e5e21ba096b45888d2475f531484011",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8e5e21ba096b45888d2475f531484011.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4676,
          name: "Mayan Gold Mobile",
          uuid: "e4006d09388f450aa393bcf8b2c902eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e4006d09388f450aa393bcf8b2c902eb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4491,
          name: "Mazu",
          uuid: "9104163fd6b94b18acc6663154cf6402",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9104163fd6b94b18acc6663154cf6402.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4490,
          name: "Mazu Mobile",
          uuid: "3c5f28ff02b54d27b59b72495765e88f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3c5f28ff02b54d27b59b72495765e88f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4401,
          name: "Medal Winner Megaways",
          uuid: "50c83d62db66447aad54ab8050e23272",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/50c83d62db66447aad54ab8050e23272.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4400,
          name: "Medal Winner Megaways Mobile",
          uuid: "1051aade81ef4047b880b79d6a630ee7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1051aade81ef4047b880b79d6a630ee7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4685,
          name: "Medieval Knights",
          uuid: "74ecd3f1cd2c4dda8acd2cab4e150024",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/74ecd3f1cd2c4dda8acd2cab4e150024.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4684,
          name: "Medieval Knights Mobile",
          uuid: "2190a4b068924f32800536955dc84769",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2190a4b068924f32800536955dc84769.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4996,
          name: "Medusa",
          uuid: "aad01a7b4e3dc0df632ed257ed8630dbd40d86f1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/aad01a7b4e3dc0df632ed257ed8630dbd40d86f1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4997,
          name: "Medusa Mobile",
          uuid: "6461932a76b14147befa0f65099a65f2d4145a87",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6461932a76b14147befa0f65099a65f2d4145a87.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4836,
          name: "Meerkats' Family",
          uuid: "27e508deea0045438fccf69f9d6b742e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/27e508deea0045438fccf69f9d6b742e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4837,
          name: "Meerkats' Family Mobile",
          uuid: "fe5bf6838cb7497bbc1f334218b2d4d4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fe5bf6838cb7497bbc1f334218b2d4d4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4838,
          name: "Meowfia",
          uuid: "79c4edcabaf14f09add9c91bd56ae1be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/79c4edcabaf14f09add9c91bd56ae1be.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4839,
          name: "Meowfia Mobile",
          uuid: "37a7770ce8da4372a6de9d2b3a256ecb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/37a7770ce8da4372a6de9d2b3a256ecb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4778,
          name: "Mermaid Hunter",
          uuid: "894e6883034c470da7773f620f5dfa47",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/894e6883034c470da7773f620f5dfa47.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4779,
          name: "Mermaid Hunter Mobile",
          uuid: "cb3eaa83be174e37a4dae8660b7b0ef5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cb3eaa83be174e37a4dae8660b7b0ef5.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5130,
          name: "Mermaid Legend",
          uuid: "f9e21c11faf64cd0b9fc2f2a641514fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f9e21c11faf64cd0b9fc2f2a641514fa.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5131,
          name: "Mermaid Legend Mobile",
          uuid: "b5217ff66c2d4f18adc50f70c6bd0b27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b5217ff66c2d4f18adc50f70c6bd0b27.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4575,
          name: "Mermaid Seas",
          uuid: "eb6533f0ae144970b1e7592fc6fb6062",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/eb6533f0ae144970b1e7592fc6fb6062.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4574,
          name: "Mermaid Seas Mobile",
          uuid: "44bf0b1087f644a1b7aa2154a4979ae6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/44bf0b1087f644a1b7aa2154a4979ae6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4960,
          name: "Mermaid World",
          uuid: "307ca25d94c72e5c172924a9334fec1bc2e2c758",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/307ca25d94c72e5c172924a9334fec1bc2e2c758.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4961,
          name: "Mermaid World Mobile",
          uuid: "df4a25cfb195833365e83d2c547697570e53edbe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/df4a25cfb195833365e83d2c547697570e53edbe.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4405,
          name: "Mexicaliente",
          uuid: "e6acc7bcd26e425785749d043c943104",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e6acc7bcd26e425785749d043c943104.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4404,
          name: "Mexicaliente Mobile",
          uuid: "b9435158eca14b80be92b4651c6055a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b9435158eca14b80be92b4651c6055a9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4898,
          name: "Midas Touch",
          uuid: "66d7d985ce7766c7035e6fe4345bbc625a35166e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/66d7d985ce7766c7035e6fe4345bbc625a35166e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4899,
          name: "Midas Touch Mobile",
          uuid: "6a464005318e6277a4e0a545219156327c4862f7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6a464005318e6277a4e0a545219156327c4862f7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4790,
          name: "Milk Girl",
          uuid: "e6a677b53b1b4d89af72a102e4d3f8a2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e6a677b53b1b4d89af72a102e4d3f8a2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4791,
          name: "Milk Girl Mobile",
          uuid: "c3111197521540b0a73f885f56694924",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c3111197521540b0a73f885f56694924.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4828,
          name: "Millennium Love",
          uuid: "02f80616879f45fca37b523f991d7b2f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/02f80616879f45fca37b523f991d7b2f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4829,
          name: "Millennium Love Mobile",
          uuid: "394fb5ec49a945b288210d6c4090d3cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/394fb5ec49a945b288210d6c4090d3cc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5481,
          name: "Million Lucky Wheel",
          uuid: "ffef46a4375fed73752b40082c60e11b62a94ea6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ffef46a4375fed73752b40082c60e11b62a94ea6.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5480,
          name: "Million Lucky Wheel Mobile",
          uuid: "a96fdd5a7b00615d9302053c60f153aa48b05408",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a96fdd5a7b00615d9302053c60f153aa48b05408.jpg",
          type: "american roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4639,
          name: "Millionaires",
          uuid: "e6ab4c446a514172a3b7b115a3790dc3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e6ab4c446a514172a3b7b115a3790dc3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4638,
          name: "Millionaires Mobile",
          uuid: "c55bfa6c40fc4a23bfe1bc8418cb1119",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c55bfa6c40fc4a23bfe1bc8418cb1119.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4719,
          name: "Ming Imperial Guards",
          uuid: "99629b553b324bde9cf02ca3c9ca0efd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/99629b553b324bde9cf02ca3c9ca0efd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4718,
          name: "Ming Imperial Guards Mobile",
          uuid: "210eaab73d134ff9902c60b51afe2854",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/210eaab73d134ff9902c60b51afe2854.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4882,
          name: "Mirror House",
          uuid: "44b168803ae32540089b66628dd8697ed480f852",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/44b168803ae32540089b66628dd8697ed480f852.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4883,
          name: "Mirror House Mobile",
          uuid: "1aa0a1e06513f17ec73b6079980eec695c9331df",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1aa0a1e06513f17ec73b6079980eec695c9331df.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5168,
          name: "Miss Joker",
          uuid: "3e962d42a5034c70bd9445a0291ea2bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3e962d42a5034c70bd9445a0291ea2bf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5169,
          name: "Miss Joker Mobile",
          uuid: "68930303ca2c4c27866b514c96185af4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/68930303ca2c4c27866b514c96185af4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4641,
          name: "Miss Tiger",
          uuid: "58f4abd1505f4168b4c1d71a47979c39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/58f4abd1505f4168b4c1d71a47979c39.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4640,
          name: "Miss Tiger Mobile",
          uuid: "2ab31b4b3bfb435390c8000b0506ebbe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2ab31b4b3bfb435390c8000b0506ebbe.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4533,
          name: "Modern 7 Wonders",
          uuid: "44c9d1275680453daa671ecc449d9d4a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/44c9d1275680453daa671ecc449d9d4a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4532,
          name: "Modern 7 Wonders Mobile",
          uuid: "365eb58b6a7645a7a6f87e9be627bb43",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/365eb58b6a7645a7a6f87e9be627bb43.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5607,
          name: "Modernizations",
          uuid: "610c0a28f33a4e8380a2b1713bd62f70",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/610c0a28f33a4e8380a2b1713bd62f70.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5606,
          name: "Modernizations Mobile",
          uuid: "66d6e9f74a3046809a5b811ddd8a233b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/66d6e9f74a3046809a5b811ddd8a233b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5372,
          name: "Mole Money",
          uuid: "99d6408ecbc3416bb57b90bc286a1db5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/99d6408ecbc3416bb57b90bc286a1db5.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5373,
          name: "Mole Money Mobile",
          uuid: "c13b347c97aa4e108a1a885bd599ac5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c13b347c97aa4e108a1a885bd599ac5d.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5433,
          name: "Money God",
          uuid: "7b8adbb00530a9a87df235a68f5db3821db4d124",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7b8adbb00530a9a87df235a68f5db3821db4d124.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5432,
          name: "Money God Mobile",
          uuid: "0ee2bd6fc506b20941821aceefe85e627ad81fab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0ee2bd6fc506b20941821aceefe85e627ad81fab.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4810,
          name: "Monkey and Crab",
          uuid: "1ecbf4addbdb4dcf8e1609449d1eca4b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1ecbf4addbdb4dcf8e1609449d1eca4b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4811,
          name: "Monkey and Crab Mobile",
          uuid: "ea0fd2e992854ab0be10b181035e88e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ea0fd2e992854ab0be10b181035e88e9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5314,
          name: "Monkey King",
          uuid: "af090536e03541358544895689a466b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/af090536e03541358544895689a466b2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5315,
          name: "Monkey King Mobile",
          uuid: "1f15be40cf10487da16d5803f0622439",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1f15be40cf10487da16d5803f0622439.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4884,
          name: "Monkey Maniac",
          uuid: "4957eaf639e2d30e8989fbdbf7a27a3648e49652",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4957eaf639e2d30e8989fbdbf7a27a3648e49652.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4885,
          name: "Monkey Maniac Mobile",
          uuid: "b3e18954ca237039c4171d4b76a4d6b969c87dbe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b3e18954ca237039c4171d4b76a4d6b969c87dbe.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5198,
          name: "Monster Buster",
          uuid: "0ef92b75d8fc4db0aca38fae43a1a0c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0ef92b75d8fc4db0aca38fae43a1a0c6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5199,
          name: "Monster Buster Mobile",
          uuid: "1d7003a66b1a4c32b369a5098740000f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1d7003a66b1a4c32b369a5098740000f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5226,
          name: "Monster Collector",
          uuid: "85c79775d58542b195ba5e9b6b7bfd32",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/85c79775d58542b195ba5e9b6b7bfd32.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5227,
          name: "Monster Collector Mobile",
          uuid: "739250bff38647e9a78ebab2ecdabeab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/739250bff38647e9a78ebab2ecdabeab.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5538,
          name: "Monster Destroyer",
          uuid: "fdec16366a70463785f43f88cef50ef0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fdec16366a70463785f43f88cef50ef0.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5539,
          name: "Monster Destroyer Mobile",
          uuid: "c476a1b0e1904eb282499cd44928161e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c476a1b0e1904eb282499cd44928161e.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5244,
          name: "Monster File",
          uuid: "19632881f19a4d69b45d797f89ba8716",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/19632881f19a4d69b45d797f89ba8716.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5245,
          name: "Monster File Mobile",
          uuid: "5076b440aad144aa909cc07ad48189ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5076b440aad144aa909cc07ad48189ec.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5431,
          name: "Monster Go Shopping",
          uuid: "d5c201318da5a3e7f3466b570f0cec25b7a771bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d5c201318da5a3e7f3466b570f0cec25b7a771bc.jpg",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5430,
          name: "Monster Go Shopping Mobile",
          uuid: "e8468db02e9fb056213a4db9c3947b7e0513b224",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e8468db02e9fb056213a4db9c3947b7e0513b224.jpg",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4950,
          name: "Monster Island",
          uuid: "175c49f66cfabe9307fa1001af73578fa6733a54",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/175c49f66cfabe9307fa1001af73578fa6733a54.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4951,
          name: "Monster Island Mobile",
          uuid: "4a3d8e1dde5e55072f8448f6db91aa03e7bea0e0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4a3d8e1dde5e55072f8448f6db91aa03e7bea0e0.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4846,
          name: "Monster Parade",
          uuid: "18b360beb3b44a9d8c51cfd0316bc2d1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/18b360beb3b44a9d8c51cfd0316bc2d1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4847,
          name: "Monster Parade Mobile",
          uuid: "d4c760c479ba41d3bec3e3b4603416fc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d4c760c479ba41d3bec3e3b4603416fc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4593,
          name: "Moon Goddess",
          uuid: "5b7666d047404e27b705f6cedd00f806",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5b7666d047404e27b705f6cedd00f806.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4592,
          name: "Moon Goddess Mobile",
          uuid: "d5f590aaf160408c87cff011fdbd0b0d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d5f590aaf160408c87cff011fdbd0b0d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5004,
          name: "Moulin Rouge",
          uuid: "76d4b0f4386f425ab7f5f0876226d89f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/76d4b0f4386f425ab7f5f0876226d89f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5005,
          name: "Moulin Rouge Mobile",
          uuid: "2f839f5a588f4fdeabd44864d20a91dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2f839f5a588f4fdeabd44864d20a91dd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5618,
          name: "Mouse on the Prairie",
          uuid: "1aa7e0e933f34c6ca2515b5dbd80928a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1aa7e0e933f34c6ca2515b5dbd80928a.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5619,
          name: "Mouse on the Prairie Mobile",
          uuid: "814e28c2296a41fc950bf839ea982867",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/814e28c2296a41fc950bf839ea982867.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5626,
          name: "Munich Beer Bash",
          uuid: "eb744d2fc8bb4bf4b7e3bf5a812d3f00",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/eb744d2fc8bb4bf4b7e3bf5a812d3f00.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5627,
          name: "Munich Beer Bash Mobile",
          uuid: "9769ebcff84b43a69502d20172809565",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9769ebcff84b43a69502d20172809565.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4543,
          name: "Muscle Cars",
          uuid: "a03144c9eb904f7996fbd8fa6c4557ba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a03144c9eb904f7996fbd8fa6c4557ba.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4542,
          name: "Muscle Cars Mobile",
          uuid: "c0e9ce5148da4dd383b847135f0131bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c0e9ce5148da4dd383b847135f0131bb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5620,
          name: "Museum of Fantasy Fusion Reels",
          uuid: "cd38f5ee5cd44b289f8e56cea0ada6b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cd38f5ee5cd44b289f8e56cea0ada6b8.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5621,
          name: "Museum of Fantasy Fusion Reels Mobile",
          uuid: "c46519909fb64d0c819ca4cc3569efa6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c46519909fb64d0c819ca4cc3569efa6.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4461,
          name: "Musketeers",
          uuid: "54b0fea3c0a94e57b7b5ec3afca2e1b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/54b0fea3c0a94e57b7b5ec3afca2e1b4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4460,
          name: "Musketeers Mobile",
          uuid: "72773b515ea74e6991cee57ebf426ffa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/72773b515ea74e6991cee57ebf426ffa.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4647,
          name: "Mysterious Pyramid",
          uuid: "ef0dfafe0b1d486f99be71e597d5ba8d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ef0dfafe0b1d486f99be71e597d5ba8d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4646,
          name: "Mysterious Pyramid Mobile",
          uuid: "74ba1cc30284466e952926635e5cd377",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/74ba1cc30284466e952926635e5cd377.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4357,
          name: "Mystery Alchemy",
          uuid: "699c1046d18d426cb82d60b0e787311f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/699c1046d18d426cb82d60b0e787311f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4356,
          name: "Mystery Alchemy Mobile",
          uuid: "d695498464794e26b559eb1f35622218",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d695498464794e26b559eb1f35622218.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5500,
          name: "Mystery Detective",
          uuid: "688cd629608042a2a35c3b4522bb50c5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/688cd629608042a2a35c3b4522bb50c5.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5501,
          name: "Mystery Detective Mobile",
          uuid: "cdbfefe5e12c4e18866c1bb5a7eff5e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cdbfefe5e12c4e18866c1bb5a7eff5e5.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5068,
          name: "Mystery Shoot",
          uuid: "479ad5f9147f424fa47e262813b23464",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/479ad5f9147f424fa47e262813b23464.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5069,
          name: "Mystery Shoot Mobile",
          uuid: "a57bc20c0ac84b76a5b4af89beed877c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a57bc20c0ac84b76a5b4af89beed877c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4681,
          name: "Mythic",
          uuid: "20b553cf6e284520901c662693e551d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/20b553cf6e284520901c662693e551d3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4680,
          name: "Mythic Mobile",
          uuid: "7b5737184e9f41d1be110facedaaaa11",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7b5737184e9f41d1be110facedaaaa11.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5000,
          name: "Mythical Beast",
          uuid: "6868365d7bcd43fd82da75b845e007c5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6868365d7bcd43fd82da75b845e007c5.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5001,
          name: "Mythical Beast Mobile",
          uuid: "bf6384fac30b48cd9955370440ca0034",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bf6384fac30b48cd9955370440ca0034.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4858,
          name: "Mythological Creatures",
          uuid: "019a8420771841f492317fb021c6cfc9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/019a8420771841f492317fb021c6cfc9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4859,
          name: "Mythological Creatures Mobile",
          uuid: "b10821977cf64b35aef6f2fdd464b1f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b10821977cf64b35aef6f2fdd464b1f6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5082,
          name: "Naughty Witches",
          uuid: "63993035ead74e2abdae3d8e7203b275",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/63993035ead74e2abdae3d8e7203b275.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5083,
          name: "Naughty Witches Mobile",
          uuid: "93552c010cce4973912fb7bf8a8e567f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/93552c010cce4973912fb7bf8a8e567f.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4721,
          name: "Neanderthals",
          uuid: "9b07368fbde44b71964ac340c700bcd8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9b07368fbde44b71964ac340c700bcd8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4720,
          name: "Neanderthals Mobile",
          uuid: "62b2ed96e4f9465594cbabc6eec4ba57",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/62b2ed96e4f9465594cbabc6eec4ba57.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5429,
          name: "Neonmal",
          uuid: "1ae4cf355bf042d6a1acf6bdfa59df94",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1ae4cf355bf042d6a1acf6bdfa59df94.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5428,
          name: "Neonmal Mobile",
          uuid: "7d22ed75b1f24f83bdcd25d5d160f19b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7d22ed75b1f24f83bdcd25d5d160f19b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4725,
          name: "Nezha",
          uuid: "9fc40ea426834f8a82920c8134808ae1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9fc40ea426834f8a82920c8134808ae1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4724,
          name: "Nezha Mobile",
          uuid: "3b4e83343c584c3a8eabdc806b3eed5e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3b4e83343c584c3a8eabdc806b3eed5e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4503,
          name: "Nian",
          uuid: "1f676c69ea474e8b8bb88548d1690492",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1f676c69ea474e8b8bb88548d1690492.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4502,
          name: "Nian Mobile",
          uuid: "cede64471c0c4ecb8b42ee8bb7790c4b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cede64471c0c4ecb8b42ee8bb7790c4b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5280,
          name: "Nie Xiaoqian",
          uuid: "d369a4dfb6074d3da60e6f01fd1d6a1a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d369a4dfb6074d3da60e6f01fd1d6a1a.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5281,
          name: "Nie Xiaoqian Mobile",
          uuid: "9aa16c9c569a45ff90374c3b096bf472",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9aa16c9c569a45ff90374c3b096bf472.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5212,
          name: "Night On The Nile",
          uuid: "0433442900004b18bc9b0006641aa12a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0433442900004b18bc9b0006641aa12a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5213,
          name: "Night On The Nile Mobile",
          uuid: "fa47d077d0ad414fbbeabf727fea26e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fa47d077d0ad414fbbeabf727fea26e1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4559,
          name: "Nine Lucks",
          uuid: "fb851dcbba364c768758ebfdb3fe0ec2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fb851dcbba364c768758ebfdb3fe0ec2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4558,
          name: "Nine Lucks Mobile",
          uuid: "c1b53da98ad541c4a7efa362982f029c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c1b53da98ad541c4a7efa362982f029c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5494,
          name: "Nine Monkey",
          uuid: "23c98e6ca5fe40f18b5fa5c808843f1e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/23c98e6ca5fe40f18b5fa5c808843f1e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5495,
          name: "Nine Monkey Mobile",
          uuid: "d5951d4d1d8244e5ba92a9670c6cd2f3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d5951d4d1d8244e5ba92a9670c6cd2f3.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5578,
          name: "Nine Tailed Fox",
          uuid: "9955aafa23da4160a3174fc6ef980752",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9955aafa23da4160a3174fc6ef980752.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5579,
          name: "Nine Tailed Fox Mobile",
          uuid: "ed70a1e33d4c4ca2bb4118c930c91eac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ed70a1e33d4c4ca2bb4118c930c91eac.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5190,
          name: "No Fly Zone",
          uuid: "a2684d45ac1746e58c2c6e5e46ca764e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a2684d45ac1746e58c2c6e5e46ca764e.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5191,
          name: "No Fly Zone Mobile",
          uuid: "c5f7ff79e3b7406d9c605d73f849e246",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c5f7ff79e3b7406d9c605d73f849e246.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5580,
          name: "Noble Cat",
          uuid: "d3f49b1b09fd42d09f601291a1f2210f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d3f49b1b09fd42d09f601291a1f2210f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5581,
          name: "Noble Cat Mobile",
          uuid: "93a45e724d964a69bfdd5082b7558c6b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/93a45e724d964a69bfdd5082b7558c6b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4513,
          name: "Nvwa",
          uuid: "b211d458ce8d41b899f8db5f69f3969a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b211d458ce8d41b899f8db5f69f3969a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4512,
          name: "Nvwa Mobile",
          uuid: "e7e2999c748546f69f1876d58f343646",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e7e2999c748546f69f1876d58f343646.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4972,
          name: "Ocean Princess",
          uuid: "f613b25f8605e1a9d184d87c1e40a60618679665",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f613b25f8605e1a9d184d87c1e40a60618679665.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4973,
          name: "Ocean Princess Mobile",
          uuid: "fe6badc0556e6f61e4fb8593b987f9fba7df14d0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fe6badc0556e6f61e4fb8593b987f9fba7df14d0.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5058,
          name: "Ocean Star Hunting",
          uuid: "a79ef4bfc13c4b528a1d4b931a7533cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a79ef4bfc13c4b528a1d4b931a7533cf.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5059,
          name: "Ocean Star Hunting Mobile",
          uuid: "118da788ee724b23a611bb0c5975e8bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/118da788ee724b23a611bb0c5975e8bb.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5419,
          name: "Octopus Legend",
          uuid: "36821700e05a4f36a65f32e9d9733d41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/36821700e05a4f36a65f32e9d9733d41.png",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5418,
          name: "Octopus Legend Mobile",
          uuid: "fe7715adf62a4ae49ba9e5a15ba0395a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fe7715adf62a4ae49ba9e5a15ba0395a.png",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4986,
          name: "Oktoberfest",
          uuid: "2b15dae86e0dd73e5d90ab7e8c8fedd60cb17d9c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2b15dae86e0dd73e5d90ab7e8c8fedd60cb17d9c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4987,
          name: "Oktoberfest Mobile",
          uuid: "3df8adb9c20cbb8851a6b623152a266044a6255d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3df8adb9c20cbb8851a6b623152a266044a6255d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5444,
          name: "Olympus Gods",
          uuid: "997eb1f904e04dc8b59a667ea5be016b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/997eb1f904e04dc8b59a667ea5be016b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5445,
          name: "Olympus Gods Mobile",
          uuid: "dc178904faef43a194e89d974447a1d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/dc178904faef43a194e89d974447a1d3.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5286,
          name: "Onmyoji",
          uuid: "fcd0c041d1cb4256b36258ce46f48993",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fcd0c041d1cb4256b36258ce46f48993.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5287,
          name: "Onmyoji Mobile",
          uuid: "152ffb40f0c44751809bd00c71444d6e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/152ffb40f0c44751809bd00c71444d6e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5088,
          name: "Oriental Beast",
          uuid: "8bd406a60c494c0caaac0dd857e23cf3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8bd406a60c494c0caaac0dd857e23cf3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5089,
          name: "Oriental Beast Mobile",
          uuid: "88144e789c5c4dca9dbb2cec3c5bc53c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/88144e789c5c4dca9dbb2cec3c5bc53c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5218,
          name: "Oriental Monster",
          uuid: "6c6e06300dc74f3e9846f033fe27729b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6c6e06300dc74f3e9846f033fe27729b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5219,
          name: "Oriental Monster Mobile",
          uuid: "61c045083f8c4f31a7c4fd3090d0bf0a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/61c045083f8c4f31a7c4fd3090d0bf0a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4583,
          name: "Origin Of Fire",
          uuid: "3da6e03bf12d4d439c253318c52c2205",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3da6e03bf12d4d439c253318c52c2205.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4582,
          name: "Origin Of Fire Mobile",
          uuid: "31c5f427d4284ff5b2b624bc04dfc2dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/31c5f427d4284ff5b2b624bc04dfc2dc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4878,
          name: "Owl In Forest",
          uuid: "bc25e95c406870eda62653ee0698d68dd92503f6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bc25e95c406870eda62653ee0698d68dd92503f6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4879,
          name: "Owl In Forest Mobile",
          uuid: "d675dc5fa1b5ff93c6981840ad8f92307d2b285b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d675dc5fa1b5ff93c6981840ad8f92307d2b285b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4515,
          name: "Pandora's Box ",
          uuid: "41ec5bcbbb7141b5b052ac67c491bf2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/41ec5bcbbb7141b5b052ac67c491bf2d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4514,
          name: "Pandora's Box  Mobile",
          uuid: "c467ddccf1c34ab489b259cc29aa08ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c467ddccf1c34ab489b259cc29aa08ec.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4425,
          name: "Party Girl",
          uuid: "b235888468c34c0688dfffbea2e04895",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b235888468c34c0688dfffbea2e04895.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5452,
          name: "Party Girl Deluxe",
          uuid: "a4dc0c40e559458d9332cd78d0604a2b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a4dc0c40e559458d9332cd78d0604a2b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5453,
          name: "Party Girl Deluxe Mobile",
          uuid: "0430318459f946c1b21ede0b8a1e2f58",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0430318459f946c1b21ede0b8a1e2f58.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4424,
          name: "Party Girl Mobile",
          uuid: "39fe4d831a1441e4925d458a9c1684e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/39fe4d831a1441e4925d458a9c1684e4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4599,
          name: "Party Girl Ways",
          uuid: "85603dbfba8b4251aa1233c29dff3073",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/85603dbfba8b4251aa1233c29dff3073.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4598,
          name: "Party Girl Ways Mobile",
          uuid: "aa2e7a11c2b64bbca5a81fe14296a0df",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/aa2e7a11c2b64bbca5a81fe14296a0df.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5338,
          name: "Peacock Princess",
          uuid: "e0b6fc12bbd747a1861a1c836412ea24",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e0b6fc12bbd747a1861a1c836412ea24.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5339,
          name: "Peacock Princess Mobile",
          uuid: "3804186e395a49319ba91ed0ffff447c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3804186e395a49319ba91ed0ffff447c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5048,
          name: "Penalty Kick",
          uuid: "0724aa6806404510951eb176fe21d44c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0724aa6806404510951eb176fe21d44c.png",
          type: "other",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5049,
          name: "Penalty Kick Mobile",
          uuid: "fae0eae5c111428a9682815b7a72eab9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fae0eae5c111428a9682815b7a72eab9.png",
          type: "other",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5170,
          name: "Penguin Family",
          uuid: "4ca7e007a5e2459191e449deaa623b01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4ca7e007a5e2459191e449deaa623b01.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5171,
          name: "Penguin Family Mobile",
          uuid: "5623bbac054548f3a9f4949b4e7cd83c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5623bbac054548f3a9f4949b4e7cd83c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4852,
          name: "Persia Bonanza Megaways",
          uuid: "9bdc2621685943249df3dd532d29b05f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9bdc2621685943249df3dd532d29b05f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4853,
          name: "Persia Bonanza Megaways Mobile",
          uuid: "74e7d08d8e254ceca36980b5516d055a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/74e7d08d8e254ceca36980b5516d055a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4549,
          name: "Peter Pan",
          uuid: "27f98015e3d4499785c128ce0359db1d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/27f98015e3d4499785c128ce0359db1d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4548,
          name: "Peter Pan Mobile",
          uuid: "0a18f843206c4eafb703fc4eaabc2755",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0a18f843206c4eafb703fc4eaabc2755.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4463,
          name: "Pets",
          uuid: "e4e1ea9efe594f1eb9f5487c021144d6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e4e1ea9efe594f1eb9f5487c021144d6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4462,
          name: "Pets Mobile",
          uuid: "805e4a06928f479e859850dc5b5ea9d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/805e4a06928f479e859850dc5b5ea9d2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5408,
          name: "Philosopher Roulette",
          uuid: "c38afd7ad1af431fbc32b2bda055b091",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c38afd7ad1af431fbc32b2bda055b091.jpg",
          type: "roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5409,
          name: "Philosopher Roulette Mobile",
          uuid: "7940263eff0b4d94874b8640cb470354",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7940263eff0b4d94874b8640cb470354.jpg",
          type: "roulette",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4818,
          name: "Phoenix Rising",
          uuid: "e86f786d7217475699158d624c52637e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e86f786d7217475699158d624c52637e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4819,
          name: "Phoenix Rising Mobile",
          uuid: "704a8d2ffa454b299f287f498d26b90f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/704a8d2ffa454b299f287f498d26b90f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4888,
          name: "Pied Piper",
          uuid: "2ad3779504b1d383911a13baf5ca488f46eed5d0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2ad3779504b1d383911a13baf5ca488f46eed5d0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4889,
          name: "Pied Piper Mobile",
          uuid: "3f266e9ddc7fbe13975ce19d5b9ec57f449676b0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3f266e9ddc7fbe13975ce19d5b9ec57f449676b0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5156,
          name: "Piggy Bank Machine",
          uuid: "6d73f80154fd47fcbbba6005e8f14330",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6d73f80154fd47fcbbba6005e8f14330.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5157,
          name: "Piggy Bank Machine Mobile",
          uuid: "64c1b41ddece4db8b5b9b4b48b97fe88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/64c1b41ddece4db8b5b9b4b48b97fe88.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4307,
          name: "Pinata",
          uuid: "674ffdd452f8486c936158c19b22b477",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/674ffdd452f8486c936158c19b22b477.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4306,
          name: "Pinata Mobile",
          uuid: "96242433aedd472cb1b32339244ac1ac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/96242433aedd472cb1b32339244ac1ac.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4531,
          name: "Pinocchio",
          uuid: "8e8c3f82852a4d54948a9358572cc84d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8e8c3f82852a4d54948a9358572cc84d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4530,
          name: "Pinocchio Mobile",
          uuid: "bc03db15ada94073a79d51bbe790c8b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bc03db15ada94073a79d51bbe790c8b7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4651,
          name: "Pirate King",
          uuid: "e6ce0fbecf0a4e0b93ed633740a19e25",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e6ce0fbecf0a4e0b93ed633740a19e25.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4650,
          name: "Pirate King Mobile",
          uuid: "c873ecc2f44d4ea38704ff5e7ae4ab37",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c873ecc2f44d4ea38704ff5e7ae4ab37.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5634,
          name: "Pirate Treasure Hunt",
          uuid: "1a1578cb6f22481eba37d57c8bd12eb4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1a1578cb6f22481eba37d57c8bd12eb4.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5635,
          name: "Pirate Treasure Hunt Mobile",
          uuid: "b7ee50bc8c8f44fa93a9ca79df9731be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b7ee50bc8c8f44fa93a9ca79df9731be.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5604,
          name: "Pirate's Treasure Quest",
          uuid: "0269f990c2e44a4a92d76740e857698a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0269f990c2e44a4a92d76740e857698a.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5605,
          name: "Pirate's Treasure Quest Mobile",
          uuid: "430b49ccab9c41b9b543d10a59f7c0b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/430b49ccab9c41b9b543d10a59f7c0b0.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5092,
          name: "Plague Doctor",
          uuid: "b43f32713a284f4fb50c11206ed48799",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b43f32713a284f4fb50c11206ed48799.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5093,
          name: "Plague Doctor Mobile",
          uuid: "95c7fd1f83fd482d8a378cc95f904be3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/95c7fd1f83fd482d8a378cc95f904be3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5272,
          name: "Plinko S",
          uuid: "dc7e693323fc4ed99e3c0b2ab5f6bf0e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/dc7e693323fc4ed99e3c0b2ab5f6bf0e.png",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5273,
          name: "Plinko S Mobile",
          uuid: "cabd37b4ad3d4025ab6b94745f39c1e2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cabd37b4ad3d4025ab6b94745f39c1e2.png",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4337,
          name: "Polaroid",
          uuid: "87dacf0a01784a2c9df3a2c3377540ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/87dacf0a01784a2c9df3a2c3377540ff.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4336,
          name: "Polaroid Mobile",
          uuid: "0cb50c4543ae4db6907e8e311b47ca45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0cb50c4543ae4db6907e8e311b47ca45.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4751,
          name: "Polynesian",
          uuid: "924138289177402fb087f46850468f8d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/924138289177402fb087f46850468f8d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4750,
          name: "Polynesian Mobile",
          uuid: "2f1c12e90e424ddcb95695ba5243742f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2f1c12e90e424ddcb95695ba5243742f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5262,
          name: "Poseidon Battle",
          uuid: "0b1e4d2c7a324d8786987591245a51c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0b1e4d2c7a324d8786987591245a51c2.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5263,
          name: "Poseidon Battle Mobile",
          uuid: "47523fbd2ebb41dfa2a429dc949e4d82",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/47523fbd2ebb41dfa2a429dc949e4d82.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4776,
          name: "Poseidon's Secret",
          uuid: "5717ddb615524338b646e27ba28ce51e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5717ddb615524338b646e27ba28ce51e.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5562,
          name: "Poseidon's Secret 2",
          uuid: "8e920ec621ee40dc858c46d1d844daf4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8e920ec621ee40dc858c46d1d844daf4.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5563,
          name: "Poseidon's Secret 2 Mobile",
          uuid: "0074487773db41e9a017e0ff90439fbd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0074487773db41e9a017e0ff90439fbd.jpg",
          type: "shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4777,
          name: "Poseidon's Secret Mobile",
          uuid: "7b9fa0fa6f4247d7ae2f3145bd5c1fc2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7b9fa0fa6f4247d7ae2f3145bd5c1fc2.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4693,
          name: "Poseidon's Treasure",
          uuid: "c2f0a6826f2e48a68eb78b0a086ce713",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c2f0a6826f2e48a68eb78b0a086ce713.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4692,
          name: "Poseidon's Treasure Mobile",
          uuid: "aaebad596cd14bea9acfc0fd6d5f3941",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/aaebad596cd14bea9acfc0fd6d5f3941.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5525,
          name: "Pot Feast",
          uuid: "ede780eed3aa4f7cb744bccb369f5c82",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ede780eed3aa4f7cb744bccb369f5c82.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5524,
          name: "Pot Feast Mobile",
          uuid: "b8b7d77698314752b9b659ec802a4573",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b8b7d77698314752b9b659ec802a4573.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5509,
          name: "PrettyPrincess",
          uuid: "24f010bea733446daafc72d692d83b70",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/24f010bea733446daafc72d692d83b70.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5508,
          name: "PrettyPrincess Mobile",
          uuid: "4347f66b700640238dcfc33894fb9b01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4347f66b700640238dcfc33894fb9b01.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5020,
          name: "Priceless Museum",
          uuid: "ac8c48a2a34148f9a7c8879dc99c87b2907b4044",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ac8c48a2a34148f9a7c8879dc99c87b2907b4044.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5021,
          name: "Priceless Museum Mobile",
          uuid: "907f6e4a5f374a8197b674401659b6be974d51c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/907f6e4a5f374a8197b674401659b6be974d51c6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4551,
          name: "Primeval Rainforest",
          uuid: "49e0e19c4a06410c8f13f072eae3aab0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/49e0e19c4a06410c8f13f072eae3aab0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4550,
          name: "Primeval Rainforest Mobile",
          uuid: "8b9d46ec975e452fb40154c0ece0fa83",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8b9d46ec975e452fb40154c0ece0fa83.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4627,
          name: "Princess Wencheng",
          uuid: "6878e603b7da49f38489a779388a62dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6878e603b7da49f38489a779388a62dc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4626,
          name: "Princess Wencheng Mobile",
          uuid: "d747b48c3c724d49939f45efe5642347",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d747b48c3c724d49939f45efe5642347.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5288,
          name: "Puffer Swimming",
          uuid: "420f2c020edc4492bd7aed3664fb0012",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/420f2c020edc4492bd7aed3664fb0012.jpg",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5289,
          name: "Puffer Swimming Mobile",
          uuid: "1c57f84cb0ec4f0ebcd239ae6888d8ed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1c57f84cb0ec4f0ebcd239ae6888d8ed.jpg",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4387,
          name: "Pumpkin Win",
          uuid: "d455e38029e74634a22e1210148f9b7e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d455e38029e74634a22e1210148f9b7e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4386,
          name: "Pumpkin Win Mobile",
          uuid: "51e2cdcc98d24fb2ada699248789ca92",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/51e2cdcc98d24fb2ada699248789ca92.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5348,
          name: "Putter King",
          uuid: "4cd384ea93f142b7935e1e0d8f113f22",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4cd384ea93f142b7935e1e0d8f113f22.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5349,
          name: "Putter King Mobile",
          uuid: "d0a1cc1eabee41a8a9ce041daf0025a4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d0a1cc1eabee41a8a9ce041daf0025a4.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4329,
          name: "Quadruple Dragons",
          uuid: "b198ecac5d954797800d72bca96df97a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b198ecac5d954797800d72bca96df97a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4328,
          name: "Quadruple Dragons Mobile",
          uuid: "21f81b55a3314c7a83c957f9a9a9e71f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/21f81b55a3314c7a83c957f9a9a9e71f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5603,
          name: "Queen's Corgi",
          uuid: "d7c2af35fc234b1f861481ab44b8466a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d7c2af35fc234b1f861481ab44b8466a.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5602,
          name: "Queen's Corgi Mobile",
          uuid: "d6b1310d3e8e4f4e8841f09ded955d4e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d6b1310d3e8e4f4e8841f09ded955d4e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4299,
          name: "Quick Play Candy",
          uuid: "35dc91f759ae48f1850e669f8f64797c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/35dc91f759ae48f1850e669f8f64797c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4298,
          name: "Quick Play Candy Mobile",
          uuid: "7f45df682e364713bf44423ec10d830a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7f45df682e364713bf44423ec10d830a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4555,
          name: "Quick Play Jewels",
          uuid: "d0ffa105ed6a4b56a6a76977b529c851",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d0ffa105ed6a4b56a6a76977b529c851.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4554,
          name: "Quick Play Jewels Mobile",
          uuid: "6a625f2370894bd5a6540e2cc765b54b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6a625f2370894bd5a6540e2cc765b54b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4563,
          name: "Quick Play Mahjong",
          uuid: "0e34586627fb493083576357e86fb4a7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0e34586627fb493083576357e86fb4a7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4562,
          name: "Quick Play Mahjong Mobile",
          uuid: "3b9c7b6cd0b443cab143cb4d0702d927",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3b9c7b6cd0b443cab143cb4d0702d927.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5094,
          name: "Rabbit Party",
          uuid: "b99f93327a424398b760a829a6b8171d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b99f93327a424398b760a829a6b8171d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5095,
          name: "Rabbit Party Mobile",
          uuid: "d225be7a834748ecab90217f6e23233f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d225be7a834748ecab90217f6e23233f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5368,
          name: "Radiant City",
          uuid: "e81527c2011344c3a718424dc9882e80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e81527c2011344c3a718424dc9882e80.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5369,
          name: "Radiant City Mobile",
          uuid: "3a4df29177384e779f8bef32276a6f5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3a4df29177384e779f8bef32276a6f5c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4539,
          name: "Rarities",
          uuid: "02338528cb6d4436a4cf0451c89a0b2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/02338528cb6d4436a4cf0451c89a0b2d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4538,
          name: "Rarities Mobile",
          uuid: "6dcc54e5901f47108edcc8c8d6d1347e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6dcc54e5901f47108edcc8c8d6d1347e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5511,
          name: "Rebirth",
          uuid: "363cd3f843e84ec9bd785794d28464aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/363cd3f843e84ec9bd785794d28464aa.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5510,
          name: "Rebirth Mobile",
          uuid: "92eac430f7f445298c37913296d44807",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/92eac430f7f445298c37913296d44807.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5192,
          name: "Red Baron",
          uuid: "e9d07ca5b5aa45a887ea7f7cea1d4873",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e9d07ca5b5aa45a887ea7f7cea1d4873.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5193,
          name: "Red Baron Mobile",
          uuid: "b6aa1195b04d43ff8bb7de55ce693c39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b6aa1195b04d43ff8bb7de55ce693c39.png",
          type: "crash",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4413,
          name: "Red Boy",
          uuid: "5dc9b354136a4d518322aff951f35f87",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5dc9b354136a4d518322aff951f35f87.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4412,
          name: "Red Boy Mobile",
          uuid: "af7feaa4705e4464a373eda78e4a0b48",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/af7feaa4705e4464a373eda78e4a0b48.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4792,
          name: "Red Cliff",
          uuid: "973946754df14489a3d48440d0a201e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/973946754df14489a3d48440d0a201e8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4793,
          name: "Red Cliff Mobile",
          uuid: "719bd14b00ad450697772e65da25d39a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/719bd14b00ad450697772e65da25d39a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4701,
          name: "Red Riding Hood",
          uuid: "929db10eb2af4269a2a49b22101dfc41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/929db10eb2af4269a2a49b22101dfc41.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4700,
          name: "Red Riding Hood Mobile",
          uuid: "74596d7affcb434090b523c8009f2c5b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/74596d7affcb434090b523c8009f2c5b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5302,
          name: "Red Sorghum",
          uuid: "1aed5aff45034535856144ccd029b1c7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1aed5aff45034535856144ccd029b1c7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5303,
          name: "Red Sorghum Mobile",
          uuid: "99233ebed020400d9354d6d7ac68abec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/99233ebed020400d9354d6d7ac68abec.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5364,
          name: "Repair Robot",
          uuid: "698e0cf981854ff8a1d2331c49b4a5a8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/698e0cf981854ff8a1d2331c49b4a5a8.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5365,
          name: "Repair Robot Mobile",
          uuid: "8c7f18c4eb644d16a3d93d31b5ac6c7f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8c7f18c4eb644d16a3d93d31b5ac6c7f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5014,
          name: "Rich Diwali",
          uuid: "51785f80b399aed0ceb502a9e0f1920095dee213",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/51785f80b399aed0ceb502a9e0f1920095dee213.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5015,
          name: "Rich Diwali Mobile",
          uuid: "e2e4fe8207c5fd06f18911eb1939a70ff2df9bb2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e2e4fe8207c5fd06f18911eb1939a70ff2df9bb2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5036,
          name: "Rich Farm",
          uuid: "1d690debbf57d524078874ec2c90986ec799ba24",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1d690debbf57d524078874ec2c90986ec799ba24.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5037,
          name: "Rich Farm Mobile",
          uuid: "e9eb05834909e74140a27e70702dfd229e7df2ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e9eb05834909e74140a27e70702dfd229e7df2ad.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4597,
          name: "Rich Squire",
          uuid: "96a20b535def4d1d97a5a715f10d1bec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/96a20b535def4d1d97a5a715f10d1bec.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4596,
          name: "Rich Squire Mobile",
          uuid: "c0b99b6c940c4d33b4dd46868afc7a92",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c0b99b6c940c4d33b4dd46868afc7a92.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4984,
          name: "Robot Wars",
          uuid: "b21524a25ec72f41ca83124c31685c6af7041430",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b21524a25ec72f41ca83124c31685c6af7041430.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4985,
          name: "Robot Wars Mobile",
          uuid: "17e0d1f89da04129d562f059f0735c3812ea9c2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/17e0d1f89da04129d562f059f0735c3812ea9c2c.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4567,
          name: "Robots",
          uuid: "a9d0da7c295546269c5918aa55a125b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a9d0da7c295546269c5918aa55a125b4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4566,
          name: "Robots Mobile",
          uuid: "b5676d77904445339d882009bd7839d1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b5676d77904445339d882009bd7839d1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5464,
          name: "Rock Parrot",
          uuid: "d08240a03e424d4088c52ce1ea3052ab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d08240a03e424d4088c52ce1ea3052ab.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5465,
          name: "Rock Parrot Mobile",
          uuid: "72fb3753727e4504b4b49f5f0ac8500e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/72fb3753727e4504b4b49f5f0ac8500e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5410,
          name: "Rocket Race",
          uuid: "31075b9bcce4413cb7c3c38f3f6fb86a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/31075b9bcce4413cb7c3c38f3f6fb86a.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5411,
          name: "Rocket Race Mobile",
          uuid: "1f0ca714345c40ec8aaa42e425db706e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1f0ca714345c40ec8aaa42e425db706e.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5134,
          name: "Romance In England",
          uuid: "bd55de2c15294dd0aaf956fee3fe240d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bd55de2c15294dd0aaf956fee3fe240d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5135,
          name: "Romance In England Mobile",
          uuid: "ceaf66c0a2f6453894fa04fa66931273",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ceaf66c0a2f6453894fa04fa66931273.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4263,
          name: "Romance of the Three Kingdoms",
          uuid: "1defa77493db46ef9f53ba67d385e804",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1defa77493db46ef9f53ba67d385e804.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4262,
          name: "Romance of the Three Kingdoms Mobile",
          uuid: "2e133ace663746479d9915e994b7fc5a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2e133ace663746479d9915e994b7fc5a.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5450,
          name: "Romani Secret",
          uuid: "b5b58a79b1fa4d9592b56666beeb2132",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b5b58a79b1fa4d9592b56666beeb2132.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5451,
          name: "Romani Secret Mobile",
          uuid: "75ad3907cde1414c8641d3f41ce28616",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/75ad3907cde1414c8641d3f41ce28616.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5386,
          name: "Romeo And Juliet",
          uuid: "00018917d25147249c2de4af342bb0a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/00018917d25147249c2de4af342bb0a5.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5387,
          name: "Romeo And Juliet Mobile",
          uuid: "962f6d522ebf406fb7967404a03f0e61",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/962f6d522ebf406fb7967404a03f0e61.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4363,
          name: "Rouran Khaganate",
          uuid: "b84077aec42d447e9496e6f2d55b5ba9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b84077aec42d447e9496e6f2d55b5ba9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4362,
          name: "Rouran Khaganate Mobile",
          uuid: "d29ed778594c415eab580d07f8e54764",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d29ed778594c415eab580d07f8e54764.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4619,
          name: "Route 66",
          uuid: "58840a96151a483bba2a143755b2dda6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/58840a96151a483bba2a143755b2dda6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4618,
          name: "Route 66 Mobile",
          uuid: "6c3748b425514ad7bf218da33fdfbd84",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6c3748b425514ad7bf218da33fdfbd84.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4311,
          name: "Royal Demeanor",
          uuid: "9770853e59964ab69eb13eaaf3d6fb58",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9770853e59964ab69eb13eaaf3d6fb58.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4310,
          name: "Royal Demeanor Mobile",
          uuid: "b98aa59554a94cf0b96d91df9b0428bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b98aa59554a94cf0b96d91df9b0428bf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5384,
          name: "Rudolph",
          uuid: "a9287ed26b334fb0b6d630c4971b3250",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a9287ed26b334fb0b6d630c4971b3250.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5385,
          name: "Rudolph Mobile",
          uuid: "e3d03fa11c044cccba6f02a6f1571dab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e3d03fa11c044cccba6f02a6f1571dab.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4655,
          name: "Safari Slots",
          uuid: "27fb959d5f6742709818c64a566c9074",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/27fb959d5f6742709818c64a566c9074.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4654,
          name: "Safari Slots Mobile",
          uuid: "3d46a5f33d91419da87adcb48fb3f674",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3d46a5f33d91419da87adcb48fb3f674.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4876,
          name: "Sailor Man",
          uuid: "7fdbd265cbb697155177e001c53e784ad7615c1b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7fdbd265cbb697155177e001c53e784ad7615c1b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4877,
          name: "Sailor Man Mobile",
          uuid: "940cefd4400b6dbdd8091426234ed9526a48cd7d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/940cefd4400b6dbdd8091426234ed9526a48cd7d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4665,
          name: "Samurai Way",
          uuid: "16a71d3da912470ea8d187097a35b5f3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/16a71d3da912470ea8d187097a35b5f3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4664,
          name: "Samurai Way Mobile",
          uuid: "711256d372e549328eddccdaec5db61b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/711256d372e549328eddccdaec5db61b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4339,
          name: "Sante",
          uuid: "307d0130e4834caba479d16548c8c47e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/307d0130e4834caba479d16548c8c47e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4338,
          name: "Sante Mobile",
          uuid: "a468c077543f405fa1c6f4e93cf0c6a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a468c077543f405fa1c6f4e93cf0c6a9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5148,
          name: "Scary Clown",
          uuid: "cc35f1e4506945c7b4c4a43841652228",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cc35f1e4506945c7b4c4a43841652228.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5149,
          name: "Scary Clown Mobile",
          uuid: "fff4e44c5f974323bec49de632ec4950",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fff4e44c5f974323bec49de632ec4950.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5532,
          name: "Scientists Legends Lock 2 spin",
          uuid: "d87eecd593b24289afccbd54ec70039d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d87eecd593b24289afccbd54ec70039d.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5533,
          name: "Scientists Legends Lock 2 spin Mobile",
          uuid: "ede213d7a66546599cb95e007bc2e93d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ede213d7a66546599cb95e007bc2e93d.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4952,
          name: "Seagull",
          uuid: "a2a6fb6f60e8cd653ab406f1ad11123891eb678a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a2a6fb6f60e8cd653ab406f1ad11123891eb678a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4953,
          name: "Seagull Mobile",
          uuid: "66feb214d4b6e1e007b056def771df3131d53da1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/66feb214d4b6e1e007b056def771df3131d53da1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4970,
          name: "Secret Agent",
          uuid: "499aaddfbaea26b958bdacbc4e5665dbabab60d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/499aaddfbaea26b958bdacbc4e5665dbabab60d7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4971,
          name: "Secret Agent Mobile",
          uuid: "b051e60ab4d247095cb511c2462b5ac30cf004e2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b051e60ab4d247095cb511c2462b5ac30cf004e2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4840,
          name: "Secret of Ocean",
          uuid: "e8cfe78f333b44678a7d3ac02c436dc3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e8cfe78f333b44678a7d3ac02c436dc3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4841,
          name: "Secret of Ocean Mobile",
          uuid: "c154b8eab7c04884a338b0f129a4e0e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c154b8eab7c04884a338b0f129a4e0e1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5104,
          name: "Seediq",
          uuid: "bd015c4ca3f843ff81e25791d1664ce1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bd015c4ca3f843ff81e25791d1664ce1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5105,
          name: "Seediq Mobile",
          uuid: "29b2154d626641fcaa963489602fbd72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/29b2154d626641fcaa963489602fbd72.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5392,
          name: "Seven Sinners",
          uuid: "af51291857124013b5b213ee74090c76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/af51291857124013b5b213ee74090c76.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5393,
          name: "Seven Sinners Mobile",
          uuid: "87a4f1f956e348d0b8bc28a4b16a3a87",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/87a4f1f956e348d0b8bc28a4b16a3a87.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5052,
          name: "Sexy Lips",
          uuid: "52d3c35bd31a46a49d3d621b3e59713f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/52d3c35bd31a46a49d3d621b3e59713f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5053,
          name: "Sexy Lips Mobile",
          uuid: "16de54bef22341a58323060bafc847e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/16de54bef22341a58323060bafc847e6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4457,
          name: "Shadow Play",
          uuid: "28b7c8e419c2485b8fb2ee3c5c4bec57",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/28b7c8e419c2485b8fb2ee3c5c4bec57.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4456,
          name: "Shadow Play Mobile",
          uuid: "ecd1fffe2253463ab3f9b20bc3cda838",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ecd1fffe2253463ab3f9b20bc3cda838.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4904,
          name: "Shaolin Legend",
          uuid: "8fd2ea40d18faebf5b89fa3f5c76a23e94932361",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8fd2ea40d18faebf5b89fa3f5c76a23e94932361.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4905,
          name: "Shaolin Legend Mobile",
          uuid: "ef2ebac2fde7ac3212b246703265668bdf141525",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ef2ebac2fde7ac3212b246703265668bdf141525.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5042,
          name: "Shaolin Soccer",
          uuid: "ed33f668c0a46644a07ce0ae7678803903a7903d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ed33f668c0a46644a07ce0ae7678803903a7903d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5043,
          name: "Shaolin Soccer Mobile",
          uuid: "5bdd6ffa61d667bdf29fd3a22e3544b0ac3b29ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5bdd6ffa61d667bdf29fd3a22e3544b0ac3b29ce.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5558,
          name: "Shark Bite",
          uuid: "5476c6f68d7e452fa83cd222d264fba5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5476c6f68d7e452fa83cd222d264fba5.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5559,
          name: "Shark Bite Mobile",
          uuid: "bbb1e1eccd8f4da09d15357a026828dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bbb1e1eccd8f4da09d15357a026828dd.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5298,
          name: "Shark Fight",
          uuid: "62cfa0dc724b495081974741567af374",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/62cfa0dc724b495081974741567af374.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5299,
          name: "Shark Fight Mobile",
          uuid: "f1daadd6471f48c98d53d826d6d208f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f1daadd6471f48c98d53d826d6d208f6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5290,
          name: "Sharpshooter",
          uuid: "237bf087854b4425808ba20cf24768e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/237bf087854b4425808ba20cf24768e7.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5291,
          name: "Sharpshooter Mobile",
          uuid: "93567f9101ed4f679d6fd1305aeb9417",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/93567f9101ed4f679d6fd1305aeb9417.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4892,
          name: "Shennong",
          uuid: "2d85a10f1ab75b4d7a24e98fc94b6b7bc4d763cb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2d85a10f1ab75b4d7a24e98fc94b6b7bc4d763cb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4893,
          name: "Shennong Mobile",
          uuid: "8488c178639d0f6a61cc9575b3e8875baeb68c20",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8488c178639d0f6a61cc9575b3e8875baeb68c20.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5310,
          name: "Shenron Hunter",
          uuid: "10ec5b547143465883079c58a49de4ee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/10ec5b547143465883079c58a49de4ee.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5311,
          name: "Shenron Hunter Mobile",
          uuid: "96872468fd994d569f65d8b339f13dc6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/96872468fd994d569f65d8b339f13dc6.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4389,
          name: "Shock Tower",
          uuid: "03a47a8409284625ac4effaeaa20a58d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/03a47a8409284625ac4effaeaa20a58d.png",
          type: "other",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4388,
          name: "Shock Tower Mobile",
          uuid: "166975168baf47b4847fa6494ad4560b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/166975168baf47b4847fa6494ad4560b.png",
          type: "other",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4561,
          name: "Shopping Fiend",
          uuid: "74cb5a4e3bac4ece954dbfa66e71ed3d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/74cb5a4e3bac4ece954dbfa66e71ed3d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4560,
          name: "Shopping Fiend Mobile",
          uuid: "4288f81922d044fab6740491626f0dcc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4288f81922d044fab6740491626f0dcc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5642,
          name: "Shopping Mall Tycoon",
          uuid: "3c27a930809f427db78be8a1dfda34a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3c27a930809f427db78be8a1dfda34a9.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5643,
          name: "Shopping Mall Tycoon Mobile",
          uuid: "d3a8065d40874f40a0c8c1950ec16571",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d3a8065d40874f40a0c8c1950ec16571.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5437,
          name: "Shrove Tuesday",
          uuid: "8bcac48031ab1f8ec11b0141f72a28a343ba0137",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8bcac48031ab1f8ec11b0141f72a28a343ba0137.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5436,
          name: "Shrove Tuesday Mobile",
          uuid: "bf5814c3b80dfce45d6e9f2ea7ebe2ce9f774a28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bf5814c3b80dfce45d6e9f2ea7ebe2ce9f774a28.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4527,
          name: "Siberian Wolves",
          uuid: "3d0d63357f3d4d52b42875dab6bbf082",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3d0d63357f3d4d52b42875dab6bbf082.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4526,
          name: "Siberian Wolves Mobile",
          uuid: "8172c09ade7e407b8d5e0ba58a60f85c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8172c09ade7e407b8d5e0ba58a60f85c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4733,
          name: "Silk Road",
          uuid: "1f249d2ff14f4a07b09a7e4ccd3ed6cb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1f249d2ff14f4a07b09a7e4ccd3ed6cb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4732,
          name: "Silk Road Mobile",
          uuid: "8025e403d6b7446b861348974aad8c22",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8025e403d6b7446b861348974aad8c22.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5066,
          name: "Sinbad",
          uuid: "820c1f2f3e174bb9b2057e250eca851b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/820c1f2f3e174bb9b2057e250eca851b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5067,
          name: "Sinbad Mobile",
          uuid: "106c145a320e4a5e8b4d86e303b95b0d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/106c145a320e4a5e8b4d86e303b95b0d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4796,
          name: "Sky Force",
          uuid: "2fb6698479d04368856c6bba10a66525",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2fb6698479d04368856c6bba10a66525.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4797,
          name: "Sky Force Mobile",
          uuid: "20203105edf34416b864df229d5a1197",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/20203105edf34416b864df229d5a1197.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5206,
          name: "Slap It",
          uuid: "52888e0c25564c17b45c60e48cb93ceb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/52888e0c25564c17b45c60e48cb93ceb.png",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5207,
          name: "Slap It Mobile",
          uuid: "de3740e60cef45f3a85538c745b4efe2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/de3740e60cef45f3a85538c745b4efe2.png",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4944,
          name: "Sleepwalking Sheep",
          uuid: "36b6d77b845ed0a164b2dc7708cf5e8ed0c52f56",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/36b6d77b845ed0a164b2dc7708cf5e8ed0c52f56.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4945,
          name: "Sleepwalking Sheep Mobile",
          uuid: "b81be13771bd63c05da8321ff236a28e8e8a4074",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b81be13771bd63c05da8321ff236a28e8e8a4074.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5142,
          name: "Snow Goddess",
          uuid: "f043127c114f49d9b97ee624740798e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f043127c114f49d9b97ee624740798e4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5143,
          name: "Snow Goddess Mobile",
          uuid: "d990228578d2496880a492f585c34dd4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d990228578d2496880a492f585c34dd4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4613,
          name: "Snow Leopards",
          uuid: "62cc0ec577824d72ab5139606a379a45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/62cc0ec577824d72ab5139606a379a45.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4612,
          name: "Snow Leopards Mobile",
          uuid: "744586ddbada4dfd80187b25d34d59e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/744586ddbada4dfd80187b25d34d59e6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4291,
          name: "Snow Queen",
          uuid: "684edbed8db8466988636b0ba890ce34",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/684edbed8db8466988636b0ba890ce34.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4290,
          name: "Snow Queen Mobile",
          uuid: "cb207df605f9419ba948487fab69c0b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cb207df605f9419ba948487fab69c0b7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4489,
          name: "Snow White",
          uuid: "bcd9a7b8d1b64ce2b54b804c76d2ef57",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bcd9a7b8d1b64ce2b54b804c76d2ef57.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4488,
          name: "Snow White Mobile",
          uuid: "9e22888462774b3cbc4a2eed0287f9b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9e22888462774b3cbc4a2eed0287f9b4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4771,
          name: "SNS Friends",
          uuid: "3375c4a485cc4c93aad0cf63839e141d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3375c4a485cc4c93aad0cf63839e141d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4770,
          name: "SNS Friends Mobile",
          uuid: "5d699b6b46344ed09927ccd2d823547f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5d699b6b46344ed09927ccd2d823547f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4822,
          name: "Soldiers",
          uuid: "a9b1a08a82c3449b828ffa54eaac96f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a9b1a08a82c3449b828ffa54eaac96f4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4823,
          name: "Soldiers Mobile",
          uuid: "cc230b8263594d79b8e32951bf1a4d94",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cc230b8263594d79b8e32951bf1a4d94.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4916,
          name: "Solomons Treasure",
          uuid: "24491e058d41c18815d2bbcf93820301a0e89612",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/24491e058d41c18815d2bbcf93820301a0e89612.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4917,
          name: "Solomons Treasure Mobile",
          uuid: "b8c68ff15f9263fd99cef477d46c95b1ed8a9e92",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b8c68ff15f9263fd99cef477d46c95b1ed8a9e92.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5485,
          name: "Songkran Warfare",
          uuid: "b9fade935af3611b974542e0139835316e5f9873",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b9fade935af3611b974542e0139835316e5f9873.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5484,
          name: "Songkran Warfare Mobile",
          uuid: "a80ad109a04ad90265adb571760385d4b5c5a0bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a80ad109a04ad90265adb571760385d4b5c5a0bb.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5638,
          name: "Sorcerer and Demon",
          uuid: "96365a9732c7450685c9e65f4efc6ccc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/96365a9732c7450685c9e65f4efc6ccc.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5639,
          name: "Sorcerer and Demon Mobile",
          uuid: "4a7349153e914511917e83ae3f61f1d0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4a7349153e914511917e83ae3f61f1d0.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5188,
          name: "Soul Gems",
          uuid: "f918b0b395cd4dbe90b51757ae03d7ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f918b0b395cd4dbe90b51757ae03d7ce.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5189,
          name: "Soul Gems Mobile",
          uuid: "7962d444c4a4464b8ffd5748c9cc7a3a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7962d444c4a4464b8ffd5748c9cc7a3a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4269,
          name: "Space Cat",
          uuid: "6fdaa320813842f88b43c81febf4e080",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6fdaa320813842f88b43c81febf4e080.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4268,
          name: "Space Cat Mobile",
          uuid: "37b7397c7ad441cba680acc6b962db17",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/37b7397c7ad441cba680acc6b962db17.png",
          type: "fish/shooting",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4407,
          name: "Space Storm",
          uuid: "3d3aca18525749379a511ef5dc500d4e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3d3aca18525749379a511ef5dc500d4e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4406,
          name: "Space Storm Mobile",
          uuid: "e445af394081425bbad7358a16f85168",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e445af394081425bbad7358a16f85168.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5596,
          name: "Spartan",
          uuid: "0c3571ba58e74687b37aaf27f5c6d4e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0c3571ba58e74687b37aaf27f5c6d4e1.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5597,
          name: "Spartan Mobile",
          uuid: "e4f47175e80f4f8688ca249ed6247f27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e4f47175e80f4f8688ca249ed6247f27.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4535,
          name: "Speakeasy",
          uuid: "84dad5a56ffd4bdbbb01e34000b82d9a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/84dad5a56ffd4bdbbb01e34000b82d9a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5390,
          name: "Speakeasy 2",
          uuid: "6c445331f5ee47b591b4c16a5e79f655",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6c445331f5ee47b591b4c16a5e79f655.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5391,
          name: "Speakeasy 2 Mobile",
          uuid: "cfa711ae1ae3415da8c0dbb1659d9b5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cfa711ae1ae3415da8c0dbb1659d9b5c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4534,
          name: "Speakeasy Mobile",
          uuid: "c13267dc87c94aa0b2afd7cc0e5fe2a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c13267dc87c94aa0b2afd7cc0e5fe2a5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5002,
          name: "Special OPS",
          uuid: "aa05ea81ba5f475197a9c38655feae24",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/aa05ea81ba5f475197a9c38655feae24.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5003,
          name: "Special OPS Mobile",
          uuid: "c6f12eb81f974659a08441645e7d4dcf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c6f12eb81f974659a08441645e7d4dcf.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5489,
          name: "Speed King",
          uuid: "b4ccb4381fe2cd147e6da53aaf80255a0675306e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b4ccb4381fe2cd147e6da53aaf80255a0675306e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5488,
          name: "Speed King Mobile",
          uuid: "625f63d1fa831f62a31d38c99ab0d32154170883",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/625f63d1fa831f62a31d38c99ab0d32154170883.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5162,
          name: "Spider Goblin",
          uuid: "55416ae167164726a507e98ba84025c5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/55416ae167164726a507e98ba84025c5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5163,
          name: "Spider Goblin Mobile",
          uuid: "a17a19587b464f1787a8e595e16d0890",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a17a19587b464f1787a8e595e16d0890.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4667,
          name: "Spinning In Space",
          uuid: "44f1d158df34465bad41c87075da9c2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/44f1d158df34465bad41c87075da9c2c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4666,
          name: "Spinning In Space Mobile",
          uuid: "775bb87a134d46a2a02b5768f18b7758",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/775bb87a134d46a2a02b5768f18b7758.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4485,
          name: "Spring Blossom",
          uuid: "8eefb58d6a3d4a24b2df68fda1e436d1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8eefb58d6a3d4a24b2df68fda1e436d1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4484,
          name: "Spring Blossom Mobile",
          uuid: "f5fb27605bb24d5d80b8aaf8b7bc7949",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f5fb27605bb24d5d80b8aaf8b7bc7949.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4990,
          name: "Squid Party",
          uuid: "bccedc19cb456be5427b3a4732bc2a3122cd6467",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bccedc19cb456be5427b3a4732bc2a3122cd6467.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4991,
          name: "Squid Party Mobile",
          uuid: "596240d90397e551597e84aefd6a8c94dc2c5d8c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/596240d90397e551597e84aefd6a8c94dc2c5d8c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5296,
          name: "Steampunk",
          uuid: "8a6d6a2936ac451ba9be810b9efe113f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8a6d6a2936ac451ba9be810b9efe113f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5297,
          name: "Steampunk Mobile",
          uuid: "c71d2df41d3e4966bc0b16f505d9f47c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c71d2df41d3e4966bc0b16f505d9f47c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4705,
          name: "Stocked Bar",
          uuid: "064ea01ebc5041b29b3e10453dc23364",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/064ea01ebc5041b29b3e10453dc23364.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4704,
          name: "Stocked Bar Mobile",
          uuid: "d976d464de2c4fd98e4f5c6975fb6ac5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d976d464de2c4fd98e4f5c6975fb6ac5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5340,
          name: "Stone Age",
          uuid: "b91541ba0fd348f38c4fd01135ec11e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b91541ba0fd348f38c4fd01135ec11e1.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5341,
          name: "Stone Age Mobile",
          uuid: "f9dbcadd849a44e0a20ac1d46cac1418",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f9dbcadd849a44e0a20ac1d46cac1418.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4731,
          name: "Stonehenge",
          uuid: "747699ef613b4ece8059073351a71b6e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/747699ef613b4ece8059073351a71b6e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4730,
          name: "Stonehenge Mobile",
          uuid: "f42034838b694df1b5db661485b34072",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f42034838b694df1b5db661485b34072.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5546,
          name: "Story of Farmer",
          uuid: "90cb2a87604d4a018ba1b049d52bf9a3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/90cb2a87604d4a018ba1b049d52bf9a3.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5547,
          name: "Story of Farmer Mobile",
          uuid: "94b7ea665713435c9bae2dd32f08255a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/94b7ea665713435c9bae2dd32f08255a.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5556,
          name: "Story Of Jing Jiang",
          uuid: "a3d79c010052430791b9209409e5ea77",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a3d79c010052430791b9209409e5ea77.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5557,
          name: "Story Of Jing Jiang Mobile",
          uuid: "7572092599c647dab555c956186c479e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7572092599c647dab555c956186c479e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4541,
          name: "Street Racing",
          uuid: "2dd61f7d8c54401eb0feb2dd5b54d77f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2dd61f7d8c54401eb0feb2dd5b54d77f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4540,
          name: "Street Racing Mobile",
          uuid: "1bddd6295f154e84b0dfa45f06f372ca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1bddd6295f154e84b0dfa45f06f372ca.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5584,
          name: "Submariner",
          uuid: "a275a0347ca042cd8f325874b87514f2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a275a0347ca042cd8f325874b87514f2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5585,
          name: "Submariner Mobile",
          uuid: "ddfde7eabd024aed9bc39a4dda251b1c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ddfde7eabd024aed9bc39a4dda251b1c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5114,
          name: "Summer Samba",
          uuid: "a98e51f5e03b494cb81fd7cc91c32666",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a98e51f5e03b494cb81fd7cc91c32666.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5115,
          name: "Summer Samba Mobile",
          uuid: "9c3b6d4981034e05802771d45e748afe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9c3b6d4981034e05802771d45e748afe.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5062,
          name: "Sumo",
          uuid: "4c1200c4014845b289906be8c9359dab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4c1200c4014845b289906be8c9359dab.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5063,
          name: "Sumo Mobile",
          uuid: "75114db9b61448fd931d9a93a16eb290",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/75114db9b61448fd931d9a93a16eb290.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4479,
          name: "Sunny Bikini",
          uuid: "e76ec8f52fe24a64b618063eb03788e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e76ec8f52fe24a64b618063eb03788e6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4478,
          name: "Sunny Bikini Mobile",
          uuid: "1f67cb34747b4f2d9c9233ed844905b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1f67cb34747b4f2d9c9233ed844905b8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4313,
          name: "Super Bonus Mania",
          uuid: "b43bbaba22744ebe886ba4923a3e1e82",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b43bbaba22744ebe886ba4923a3e1e82.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4312,
          name: "Super Bonus Mania Mobile",
          uuid: "6e1dd32e44b94c1d96b0a4c5e73874bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6e1dd32e44b94c1d96b0a4c5e73874bf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4930,
          name: "Super Energy",
          uuid: "8cac79f16c750b57a6c75e546705946f27ed24da",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8cac79f16c750b57a6c75e546705946f27ed24da.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4931,
          name: "Super Energy Mobile",
          uuid: "e4dd3a987a57b082cd273cc2c75a08149bd86cdb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e4dd3a987a57b082cd273cc2c75a08149bd86cdb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4709,
          name: "Super Keno",
          uuid: "6103712a2f1d4092a340abd297362beb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6103712a2f1d4092a340abd297362beb.png",
          type: "keno",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4708,
          name: "Super Keno Mobile",
          uuid: "685f7a55edec4f6f8e37879c15bbce9f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/685f7a55edec4f6f8e37879c15bbce9f.png",
          type: "keno",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4890,
          name: "Super Slime",
          uuid: "61c04219f6e161a45011e782b96455c656b5d724",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/61c04219f6e161a45011e782b96455c656b5d724.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4891,
          name: "Super Slime Mobile",
          uuid: "6d7d2884953cd8160fce8cdf309d99f5b6881c46",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6d7d2884953cd8160fce8cdf309d99f5b6881c46.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4711,
          name: "Super Video Poker",
          uuid: "2e7cf5c6c6864a9e9bc9eb2b6654d726",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2e7cf5c6c6864a9e9bc9eb2b6654d726.png",
          type: "poker",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4710,
          name: "Super Video Poker Mobile",
          uuid: "d6e9e4537fb045c6984dffd4ea979680",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d6e9e4537fb045c6984dffd4ea979680.png",
          type: "poker",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4295,
          name: "SuperShot",
          uuid: "4c58f4e084ba4ec98661cef8764a8658",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4c58f4e084ba4ec98661cef8764a8658.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4323,
          name: "SuperShot 2",
          uuid: "86c68a34de6c4210a234ac1120e59971",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/86c68a34de6c4210a234ac1120e59971.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4322,
          name: "SuperShot 2 Mobile",
          uuid: "1eea9be86d43413da468f5ac358582df",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1eea9be86d43413da468f5ac358582df.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4294,
          name: "SuperShot Mobile",
          uuid: "8e4b2d1c4874458ab32dcd3811feea63",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8e4b2d1c4874458ab32dcd3811feea63.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5304,
          name: "Surprise Box",
          uuid: "cb015644bc03445bb2481986fa605059",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cb015644bc03445bb2481986fa605059.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5305,
          name: "Surprise Box Mobile",
          uuid: "72341791bf8149118360652d3701d4f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/72341791bf8149118360652d3701d4f7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4377,
          name: "Sushi Ninja",
          uuid: "f7955a86b97e4dbb85008c167f19ecb6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f7955a86b97e4dbb85008c167f19ecb6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4376,
          name: "Sushi Ninja Mobile",
          uuid: "c0659fb8070442fdaf34657e81bf36f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c0659fb8070442fdaf34657e81bf36f8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4808,
          name: "Sweet Maid",
          uuid: "c37c938ca3bf4e61b7f4ae5215425fdc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c37c938ca3bf4e61b7f4ae5215425fdc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4809,
          name: "Sweet Maid Mobile",
          uuid: "b0b35f0edb7540f0a2bfe5d9d42a133c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b0b35f0edb7540f0a2bfe5d9d42a133c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4812,
          name: "Sweetopia",
          uuid: "d3ebed446cc9459e90fec134eb812f84",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d3ebed446cc9459e90fec134eb812f84.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4813,
          name: "Sweetopia Mobile",
          uuid: "26e6fa7af46947758e207e63e68171cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/26e6fa7af46947758e207e63e68171cf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5570,
          name: "Symphony Fantasia Lock 2 Spin",
          uuid: "fc07d3123d064ba38c070c2c24ee0ec3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fc07d3123d064ba38c070c2c24ee0ec3.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5571,
          name: "Symphony Fantasia Lock 2 Spin Mobile",
          uuid: "f6d54416a32d447a9a00b14615e68a64",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f6d54416a32d447a9a00b14615e68a64.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4509,
          name: "Tai Chi",
          uuid: "0b1081c6ea174d8e884a7ae1062ed30d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0b1081c6ea174d8e884a7ae1062ed30d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4508,
          name: "Tai Chi Mobile",
          uuid: "0ce2daad8f984f6d8836088cb23af191",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0ce2daad8f984f6d8836088cb23af191.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5374,
          name: "Taiko Master",
          uuid: "9cbd4371b1fc4bf2b53d93cbaca3e877",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9cbd4371b1fc4bf2b53d93cbaca3e877.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5375,
          name: "Taiko Master Mobile",
          uuid: "4b4d6cda764643f8a7d94a98cde6d96c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4b4d6cda764643f8a7d94a98cde6d96c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4743,
          name: "Taiwan Black Bear",
          uuid: "40bbd18482604416a0dad26b8dc6b332",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/40bbd18482604416a0dad26b8dc6b332.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4742,
          name: "Taiwan Black Bear Mobile",
          uuid: "676f270862ff41318985d62923baa050",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/676f270862ff41318985d62923baa050.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5176,
          name: "Taketori Monogatari",
          uuid: "bf18d1abbc7341d0ad7c63982c6a6598",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bf18d1abbc7341d0ad7c63982c6a6598.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5177,
          name: "Taketori Monogatari Mobile",
          uuid: "d63addf050e64ec89c08ab050067e14b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d63addf050e64ec89c08ab050067e14b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5380,
          name: "Tanuki Tavern",
          uuid: "b6d99f2bedb14f1bb35a2d77dd1bd9f2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b6d99f2bedb14f1bb35a2d77dd1bd9f2.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5381,
          name: "Tanuki Tavern Mobile",
          uuid: "e151a5d3e50047f3a782109a518c5969",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e151a5d3e50047f3a782109a518c5969.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4643,
          name: "Tao",
          uuid: "27df0dff8b4449e3aa842354cfdbd4e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/27df0dff8b4449e3aa842354cfdbd4e8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4642,
          name: "Tao Mobile",
          uuid: "36682a0787ed4b289f9ab3a709ea67e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/36682a0787ed4b289f9ab3a709ea67e7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5332,
          name: "Tap Heroes",
          uuid: "a1215df090264f41b37b0278090e945c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a1215df090264f41b37b0278090e945c.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5333,
          name: "Tap Heroes Mobile",
          uuid: "cf672e78f33a4d42a9c8f49b739bc594",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/cf672e78f33a4d42a9c8f49b739bc594.jpg",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5564,
          name: "Ten Jin Matsuri",
          uuid: "f2d7eb3d7edd4d99aa2ccc331f83ef15",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f2d7eb3d7edd4d99aa2ccc331f83ef15.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5565,
          name: "Ten Jin Matsuri Mobile",
          uuid: "ead649c1775a45fca3f47018671e27bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ead649c1775a45fca3f47018671e27bb.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4625,
          name: "The Apes",
          uuid: "249a05d0e2414bf5ae2038276c882d5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/249a05d0e2414bf5ae2038276c882d5d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4624,
          name: "The Apes Mobile",
          uuid: "25a38c2c295f4bd9bf029c876c65bbaf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/25a38c2c295f4bd9bf029c876c65bbaf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4910,
          name: "The Boy Who Cried Wolf",
          uuid: "800bd27921eb279aa055c2566e86870e6fa230ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/800bd27921eb279aa055c2566e86870e6fa230ae.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4911,
          name: "The Boy Who Cried Wolf Mobile",
          uuid: "413b91a113c4b16e1abe1bb8338847cb3fb45eda",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/413b91a113c4b16e1abe1bb8338847cb3fb45eda.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5448,
          name: "The Crypto",
          uuid: "6cadf7ff40274f83a0b88893bf10ad51",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6cadf7ff40274f83a0b88893bf10ad51.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5449,
          name: "The Crypto Mobile",
          uuid: "3267a3dbafcb44499fa2375e2372a9fd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3267a3dbafcb44499fa2375e2372a9fd.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4391,
          name: "The Door Gods",
          uuid: "ee62043a42844f199a9480a28c3a9873",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ee62043a42844f199a9480a28c3a9873.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4390,
          name: "The Door Gods Mobile",
          uuid: "942b024cdeca4608aa42a065ac51b8a8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/942b024cdeca4608aa42a065ac51b8a8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4545,
          name: "The Four Scholars",
          uuid: "11cebef877fb4118af4c8a5a6ea4f875",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/11cebef877fb4118af4c8a5a6ea4f875.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4544,
          name: "The Four Scholars Mobile",
          uuid: "3d0501249dec49bc9c26043b5aeb4c78",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3d0501249dec49bc9c26043b5aeb4c78.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5330,
          name: "The Frog Prince",
          uuid: "20bc7a9aec624c9480649e58f1037b88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/20bc7a9aec624c9480649e58f1037b88.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5331,
          name: "The Frog Prince Mobile",
          uuid: "83fe6ff8694a4c9cb2d199133e8da651",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/83fe6ff8694a4c9cb2d199133e8da651.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4411,
          name: "The Gingerbread Land",
          uuid: "44ea8852674f473c89724187b7ffbd53",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/44ea8852674f473c89724187b7ffbd53.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4410,
          name: "The Gingerbread Land Mobile",
          uuid: "2bbcd897948a4615bf34bec43bdb2997",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2bbcd897948a4615bf34bec43bdb2997.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4465,
          name: "The Golden Ax",
          uuid: "9f7340e71c5e44618807ed0b70508278",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9f7340e71c5e44618807ed0b70508278.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4464,
          name: "The Golden Ax Mobile",
          uuid: "57dc763a31204487bbd2f8082b12c09e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/57dc763a31204487bbd2f8082b12c09e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4505,
          name: "The Grandmaster",
          uuid: "b524c4b23cbb40c2a2366d6fc47a5ef1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b524c4b23cbb40c2a2366d6fc47a5ef1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4504,
          name: "The Grandmaster Mobile",
          uuid: "bee8d06a3c6e47f9ae382832a9cb797f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bee8d06a3c6e47f9ae382832a9cb797f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4335,
          name: "The Great Voyages",
          uuid: "0c78fbd893914766ac115b6f6db18a07",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0c78fbd893914766ac115b6f6db18a07.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4334,
          name: "The Great Voyages Mobile",
          uuid: "a680c4280d78437a9d137d58b037ab82",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a680c4280d78437a9d137d58b037ab82.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4802,
          name: "The Guard of Hades",
          uuid: "93c1c0ac01d446a8999268852e929fc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/93c1c0ac01d446a8999268852e929fc8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4803,
          name: "The Guard of Hades Mobile",
          uuid: "1c93e05320294bfebd4e0099e04aed73",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1c93e05320294bfebd4e0099e04aed73.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4745,
          name: "The King of Dinosaurs",
          uuid: "145a223120f64008b53823ab8513de44",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/145a223120f64008b53823ab8513de44.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4744,
          name: "The King of Dinosaurs Mobile",
          uuid: "0a23953f7337481cb4d7f8387b43da4c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0a23953f7337481cb4d7f8387b43da4c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5056,
          name: "The King Producer",
          uuid: "bec35ecb39fe4767afd6585f6dc67b95",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bec35ecb39fe4767afd6585f6dc67b95.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5057,
          name: "The King Producer Mobile",
          uuid: "535253240006454189ee62639aa56ca5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/535253240006454189ee62639aa56ca5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4856,
          name: "The Legend of Heroes",
          uuid: "4817ac0f609948ebaae0e2b71f1100c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4817ac0f609948ebaae0e2b71f1100c0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4857,
          name: "The Legend of Heroes Mobile",
          uuid: "1d98ca1a9f114c088afb5a578c5c570f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1d98ca1a9f114c088afb5a578c5c570f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5230,
          name: "The Little Prince",
          uuid: "b00491dc383744cfbd39a7f747e39328",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b00491dc383744cfbd39a7f747e39328.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5231,
          name: "The Little Prince Mobile",
          uuid: "9ae48784829a47bc8908a56e1802324b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9ae48784829a47bc8908a56e1802324b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4423,
          name: "The Lotus Lamp",
          uuid: "50b245d70b704325936858a58b1070f3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/50b245d70b704325936858a58b1070f3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4422,
          name: "The Lotus Lamp Mobile",
          uuid: "3fdb33a48ded4d56ba210cedcf2720ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3fdb33a48ded4d56ba210cedcf2720ea.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4557,
          name: "The Mask of Zorro",
          uuid: "c3286825706b4d9094caaa7f4dd332ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c3286825706b4d9094caaa7f4dd332ff.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4556,
          name: "The Mask of Zorro Mobile",
          uuid: "d7abe0998b0d46f6ae234c1def28e8d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d7abe0998b0d46f6ae234c1def28e8d2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5326,
          name: "The Master Cat",
          uuid: "40ec6a72f0f84037a4f4d2c2f4b5f996",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/40ec6a72f0f84037a4f4d2c2f4b5f996.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5327,
          name: "The Master Cat Mobile",
          uuid: "6634525a32e84448b0339c9cd101fc4d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6634525a32e84448b0339c9cd101fc4d.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4553,
          name: "The Nut Cracker",
          uuid: "89a0779da78c487e854101c84c7b0ca0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/89a0779da78c487e854101c84c7b0ca0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4552,
          name: "The Nut Cracker Mobile",
          uuid: "d902e547072c4ed0aceb413deabd6942",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d902e547072c4ed0aceb413deabd6942.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5266,
          name: "The Peony Pavilion",
          uuid: "5450a831984e4ce4b0ffa7dc1e7dba90",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5450a831984e4ce4b0ffa7dc1e7dba90.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5267,
          name: "The Peony Pavilion Mobile",
          uuid: "efe90ccce4e3429eb8d2cb9dbd3f7ea0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/efe90ccce4e3429eb8d2cb9dbd3f7ea0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4824,
          name: "The West Chamber",
          uuid: "3cebbabc68dc4f038735367b96c06a43",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3cebbabc68dc4f038735367b96c06a43.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4825,
          name: "The West Chamber Mobile",
          uuid: "d8da5183136643188ad3a1a08b75207c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d8da5183136643188ad3a1a08b75207c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5446,
          name: "The Wild Four",
          uuid: "c7a6a03540074f1397781d677f026c86",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c7a6a03540074f1397781d677f026c86.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5447,
          name: "The Wild Four Mobile",
          uuid: "7d363dba01244b58bf16efdcff09d4e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7d363dba01244b58bf16efdcff09d4e3.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4471,
          name: "The Wizard of Oz",
          uuid: "a705b6ded00b4ce497a14bf394f4cbdb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a705b6ded00b4ce497a14bf394f4cbdb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4470,
          name: "The Wizard of Oz Mobile",
          uuid: "a6bd6cbb1d674ba2b2996dfde7e5c867",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a6bd6cbb1d674ba2b2996dfde7e5c867.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5568,
          name: "The Wolf of Wall Street",
          uuid: "96b5f2db40284f088d512ea7434d0b5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/96b5f2db40284f088d512ea7434d0b5c.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5569,
          name: "The Wolf of Wall Street Mobile",
          uuid: "ecd457ed55b540f19244e99e0373f099",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ecd457ed55b540f19244e99e0373f099.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5318,
          name: "Thief",
          uuid: "fc774a9646ce414eb561faabcf6338c8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fc774a9646ce414eb561faabcf6338c8.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5319,
          name: "Thief Mobile",
          uuid: "37d07b80127a457ea320bd19868f56c4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/37d07b80127a457ea320bd19868f56c4.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4854,
          name: "Three Bandits",
          uuid: "7f697048f0334b94af7cfbd4f5002f9a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7f697048f0334b94af7cfbd4f5002f9a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4855,
          name: "Three Bandits Mobile",
          uuid: "159c8022ddcc465dbcae01ab3274dc19",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/159c8022ddcc465dbcae01ab3274dc19.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4585,
          name: "Three Gods",
          uuid: "d655e1a8b832466b9ab78f96730d9be2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d655e1a8b832466b9ab78f96730d9be2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4584,
          name: "Three Gods Mobile",
          uuid: "1cb68ab2f8cc4f249ff1db2f213044d6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1cb68ab2f8cc4f249ff1db2f213044d6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4936,
          name: "Three Headed Dragon",
          uuid: "53fe2c4534e11433b457a553d4bdcbe0fb8f6640",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/53fe2c4534e11433b457a553d4bdcbe0fb8f6640.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4937,
          name: "Three Headed Dragon Mobile",
          uuid: "8879bf8a32596cc1fa8a86b5dffd3bf0bac09db5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8879bf8a32596cc1fa8a86b5dffd3bf0bac09db5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4607,
          name: "Three Heroes",
          uuid: "93596342da254f09800127b73b465f4b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/93596342da254f09800127b73b465f4b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4606,
          name: "Three Heroes Mobile",
          uuid: "f81fe47cdd714d3ea336c3e202fd27c9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f81fe47cdd714d3ea336c3e202fd27c9.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4659,
          name: "Three Little Pigs",
          uuid: "6f966156ec1644afb17161acc1c66b9f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6f966156ec1644afb17161acc1c66b9f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4658,
          name: "Three Little Pigs Mobile",
          uuid: "601f88d45f3647b881eeb79f6ee3fedf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/601f88d45f3647b881eeb79f6ee3fedf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5122,
          name: "Three Monkeys",
          uuid: "f026d5aa223f4137baafbc56ef48a835",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f026d5aa223f4137baafbc56ef48a835.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5123,
          name: "Three Monkeys Mobile",
          uuid: "5b24ead4afd442488786fe251068e034",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/5b24ead4afd442488786fe251068e034.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5534,
          name: "Thumbelina",
          uuid: "7d46c32aaab744c9bc3d430eb765f4a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7d46c32aaab744c9bc3d430eb765f4a5.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5535,
          name: "Thumbelina Mobile",
          uuid: "8a509493f4ca409f8706240b793ef30e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8a509493f4ca409f8706240b793ef30e.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5441,
          name: "Thunder God",
          uuid: "71d7ec46689f5238b7a5e73974ecf7b0145a6081",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/71d7ec46689f5238b7a5e73974ecf7b0145a6081.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5440,
          name: "Thunder God Mobile",
          uuid: "6d10d6270d21db300b3884b09b436da570755049",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6d10d6270d21db300b3884b09b436da570755049.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5208,
          name: "Thunder Land",
          uuid: "c218c063dd9f4cd2a453baed0fda2650",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c218c063dd9f4cd2a453baed0fda2650.png",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5209,
          name: "Thunder Land Mobile",
          uuid: "080ff79faafc4e479efab7e8ec6eb537",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/080ff79faafc4e479efab7e8ec6eb537.png",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5360,
          name: "Tibet Plateau",
          uuid: "d4ebad5bfbc14fcf94f2a23c81e1f3e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d4ebad5bfbc14fcf94f2a23c81e1f3e8.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5361,
          name: "Tibet Plateau Mobile",
          uuid: "701c0f3b2886491f83b49897ffdf4660",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/701c0f3b2886491f83b49897ffdf4660.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4966,
          name: "Tiger Girl",
          uuid: "3339efd9c496f809b600bec3e0a3e2904f8fb597",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3339efd9c496f809b600bec3e0a3e2904f8fb597.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4967,
          name: "Tiger Girl Mobile",
          uuid: "d2f920ce2dd43fed422390eb18756589382df0b1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d2f920ce2dd43fed422390eb18756589382df0b1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4814,
          name: "Tomb Heroes",
          uuid: "d434a067cc8c459fb73bcc8bead8400e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d434a067cc8c459fb73bcc8bead8400e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4815,
          name: "Tomb Heroes Mobile",
          uuid: "81d08d60541243b096d9a090d0f2c93f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/81d08d60541243b096d9a090d0f2c93f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4481,
          name: "Tower of Babel",
          uuid: "6db7d9892b5a4e719b20e09c5f083435",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6db7d9892b5a4e719b20e09c5f083435.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4480,
          name: "Tower of Babel Mobile",
          uuid: "1aa64c7d82aa4d17ad531fb41bfa08a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1aa64c7d82aa4d17ad531fb41bfa08a5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4279,
          name: "Treasure Bowl",
          uuid: "95142725da864976a416312124a3fc72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/95142725da864976a416312124a3fc72.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4278,
          name: "Treasure Bowl Mobile",
          uuid: "4cc669ac922849d19c8a2d0442e5dde3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/4cc669ac922849d19c8a2d0442e5dde3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4940,
          name: "Treasure Carp",
          uuid: "1593aa2f8eb34ba5540c640b29a2fbba2a0751ed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1593aa2f8eb34ba5540c640b29a2fbba2a0751ed.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4941,
          name: "Treasure Carp Mobile",
          uuid: "53a438ecdc87a8a32d3be03c6232d5e4189d7178",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/53a438ecdc87a8a32d3be03c6232d5e4189d7178.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4862,
          name: "Treasure Tiger",
          uuid: "38a1ed53d77d6f2bb4343c910d1686b3603ca682",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/38a1ed53d77d6f2bb4343c910d1686b3603ca682.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4863,
          name: "Treasure Tiger Mobile",
          uuid: "c62691568d3c21f0c77918518c4c2c19dfe3327a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c62691568d3c21f0c77918518c4c2c19dfe3327a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5336,
          name: "Trick Or Treat",
          uuid: "62e897bc24814a348a5293aaa78b22a7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/62e897bc24814a348a5293aaa78b22a7.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5337,
          name: "Trick Or Treat Mobile",
          uuid: "efff3771f3164cbcba1d237f4591f6e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/efff3771f3164cbcba1d237f4591f6e1.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4595,
          name: "Triple Dragons",
          uuid: "dd1caf3d58554b458c8b644bdb044796",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/dd1caf3d58554b458c8b644bdb044796.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4594,
          name: "Triple Dragons Mobile",
          uuid: "ba6b3f4967374fc892a1ae0e92110d14",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ba6b3f4967374fc892a1ae0e92110d14.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4707,
          name: "Trippy Mushrooms",
          uuid: "23c7636000cc47c38406e7f0f1097f87",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/23c7636000cc47c38406e7f0f1097f87.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4706,
          name: "Trippy Mushrooms Mobile",
          uuid: "1c80549e402444ec8a3c70561d286274",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1c80549e402444ec8a3c70561d286274.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5108,
          name: "Tuan Yuan",
          uuid: "17f83b21c05a41e5abb725022f383833",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/17f83b21c05a41e5abb725022f383833.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5109,
          name: "Tuan Yuan Mobile",
          uuid: "78037b07765a47f29453ce43cd838ecd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/78037b07765a47f29453ce43cd838ecd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4571,
          name: "UFO",
          uuid: "23b970487b204db2bc4f9a8da4196e7c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/23b970487b204db2bc4f9a8da4196e7c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5460,
          name: "UFO Hunt",
          uuid: "b4a6d54435ba4e07a6d821e33e220a8b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b4a6d54435ba4e07a6d821e33e220a8b.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5461,
          name: "UFO Hunt Mobile",
          uuid: "8587da5577b04704925c60ae75c168f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8587da5577b04704925c60ae75c168f7.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4570,
          name: "UFO Mobile",
          uuid: "ac7f5eec29f24c1fa45c8e3af40dd51d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/ac7f5eec29f24c1fa45c8e3af40dd51d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5046,
          name: "Undersea Treasure",
          uuid: "a758d654c23e48b186d1cb9e169f3ec6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a758d654c23e48b186d1cb9e169f3ec6.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5047,
          name: "Undersea Treasure Mobile",
          uuid: "342e67c3cdde4c85a61e3f3f1ff8f2e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/342e67c3cdde4c85a61e3f3f1ff8f2e9.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5064,
          name: "Unicorn Party",
          uuid: "99a86d908c12423593a21551b1ee57b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/99a86d908c12423593a21551b1ee57b4.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5065,
          name: "Unicorn Party Mobile",
          uuid: "7040ce4ca9dc471380dcfffd92a9a31f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7040ce4ca9dc471380dcfffd92a9a31f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5102,
          name: "Up Helly Aa",
          uuid: "034b671d44284036877e02fffa793f17",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/034b671d44284036877e02fffa793f17.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5103,
          name: "Up Helly Aa Mobile",
          uuid: "0019c4914d7a4cd383db6b46d2d380fc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0019c4914d7a4cd383db6b46d2d380fc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4581,
          name: "Vampire's Tale",
          uuid: "c8cb8fae8a0b40babb345b97bd0fee45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c8cb8fae8a0b40babb345b97bd0fee45.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4580,
          name: "Vampire's Tale Mobile",
          uuid: "88830086fdea4173ac9fbc2d5c3a128b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/88830086fdea4173ac9fbc2d5c3a128b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4439,
          name: "Veggies Plot",
          uuid: "a24031926a9c4f23801c0d3a64b38bc0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a24031926a9c4f23801c0d3a64b38bc0.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4438,
          name: "Veggies Plot Mobile",
          uuid: "471ca4a398f04615afac8010b1aa364a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/471ca4a398f04615afac8010b1aa364a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4521,
          name: "Volcano Adventure",
          uuid: "e15dff493566480f860279c23381abbd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e15dff493566480f860279c23381abbd.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4520,
          name: "Volcano Adventure Mobile",
          uuid: "8d29886015d8447a854be74c62ed1995",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8d29886015d8447a854be74c62ed1995.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5222,
          name: "Walking Oz",
          uuid: "9cb78ff8642644eb9079a55e3eb70440",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9cb78ff8642644eb9079a55e3eb70440.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5223,
          name: "Walking Oz Mobile",
          uuid: "d362553a8507416891693530c479f01b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d362553a8507416891693530c479f01b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4467,
          name: "WanFu JinAn",
          uuid: "8d0e6a56e6c74934886d7b222b311635",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8d0e6a56e6c74934886d7b222b311635.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4466,
          name: "WanFu JinAn Mobile",
          uuid: "26de034280c944218b8a06bfb882b94c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/26de034280c944218b8a06bfb882b94c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5022,
          name: "War Of Thrones",
          uuid: "7433653da3476fd041e6c53e7fc5562e99083d9f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7433653da3476fd041e6c53e7fc5562e99083d9f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5023,
          name: "War Of Thrones Mobile",
          uuid: "c5a62aab88e3638f3d2d01f0e0734e7d621178c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/c5a62aab88e3638f3d2d01f0e0734e7d621178c6.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5599,
          name: "War Turtle",
          uuid: "83f5068eb38c49ba9391e043e72b2fb4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/83f5068eb38c49ba9391e043e72b2fb4.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5598,
          name: "War Turtle Mobile",
          uuid: "1bce2bcac98946f68b498721e5b58236",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1bce2bcac98946f68b498721e5b58236.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5074,
          name: "Wealth Toad",
          uuid: "846e45b7fc03457c91d8ba742c55dc06",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/846e45b7fc03457c91d8ba742c55dc06.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5075,
          name: "Wealth Toad Mobile",
          uuid: "6d560b097a09413193d4833b45af2a39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6d560b097a09413193d4833b45af2a39.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4345,
          name: "Welcome Fortune",
          uuid: "a6224255e20f42e8a4db683871adef09",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a6224255e20f42e8a4db683871adef09.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4344,
          name: "Welcome Fortune Mobile",
          uuid: "9e9195d33c4843cba12e3b700bd1378a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9e9195d33c4843cba12e3b700bd1378a.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4365,
          name: "Wen Ding",
          uuid: "0c889b294b044599b1c7828376b3a1d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0c889b294b044599b1c7828376b3a1d7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4364,
          name: "Wen Ding Mobile",
          uuid: "68fddd1ad7b94787bcc7f07c152035b1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/68fddd1ad7b94787bcc7f07c152035b1.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5470,
          name: "Werewolf",
          uuid: "2bedc89857841aaeda9044ce3ce6627e8f210a3a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2bedc89857841aaeda9044ce3ce6627e8f210a3a.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4816,
          name: "Werewolf Is Coming",
          uuid: "443da04c981f49eb84c73a221289a81e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/443da04c981f49eb84c73a221289a81e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4817,
          name: "Werewolf Is Coming Mobile",
          uuid: "43a3cc1b9b274555b616c74383c50b3c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/43a3cc1b9b274555b616c74383c50b3c.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5471,
          name: "Werewolf Mobile",
          uuid: "8b620c01d9287213b97eb7bdf5a863cbbb9b7a0f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8b620c01d9287213b97eb7bdf5a863cbbb9b7a0f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4886,
          name: "Whale Wild",
          uuid: "54ed547c335f921d561a57af2a8d55a6900bd2c5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/54ed547c335f921d561a57af2a8d55a6900bd2c5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4887,
          name: "Whale Wild Mobile",
          uuid: "f33ebe913b8d3ea0117b15d7c89e4ae0d7ea0aa7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f33ebe913b8d3ea0117b15d7c89e4ae0d7ea0aa7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4868,
          name: "White Deer",
          uuid: "ab34f13ac663fa90dbc7fffecc4ff9b7a1bf66cb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ab34f13ac663fa90dbc7fffecc4ff9b7a1bf66cb.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4869,
          name: "White Deer Mobile",
          uuid: "7cae8116b7422694c19ebffb455699d3ae63bc5d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7cae8116b7422694c19ebffb455699d3ae63bc5d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5164,
          name: "White Nose Cat",
          uuid: "2d1c52b508284ed580b7d14fc0bbb8da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2d1c52b508284ed580b7d14fc0bbb8da.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5165,
          name: "White Nose Cat Mobile",
          uuid: "bb263874bc164ae2a39f8f4dd1fdd527",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bb263874bc164ae2a39f8f4dd1fdd527.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4994,
          name: "Who Let The Dogs Out",
          uuid: "bdf181df793b763c77ae24440900babf5c6f9d2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/bdf181df793b763c77ae24440900babf5c6f9d2d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4995,
          name: "Who Let The Dogs Out Mobile",
          uuid: "93df592bb5d68819355cea0d03e0dcedb58c880d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/93df592bb5d68819355cea0d03e0dcedb58c880d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4753,
          name: "Wild Alaska",
          uuid: "8241e72bb3364d56939845eebbd6f997",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8241e72bb3364d56939845eebbd6f997.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4752,
          name: "Wild Alaska Mobile",
          uuid: "50f7292beef14392b2d2b35ee0a2ed4d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/50f7292beef14392b2d2b35ee0a2ed4d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4958,
          name: "Wild Jungle",
          uuid: "241416cf30b339eed7d0269d4b44a04f6acd3b03",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/241416cf30b339eed7d0269d4b44a04f6acd3b03.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4959,
          name: "Wild Jungle Mobile",
          uuid: "8ddcbb6b1a45f040eae2d34353d495a6a7c09949",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8ddcbb6b1a45f040eae2d34353d495a6a7c09949.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4293,
          name: "Wild Vick",
          uuid: "66e51e2ee42d49b1943e825a4dc6174d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/66e51e2ee42d49b1943e825a4dc6174d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4292,
          name: "Wild Vick Mobile",
          uuid: "3e510eadeae64a38922dc01172f46c1b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3e510eadeae64a38922dc01172f46c1b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4605,
          name: "Wild Wild Bell",
          uuid: "b681d4e0beb24e8fb6860f5d845e8946",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b681d4e0beb24e8fb6860f5d845e8946.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4604,
          name: "Wild Wild Bell Mobile",
          uuid: "93fa45f81632476e9b553710e756cdd3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/93fa45f81632476e9b553710e756cdd3.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5388,
          name: "Wind Lion God",
          uuid: "afcad686b04e433e9bdb71f4ac915523",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/afcad686b04e433e9bdb71f4ac915523.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5389,
          name: "Wind Lion God Mobile",
          uuid: "b34de607d3624501bba15a6a1fd85005",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b34de607d3624501bba15a6a1fd85005.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5238,
          name: "Wizard Of Wild",
          uuid: "eadf999b2e2b4bfb9d4485f769750ee1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/eadf999b2e2b4bfb9d4485f769750ee1.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5239,
          name: "Wizard Of Wild Mobile",
          uuid: "79067f0e7c034a8b8ec3b84290b385e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/79067f0e7c034a8b8ec3b84290b385e5.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4673,
          name: "Wizardry",
          uuid: "9637bd15016d484fa2a578057a46bed2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9637bd15016d484fa2a578057a46bed2.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4672,
          name: "Wizardry Mobile",
          uuid: "043170804bff40ee9246c031e9d833f5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/043170804bff40ee9246c031e9d833f5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4519,
          name: "Wolf Warrior",
          uuid: "6631ddeb71b043d1b885dddffe6af79d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/6631ddeb71b043d1b885dddffe6af79d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4518,
          name: "Wolf Warrior Mobile",
          uuid: "d0f0f1645b6043af94ce4a0bf39de442",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d0f0f1645b6043af94ce4a0bf39de442.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4832,
          name: "Won Won Catching",
          uuid: "518b5cbe2afa43ca9df816f194eb2531",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/518b5cbe2afa43ca9df816f194eb2531.png",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4833,
          name: "Won Won Catching Mobile",
          uuid: "8aeac80c35d0470eb11f201257b26fd3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8aeac80c35d0470eb11f201257b26fd3.png",
          type: "arcade",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4475,
          name: "Won Won Rich",
          uuid: "d4e0e63c7d564f8c95d75087a9894468",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d4e0e63c7d564f8c95d75087a9894468.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4474,
          name: "Won Won Rich Mobile",
          uuid: "30e2d7964ba84274a76a70a34b418e77",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/30e2d7964ba84274a76a70a34b418e77.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4794,
          name: "Wong TaiSin",
          uuid: "e4e47a8387bc40b0b5bd71d853c9511d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e4e47a8387bc40b0b5bd71d853c9511d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4795,
          name: "Wong TaiSin Mobile",
          uuid: "2a99dfaf7aad4b1db3cda523ec8a94aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2a99dfaf7aad4b1db3cda523ec8a94aa.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5070,
          name: "World Of Lord Elf King",
          uuid: "0f39692835c649b6ae5bdb43ec26dbdf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0f39692835c649b6ae5bdb43ec26dbdf.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5071,
          name: "World Of Lord Elf King Mobile",
          uuid: "d82bc13c3554400bb8c5ab6a9564a5cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d82bc13c3554400bb8c5ab6a9564a5cc.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5178,
          name: "World Of Lord Witch King",
          uuid: "634f70af44ee42bcb9ab840f9f75c829",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/634f70af44ee42bcb9ab840f9f75c829.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5179,
          name: "World Of Lord Witch King Mobile",
          uuid: "7d5bb2d38f1a4d14aa5fad4991522272",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7d5bb2d38f1a4d14aa5fad4991522272.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5530,
          name: "Wrestling Queen",
          uuid: "e98af5fceb1c4266bf9668470390a20f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e98af5fceb1c4266bf9668470390a20f.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5531,
          name: "Wrestling Queen Mobile",
          uuid: "9e2826f3e9fd4c24ae869127adc47cff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9e2826f3e9fd4c24ae869127adc47cff.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4591,
          name: "Wu Gang",
          uuid: "f6846b22f7ae483dae50dcbf289a177b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f6846b22f7ae483dae50dcbf289a177b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4590,
          name: "Wu Gang Mobile",
          uuid: "7ca4de92700d48f9a642f2320625cfed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7ca4de92700d48f9a642f2320625cfed.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4383,
          name: "Wu Song",
          uuid: "7ae76211b17c4fc7a744f738ee000677",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/7ae76211b17c4fc7a744f738ee000677.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4382,
          name: "Wu Song Mobile",
          uuid: "06f3ae25c90d4110aa39fe3323739386",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/06f3ae25c90d4110aa39fe3323739386.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4798,
          name: "Wu Zetian",
          uuid: "9d58be7515a94de9b7d8c4eeeddd7e39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9d58be7515a94de9b7d8c4eeeddd7e39.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4799,
          name: "Wu Zetian Mobile",
          uuid: "802d951c3f7b4cd490adf4e55c14db3e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/802d951c3f7b4cd490adf4e55c14db3e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4565,
          name: "X-Bomber",
          uuid: "0cb79d5e31d94c6d882c50aa28045c18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0cb79d5e31d94c6d882c50aa28045c18.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4564,
          name: "X-Bomber Mobile",
          uuid: "a80d8a3ee68f4ba6a3fb5a404b8231ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a80d8a3ee68f4ba6a3fb5a404b8231ef.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4429,
          name: "X-Elements",
          uuid: "d454cb06b8554a6cae7c6ba32f88e284",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d454cb06b8554a6cae7c6ba32f88e284.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4428,
          name: "X-Elements Mobile",
          uuid: "13249520902548438b16187fb2a7ab65",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/13249520902548438b16187fb2a7ab65.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4369,
          name: "Xmas Wishes",
          uuid: "1b7c704fb9724c36b72da9301232589d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1b7c704fb9724c36b72da9301232589d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4368,
          name: "Xmas Wishes Mobile",
          uuid: "f5d5570495c34b0e977a0c3e5c28dc09",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f5d5570495c34b0e977a0c3e5c28dc09.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4773,
          name: "Yamato",
          uuid: "fe8ab0c50e9743a29ed11f8c04096dbe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/fe8ab0c50e9743a29ed11f8c04096dbe.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4772,
          name: "Yamato Mobile",
          uuid: "8bcad35a199b4b6e958025af1f7ffca5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8bcad35a199b4b6e958025af1f7ffca5.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5096,
          name: "Year Of The Rabbit",
          uuid: "1f7ea0bac13e4211b916935648d03f4b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/1f7ea0bac13e4211b916935648d03f4b.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5097,
          name: "Year Of The Rabbit Mobile",
          uuid: "0b117bf1d7e644b693e90337b80a357e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/0b117bf1d7e644b693e90337b80a357e.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4864,
          name: "Year Of The Tiger",
          uuid: "4bdc0d46dfb67cfd031f0f5c9f156e36db240111",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4bdc0d46dfb67cfd031f0f5c9f156e36db240111.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4865,
          name: "Year Of The Tiger Mobile",
          uuid: "b6e20977f6442af31f5aaeb12bd6089b864c6a0d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b6e20977f6442af31f5aaeb12bd6089b864c6a0d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5540,
          name: "Yu Fu",
          uuid: "b1bf35abf3bf48c783ac9992b223ada8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/b1bf35abf3bf48c783ac9992b223ada8.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5541,
          name: "Yu Fu Mobile",
          uuid: "48c4a2b0f32a490483f1447577553828",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/48c4a2b0f32a490483f1447577553828.jpg",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4645,
          name: "Yu Gong",
          uuid: "2601c58c1a65485fb4c707efc4b2ab78",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/2601c58c1a65485fb4c707efc4b2ab78.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4644,
          name: "Yu Gong Mobile",
          uuid: "9ca361ab608d4d60a60eeefc16d738e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/9ca361ab608d4d60a60eeefc16d738e7.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4393,
          name: "Yue Fei",
          uuid: "f22be3a539054bb0aaef464394a18d9d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/f22be3a539054bb0aaef464394a18d9d.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4392,
          name: "Yue Fei Mobile",
          uuid: "3da0823492d04b43a2b0344898d76c08",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/3da0823492d04b43a2b0344898d76c08.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4435,
          name: "Yun Cai Tong Zi",
          uuid: "8c4bf6dfb55d49e8b1c9418417e13a54",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/8c4bf6dfb55d49e8b1c9418417e13a54.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4434,
          name: "Yun Cai Tong Zi Mobile",
          uuid: "44f0c6b1ecea47a2b4202fb8d3d27a30",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/44f0c6b1ecea47a2b4202fb8d3d27a30.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5242,
          name: "Zhong Yi And Dragon",
          uuid: "efe4670cb3b84108815748a18ffa9336",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/efe4670cb3b84108815748a18ffa9336.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5243,
          name: "Zhong Yi And Dragon Mobile",
          uuid: "299f9a9ce7874c44a3e227b654ce141f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/299f9a9ce7874c44a3e227b654ce141f.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5322,
          name: "Zodiac Hunting",
          uuid: "d8048e73d77b4378a6ce30249ad477fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/d8048e73d77b4378a6ce30249ad477fe.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5323,
          name: "Zodiac Hunting Mobile",
          uuid: "a9671141e8df41a8b24ef6e850845854",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/a9671141e8df41a8b24ef6e850845854.jpg",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4948,
          name: "Zombie Chicken",
          uuid: "f258517976ca9aa4d4ef11a9e7179194f44be49a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f258517976ca9aa4d4ef11a9e7179194f44be49a.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4949,
          name: "Zombie Chicken Mobile",
          uuid: "55a97f706c4cebd23c237d03aa0130bf9dd648f7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/55a97f706c4cebd23c237d03aa0130bf9dd648f7.png",
          type: "fishing",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4956,
          name: "Zombie Expert",
          uuid: "00cef4435b849fa0407f38a36792bfa17dd20142",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/00cef4435b849fa0407f38a36792bfa17dd20142.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4957,
          name: "Zombie Expert Mobile",
          uuid: "d5ea89880e509bdaa0a251cc3f1d13e782c4f886",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d5ea89880e509bdaa0a251cc3f1d13e782c4f886.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4679,
          name: "Zombie Land",
          uuid: "84b01d4a75254b04aebb15fdb9b4bdda",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/84b01d4a75254b04aebb15fdb9b4bdda.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 4678,
          name: "Zombie Land Mobile",
          uuid: "e0d819738f814097a1a69460ad0a74f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/KAGaming/e0d819738f814097a1a69460ad0a74f8.png",
          type: "slots",
          provider: "KAGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 127,
      name: "Kalamba",
      providerId: 43,
      providerName: "Kalamba",
      games: [
        {
          game_id: 5829,
          name: "9 Blazing Cashpots",
          uuid: "eed1d817e1944b3d8a96cdd21a37ff20",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/eed1d817e1944b3d8a96cdd21a37ff20.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5918,
          name: "9 Blazing Cashpots 50K",
          uuid: "4218cea2dc5d4b5fb1441e0c7609f8c3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/4218cea2dc5d4b5fb1441e0c7609f8c3.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5919,
          name: "9 Blazing Cashpots 50K Mobile",
          uuid: "cc3bd6076d704257b96c8b462500be38",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/cc3bd6076d704257b96c8b462500be38.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5832,
          name: "9 Blazing Cashpots Megaways",
          uuid: "2cf24031552142989b7a7e13010f291a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/2cf24031552142989b7a7e13010f291a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5833,
          name: "9 Blazing Cashpots Megaways Mobile",
          uuid: "1fec63f003a44653b0b9f0aec3652645",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/1fec63f003a44653b0b9f0aec3652645.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5828,
          name: "9 Blazing Cashpots Mobile",
          uuid: "3fd3586cdcf549d8baad30de0a84c6fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/3fd3586cdcf549d8baad30de0a84c6fb.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5776,
          name: "Age of Dragons",
          uuid: "87b2e44ac0d24119820c07922b8cf468",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/87b2e44ac0d24119820c07922b8cf468.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5834,
          name: "Age of Dragons Mini-Max",
          uuid: "29850e0a0b634ff6aafc937895224404",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/29850e0a0b634ff6aafc937895224404.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5835,
          name: "Age of Dragons Mini-Max Mobile",
          uuid: "634701951a784cf9a2adb6e0287b8087",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/634701951a784cf9a2adb6e0287b8087.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5777,
          name: "Age of Dragons Mobile",
          uuid: "ecf91dc07bc34070bffd3362ebded458",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ecf91dc07bc34070bffd3362ebded458.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5678,
          name: "Age of Huracan",
          uuid: "6d3552cb791f4eea97abd075f34bc253",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/6d3552cb791f4eea97abd075f34bc253.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5679,
          name: "Age of Huracan Mobile",
          uuid: "a89aced1673043c0a6bc8478c59d66e8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a89aced1673043c0a6bc8478c59d66e8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5760,
          name: "Age of Ice Dragons",
          uuid: "be5501e47c1f41058a66bd7e49d4425b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/be5501e47c1f41058a66bd7e49d4425b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5761,
          name: "Age of Ice Dragons Mobile",
          uuid: "c950064de0cc4556ac1ebf40009c2839",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/c950064de0cc4556ac1ebf40009c2839.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5700,
          name: "Agent 51",
          uuid: "f82865353f13427e81434ece1828106d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/f82865353f13427e81434ece1828106d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5701,
          name: "Agent 51 Mobile",
          uuid: "06fe34830bd9429bbaa2ec81b3e90ef8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/06fe34830bd9429bbaa2ec81b3e90ef8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5892,
          name: "Allstar 7s Hold and Win",
          uuid: "5703d81c359a44f5a9276f4e099e9541",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/5703d81c359a44f5a9276f4e099e9541.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5893,
          name: "Allstar 7s Hold and Win Mobile",
          uuid: "d585f6d061204fccb1332fed134ad19f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/d585f6d061204fccb1332fed134ad19f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5778,
          name: "Atlantis Thunder",
          uuid: "785dab6d08e74e21ba18677e435ea4c9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/785dab6d08e74e21ba18677e435ea4c9.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5779,
          name: "Atlantis Thunder Mobile",
          uuid: "7c102844b2d04ea5ad0733f40a3b0caf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7c102844b2d04ea5ad0733f40a3b0caf.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5768,
          name: "Atlantis Thunder St Patrick's Edition",
          uuid: "3096c047bcf349f483ac1b6dc2cbf84d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/3096c047bcf349f483ac1b6dc2cbf84d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5769,
          name: "Atlantis Thunder St Patrick's Edition Mobile",
          uuid: "1dc35eca22d74732bf0c18c38b1e84d9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/1dc35eca22d74732bf0c18c38b1e84d9.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5780,
          name: "Bangkok Dreams",
          uuid: "c9228d09f47348478ff66df87b372754",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/c9228d09f47348478ff66df87b372754.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5684,
          name: "Bangkok Dreams Gamble Feature",
          uuid: "fc4da04d3d334776a670039e9880fd22",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/fc4da04d3d334776a670039e9880fd22.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5685,
          name: "Bangkok Dreams Gamble Feature Mobile",
          uuid: "652109167ead43e6b11b9f71d2e7b63c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/652109167ead43e6b11b9f71d2e7b63c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5781,
          name: "Bangkok Dreams Mobile",
          uuid: "7f5eebdc48a74bba8df13baa76019726",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7f5eebdc48a74bba8df13baa76019726.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5716,
          name: "Beers on Reels",
          uuid: "887c2626bbda4c94806abc826d4da08f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/887c2626bbda4c94806abc826d4da08f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5717,
          name: "Beers on Reels Mobile",
          uuid: "663e93dbb375455a9b0d31f201ff28b1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/663e93dbb375455a9b0d31f201ff28b1.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5736,
          name: "Big Bounty Bill",
          uuid: "91521263af404a94bc0e30f0f34ce96b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/91521263af404a94bc0e30f0f34ce96b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5737,
          name: "Big Bounty Bill Mobile",
          uuid: "d370146a397447bd9e538a9fa186c1b6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/d370146a397447bd9e538a9fa186c1b6.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5724,
          name: "Blazing Bull",
          uuid: "76be93b5459345da91daf0194be3787a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/76be93b5459345da91daf0194be3787a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5674,
          name: "Blazing Bull 2",
          uuid: "ddbf28b189eb42718a2442490acb3d60",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ddbf28b189eb42718a2442490acb3d60.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5836,
          name: "Blazing Bull 2 Mini-Max",
          uuid: "b15ed1c0373c467a87220651c08b7c98",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b15ed1c0373c467a87220651c08b7c98.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5837,
          name: "Blazing Bull 2 Mini-Max Mobile",
          uuid: "1414c19d666e424c88cdccd61b27001c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/1414c19d666e424c88cdccd61b27001c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5675,
          name: "Blazing Bull 2 Mobile",
          uuid: "e4cdc311a6a9449e993e05da5d44561d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/e4cdc311a6a9449e993e05da5d44561d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5825,
          name: "Blazing Bull Cash Quest",
          uuid: "5504df9d7714475787e16e27009feecf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/5504df9d7714475787e16e27009feecf.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5824,
          name: "Blazing Bull Cash Quest Mobile",
          uuid: "7e41f4106953459c8a5633ad6221b737",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7e41f4106953459c8a5633ad6221b737.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5658,
          name: "Blazing Bull Gamble Feature",
          uuid: "38722f6f01574aaf885e47a6edc9460b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/38722f6f01574aaf885e47a6edc9460b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5659,
          name: "Blazing Bull Gamble Feature Mobile",
          uuid: "a0705dbf891843a8951b2c43bf396001",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a0705dbf891843a8951b2c43bf396001.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5725,
          name: "Blazing Bull Mobile",
          uuid: "8bb853486c20454dbe54e83ec9000fc0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/8bb853486c20454dbe54e83ec9000fc0.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5866,
          name: "Blazing Bull Remastered",
          uuid: "ff251a7453ac4b4ebd2cd84e95595687",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ff251a7453ac4b4ebd2cd84e95595687.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5867,
          name: "Blazing Bull Remastered Mobile",
          uuid: "b39051a093b94449901930d5fdc9507e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b39051a093b94449901930d5fdc9507e.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5882,
          name: "Blazing Spirit Hold and Win",
          uuid: "899cd415ca1a847eeb8612d2b881b78054bb2956",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/899cd415ca1a847eeb8612d2b881b78054bb2956.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5883,
          name: "Blazing Spirit Hold and Win Mobile",
          uuid: "3dad5c57e28f9147e0d992afe5625507a5c9c3da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/3dad5c57e28f9147e0d992afe5625507a5c9c3da.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5900,
          name: "Blessed Dragons Hold and Win",
          uuid: "20d32f965379488493530ad8ba591b7c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/20d32f965379488493530ad8ba591b7c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5901,
          name: "Blessed Dragons Hold and Win Mobile",
          uuid: "b62edde6c37d40daae79d74d255f3c8d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b62edde6c37d40daae79d74d255f3c8d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5940,
          name: "Bling Boyz",
          uuid: "82688ccec3964fc48636140cd95987e1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/82688ccec3964fc48636140cd95987e1.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5941,
          name: "Bling Boyz Mobile",
          uuid: "0cfaad021a32422682917dcf6263db88",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0cfaad021a32422682917dcf6263db88.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5738,
          name: "Blood Moon Express",
          uuid: "4c5daaf0b64c4b86b66172e9447ece42",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/4c5daaf0b64c4b86b66172e9447ece42.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5739,
          name: "Blood Moon Express Mobile",
          uuid: "743ee757d49642279070912504047647",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/743ee757d49642279070912504047647.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5750,
          name: "Burning Diamonds",
          uuid: "cbf19071c3214904862dcc6132be385b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/cbf19071c3214904862dcc6132be385b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5688,
          name: "Burning Diamonds Gamble Feature",
          uuid: "24edf93f93d144a68f0070b89a863f59",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/24edf93f93d144a68f0070b89a863f59.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5689,
          name: "Burning Diamonds Gamble Feature Mobile",
          uuid: "c4da55c262b4495f9821f04e80cf2b7f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/c4da55c262b4495f9821f04e80cf2b7f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5751,
          name: "Burning Diamonds Mobile",
          uuid: "1ca047c7a1614271b573db04fced6cf8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/1ca047c7a1614271b573db04fced6cf8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5748,
          name: "Caribbean Anne",
          uuid: "72e33ed730894977892a0ddbd0df764f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/72e33ed730894977892a0ddbd0df764f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5664,
          name: "Caribbean Anne 2",
          uuid: "45718c0d53564aecb2b3e240e61493f2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/45718c0d53564aecb2b3e240e61493f2.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5665,
          name: "Caribbean Anne 2 Mobile",
          uuid: "e605919c41ff446ea448a79be0a9797e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/e605919c41ff446ea448a79be0a9797e.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5656,
          name: "Caribbean Anne Gamble Feature",
          uuid: "81f93719f562414f8116893eb6bb3d57",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/81f93719f562414f8116893eb6bb3d57.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5657,
          name: "Caribbean Anne Gamble Feature Mobile",
          uuid: "6ab6f8c1d36644f2bd511ff88af8ae18",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/6ab6f8c1d36644f2bd511ff88af8ae18.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5823,
          name: "Caribbean Anne Mini-Max",
          uuid: "2cd44f62044547cab77b888a157a0664",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/2cd44f62044547cab77b888a157a0664.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5822,
          name: "Caribbean Anne Mini-Max Mobile",
          uuid: "51722d8eb3474d17a34e28fc9ac33ecf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/51722d8eb3474d17a34e28fc9ac33ecf.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5749,
          name: "Caribbean Anne Mobile",
          uuid: "e85222a1a6284f03827810aa0370a4ee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/e85222a1a6284f03827810aa0370a4ee.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5827,
          name: "Cashpot Kegs",
          uuid: "de0b4969dd0d4b6d9576b57d38aab8a2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/de0b4969dd0d4b6d9576b57d38aab8a2.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5826,
          name: "Cashpot Kegs Mobile",
          uuid: "03cec659b8044ec3b3e68bef744feda1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/03cec659b8044ec3b3e68bef744feda1.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5914,
          name: "Cashpot Strike 7s",
          uuid: "403f1958b3a346aa9eed35e0bcd64146",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/403f1958b3a346aa9eed35e0bcd64146.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5915,
          name: "Cashpot Strike 7s Mobile",
          uuid: "546e4d0927d34ed1a4de7ccb2c2f4886",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/546e4d0927d34ed1a4de7ccb2c2f4886.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5938,
          name: "Champion Blitz Hold and Win",
          uuid: "4a312088e682407e9018dc8b03fcc6cf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/4a312088e682407e9018dc8b03fcc6cf.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5939,
          name: "Champion Blitz Hold and Win Mobile",
          uuid: "121c4b4585694438b0f860b2c7b450bc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/121c4b4585694438b0f860b2c7b450bc.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5937,
          name: "Chili Flame 7s Hold and Win",
          uuid: "7ae963cd4da790332d0370b41f713880004dfc52",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/7ae963cd4da790332d0370b41f713880004dfc52.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5936,
          name: "Chili Flame 7s Hold and Win Mobile",
          uuid: "2b367e8cc2224a2e36e52f5481b912bf999c43bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/2b367e8cc2224a2e36e52f5481b912bf999c43bf.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5906,
          name: "Clover Blitz Hold and Win",
          uuid: "1a376e20bb7342d785149d35dba3a396",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/1a376e20bb7342d785149d35dba3a396.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5907,
          name: "Clover Blitz Hold and Win Mobile",
          uuid: "87600adc14544abd94facc44126b48f9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/87600adc14544abd94facc44126b48f9.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5676,
          name: "Cosmic Charms",
          uuid: "09cc06bec95645199284761abda5f1af",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/09cc06bec95645199284761abda5f1af.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5677,
          name: "Cosmic Charms Mobile",
          uuid: "fc8bf6332f464d98bf21b854a353d456",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/fc8bf6332f464d98bf21b854a353d456.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5728,
          name: "Crystal Cavern",
          uuid: "77a475461eed4548af5acac56553a5e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/77a475461eed4548af5acac56553a5e3.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5838,
          name: "Crystal Cavern Mini-Max",
          uuid: "cf847569cd8f4edfb22379556d97db5e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/cf847569cd8f4edfb22379556d97db5e.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5839,
          name: "Crystal Cavern Mini-Max Mobile",
          uuid: "7055d6af578c410780bd258b8292a6a6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7055d6af578c410780bd258b8292a6a6.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5729,
          name: "Crystal Cavern Mobile",
          uuid: "63b5fda995b74c0e9098ddffe1c97a69",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/63b5fda995b74c0e9098ddffe1c97a69.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5872,
          name: "Deep Rush",
          uuid: "e9d8210c37ac4d169a4236f318c7dccc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/e9d8210c37ac4d169a4236f318c7dccc.png",
          type: "crash",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5873,
          name: "Deep Rush Mobile",
          uuid: "35fbbb97c57a4fba810cb61389f618db",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/35fbbb97c57a4fba810cb61389f618db.png",
          type: "crash",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5770,
          name: "Desert Gem",
          uuid: "953bfe93a10442478a5f10eea268c479",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/953bfe93a10442478a5f10eea268c479.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5771,
          name: "Desert Gem Mobile",
          uuid: "5337d72375f54c26b20587ceb93be3ba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/5337d72375f54c26b20587ceb93be3ba.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5878,
          name: "Diamond Bounty 7s Hold and Win",
          uuid: "cb00f6d9aecb4f37a7d597be0a5a09f0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/cb00f6d9aecb4f37a7d597be0a5a09f0.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5879,
          name: "Diamond Bounty 7s Hold and Win Mobile",
          uuid: "d76850ead7ae40469842d77a53403583",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/d76850ead7ae40469842d77a53403583.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5898,
          name: "Diamond Bounty Xmas Hold and Win",
          uuid: "b88979f7988b48958a4c5c3227e54234",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b88979f7988b48958a4c5c3227e54234.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5899,
          name: "Diamond Bounty Xmas Hold and Win Mobile",
          uuid: "d0b1f2f621c240ea8164a015bf478964",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/d0b1f2f621c240ea8164a015bf478964.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5884,
          name: "Diamond Supreme Hold and Win",
          uuid: "2c6cae46469224e3dbf4bc7dd35af91bcc9e519a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/2c6cae46469224e3dbf4bc7dd35af91bcc9e519a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5885,
          name: "Diamond Supreme Hold and Win Mobile",
          uuid: "021b338e7af9bf471279a6d3fab0620545e9f637",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/021b338e7af9bf471279a6d3fab0620545e9f637.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5792,
          name: "Dino Odyssey",
          uuid: "308bd9ccbb224a149cf71710538e38ac",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/308bd9ccbb224a149cf71710538e38ac.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5793,
          name: "Dino Odyssey Mobile",
          uuid: "492c548cb6e04766b503da1cb9d174be",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/492c548cb6e04766b503da1cb9d174be.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5840,
          name: "Divine Gongs",
          uuid: "645ebd3738c140c183bca27d7370b413",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/645ebd3738c140c183bca27d7370b413.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5841,
          name: "Divine Gongs Mobile",
          uuid: "bde0135dc8c248249dd2ab7ea65b163d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/bde0135dc8c248249dd2ab7ea65b163d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5756,
          name: "Doctor Electro",
          uuid: "d8ff55ba61a847849569f21eea729402",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/d8ff55ba61a847849569f21eea729402.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5757,
          name: "Doctor Electro Mobile",
          uuid: "af30872506154040b6c2ebb5d5338f0c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/af30872506154040b6c2ebb5d5338f0c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5912,
          name: "Dolphin Riches  Hold and Win Mobile",
          uuid: "820200a4288341fda7b55814daf317e7a1973ea3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/820200a4288341fda7b55814daf317e7a1973ea3.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5913,
          name: "Dolphin Riches Hold and Win",
          uuid: "e6abe884c9b0e3395623356494ae3a6b47a7f029",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/e6abe884c9b0e3395623356494ae3a6b47a7f029.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5794,
          name: "Double Joker",
          uuid: "b0ac0a6f2499463e8d38a8689546dd97",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b0ac0a6f2499463e8d38a8689546dd97.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5944,
          name: "Double Joker Hold and Win",
          uuid: "ea069917de08485eb60ca74fef3219e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ea069917de08485eb60ca74fef3219e4.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5945,
          name: "Double Joker Hold and Win Mobile",
          uuid: "1751fc705c82434b80ae3280bf72faed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/1751fc705c82434b80ae3280bf72faed.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5795,
          name: "Double Joker Mobile",
          uuid: "5a84e917a23840d8a9dcd4d6712835fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/5a84e917a23840d8a9dcd4d6712835fb.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5702,
          name: "Ducks Till Dawn",
          uuid: "af529a8b144042ec98193208b3ef4fdf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/af529a8b144042ec98193208b3ef4fdf.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5703,
          name: "Ducks Till Dawn Mobile",
          uuid: "3e6dd030d5de4870b06fadd47bec50e8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/3e6dd030d5de4870b06fadd47bec50e8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5666,
          name: "El Vigilante",
          uuid: "3e674efcb2f8485ebb6675aaf9c004df",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/3e674efcb2f8485ebb6675aaf9c004df.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5667,
          name: "El Vigilante Mobile",
          uuid: "18acc0daace2441aae226144b0111266",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/18acc0daace2441aae226144b0111266.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5910,
          name: "Emerald Bounty 7s Hold and Win",
          uuid: "c50da0802b5644fdb7dfbb13a2737685",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/c50da0802b5644fdb7dfbb13a2737685.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5911,
          name: "Emerald Bounty 7s Hold and Win Mobile",
          uuid: "3ee6ad27552846f4b8bba42f848499f8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/3ee6ad27552846f4b8bba42f848499f8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5821,
          name: "Finnegan's Banditos",
          uuid: "90ee1560f5974de6b3d8f5a2e22b1ffc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/90ee1560f5974de6b3d8f5a2e22b1ffc.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5820,
          name: "Finnegan's Banditos Mobile",
          uuid: "39d6415c704d4b14bc4ce4a3d903cb5d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/39d6415c704d4b14bc4ce4a3d903cb5d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5690,
          name: "Finnegan's Formula",
          uuid: "53b97f70e9354938b14389159c84298b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/53b97f70e9354938b14389159c84298b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5691,
          name: "Finnegan's Formula Mobile",
          uuid: "ba6044c7baf8401eb82cbc9411a3c53c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ba6044c7baf8401eb82cbc9411a3c53c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5796,
          name: "Fire Eagle",
          uuid: "690bf0f972b84127a30f3553080e5714",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/690bf0f972b84127a30f3553080e5714.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5797,
          name: "Fire Eagle Mobile",
          uuid: "183364669ec845f2bfb4ea253f32b764",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/183364669ec845f2bfb4ea253f32b764.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5942,
          name: "Fire Eagle Reborn",
          uuid: "f6a934bdc3614bc79bc2202c65f7d824",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/f6a934bdc3614bc79bc2202c65f7d824.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5943,
          name: "Fire Eagle Reborn Mobile",
          uuid: "5694b355232b4c358bd041df07c1df53",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/5694b355232b4c358bd041df07c1df53.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5928,
          name: "Firebolt Multi 7s Hold and Win",
          uuid: "15d58cee25914d11aeff22154f971129",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/15d58cee25914d11aeff22154f971129.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5929,
          name: "Firebolt Multi 7s Hold and Win Mobile",
          uuid: "9539f2e7bf094e98b3b4bf331afa3bad",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/9539f2e7bf094e98b3b4bf331afa3bad.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5704,
          name: "Firedrake's Fortune",
          uuid: "b0af4f3cbe694cc8b14e930642e8682b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b0af4f3cbe694cc8b14e930642e8682b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5680,
          name: "Firedrake's Fortune Gamble Feature",
          uuid: "39339ca4c7ff4be98fdcf4162e05ab4d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/39339ca4c7ff4be98fdcf4162e05ab4d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5681,
          name: "Firedrake's Fortune Gamble Feature Mobile",
          uuid: "4189a21132784dfaa51a888d35f87252",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/4189a21132784dfaa51a888d35f87252.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5705,
          name: "Firedrake's Fortune Mobile",
          uuid: "affdf879d11c42199f5432eb2d684069",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/affdf879d11c42199f5432eb2d684069.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5958,
          name: "Fishline Loot Hold and Win",
          uuid: "5cc29b0c314746b087871d0751e15a57",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/5cc29b0c314746b087871d0751e15a57.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5959,
          name: "Fishline Loot Hold and Win Mobile",
          uuid: "e2d3fee16f944f3fa8ae98798ee7d650",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/e2d3fee16f944f3fa8ae98798ee7d650.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5868,
          name: "Flaming Scarabs",
          uuid: "824d9bcaacfd0fe9d8adf84eb22195e4ec9888f9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/824d9bcaacfd0fe9d8adf84eb22195e4ec9888f9.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5869,
          name: "Flaming Scarabs Mobile",
          uuid: "1c1c9963007e6ad2be823dfdb0ab47b75c706973",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/1c1c9963007e6ad2be823dfdb0ab47b75c706973.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5802,
          name: "Football Blast",
          uuid: "c4ca2ca8ea5354cb93f8db3da1c793bbdb7d1a35",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/c4ca2ca8ea5354cb93f8db3da1c793bbdb7d1a35.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5930,
          name: "Football Blast Hold and Win",
          uuid: "bb78f341cc054980b8aa76143376c4dc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/bb78f341cc054980b8aa76143376c4dc.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5931,
          name: "Football Blast Hold and Win Mobile",
          uuid: "467cc553c8354741b1dbea4b934785c9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/467cc553c8354741b1dbea4b934785c9.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5803,
          name: "Football Blast Mobile",
          uuid: "3647be407901d1d681f124a41b298bbfaa803c3d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/3647be407901d1d681f124a41b298bbfaa803c3d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5819,
          name: "FruitMax: Cashlinez",
          uuid: "0dcf52d5fd184ce39eff7901835d1499",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0dcf52d5fd184ce39eff7901835d1499.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5818,
          name: "FruitMax: Cashlinez Mobile",
          uuid: "81a3d957a4a24b4aaacbfe3db973c3f7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/81a3d957a4a24b4aaacbfe3db973c3f7.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5784,
          name: "Gates of Babylon",
          uuid: "98dcb32702734f5e83d5bc84224c3fec",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/98dcb32702734f5e83d5bc84224c3fec.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5842,
          name: "Gates of Babylon Mini-Max",
          uuid: "9d7739795db94448acdab5a0ac81fbe5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/9d7739795db94448acdab5a0ac81fbe5.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5843,
          name: "Gates of Babylon Mini-Max Mobile",
          uuid: "b3da722405ef44b79a432ec42065d101",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b3da722405ef44b79a432ec42065d101.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5785,
          name: "Gates of Babylon Mobile",
          uuid: "40c04e845fe04032ac87081f6f01205d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/40c04e845fe04032ac87081f6f01205d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5706,
          name: "Goblins & Gemstones",
          uuid: "7bf072f212da49169083f532fbc483d2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7bf072f212da49169083f532fbc483d2.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5707,
          name: "Goblins & Gemstones Mobile",
          uuid: "e0a0f94bc6dc436d9da9d3f3dd919be2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/e0a0f94bc6dc436d9da9d3f3dd919be2.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5817,
          name: "Goblins & Gemstones: Hit 'n' Roll",
          uuid: "6fdb9163678e4499b033f73ad52c1275",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/6fdb9163678e4499b033f73ad52c1275.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5816,
          name: "Goblins & Gemstones: Hit 'n' Roll Mobile",
          uuid: "2cb6a8eec7904e96be499036ffe092cd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/2cb6a8eec7904e96be499036ffe092cd.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5874,
          name: "Gold Tracker 7s",
          uuid: "26a1e41f7ddb41b99894b84f6f61008f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/26a1e41f7ddb41b99894b84f6f61008f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5875,
          name: "Gold Tracker 7s Mobile",
          uuid: "b0b68093da0c421c9c8fd5c3a078e205",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b0b68093da0c421c9c8fd5c3a078e205.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5952,
          name: "Golden Kegs 50K",
          uuid: "ddc8a976e6b5404cafa6673ac41f5c52",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ddc8a976e6b5404cafa6673ac41f5c52.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5953,
          name: "Golden Kegs 50K Mobile",
          uuid: "5be2cc863696481887caff6c97c888bd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/5be2cc863696481887caff6c97c888bd.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5798,
          name: "Goldfire7s",
          uuid: "38cee81ba9d74a2ebcf1e5d21bca80c7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/38cee81ba9d74a2ebcf1e5d21bca80c7.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5799,
          name: "Goldfire7s Mobile",
          uuid: "bc2db9fd33854b4ab67586596eaef19b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/bc2db9fd33854b4ab67586596eaef19b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5720,
          name: "Griffin's Quest",
          uuid: "6e4a60f939aa4cd8a57e01d2075bb12f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/6e4a60f939aa4cd8a57e01d2075bb12f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5652,
          name: "Griffin's Quest Gamble Feature",
          uuid: "554cfaa61c97443baca2da8167c82269",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/554cfaa61c97443baca2da8167c82269.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5653,
          name: "Griffin's Quest Gamble Feature Mobile",
          uuid: "2aa59f3f4dfa4804964ad4eae534789a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/2aa59f3f4dfa4804964ad4eae534789a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5721,
          name: "Griffin's Quest Mobile",
          uuid: "f99060057f1e4801b496694cc468cb76",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/f99060057f1e4801b496694cc468cb76.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5708,
          name: "Griffin's Quest Xmas",
          uuid: "ca02c30b340d4f56858566d97f17b4d8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ca02c30b340d4f56858566d97f17b4d8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5709,
          name: "Griffin's Quest Xmas Mobile",
          uuid: "695e39e1fc3740088b765cb25c7584da",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/695e39e1fc3740088b765cb25c7584da.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5955,
          name: "Haunted Joker Hold and Win",
          uuid: "b7a2988803e98eb4ae64d8c126527b97370590aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/b7a2988803e98eb4ae64d8c126527b97370590aa.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5954,
          name: "Haunted Joker Hold and Win Mobile",
          uuid: "36e838e88c6b4b5a2d8464b9b5fc418c7d44b639",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/36e838e88c6b4b5a2d8464b9b5fc418c7d44b639.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5864,
          name: "Heimdall's Gate",
          uuid: "fbf1281f41c94174ba2e301e7bf682d2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/fbf1281f41c94174ba2e301e7bf682d2.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5904,
          name: "Heimdall's Gate Cash Quest",
          uuid: "ffb8548cc6d44245ad62a1a63c18d75b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ffb8548cc6d44245ad62a1a63c18d75b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5905,
          name: "Heimdall's Gate Cash Quest Mobile",
          uuid: "0e66494b1816401a92ee12da05240d1f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0e66494b1816401a92ee12da05240d1f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5865,
          name: "Heimdall's Gate Mobile",
          uuid: "facaadddcac34fe89bdb8cc986f60686",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/facaadddcac34fe89bdb8cc986f60686.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5888,
          name: "Hell Chef",
          uuid: "12a71c16b7a94fdaaee87d21c1b9e68b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/12a71c16b7a94fdaaee87d21c1b9e68b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5889,
          name: "Hell Chef Mobile",
          uuid: "6d14df1427e74a6b89c9ba0f0616e71f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/6d14df1427e74a6b89c9ba0f0616e71f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5800,
          name: "Holmes: Reel Detective",
          uuid: "506f043a93f54f84b2a8257d00520525",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/506f043a93f54f84b2a8257d00520525.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5801,
          name: "Holmes: Reel Detective Mobile",
          uuid: "cbffc47a3a5d41a0849de705abd41ac2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/cbffc47a3a5d41a0849de705abd41ac2.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5764,
          name: "Hong Bao",
          uuid: "83755447dae04bd79742d9d5d473c13b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/83755447dae04bd79742d9d5d473c13b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5765,
          name: "Hong Bao Mobile",
          uuid: "c4e04a4be8f74f2382d993b488121656",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/c4e04a4be8f74f2382d993b488121656.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5870,
          name: "Hyper Blitz Hold and Win",
          uuid: "d9578966e8dff0bb3e5535578f9004a6720c6945",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/d9578966e8dff0bb3e5535578f9004a6720c6945.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5871,
          name: "Hyper Blitz Hold and Win Mobile",
          uuid: "27ac7d4c1a03fe4c7bc83985cb38c0aebfcaadd8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/27ac7d4c1a03fe4c7bc83985cb38c0aebfcaadd8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5668,
          name: "Jewels of Jupiter",
          uuid: "9b76c2664e664a05895ecedc9574bb66",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/9b76c2664e664a05895ecedc9574bb66.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5669,
          name: "Jewels of Jupiter Mobile",
          uuid: "4bb6a2d86a4d4550b18401448a39439b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/4bb6a2d86a4d4550b18401448a39439b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5754,
          name: "Joker 3600",
          uuid: "85ba3f1475414c4ca31cb7f29ec9cff2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/85ba3f1475414c4ca31cb7f29ec9cff2.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5755,
          name: "Joker 3600 Mobile",
          uuid: "6ebc2ab76dbe415d83134229e7494510",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/6ebc2ab76dbe415d83134229e7494510.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5830,
          name: "Joker Fortune Blitz",
          uuid: "14564f923b9dc2a467f0149bedd69f65c7e03757",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/14564f923b9dc2a467f0149bedd69f65c7e03757.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5831,
          name: "Joker Fortune Blitz Mobile",
          uuid: "c39abb2e325ebbcb9ef8ec0067ce7112d727dcdf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/c39abb2e325ebbcb9ef8ec0067ce7112d727dcdf.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5934,
          name: "Joker Gold 7s",
          uuid: "9da5187c56d94e57b3e4742642308bcd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/9da5187c56d94e57b3e4742642308bcd.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5935,
          name: "Joker Gold 7s Mobile",
          uuid: "44ec25f79cb74422b2eda80b611b0966",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/44ec25f79cb74422b2eda80b611b0966.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5932,
          name: "Joker Hit'n'Goal",
          uuid: "3916402a44174d84a4d946651a0e1ea8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/3916402a44174d84a4d946651a0e1ea8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5933,
          name: "Joker Hit'n'Goal Mobile",
          uuid: "0b9d811f0efb4196b1de810946e9492b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0b9d811f0efb4196b1de810946e9492b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5714,
          name: "Joker Lanterns",
          uuid: "fa3d8fa098504e3a934f75955e8be0bb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/fa3d8fa098504e3a934f75955e8be0bb.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5815,
          name: "Joker Lanterns Hit 'n' Roll",
          uuid: "184fcb693dca43c291ac9c4d01bbb979",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/184fcb693dca43c291ac9c4d01bbb979.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5814,
          name: "Joker Lanterns Hit 'n' Roll Mobile",
          uuid: "0b174b4422b042bdb9fac2109a34a2ab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0b174b4422b042bdb9fac2109a34a2ab.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5886,
          name: "Joker Lanterns Hold and Win",
          uuid: "44ae6e41ee34456181d00b957d722324",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/44ae6e41ee34456181d00b957d722324.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5887,
          name: "Joker Lanterns Hold and Win Mobile",
          uuid: "05b6d192f1bd4d7c96276aed96f822e7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/05b6d192f1bd4d7c96276aed96f822e7.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5715,
          name: "Joker Lanterns Mobile",
          uuid: "b5adc90db650453f8f0a0a3f3bebe623",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b5adc90db650453f8f0a0a3f3bebe623.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5698,
          name: "Joker Leprechauns",
          uuid: "05ec5d3bcc5a41d59e5680a866c07c49",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/05ec5d3bcc5a41d59e5680a866c07c49.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5844,
          name: "Joker Leprechauns Hit’n’Roll",
          uuid: "56033c22d0ff45a8941a91549c3de1a2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/56033c22d0ff45a8941a91549c3de1a2.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5845,
          name: "Joker Leprechauns Hit’n’Roll Mobile",
          uuid: "cbc9f69de6004cb9887b4a8b4fd90f53",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/cbc9f69de6004cb9887b4a8b4fd90f53.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5908,
          name: "Joker Leprechauns Hold and Win",
          uuid: "47529450a0774c09acfa892beeebaca3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/47529450a0774c09acfa892beeebaca3.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5909,
          name: "Joker Leprechauns Hold and Win Mobile",
          uuid: "d57bcb256e254103ad8c7434090bfc75",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/d57bcb256e254103ad8c7434090bfc75.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5699,
          name: "Joker Leprechauns Mobile",
          uuid: "d6f848e018e540748f24b69a988b50c8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/d6f848e018e540748f24b69a988b50c8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5730,
          name: "Joker Max",
          uuid: "c414d536fdf545a0a7fbc6562ba1933b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/c414d536fdf545a0a7fbc6562ba1933b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5682,
          name: "Joker Max Gamble Feature",
          uuid: "0ba5e2bb528d4c3289c1fa9e26b3ec9a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0ba5e2bb528d4c3289c1fa9e26b3ec9a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5683,
          name: "Joker Max Gamble Feature Mobile",
          uuid: "a08e8162be6248958aac055ace185980",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a08e8162be6248958aac055ace185980.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5846,
          name: "Joker Max Hit'n'Roll X-mas Edition",
          uuid: "956aee867cdb4ed68d3a6bfe19ac0213",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/956aee867cdb4ed68d3a6bfe19ac0213.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5847,
          name: "Joker Max Hit'n'Roll X-mas Edition Mobile",
          uuid: "470f03449a1241a4abee46673693a4b3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/470f03449a1241a4abee46673693a4b3.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5731,
          name: "Joker Max Mobile",
          uuid: "4d8ed18c009d4d43934701ba3843a0e0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/4d8ed18c009d4d43934701ba3843a0e0.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5813,
          name: "Joker Max: Hit 'n' Roll",
          uuid: "c920316279b24f3f8a3221d555b33657",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/c920316279b24f3f8a3221d555b33657.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5812,
          name: "Joker Max: Hit 'n' Roll Mobile",
          uuid: "abbed40f52bb4e07a1b3be2931c654e0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/abbed40f52bb4e07a1b3be2931c654e0.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5848,
          name: "Joker Rainbows",
          uuid: "8f925cc87ce94828a3e2a9cc1931ab14",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/8f925cc87ce94828a3e2a9cc1931ab14.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5849,
          name: "Joker Rainbows Mobile",
          uuid: "512168f60a3147f78fec1b9b5b94f609",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/512168f60a3147f78fec1b9b5b94f609.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5956,
          name: "Joker Sparks 7s",
          uuid: "7c0934c3ea0e43bc8a61e3e3a5cc34d8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7c0934c3ea0e43bc8a61e3e3a5cc34d8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5957,
          name: "Joker Sparks 7s Mobile",
          uuid: "076a21494fbb4fdf9cc5ca5038b7069c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/076a21494fbb4fdf9cc5ca5038b7069c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5772,
          name: "Joker Supreme",
          uuid: "849e4a2092dc484aa008d71eb7612583",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/849e4a2092dc484aa008d71eb7612583.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5773,
          name: "Joker Supreme Mobile",
          uuid: "94a48c49c3f34f7287241895a8a97371",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/94a48c49c3f34f7287241895a8a97371.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5742,
          name: "Joker Supreme Xmas",
          uuid: "2ad68adb44844c8a8df5ee3064017ed3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/2ad68adb44844c8a8df5ee3064017ed3.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5743,
          name: "Joker Supreme Xmas Mobile",
          uuid: "3c5d1211f93c461ebe24ac2ca8217744",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/3c5d1211f93c461ebe24ac2ca8217744.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5692,
          name: "Joker Times",
          uuid: "cdef9432d93f4791b7fa8a888c52539f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/cdef9432d93f4791b7fa8a888c52539f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5693,
          name: "Joker Times Mobile",
          uuid: "ab190680e5e74606b435b3570dd253e8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ab190680e5e74606b435b3570dd253e8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5650,
          name: "Joker Times Xmas",
          uuid: "d39a9893221246feaf4c0d7e38feb073",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/d39a9893221246feaf4c0d7e38feb073.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5651,
          name: "Joker Times Xmas Mobile",
          uuid: "5ac6fb8c173d40fe91b47c365961cf31",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/5ac6fb8c173d40fe91b47c365961cf31.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5662,
          name: "Lady Lava",
          uuid: "7c7948de5f0c491c87f27c7e34804e76",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7c7948de5f0c491c87f27c7e34804e76.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5850,
          name: "Lady Lava Mini-Max",
          uuid: "125fc20a371142668357214d5d9ae63c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/125fc20a371142668357214d5d9ae63c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5851,
          name: "Lady Lava Mini-Max Mobile",
          uuid: "73174b29d48d4c33b07df56e4e77fdcc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/73174b29d48d4c33b07df56e4e77fdcc.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5663,
          name: "Lady Lava Mobile",
          uuid: "f740194be45c48a598401ce0e7ac573f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/f740194be45c48a598401ce0e7ac573f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5811,
          name: "Legend of Senteng",
          uuid: "a3cf178daaea48a48d3d081aade391c8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a3cf178daaea48a48d3d081aade391c8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5810,
          name: "Legend of Senteng Mobile",
          uuid: "e3f1a98fd5df4aa093b37d3e50447a94",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/e3f1a98fd5df4aa093b37d3e50447a94.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5862,
          name: "Lightning Fortune",
          uuid: "b15ff08217644e3cb08dbb1fc96a7a41d2946fc4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/b15ff08217644e3cb08dbb1fc96a7a41d2946fc4.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5863,
          name: "Lightning Fortune Mobile",
          uuid: "a2f6d325d4e9ddc1f62bc8e1e7b596b2eec86362",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/a2f6d325d4e9ddc1f62bc8e1e7b596b2eec86362.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5923,
          name: "Machina Prize Match",
          uuid: "f0918645377f4192894aecc2d0bde83a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/f0918645377f4192894aecc2d0bde83a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5922,
          name: "Machina Prize Match Mobile",
          uuid: "181835aa50fa48358c104bd1d3d1d4c8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/181835aa50fa48358c104bd1d3d1d4c8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5786,
          name: "Mammoth Chase",
          uuid: "1531138596ba45b4a92d506d2f914968",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/1531138596ba45b4a92d506d2f914968.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5762,
          name: "Mammoth Chase Easter Edition",
          uuid: "1c633b42b79c463db198c9c192406cf4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/1c633b42b79c463db198c9c192406cf4.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5763,
          name: "Mammoth Chase Easter Edition Mobile",
          uuid: "2ad299e73553424fb8b3973067ed4515",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/2ad299e73553424fb8b3973067ed4515.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5787,
          name: "Mammoth Chase Mobile",
          uuid: "7afe0274ff3d4fbba7be6c2be3443d92",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7afe0274ff3d4fbba7be6c2be3443d92.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5718,
          name: "Maui Millions",
          uuid: "49adc8b7fd99413694b3bac30d25ad14",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/49adc8b7fd99413694b3bac30d25ad14.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5719,
          name: "Maui Millions Mobile",
          uuid: "a38bdd772de345c1a21d802d2a2b89c3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a38bdd772de345c1a21d802d2a2b89c3.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5758,
          name: "Mermaids Galore",
          uuid: "a66af4d0424e4f1e8dc91a42cb1eae26",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a66af4d0424e4f1e8dc91a42cb1eae26.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5759,
          name: "Mermaids Galore Mobile",
          uuid: "b4eb7af2ac294bd6b3e3058b6e928e28",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b4eb7af2ac294bd6b3e3058b6e928e28.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5726,
          name: "Miami Bonus Wheel",
          uuid: "c7de379f32fe44c38f51225e942c2045",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/c7de379f32fe44c38f51225e942c2045.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5852,
          name: "Miami Bonus Wheel Hit'n'Roll",
          uuid: "591141f1dfa24e029f19c6925cd7a7c3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/591141f1dfa24e029f19c6925cd7a7c3.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5853,
          name: "Miami Bonus Wheel Hit'n'Roll Mobile",
          uuid: "0bbf9c4f752f4fe185961f3e11bbd854",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0bbf9c4f752f4fe185961f3e11bbd854.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5727,
          name: "Miami Bonus Wheel Mobile",
          uuid: "a083cd3c6ca94ce6aed9a3ddf16aad14",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a083cd3c6ca94ce6aed9a3ddf16aad14.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5920,
          name: "Miami Sunset 7s Hold and Win",
          uuid: "71eedbbf0e0745bdb15b297b58f60734",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/71eedbbf0e0745bdb15b297b58f60734.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5921,
          name: "Miami Sunset 7s Hold and Win Mobile",
          uuid: "6b98310ec995408e9c951bc39eedd879",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/6b98310ec995408e9c951bc39eedd879.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5752,
          name: "Midas Treasure",
          uuid: "1d8b27ed05514eb0a1e6c048bb2dd2c9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/1d8b27ed05514eb0a1e6c048bb2dd2c9.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5854,
          name: "Midas Treasure Mini-Max",
          uuid: "a53f90572bb342069fc9c0ddd139052b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a53f90572bb342069fc9c0ddd139052b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5855,
          name: "Midas Treasure Mini-Max Mobile",
          uuid: "5e997fe8559e490fb027d1b4bf7a6609",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/5e997fe8559e490fb027d1b4bf7a6609.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5753,
          name: "Midas Treasure Mobile",
          uuid: "60aed94bb62941de84db355426c2ecd8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/60aed94bb62941de84db355426c2ecd8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5782,
          name: "Monkey God",
          uuid: "7e1fb96117c6426cad425360a25f2bd0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7e1fb96117c6426cad425360a25f2bd0.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5694,
          name: "Monkey God Buy Feature",
          uuid: "0aed3b0599504c1db4dc802bd88f069d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0aed3b0599504c1db4dc802bd88f069d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5695,
          name: "Monkey God Buy Feature Mobile",
          uuid: "3cf4dea0b723463fbc5bdbfe39a432fd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/3cf4dea0b723463fbc5bdbfe39a432fd.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5890,
          name: "Monkey God Hold and Win",
          uuid: "0cc3348016664ccd8d29db35e7b52fe2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0cc3348016664ccd8d29db35e7b52fe2.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5891,
          name: "Monkey God Hold and Win Mobile",
          uuid: "643a6a163ac6450ab77226d43f5feb4a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/643a6a163ac6450ab77226d43f5feb4a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5783,
          name: "Monkey God Mobile",
          uuid: "6d783b37d7d247b98dcd72234f43356f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/6d783b37d7d247b98dcd72234f43356f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5926,
          name: "Moo Snatchers",
          uuid: "9cabea74a2694772a8f19bac0218d62b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/9cabea74a2694772a8f19bac0218d62b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5927,
          name: "Moo Snatchers Mobile",
          uuid: "50033a8dd59643a39371541fc08a02c0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/50033a8dd59643a39371541fc08a02c0.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5951,
          name: "Mooncake Riches Hold and Win",
          uuid: "81bf0779d1c6e664df954813e0e41f27754138bd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/81bf0779d1c6e664df954813e0e41f27754138bd.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5950,
          name: "Mooncake Riches Hold and Win Mobile",
          uuid: "820cad39e6bd88f590ddeaded5fd3b79b6817fac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Kalamba/820cad39e6bd88f590ddeaded5fd3b79b6817fac.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5880,
          name: "Moonrise Fortunes Hold and Win",
          uuid: "75c5f2ce07554f79b8c1c42985c1f88a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/75c5f2ce07554f79b8c1c42985c1f88a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5881,
          name: "Moonrise Fortunes Hold and Win Mobile",
          uuid: "0357ab9812e24288a53796647c498c4e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0357ab9812e24288a53796647c498c4e.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5902,
          name: "Ninjaz vs Narcos",
          uuid: "4f41738fea7f4c2da79a4e728c6e89c1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/4f41738fea7f4c2da79a4e728c6e89c1.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5903,
          name: "Ninjaz vs Narcos Mobile",
          uuid: "e5110b5f99f24ee9982e079af408ea36",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/e5110b5f99f24ee9982e079af408ea36.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5740,
          name: "Operation Diamond Hunt",
          uuid: "02cce40bf028449d959a05495cefc545",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/02cce40bf028449d959a05495cefc545.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5686,
          name: "Operation Diamond Hunt Gamble Feature",
          uuid: "b9d8204e50094ef599a15bd71414362b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b9d8204e50094ef599a15bd71414362b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5687,
          name: "Operation Diamond Hunt Gamble Feature Mobile",
          uuid: "3ddd6ae9d6bd4ca696eb701ff72b88ab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/3ddd6ae9d6bd4ca696eb701ff72b88ab.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5856,
          name: "Operation Diamond Hunt Mini-Max",
          uuid: "10881bddae294bc5a8629d24ccaba2b8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/10881bddae294bc5a8629d24ccaba2b8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5857,
          name: "Operation Diamond Hunt Mini-Max Mobile",
          uuid: "b74c91912ec042bf83222821102e4408",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b74c91912ec042bf83222821102e4408.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5741,
          name: "Operation Diamond Hunt Mobile",
          uuid: "b2a29475bd5d4330b2c3f8b1bc20694e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b2a29475bd5d4330b2c3f8b1bc20694e.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5712,
          name: "Pawprints of Purrsia",
          uuid: "9c2b1aa86ed542dc8bbe341ef9d77070",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/9c2b1aa86ed542dc8bbe341ef9d77070.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5713,
          name: "Pawprints of Purrsia Mobile",
          uuid: "376e9e48dd81462fb913b6713ce0f42e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/376e9e48dd81462fb913b6713ce0f42e.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5672,
          name: "Pearls of Aphrodite",
          uuid: "da4d6756f29d4fd095045a9e42bdc17a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/da4d6756f29d4fd095045a9e42bdc17a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5809,
          name: "Pearls of Aphrodite Mini-Max",
          uuid: "57f5b1e320764041b0b235e0278ffc66",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/57f5b1e320764041b0b235e0278ffc66.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5808,
          name: "Pearls of Aphrodite Mini-Max Mobile",
          uuid: "637bb7d5def7495f9f2e74ac37b7fffd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/637bb7d5def7495f9f2e74ac37b7fffd.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5673,
          name: "Pearls of Aphrodite Mobile",
          uuid: "9e0d8cd46df9421bbe7d673bcac663c9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/9e0d8cd46df9421bbe7d673bcac663c9.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5648,
          name: "Pharaoh's Reign",
          uuid: "38cce027663f48c4bcc3b66c43a4bfed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/38cce027663f48c4bcc3b66c43a4bfed.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5858,
          name: "Pharaoh's Reign Mini-Max",
          uuid: "94a3aa628bea49a7a6afe8ae4ec508af",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/94a3aa628bea49a7a6afe8ae4ec508af.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5859,
          name: "Pharaoh's Reign Mini-Max Mobile",
          uuid: "7ae20e7d52534d80bfea93095753b4b8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7ae20e7d52534d80bfea93095753b4b8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5649,
          name: "Pharaoh's Reign Mobile",
          uuid: "12def15a8aaa46c98abb1a5682d71e57",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/12def15a8aaa46c98abb1a5682d71e57.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5948,
          name: "Pharaohs Dynasty PrizeMatch",
          uuid: "65529cceff2a4a408029f799659a727c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/65529cceff2a4a408029f799659a727c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5949,
          name: "Pharaohs Dynasty PrizeMatch Mobile",
          uuid: "dcbfb6ec7ac14dcbafb3f406f0274ae8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/dcbfb6ec7ac14dcbafb3f406f0274ae8.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5732,
          name: "Pyro Pixie",
          uuid: "493f37df79ea44c182d17ca2168efc85",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/493f37df79ea44c182d17ca2168efc85.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5733,
          name: "Pyro Pixie Mobile",
          uuid: "84ab11e9d1b24bfcae69909c5a126801",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/84ab11e9d1b24bfcae69909c5a126801.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5924,
          name: "Ramses Blitz Hold and Win",
          uuid: "809a2f0ced3c4776a2858c2128ba6a8a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/809a2f0ced3c4776a2858c2128ba6a8a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5925,
          name: "Ramses Blitz Hold and Win Mobile",
          uuid: "82d8bf4b71194ca2a438fe4704421e7a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/82d8bf4b71194ca2a438fe4704421e7a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5766,
          name: "Ruby Hunter",
          uuid: "b5cdd56308f540f0b3caac3354605abf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b5cdd56308f540f0b3caac3354605abf.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5767,
          name: "Ruby Hunter Mobile",
          uuid: "80292ac19cb5449281e84a932bd5c345",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/80292ac19cb5449281e84a932bd5c345.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5916,
          name: "Rumble Ratz Hold the Cheese",
          uuid: "55c4934524b240a1b15466c6dc60065b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/55c4934524b240a1b15466c6dc60065b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5917,
          name: "Rumble Ratz Hold the Cheese Mobile",
          uuid: "9cd92e0a9a6040e4b7b6ae1d70f325ed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/9cd92e0a9a6040e4b7b6ae1d70f325ed.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5710,
          name: "Sadie Swift: Guns'n Glyphs",
          uuid: "40a1ca35022a4d858e123b3d1af1cee7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/40a1ca35022a4d858e123b3d1af1cee7.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5711,
          name: "Sadie Swift: Guns'n Glyphs Mobile",
          uuid: "ff05e507d32847f48a80dcd491176914",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ff05e507d32847f48a80dcd491176914.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5670,
          name: "Safari Chase",
          uuid: "156463dbdfe1438e98d593d03cedf7e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/156463dbdfe1438e98d593d03cedf7e3.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5671,
          name: "Safari Chase Mobile",
          uuid: "de6d5052c6844b22b6be9cd51a131b2c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/de6d5052c6844b22b6be9cd51a131b2c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5807,
          name: "Safari Chase: Hit 'n' Roll",
          uuid: "052005b4f88d494e8366da652eabca2c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/052005b4f88d494e8366da652eabca2c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5806,
          name: "Safari Chase: Hit 'n' Roll Mobile",
          uuid: "2391ce9dbd054211bd9265fed65d28a4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/2391ce9dbd054211bd9265fed65d28a4.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5896,
          name: "Santa Blitz Hold and Win",
          uuid: "ae9f63cc86eb48fc8f43268d39baf300",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ae9f63cc86eb48fc8f43268d39baf300.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5897,
          name: "Santa Blitz Hold and Win Mobile",
          uuid: "65bd8da721b04cf2bf4a98756265290a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/65bd8da721b04cf2bf4a98756265290a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5947,
          name: "Shamrock Party 7s",
          uuid: "a466f28cd777488abbec479ca629b3a0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a466f28cd777488abbec479ca629b3a0.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5946,
          name: "Shamrock Party 7s Mobile",
          uuid: "58459586d46841e6a8b365fd61b3c3ab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/58459586d46841e6a8b365fd61b3c3ab.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5722,
          name: "Sky Hunters",
          uuid: "da37799d4cd84f728f98f22263970b8c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/da37799d4cd84f728f98f22263970b8c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5660,
          name: "Sky Hunters Gamble Feature",
          uuid: "a6fb78be3573487f8577136a5780faba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a6fb78be3573487f8577136a5780faba.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5661,
          name: "Sky Hunters Gamble Feature Mobile",
          uuid: "fa3caf35b23b4e17a703bc563c675e0c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/fa3caf35b23b4e17a703bc563c675e0c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5723,
          name: "Sky Hunters Mobile",
          uuid: "b606c2aba06941b2a9d27aedcb9e1317",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b606c2aba06941b2a9d27aedcb9e1317.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5696,
          name: "Speakeasy Boost",
          uuid: "7dc889b689d944289bb6b0adc08a7266",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7dc889b689d944289bb6b0adc08a7266.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5697,
          name: "Speakeasy Boost Mobile",
          uuid: "92d2b3f9313642f78feaed479f81e805",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/92d2b3f9313642f78feaed479f81e805.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5805,
          name: "Super Size Atlas",
          uuid: "f2254d53ec79495f97bd42dba559eeee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/f2254d53ec79495f97bd42dba559eeee.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5804,
          name: "Super Size Atlas Mobile",
          uuid: "034c7fa6daa24f598ebb31b59a500b14",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/034c7fa6daa24f598ebb31b59a500b14.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5734,
          name: "Temple of Heroes",
          uuid: "7448c2ffd9b94b62a6e63ea44f44040b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7448c2ffd9b94b62a6e63ea44f44040b.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5735,
          name: "Temple of Heroes Mobile",
          uuid: "b33cbe13b28e4bff89905357afd1ae05",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b33cbe13b28e4bff89905357afd1ae05.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5790,
          name: "Tiger Claws",
          uuid: "1485bb01603b4f3aba3f8ad3b9d67f57",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/1485bb01603b4f3aba3f8ad3b9d67f57.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5791,
          name: "Tiger Claws Mobile",
          uuid: "fe7dd967fbb34e998aed7543492b4ffb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/fe7dd967fbb34e998aed7543492b4ffb.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5788,
          name: "Tree of Gold",
          uuid: "76daab84dd8b4f3e8fa639ef67b441dc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/76daab84dd8b4f3e8fa639ef67b441dc.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5789,
          name: "Tree of Gold Mobile",
          uuid: "20104d915aed461f97559c59ce3c3703",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/20104d915aed461f97559c59ce3c3703.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5646,
          name: "Vegas Blast",
          uuid: "be68cd3060f84407bcb7200415181db6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/be68cd3060f84407bcb7200415181db6.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5860,
          name: "Vegas Blast Mini-Max",
          uuid: "7a81c338b5804c75aa8558f808d7c79a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7a81c338b5804c75aa8558f808d7c79a.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5861,
          name: "Vegas Blast Mini-Max Mobile",
          uuid: "9ac380f7621e45b8a71d81469c186536",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/9ac380f7621e45b8a71d81469c186536.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5647,
          name: "Vegas Blast Mobile",
          uuid: "b9e870f44f3b41fbb29c60d2b2cd76eb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/b9e870f44f3b41fbb29c60d2b2cd76eb.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5744,
          name: "Wildcraft",
          uuid: "7c0f509934414a8893283c2c65d5a449",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/7c0f509934414a8893283c2c65d5a449.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5745,
          name: "Wildcraft Mobile",
          uuid: "98238a3bfd8e41059d8acefd4b8eb28c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/98238a3bfd8e41059d8acefd4b8eb28c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5774,
          name: "Wildmine",
          uuid: "8588759cdf0341d88a7d0513e9b99505",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/8588759cdf0341d88a7d0513e9b99505.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5775,
          name: "Wildmine Mobile",
          uuid: "c052cb7832eb432bb80a03320a527b58",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/c052cb7832eb432bb80a03320a527b58.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5876,
          name: "Wilds from the Crypt",
          uuid: "9193ec1ef48748128f4eaec70298a04f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/9193ec1ef48748128f4eaec70298a04f.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5877,
          name: "Wilds from the Crypt Mobile",
          uuid: "d8dc703ecf52407cad381a2ce629eda9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/d8dc703ecf52407cad381a2ce629eda9.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5894,
          name: "Xmas Lightning",
          uuid: "5943795af680445eaf2de4805257234c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/5943795af680445eaf2de4805257234c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5895,
          name: "Xmas Lightning Mobile",
          uuid: "ef0d4b198e814a65ad7ffbef6b592fd5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/ef0d4b198e814a65ad7ffbef6b592fd5.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5746,
          name: "Zombie Queen",
          uuid: "013078456be84906ae5a5a0cdd85b860",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/013078456be84906ae5a5a0cdd85b860.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5654,
          name: "Zombie Queen Gamble Feature",
          uuid: "eb0069175b054f3b8246721cd2a1cdeb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/eb0069175b054f3b8246721cd2a1cdeb.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5655,
          name: "Zombie Queen Gamble Feature Mobile",
          uuid: "a6f6aad4af1a4922959923c99976f70d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/a6f6aad4af1a4922959923c99976f70d.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5747,
          name: "Zombie Queen Mobile",
          uuid: "0e367146444f4bc1ad410f47d4c21c4c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Kalamba/0e367146444f4bc1ad410f47d4c21c4c.png",
          type: "slots",
          provider: "Kalamba",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 128,
      name: "MPlay",
      providerId: 49,
      providerName: "MPlay",
      games: [
        {
          game_id: 5965,
          name: "Andar Bahar",
          uuid: "ddda30159e51481e8ce8f5c844b0606a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/ddda30159e51481e8ce8f5c844b0606a.png",
          type: "Table Game",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5976,
          name: "Bollywood Stars",
          uuid: "1a1911c1f89e41c5983719b29840dd02",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/1a1911c1f89e41c5983719b29840dd02.png",
          type: "slots",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5973,
          name: "Cosmic Jewels",
          uuid: "cd12088e64d542bcaab5196c44276c92",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/cd12088e64d542bcaab5196c44276c92.png",
          type: "slots",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5961,
          name: "Crorepati Challenge",
          uuid: "a46ed90239c14874aa792db97588d732",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/a46ed90239c14874aa792db97588d732.png",
          type: "slots",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5970,
          name: "Diwali Dhamaka",
          uuid: "a9535a1cbd954868810dd2275fa46363",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/a9535a1cbd954868810dd2275fa46363.png",
          type: "slots",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5964,
          name: "Double Up",
          uuid: "67d821867da548e5b2888a62da3c95d3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/67d821867da548e5b2888a62da3c95d3.png",
          type: "Table Game",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5974,
          name: "Dragon Tiger",
          uuid: "5e9752a874a34665a68051454f0e08a8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/5e9752a874a34665a68051454f0e08a8.png",
          type: "Table Game",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5963,
          name: "Indian T20 League",
          uuid: "eca2f0f503b04b179da2243e4ba907fd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/eca2f0f503b04b179da2243e4ba907fd.png",
          type: "slots",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5969,
          name: "Jhandi Munda",
          uuid: "65257ce131af4fdda9950be3efbdce65",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/65257ce131af4fdda9950be3efbdce65.png",
          type: "Table Game",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5966,
          name: "Ludo Express",
          uuid: "6425fc82f7834fb3a0cb896d2d208df4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/6425fc82f7834fb3a0cb896d2d208df4.png",
          type: "Board Game",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5962,
          name: "Mystic Magician",
          uuid: "ab71d1fe131a4877b6781891edc470ce",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/ab71d1fe131a4877b6781891edc470ce.png",
          type: "slots",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5975,
          name: "Satta Matka Express",
          uuid: "c4fa77a3e0814eed960a26690b6d3abf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/c4fa77a3e0814eed960a26690b6d3abf.png",
          type: "Lottery Style Game",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5977,
          name: "Sicbo",
          uuid: "dfd2d4ca15104a8cb46981b079941ca5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/dfd2d4ca15104a8cb46981b079941ca5.png",
          type: "Table Game",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5972,
          name: "Snakes n Ladders",
          uuid: "205a484b1a6f473a937d13dbdab4b069",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/205a484b1a6f473a937d13dbdab4b069.png",
          type: "Board Game",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5968,
          name: "Teen Patti Champion",
          uuid: "c3669029c45c4ea7b95e685009a50ac8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/c3669029c45c4ea7b95e685009a50ac8.png",
          type: "Table Game",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5967,
          name: "Teen Patti Express",
          uuid: "d3571c9140804b23816e3c9816303d3e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/d3571c9140804b23816e3c9816303d3e.png",
          type: "Table Game",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5960,
          name: "Tropical Birds",
          uuid: "8733ebd964054db0902f16fa851db280",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/8733ebd964054db0902f16fa851db280.png",
          type: "slots",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5971,
          name: "Wild West Mystery",
          uuid: "db43937de0404b95b5a951d9082c252b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/MPlay/db43937de0404b95b5a951d9082c252b.png",
          type: "slots",
          provider: "MPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 129,
      name: "MacawGaming",
      providerId: 39,
      providerName: "MacawGaming",
      games: [
        {
          game_id: 5996,
          name: "Abrakadabra",
          uuid: "70a748424e321fee3db16bdc94e1e57fa9ce285c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/70a748424e321fee3db16bdc94e1e57fa9ce285c.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5997,
          name: "Abrakadabra Mobile",
          uuid: "7feb890fc21ed6e6025ae2f2475b33697d509b68",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7feb890fc21ed6e6025ae2f2475b33697d509b68.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5986,
          name: "Fortune Melody",
          uuid: "7dece7f33bf443e786243d6cd6d9ea61",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/7dece7f33bf443e786243d6cd6d9ea61.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5987,
          name: "Fortune Melody Mobile",
          uuid: "9982a6a2bf084910999a95f25010b09d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/9982a6a2bf084910999a95f25010b09d.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5978,
          name: "Fruit Cocktail",
          uuid: "4ea6cf4600e34cdb9edef3a8dd54c2ab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/4ea6cf4600e34cdb9edef3a8dd54c2ab.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5979,
          name: "Fruit Cocktail Mobile",
          uuid: "62291d68d56b49d697d7f4d30583e42c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/62291d68d56b49d697d7f4d30583e42c.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5990,
          name: "Hot Balloon",
          uuid: "4f420b34bbd54c06b3c25b2a1cb9f791",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/4f420b34bbd54c06b3c25b2a1cb9f791.png",
          type: "other",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6004,
          name: "Hot Balloon Deluxe",
          uuid: "f896dbcabb994062bd7a26df58b6bd45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/f896dbcabb994062bd7a26df58b6bd45.png",
          type: "crash",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6005,
          name: "Hot Balloon Deluxe Mobile",
          uuid: "27d260a3266945449292aa47db46add2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/27d260a3266945449292aa47db46add2.png",
          type: "crash",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5991,
          name: "Hot Balloon Mobile",
          uuid: "7f3e7eb5981c4224b09cb9544073ebb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/7f3e7eb5981c4224b09cb9544073ebb3.png",
          type: "other",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5998,
          name: "Joker's Smile",
          uuid: "cbf7cfa9ef54b894c3f1c628d1e7a29f00ddc917",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cbf7cfa9ef54b894c3f1c628d1e7a29f00ddc917.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5999,
          name: "Joker's Smile Mobile",
          uuid: "f2ec36e8660c258bcae04ac7807285fd7e721cbf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f2ec36e8660c258bcae04ac7807285fd7e721cbf.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5994,
          name: "Lucky 8",
          uuid: "726f40b3ca4640a89b886d99a6efd593",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/726f40b3ca4640a89b886d99a6efd593.png",
          type: "keno",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5995,
          name: "Lucky 8 Mobile",
          uuid: "3d2212ead9b64d61a1d540f74225474e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/3d2212ead9b64d61a1d540f74225474e.png",
          type: "keno",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6000,
          name: "MacLudo",
          uuid: "15336b6223ca4bf6cbdeeaf7a8b253823957d8f5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/15336b6223ca4bf6cbdeeaf7a8b253823957d8f5.png",
          type: "other",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6001,
          name: "MacLudo Mobile",
          uuid: "be92569e9279422187546c54553ad326d8907b3b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/be92569e9279422187546c54553ad326d8907b3b.png",
          type: "other",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5980,
          name: "Mysterious Night",
          uuid: "ecfbb24f7bf047c090ebfe56396c78db",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/ecfbb24f7bf047c090ebfe56396c78db.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5981,
          name: "Mysterious Night Mobile",
          uuid: "e1184b5e92324c52b34a951af784f13b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/e1184b5e92324c52b34a951af784f13b.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5992,
          name: "Neon City Wheel",
          uuid: "f031874d915f4271aaaf070ca6cc30e2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/f031874d915f4271aaaf070ca6cc30e2.png",
          type: "wheel of fortune",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5993,
          name: "Neon City Wheel Mobile",
          uuid: "f263ec9be7b54d65842c72eaebb44265",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/f263ec9be7b54d65842c72eaebb44265.png",
          type: "wheel of fortune",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5988,
          name: "Rio 40 Graus",
          uuid: "21bcb47d862142eaa3b316da06e81daa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/21bcb47d862142eaa3b316da06e81daa.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5989,
          name: "Rio 40 Graus Mobile",
          uuid: "d111bc5cbd5545eebbeaa44c043510a0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/d111bc5cbd5545eebbeaa44c043510a0.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6002,
          name: "Suleiman The Magnificent",
          uuid: "3870787d37aa125cd11d00ac612d8bea06c6239a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/3870787d37aa125cd11d00ac612d8bea06c6239a.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6003,
          name: "Suleiman The Magnificent Mobile",
          uuid: "b3a2fe30874b4ac44abedf73fc2b791f99bc09d1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/b3a2fe30874b4ac44abedf73fc2b791f99bc09d1.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5984,
          name: "Walking Death",
          uuid: "b9ba2a3640c44546a3ec34f436ee31d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/b9ba2a3640c44546a3ec34f436ee31d3.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5985,
          name: "Walking Death Mobile",
          uuid: "d70dc832348e46438cb9843896597f17",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/d70dc832348e46438cb9843896597f17.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5982,
          name: "Zodiac Adventure",
          uuid: "4bd1d02a07c74e738c8225888ab5fe96",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/4bd1d02a07c74e738c8225888ab5fe96.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 5983,
          name: "Zodiac Adventure Mobile",
          uuid: "8f0dbe088c5f48e7ae7de519165ad96e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/MacawGaming/8f0dbe088c5f48e7ae7de519165ad96e.png",
          type: "slots",
          provider: "MacawGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 130,
      name: "Mascot",
      providerId: 23,
      providerName: "Mascot",
      games: [
        {
          game_id: 6032,
          name: "3 Corsairs",
          uuid: "320c04f3ce02e37946bf85f860e98d147bd70a83",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/320c04f3ce02e37946bf85f860e98d147bd70a83.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6054,
          name: "Across The Universe",
          uuid: "3291369be45ed750a9712a90873d38bc64187f90",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3291369be45ed750a9712a90873d38bc64187f90.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6102,
          name: "Across the Universe Keno",
          uuid: "6e13cb70f8570272083a540aa40fc203da4db4c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/6e13cb70f8570272083a540aa40fc203da4db4c0.png",
          type: "keno",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6046,
          name: "Aloha! Tiki bar",
          uuid: "c9504a4b986951b7f01c4f298cc30de6437b7936",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c9504a4b986951b7f01c4f298cc30de6437b7936.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6076,
          name: "Aloha! Tiki Bar dice",
          uuid: "42180bf0e6b640a4bb96c53dc6bb2482",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/42180bf0e6b640a4bb96c53dc6bb2482.png",
          type: "other",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6052,
          name: "Amaterasu Keno",
          uuid: "8c30002d6536f4ecb42dd3dfd7df5b016c7eb133",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8c30002d6536f4ecb42dd3dfd7df5b016c7eb133.png",
          type: "lottery",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6006,
          name: "Anksunamun: the queen of Egypt",
          uuid: "84ca1ca751bb9dd9485f95e5c00351cdd98b3ed1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/84ca1ca751bb9dd9485f95e5c00351cdd98b3ed1.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6018,
          name: "Baccarat",
          uuid: "73dfee0f3eb4a957fd0be44bb625e6e9822022ba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/73dfee0f3eb4a957fd0be44bb625e6e9822022ba.png",
          type: "table",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6015,
          name: "Bamboo Bear",
          uuid: "840d69c507376f49b73b8255e0aec69a6f7ef1d3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/840d69c507376f49b73b8255e0aec69a6f7ef1d3.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6030,
          name: "Bastet and Cats",
          uuid: "464f6d6ef9db057280afae5b7ad7f9ec3e966409",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/464f6d6ef9db057280afae5b7ad7f9ec3e966409.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6079,
          name: "Ben Gunn Robinosn",
          uuid: "2d23a4d519a1a8b5e26458abbc9df5a2a5ea5757",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/2d23a4d519a1a8b5e26458abbc9df5a2a5ea5757.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6026,
          name: "Benny's the Biggest game",
          uuid: "f374f38c05f78decbc031b67cc933404a42fc0ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f374f38c05f78decbc031b67cc933404a42fc0ae.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6098,
          name: "Big Win x25",
          uuid: "51144f681e8c4edaaebcd9b2b0dd1f35",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/51144f681e8c4edaaebcd9b2b0dd1f35.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6017,
          name: "Black Jack",
          uuid: "c297c18b30649d175db289ad8fce84bbb126e67a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c297c18b30649d175db289ad8fce84bbb126e67a.png",
          type: "table",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6028,
          name: "Book of Amaterasu",
          uuid: "3617aa61eee27615cf0af7aae60406eede7d1b6f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3617aa61eee27615cf0af7aae60406eede7d1b6f.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6087,
          name: "Book of Anksunamun Rockways",
          uuid: "a50ec9b2fd3fd089cae8b9953856844b336cc655",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/a50ec9b2fd3fd089cae8b9953856844b336cc655.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6009,
          name: "CanCan Saloon",
          uuid: "26bc7014a54ca4565b75d217c6259a2468373b2a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/26bc7014a54ca4565b75d217c6259a2468373b2a.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6111,
          name: "Cancan Saloon Deluxe",
          uuid: "ed7e5a2705c940debf7750e0703545d5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/ed7e5a2705c940debf7750e0703545d5.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6053,
          name: "Candy Crush",
          uuid: "637970099a718a6c55d51c0c05cf3c6ca4113541",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/637970099a718a6c55d51c0c05cf3c6ca4113541.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6034,
          name: "Casino Hold'em",
          uuid: "2b7dbbca300cc60b16c849e34016f13af349ad4b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2b7dbbca300cc60b16c849e34016f13af349ad4b.png",
          type: "table",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6088,
          name: "Christmas Infinite Gifts",
          uuid: "fddbbab5d2fd17f3fc8d31425d950acb8860d314",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/fddbbab5d2fd17f3fc8d31425d950acb8860d314.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6067,
          name: "Cleopatra's Gems Bingo",
          uuid: "3bfd6b7811306a955a1174fabd303a8e8ca0fc48",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/3bfd6b7811306a955a1174fabd303a8e8ca0fc48.png",
          type: "bingo",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6050,
          name: "Cleopatra's gems Rockways",
          uuid: "e48ebff433dec1be50351f30deb6409cf480034d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e48ebff433dec1be50351f30deb6409cf480034d.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6093,
          name: "Crash, Hamster, Crash!",
          uuid: "dbface85d689490ea0b4f1eb7f8fdbdd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/dbface85d689490ea0b4f1eb7f8fdbdd.png",
          type: "crash",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6061,
          name: "Deepsea Riches",
          uuid: "35a62d818d395de4d90d1669ead4c184745991d4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/35a62d818d395de4d90d1669ead4c184745991d4.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6075,
          name: "Dice Disco: MEGA STACKS",
          uuid: "b3dfd47ac95f4078ad0a59121cf0ac7e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/b3dfd47ac95f4078ad0a59121cf0ac7e.png",
          type: "other",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6073,
          name: "Dice of Luxor",
          uuid: "2433b75bafb94bfba55665816442d626",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/2433b75bafb94bfba55665816442d626.png",
          type: "other",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6072,
          name: "Dice Vegas",
          uuid: "ff0b702d7e544bd8af4a3b77c793d07e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/ff0b702d7e544bd8af4a3b77c793d07e.png",
          type: "other",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6057,
          name: "Double-Triple Fruits",
          uuid: "f8dcfaeee6deacda39e68280e8c24faef18fc82f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f8dcfaeee6deacda39e68280e8c24faef18fc82f.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6092,
          name: "Dragon's Lucky 25",
          uuid: "cbfbbc981f7a4e8980a5231bef6419c1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/cbfbbc981f7a4e8980a5231bef6419c1.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6043,
          name: "Dragon’s Nest",
          uuid: "f62e909b4811fe8c1fcafb3195ea82ed70555c8c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f62e909b4811fe8c1fcafb3195ea82ed70555c8c.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6094,
          name: "Dreamshock Jackpot X",
          uuid: "b4e2017b13f742039a54dce908a3bdee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/b4e2017b13f742039a54dce908a3bdee.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6070,
          name: "Easter Luck",
          uuid: "64f5595a1964484587c6f6f5f16d44bf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/64f5595a1964484587c6f6f5f16d44bf.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6045,
          name: "Evil Bet",
          uuid: "4edaa6a99a106de75090ee3a30df2b469ee7414c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4edaa6a99a106de75090ee3a30df2b469ee7414c.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6099,
          name: "Fabulous Farm slot",
          uuid: "ba4608fa16270bbcaf8c7d7071b198c9943c6e93",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/ba4608fa16270bbcaf8c7d7071b198c9943c6e93.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6048,
          name: "Fairytale Coven",
          uuid: "581f610387ec9e9e77e45231677c37e64f041e80",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/581f610387ec9e9e77e45231677c37e64f041e80.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6051,
          name: "For the Realm",
          uuid: "2c05eff0fb7bca9b8f73dfdccdcfd58685a9346e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2c05eff0fb7bca9b8f73dfdccdcfd58685a9346e.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6058,
          name: "Fruit Disco: Mega Stacks",
          uuid: "5a5ac82b92f933d38706ea3afd97430e7b14bf79",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5a5ac82b92f933d38706ea3afd97430e7b14bf79.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6036,
          name: "Fruit Macau",
          uuid: "cdcf57de1726624f84c1afa4b5a53d49971b9d76",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cdcf57de1726624f84c1afa4b5a53d49971b9d76.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6077,
          name: "Fruit Machine Megabonus",
          uuid: "c03c0843a167b4168456c378b6ddf710f12305dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/c03c0843a167b4168456c378b6ddf710f12305dd.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6069,
          name: "Fruit Machine x25",
          uuid: "b984e48cc3e8ef2101cd2f67c4ed6836000febac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/b984e48cc3e8ef2101cd2f67c4ed6836000febac.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6035,
          name: "Fruit Monaco",
          uuid: "07d8ef8f8aedee2fd11b4480b4439e6d5f9146b0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/07d8ef8f8aedee2fd11b4480b4439e6d5f9146b0.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6020,
          name: "Fruit Vegas",
          uuid: "0542919fcca10e44b883dad341714f3831873521",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0542919fcca10e44b883dad341714f3831873521.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6110,
          name: "Fruit Vegas Extreme x125",
          uuid: "19f8ed3fc321419d9b82465bb7239b16",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/19f8ed3fc321419d9b82465bb7239b16.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6041,
          name: "Fruits of Luxor",
          uuid: "ab6d200f9071fb790e9cfec1b596c43206d5141a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ab6d200f9071fb790e9cfec1b596c43206d5141a.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6025,
          name: "Gemz Grow",
          uuid: "34fbd2712d772af145cffef4de223f101b7a0647",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/34fbd2712d772af145cffef4de223f101b7a0647.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6066,
          name: "Greedy Greenskins Rockways",
          uuid: "aa83feea07f3067f72eb8c268418dcdb6150d339",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/aa83feea07f3067f72eb8c268418dcdb6150d339.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6021,
          name: "Gryphon's Castle",
          uuid: "e9233e63ba7ba4722592291c85ca6adacbcae1de",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e9233e63ba7ba4722592291c85ca6adacbcae1de.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6089,
          name: "Gryphon's Castle Rush25",
          uuid: "4824eec3553f3820dbe04f1f410a4346d3e5fba6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/4824eec3553f3820dbe04f1f410a4346d3e5fba6.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6016,
          name: "Hell'Sing",
          uuid: "3628ba47027d9d70d122838fa59f222282a6bc1d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3628ba47027d9d70d122838fa59f222282a6bc1d.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6085,
          name: "Hello Win",
          uuid: "40f35fc0b28e72ad759c6df02f6327d8ee28712c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/40f35fc0b28e72ad759c6df02f6327d8ee28712c.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6078,
          name: "Hook Up! Fishing Wars",
          uuid: "460f7ee57f4eb016fdea6a77737d26925e6aa9f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/460f7ee57f4eb016fdea6a77737d26925e6aa9f0.png",
          type: "shooting",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6100,
          name: "Hot Fruit x15",
          uuid: "c0b47470dfe24b919d82632f4c50ba5c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/c0b47470dfe24b919d82632f4c50ba5c.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6062,
          name: "Hydra's Lair",
          uuid: "cdd2d5e973c2de862d709a9e6acf0d7a0fab996a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/cdd2d5e973c2de862d709a9e6acf0d7a0fab996a.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6101,
          name: "Ice Number One",
          uuid: "a71ed080fb974b4e98fdfe02bf382264",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/a71ed080fb974b4e98fdfe02bf382264.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6108,
          name: "Kite Festival Crash game",
          uuid: "85ff9466e83e460ba6cc3fe96cc1ad50",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/85ff9466e83e460ba6cc3fe96cc1ad50.png",
          type: "crash",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6064,
          name: "La Fiesta de Muertos",
          uuid: "76b3e870f82a4e03926f0454348368f2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/76b3e870f82a4e03926f0454348368f2.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6019,
          name: "Legioner",
          uuid: "20cccb74641b8f578971d346188c9fe0ed27a073",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/20cccb74641b8f578971d346188c9fe0ed27a073.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6010,
          name: "Lion's Pride",
          uuid: "9fd1da0e591015ca0ce54a250a2a17cb4a38fd7f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9fd1da0e591015ca0ce54a250a2a17cb4a38fd7f.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6060,
          name: "Loot the Train",
          uuid: "00bb6238c486857044398a72ff02c225d80f008e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/00bb6238c486857044398a72ff02c225d80f008e.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6105,
          name: "Mayan Riches",
          uuid: "ea602f5091021bbec57ffb250a783ce2eae1bbc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/ea602f5091021bbec57ffb250a783ce2eae1bbc8.png",
          type: "bingo",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6055,
          name: "Mayan Riches Rockways",
          uuid: "31e124f2d3802e6fb9e34a81bcde9c5d7aaec226",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/31e124f2d3802e6fb9e34a81bcde9c5d7aaec226.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6023,
          name: "Merlin's Tower",
          uuid: "2deeb71057b78739d3f673b5a422f0350623180c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2deeb71057b78739d3f673b5a422f0350623180c.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6013,
          name: "Mermaid's Bay",
          uuid: "27c7fbdcadbfaf12ad1d8f28f44d3e2d8285ae7a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/27c7fbdcadbfaf12ad1d8f28f44d3e2d8285ae7a.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6027,
          name: "Merry Scary Christmas!",
          uuid: "062798a31dcf1ea19f26d356b8daa79041dadf62",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/062798a31dcf1ea19f26d356b8daa79041dadf62.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6082,
          name: "Minotaurs Wilds",
          uuid: "f5b926497258a96ab35c98bf5af89d7c9178a1ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/f5b926497258a96ab35c98bf5af89d7c9178a1ad.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6097,
          name: "Mist",
          uuid: "39dfc36cbdb841e2b76dce8bfcbc9962",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/39dfc36cbdb841e2b76dce8bfcbc9962.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6008,
          name: "Northern Heat",
          uuid: "037baa5133d7feeb9c475c3770b98a3ea7415025",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/037baa5133d7feeb9c475c3770b98a3ea7415025.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6084,
          name: "Paper Lanterns",
          uuid: "ce4c36bbdf943aa4aa0432d19450cbe9a65afe37",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/ce4c36bbdf943aa4aa0432d19450cbe9a65afe37.png",
          type: "crash",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6044,
          name: "Pinup Dolls",
          uuid: "acc57c071aacac3573f26046c6292a738c88b66e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/acc57c071aacac3573f26046c6292a738c88b66e.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6047,
          name: "Primal Bet",
          uuid: "326c9eafa7e5b9d76eb42d16b9469c8d490a0088",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/326c9eafa7e5b9d76eb42d16b9469c8d490a0088.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6031,
          name: "Prince of Persia: the Gems of Persepolis",
          uuid: "085152a2b92cd7a5d6707ba8eb2d9a708d31cc86",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/085152a2b92cd7a5d6707ba8eb2d9a708d31cc86.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6059,
          name: "Princess and Dwarfs",
          uuid: "845894b070b9f613789a7fcd2f71144866cdbea6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/845894b070b9f613789a7fcd2f71144866cdbea6.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6024,
          name: "Purple Pills",
          uuid: "d71a9618372ae5716543e65f8e34443b27a6ca3e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d71a9618372ae5716543e65f8e34443b27a6ca3e.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6033,
          name: "Queen of Spades",
          uuid: "c9140a1752720846d9653db3620c28dd891f3348",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c9140a1752720846d9653db3620c28dd891f3348.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6007,
          name: "Re Kill",
          uuid: "3c1fe8d08fc6f1952d11bfd5615fcea1606abb5e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3c1fe8d08fc6f1952d11bfd5615fcea1606abb5e.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6091,
          name: "Re-Kill Ultimate",
          uuid: "44496dc16591417c8fa32870d9169209",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/44496dc16591417c8fa32870d9169209.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6038,
          name: "Red Horde",
          uuid: "5abe2b89a50e1aa37105e95838476852299f2aaa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5abe2b89a50e1aa37105e95838476852299f2aaa.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6104,
          name: "Reel Eldorado",
          uuid: "566e1c62feb345f49c953aa1e66b93db",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/566e1c62feb345f49c953aa1e66b93db.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6086,
          name: "Reveal The Kraken",
          uuid: "d9f50bdda49bfbf09984b1ea7e96f5737c4e8e0d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/d9f50bdda49bfbf09984b1ea7e96f5737c4e8e0d.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6037,
          name: "Riot",
          uuid: "62fac97ef3dff5bdb78faadc60c0312e7ca256f9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/62fac97ef3dff5bdb78faadc60c0312e7ca256f9.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6056,
          name: "Riot 2: burn and blow",
          uuid: "c268351a1b78f3f35ce46ff37bce2f463b2da44e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c268351a1b78f3f35ce46ff37bce2f463b2da44e.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6107,
          name: "Riot Ultimate",
          uuid: "9365294eac724c40929f00121c29c7c8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/9365294eac724c40929f00121c29c7c8.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6039,
          name: "Robin of Loxley",
          uuid: "ea74a85da6ab296af409a4a9e9c6472f347b1efe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ea74a85da6ab296af409a4a9e9c6472f347b1efe.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6071,
          name: "Rocket Chimp Jackpot",
          uuid: "318158243aec057e04af9dd1d452a972295d39a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/318158243aec057e04af9dd1d452a972295d39a5.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6103,
          name: "Sticky Fruit Madness",
          uuid: "19e18dc9f9b24083a8bd1635362f8854",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/19e18dc9f9b24083a8bd1635362f8854.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6065,
          name: "The Candy Keno",
          uuid: "52352d00161c1f78e109b2d07020c691fd1609b9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/52352d00161c1f78e109b2d07020c691fd1609b9.png",
          type: "keno",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6096,
          name: "The Candy Slot Deluxe",
          uuid: "7328d3dc070b463eb24365a767c37895",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/7328d3dc070b463eb24365a767c37895.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6109,
          name: "The Incredible X Fu Hero",
          uuid: "12b759620583411da348788ed7001f83",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/12b759620583411da348788ed7001f83.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6029,
          name: "The Myth",
          uuid: "c17cdd86e1e936c26ec71ee22a5084bb56203482",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c17cdd86e1e936c26ec71ee22a5084bb56203482.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6081,
          name: "The Pendragon Legend",
          uuid: "cc2ef3206a1a498a8cb8d0a0ee8fd6ba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/cc2ef3206a1a498a8cb8d0a0ee8fd6ba.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6011,
          name: "The Rite",
          uuid: "82157981ec7f7b3c9529d0fdadfe9933855b4d54",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/82157981ec7f7b3c9529d0fdadfe9933855b4d54.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6012,
          name: "The Tomb",
          uuid: "eeff44077f76f634a5173da07469497a70c03d29",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/eeff44077f76f634a5173da07469497a70c03d29.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6068,
          name: "Trump Card Queen",
          uuid: "20e0123495a89ac704e2a3626f92bb316a1b4b47",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/20e0123495a89ac704e2a3626f92bb316a1b4b47.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6074,
          name: "Twin Dice of Olympus",
          uuid: "5d0f18f7e16146e89a4fed7127a30ac2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/5d0f18f7e16146e89a4fed7127a30ac2.png",
          type: "other",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6042,
          name: "Twin Fruits of Olympus",
          uuid: "cfd7fa9285643a330db41153e01e8d414687e0c2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cfd7fa9285643a330db41153e01e8d414687e0c2.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6049,
          name: "Twin Fruits of Santa",
          uuid: "9b6f93e7460c1c800945744696a6b5e13d3194b2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9b6f93e7460c1c800945744696a6b5e13d3194b2.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6014,
          name: "Venetian Magic",
          uuid: "425cf50655108392c9c493704dee53ad0b0f6b1c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/425cf50655108392c9c493704dee53ad0b0f6b1c.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6080,
          name: "Wild Phoenix Rises",
          uuid: "1f6b8d9c889d88fd91b7b4acf73fbe021c0810fb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/1f6b8d9c889d88fd91b7b4acf73fbe021c0810fb.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6022,
          name: "Wild Spirit",
          uuid: "3bfa78c079ebae7be59baf6b75bd1f20eae2e577",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3bfa78c079ebae7be59baf6b75bd1f20eae2e577.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6090,
          name: "Wild Wild Bet",
          uuid: "4301af9a3dcb62e6c3c3cb2e5c84e8413e3c25f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/4301af9a3dcb62e6c3c3cb2e5c84e8413e3c25f7.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6063,
          name: "Wildlife Riches",
          uuid: "030463c2a29d4b7186bb6910cb4999b1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/030463c2a29d4b7186bb6910cb4999b1.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6106,
          name: "Wood luck",
          uuid: "e3aca7c5485641f68d595583aa536e61",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Mascot/e3aca7c5485641f68d595583aa536e61.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6095,
          name: "Yukon Fever",
          uuid: "020184ed7e06faba72c21ae64e28182c6b3d7bbe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/020184ed7e06faba72c21ae64e28182c6b3d7bbe.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6040,
          name: "Zeus the Thunderer",
          uuid: "e7659f1586636fc74d02aaeae8b84b18db9eb5aa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e7659f1586636fc74d02aaeae8b84b18db9eb5aa.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6083,
          name: "Zeus the Thunderer Deluxe",
          uuid: "2e76b7ea49e300617b9edbc94db5b57c78ff0821",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Mascot/2e76b7ea49e300617b9edbc94db5b57c78ff0821.png",
          type: "slots",
          provider: "Mascot",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 131,
      name: "NYX",
      providerId: 5,
      providerName: "NYX",
      games: [
        {
          game_id: 229,
          name: "50 Dragons",
          uuid: "a23cc95d3e81e97844d6a1283b9237eb048d0901",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a23cc95d3e81e97844d6a1283b9237eb048d0901.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 230,
          name: "Big Ben",
          uuid: "03233e92e893bd84b89920fdc815c513c79d974a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/03233e92e893bd84b89920fdc815c513c79d974a.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 239,
          name: "Bingo Billions",
          uuid: "51b1d2240bfac317a949eb51d95a004b9b30aceb",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/51b1d2240bfac317a949eb51d95a004b9b30aceb.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 240,
          name: "Bingo Billions BDG",
          uuid: "bdece8262fdafddd9803449eb076d8a9a8fdd5bb",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bdece8262fdafddd9803449eb076d8a9a8fdd5bb.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 241,
          name: "Bloodlore Vampire clan",
          uuid: "d6cb558daab656f0ee082e3ed71f16690de3fee2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d6cb558daab656f0ee082e3ed71f16690de3fee2.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 242,
          name: "Cherry Blossoms",
          uuid: "0d666e40bf35d9ec5bdc7db44b1756ea7de1063c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0d666e40bf35d9ec5bdc7db44b1756ea7de1063c.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 231,
          name: "Choy Sun Doa",
          uuid: "64b16c927cdda954d77146da038db5145194bf25",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/64b16c927cdda954d77146da038db5145194bf25.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 243,
          name: "Cool Bananas",
          uuid: "af345ca5dcca64f6e32ee6389d2c6e9e3e14cd2c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/af345ca5dcca64f6e32ee6389d2c6e9e3e14cd2c.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 244,
          name: "Doctor Love",
          uuid: "b9852ec26d29e01127614750db65c5bea0659ada",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b9852ec26d29e01127614750db65c5bea0659ada.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 245,
          name: "Doctor Love On Vacation",
          uuid: "08aa1378fab495477ec73f8cee862d1c9f371027",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/08aa1378fab495477ec73f8cee862d1c9f371027.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 232,
          name: "Double Happiness",
          uuid: "716ef43c014d30be12e4edaaf87c8addc91ed78a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/716ef43c014d30be12e4edaaf87c8addc91ed78a.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 233,
          name: "Dragon Emperor",
          uuid: "57c6336841e14626ff072de93ed02c201795bbef",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/57c6336841e14626ff072de93ed02c201795bbef.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 255,
          name: "Eastern Dragon",
          uuid: "5e493158fffbb3ee7beb9365cd4b0cbf250917e6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5e493158fffbb3ee7beb9365cd4b0cbf250917e6.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 256,
          name: "Emperors Garden",
          uuid: "fda41641a0ee6cf9294003d0c319c63d2c66fe97",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/fda41641a0ee6cf9294003d0c319c63d2c66fe97.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 257,
          name: "Emperors Garden Dice",
          uuid: "9611f3c6744f8ae842f7825e4fee88c80313f6c6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9611f3c6744f8ae842f7825e4fee88c80313f6c6.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 258,
          name: "Funky Chicken",
          uuid: "d7ef401a68fa90918e82270202bab5335f37446b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d7ef401a68fa90918e82270202bab5335f37446b.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 246,
          name: "Golden Mane",
          uuid: "7760219279f110c991da1d19e99ac100fedaa97b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7760219279f110c991da1d19e99ac100fedaa97b.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 247,
          name: "Irish Eyes 2",
          uuid: "0ac20ee3cf3bceb10b5722afae4308f67b96ee7f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0ac20ee3cf3bceb10b5722afae4308f67b96ee7f.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 248,
          name: "Jade Idol",
          uuid: "6101616f3967418f97c17f896e1ffb9ac5165c25",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6101616f3967418f97c17f896e1ffb9ac5165c25.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 234,
          name: "Lucky88",
          uuid: "10a0621fc5c1d449cd211d421864ef74764bfdcf",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/10a0621fc5c1d449cd211d421864ef74764bfdcf.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 249,
          name: "Mad Mad Monkey",
          uuid: "5ae4085e58231d4bdbfc95ddb38b89696f670335",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5ae4085e58231d4bdbfc95ddb38b89696f670335.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 235,
          name: "Miss Kitty",
          uuid: "1cc312dcc5a9450e8085c2673e8e0a894377f73c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1cc312dcc5a9450e8085c2673e8e0a894377f73c.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 250,
          name: "Platinum Pyramid",
          uuid: "67df5d912f5d1223daccf591bf5a35643307b159",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/67df5d912f5d1223daccf591bf5a35643307b159.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 251,
          name: "Potion Commotion",
          uuid: "9a8a33a4558d521e9ff25078733ed0297a1b3d5e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9a8a33a4558d521e9ff25078733ed0297a1b3d5e.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 236,
          name: "Queen of the Nile II",
          uuid: "e067abc4b1c266e510f5ca606cd1d6abe3c6fa5a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e067abc4b1c266e510f5ca606cd1d6abe3c6fa5a.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 252,
          name: "Ramesses Riches",
          uuid: "bfd8149583e59a681a5b6a1f4a7b4c40e9262e4f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bfd8149583e59a681a5b6a1f4a7b4c40e9262e4f.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 237,
          name: "Red Baron",
          uuid: "acc439151720e7b63e8b4d4c7900cda923e9236b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/acc439151720e7b63e8b4d4c7900cda923e9236b.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 253,
          name: "Savannah Sunrise",
          uuid: "5638d7ccd1f8fd785a0a1aee4c6e4c03faddf24c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5638d7ccd1f8fd785a0a1aee4c6e4c03faddf24c.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 259,
          name: "Starmania",
          uuid: "1f89c40d72343a1fb87d95b07aec9712abb52241",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1f89c40d72343a1fb87d95b07aec9712abb52241.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 260,
          name: "The Snake Charmer",
          uuid: "eeea8ea881cfe21f7aa2e57ba0207108d4edd9cc",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/eeea8ea881cfe21f7aa2e57ba0207108d4edd9cc.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 254,
          name: "Venetian Rose",
          uuid: "599bd28b1993cba6ef8e7f8a2ba68d6c6fb7309b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/599bd28b1993cba6ef8e7f8a2ba68d6c6fb7309b.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 238,
          name: "Werewolf Wild",
          uuid: "8f84e3c214cfc3b641e9053a6304faca5946e75d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8f84e3c214cfc3b641e9053a6304faca5946e75d.jpg",
          type: "slots",
          provider: "NYX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 132,
      name: "NetEnt",
      providerId: 4,
      providerName: "NetEnt",
      games: [
        {
          game_id: 366,
          name: "Aloha",
          uuid: "fb38a897c531e75e3e7b88e672f9e0e62f9347e6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/fb38a897c531e75e3e7b88e672f9e0e62f9347e6.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 367,
          name: "Arcane Reel Chaos Slot",
          uuid: "4afd3686691f7c7b02a3dfe81c353234d968e999",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4afd3686691f7c7b02a3dfe81c353234d968e999.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 368,
          name: "Archangels: Salvation Slot",
          uuid: "34fa5181cf99cc24129f9c8574f1a35b8cc2bd57",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/34fa5181cf99cc24129f9c8574f1a35b8cc2bd57.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 369,
          name: "Asgardian Stones",
          uuid: "bd49e9fc20cf34736e0748a04d2730ca9fee2355",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bd49e9fc20cf34736e0748a04d2730ca9fee2355.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 657,
          name: "Bee Hive Bonanza",
          uuid: "3b5d832bb1dd49b293a9ca0544e2c178",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/3b5d832bb1dd49b293a9ca0544e2c178.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 292,
          name: "BerryBurst",
          uuid: "d60807caee793b8da17f2967ff754042aa9594fd",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d60807caee793b8da17f2967ff754042aa9594fd.jpg",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 293,
          name: "BerryBurstMax",
          uuid: "c5d07f580807e8721f1d0d4ed3c87b2a9273bddc",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c5d07f580807e8721f1d0d4ed3c87b2a9273bddc.jpg",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 841,
          name: "BloodSuckers",
          uuid: "e8ba0a21141334fe973286412191729b33edc54e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e8ba0a21141334fe973286412191729b33edc54e.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 227,
          name: "BloodSuckers2",
          uuid: "87f4275df1c107bfbc9ac080fe36083c0b459fdf",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/87f4275df1c107bfbc9ac080fe36083c0b459fdf.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 350,
          name: "Bollywood Story",
          uuid: "535ecd5946294b80d92f7219cd32e84ed0019fe3",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/535ecd5946294b80d92f7219cd32e84ed0019fe3.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 660,
          name: "Buster's Bones",
          uuid: "222a83ce5db647a1bdf971a48ee08a70",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/222a83ce5db647a1bdf971a48ee08a70.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 339,
          name: "Butterfly Staxx 2",
          uuid: "0a2350a96b0ff0109ccf8f27c3419bb2a2e0e981",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0a2350a96b0ff0109ccf8f27c3419bb2a2e0e981.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 842,
          name: "ButterflyStaxx",
          uuid: "eb3b1a1e45ebb19aa2ec6b9198e29b820be4d2fb",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/eb3b1a1e45ebb19aa2ec6b9198e29b820be4d2fb.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 370,
          name: "Cash Noire",
          uuid: "8d47cb95e98003b18fdaebd57bb9687284c44105",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8d47cb95e98003b18fdaebd57bb9687284c44105.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 541,
          name: "Cash-o-Matic",
          uuid: "bb23ea2ed96e65b002e35b0af202e621c5632c3d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bb23ea2ed96e65b002e35b0af202e621c5632c3d.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 371,
          name: "Coins of Egypt",
          uuid: "03d67136f74b0b1a9f30273d36959560c50d0622",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/03d67136f74b0b1a9f30273d36959560c50d0622.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 843,
          name: "CopyCats",
          uuid: "0d045e42e63db018db8b7c789ca03b8015267c68",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0d045e42e63db018db8b7c789ca03b8015267c68.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 658,
          name: "Cornelius",
          uuid: "3102b4556c364861becce635d95ce441",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/3102b4556c364861becce635d95ce441.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 110,
          name: "Cupcakes",
          uuid: "36c5024fef6b45738122ea5bb6001a77",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/36c5024fef6b45738122ea5bb6001a77.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 372,
          name: "Dark King: Forbidden Riches",
          uuid: "dd14c96eb7e41a23dbc80fb16f0af96f4e3b2617",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/dd14c96eb7e41a23dbc80fb16f0af96f4e3b2617.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 141,
          name: "Dazzle Me Megaways",
          uuid: "699a8f38f22fb6740135d7c30d45d8a4a8b07f70",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/699a8f38f22fb6740135d7c30d45d8a4a8b07f70.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 844,
          name: "DeadOrAlive",
          uuid: "9f662a211275f39a9c37e850cd739dbb0a2660f8",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9f662a211275f39a9c37e850cd739dbb0a2660f8.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 690,
          name: "DeadOrAlive2",
          uuid: "b832ecb5b34d4c69a5c0ee86ee6694e9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/b832ecb5b34d4c69a5c0ee86ee6694e9.jpg",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 294,
          name: "DeadOrAlive2",
          uuid: "28783c50636dd30a3d4ce82c3bcff2d153692370",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/28783c50636dd30a3d4ce82c3bcff2d153692370.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 373,
          name: "Divine Fortune MegaWays™",
          uuid: "392bc6ef13ecf3a7da03bc559426ab665b174dec",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/392bc6ef13ecf3a7da03bc559426ab665b174dec.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 351,
          name: "Double Stacks",
          uuid: "3f98fce7841d80b26f43707b7c36889bf102fd1e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3f98fce7841d80b26f43707b7c36889bf102fd1e.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 845,
          name: "Dracula",
          uuid: "3213c61614454b39d1b5ff847bff68e30078b2a2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3213c61614454b39d1b5ff847bff68e30078b2a2.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 542,
          name: "Elements: The Awakening",
          uuid: "2d2b2a09ddbde7d83f92a2a9892b85d09c8b40f7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2d2b2a09ddbde7d83f92a2a9892b85d09c8b40f7.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 348,
          name: "Excalibur",
          uuid: "cc0457fd171acd7ad1c6faa49cc4f22712ba0257",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cc0457fd171acd7ad1c6faa49cc4f22712ba0257.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 295,
          name: "Flowers",
          uuid: "3f3c89ac6f5ff1972e951d17fe6576272cfbf412",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3f3c89ac6f5ff1972e951d17fe6576272cfbf412.jpg",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 866,
          name: "FlowersChristmas",
          uuid: "57cd6f8da3c044e550029408b0303ec4b0c1e109",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/57cd6f8da3c044e550029408b0303ec4b0c1e109.jpg",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 848,
          name: "Football",
          uuid: "fe9764853d4b22493988205be0ab00267ef7a95c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/fe9764853d4b22493988205be0ab00267ef7a95c.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 374,
          name: "Fortune Rangers Slot",
          uuid: "b2b1ef251c4cd2d2a808a41c9ef87222a41552e9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b2b1ef251c4cd2d2a808a41c9ef87222a41552e9.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 375,
          name: "Fruit Shop MegaWays™",
          uuid: "371f56c12bacbf0cbf4d2811804bb957f6aec829",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/371f56c12bacbf0cbf4d2811804bb957f6aec829.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 849,
          name: "Fruitshop",
          uuid: "91df143ad01728eda6bfd793ad210b4d62b5fd44",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/91df143ad01728eda6bfd793ad210b4d62b5fd44.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 543,
          name: "Funk Master",
          uuid: "cf7dcc20c1c2fe688a0655390f5330baed6e4f46",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cf7dcc20c1c2fe688a0655390f5330baed6e4f46.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 384,
          name: "Gods Of Gold",
          uuid: "7cba4c3a061b323371c027d2d7e5fa93124fa586",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7cba4c3a061b323371c027d2d7e5fa93124fa586.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 296,
          name: "GoldenGrimoire",
          uuid: "d27c8b6f7a1d8c5acefa17af77f5cce7628e0825",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d27c8b6f7a1d8c5acefa17af77f5cce7628e0825.jpg",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 544,
          name: "Gonzo's Gold",
          uuid: "55ec8049aebe3a87c6c6c23f2185012559a43351",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/55ec8049aebe3a87c6c6c23f2185012559a43351.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 846,
          name: "Gonzo's Quest",
          uuid: "0499284ddffa3a1793aab8e45cf40a01a358ecbe",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0499284ddffa3a1793aab8e45cf40a01a358ecbe.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 376,
          name: "Gorilla Kingdom Slot",
          uuid: "154467102ed7c52659b23e1c6fa7db62d1dc68f0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/154467102ed7c52659b23e1c6fa7db62d1dc68f0.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 691,
          name: "Hotline 2",
          uuid: "e3ac761d3fdb421eb9dc932dcaddba4f",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/e3ac761d3fdb421eb9dc932dcaddba4f.jpg",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 851,
          name: "InvisibleMan",
          uuid: "759fa434b908738b1245e88e041943137a39a918",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/759fa434b908738b1245e88e041943137a39a918.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 545,
          name: "Jack and the Beanstalk",
          uuid: "29570d3a9a56102c22a1071261b1fed3f2a929ad",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/29570d3a9a56102c22a1071261b1fed3f2a929ad.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 297,
          name: "JackHammer2",
          uuid: "c9eda62a56e83a59f3c8757fd39db45707367fac",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c9eda62a56e83a59f3c8757fd39db45707367fac.jpg",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 852,
          name: "JokerPro",
          uuid: "8567430c4612116202cebc9389fa128cb869b1a5",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/8567430c4612116202cebc9389fa128cb869b1a5.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 340,
          name: "King of Slots",
          uuid: "d8007bb29490cb22174faf6fc1449feb0d60ed6a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d8007bb29490cb22174faf6fc1449feb0d60ed6a.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 546,
          name: "Knight Rider",
          uuid: "3b8635ab90abe7b6fe2889e9689b3d1fe9acf840",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3b8635ab90abe7b6fe2889e9689b3d1fe9acf840.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 547,
          name: "Koi Princess",
          uuid: "19c8bd3579ebae450b0a65e35cccdb9ce8de1873",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/19c8bd3579ebae450b0a65e35cccdb9ce8de1873.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 123,
          name: "Let It Burn",
          uuid: "901ac0d989bb4eca847daae2fb705fdd",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/901ac0d989bb4eca847daae2fb705fdd.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 847,
          name: "Lights (Fireflies)",
          uuid: "59129b91932a21e503d90f295cd1c706bb95963a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/59129b91932a21e503d90f295cd1c706bb95963a.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 385,
          name: "Long Pao",
          uuid: "ff46ca203f1e5811e2671ea7467c731a11b581a2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ff46ca203f1e5811e2671ea7467c731a11b581a2.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 853,
          name: "MagicPortals",
          uuid: "ebcf19889400fe52b527f5976c65306833dabca6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ebcf19889400fe52b527f5976c65306833dabca6.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 377,
          name: "Motörhead Slot",
          uuid: "46bbca26aadd93fd8214e6220a192c4d0f6ca335",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/46bbca26aadd93fd8214e6220a192c4d0f6ca335.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 854,
          name: "MultiplierMayhem",
          uuid: "894ab987985e59a7d43c2369078853525a9752f5",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/894ab987985e59a7d43c2369078853525a9752f5.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 855,
          name: "MythicMaiden",
          uuid: "66de752d3adef77f197e21ab0d0ca66dcbea168c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/66de752d3adef77f197e21ab0d0ca66dcbea168c.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 856,
          name: "NeonStaxx",
          uuid: "e9340825b1f43d974916384ba3cba08bacb25099",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e9340825b1f43d974916384ba3cba08bacb25099.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 378,
          name: "Parthenon: Quest for Immortality™",
          uuid: "43cc1437dadf8f13049bc00eb39e00263c92628c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/43cc1437dadf8f13049bc00eb39e00263c92628c.jpeg",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 857,
          name: "PiggyRiches",
          uuid: "76d855fdf3efa6f07ffbb7d703fb3ab7bafcdeb9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/76d855fdf3efa6f07ffbb7d703fb3ab7bafcdeb9.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 569,
          name: "Pyramid",
          uuid: "4402bf8b37ae9b664655165a3e91c1e860c52e52",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4402bf8b37ae9b664655165a3e91c1e860c52e52.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 386,
          name: "Reef Raider",
          uuid: "bdebf02fdde74b0c57cea21df0375f8b5dd4ab08",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bdebf02fdde74b0c57cea21df0375f8b5dd4ab08.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 858,
          name: "ReelRush",
          uuid: "201df9871e2a47e8200b1bf6d8938519894b8d1c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/201df9871e2a47e8200b1bf6d8938519894b8d1c.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 105,
          name: "Santa Vs Rudolf",
          uuid: "d9b76206e1846e42dc6a4e181877625e684fa875",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d9b76206e1846e42dc6a4e181877625e684fa875.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 859,
          name: "ScruffyDuck",
          uuid: "f2e0ee8d70b1f41f7f92bb4f689b4462e3e9afb3",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f2e0ee8d70b1f41f7f92bb4f689b4462e3e9afb3.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 850,
          name: "Secrets of Atlantis",
          uuid: "5f0116f0a9b9b26140993a01b64f8d40c7a72d78",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5f0116f0a9b9b26140993a01b64f8d40c7a72d78.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 860,
          name: "SecretsOfChristmas",
          uuid: "0f2f53b745a429dbf585bf5c7e245cb112e2b319",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0f2f53b745a429dbf585bf5c7e245cb112e2b319.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 341,
          name: "Space Wars",
          uuid: "7ae2162b408679c2c28196d8e451989b3e3dd662",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7ae2162b408679c2c28196d8e451989b3e3dd662.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 548,
          name: "Space Wars 2",
          uuid: "cc2c91e6f69df07858542ce21accf68807060472",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cc2c91e6f69df07858542ce21accf68807060472.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 861,
          name: "Sparks",
          uuid: "64d860091b16d9a754ce86625378166a6e5f6077",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/64d860091b16d9a754ce86625378166a6e5f6077.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 342,
          name: "Spellcast ",
          uuid: "5df2041fdc99932cb8ac4c8a21433848aedf2b4c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5df2041fdc99932cb8ac4c8a21433848aedf2b4c.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 379,
          name: "Spinata Grande",
          uuid: "c27f36095ea673062f5cef5de407192eb7bd364d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c27f36095ea673062f5cef5de407192eb7bd364d.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 343,
          name: "Spinsane ",
          uuid: "94774fffad5a68bd5b5b4c16f20433127a3342ee",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/94774fffad5a68bd5b5b4c16f20433127a3342ee.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 862,
          name: "StarBurst",
          uuid: "e1c86afe93ad7ef54096708419deee8dd8fc0ac2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e1c86afe93ad7ef54096708419deee8dd8fc0ac2.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 549,
          name: "Starburst XXXtreme",
          uuid: "03823129c8858cade89053b6bf3af8fb92b4c8e1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/03823129c8858cade89053b6bf3af8fb92b4c8e1.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 863,
          name: "SteamTower",
          uuid: "2024fbd8a3a110f2430d28511273168b298ff77f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2024fbd8a3a110f2430d28511273168b298ff77f.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 864,
          name: "Stickers",
          uuid: "24de9416b21466f4a6fb6d2f91e12ad969d6aab8",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/24de9416b21466f4a6fb6d2f91e12ad969d6aab8.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 656,
          name: "Superstars",
          uuid: "478ff6a987a741b9b28e1e61d9e48c37",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/478ff6a987a741b9b28e1e61d9e48c37.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 344,
          name: "Sweety Honey Fruity ",
          uuid: "c8544668d60074aea17e62419fa7903a1ef46b65",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c8544668d60074aea17e62419fa7903a1ef46b65.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 659,
          name: "Taco Fury XXXtreme",
          uuid: "24f18a3e00a647039981c16206d5df9e",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/24f18a3e00a647039981c16206d5df9e.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 380,
          name: "The Creature from the Black Lagoon",
          uuid: "7425955d6e7cd7749401b7ac9e9b1538dad5cd56",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7425955d6e7cd7749401b7ac9e9b1538dad5cd56.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 387,
          name: "The Legend of Shangri-La",
          uuid: "14d671846cd51ce49688eca2b118f1827073d49f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/14d671846cd51ce49688eca2b118f1827073d49f.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 347,
          name: "The Reel Steal",
          uuid: "bccdaec282bf8b02e6a589e70ffdd28d58d2400f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bccdaec282bf8b02e6a589e70ffdd28d58d2400f.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 353,
          name: "Twin Spin Deluxe",
          uuid: "b988c6f2bbe09c70922951354e8f23e0419c3381",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b988c6f2bbe09c70922951354e8f23e0419c3381.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 381,
          name: "Twin Spin MegaWays",
          uuid: "3d778a8a48e829e019846be83c8a5fda4a5e99ef",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3d778a8a48e829e019846be83c8a5fda4a5e99ef.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 345,
          name: "Twinhappiness ",
          uuid: "85bcc8d4729d29f242765da7b3322e4ea0a50153",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/85bcc8d4729d29f242765da7b3322e4ea0a50153.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 865,
          name: "TwinSpin",
          uuid: "960feedd2f94e730a76687381438c65219edd936",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/960feedd2f94e730a76687381438c65219edd936.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 224,
          name: "Victorious",
          uuid: "c5b8a4a1db25d28b961f5685945380970cbad9fb",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c5b8a4a1db25d28b961f5685945380970cbad9fb.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 388,
          name: "Vikings",
          uuid: "10a2fd11442f6eb4fc59944e278d24396c271f87",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/10a2fd11442f6eb4fc59944e278d24396c271f87.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 382,
          name: "Warlords Slot",
          uuid: "b9af04ea043512a4b8643f917c73d840044391d3",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b9af04ea043512a4b8643f917c73d840044391d3.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 389,
          name: "Who`s The Bride?",
          uuid: "25ea429288cd29e3fe421734614b0118e9b1ee55",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/25ea429288cd29e3fe421734614b0118e9b1ee55.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 383,
          name: "Wild Bazaar Slot",
          uuid: "c35215d68180dd0274c07293948a21490ba850a4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c35215d68180dd0274c07293948a21490ba850a4.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 349,
          name: "Wild Turkey",
          uuid: "88cdfaca5ba705b91dd8543e3aa8462ebb03ad03",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/88cdfaca5ba705b91dd8543e3aa8462ebb03ad03.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 352,
          name: "Wild-O-Tron 3000",
          uuid: "e25ab07aa149dc466b68ac78f25549f2e0ad0786",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e25ab07aa149dc466b68ac78f25549f2e0ad0786.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 225,
          name: "WildWater",
          uuid: "721fd6f63c49564341a9d63db39c4d4521d42a91",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/721fd6f63c49564341a9d63db39c4d4521d42a91.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 226,
          name: "WildWildWest",
          uuid: "43c0e7b0f77e233fa09ec5a99c176e87603d5ceb",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/43c0e7b0f77e233fa09ec5a99c176e87603d5ceb.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 390,
          name: "Wings of Riches",
          uuid: "e41d1c2c276e3b0b79fa923f99eaf91920c3f931",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e41d1c2c276e3b0b79fa923f99eaf91920c3f931.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 228,
          name: "Wolf Cub",
          uuid: "74daff1e5b1535c9078f02f0d123f10c8566ffb8",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/74daff1e5b1535c9078f02f0d123f10c8566ffb8.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 346,
          name: "Wolfs Bane ",
          uuid: "a263b7390c4d797ba813617eef9449ac6f442c51",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a263b7390c4d797ba813617eef9449ac6f442c51.png",
          type: "slots",
          provider: "NetEnt",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 133,
      name: "NetGame",
      providerId: 30,
      providerName: "NetGame",
      games: [
        {
          game_id: 6189,
          name: "8 Pool Stars",
          uuid: "b9ccf29c93aa4b1960391738c1578b85d7a50d18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/b9ccf29c93aa4b1960391738c1578b85d7a50d18.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6138,
          name: "African King: Hold 'n' Link",
          uuid: "18221471b95a3e88beaa8ec36955cfcd26d724ca",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/18221471b95a3e88beaa8ec36955cfcd26d724ca.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6147,
          name: "Aladdin's chest",
          uuid: "0f6965a2d11e079428eea7e7ca2db6c5cd0f0166",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/0f6965a2d11e079428eea7e7ca2db6c5cd0f0166.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6130,
          name: "B.U.C.K.S.",
          uuid: "41b255607611770eae989a0b30c44d3b6a315272",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/41b255607611770eae989a0b30c44d3b6a315272.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6164,
          name: "Bandits Bounty: Cash Pool",
          uuid: "b390cc97d0a64db28e39105316ff56bb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/b390cc97d0a64db28e39105316ff56bb.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6192,
          name: "Big Banker Bonanza",
          uuid: "61cc098cb7894e0687f2a3477c826674",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/61cc098cb7894e0687f2a3477c826674.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6166,
          name: "Big Catch Bonanza",
          uuid: "c7bbe4009a6549e0b3562d6be7e9f6aa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/c7bbe4009a6549e0b3562d6be7e9f6aa.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6177,
          name: "Big Catch Bonanza: Bonus Buy",
          uuid: "5ff28bb71df84c78b3be0c21af442449",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/5ff28bb71df84c78b3be0c21af442449.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6194,
          name: "Big Catch Bonanza: Perfect Houl",
          uuid: "70dafa47926ce7e30ddaf1592de645f0c4fa6655",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/70dafa47926ce7e30ddaf1592de645f0c4fa6655.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6129,
          name: "Book of Nile: Hold'n'Link",
          uuid: "6eed18c4508978f67122958a62d05e4136573d02",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6eed18c4508978f67122958a62d05e4136573d02.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6124,
          name: "Book of Nile: Lost Chapter",
          uuid: "2ff89ef34fdd2f82014fe58c01f3ee8ad4a2649d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2ff89ef34fdd2f82014fe58c01f3ee8ad4a2649d.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6126,
          name: "Book of Nile: Magic Choice",
          uuid: "e8926df6aecd37ce60f4f078545baf29dac52cb8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e8926df6aecd37ce60f4f078545baf29dac52cb8.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6123,
          name: "Book of Nile: Revenge",
          uuid: "6ad32a3e34523ed96fe5c1738221a978e7434043",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6ad32a3e34523ed96fe5c1738221a978e7434043.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6170,
          name: "Buffalo: Cash Pool",
          uuid: "2c0bbf3d41154f12b4cf84fcf70cb253",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/2c0bbf3d41154f12b4cf84fcf70cb253.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6184,
          name: "Bunny's Bounty: Hold 'N' Link",
          uuid: "76873a56ee244585bf9c6fe32978bc7b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/76873a56ee244585bf9c6fe32978bc7b.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6159,
          name: "Cactus Riches: Cash Pool",
          uuid: "9f0cefc0923244b197352631578e350c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/9f0cefc0923244b197352631578e350c.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6199,
          name: "Cai Shen's Palace: Hold 'N' Link",
          uuid: "42cb5c9f5e676e2e22f0523850cd13242cecb796",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/42cb5c9f5e676e2e22f0523850cd13242cecb796.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6208,
          name: "Candy Heroes",
          uuid: "a74894381956ada4c1e009b76bc801afe1749688",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/a74894381956ada4c1e009b76bc801afe1749688.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6200,
          name: "Captain Lobster",
          uuid: "bbe374ddb68280724ba257d29705a04140599cec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/bbe374ddb68280724ba257d29705a04140599cec.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6165,
          name: "Carnival Cat: Bonus Combo",
          uuid: "a8f2c2cd350f4e1c97ed304932506c38",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/a8f2c2cd350f4e1c97ed304932506c38.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6156,
          name: "Cash Kingdom",
          uuid: "c047de23f121eab382546df353a870f53678ae6b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/c047de23f121eab382546df353a870f53678ae6b.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6148,
          name: "Cash Vaults Hold n Link",
          uuid: "bec9db7ff6b249598e91a0ca09f0f7e1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/bec9db7ff6b249598e91a0ca09f0f7e1.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6142,
          name: "Charming Gold Hold n Link",
          uuid: "1bd2b2c0c61617785167729b02814746fce35966",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1bd2b2c0c61617785167729b02814746fce35966.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6183,
          name: "Clover Shot",
          uuid: "a5d66215c2cd4400a1108cd946f1c7d5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/a5d66215c2cd4400a1108cd946f1c7d5.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6127,
          name: "Dancing Lanterns",
          uuid: "414715a43c4c3c9e76a89bc188bf51afceb8a330",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/414715a43c4c3c9e76a89bc188bf51afceb8a330.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6143,
          name: "Dancing Lanterns 2",
          uuid: "69ecf6e86fe6db2918fa4fa95555ac8d076652a9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/69ecf6e86fe6db2918fa4fa95555ac8d076652a9.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6171,
          name: "Desert Riches: Hold 'N' Link",
          uuid: "36743fd7e14b460196e88883e95aa00c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/36743fd7e14b460196e88883e95aa00c.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6112,
          name: "Diamond Shot",
          uuid: "0e87edd4ce8c4f04017ced12a9df420519c6ecaa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0e87edd4ce8c4f04017ced12a9df420519c6ecaa.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6152,
          name: "Diamond Shot Blitz",
          uuid: "58ab80eefbc849fb8bb997e1587c634e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/58ab80eefbc849fb8bb997e1587c634e.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6134,
          name: "Dolphin Queen",
          uuid: "97b919554e9ab3349b4d78a22d53ad2237a98267",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/97b919554e9ab3349b4d78a22d53ad2237a98267.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6186,
          name: "Elephant's Gold: Bonus Combo",
          uuid: "8c446f1b778711327c8bcc2acefd8b75fdd77cdf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/8c446f1b778711327c8bcc2acefd8b75fdd77cdf.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6197,
          name: "Elephant's Gold: Buy Bonus Combo",
          uuid: "765f74940725003ba0e6c865b0934d2d9e0547cd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/765f74940725003ba0e6c865b0934d2d9e0547cd.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6145,
          name: "Explorer's Gold: Cash Blast",
          uuid: "f58a982d4e8baa6664e31bbd6e4383b69c3b2634",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f58a982d4e8baa6664e31bbd6e4383b69c3b2634.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6206,
          name: "Fishing Kingdom remastered",
          uuid: "2ecba962a0864cc9882ac15c07dc5514",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/2ecba962a0864cc9882ac15c07dc5514.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6121,
          name: "Fortune Cash",
          uuid: "9fa64057284c41d88b2f3701288fbd4a5915a416",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9fa64057284c41d88b2f3701288fbd4a5915a416.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6160,
          name: "Fortune Skulls",
          uuid: "46f9a5546dd7409598c5de8b7ff5dd80",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/46f9a5546dd7409598c5de8b7ff5dd80.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6195,
          name: "Fortune Skulls Bonus Buy",
          uuid: "21ad2a2aade1843281f49c6b74417963691b5c42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/21ad2a2aade1843281f49c6b74417963691b5c42.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6198,
          name: "Frontier Falcon: Hold 'N' Link",
          uuid: "535b0efb552a6b97a21261af3329f1dad5215a59",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/535b0efb552a6b97a21261af3329f1dad5215a59.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6113,
          name: "Frosty Fruits",
          uuid: "788344b160d9257814b04e1b2f83ebf1893239d9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/788344b160d9257814b04e1b2f83ebf1893239d9.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6120,
          name: "Fruit Burst",
          uuid: "12bcb219edf9f3e15cd3a5e41363ebe5b3a832c0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/12bcb219edf9f3e15cd3a5e41363ebe5b3a832c0.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6125,
          name: "Fruit Cash Hold 'n' Link",
          uuid: "4788c2b42784ef400250ce5af4760b324a7ca9c5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4788c2b42784ef400250ce5af4760b324a7ca9c5.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6153,
          name: "Fu Yin Yang Hold 'n' Link",
          uuid: "e7d3bc8afe97c6f1ebe0c48c6f6855d0127a2f9c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/e7d3bc8afe97c6f1ebe0c48c6f6855d0127a2f9c.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6190,
          name: "Galaxy Glitter",
          uuid: "a1b4d9ec27badc7cbef9a6e926daf5714370a3b6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/a1b4d9ec27badc7cbef9a6e926daf5714370a3b6.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6179,
          name: "Golden Dragon: Hold 'N' link",
          uuid: "0a8f0d019bce4b3699f6e7ef64deceb7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/0a8f0d019bce4b3699f6e7ef64deceb7.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6167,
          name: "Golden Tree",
          uuid: "413b13b4ef509b052e308aa537ed842866bc4035",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/413b13b4ef509b052e308aa537ed842866bc4035.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6188,
          name: "Gorilla Rapid Link",
          uuid: "204255d65a2ea5ca8d3850b890116723fdb73178",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/204255d65a2ea5ca8d3850b890116723fdb73178.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6161,
          name: "Grand Koala",
          uuid: "6c231ea631c449769499da2b405d5544",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/6c231ea631c449769499da2b405d5544.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6146,
          name: "Heat Hit",
          uuid: "e2481414c8119bd357c750617ef1940446ccd59d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e2481414c8119bd357c750617ef1940446ccd59d.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6114,
          name: "Hit in Vegas",
          uuid: "bbbee6f0b10225e2043ebf7df850a6a61ee56b04",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bbbee6f0b10225e2043ebf7df850a6a61ee56b04.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6180,
          name: "Hot as Devil: Hold 'N' link",
          uuid: "8e4b185ba1ff486c890e80c51043dedb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/8e4b185ba1ff486c890e80c51043dedb.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6137,
          name: "Hot Cash: Hold ‘n’ Link",
          uuid: "60d5ba7d10cfab27dc2215eaae5caaca7a0d3f18",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/60d5ba7d10cfab27dc2215eaae5caaca7a0d3f18.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6131,
          name: "Inferno Fruits",
          uuid: "5ed34b3b710e0b0ee05d67f5140d8feacf071054",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5ed34b3b710e0b0ee05d67f5140d8feacf071054.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6122,
          name: "Jackpot Sevens",
          uuid: "6666097e45f51afff0656a0e3483094efac9c07c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6666097e45f51afff0656a0e3483094efac9c07c.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6140,
          name: "Just Gems: Hold ‘n’ Link",
          uuid: "5abb0194aa14c38463da95d729e01caebcc26fe4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5abb0194aa14c38463da95d729e01caebcc26fe4.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6175,
          name: "Katana Klash: Hold 'N' Link",
          uuid: "65cca215dd77f41ef2ddf17fc32167d0f9ace147",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/65cca215dd77f41ef2ddf17fc32167d0f9ace147.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6128,
          name: "Lotus Fortune",
          uuid: "142110b5f50c684426ca89cb43b9b48576acd832",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/142110b5f50c684426ca89cb43b9b48576acd832.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6207,
          name: "Luck of Panda: Bonus Combo",
          uuid: "2f3ce00c9332edf2dad64478fa642ad1d7a7c068",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/2f3ce00c9332edf2dad64478fa642ad1d7a7c068.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6181,
          name: "Luck of Tiger: Bonus Combo",
          uuid: "54c0fc7e6a80471691b4c3d73de692f9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/54c0fc7e6a80471691b4c3d73de692f9.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6136,
          name: "Luxor Relics",
          uuid: "b463e13d589cba48da28e4d013be6886e9531ae7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b463e13d589cba48da28e4d013be6886e9531ae7.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6204,
          name: "Mardi Gras Queen",
          uuid: "c70f854a1bca066008b00ccae7a91c19e2bb973d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/c70f854a1bca066008b00ccae7a91c19e2bb973d.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6176,
          name: "Merry Cash Splash: Hold 'N' Link",
          uuid: "56254f431af0961ab2d775443dac8ae95710d9fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/56254f431af0961ab2d775443dac8ae95710d9fa.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6155,
          name: "Mighty Horses: Cash Connect",
          uuid: "40f74ef12cc54640808ec33b9a01e541",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/40f74ef12cc54640808ec33b9a01e541.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6185,
          name: "Mighty Stars",
          uuid: "37789caecbdd4528a497143456f7349e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/37789caecbdd4528a497143456f7349e.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6182,
          name: "Money Hive 50: Hold 'N' link",
          uuid: "aa363db5ac4de031ba51c2214653e62daa57b490",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/aa363db5ac4de031ba51c2214653e62daa57b490.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6157,
          name: "Money Hive Hold n Link",
          uuid: "11a3cdcf272cbeee1eabe8f94bdf322869968fc4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/11a3cdcf272cbeee1eabe8f94bdf322869968fc4.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6144,
          name: "Mriya",
          uuid: "a054edc2ec93ab0000ba3a4f2e07c7a5846a0c07",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a054edc2ec93ab0000ba3a4f2e07c7a5846a0c07.png",
          type: "crash",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6132,
          name: "Mystery Dragons",
          uuid: "731ab57db9e0cb2ce59b1f49b2066f21006e1570",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/731ab57db9e0cb2ce59b1f49b2066f21006e1570.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6150,
          name: "Osiris Gold",
          uuid: "64fe0158c3a14c1180c93170e3c97aad",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/64fe0158c3a14c1180c93170e3c97aad.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6172,
          name: "Pelican's Bay: Rapid Link",
          uuid: "d41a72aae234879a6bb3ea5a0f6a10b89a709f2b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/d41a72aae234879a6bb3ea5a0f6a10b89a709f2b.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6201,
          name: "Phoenix queen: Hold 'n' Link",
          uuid: "9217843923b285c0801ca04d650a2b495f278af0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/9217843923b285c0801ca04d650a2b495f278af0.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6154,
          name: "Pompeii Gold: Rapid Link",
          uuid: "d5727adcc398e9143ea869bdcf7379de1801d11d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/d5727adcc398e9143ea869bdcf7379de1801d11d.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6191,
          name: "Pompeii Gold: Rapid Link Bonus Buy",
          uuid: "a5758ac98c7779a21c8b91dd23e6a5cfcb6aeee0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/a5758ac98c7779a21c8b91dd23e6a5cfcb6aeee0.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6162,
          name: "Power of Zorro",
          uuid: "a5b33fa1c527430bba3aab38fb227c29",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/a5b33fa1c527430bba3aab38fb227c29.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6178,
          name: "Ranch Life",
          uuid: "4e9e5c374414436487eb0a2270bca68c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/4e9e5c374414436487eb0a2270bca68c.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6149,
          name: "Reel Rider: Rapid Link",
          uuid: "bfbe2d45dd9e465b8b49e4abd95c775b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/bfbe2d45dd9e465b8b49e4abd95c775b.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6174,
          name: "Rich Piggies: Bonus Combo",
          uuid: "94b480c22ee1f32451676a245792c8cb7319b313",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/94b480c22ee1f32451676a245792c8cb7319b313.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6115,
          name: "Royal Fruits 20",
          uuid: "1cb519389d11978924c6d25673ef1fdc68c5ea4c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1cb519389d11978924c6d25673ef1fdc68c5ea4c.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6116,
          name: "Royal Fruits 40",
          uuid: "bb07ea1636df6e6b10fe09037396cbc4c988c40e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bb07ea1636df6e6b10fe09037396cbc4c988c40e.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6139,
          name: "Royal Fruits 5: Hold 'n' Link",
          uuid: "bafa5a9b796d27d66b3a841747acaf5bfe847646",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bafa5a9b796d27d66b3a841747acaf5bfe847646.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6187,
          name: "Royal Fruits 9: Hold 'N' Link",
          uuid: "782da7fa113d4c62a05702a9fe52d0c9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/782da7fa113d4c62a05702a9fe52d0c9.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6203,
          name: "Rubber Duck: Hold 'N' Link",
          uuid: "241a440bcf9a66f620dce597295c39e8b100cb50",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/241a440bcf9a66f620dce597295c39e8b100cb50.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6202,
          name: "San Chong BaoZha Blue: Bonus Combo",
          uuid: "c660cd34cdd3830e8e2f3d5fe806d1debbbf6a23",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/c660cd34cdd3830e8e2f3d5fe806d1debbbf6a23.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6151,
          name: "Savanna Squad",
          uuid: "da381e6a8f4a4a3f84daacbb2146bdc5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/da381e6a8f4a4a3f84daacbb2146bdc5.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6117,
          name: "Shining Princess: Rapid Link",
          uuid: "c4388350e9a48a614dd6a029274e246a75e0bd18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/c4388350e9a48a614dd6a029274e246a75e0bd18.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6135,
          name: "Squid Slot",
          uuid: "e4a34056a26ca89c24479a406e0bf47b9c784c0e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e4a34056a26ca89c24479a406e0bf47b9c784c0e.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6133,
          name: "The Big Game Hold ‘n’ Link",
          uuid: "16d0e09a14e2bc3efa728d81570c50da2a37387c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/16d0e09a14e2bc3efa728d81570c50da2a37387c.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6163,
          name: "The One Armed Pirate",
          uuid: "2094208a678746e5acaf4d0733264976",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/NetGame/2094208a678746e5acaf4d0733264976.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6193,
          name: "Three Piglets",
          uuid: "a2a4f6b17b431516b55440d20598ac57509d20fb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/a2a4f6b17b431516b55440d20598ac57509d20fb.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6169,
          name: "Tiki Island: Hold 'N' Link",
          uuid: "2be7eaa1b85aa405aea18fd595646502d53e80e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/2be7eaa1b85aa405aea18fd595646502d53e80e4.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6168,
          name: "Tumblin' Dublin",
          uuid: "45a9a9ff339bbec7516044e197e3ee3e405fc272",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/45a9a9ff339bbec7516044e197e3ee3e405fc272.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6118,
          name: "Volcano Fruits",
          uuid: "c7cdce4e438b266e9d23b5b3ced2d1473c0878e0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c7cdce4e438b266e9d23b5b3ced2d1473c0878e0.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6119,
          name: "Wild Buffalo",
          uuid: "d2e61b7d6fe114509ab92051a678736819b27328",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d2e61b7d6fe114509ab92051a678736819b27328.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6158,
          name: "Wild Buffalo Fortune Wheel",
          uuid: "71f3a4f53ad36a9fe7f5922553e5d8ab02b37a8b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/71f3a4f53ad36a9fe7f5922553e5d8ab02b37a8b.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6205,
          name: "Wild Buffalo Hold 'N' Link Bonus Buy",
          uuid: "45aca584f54c55091a94344848df1ca94861caca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/45aca584f54c55091a94344848df1ca94861caca.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6141,
          name: "Wild Buffalo Hold n Link",
          uuid: "a41604e75d22e3b5e464a02a855cd3c50a859cc2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a41604e75d22e3b5e464a02a855cd3c50a859cc2.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6173,
          name: "Wild Buffalo: Rapid Link",
          uuid: "7bff61bbd13b0ba8846bb2904d73e6e0f26d8584",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/7bff61bbd13b0ba8846bb2904d73e6e0f26d8584.jpg",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6196,
          name: "Wolf Reels Rapid Link",
          uuid: "ca66da9a6aa724514abb2fb9231804760a8df44f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/NetGame/ca66da9a6aa724514abb2fb9231804760a8df44f.png",
          type: "slots",
          provider: "NetGame",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 134,
      name: "Nucleus",
      providerId: 44,
      providerName: "Nucleus",
      games: [
        {
          game_id: 6211,
          name: "12 Animals",
          uuid: "34c1335d80a54d3b81cea58cc4426959",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/34c1335d80a54d3b81cea58cc4426959.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6212,
          name: "12 Animals Mobile",
          uuid: "889b3db5d3e64b9c84d50850201a9ee5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/889b3db5d3e64b9c84d50850201a9ee5.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6369,
          name: "21 Burn Black Jack",
          uuid: "143ee49b65fa4612b2e76046150fef25",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/143ee49b65fa4612b2e76046150fef25.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6370,
          name: "21 Burn Black Jack Mobile",
          uuid: "3c7aea1e3bcb4f0481293332585fcf4e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/3c7aea1e3bcb4f0481293332585fcf4e.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6311,
          name: "A Time to Win",
          uuid: "12fa8c807380484e8cdbf26d2d4798d4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/12fa8c807380484e8cdbf26d2d4798d4.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6312,
          name: "A Time to Win Mobile",
          uuid: "26f24c5ad51e45e6a4b512210f1d81d9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/26f24c5ad51e45e6a4b512210f1d81d9.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6329,
          name: "All Reel Drive",
          uuid: "dff3605111e844308bbe30cda234dc95",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/dff3605111e844308bbe30cda234dc95.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6330,
          name: "All Reel Drive Mobile",
          uuid: "d1692c6a4699442fb8daa9be4a0887d6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/d1692c6a4699442fb8daa9be4a0887d6.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6357,
          name: "American Blackjack",
          uuid: "7d30a06c7baf44d483be5fb7d821a52e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/7d30a06c7baf44d483be5fb7d821a52e.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6358,
          name: "American Blackjack Mobile",
          uuid: "c6dbcb21626441908ec2cdbb1a187237",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/c6dbcb21626441908ec2cdbb1a187237.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6391,
          name: "American Roulette",
          uuid: "cf53925e48a6410abd502406830e43c1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/cf53925e48a6410abd502406830e43c1.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6392,
          name: "American Roulette Mobile",
          uuid: "935405882be04b0b9108d4523e690e82",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/935405882be04b0b9108d4523e690e82.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6237,
          name: "Arabian Wishes",
          uuid: "214ebf0418c84e60890c23ebe97303e2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/214ebf0418c84e60890c23ebe97303e2.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6238,
          name: "Arabian Wishes Mobile",
          uuid: "6f2fa25047aa411d862cf8143df6e6ec",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/6f2fa25047aa411d862cf8143df6e6ec.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6359,
          name: "Baccarat",
          uuid: "43e710f336264f9c80231ddd458d6974",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/43e710f336264f9c80231ddd458d6974.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6360,
          name: "Baccarat Mobile",
          uuid: "bac364b6282e4825ae26a924ee8f0008",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/bac364b6282e4825ae26a924ee8f0008.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6427,
          name: "Bandits and Bounties",
          uuid: "e63e3a0b9435887054f3f4e2b0abdb9fede29608",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/e63e3a0b9435887054f3f4e2b0abdb9fede29608.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6428,
          name: "Bandits and Bounties Mobile",
          uuid: "d169c0a4048264c97d3f76937678400451049866",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/d169c0a4048264c97d3f76937678400451049866.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6253,
          name: "Blades Of The Abyss",
          uuid: "074b16d5d47c4451bed879b13c89ff8a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/074b16d5d47c4451bed879b13c89ff8a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6254,
          name: "Blades Of The Abyss Mobile",
          uuid: "f2de35586a124c64a3af6d465fdc2636",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/f2de35586a124c64a3af6d465fdc2636.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6423,
          name: "Book of Christmas Eve",
          uuid: "ecf4e99fe7f74b8db021807d2a6e00f9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/ecf4e99fe7f74b8db021807d2a6e00f9.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6424,
          name: "Book of Christmas Eve Mobile",
          uuid: "433d5f22004f433b9c12edb5dc918cc5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/433d5f22004f433b9c12edb5dc918cc5.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6433,
          name: "Bottled Wishes",
          uuid: "804035d314d286a7f4447a74c0fd0aa2d0149814",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/804035d314d286a7f4447a74c0fd0aa2d0149814.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6434,
          name: "Bottled Wishes Mobile",
          uuid: "68ad588bcf697c78403a955e5e1e1c83a5b6171d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/68ad588bcf697c78403a955e5e1e1c83a5b6171d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6417,
          name: "Call Of Zeus",
          uuid: "ca45fb12289de07ae2756a752f27024f55c6959d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/ca45fb12289de07ae2756a752f27024f55c6959d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6418,
          name: "Call Of Zeus Mobile",
          uuid: "2cad19b7cd0daadde28cf8af064b220382fa26e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/2cad19b7cd0daadde28cf8af064b220382fa26e1.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6281,
          name: "Cardinal Dragons",
          uuid: "02872db0cc624648be245777a0ee5996",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/02872db0cc624648be245777a0ee5996.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6282,
          name: "Cardinal Dragons Mobile",
          uuid: "d7f2c487bec84ba68cc71cf83afa66f9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/d7f2c487bec84ba68cc71cf83afa66f9.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6375,
          name: "Caribbean Poker",
          uuid: "68f5951ad1ba4b9d8d50448e97de65bf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/68f5951ad1ba4b9d8d50448e97de65bf.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6376,
          name: "Caribbean Poker Mobile",
          uuid: "0bbc9d04da4846f38e6176083fddb72e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/0bbc9d04da4846f38e6176083fddb72e.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6245,
          name: "Catch & Release",
          uuid: "7c36f7a63ac84df182e12a598081b014",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/7c36f7a63ac84df182e12a598081b014.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6246,
          name: "Catch & Release Mobile",
          uuid: "3c6da1d2c00447bc8e690bb680e1394b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/3c6da1d2c00447bc8e690bb680e1394b.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6461,
          name: "Chameleon Cash - Hold & Win",
          uuid: "4c6657542d3045ff8224ab41ba1e7e9f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/4c6657542d3045ff8224ab41ba1e7e9f.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6462,
          name: "Chameleon Cash - Hold & Win Mobile",
          uuid: "92fb847ce5f044978e8302bdd591f6c0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/92fb847ce5f044978e8302bdd591f6c0.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6407,
          name: "Christmas Journey",
          uuid: "111e73c96d251cf0eac3e18c48a9ba709d134df6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/111e73c96d251cf0eac3e18c48a9ba709d134df6.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6408,
          name: "Christmas Journey Mobile",
          uuid: "457d2db313ea13278f4d8718c85504a8b75449ac",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/457d2db313ea13278f4d8718c85504a8b75449ac.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6275,
          name: "Coco Bongo",
          uuid: "96ffb5d0bd42436a99da9c111b0bfe2b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/96ffb5d0bd42436a99da9c111b0bfe2b.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6276,
          name: "Coco Bongo Mobile",
          uuid: "abde600f86384e17938f1cf2b773b711",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/abde600f86384e17938f1cf2b773b711.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6403,
          name: "Deuces Wild",
          uuid: "6d423c587e87481e6d101ba4ad8e6a031ba9d9e2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6d423c587e87481e6d101ba4ad8e6a031ba9d9e2.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6404,
          name: "Deuces Wild Mobile",
          uuid: "e5ac64ed057ef39f2cc6dba826067165988cb366",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e5ac64ed057ef39f2cc6dba826067165988cb366.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6459,
          name: "Diamond Deluxe",
          uuid: "a297b9b04ac2657477953e2befa1fff6eb4cc840",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/a297b9b04ac2657477953e2befa1fff6eb4cc840.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6460,
          name: "Diamond Deluxe Mobile",
          uuid: "3d6036d65fc56255b480b499d337f33865d3838c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/3d6036d65fc56255b480b499d337f33865d3838c.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6470,
          name: "Diamond Fruit Deluxe",
          uuid: "f71b3f7ae0034bab9157562a9aff1857",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/f71b3f7ae0034bab9157562a9aff1857.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6469,
          name: "Diamond Fruit Deluxe Mobile",
          uuid: "faad57af6a5e49f0b76d264d3c1d09e8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/faad57af6a5e49f0b76d264d3c1d09e8.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6333,
          name: "Diamond Strip",
          uuid: "f91043d1e3824351b65a20c572cd1465",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/f91043d1e3824351b65a20c572cd1465.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6334,
          name: "Diamond Strip Mobile",
          uuid: "6ef9126d4d6f49cd99943e04874cc8d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/6ef9126d4d6f49cd99943e04874cc8d7.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6337,
          name: "Dragon Watch",
          uuid: "8be2aa89067b4e84a9f6946f51f4a9d2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/8be2aa89067b4e84a9f6946f51f4a9d2.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6338,
          name: "Dragon Watch Mobile",
          uuid: "75e53f7229294d4097d208db848f195c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/75e53f7229294d4097d208db848f195c.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6361,
          name: "Draw Hi-Lo",
          uuid: "1dec4f9bf4cc46caa1fc13922fe037e7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/1dec4f9bf4cc46caa1fc13922fe037e7.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6362,
          name: "Draw Hi-Lo Mobile",
          uuid: "3050a8e5c3e44166a9da6a0c957f8ace",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/3050a8e5c3e44166a9da6a0c957f8ace.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6295,
          name: "End Zone Riches",
          uuid: "d6d41b842a444b6b92e3067adaa6a883",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/d6d41b842a444b6b92e3067adaa6a883.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6296,
          name: "End Zone Riches Mobile",
          uuid: "9227505d1a694f52b4f5a467d2e88bec",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/9227505d1a694f52b4f5a467d2e88bec.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6371,
          name: "European Blackjack",
          uuid: "e8ff16ef87cd415e8c65f9126f33e33b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/e8ff16ef87cd415e8c65f9126f33e33b.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6372,
          name: "European Blackjack Mobile",
          uuid: "d043804bb1a04deaa9547c3dd47d7d65",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/d043804bb1a04deaa9547c3dd47d7d65.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6389,
          name: "European Roulette",
          uuid: "f1dd84a43c39439092e82738c3c6963b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/f1dd84a43c39439092e82738c3c6963b.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6390,
          name: "European Roulette Mobile",
          uuid: "c68987759c7f4ca2a3fc3dba6cf6aa6a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/c68987759c7f4ca2a3fc3dba6cf6aa6a.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6217,
          name: "Firecrackers",
          uuid: "5b726561b6a04104b48c42b6a4bad4a4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/5b726561b6a04104b48c42b6a4bad4a4.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6218,
          name: "Firecrackers Mobile",
          uuid: "0c5a025ac667452287673f80e218008c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/0c5a025ac667452287673f80e218008c.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6452,
          name: "Fishin Fortunes",
          uuid: "8ebe7418e8b3c3f7fb839fd91818ecccaf943a1d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/8ebe7418e8b3c3f7fb839fd91818ecccaf943a1d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6451,
          name: "Fishin Fortunes Mobile",
          uuid: "6d83a801342d42db94caa24b5a21a02aebbbe4d9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/6d83a801342d42db94caa24b5a21a02aebbbe4d9.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6213,
          name: "Freaky Frankenstein",
          uuid: "1c73cbd3e8a94a9eb6f12f5391eb096f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/1c73cbd3e8a94a9eb6f12f5391eb096f.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6214,
          name: "Freaky Frankenstein Mobile",
          uuid: "de278800febd4feab730254a7dc18dea",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/de278800febd4feab730254a7dc18dea.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6463,
          name: "Frontier Gold",
          uuid: "6feb702bdf5f4ce59fc01ca2e5842cc7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/6feb702bdf5f4ce59fc01ca2e5842cc7.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6464,
          name: "Frontier Gold Mobile",
          uuid: "4a8a189d57e24a4eb6fd3bc83c64cb38",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/4a8a189d57e24a4eb6fd3bc83c64cb38.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6265,
          name: "Fruit Serenity",
          uuid: "dc77a751608c4e5b99c2721c7e564f88",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/dc77a751608c4e5b99c2721c7e564f88.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6266,
          name: "Fruit Serenity Mobile",
          uuid: "8e8016cb62414cfebb2ad2afdae89d2d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/8e8016cb62414cfebb2ad2afdae89d2d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6317,
          name: "Fruity Way",
          uuid: "f00d7fb8fb59434ebf465911ab942c91",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/f00d7fb8fb59434ebf465911ab942c91.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6318,
          name: "Fruity Way Mobile",
          uuid: "0dde27b49529421d9705c1bb030aaee1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/0dde27b49529421d9705c1bb030aaee1.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6351,
          name: "Geppetto's Toy Shop",
          uuid: "ce74db22254f4ac9a8a55ec6beb9f167",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/ce74db22254f4ac9a8a55ec6beb9f167.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6352,
          name: "Geppetto's Toy Shop Mobile",
          uuid: "c4424e81fd7c43bf8382f7819a658424",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/c4424e81fd7c43bf8382f7819a658424.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6301,
          name: "Glorious Gems",
          uuid: "25c59209f4a24a17909ffce7131c68c9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/25c59209f4a24a17909ffce7131c68c9.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6302,
          name: "Glorious Gems Mobile",
          uuid: "841b5d2301ca49e79174b7b622d6cb25",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/841b5d2301ca49e79174b7b622d6cb25.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6339,
          name: "Goals of Glory",
          uuid: "6b6450a0a44b40018d2081703928814a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/6b6450a0a44b40018d2081703928814a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6340,
          name: "Goals of Glory Mobile",
          uuid: "1cede81feccc454697c59c65d7fa364f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/1cede81feccc454697c59c65d7fa364f.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6431,
          name: "Grand Luxe",
          uuid: "275a64feb3fe51751cb13c159b83c0125d79946d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/275a64feb3fe51751cb13c159b83c0125d79946d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6432,
          name: "Grand Luxe Mobile",
          uuid: "13a0cffe862244726c530929959ae1041b659866",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/13a0cffe862244726c530929959ae1041b659866.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6353,
          name: "High Noon",
          uuid: "90bb725d074240c8931b0f76bd95287c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/90bb725d074240c8931b0f76bd95287c.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6354,
          name: "High Noon Mobile",
          uuid: "e71b7d1c1e6b45a1a94d9f6d078e4ad2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/e71b7d1c1e6b45a1a94d9f6d078e4ad2.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6325,
          name: "Ho Ho Cash",
          uuid: "20006fb7d0694c64bf38e7371fda0ecd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/20006fb7d0694c64bf38e7371fda0ecd.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6326,
          name: "Ho Ho Cash Mobile",
          uuid: "65ddc9cfd62147eda83391bd117296fa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/65ddc9cfd62147eda83391bd117296fa.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6453,
          name: "Hot Magic",
          uuid: "682dcaf2f667f3105138b3d07e311afcae0e3c29",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/682dcaf2f667f3105138b3d07e311afcae0e3c29.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6454,
          name: "Hot Magic Mobile",
          uuid: "c6ba6d50c66d564fa6d85352caccc70178135326",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/c6ba6d50c66d564fa6d85352caccc70178135326.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6293,
          name: "Howling At The Moon",
          uuid: "d8dc774b5f3d4b60a2d6054e8762d48d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/d8dc774b5f3d4b60a2d6054e8762d48d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6294,
          name: "Howling At The Moon Mobile",
          uuid: "6ff3ef99db3d4fa28a541db4e8b2365f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/6ff3ef99db3d4fa28a541db4e8b2365f.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6347,
          name: "Hyper Galactic",
          uuid: "95fd233732944a9884e1f830dbd2048f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/95fd233732944a9884e1f830dbd2048f.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6348,
          name: "Hyper Galactic Mobile",
          uuid: "7d4255f3ade14354b24fe2cb7d9c5bd4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/7d4255f3ade14354b24fe2cb7d9c5bd4.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6419,
          name: "Jack and the Mighty Beanstalk",
          uuid: "723b06b118af71e509811d13cefe16532d788856",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/723b06b118af71e509811d13cefe16532d788856.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6420,
          name: "Jack and the Mighty Beanstalk Mobile",
          uuid: "e9c4d9cbc827a8caab5710755bf87c096ee8e915",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/e9c4d9cbc827a8caab5710755bf87c096ee8e915.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6425,
          name: "Jewel Falls",
          uuid: "c3f0574b421c47dda5aab9f69cfbd830",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/c3f0574b421c47dda5aab9f69cfbd830.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6426,
          name: "Jewel Falls Mobile",
          uuid: "0f571d9e3d8142338fd362a82bf11c80",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/0f571d9e3d8142338fd362a82bf11c80.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6305,
          name: "Jingle Slots",
          uuid: "aacdcf6712d944bd9c7d1cd0000e8040",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/aacdcf6712d944bd9c7d1cd0000e8040.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6306,
          name: "Jingle Slots Mobile",
          uuid: "f4928c6d475c4a90af79fc975b7a3ae0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/f4928c6d475c4a90af79fc975b7a3ae0.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6439,
          name: "Joker City",
          uuid: "24569c0d0a164752bb71f909de03855d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/24569c0d0a164752bb71f909de03855d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6440,
          name: "Joker City Mobile",
          uuid: "6ff089774b624f6a9309a1d2c251dc6b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/6ff089774b624f6a9309a1d2c251dc6b.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6435,
          name: "Joker Gemferno",
          uuid: "d8aa1f4136d1e0c5fdd294c18b17456caae68059",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/d8aa1f4136d1e0c5fdd294c18b17456caae68059.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6436,
          name: "Joker Gemferno Mobile",
          uuid: "e0be71bc76c967a5260c76b569f94934ced06dd0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/e0be71bc76c967a5260c76b569f94934ced06dd0.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6415,
          name: "Juicy Bonanza",
          uuid: "f296ad991fa4843b693a98727b2aeb62ca737d0b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f296ad991fa4843b693a98727b2aeb62ca737d0b.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6416,
          name: "Juicy Bonanza Mobile",
          uuid: "f6a620090781825e45a53055d9947835f37f00ee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f6a620090781825e45a53055d9947835f37f00ee.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6279,
          name: "Jumbo Jester",
          uuid: "322940f050b948d79d0f6062dae8ca83",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/322940f050b948d79d0f6062dae8ca83.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6280,
          name: "Jumbo Jester Mobile",
          uuid: "819a5d3654574cf9b58949254108775d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/819a5d3654574cf9b58949254108775d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6471,
          name: "Jurassic Fortunes",
          uuid: "59d1311ecdea475ab06f757c3110acf2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/59d1311ecdea475ab06f757c3110acf2.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6472,
          name: "Jurassic Fortunes Mobile",
          uuid: "d6457e4c714445edb96d1dfc1c7cb695",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/d6457e4c714445edb96d1dfc1c7cb695.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6233,
          name: "Kitty Cutie",
          uuid: "0ff78f67ddb44243b8a16e833672a6a5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/0ff78f67ddb44243b8a16e833672a6a5.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6234,
          name: "Kitty Cutie Mobile",
          uuid: "b360bfc2bbb141f4b17017e92a2a7112",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/b360bfc2bbb141f4b17017e92a2a7112.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6323,
          name: "Kraken Deep Wins",
          uuid: "8b5adf55076a461e932ce11732087e44",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/8b5adf55076a461e932ce11732087e44.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6324,
          name: "Kraken Deep Wins Mobile",
          uuid: "bc73c54c90024ada887b89b45944628b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/bc73c54c90024ada887b89b45944628b.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6405,
          name: "Legend of Azteca",
          uuid: "3552355771d358a485b6ca08e1a1b48d1c107652",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3552355771d358a485b6ca08e1a1b48d1c107652.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6406,
          name: "Legend of Azteca Mobile",
          uuid: "b8fc9b94e408c16e5561363f9bca9afda872332e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b8fc9b94e408c16e5561363f9bca9afda872332e.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6251,
          name: "Lucky Clovers",
          uuid: "2ec6c81cd6c94186b02cc9c9402aec0f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/2ec6c81cd6c94186b02cc9c9402aec0f.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6252,
          name: "Lucky Clovers Mobile",
          uuid: "83c6e84649ac4cdd95632158203566df",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/83c6e84649ac4cdd95632158203566df.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6223,
          name: "Matinee",
          uuid: "7776b99123bd4e398a1f54af622b7731",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/7776b99123bd4e398a1f54af622b7731.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6224,
          name: "Matinee Mobile",
          uuid: "0666a7e219d24e8dbe790f5483ca1882",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/0666a7e219d24e8dbe790f5483ca1882.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6229,
          name: "Merlin's Elements",
          uuid: "1d6257d4e70948688597d4eb0d1a2809",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/1d6257d4e70948688597d4eb0d1a2809.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6230,
          name: "Merlin's Elements Mobile",
          uuid: "7f25c33404d4436993531e8657de1f47",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/7f25c33404d4436993531e8657de1f47.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6267,
          name: "Mermaid's Treasure",
          uuid: "f4799410244a42178f511c4c53d977e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/f4799410244a42178f511c4c53d977e3.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6268,
          name: "Mermaid's Treasure Mobile",
          uuid: "dd91277719914b5f80ce38d6b7bf3afe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/dd91277719914b5f80ce38d6b7bf3afe.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6257,
          name: "Moon Bitten",
          uuid: "88a28670298c44d297ac3b386dfc8759",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/88a28670298c44d297ac3b386dfc8759.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6258,
          name: "Moon Bitten Mobile",
          uuid: "1bc0958d307d4156bf577ff317f050cc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/1bc0958d307d4156bf577ff317f050cc.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6309,
          name: "Mystic Rift",
          uuid: "c4259b5fa49f4f738193529836a319e6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/c4259b5fa49f4f738193529836a319e6.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6310,
          name: "Mystic Rift Mobile",
          uuid: "a532b4dde067464fb55337d7dfce761a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/a532b4dde067464fb55337d7dfce761a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6313,
          name: "No Limit Bandit",
          uuid: "01524a3aee5c4e9f8253a6064a7ff52a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/01524a3aee5c4e9f8253a6064a7ff52a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6314,
          name: "No Limit Bandit Mobile",
          uuid: "bb9d12d942a4492a82fdd1a0ec3211b5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/bb9d12d942a4492a82fdd1a0ec3211b5.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6377,
          name: "Oasis Poker",
          uuid: "774b229773614a5b96eadb88c17add65",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/774b229773614a5b96eadb88c17add65.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6378,
          name: "Oasis Poker Mobile",
          uuid: "1b10f034129542ed9a8f75241dfb1399",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/1b10f034129542ed9a8f75241dfb1399.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6283,
          name: "Ogre Village",
          uuid: "e774fe831fba4789a2e9121918b46278",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/e774fe831fba4789a2e9121918b46278.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6284,
          name: "Ogre Village Mobile",
          uuid: "746edf4112a44bfbb93fef26efd6aadf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/746edf4112a44bfbb93fef26efd6aadf.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6363,
          name: "Pai Gow",
          uuid: "d00d3b6d17a34118a1d55a5925756afa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/d00d3b6d17a34118a1d55a5925756afa.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6364,
          name: "Pai Gow Mobile",
          uuid: "33709f27c5a14cda90d61480291373f4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/33709f27c5a14cda90d61480291373f4.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6413,
          name: "Party Paradise",
          uuid: "97324228df3941c26908fdfdedaeaab202bb7de4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/97324228df3941c26908fdfdedaeaab202bb7de4.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6414,
          name: "Party Paradise Mobile",
          uuid: "2ffe429d94a60cbadf9f1e104718f593f780f9e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2ffe429d94a60cbadf9f1e104718f593f780f9e3.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6327,
          name: "Peak Riches",
          uuid: "c6369c5a2f5246bfa349afadcda76e7a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/c6369c5a2f5246bfa349afadcda76e7a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6328,
          name: "Peak Riches Mobile",
          uuid: "fcd422ab0cb644a1b2abd61da1968ebb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/fcd422ab0cb644a1b2abd61da1968ebb.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6379,
          name: "Pirate 21",
          uuid: "e03eebd99e8346809ffbe830a2a1f75f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/e03eebd99e8346809ffbe830a2a1f75f.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6380,
          name: "Pirate 21 Mobile",
          uuid: "18740b0bf77e4961bcf8be567e82ef5b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/18740b0bf77e4961bcf8be567e82ef5b.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6287,
          name: "Pixie Magic",
          uuid: "e24baead28a14d4fb8da60037a35a43e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/e24baead28a14d4fb8da60037a35a43e.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6288,
          name: "Pixie Magic Mobile",
          uuid: "4608ac2c34574587aabc7e786cde2e3f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/4608ac2c34574587aabc7e786cde2e3f.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6291,
          name: "Polar Breeze",
          uuid: "74c1c40e1bc84aff95a48c8476f6dfb7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/74c1c40e1bc84aff95a48c8476f6dfb7.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6292,
          name: "Polar Breeze Mobile",
          uuid: "671eb0e0cbcb4b818633de1e2f785af5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/671eb0e0cbcb4b818633de1e2f785af5.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6381,
          name: "Pontoon",
          uuid: "ad2b9aa3926d4b92aac2a98e33a0f300",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/ad2b9aa3926d4b92aac2a98e33a0f300.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6382,
          name: "Pontoon Mobile",
          uuid: "ae71e17fce214a6989963e053472ef13",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/ae71e17fce214a6989963e053472ef13.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6466,
          name: "Pots of Gold",
          uuid: "0a2450dbce1c4c65b64f2e68973bc107",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/0a2450dbce1c4c65b64f2e68973bc107.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6465,
          name: "Pots of Gold Mobile",
          uuid: "dbd26bf710be4cde9c3e7d3b9e828476",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/dbd26bf710be4cde9c3e7d3b9e828476.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6365,
          name: "Red Dog",
          uuid: "56bf81876c2742f9b88a943e80dcd19d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/56bf81876c2742f9b88a943e80dcd19d.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6366,
          name: "Red Dog Mobile",
          uuid: "30fb0f99288c48a1bbe27497bd6b3977",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/30fb0f99288c48a1bbe27497bd6b3977.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6277,
          name: "Reels Of Treasure",
          uuid: "11a2d648589f454cac1c0d8b8a065b6f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/11a2d648589f454cac1c0d8b8a065b6f.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6278,
          name: "Reels Of Treasure Mobile",
          uuid: "d18fdf72b07042f4a49caa96b0ed522a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/d18fdf72b07042f4a49caa96b0ed522a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6219,
          name: "Rollin Trolls",
          uuid: "5dd6f04b6bab442393f242ae9d824e33",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/5dd6f04b6bab442393f242ae9d824e33.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6220,
          name: "Rollin Trolls Mobile",
          uuid: "853dbac29ed44f86864d99482a2f2662",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/853dbac29ed44f86864d99482a2f2662.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6261,
          name: "Ruby Jade",
          uuid: "a1b0896a36744767b528201af75608a6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/a1b0896a36744767b528201af75608a6.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6262,
          name: "Ruby Jade Mobile",
          uuid: "dc77f3de7cd74c58b7e0fd40f0838e73",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/dc77f3de7cd74c58b7e0fd40f0838e73.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6289,
          name: "Runes Of Odin",
          uuid: "21cdab8865de4b8cbf7c23e12c615b25",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/21cdab8865de4b8cbf7c23e12c615b25.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6290,
          name: "Runes Of Odin Mobile",
          uuid: "863f95c6180e448aba070c16081ce148",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/863f95c6180e448aba070c16081ce148.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6411,
          name: "Safari Spins",
          uuid: "83acd4dd2ab202d7f822ce1d001fd4c95e2f2829",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/83acd4dd2ab202d7f822ce1d001fd4c95e2f2829.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6412,
          name: "Safari Spins Mobile",
          uuid: "30f8e1e42e77fbc9cdd07ab1dd76e0b613ed4bb1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/30f8e1e42e77fbc9cdd07ab1dd76e0b613ed4bb1.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6285,
          name: "Salsa Poppers",
          uuid: "a94939316e304dbfa25c4e41871796c3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/a94939316e304dbfa25c4e41871796c3.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6286,
          name: "Salsa Poppers Mobile",
          uuid: "7fe68c287e5e42b496606cda4c266ed8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/7fe68c287e5e42b496606cda4c266ed8.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6271,
          name: "Sands Of Egypt",
          uuid: "67d04f328b8749b9b15dbf78eaaaf27d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/67d04f328b8749b9b15dbf78eaaaf27d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6272,
          name: "Sands Of Egypt Mobile",
          uuid: "7944418fad454f09b46f12e3c2faa492",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/7944418fad454f09b46f12e3c2faa492.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6225,
          name: "Sashimi Dreams",
          uuid: "5f4083151de5406e833358917e7c45e0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/5f4083151de5406e833358917e7c45e0.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6226,
          name: "Sashimi Dreams Mobile",
          uuid: "008bec539ae741a1a98bf4d4f35cd938",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/008bec539ae741a1a98bf4d4f35cd938.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6259,
          name: "Savanna Safari",
          uuid: "18947f0ea8ca4824a48a8a6f304b7254",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/18947f0ea8ca4824a48a8a6f304b7254.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6260,
          name: "Savanna Safari Mobile",
          uuid: "824cd610d53742da91aff4e7f2c4608d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/824cd610d53742da91aff4e7f2c4608d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6273,
          name: "Scroll Of Horus",
          uuid: "0cf285be09864b05a022631da09b2522",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/0cf285be09864b05a022631da09b2522.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6274,
          name: "Scroll Of Horus Mobile",
          uuid: "322a6043e61f4346b1117ce1d5b7aa70",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/322a6043e61f4346b1117ce1d5b7aa70.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6335,
          name: "Seer's Crystal",
          uuid: "52f317f911914b52b0681f9fd53458e9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/52f317f911914b52b0681f9fd53458e9.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6336,
          name: "Seer's Crystal Mobile",
          uuid: "aa441aeca3fd420daabef50833843bcf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/aa441aeca3fd420daabef50833843bcf.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6399,
          name: "Shark Spin",
          uuid: "ff3bf50133c5fc8e4a34988b3491de6a69b7d280",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ff3bf50133c5fc8e4a34988b3491de6a69b7d280.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6400,
          name: "Shark Spin Mobile",
          uuid: "633fc8ad9595b7a4d99ddcc09257dc071643e0d0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/633fc8ad9595b7a4d99ddcc09257dc071643e0d0.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6443,
          name: "Sherlock and the Mystic Compass NJP",
          uuid: "ef3ab5b47dfaac8ccb428c467c870fe00008029c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/ef3ab5b47dfaac8ccb428c467c870fe00008029c.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6444,
          name: "Sherlock and the Mystic Compass NJP Mobile",
          uuid: "f37166f073fb1e9e5d17df61fffc0d366b9b8335",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/f37166f073fb1e9e5d17df61fffc0d366b9b8335.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6373,
          name: "Single Deck Blackjack",
          uuid: "98a7cace6dea4bb78802ef509664b695",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/98a7cace6dea4bb78802ef509664b695.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6374,
          name: "Single Deck Blackjack Mobile",
          uuid: "b456d6a99b504a0db16535eaa9c6b4d3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/b456d6a99b504a0db16535eaa9c6b4d3.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6235,
          name: "Sisters Of Luck",
          uuid: "296db938fe984a979110f46181fe525d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/296db938fe984a979110f46181fe525d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6236,
          name: "Sisters Of Luck Mobile",
          uuid: "1bdef2eb8d2148dca6967cd80881f94c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/1bdef2eb8d2148dca6967cd80881f94c.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6441,
          name: "Slam Dunk Spins",
          uuid: "18204d392988462eaf31f5da05a8070a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/18204d392988462eaf31f5da05a8070a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6442,
          name: "Slam Dunk Spins Mobile",
          uuid: "35ddd32879734e8d9f86f3e9f319fe62",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/35ddd32879734e8d9f86f3e9f319fe62.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6255,
          name: "Sleight Of Hand",
          uuid: "779207c9bb9f4cfb87c929f21f6dae99",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/779207c9bb9f4cfb87c929f21f6dae99.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6256,
          name: "Sleight Of Hand Mobile",
          uuid: "ce4c450fe1864209a7daeb9a85405bd8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/ce4c450fe1864209a7daeb9a85405bd8.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6263,
          name: "Soaring Wind",
          uuid: "8bd93324f60f44f78031701bab9b3e3f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/8bd93324f60f44f78031701bab9b3e3f.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6264,
          name: "Soaring Wind Mobile",
          uuid: "ddeaba1504e64d3e9d13f2f752a0e11e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/ddeaba1504e64d3e9d13f2f752a0e11e.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6457,
          name: "Solar Spins",
          uuid: "b7e62eb9f308457ea14c0889fd44a0a1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/b7e62eb9f308457ea14c0889fd44a0a1.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6458,
          name: "Solar Spins Mobile",
          uuid: "f93b36d19e724168bbe4ddc68a96adbd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/f93b36d19e724168bbe4ddc68a96adbd.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6343,
          name: "Spin To Ride",
          uuid: "60f3261f9427491089e092a030b2bfee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/60f3261f9427491089e092a030b2bfee.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6344,
          name: "Spin To Ride Mobile",
          uuid: "b4d82a8b2ff94daaa2ad63baff876140",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/b4d82a8b2ff94daaa2ad63baff876140.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6297,
          name: "Spirit of the Nile",
          uuid: "48a8bb40873b4c9aa061d785e1b527d4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/48a8bb40873b4c9aa061d785e1b527d4.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6298,
          name: "Spirit of the Nile Mobile",
          uuid: "3a71edf2916f4e8ba421ad143a873606",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/3a71edf2916f4e8ba421ad143a873606.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6321,
          name: "Spooktacular Spins",
          uuid: "004fda8f82db4ce988f9bc1e775b380a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/004fda8f82db4ce988f9bc1e775b380a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6322,
          name: "Spooktacular Spins Mobile",
          uuid: "1b31a54873064f31a40a0ecae0c29a1a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/1b31a54873064f31a40a0ecae0c29a1a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6473,
          name: "Street dogs",
          uuid: "718c7efe1e1943148ee573624b95841c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/718c7efe1e1943148ee573624b95841c.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6474,
          name: "Street dogs Mobile",
          uuid: "b78c6bb74d0d4223818d5312eb1ec4e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/b78c6bb74d0d4223818d5312eb1ec4e3.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6449,
          name: "Sugar and Spins",
          uuid: "0d88bcf2115bab151629baecb8c235e1c6d8a987",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/0d88bcf2115bab151629baecb8c235e1c6d8a987.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6450,
          name: "Sugar and Spins Mobile",
          uuid: "852a3db431e2a2837e793e4edc19f4455cb9eaa9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/852a3db431e2a2837e793e4edc19f4455cb9eaa9.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6385,
          name: "Super 7 Blackjack",
          uuid: "2d748969b66f4b42ba56d3a7c2fd359a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/2d748969b66f4b42ba56d3a7c2fd359a.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6386,
          name: "Super 7 Blackjack Mobile",
          uuid: "64788315c7664b36aa64c91aa6137f26",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/64788315c7664b36aa64c91aa6137f26.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6231,
          name: "Super 7s",
          uuid: "fcfeb248a3ef47a4b29850da59dd2ba7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/fcfeb248a3ef47a4b29850da59dd2ba7.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6232,
          name: "Super 7s Mobile",
          uuid: "dee939bdbec84bbc87766fb2711516cd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/dee939bdbec84bbc87766fb2711516cd.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6349,
          name: "Super Crystals",
          uuid: "ea139275c18447cb920e9fab55e7d29e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/ea139275c18447cb920e9fab55e7d29e.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6350,
          name: "Super Crystals Mobile",
          uuid: "92425401de2640a8b3c9a8ed8f7ec4af",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/92425401de2640a8b3c9a8ed8f7ec4af.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6215,
          name: "Sweet Treats",
          uuid: "099fdb931d164242811a1961c27a0f0a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/099fdb931d164242811a1961c27a0f0a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6269,
          name: "Sweet Treats 2",
          uuid: "287486eeb37040198d66166bf2435077",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/287486eeb37040198d66166bf2435077.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6270,
          name: "Sweet Treats 2 Mobile",
          uuid: "c2d8719aa2be441fb49d595aa87b5788",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c2d8719aa2be441fb49d595aa87b5788.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6216,
          name: "Sweet Treats Mobile",
          uuid: "a54281c7b0ee44afa7eb9de1428d5ef6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/a54281c7b0ee44afa7eb9de1428d5ef6.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6243,
          name: "The Critters",
          uuid: "d2be181ad94c498194455781829de690",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/d2be181ad94c498194455781829de690.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6244,
          name: "The Critters Mobile",
          uuid: "093691ff3ac140d8b20c039f41ee7e7a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/093691ff3ac140d8b20c039f41ee7e7a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6437,
          name: "The Family",
          uuid: "97607d0a511b745ec2954260da472a0091f2b46a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/97607d0a511b745ec2954260da472a0091f2b46a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6249,
          name: "The Family JP",
          uuid: "69fbcd7eb60b4451a2f77aa566aa94bf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/69fbcd7eb60b4451a2f77aa566aa94bf.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6250,
          name: "The Family JP Mobile",
          uuid: "ad4c781d0af642e6b476d59baf2c6b9a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/ad4c781d0af642e6b476d59baf2c6b9a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6438,
          name: "The Family Mobile",
          uuid: "717d44c5ced028ce2ca52d1ed8b9d440b8e994b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/717d44c5ced028ce2ca52d1ed8b9d440b8e994b8.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6239,
          name: "The Family Part II",
          uuid: "3320646ccf5f4924a8ec4157b800e0ea",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/3320646ccf5f4924a8ec4157b800e0ea.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6240,
          name: "The Family Part II Mobile",
          uuid: "0f3d1100ec7f4544950cdc2d7c812811",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/0f3d1100ec7f4544950cdc2d7c812811.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6397,
          name: "The Forbidden Tomb",
          uuid: "6f9ef98785e7e0c3cb43c586b5e59095456891ddc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/6f9ef98785e7e0c3cb43c586b5e59095456891ddc.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6398,
          name: "The Forbidden Tomb Mobile",
          uuid: "fa894129ebc1d1003f41b8f1e596266868dbf87a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fa894129ebc1d1003f41b8f1e596266868dbf87a.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6319,
          name: "The Golden Games",
          uuid: "9dfd78391efc462284aaf7fd1c9c0c7c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/9dfd78391efc462284aaf7fd1c9c0c7c.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6320,
          name: "The Golden Games Mobile",
          uuid: "76f8ada06c4348a1b7fd6e31acd8445b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/76f8ada06c4348a1b7fd6e31acd8445b.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6331,
          name: "The Golden Inn",
          uuid: "191cc8002d3f42debd4fe26380686751",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/191cc8002d3f42debd4fe26380686751.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6332,
          name: "The Golden Inn Mobile",
          uuid: "10acd255d8754b51be9b1faabf783e72",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/10acd255d8754b51be9b1faabf783e72.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6345,
          name: "The Haunted Carnival",
          uuid: "c0aae058d03b4c08937f53f15807cd7b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/c0aae058d03b4c08937f53f15807cd7b.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6346,
          name: "The Haunted Carnival Mobile",
          uuid: "5c7ae760a6104478a4ef78554d3a8031",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/5c7ae760a6104478a4ef78554d3a8031.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6421,
          name: "The Haunted Inn",
          uuid: "eb208cefa9dfe5dd96036a3c9811cf1cffda9270",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/eb208cefa9dfe5dd96036a3c9811cf1cffda9270.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6422,
          name: "The Haunted Inn Mobile",
          uuid: "07e194c7c8345ca2a7273b564e47b4af0ee1da41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/07e194c7c8345ca2a7273b564e47b4af0ee1da41.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6307,
          name: "The Job",
          uuid: "712f3f30eb5b4ec6af6708e49bafab16",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/712f3f30eb5b4ec6af6708e49bafab16.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6308,
          name: "The Job Mobile",
          uuid: "07b08adeaa60433bb22c3a04a0530146",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/07b08adeaa60433bb22c3a04a0530146.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6299,
          name: "The Mad Genius",
          uuid: "07281c3146324b258ed5d1215a615511",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/07281c3146324b258ed5d1215a615511.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6300,
          name: "The Mad Genius Mobile",
          uuid: "10ed182cc1b142e8a4961c3987a2e234",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/10ed182cc1b142e8a4961c3987a2e234.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6241,
          name: "The Moguls",
          uuid: "e8814c22d1d44da0a358c1c1d954cd51",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/e8814c22d1d44da0a358c1c1d954cd51.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6242,
          name: "The Moguls Mobile",
          uuid: "4c2b45e382d34236ad6ec3d4d75d28fa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/4c2b45e382d34236ad6ec3d4d75d28fa.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6209,
          name: "The Party Guy",
          uuid: "43c500ea60614a8e9b2804056e42bc82",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/43c500ea60614a8e9b2804056e42bc82.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6210,
          name: "The Party Guy Mobile",
          uuid: "e71a899ca47242a692f32efb766545a5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/e71a899ca47242a692f32efb766545a5.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6445,
          name: "The Quest of Azteca",
          uuid: "021a64666da4ec9ae02b04dc0ea8ec5420e3246d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/021a64666da4ec9ae02b04dc0ea8ec5420e3246d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6446,
          name: "The Quest of Azteca Mobile",
          uuid: "4701ad84acad5b8c1bbaaa72660bfd04940b4790",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/4701ad84acad5b8c1bbaaa72660bfd04940b4790.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6221,
          name: "There's Gold Yonder",
          uuid: "841e384fe0e74e5ab9e4bc054dd88ed1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/841e384fe0e74e5ab9e4bc054dd88ed1.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6222,
          name: "There's Gold Yonder Mobile",
          uuid: "d929fa23c60e484ba21b6f1d7d9e6f9d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/d929fa23c60e484ba21b6f1d7d9e6f9d.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6367,
          name: "Three Card Rummy",
          uuid: "1d15f16e106341198c99ca59ef07a1c5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/1d15f16e106341198c99ca59ef07a1c5.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6368,
          name: "Three Card Rummy Mobile",
          uuid: "2cbaa5433a3c4a6ba7f854e8931ed8a6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/2cbaa5433a3c4a6ba7f854e8931ed8a6.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6227,
          name: "Totem Tumble",
          uuid: "a00cc50e0685426388cabcdbfb65a3bc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/a00cc50e0685426388cabcdbfb65a3bc.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6228,
          name: "Totem Tumble Mobile",
          uuid: "c67f93e5d9ae4cae9286dbe5cb55642b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/c67f93e5d9ae4cae9286dbe5cb55642b.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6315,
          name: "Totems Of Fortune",
          uuid: "6e3552b149d942ac9aa3545c031e909e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/6e3552b149d942ac9aa3545c031e909e.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6316,
          name: "Totems Of Fortune Mobile",
          uuid: "f6da18e563314d4980f8715c1876f53f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/f6da18e563314d4980f8715c1876f53f.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6455,
          name: "Touchdown Gold",
          uuid: "bd0926cf47af401eb3b89352af2d3b97",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/bd0926cf47af401eb3b89352af2d3b97.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6456,
          name: "Touchdown Gold Mobile",
          uuid: "cbf591910f8c48409cfbfafa55aa7e85",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/cbf591910f8c48409cfbfafa55aa7e85.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6383,
          name: "Triple Edge Poker",
          uuid: "824eef2c399d4cbb88fbb673c862cf8e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/824eef2c399d4cbb88fbb673c862cf8e.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6384,
          name: "Triple Edge Poker Mobile",
          uuid: "3362962fa6764ccbb6f28523ad516d29",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/3362962fa6764ccbb6f28523ad516d29.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6341,
          name: "Tropical Splash",
          uuid: "22ffd59030ad474aa170aa0acfaf5e0b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/22ffd59030ad474aa170aa0acfaf5e0b.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6342,
          name: "Tropical Splash Mobile",
          uuid: "146c7703528c4932a4994c67deebfdaa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/146c7703528c4932a4994c67deebfdaa.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6355,
          name: "Vegas Road Trip",
          uuid: "c6076f78d9474fd4b75a582631394a15",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/c6076f78d9474fd4b75a582631394a15.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6356,
          name: "Vegas Road Trip Mobile",
          uuid: "621d40e9ea6d4bcaac7b4f6fbf0bb8f9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/621d40e9ea6d4bcaac7b4f6fbf0bb8f9.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6393,
          name: "VIP European Roulette",
          uuid: "001c9c9e36874c3a8f1a99a8631af3d4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/001c9c9e36874c3a8f1a99a8631af3d4.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6394,
          name: "VIP European Roulette Mobile",
          uuid: "6b216b5ee76c4f3eb0fbc352d394b508",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/6b216b5ee76c4f3eb0fbc352d394b508.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6387,
          name: "War",
          uuid: "9b7521b24a5441998a949274cbe08576",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/9b7521b24a5441998a949274cbe08576.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6388,
          name: "War Mobile",
          uuid: "2f64b5be1c0b4321a12f62c3ecbe3dc0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/2f64b5be1c0b4321a12f62c3ecbe3dc0.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6467,
          name: "Wealth of Olympus",
          uuid: "0efa18b7684343bc83fb6caf5f64c0f2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/0efa18b7684343bc83fb6caf5f64c0f2.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6468,
          name: "Wealth of Olympus Mobile",
          uuid: "a0fed5c586224d4d9118417cc283a055",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/a0fed5c586224d4d9118417cc283a055.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6247,
          name: "Wild Adventures",
          uuid: "1e4eadfd06124704b4d2e44bcf248724",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/1e4eadfd06124704b4d2e44bcf248724.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6248,
          name: "Wild Adventures Mobile",
          uuid: "3df43ee72cc44f0eb1bc7e721d04a135",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/3df43ee72cc44f0eb1bc7e721d04a135.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6303,
          name: "Wild Cherry Blast",
          uuid: "379dbce6a51d4ec69b34975a770b5512",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/379dbce6a51d4ec69b34975a770b5512.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6304,
          name: "Wild Cherry Blast Mobile",
          uuid: "43b32f1564374b96b8b9f3cb8e8c15ce",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/43b32f1564374b96b8b9f3cb8e8c15ce.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6409,
          name: "Wings Of Victory",
          uuid: "d8ae954ed60af937e54968276db95a57d152bccf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d8ae954ed60af937e54968276db95a57d152bccf.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6410,
          name: "Wings Of Victory Mobile",
          uuid: "47d1d3f96e85d4132e60447d0029d7537652e76c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/47d1d3f96e85d4132e60447d0029d7537652e76c.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6429,
          name: "Wins Ahoy!",
          uuid: "d35f3c2f78a4356c786ec877e75518d13b503375",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/d35f3c2f78a4356c786ec877e75518d13b503375.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6430,
          name: "Wins Ahoy! Mobile",
          uuid: "a694ae038fcfc37a2e80ee968da587bee10ac1db",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Nucleus/a694ae038fcfc37a2e80ee968da587bee10ac1db.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6401,
          name: "Winter Champs",
          uuid: "898e778e8c4ea759dbe4f649aa2b643ca83ba249",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/898e778e8c4ea759dbe4f649aa2b643ca83ba249.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6402,
          name: "Winter Champs Mobile",
          uuid: "b6dcfb97fde66cad17255bd580662e518837a491",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b6dcfb97fde66cad17255bd580662e518837a491.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6447,
          name: "Wrath of Thor",
          uuid: "6f80c49311324dfbb4ffd193213b79e5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/6f80c49311324dfbb4ffd193213b79e5.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6448,
          name: "Wrath of Thor Mobile",
          uuid: "b5194fd6d1ff4d38ac017fc568addccb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/b5194fd6d1ff4d38ac017fc568addccb.png",
          type: "slots",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6395,
          name: "Zoom Roulette",
          uuid: "e0be22d154764b018b85d1317bb7c815",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/e0be22d154764b018b85d1317bb7c815.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6396,
          name: "Zoom Roulette Mobile",
          uuid: "4b4213db633e41b091578ccfdb4c60f5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Nucleus/4b4213db633e41b091578ccfdb4c60f5.png",
          type: "table",
          provider: "Nucleus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 135,
      name: "One Touch",
      providerId: 29,
      providerName: "OneTouch",
      games: [
        {
          game_id: 6509,
          name: "Andar Bahar",
          uuid: "d28c9fb3b6aca17e87cbcb9bfc0e29217dc7eceb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d28c9fb3b6aca17e87cbcb9bfc0e29217dc7eceb.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6510,
          name: "Andar Bahar Mobile",
          uuid: "5edb4b4931ca12fa3cb2df55060aea476bf1370e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5edb4b4931ca12fa3cb2df55060aea476bf1370e.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6597,
          name: "Angels and Demons",
          uuid: "4856993e274d46b58cbca29a9b80f301",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/4856993e274d46b58cbca29a9b80f301.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6598,
          name: "Angels and Demons Mobile",
          uuid: "e1158d36f02d4bc8991940477d6329ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/e1158d36f02d4bc8991940477d6329ec.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6525,
          name: "Baccarat",
          uuid: "913055a5124b715329aa1cb93bfb28d5a9f06b1c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/913055a5124b715329aa1cb93bfb28d5a9f06b1c.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6526,
          name: "Baccarat Mobile",
          uuid: "45fd30bf845d2970587512741c63e062c1a2fbef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/45fd30bf845d2970587512741c63e062c1a2fbef.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6519,
          name: "Baccarat No Commission",
          uuid: "55b147f965fc0729bae2883afe01d56f6ff4eb72",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/55b147f965fc0729bae2883afe01d56f6ff4eb72.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6520,
          name: "Baccarat No Commission Mobile",
          uuid: "6905eb8cbd13fa5058ffbf99fe2557143eb376bb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6905eb8cbd13fa5058ffbf99fe2557143eb376bb.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6495,
          name: "Baccarat Supreme",
          uuid: "dda68bb78a9f4b08c05b470dc81e41fa8fd615c0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dda68bb78a9f4b08c05b470dc81e41fa8fd615c0.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6496,
          name: "Baccarat Supreme Mobile",
          uuid: "25693dc2c225d2c2d7544c396d661bca65a101f3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/25693dc2c225d2c2d7544c396d661bca65a101f3.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6487,
          name: "Baccarat Supreme No Commission",
          uuid: "f7758288f5a7126ff0620d34528327290ea80360",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f7758288f5a7126ff0620d34528327290ea80360.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6488,
          name: "Baccarat Supreme No Commission Mobile",
          uuid: "91c55beda8f14f0863cb1312bce5cdf226548098",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/91c55beda8f14f0863cb1312bce5cdf226548098.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6618,
          name: "Baccarat Triple Treat",
          uuid: "bdb12fda3fbe49c835008dba9e94b2ffe47d1fa7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/bdb12fda3fbe49c835008dba9e94b2ffe47d1fa7.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6617,
          name: "Baccarat Triple Treat Mobile",
          uuid: "171d96069c8de76c13a99d4b00b826db90b18597",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/171d96069c8de76c13a99d4b00b826db90b18597.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6523,
          name: "Blackjack Classic",
          uuid: "66d5bba5dbe943616f477c71c4580535246861ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/66d5bba5dbe943616f477c71c4580535246861ae.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6524,
          name: "Blackjack Classic Mobile",
          uuid: "a6ef66fe06d01eed5c12b7e1ee0b0a072776ab44",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a6ef66fe06d01eed5c12b7e1ee0b0a072776ab44.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6517,
          name: "Blackjack Classic Perfect Pairs",
          uuid: "d39722a1c9dcda354e0bc1c1e23a07fc3df4740c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d39722a1c9dcda354e0bc1c1e23a07fc3df4740c.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6518,
          name: "Blackjack Classic Perfect Pairs Mobile",
          uuid: "4a36039b8c6b344d2b68fed5807b1fadc7ee8c69",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4a36039b8c6b344d2b68fed5807b1fadc7ee8c69.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6477,
          name: "Blackjack Supreme Multi Hand Perfect Pairs",
          uuid: "a355f1838e0684b4eceb98d0876ba450f830c6da",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a355f1838e0684b4eceb98d0876ba450f830c6da.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6478,
          name: "Blackjack Supreme Multi Hand Perfect Pairs Mobile",
          uuid: "b96f4d83eff9bf67c044ec6d4280bb8a986dec54",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b96f4d83eff9bf67c044ec6d4280bb8a986dec54.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6479,
          name: "Blackjack Supreme Single Hand Perfect Pairs",
          uuid: "04101f97265a92b44bd591af127330cac89be702",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/04101f97265a92b44bd591af127330cac89be702.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6480,
          name: "Blackjack Supreme Single Hand Perfect Pairs Mobile",
          uuid: "3aa8363d780994b520a16cdc71c344d1b0afdf45",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3aa8363d780994b520a16cdc71c344d1b0afdf45.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6591,
          name: "Bombay Blackjack",
          uuid: "4fe10bababcb7ef029f73e80623cf488ab787e69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/4fe10bababcb7ef029f73e80623cf488ab787e69.png",
          type: "blackjack",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6592,
          name: "Bombay Blackjack Mobile",
          uuid: "7a9e5d67847ed04345f6c02b8e8a4cb9dd30564b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/7a9e5d67847ed04345f6c02b8e8a4cb9dd30564b.png",
          type: "blackjack",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6581,
          name: "Bombay Roulette",
          uuid: "74776b6e0efd8049490ae5c6314d806efe73202e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/74776b6e0efd8049490ae5c6314d806efe73202e.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6582,
          name: "Bombay Roulette Mobile",
          uuid: "664eebc1812ba40cfe725358ec2f26fcc0e66818",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/664eebc1812ba40cfe725358ec2f26fcc0e66818.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6557,
          name: "Bonus Track",
          uuid: "0192b8a31a8d5086d1ebb0f8b7c6b653d46bed6f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0192b8a31a8d5086d1ebb0f8b7c6b653d46bed6f.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6558,
          name: "Bonus Track Mobile",
          uuid: "d0924ca168c607147047ba28d87e902f18f306cb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d0924ca168c607147047ba28d87e902f18f306cb.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6485,
          name: "Bubbles Bonanza",
          uuid: "e763327290bd22bc665a2fff7b2d60d9fe55127c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e763327290bd22bc665a2fff7b2d60d9fe55127c.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6486,
          name: "Bubbles Bonanza Mobile",
          uuid: "de9ad239d6940ea3d0816c9bb3720b4f810bc6dd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/de9ad239d6940ea3d0816c9bb3720b4f810bc6dd.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6571,
          name: "Cash Galaxy",
          uuid: "0a7b214a1cc81b4d5b494bfe71376f94030f3e80",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0a7b214a1cc81b4d5b494bfe71376f94030f3e80.png",
          type: "crash",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6572,
          name: "Cash Galaxy Mobile",
          uuid: "631bad519fec358b9cd25956fc8854f0d930562b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/631bad519fec358b9cd25956fc8854f0d930562b.png",
          type: "crash",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6609,
          name: "Christmas Grand Heist Feature Buy",
          uuid: "1acca9d5b48f4850817020a1cef8a108",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/1acca9d5b48f4850817020a1cef8a108.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6610,
          name: "Christmas Grand Heist Feature Buy Mobile",
          uuid: "e052d22597854b0d9186fd6ba517ec85",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/e052d22597854b0d9186fd6ba517ec85.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6611,
          name: "Colosseum Clash",
          uuid: "72388c5f18b4479b910bde81fbed6dc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/72388c5f18b4479b910bde81fbed6dc8.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6612,
          name: "Colosseum Clash Mobile",
          uuid: "a8322299031f48da84dcfc7d85570dbb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/a8322299031f48da84dcfc7d85570dbb.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6483,
          name: "Dragon Tiger",
          uuid: "404efaa68d461e4f9bf1f41eb5124e8cb819128f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/404efaa68d461e4f9bf1f41eb5124e8cb819128f.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6484,
          name: "Dragon Tiger Mobile",
          uuid: "527899fbbabd494533bc02a0df735123da25d21b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/527899fbbabd494533bc02a0df735123da25d21b.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6537,
          name: "Flexing Dragons",
          uuid: "d1cb3ecd9e14c4bf01f7cb76958e72c5abae569b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d1cb3ecd9e14c4bf01f7cb76958e72c5abae569b.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6538,
          name: "Flexing Dragons Mobile",
          uuid: "5572a5ac3700c8dc9a028496a13b46f4db401582",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5572a5ac3700c8dc9a028496a13b46f4db401582.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6543,
          name: "Forgotten Pharaoh",
          uuid: "ffa6df3ef2fc349259d90e4c20ee9b0318538087",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ffa6df3ef2fc349259d90e4c20ee9b0318538087.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6544,
          name: "Forgotten Pharaoh Mobile",
          uuid: "f6a05e2cce90e57b26480fc198832d27d0b0f293",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f6a05e2cce90e57b26480fc198832d27d0b0f293.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6493,
          name: "Fortune Miner",
          uuid: "a8a5d44101fcca28ad0f0eb04b6cc5890b58b8f4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a8a5d44101fcca28ad0f0eb04b6cc5890b58b8f4.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6494,
          name: "Fortune Miner Mobile",
          uuid: "53ff8d5a1dfc34c7628dd210b45dd30b9e2e5737",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/53ff8d5a1dfc34c7628dd210b45dd30b9e2e5737.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6545,
          name: "GanBaruto Battle",
          uuid: "72bf0d09f2f566463839308ea02881b5336271a5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/72bf0d09f2f566463839308ea02881b5336271a5.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6546,
          name: "GanBaruto Battle Mobile",
          uuid: "b548ee0725880523b6b56ef70f89a0e366f9e656",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b548ee0725880523b6b56ef70f89a0e366f9e656.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6567,
          name: "Genie's Secret",
          uuid: "3a55e0735f9ad1ee23966203c17fd695e04ade14",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3a55e0735f9ad1ee23966203c17fd695e04ade14.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6585,
          name: "Genie's Secret Feature Buy",
          uuid: "4c0ba4441149c29cde5a411b37ca27b2bb9a1efb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/4c0ba4441149c29cde5a411b37ca27b2bb9a1efb.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6586,
          name: "Genie's Secret Feature Buy Mobile",
          uuid: "e1b16178c8f4f566d9eece7dd0b9c4e807dbd442",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/e1b16178c8f4f566d9eece7dd0b9c4e807dbd442.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6568,
          name: "Genie's Secret Mobile",
          uuid: "24a48c59ea8a4f64eabf28c958aaef8579e79b5d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/24a48c59ea8a4f64eabf28c958aaef8579e79b5d.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6595,
          name: "Gifts of Horus",
          uuid: "3284417e00934131969319fc9c98bb8a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/3284417e00934131969319fc9c98bb8a.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6596,
          name: "Gifts of Horus Mobile",
          uuid: "7189e2828f694fcc93646b40914d0e81",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/7189e2828f694fcc93646b40914d0e81.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6593,
          name: "God Hand Feature Buy",
          uuid: "9121feaf449f4adcaf29211bf14eb0f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/9121feaf449f4adcaf29211bf14eb0f0.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6594,
          name: "God Hand Feature Buy Mobile",
          uuid: "be0e9cc122cb426d83fcadbd03abaaa6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/be0e9cc122cb426d83fcadbd03abaaa6.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6497,
          name: "Golden Stripe",
          uuid: "f3be3b43b3b920711c93cea59b8d5c6939b4a744",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f3be3b43b3b920711c93cea59b8d5c6939b4a744.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6498,
          name: "Golden Stripe Mobile",
          uuid: "9e427e5075a8a768f5e05cb0034e40b52d41226c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9e427e5075a8a768f5e05cb0034e40b52d41226c.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6551,
          name: "Grand Heist",
          uuid: "18e237b7814a3dfc1aa982be8d82e28f74f68c96",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/18e237b7814a3dfc1aa982be8d82e28f74f68c96.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6575,
          name: "Grand Heist Feature Buy",
          uuid: "d486095ce07a76279cfb3f73a09e9ce033226d08",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/d486095ce07a76279cfb3f73a09e9ce033226d08.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6576,
          name: "Grand Heist Feature Buy Mobile",
          uuid: "a37fa193417b0a14cbf7ab4d3b7937a4ec0a87eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/a37fa193417b0a14cbf7ab4d3b7937a4ec0a87eb.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6552,
          name: "Grand Heist Mobile",
          uuid: "9163e97a92fc41022b0aca6985943dc7d42a09f4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9163e97a92fc41022b0aca6985943dc7d42a09f4.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6605,
          name: "Halloween Hunt",
          uuid: "b589cec6c90b46a1812c69195ada0fba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/b589cec6c90b46a1812c69195ada0fba.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6606,
          name: "Halloween Hunt Mobile",
          uuid: "8084e03076124722922ca1502626ce1f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/8084e03076124722922ca1502626ce1f.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6503,
          name: "High Hand Hold`em Poker",
          uuid: "71915624f2a0f411e3d02c2f2d407a0cf980ed17",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/71915624f2a0f411e3d02c2f2d407a0cf980ed17.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6504,
          name: "High Hand Hold`em Poker Mobile",
          uuid: "19c0002e940662f4c9390bfd6b117e88194f7985",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/19c0002e940662f4c9390bfd6b117e88194f7985.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6527,
          name: "Hold’em Poker",
          uuid: "b753a3c19b2cd72ad9b9cd0399b3f2440f0331d4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b753a3c19b2cd72ad9b9cd0399b3f2440f0331d4.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6528,
          name: "Hold’em Poker Mobile",
          uuid: "24e4f91f4da782d9b97639cd9d4bb6e816a8bc6a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/24e4f91f4da782d9b97639cd9d4bb6e816a8bc6a.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6505,
          name: "In Between Poker",
          uuid: "ca112305a2cca481643016b498784f5287573bd6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ca112305a2cca481643016b498784f5287573bd6.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6506,
          name: "In Between Poker Mobile",
          uuid: "2d12b61559282783ae6663119604b321f600a364",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2d12b61559282783ae6663119604b321f600a364.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6511,
          name: "Juicy 7",
          uuid: "5e17cd48d3eaea2018441fcecc90366b5a3fd77c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5e17cd48d3eaea2018441fcecc90366b5a3fd77c.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6512,
          name: "Juicy 7 Mobile",
          uuid: "b8b4a7f5085250e0e06fd846a7e3825ba8611d49",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b8b4a7f5085250e0e06fd846a7e3825ba8611d49.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6607,
          name: "King of Mafia",
          uuid: "14a865df1e08a94da0a7cf552c19a649b8395689",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/14a865df1e08a94da0a7cf552c19a649b8395689.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6608,
          name: "King of Mafia Mobile",
          uuid: "52c614ab12352cdc4ef967bdc17ff5164ea01cad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/52c614ab12352cdc4ef967bdc17ff5164ea01cad.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6569,
          name: "Lady Fortuna",
          uuid: "4b381ef501820299d92aaade145b2e5b8ad7af57",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4b381ef501820299d92aaade145b2e5b8ad7af57.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6570,
          name: "Lady Fortuna Mobile",
          uuid: "e32b6351d4fcc4029c893413b3c1c6f750ba1ddf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e32b6351d4fcc4029c893413b3c1c6f750ba1ddf.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6632,
          name: "Legends Cup",
          uuid: "24ab6c4ed801b9dfe6326a6eae9b69ef951b410d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/24ab6c4ed801b9dfe6326a6eae9b69ef951b410d.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6631,
          name: "Legends Cup Mobile",
          uuid: "4efee9ef17b638d3612106067962f5e5254c3124",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/4efee9ef17b638d3612106067962f5e5254c3124.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6547,
          name: "Loot or Boot",
          uuid: "7d5c724c1bf2155632b3319e2c24a4b4c651868c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7d5c724c1bf2155632b3319e2c24a4b4c651868c.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6548,
          name: "Loot or Boot Mobile",
          uuid: "4bac02760e2c5b6b3afa098c8db99a59899136fa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4bac02760e2c5b6b3afa098c8db99a59899136fa.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6501,
          name: "Lucky Lion",
          uuid: "c5f20442e85e25e8f4b9b67d923c66a1f2003860",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c5f20442e85e25e8f4b9b67d923c66a1f2003860.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6502,
          name: "Lucky Lion Mobile",
          uuid: "ccc3d61580b7d43b04a9af5ed007d03ea2a9d7d9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ccc3d61580b7d43b04a9af5ed007d03ea2a9d7d9.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6573,
          name: "Miko Festival",
          uuid: "a811e31871752c7cd92bc9501ca92647c6c4830d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/a811e31871752c7cd92bc9501ca92647c6c4830d.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6615,
          name: "Miko Festival Feature Buy",
          uuid: "990843c29ab6edf6e85919ce4b1a507fb2235547",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/990843c29ab6edf6e85919ce4b1a507fb2235547.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6616,
          name: "Miko Festival Feature Buy Mobile",
          uuid: "1f90d5ce31a4ade9ea6233574472c56f2605de50",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/1f90d5ce31a4ade9ea6233574472c56f2605de50.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6574,
          name: "Miko Festival Mobile",
          uuid: "b504941b27dee194357f103b711f4dfa1386267d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/b504941b27dee194357f103b711f4dfa1386267d.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6623,
          name: "Mini Baccarat",
          uuid: "80898f84977647a5ab4ee27520097eee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/80898f84977647a5ab4ee27520097eee.png",
          type: "minigame",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6628,
          name: "Mini Baccarat Mobile",
          uuid: "a5d9ba2045c644bdbe73b39f05fb90a7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/a5d9ba2045c644bdbe73b39f05fb90a7.png",
          type: "minigame",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6621,
          name: "Mini Blackjack",
          uuid: "879fde8318d5413a85e14a22cbbe358a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/879fde8318d5413a85e14a22cbbe358a.png",
          type: "mini game",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6626,
          name: "Mini Blackjack Mobile",
          uuid: "13fefa62ee5243b29f83ca56631d61ca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/13fefa62ee5243b29f83ca56631d61ca.png",
          type: "mini game",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6620,
          name: "Mini Hi-Lo",
          uuid: "11f3d587d8504b39bede7ac00169fe0f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/11f3d587d8504b39bede7ac00169fe0f.png",
          type: "mini game",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6625,
          name: "Mini Hi-Lo Mobile",
          uuid: "387b2fcaf0e949ae95576cd081e1e42b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/387b2fcaf0e949ae95576cd081e1e42b.png",
          type: "mini game",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6619,
          name: "Mini Plinko ",
          uuid: "21e3de036a034d8399315ffc4a02b479",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/21e3de036a034d8399315ffc4a02b479.png",
          type: "mini game",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6624,
          name: "Mini Plinko  Mobile",
          uuid: "a44e5bd886ce4d93a2d719e118b78e87",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/a44e5bd886ce4d93a2d719e118b78e87.png",
          type: "mini game",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6622,
          name: "Mini Roulette",
          uuid: "76eaf8c4978048d78653591a53b0c614",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/76eaf8c4978048d78653591a53b0c614.png",
          type: "mini game",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6627,
          name: "Mini Roulette Mobile",
          uuid: "464f63cfcdc64c169c34b6f0d2291a7f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/464f63cfcdc64c169c34b6f0d2291a7f.png",
          type: "mini game",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6599,
          name: "MoMoMoolah",
          uuid: "e3bdb39b30e94fdaa5acb48e992d1b4c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/e3bdb39b30e94fdaa5acb48e992d1b4c.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6600,
          name: "MoMoMoolah Mobile",
          uuid: "eca61c6553024ef2b2477ce6d4a16560",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/eca61c6553024ef2b2477ce6d4a16560.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6541,
          name: "MVP Hoops",
          uuid: "e1a5a01a6f44ccd4864433bae7148b9003f530af",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e1a5a01a6f44ccd4864433bae7148b9003f530af.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6542,
          name: "MVP Hoops Mobile",
          uuid: "ad75e0f3d468db22803d27fe3dc19343cdc1ba43",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ad75e0f3d468db22803d27fe3dc19343cdc1ba43.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6533,
          name: "Neon 2077",
          uuid: "c247e7734f1a51891672e646257262393ad1b405",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c247e7734f1a51891672e646257262393ad1b405.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6534,
          name: "Neon 2077 Mobile",
          uuid: "56c1f5d0739539c4b46203c52528518b2696e965",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/56c1f5d0739539c4b46203c52528518b2696e965.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6630,
          name: "Pawsome Plinko",
          uuid: "c0e289e796dd9103e8a2540e985a7b5c1893ae7d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/c0e289e796dd9103e8a2540e985a7b5c1893ae7d.png",
          type: "other",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6629,
          name: "Pawsome Plinko Mobile",
          uuid: "8d0639ce0a5d49aab1754d88b545791b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/8d0639ce0a5d49aab1754d88b545791b.png",
          type: "other",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6549,
          name: "Pip's Quest",
          uuid: "53be96cf460dca9c0486eb342056e925019b4e45",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/53be96cf460dca9c0486eb342056e925019b4e45.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6550,
          name: "Pip's Quest Mobile",
          uuid: "3789d31ec7fdf8660b841463e4c476f431bb946f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3789d31ec7fdf8660b841463e4c476f431bb946f.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6531,
          name: "Queens of Glory",
          uuid: "e561df170d6ce2a4420d02c22ac54d8991615fb6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e561df170d6ce2a4420d02c22ac54d8991615fb6.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6565,
          name: "Queens of Glory Legacy",
          uuid: "a3e0fd34b3fe309e43306cf93a230fc94836fb3e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a3e0fd34b3fe309e43306cf93a230fc94836fb3e.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6566,
          name: "Queens of Glory Legacy Mobile",
          uuid: "5f423dda2483d89d639b66726d066a25ce4a82fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5f423dda2483d89d639b66726d066a25ce4a82fb.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6532,
          name: "Queens of Glory Mobile",
          uuid: "70cdd2e15b8cf001790352aa41fe5cf42c4ed051",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/70cdd2e15b8cf001790352aa41fe5cf42c4ed051.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6563,
          name: "Raiden Medal Burst",
          uuid: "aedec2556746a9f37bb9e1e2f01dda20f3ead5ef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aedec2556746a9f37bb9e1e2f01dda20f3ead5ef.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6564,
          name: "Raiden Medal Burst Mobile",
          uuid: "daf30662b42445ad3b2279daa0c49b2bbcb22b43",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/daf30662b42445ad3b2279daa0c49b2bbcb22b43.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6579,
          name: "Reel Fruitz Frenzy",
          uuid: "c75f2c10ed5cd6acdabb8c90f973c0b18271cbdc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/c75f2c10ed5cd6acdabb8c90f973c0b18271cbdc.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6580,
          name: "Reel Fruitz Frenzy Mobile",
          uuid: "8d207a8ab9bab44465f157f815b5f13bbe4fdc1e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/8d207a8ab9bab44465f157f815b5f13bbe4fdc1e.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6515,
          name: "Roulette",
          uuid: "25aa3cf6dab045f516877fd46c4bc5d5fad1b9ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/25aa3cf6dab045f516877fd46c4bc5d5fad1b9ae.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6516,
          name: "Roulette Mobile",
          uuid: "c30720f1bdb986332e4415c2b6eddcae1ee2c8b4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c30720f1bdb986332e4415c2b6eddcae1ee2c8b4.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6521,
          name: "Russian Poker",
          uuid: "286c55c640e55ad7d89492445acc757658bf6698",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/286c55c640e55ad7d89492445acc757658bf6698.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6522,
          name: "Russian Poker Mobile",
          uuid: "dee3bc37bf720574b9fc16c6f5ec37fa96c444ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dee3bc37bf720574b9fc16c6f5ec37fa96c444ae.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6577,
          name: "Ryse of Rome",
          uuid: "4ea997063750412e94b13c07104d5341",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/4ea997063750412e94b13c07104d5341.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6578,
          name: "Ryse of Rome Mobile",
          uuid: "9dc61df14381471492bb4f7eadb7da6b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/9dc61df14381471492bb4f7eadb7da6b.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6589,
          name: "Ryse of the Mighty Gods",
          uuid: "0d7e9ecbd75c41b8ad8b89288f39b130",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/0d7e9ecbd75c41b8ad8b89288f39b130.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6590,
          name: "Ryse of the Mighty Gods Mobile",
          uuid: "2dc8ac0ba027499c958a24e429dc13d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/2dc8ac0ba027499c958a24e429dc13d7.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6561,
          name: "Sea Treasure",
          uuid: "c3f054c3b303564593c9b5f01f67ac88e5403b35",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c3f054c3b303564593c9b5f01f67ac88e5403b35.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6587,
          name: "Sea Treasure Deep Dive",
          uuid: "a253edd16672441cbe9a341ba40ef0bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/a253edd16672441cbe9a341ba40ef0bb.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6588,
          name: "Sea Treasure Deep Dive Mobile",
          uuid: "2dd3ff4bc7dd40cdb4b43027702eb995",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/2dd3ff4bc7dd40cdb4b43027702eb995.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6562,
          name: "Sea Treasure Mobile",
          uuid: "fde22d32eb3c48ebb8eb4c8c5b1e6d216a233c40",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fde22d32eb3c48ebb8eb4c8c5b1e6d216a233c40.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6529,
          name: "Sic Bo",
          uuid: "d73696022c9562df90f22220b3b43d3953d1f1c3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d73696022c9562df90f22220b3b43d3953d1f1c3.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6530,
          name: "Sic Bo Mobile",
          uuid: "1e6d7af28f4e55c642c1fb4d70a26e5f9674c45b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1e6d7af28f4e55c642c1fb4d70a26e5f9674c45b.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6555,
          name: "Snack Blast",
          uuid: "e12d70288aa1e07ed71d1be31f8b015b73f21115",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e12d70288aa1e07ed71d1be31f8b015b73f21115.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6556,
          name: "Snack Blast Mobile",
          uuid: "98ab18537cf996f2674b8620e39fdac76813f7d4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/98ab18537cf996f2674b8620e39fdac76813f7d4.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6491,
          name: "Steam Vault",
          uuid: "bb211b3a092ad62a100b1ffc7cb268a33e8aa929",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bb211b3a092ad62a100b1ffc7cb268a33e8aa929.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6492,
          name: "Steam Vault Mobile",
          uuid: "a370f1cc72680f975b677cae35d90df0a764e0a1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a370f1cc72680f975b677cae35d90df0a764e0a1.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6559,
          name: "Stone Gaze",
          uuid: "c5e6a08f92c26b18d8716c83daa7978d8d63ebb2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c5e6a08f92c26b18d8716c83daa7978d8d63ebb2.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6560,
          name: "Stone Gaze Mobile",
          uuid: "1b084cfc6261e86c0e7a472110107140c2f4d5df",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1b084cfc6261e86c0e7a472110107140c2f4d5df.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6507,
          name: "Sumo Showdown",
          uuid: "321155ee68c6c324a56142f120ea31fd0ce10934",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/321155ee68c6c324a56142f120ea31fd0ce10934.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6508,
          name: "Sumo Showdown Mobile",
          uuid: "e92862ee1717b948d12e050c48091652b5fa09b9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e92862ee1717b948d12e050c48091652b5fa09b9.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6553,
          name: "Teen Patti 20-20",
          uuid: "7bcc5e3ecb8c2de6e1f259c30d8e7c9b1281698f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7bcc5e3ecb8c2de6e1f259c30d8e7c9b1281698f.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6554,
          name: "Teen Patti 20-20 Mobile",
          uuid: "76548ed35c4787774732eccb8317e8b4f77a074d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/76548ed35c4787774732eccb8317e8b4f77a074d.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6535,
          name: "Tiki Terror",
          uuid: "55b976cd8352e8f643b4f2c02e6b57c96908757d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/55b976cd8352e8f643b4f2c02e6b57c96908757d.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6536,
          name: "Tiki Terror Mobile",
          uuid: "64ef24443961dc313a4a7709ffb0bb6fd579702f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/64ef24443961dc313a4a7709ffb0bb6fd579702f.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6603,
          name: "Tonatiuh's Legacy",
          uuid: "c14cd607081f441c9168d0fb1b4487d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/c14cd607081f441c9168d0fb1b4487d7.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6604,
          name: "Tonatiuh's Legacy Mobile",
          uuid: "49ceebcccac743e2a001f01b6f38b54e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/49ceebcccac743e2a001f01b6f38b54e.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6481,
          name: "Traveling Treasures India",
          uuid: "3eeb96fd1198e884295b39c5d04d27c123f5e97f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3eeb96fd1198e884295b39c5d04d27c123f5e97f.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6482,
          name: "Traveling Treasures India Mobile",
          uuid: "24beec8a79265d89618d18b9fcc1d4719cbf2a54",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/24beec8a79265d89618d18b9fcc1d4719cbf2a54.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6489,
          name: "Wacky Wildlife",
          uuid: "f16cd7a9305f3ebff868de6a82763a41b5b34d9d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f16cd7a9305f3ebff868de6a82763a41b5b34d9d.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6490,
          name: "Wacky Wildlife Mobile",
          uuid: "021946204972328517e7adf2b31ffe417a1bd637",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/021946204972328517e7adf2b31ffe417a1bd637.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6499,
          name: "Watford FC Slot",
          uuid: "2e52c17d3ca4cc9c0067da85ea256a90ac464461",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2e52c17d3ca4cc9c0067da85ea256a90ac464461.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6500,
          name: "Watford FC Slot Mobile",
          uuid: "8294909b680e8644c12f801a52e6b61a27a582d0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8294909b680e8644c12f801a52e6b61a27a582d0.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6613,
          name: "Wealthy Sharks",
          uuid: "60b08da5508f45ef83b115e563aeb49f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/60b08da5508f45ef83b115e563aeb49f.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6614,
          name: "Wealthy Sharks Mobile",
          uuid: "3cf18f1048ab448bac33655ba3f0dde9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/3cf18f1048ab448bac33655ba3f0dde9.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6513,
          name: "Wheel of Fortune",
          uuid: "f9d0b7627d0fd3027cc0ae6ecd1bab156ab65f0a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f9d0b7627d0fd3027cc0ae6ecd1bab156ab65f0a.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6514,
          name: "Wheel of Fortune Mobile",
          uuid: "3aa55b1efcf0ed8eccc264b12f7a94a816047e3c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3aa55b1efcf0ed8eccc264b12f7a94a816047e3c.png",
          type: "table",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6583,
          name: "Wild Coyote Megaways",
          uuid: "fa78c33f769f3c0a3bbbd6ba6352f275e76e734f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/fa78c33f769f3c0a3bbbd6ba6352f275e76e734f.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6584,
          name: "Wild Coyote Megaways Mobile",
          uuid: "efc596ee808d24f6ea6a805800174a4d9176bc09",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/efc596ee808d24f6ea6a805800174a4d9176bc09.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6475,
          name: "Wild Sorcery",
          uuid: "2da31836e76d6a5bf8e73e10f150893f2d378226",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2da31836e76d6a5bf8e73e10f150893f2d378226.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6476,
          name: "Wild Sorcery Mobile",
          uuid: "91668d7fb8c3e2138b25f117980843f3931ce422",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/91668d7fb8c3e2138b25f117980843f3931ce422.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6601,
          name: "Wild Voodoo",
          uuid: "b8e32eb3420b4070b1578a8d14f1a84e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/b8e32eb3420b4070b1578a8d14f1a84e.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6602,
          name: "Wild Voodoo Mobile",
          uuid: "8bbb1102b29240b28778d5fd3c6c3ffa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/OneTouch/8bbb1102b29240b28778d5fd3c6c3ffa.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6539,
          name: "Wild Wild West 2120",
          uuid: "e13bd51658c258220341e348179ac2b8d7f4ad1f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e13bd51658c258220341e348179ac2b8d7f4ad1f.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6540,
          name: "Wild Wild West 2120 Mobile",
          uuid: "efcde9f7ec37c127f298d67956e689f67912e321",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/efcde9f7ec37c127f298d67956e689f67912e321.png",
          type: "slots",
          provider: "OneTouch",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 136,
      name: "PGSoft",
      providerId: 18,
      providerName: "PGSoft",
      games: [
        {
          game_id: 729,
          name: "Bakery Bonanza",
          uuid: "3ddad6f2c02d440f8bf53142ecdb366c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3ddad6f2c02d440f8bf53142ecdb366c.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 730,
          name: "Bali Vacation",
          uuid: "d8e338a5620045dcaced240472e9ed85",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/d8e338a5620045dcaced240472e9ed85.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 731,
          name: "Battleground Royale",
          uuid: "40fc2babe06644d4a7cc3621d2520fac",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/40fc2babe06644d4a7cc3621d2520fac.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 732,
          name: "Bikini Paradise",
          uuid: "cbc35769f4f7465f84731d795933df13",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/cbc35769f4f7465f84731d795933df13.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 733,
          name: "Buffalo Win",
          uuid: "6bb4240b90ce4f0caadf789f86eb3395",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/6bb4240b90ce4f0caadf789f86eb3395.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 734,
          name: "Butterfly Blossom",
          uuid: "ff6af7041daa4a64b6267902f7946e54",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/ff6af7041daa4a64b6267902f7946e54.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 735,
          name: "Candy Bonanza",
          uuid: "d7ac004af593449495f45b79ad1457ba",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/d7ac004af593449495f45b79ad1457ba.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 736,
          name: "Captains Bounty",
          uuid: "028900c63ecd4b12a33ab1ea350e8cb9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/028900c63ecd4b12a33ab1ea350e8cb9.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 737,
          name: "Cocktail Nights",
          uuid: "5d08dbcdbab34449b0fb440a0d9ade28",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/5d08dbcdbab34449b0fb440a0d9ade28.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 738,
          name: "Crypto Gold",
          uuid: "609eb5e905594b84a33261da9109e4d7",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/609eb5e905594b84a33261da9109e4d7.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 739,
          name: "Destiny of Sun & Moon",
          uuid: "c22881d0cf1043999f1608286a2a25eb",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/c22881d0cf1043999f1608286a2a25eb.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 741,
          name: "Double Fortune",
          uuid: "05cc79e6145f4ea1947fe3c38d8ca076",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/05cc79e6145f4ea1947fe3c38d8ca076.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 742,
          name: "Dragon Hatch",
          uuid: "8459dd643efb4aba8dd2e02f3c974258",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/8459dd643efb4aba8dd2e02f3c974258.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 743,
          name: "Dreams of Macau",
          uuid: "2c14e4f28bf74f22bbbd5ceda7e48d53",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/2c14e4f28bf74f22bbbd5ceda7e48d53.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 744,
          name: "Emoji Riches",
          uuid: "9e9e7e07e5854f13848bb3a4fc087a34",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9e9e7e07e5854f13848bb3a4fc087a34.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 745,
          name: "Fortune Mouse",
          uuid: "19a513973985443e82e374fc37a6442e",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/19a513973985443e82e374fc37a6442e.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 746,
          name: "Fortune OX",
          uuid: "4fb87b09f27e49eeb29406bed87ceb3c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/4fb87b09f27e49eeb29406bed87ceb3c.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 747,
          name: "Fortune Rabbit",
          uuid: "8ea3603dd8e84a8fa5b7590592beb25d",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/8ea3603dd8e84a8fa5b7590592beb25d.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 748,
          name: "Fortune Tiger",
          uuid: "cad776b0bd2a44d4bd1a7fff6390c145",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/cad776b0bd2a44d4bd1a7fff6390c145.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 749,
          name: "Fruit Candy",
          uuid: "7e2d78f1fc6946f88ecd42aa91ddce53",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/7e2d78f1fc6946f88ecd42aa91ddce53.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 750,
          name: "Galactic Gems",
          uuid: "a25bde10cfe84329a7abd08ecbfc6194",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/a25bde10cfe84329a7abd08ecbfc6194.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 751,
          name: "Ganesha Fortune",
          uuid: "5ee626ce7a3b4db7af77bd53e9a98ee1",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/5ee626ce7a3b4db7af77bd53e9a98ee1.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 752,
          name: "Ganesha Gold",
          uuid: "c872956d300444229ccf85a1318f5329",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/c872956d300444229ccf85a1318f5329.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 753,
          name: "Garuda Gems",
          uuid: "f45246e4c38a4052a2f09a576a657572",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/f45246e4c38a4052a2f09a576a657572.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 754,
          name: "Guardians of Ice & Fire",
          uuid: "0642135cffd94a56b7fee21de59802da",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/0642135cffd94a56b7fee21de59802da.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 755,
          name: "Hawaiian Tiki",
          uuid: "17b50683aefd46e9b508ad34d10a5e79",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/17b50683aefd46e9b508ad34d10a5e79.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 756,
          name: "Heist Stakes",
          uuid: "300630120c3a4292a7b1f03c05939c06",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/300630120c3a4292a7b1f03c05939c06.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 757,
          name: "Jack Frost’s Winter",
          uuid: "4e933341da424b989f8eb58fb991cd8a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/4e933341da424b989f8eb58fb991cd8a.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 758,
          name: "Jewels of Prosperity",
          uuid: "9fcb4fc950c94c1da9a9227d7211a14a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9fcb4fc950c94c1da9a9227d7211a14a.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 759,
          name: "Jungle Delight",
          uuid: "d601cadc9d714455880d78beb1022512",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/d601cadc9d714455880d78beb1022512.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 760,
          name: "Jurassic Kingdom",
          uuid: "59195295bb364b02bbe83b50e77896c9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/59195295bb364b02bbe83b50e77896c9.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 761,
          name: "Legendary Monkey King",
          uuid: "e40af84971df40dea0d737c69cfc6ce7",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/e40af84971df40dea0d737c69cfc6ce7.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 762,
          name: "Leprechaun Riches",
          uuid: "da49fff4398642ccbc6d4f37fc76ec98",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/da49fff4398642ccbc6d4f37fc76ec98.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 763,
          name: "Lucky Clover Lady",
          uuid: "eb5cbd364ebe4608aba080060f8bf090",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/eb5cbd364ebe4608aba080060f8bf090.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 764,
          name: "Lucky Neko",
          uuid: "8fcd7247e5834f7bab7ac395ca670df8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/8fcd7247e5834f7bab7ac395ca670df8.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 765,
          name: "Lucky Piggy",
          uuid: "9843c63a026548fe81202a423c989b7a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9843c63a026548fe81202a423c989b7a.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 766,
          name: "Mahjong Ways",
          uuid: "5549efbf91194285bd797697598408df",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/5549efbf91194285bd797697598408df.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 767,
          name: "Mahjong Ways 2",
          uuid: "3a0e35201a734a0693459ac86c0345b8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3a0e35201a734a0693459ac86c0345b8.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 768,
          name: "Majestic Treasures",
          uuid: "63f502b9b90d45b684483238fcddcec9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/63f502b9b90d45b684483238fcddcec9.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 769,
          name: "Mask Carnival",
          uuid: "783dcc2a55c44ef5a9e860f28f9302cf",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/783dcc2a55c44ef5a9e860f28f9302cf.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 770,
          name: "Mermaid Riches",
          uuid: "ce13d02c1ef947fdae234e8477bc800a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/ce13d02c1ef947fdae234e8477bc800a.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 771,
          name: "Midas Fortune",
          uuid: "11a34d3d465947dfb121ebb20553f6f1",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/11a34d3d465947dfb121ebb20553f6f1.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 772,
          name: "Mystical Spirits",
          uuid: "9a144aed9c0d499ab3d4e4470e0b5232",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9a144aed9c0d499ab3d4e4470e0b5232.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 773,
          name: "Opera Dynasty",
          uuid: "3e63c46572e3440a93ad8a0258bf78ed",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3e63c46572e3440a93ad8a0258bf78ed.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 774,
          name: "Oriental Prosperity",
          uuid: "42b6bb37e5de459d9050cb4066b580f0",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/42b6bb37e5de459d9050cb4066b580f0.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 719,
          name: "Phoenix Rises",
          uuid: "ef4a4ba465544465adf7a8ea75aad960",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/ef4a4ba465544465adf7a8ea75aad960.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 720,
          name: "Piggy Gold",
          uuid: "41704e8c86174999a0405dad23ebe6e0",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/41704e8c86174999a0405dad23ebe6e0.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 721,
          name: "Prosperity Fortune Tree",
          uuid: "9ba5441a20074564a3f43e067830521a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9ba5441a20074564a3f43e067830521a.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 722,
          name: "Raider Jane’s Crypt of Fortune",
          uuid: "71d0180ca7054f95bd8d9a5690df0deb",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/71d0180ca7054f95bd8d9a5690df0deb.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 723,
          name: "Rave Party Fever",
          uuid: "9109d5a3811f4b4d9763a43177f128f1",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9109d5a3811f4b4d9763a43177f128f1.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 724,
          name: "Rise of Apollo",
          uuid: "7f99611b2d1346cca91bfb7e1751c2fa",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/7f99611b2d1346cca91bfb7e1751c2fa.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 725,
          name: "Rooster Rumble",
          uuid: "a518d5c1dccb41179f6777c2960adb1a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/a518d5c1dccb41179f6777c2960adb1a.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 726,
          name: "Songkran Splash",
          uuid: "3fb1a302eca74c14b44fc508f42af574",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3fb1a302eca74c14b44fc508f42af574.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 727,
          name: "Speed Winner",
          uuid: "38662e49afe8437bbdaef0377226b600",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/38662e49afe8437bbdaef0377226b600.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 728,
          name: "Spirited Wonders",
          uuid: "e2f2f35a22d34460bc7ebac4818122cd",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/e2f2f35a22d34460bc7ebac4818122cd.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 868,
          name: "Super Golf Drive",
          uuid: "973f6c33352647b3a9d9bb7f26091940",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/973f6c33352647b3a9d9bb7f26091940.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 869,
          name: "Supermarket Spree",
          uuid: "6ad03a82288e435e93b75175c3929012",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/6ad03a82288e435e93b75175c3929012.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 870,
          name: "Thai River Wonders",
          uuid: "3af38713b1ec439496383e721eac8a76",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3af38713b1ec439496383e721eac8a76.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 871,
          name: "The Great Icescape",
          uuid: "e98c0dd778594bdfbc9f9b30a32d1d90",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/e98c0dd778594bdfbc9f9b30a32d1d90.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 872,
          name: "The Queen’s Banquet",
          uuid: "833a181b68684eba95718cfb4f3bd23d",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/833a181b68684eba95718cfb4f3bd23d.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 873,
          name: "Totem Wonders",
          uuid: "c623d8e81d5a40abb97f5fb74f1e78e8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/c623d8e81d5a40abb97f5fb74f1e78e8.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 874,
          name: "Treasures Of Aztec",
          uuid: "a1fb63e00e7d4ab88e98daeff5a52aaf",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/a1fb63e00e7d4ab88e98daeff5a52aaf.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 875,
          name: "Vampire’s Charm",
          uuid: "cc3ea067c19f4fbd8c5f7dc68282d9e8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/cc3ea067c19f4fbd8c5f7dc68282d9e8.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 876,
          name: "Ways of the Qilin",
          uuid: "5bab76cce2cb43e4a19819b66c99cdd3",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/5bab76cce2cb43e4a19819b66c99cdd3.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 877,
          name: "Wild Bandito",
          uuid: "4aa4ec1698704e52ba4890ce1f98d083",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/4aa4ec1698704e52ba4890ce1f98d083.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 878,
          name: "Wild Bounty Showdown",
          uuid: "f02ebf1aa06149fc8e8c07197ab1fb29",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/f02ebf1aa06149fc8e8c07197ab1fb29.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 879,
          name: "Wild Coaster",
          uuid: "b5ea7407bc9c43ca9855287978ef4f2c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/b5ea7407bc9c43ca9855287978ef4f2c.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 880,
          name: "Wild Fireworks",
          uuid: "c86abd5b024a49bfb71ada7fa66a61c4",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/c86abd5b024a49bfb71ada7fa66a61c4.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 881,
          name: "Win Win Fish Prawn Crab",
          uuid: "f24e62e6dce344fdb7469ff9c0c85cd2",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/f24e62e6dce344fdb7469ff9c0c85cd2.png",
          type: "slots",
          provider: "PGSoft",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 137,
      name: "PangaGames",
      providerId: 58,
      providerName: "PangaGames",
      games: [
        {
          game_id: 6639,
          name: "Candy Castle",
          uuid: "efdc2d8a801a4fa0bdb12e8eeb04a36a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/efdc2d8a801a4fa0bdb12e8eeb04a36a.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6634,
          name: "Cowboy Jack",
          uuid: "7962425928b04583b1614d986cdaa918",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/7962425928b04583b1614d986cdaa918.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6636,
          name: "Crazy Pirates",
          uuid: "c03563dec4334035a3c4ef50e33864e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/c03563dec4334035a3c4ef50e33864e9.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6644,
          name: "Frutta Stuff",
          uuid: "b6d0785bf0974a4781cb5ea1a33d6d06",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/b6d0785bf0974a4781cb5ea1a33d6d06.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6633,
          name: "Geisha",
          uuid: "ff0b1bdebd134e0ebb53dbcfd85efbc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/ff0b1bdebd134e0ebb53dbcfd85efbc8.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6635,
          name: "Golden Egypt",
          uuid: "3f2503edb7004a3f8b0a74fea99c202c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/3f2503edb7004a3f8b0a74fea99c202c.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6640,
          name: "Green Magic",
          uuid: "b54590c734484794a48407d54ad5dfa8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/b54590c734484794a48407d54ad5dfa8.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6638,
          name: "Happy Ocean",
          uuid: "9ae39540490a4debb1073ec9c6c02458",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/9ae39540490a4debb1073ec9c6c02458.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6641,
          name: "Hyperspace",
          uuid: "2d131b21f78f429692d4199c841934e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/2d131b21f78f429692d4199c841934e5.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6643,
          name: "Kingdorado",
          uuid: "413261f588e041c08592f4c445288ea0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/413261f588e041c08592f4c445288ea0.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6637,
          name: "Magic Cave",
          uuid: "a7a6aee7741541d1bd66e90498a97156",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/a7a6aee7741541d1bd66e90498a97156.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6645,
          name: "Miami Vibes",
          uuid: "5dc4b697c50762d48e37a2ed4cf95ad6f3a2cafd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/5dc4b697c50762d48e37a2ed4cf95ad6f3a2cafd.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6646,
          name: "Plinko Cash",
          uuid: "5ec9209fa3a96beac261687be594eecd2e46f99c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/5ec9209fa3a96beac261687be594eecd2e46f99c.png",
          type: "other",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6647,
          name: "Turbo Sprint",
          uuid: "212f80d2f91e6fb941da4ba34f287271fb4199e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/212f80d2f91e6fb941da4ba34f287271fb4199e6.png",
          type: "instant win",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6642,
          name: "World Cup",
          uuid: "2a084537303c47d695db9854921ee33a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PangaGames/2a084537303c47d695db9854921ee33a.png",
          type: "slots",
          provider: "PangaGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 138,
      name: "Platipus",
      providerId: 19,
      providerName: "Platipus",
      games: [
        {
          game_id: 6770,
          name: "1000x Rush",
          uuid: "e70c37bb307a4751bb130c7690908cc3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/e70c37bb307a4751bb130c7690908cc3.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6709,
          name: "1001 Spins",
          uuid: "05edf3ab5d5987ff1324af05dacafa85dd722c39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/05edf3ab5d5987ff1324af05dacafa85dd722c39.jpeg",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6740,
          name: "2 Ways Royal",
          uuid: "c163ae780953304ea04adcbb5c50685a4f7c3cf7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/c163ae780953304ea04adcbb5c50685a4f7c3cf7.png",
          type: "table",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6773,
          name: "2500 x Rush",
          uuid: "eedef063ca3a4758b4f35ff2da281263",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/eedef063ca3a4758b4f35ff2da281263.png",
          type: "instant win",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6768,
          name: "4 Numbers",
          uuid: "09c0c9b7a36840169f27d7845972f1fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/09c0c9b7a36840169f27d7845972f1fe.png",
          type: "minigame",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6762,
          name: "5000 x Rush",
          uuid: "42de8d037e0e91d7c1901f2c99a026a57112be5e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/42de8d037e0e91d7c1901f2c99a026a57112be5e.png",
          type: "instant win",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6707,
          name: "7 & Hot Fruits",
          uuid: "05cbad24be9c2c805f23e7fa684d44e8197be4a3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/05cbad24be9c2c805f23e7fa684d44e8197be4a3.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6723,
          name: "9 Dragon Kings",
          uuid: "b0c53f7ae3f8aac5b3777badaea6bb12c14717ed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b0c53f7ae3f8aac5b3777badaea6bb12c14717ed.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6721,
          name: "9 Gems",
          uuid: "0911502e33b44b6b27e6e980007f1367fa998c8b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0911502e33b44b6b27e6e980007f1367fa998c8b.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6734,
          name: "Aces and Faces",
          uuid: "40f294b86a8d4293b7a712cd818051ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/40f294b86a8d4293b7a712cd818051ad.png",
          type: "table",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6761,
          name: "Air Boss",
          uuid: "692d6f3d188ffbcc3f1e25e8078ed8b0b6a48a7f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/692d6f3d188ffbcc3f1e25e8078ed8b0b6a48a7f.png",
          type: "crash",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6753,
          name: "American Roulette",
          uuid: "e76b121657dcf30874af7a3508daea8c03ffad9c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/e76b121657dcf30874af7a3508daea8c03ffad9c.png",
          type: "table",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6657,
          name: "Arabian Tales",
          uuid: "e768f929a2b78678c3ae4836de3c30d0b777070a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e768f929a2b78678c3ae4836de3c30d0b777070a.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6691,
          name: "Aztec Coins",
          uuid: "c7b1d1e6171965bbe4e6394d2bdf1600aeb45994",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c7b1d1e6171965bbe4e6394d2bdf1600aeb45994.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6672,
          name: "Aztec Temple",
          uuid: "8083f52bf221854c4f62c5f54922868f95fb1bcd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8083f52bf221854c4f62c5f54922868f95fb1bcd.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6682,
          name: "Baccarat Mini",
          uuid: "b14eed72ba525592e08368ecca1be74cc078f151",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b14eed72ba525592e08368ecca1be74cc078f151.png",
          type: "table",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6693,
          name: "Baccarat Pro",
          uuid: "84cf8ef0c0ad8d0071666ae6e552bdc18a861887",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/84cf8ef0c0ad8d0071666ae6e552bdc18a861887.png",
          type: "baccarat",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6683,
          name: "Baccarat VIP",
          uuid: "35be53e1d9b5eab047fc957928bd22d3037e570a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/35be53e1d9b5eab047fc957928bd22d3037e570a.png",
          type: "baccarat",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6712,
          name: "Bamboo Grove",
          uuid: "b1ff0c9d49e6a38f987e8b3ac2d9844a6d7a2e47",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b1ff0c9d49e6a38f987e8b3ac2d9844a6d7a2e47.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6685,
          name: "Bison Trail",
          uuid: "cacffbff3a1f2715d4eba04637e3e9d6809ce47b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cacffbff3a1f2715d4eba04637e3e9d6809ce47b.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6679,
          name: "Black Jack",
          uuid: "671c7a22d57235b4c7bcd3e0eeea36ee83973242",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/671c7a22d57235b4c7bcd3e0eeea36ee83973242.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6680,
          name: "Black Jack VIP",
          uuid: "cb1bf4924197653242438c79e5b449ce282dbc1a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cb1bf4924197653242438c79e5b449ce282dbc1a.png",
          type: "blackjack",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6739,
          name: "Bonus Deuces Wild",
          uuid: "5d076eb3f7e765f5fbd2cae36347244dd259bc2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/5d076eb3f7e765f5fbd2cae36347244dd259bc2c.png",
          type: "table",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6674,
          name: "Book of Egypt",
          uuid: "0ca2acdeb525dc412c89e6b4afc91c135dff6721",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0ca2acdeb525dc412c89e6b4afc91c135dff6721.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6738,
          name: "Book of Giza",
          uuid: "618e8253793316aee5c5ed2d5783b5ef1f943563",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/618e8253793316aee5c5ed2d5783b5ef1f943563.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6727,
          name: "Book of Light",
          uuid: "a851c04e5e1642d7679459154b6c8f88c2970737",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a851c04e5e1642d7679459154b6c8f88c2970737.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6695,
          name: "Caishen’s Gifts",
          uuid: "b4fc4e4bd46d7bca530a7d8b89388db1fe5974ff",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b4fc4e4bd46d7bca530a7d8b89388db1fe5974ff.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6737,
          name: "Catch The Leprechaun",
          uuid: "4239fc38c00c4b0381907a0c87830535",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/4239fc38c00c4b0381907a0c87830535.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6701,
          name: "Chilli Fiesta",
          uuid: "68cf2dc1efb74f806c3661f3e75a9511647198bf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/68cf2dc1efb74f806c3661f3e75a9511647198bf.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6690,
          name: "Chinese Tigers",
          uuid: "9255f638fab1d0acd1240b9c13bd874d7722e213",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9255f638fab1d0acd1240b9c13bd874d7722e213.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6670,
          name: "Cinderella",
          uuid: "ee8345159f0698b8598305ed57c4ffb7bb2120e9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ee8345159f0698b8598305ed57c4ffb7bb2120e9.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6648,
          name: "Cleo's Gold",
          uuid: "2f038d78ad69831069548d73ba118849e4081d60",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2f038d78ad69831069548d73ba118849e4081d60.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6748,
          name: "Coin Charge",
          uuid: "c86ee9d485e3dcb4fd2ed9c08d71229e3c0df714",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/c86ee9d485e3dcb4fd2ed9c08d71229e3c0df714.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6728,
          name: "Coinfest",
          uuid: "bdd9d00effce4c51a6da887ff89e3ec897914968",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bdd9d00effce4c51a6da887ff89e3ec897914968.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6662,
          name: "Crazy Jelly",
          uuid: "2c8e5c6c9eca357284f2c880726804cfcf03bcba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2c8e5c6c9eca357284f2c880726804cfcf03bcba.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6653,
          name: "Crocoman",
          uuid: "122165c9abe414c31deb460c1e12fb82ad45e9b3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/122165c9abe414c31deb460c1e12fb82ad45e9b3.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6669,
          name: "Crystal Sevens",
          uuid: "82dfbe1752790a20f4f5e16e1994b61730501a77",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/82dfbe1752790a20f4f5e16e1994b61730501a77.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6698,
          name: "Da Ji Da Li",
          uuid: "638c387bc142ae9977c3a8af9e2d999f483c5037",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/638c387bc142ae9977c3a8af9e2d999f483c5037.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6717,
          name: "Diamond Hunt",
          uuid: "79221be6a03cc0ceb64053ef570388d6d1584d36",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/79221be6a03cc0ceb64053ef570388d6d1584d36.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6769,
          name: "Double Steam",
          uuid: "4400d8dd70b646e696ed66aaca30d9dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/4400d8dd70b646e696ed66aaca30d9dd.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6742,
          name: "Dragon's Element Deluxe",
          uuid: "ee1d0b522dc4839b74ca555afdb5211837893a11",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/ee1d0b522dc4839b74ca555afdb5211837893a11.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6696,
          name: "Dragon`s Element",
          uuid: "81fb63581058ce757782d32d1c9314b96d47ec65",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/81fb63581058ce757782d32d1c9314b96d47ec65.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6751,
          name: "Dragon’s Money",
          uuid: "20f0b029a9e8f47362bdb85f921c7eca24ff4a25",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/20f0b029a9e8f47362bdb85f921c7eca24ff4a25.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6700,
          name: "Dynasty Warriors",
          uuid: "768cc8660b807ba7d719ebebc3a8bc7c395bfef0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/768cc8660b807ba7d719ebebc3a8bc7c395bfef0.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6706,
          name: "European Roulette",
          uuid: "b799a2aa9884bf311fb673cd54b12ec71359e134",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b799a2aa9884bf311fb673cd54b12ec71359e134.png",
          type: "table",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6729,
          name: "Extra Gems",
          uuid: "2fa3888cc50ef06214bf311ea37ec415a421f622",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/2fa3888cc50ef06214bf311ea37ec415a421f622.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6652,
          name: "Fairy Forest",
          uuid: "c701b586915470fb08e24e3fcac8df4a14e9c7dc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c701b586915470fb08e24e3fcac8df4a14e9c7dc.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6649,
          name: "Fiery Planet",
          uuid: "940809d4acd18dfc090c5289342b4ecde0b10511",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/940809d4acd18dfc090c5289342b4ecde0b10511.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6752,
          name: "First of Olympians",
          uuid: "3371e5c15b10639941f459473858ab08e0a69c1d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/3371e5c15b10639941f459473858ab08e0a69c1d.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6718,
          name: "Frozen Mirror",
          uuid: "f71124ed3fd78611f3e9a71ee86ba8ee0bb4d98d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f71124ed3fd78611f3e9a71ee86ba8ee0bb4d98d.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6732,
          name: "Fruit Boost",
          uuid: "59066625b8a34653bf27998e1f73e2c9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/59066625b8a34653bf27998e1f73e2c9.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6655,
          name: "Fruity Sevens",
          uuid: "57d5e4cf19ddf9a2cd8df333d8bf5ddea676893d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/57d5e4cf19ddf9a2cd8df333d8bf5ddea676893d.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6677,
          name: "Great Ocean",
          uuid: "30090fc08dc3967e34fde2d6c6138744bdeb9e77",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/30090fc08dc3967e34fde2d6c6138744bdeb9e77.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6711,
          name: "Guises of Dracula",
          uuid: "fe512a7d747ecea695a80fafc4e4a089e16370a0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fe512a7d747ecea695a80fafc4e4a089e16370a0.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6731,
          name: "Hallowin",
          uuid: "a95c05d05c10f3037452a06f3bef6e2f789b3ed2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/a95c05d05c10f3037452a06f3bef6e2f789b3ed2.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6704,
          name: "Hawaiian Night",
          uuid: "cf0fbe9b5f3606287544c480865d9f5b76b32095",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cf0fbe9b5f3606287544c480865d9f5b76b32095.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6692,
          name: "Jackpot Lab",
          uuid: "a429496f52e0cce482408f7d337fb9d04671926a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a429496f52e0cce482408f7d337fb9d04671926a.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6730,
          name: "Jacks or Better",
          uuid: "26c605db2c072a51cc5bee9d4daef37c83103821",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/26c605db2c072a51cc5bee9d4daef37c83103821.png",
          type: "table",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6684,
          name: "Jade Valley",
          uuid: "3d1e8538458b53eb4acf4455c5fd91245d553d7b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3d1e8538458b53eb4acf4455c5fd91245d553d7b.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6650,
          name: "Jewel Bang",
          uuid: "8091acbb90e70fcd76c90a0c9906ad286b4b0e78",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8091acbb90e70fcd76c90a0c9906ad286b4b0e78.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6719,
          name: "Joker Chase",
          uuid: "dba3877dc6ada8ff87597a039ec346103cce3f2d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dba3877dc6ada8ff87597a039ec346103cce3f2d.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6654,
          name: "Juicy Spins",
          uuid: "b8858e602302f5d36caf69e5003d286eb0f6cc23",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b8858e602302f5d36caf69e5003d286eb0f6cc23.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6760,
          name: "Juicy Wheel",
          uuid: "64be6f509c2242f29760cb458a09627e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/64be6f509c2242f29760cb458a09627e.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6656,
          name: "Jungle Spin",
          uuid: "22b018f625ffd61eb67e32ced86c7b35074d8c8d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/22b018f625ffd61eb67e32ced86c7b35074d8c8d.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6675,
          name: "Legend of Atlantis",
          uuid: "32d17d328a5d3042613af701a7209db5bf2cf5b6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/32d17d328a5d3042613af701a7209db5bf2cf5b6.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6713,
          name: "Leprechaun’s Coins",
          uuid: "646ce35c87debd565fb1112a0305e8a366db1573",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/646ce35c87debd565fb1112a0305e8a366db1573.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6716,
          name: "Little Witchy",
          uuid: "3f32a77a524651c1b75c4fe4d52dcbd749ce0238",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3f32a77a524651c1b75c4fe4d52dcbd749ce0238.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6708,
          name: "Lord of the Sun",
          uuid: "9828d970eb992f5fc187376e9c1eca6747c59007",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9828d970eb992f5fc187376e9c1eca6747c59007.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6668,
          name: "Love is",
          uuid: "7528efcbe358e0286732b0b80d5ae6fa66620f80",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7528efcbe358e0286732b0b80d5ae6fa66620f80.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6687,
          name: "Lucky Cat",
          uuid: "66d50c57c779dd049a96c01e77d081427cd48533",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/66d50c57c779dd049a96c01e77d081427cd48533.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6661,
          name: "Lucky Dolphin",
          uuid: "177c31a4c4012b83a1a3784ddb4ab799f0e083ba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/177c31a4c4012b83a1a3784ddb4ab799f0e083ba.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6667,
          name: "Lucky money",
          uuid: "1ba9fe853bb29371f2974fdd9a2421e170b0334b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1ba9fe853bb29371f2974fdd9a2421e170b0334b.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6665,
          name: "Magical Mirror",
          uuid: "f06dc1d80c2bc3751e6f8f9a8919306fe4d86266",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f06dc1d80c2bc3751e6f8f9a8919306fe4d86266.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6659,
          name: "Magical Wolf",
          uuid: "88c9efa9e6b9c1fa82ae313d9aeb0d7a5467e6fe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/88c9efa9e6b9c1fa82ae313d9aeb0d7a5467e6fe.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6676,
          name: "Mega Drago",
          uuid: "de9512d69ce79a4d0f2057cff1e9a120d9d228c6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/de9512d69ce79a4d0f2057cff1e9a120d9d228c6.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6710,
          name: "Might of Zeus",
          uuid: "ff4306a9945fc4cfed394371ba03d0a96980e0a7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ff4306a9945fc4cfed394371ba03d0a96980e0a7.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6749,
          name: "Minerz",
          uuid: "3b007fbd6c2025553ab371d27e074afe626addce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/3b007fbd6c2025553ab371d27e074afe626addce.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6766,
          name: "Mini Roulette",
          uuid: "ec5f6ee1470b3eb9ea558b25f3f46326cc8fb59f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/ec5f6ee1470b3eb9ea558b25f3f46326cc8fb59f.png",
          type: "table",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6743,
          name: "Miss Gypsy",
          uuid: "27f3aec371ea92c24a2edd46ab219617de550420",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/27f3aec371ea92c24a2edd46ab219617de550420.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6764,
          name: "Mission: Vegas",
          uuid: "fcc959c02f3945f7bcfe4b5632b221f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/fcc959c02f3945f7bcfe4b5632b221f4.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6658,
          name: "Mistress of Amazon",
          uuid: "70d10f7055fb98e20326d727c46dd4b25e0eb874",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/70d10f7055fb98e20326d727c46dd4b25e0eb874.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6671,
          name: "Monkey's Journey",
          uuid: "5ac7578430e141975096b724f97d855a7909fc23",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5ac7578430e141975096b724f97d855a7909fc23.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6757,
          name: "Multihand BlackJack",
          uuid: "525deaba51fdd0700562a98b7101082c9cde66f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/525deaba51fdd0700562a98b7101082c9cde66f4.png",
          type: "blackjack",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6763,
          name: "Mystery Stones",
          uuid: "a16d62c365052d7e43706a9f252e88bdd5b45705",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/a16d62c365052d7e43706a9f252e88bdd5b45705.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6681,
          name: "Neon Classic",
          uuid: "78c4045e8d8c7379a7e07437d55825052bbeda37",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/78c4045e8d8c7379a7e07437d55825052bbeda37.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6726,
          name: "Pearls of the Ocean",
          uuid: "06eb269178558b8a5627dac0e81eb036daf444be",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/06eb269178558b8a5627dac0e81eb036daf444be.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6686,
          name: "Pharaoh’s Empire",
          uuid: "c5b832ca93aa3f9dbb246ac6a25cefb218075c24",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c5b832ca93aa3f9dbb246ac6a25cefb218075c24.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6722,
          name: "Piedra del Sol",
          uuid: "742e5fc03f6d175f89ff73c0529864d7f31b44cd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/742e5fc03f6d175f89ff73c0529864d7f31b44cd.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6736,
          name: "Pirate's Legacy",
          uuid: "e4fc5f33015a9be9803368a63d947a08c183872a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/e4fc5f33015a9be9803368a63d947a08c183872a.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6694,
          name: "Pirates Map",
          uuid: "c3831cefef21f0022e1a27f9dd8c57425c9fd701",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c3831cefef21f0022e1a27f9dd8c57425c9fd701.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6720,
          name: "Posh Cats",
          uuid: "9305fae62386edc57d41a5423b6206518164c08f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9305fae62386edc57d41a5423b6206518164c08f.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6664,
          name: "Power of Gods",
          uuid: "a6a20b657358d1fddc3e1348af3aa3cb26ee8f7f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a6a20b657358d1fddc3e1348af3aa3cb26ee8f7f.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6678,
          name: "Power of Poseidon",
          uuid: "b01c410bc754b9c12839516d40fe98421f58a46d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b01c410bc754b9c12839516d40fe98421f58a46d.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6771,
          name: "Premium European Blackjack",
          uuid: "da950b0f1e11a1fd23d00fde563eed12aa7df054",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/da950b0f1e11a1fd23d00fde563eed12aa7df054.png",
          type: "table",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6651,
          name: "Princess of Birds",
          uuid: "818e4d15009d6d3dccf8e6342e93f8c77096104d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/818e4d15009d6d3dccf8e6342e93f8c77096104d.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6689,
          name: "Rhino Mania",
          uuid: "6f96c32a587984091f34ef2d92e4949581a4e6c9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6f96c32a587984091f34ef2d92e4949581a4e6c9.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6660,
          name: "Richy Witchy",
          uuid: "0363a3af31d9f9cdb5d68f9f82f1e24d02732f4c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0363a3af31d9f9cdb5d68f9f82f1e24d02732f4c.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6702,
          name: "Royal Lotus",
          uuid: "c3b60ea4ad3913d92c8a9d22de0913bc572ab38a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c3b60ea4ad3913d92c8a9d22de0913bc572ab38a.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6663,
          name: "Safari Adventures",
          uuid: "ac3eec73afef659ca8b4a1f0a7b85026c72c2166",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ac3eec73afef659ca8b4a1f0a7b85026c72c2166.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6666,
          name: "Sakura Wind",
          uuid: "838aaf509ffe74dad51c4f87a11093c5287a3045",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/838aaf509ffe74dad51c4f87a11093c5287a3045.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6703,
          name: "Santa’s Bag",
          uuid: "9657adb8a5ca3b26431849c6928a1f0e4d2c9fa2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9657adb8a5ca3b26431849c6928a1f0e4d2c9fa2.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6765,
          name: "Shake & Win",
          uuid: "eedcfc580ef04dde83b2153cf11ede45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/eedcfc580ef04dde83b2153cf11ede45.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6759,
          name: "Single Deck Blackjack",
          uuid: "0a1370d167fa4f7a9314550aed918ec8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/0a1370d167fa4f7a9314550aed918ec8.png",
          type: "blackjack",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6767,
          name: "Slotcade",
          uuid: "45bd7fc1cbfc62ea75b85f63d207a0e9372a81b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/45bd7fc1cbfc62ea75b85f63d207a0e9372a81b7.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6758,
          name: "Spirits of the Prairies",
          uuid: "7c960c9390a547b494e75a9356ff3c5f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/7c960c9390a547b494e75a9356ff3c5f.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6744,
          name: "Texas Hold'em",
          uuid: "6202d4b2e44117c0695b7bc82a2fa7fdfb58cb91",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/6202d4b2e44117c0695b7bc82a2fa7fdfb58cb91.png",
          type: "table",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6697,
          name: "The Ancient Four",
          uuid: "37fd831bfc1ee3c572e2b388f079603b98b4da8b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/37fd831bfc1ee3c572e2b388f079603b98b4da8b.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6735,
          name: "The Big Score",
          uuid: "70fcb4d4073c4aeabd5cd8cc6f49d985",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/70fcb4d4073c4aeabd5cd8cc6f49d985.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6745,
          name: "Thor Turbo Power",
          uuid: "1fc3b71b69f541dc9da4560a6b12da3a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/1fc3b71b69f541dc9da4560a6b12da3a.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6756,
          name: "Totem Mystique",
          uuid: "36eb8e768b704112579e4fd7b1b8dc3911570a77",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/36eb8e768b704112579e4fd7b1b8dc3911570a77.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6673,
          name: "Triple Dragon",
          uuid: "4559fe46562629cff6c7dc6f237906cdfff68f37",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4559fe46562629cff6c7dc6f237906cdfff68f37.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6750,
          name: "Ultra Disco",
          uuid: "d1395742d8ff4c39b57082c7c60ad9e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/d1395742d8ff4c39b57082c7c60ad9e1.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6715,
          name: "Un Dia De Muertos",
          uuid: "654d674a96e7388651965d26f775250e3f2c49a7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/654d674a96e7388651965d26f775250e3f2c49a7.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6755,
          name: "Urban Neon",
          uuid: "55e6ce3f26844884be8f759cb096cd1f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/55e6ce3f26844884be8f759cb096cd1f.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6747,
          name: "Vegas Hold'em",
          uuid: "604b7a5892585675727719ac854e4291557969b6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/604b7a5892585675727719ac854e4291557969b6.png",
          type: "poker",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6772,
          name: "Viking Games",
          uuid: "09e36baa734d4aaca7a0e09f17f3ba4c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/09e36baa734d4aaca7a0e09f17f3ba4c.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6724,
          name: "Ways of the Gauls",
          uuid: "7247ed8306e783ca44e1ebf8d0f6711e866bfd99",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7247ed8306e783ca44e1ebf8d0f6711e866bfd99.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6705,
          name: "Wealth of Wisdom",
          uuid: "07aa071938dbc3d36a2dbd5cca9e9d90c8cfde15",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/07aa071938dbc3d36a2dbd5cca9e9d90c8cfde15.jpeg",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6688,
          name: "Webby Heroes",
          uuid: "35ec57ac06bb09325fbdbd2aa2c64445bd10ed6b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/35ec57ac06bb09325fbdbd2aa2c64445bd10ed6b.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6774,
          name: "Wild Bingo",
          uuid: "e9347ae271d84516892bee10715c912d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/e9347ae271d84516892bee10715c912d.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6725,
          name: "Wild Crowns",
          uuid: "dc47b0cb32b201704bb83a482979d1bbdee350fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dc47b0cb32b201704bb83a482979d1bbdee350fb.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6714,
          name: "Wild Justice",
          uuid: "abe9f764743d76d6a9f608cd2eae3904ed7e5aac",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/abe9f764743d76d6a9f608cd2eae3904ed7e5aac.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6699,
          name: "Wild Spin",
          uuid: "a755941f59081d45aadaf6845f5b2c981433f6e2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a755941f59081d45aadaf6845f5b2c981433f6e2.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6741,
          name: "Wild Spin Deluxe",
          uuid: "315fa078aca8245fc2e621a8e748dbd2ce964a27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/315fa078aca8245fc2e621a8e748dbd2ce964a27.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6746,
          name: "Wizard of the Wild",
          uuid: "5b479d92cdd0951059e95f286d13fc43929264a8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/5b479d92cdd0951059e95f286d13fc43929264a8.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6754,
          name: "WondeReels",
          uuid: "ffd211905a83402d98eb8e5039f9b25d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/ffd211905a83402d98eb8e5039f9b25d.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6733,
          name: "Xmas Avalanche",
          uuid: "6077dde9c80a46e6aae8ef16f0c05f25",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Platipus/6077dde9c80a46e6aae8ef16f0c05f25.png",
          type: "slots",
          provider: "Platipus",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 139,
      name: "PlayNGo",
      providerId: 9,
      providerName: "PlayNGo",
      games: [
        {
          game_id: 550,
          name: "*NSYNC Pop",
          uuid: "42ea161e623b51185127b832d6fd596beeb4cce4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/42ea161e623b51185127b832d6fd596beeb4cce4.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 509,
          name: "15 Crystal Roses: A Tale of Love",
          uuid: "30114db330b68ea9ce38986a931d2795d26f67c2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/30114db330b68ea9ce38986a931d2795d26f67c2.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 551,
          name: "Animal Madness",
          uuid: "59eee36c09d4e7dfa4f16c2682f6e92db3f84c88",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/59eee36c09d4e7dfa4f16c2682f6e92db3f84c88.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 867,
          name: "Athena Ascending",
          uuid: "5270556574f946568675076e58d4128b",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/5270556574f946568675076e58d4128b.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 61,
          name: "Book of Dead",
          uuid: "a0b71a253c284599926231dc51a9128b",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/a0b71a253c284599926231dc51a9128b.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 600,
          name: "Bull in a Rodeo",
          uuid: "e9e365bfc0044ecb829e09b1fcafe6e9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/e9e365bfc0044ecb829e09b1fcafe6e9.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 599,
          name: "Canine Carnage",
          uuid: "ea05d0af6d3d4be0881b4a7cf2ce5e64",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/ea05d0af6d3d4be0881b4a7cf2ce5e64.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 504,
          name: "Captain Xeno`s Earth Adventure",
          uuid: "d3e5afeacfab3fc5c9e647aada9f4b29fa03bebf",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d3e5afeacfab3fc5c9e647aada9f4b29fa03bebf.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 505,
          name: "Cat Wilde and the Lost Chapter",
          uuid: "0f9eefa0e42bb314d2ba54c3a3a091533a8ca65f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0f9eefa0e42bb314d2ba54c3a3a091533a8ca65f.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 391,
          name: "Cat Wilde Doom",
          uuid: "d581dcae88a049b6866c8465ecfae750",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/d581dcae88a049b6866c8465ecfae750.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 392,
          name: "Cat Wilde Eclipse",
          uuid: "12bf9d42cd4d4c88a55bd6d8ebc8398e",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/12bf9d42cd4d4c88a55bd6d8ebc8398e.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 393,
          name: "Charlie Chance",
          uuid: "412ba82a90bf4beebf608b919f6af477",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/412ba82a90bf4beebf608b919f6af477.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 598,
          name: "Count Jokula",
          uuid: "ff39f181d8334e6fa9c59173f07e26f8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/ff39f181d8334e6fa9c59173f07e26f8.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 394,
          name: "Court of Hearts",
          uuid: "ae85ea8feec64fe8ae06670951c55c6a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/ae85ea8feec64fe8ae06670951c55c6a.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 552,
          name: "Def Leppard: Hysteria",
          uuid: "6a8f0a93c924368c38b707cbb852c065ee5f61a9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6a8f0a93c924368c38b707cbb852c065ee5f61a9.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 395,
          name: "Diamonds of the Realm",
          uuid: "2b9914de50284e9d8fc3a58525ae42e5",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/2b9914de50284e9d8fc3a58525ae42e5.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 597,
          name: "Dio - Killing the Dragon",
          uuid: "20cb6fb2b1a0488192faa0001a61402e",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/20cb6fb2b1a0488192faa0001a61402e.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 413,
          name: "Doom of Egypt",
          uuid: "81e9a2ce979d373f0cedc5712265a12cc95e9f7b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/81e9a2ce979d373f0cedc5712265a12cc95e9f7b.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 553,
          name: "Eye of Atum",
          uuid: "9ae40767110096790ab2efd8653b101640861417",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9ae40767110096790ab2efd8653b101640861417.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 396,
          name: "Fire Toad",
          uuid: "9d7ab11e2f984c309f36d6a175e6d135",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/9d7ab11e2f984c309f36d6a175e6d135.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 596,
          name: "Forge of Fortunes",
          uuid: "ba1a28d6b8374d38b8a31b4f57d8d182",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/ba1a28d6b8374d38b8a31b4f57d8d182.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 595,
          name: "Fortune Rewind",
          uuid: "2aea49471f2d4a2e8c8faeed39c0bb96",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/2aea49471f2d4a2e8c8faeed39c0bb96.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 397,
          name: "Free Reelin Joker",
          uuid: "f9ac44fa3ba14496bd21a21daef37f53",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/f9ac44fa3ba14496bd21a21daef37f53.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 594,
          name: "Gates of Troy",
          uuid: "c19e2346290745b4a55ef921143c5f53",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/c19e2346290745b4a55ef921143c5f53.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 507,
          name: "GigantoonZ",
          uuid: "560adb95fa951d24ad759195943d3cfdcb5059de",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/560adb95fa951d24ad759195943d3cfdcb5059de.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 398,
          name: "Golden Osiris",
          uuid: "7686451ba5944ad1b07988931f23ee37",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/7686451ba5944ad1b07988931f23ee37.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 399,
          name: "Green Knight",
          uuid: "015da5cdeed94af79362ef129e009d32",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/015da5cdeed94af79362ef129e009d32.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 142,
          name: "Ice Joker",
          uuid: "902978c176a54c1d9105b5fb7ef6dcc2",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/902978c176a54c1d9105b5fb7ef6dcc2.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 400,
          name: "Last Sundown",
          uuid: "bbef4c612cac4a7eaa62ded123118eaf",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/bbef4c612cac4a7eaa62ded123118eaf.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 410,
          name: "Legacy of Dead",
          uuid: "d2bac0b22b019348fc141ab3550545d67f613dd4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d2bac0b22b019348fc141ab3550545d67f613dd4.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 414,
          name: "Legacy of Egypt",
          uuid: "aa48fffeb8091805f913714a317fc399b1aa0be1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/aa48fffeb8091805f913714a317fc399b1aa0be1.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 401,
          name: "Legend of the Ice Dragon",
          uuid: "434bd9a8051e4117ab288d408595f16c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/434bd9a8051e4117ab288d408595f16c.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 593,
          name: "Leprechaun's Vault",
          uuid: "16420a1d31564fbd893f0863da29f7f1",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/16420a1d31564fbd893f0863da29f7f1.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 402,
          name: "Lokis fortune",
          uuid: "9ac2dc7b9abe4b6fbcf385168b90024a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/9ac2dc7b9abe4b6fbcf385168b90024a.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 511,
          name: "Love Joker",
          uuid: "13af573fed76b2c9e457856f27223117267fc836",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/13af573fed76b2c9e457856f27223117267fc836.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 592,
          name: "Mega Don",
          uuid: "7455c011e6aa4f9db3d17f287ed2a3c3",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/7455c011e6aa4f9db3d17f287ed2a3c3.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 591,
          name: "Merlin's Grimoire",
          uuid: "b19b46ae41954b9092afb1ec4523f10b",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/b19b46ae41954b9092afb1ec4523f10b.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 590,
          name: "Mötley Crüe",
          uuid: "5f82b572c0b349f0828e49722cb8d43b",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/5f82b572c0b349f0828e49722cb8d43b.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 589,
          name: "Mount M",
          uuid: "39b8d944ab1441f288cf6ee45a6a915f",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/39b8d944ab1441f288cf6ee45a6a915f.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 104,
          name: "New Year Riches",
          uuid: "8cb87863c3c1482ca681c762407aa12d",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/8cb87863c3c1482ca681c762407aa12d.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 510,
          name: "Phoenix Reborn",
          uuid: "fddc35098c572199d24c89bfa4c5e0ec088aa43f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/fddc35098c572199d24c89bfa4c5e0ec088aa43f.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 555,
          name: "Puebla Parade",
          uuid: "1fa91f004341613dc91cea456c2a4b015d79d617",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1fa91f004341613dc91cea456c2a4b015d79d617.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 403,
          name: "Reactoonz",
          uuid: "3e66bc6ea14f444baaab3396ce8a2949",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/3e66bc6ea14f444baaab3396ce8a2949.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 404,
          name: "Reactoonz 2",
          uuid: "3a1ae18ff0414f8f8bd85c4e5ad32934",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/3a1ae18ff0414f8f8bd85c4e5ad32934.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 405,
          name: "Rich Wilde Wandering City",
          uuid: "5476e75049fe4e83a007bf5462449444",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/5476e75049fe4e83a007bf5462449444.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 406,
          name: "Riddle Reels",
          uuid: "75653db408704ad9b390eefb0f71b2a7",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/75653db408704ad9b390eefb0f71b2a7.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 407,
          name: "Rise of Dead",
          uuid: "349f8fa27b6444f09d509c0c630551d2",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/349f8fa27b6444f09d509c0c630551d2.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 554,
          name: "Rise of Gods: Reckoning",
          uuid: "243f4bc7f0c25e016317031e1967c58dc93492f2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/243f4bc7f0c25e016317031e1967c58dc93492f2.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 411,
          name: "Rise of Merlin",
          uuid: "e20bb11288d7e9b0d0caee7719d9cf256334ad47",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e20bb11288d7e9b0d0caee7719d9cf256334ad47.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 556,
          name: "Rocco Gallo",
          uuid: "2c797b5dbbe8d0170cd2b4ac0d3d2db759bdb2c2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2c797b5dbbe8d0170cd2b4ac0d3d2db759bdb2c2.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 412,
          name: "Scroll of Dead",
          uuid: "d5bcda507a0ae2286a44a82440508adb3e16182f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d5bcda507a0ae2286a44a82440508adb3e16182f.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 692,
          name: "Secret Of Dead",
          uuid: "e764e82647fd4b1ca4e4afdc90b39050",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/e764e82647fd4b1ca4e4afdc90b39050.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 588,
          name: "Shamrock Miner",
          uuid: "c2fb13b7a8f647d984edeecb0ef8ab8f",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/c2fb13b7a8f647d984edeecb0ef8ab8f.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 408,
          name: "Snakebite",
          uuid: "5fb05eca391c403a98ddccb2d95f6d7e",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/5fb05eca391c403a98ddccb2d95f6d7e.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 409,
          name: "Sparky Shortz",
          uuid: "fa7dd0ee53df46e68c16a3f2fcce305b",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/fa7dd0ee53df46e68c16a3f2fcce305b.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 508,
          name: "Tale Of Kyubiko",
          uuid: "e63e603e80a2fe3ffc32b5d32c50b7c9e1bea3d0",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e63e603e80a2fe3ffc32b5d32c50b7c9e1bea3d0.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 506,
          name: "Tales of Asgard: Freya's Wedding",
          uuid: "95da95b63f059db97c080f6777e60afd676668a4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/95da95b63f059db97c080f6777e60afd676668a4.png",
          type: "slots",
          provider: "PlayNGo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 140,
      name: "PragmaticPlay",
      providerId: 34,
      providerName: "PragmaticPlay",
      games: [
        {
          game_id: 1660,
          name: "3 Buzzing Wilds",
          uuid: "c8d653a50795409d920ac3563501b6a2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c8d653a50795409d920ac3563501b6a2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1661,
          name: "3 Buzzing Wilds Mobile",
          uuid: "d4c7d02cf6cb4146a4d73c6d8295697d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d4c7d02cf6cb4146a4d73c6d8295697d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1616,
          name: "3 Dancing Monkeys",
          uuid: "2b03b1e8fa044e7182dfb5f1bb7dd0c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2b03b1e8fa044e7182dfb5f1bb7dd0c2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1617,
          name: "3 Dancing Monkeys Mobile",
          uuid: "05f1a73f427040a9a0b06f78d5a0e695",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/05f1a73f427040a9a0b06f78d5a0e695.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1210,
          name: "3 Genie Wishes",
          uuid: "b439aa44870a4542a7c688b8f10f7423",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b439aa44870a4542a7c688b8f10f7423.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1211,
          name: "3 Genie Wishes Mobile",
          uuid: "ed32cfda915d42a08caf3823bca30e6b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ed32cfda915d42a08caf3823bca30e6b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1280,
          name: "3 Kingdoms - Battle of Red Cliffs",
          uuid: "acce824d2bcb45519ff22f8d374bde72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/acce824d2bcb45519ff22f8d374bde72.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1281,
          name: "3 Kingdoms - Battle of Red Cliffs Mobile",
          uuid: "b60d2003b8d04a83912b1608947701b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b60d2003b8d04a83912b1608947701b8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1732,
          name: "5 Frozen Charms Megaways",
          uuid: "8e4aa666b3284741a67663b86512acca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8e4aa666b3284741a67663b86512acca.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1733,
          name: "5 Frozen Charms Megaways Mobile",
          uuid: "a60e43a006eb468990426d395ebd7f51",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a60e43a006eb468990426d395ebd7f51.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1046,
          name: "5 Lions",
          uuid: "f749ea45b04e4195ab0c6424e043d323",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f749ea45b04e4195ab0c6424e043d323.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1032,
          name: "5 Lions Dance",
          uuid: "b7ffbddd8c184e9d8feb5c581ad7ab3d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b7ffbddd8c184e9d8feb5c581ad7ab3d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1033,
          name: "5 Lions Dance Mobile",
          uuid: "c68b2316c73e46308dbae3bd9e8fcbfd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c68b2316c73e46308dbae3bd9e8fcbfd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1044,
          name: "5 Lions Gold",
          uuid: "4bb371fa58334accbac4889dd098767f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4bb371fa58334accbac4889dd098767f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1045,
          name: "5 Lions Gold Mobile",
          uuid: "0bb884d7a0f840a99a48b41de5e484ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0bb884d7a0f840a99a48b41de5e484ec.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1324,
          name: "5 Lions Megaways",
          uuid: "543bfe16f994158568c5db1e65be1e3f15ef817d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/543bfe16f994158568c5db1e65be1e3f15ef817d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1325,
          name: "5 Lions Megaways Mobile",
          uuid: "e81c5cb3d5390cffbe34a37207dba1f8eb4764ef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e81c5cb3d5390cffbe34a37207dba1f8eb4764ef.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1047,
          name: "5 Lions Mobile",
          uuid: "1dc5783d0a234621897d788ead1687f1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1dc5783d0a234621897d788ead1687f1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1853,
          name: "6 Jokers",
          uuid: "00bca1bf10c8d95d31f0bb3f05eb5fd4b149c8ed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/00bca1bf10c8d95d31f0bb3f05eb5fd4b149c8ed.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1852,
          name: "6 Jokers Mobile",
          uuid: "287ea69a78e1e9fc3f980d5605db324c9b1a83d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/287ea69a78e1e9fc3f980d5605db324c9b1a83d2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1196,
          name: "7 Monkeys",
          uuid: "14bb31e4e0dc4ec68cc74b18cc6539fb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/14bb31e4e0dc4ec68cc74b18cc6539fb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1197,
          name: "7 Monkeys Mobile",
          uuid: "d80d830f4da24d0a80e3a07136c54a8b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d80d830f4da24d0a80e3a07136c54a8b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1218,
          name: "7 Piggies",
          uuid: "dc5d116d08dd40e193d94d102bfc5798",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/dc5d116d08dd40e193d94d102bfc5798.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1278,
          name: "7 Piggies 5,000",
          uuid: "ac232668c85f4b69ae95fe289843ad38",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ac232668c85f4b69ae95fe289843ad38.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1279,
          name: "7 Piggies 5,000 Mobile",
          uuid: "9235fc9cf4b04b25bd3d3d2577186b81",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9235fc9cf4b04b25bd3d3d2577186b81.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1219,
          name: "7 Piggies Mobile",
          uuid: "92d365ad8bbf4dbc9f9f24898e81092c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/92d365ad8bbf4dbc9f9f24898e81092c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1030,
          name: "8 Dragons",
          uuid: "25b81f36ddbb4053ae9a4da2b56d4fd6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/25b81f36ddbb4053ae9a4da2b56d4fd6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1031,
          name: "8 Dragons Mobile",
          uuid: "b35f10daefd748c7b6ac097e7ec89998",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b35f10daefd748c7b6ac097e7ec89998.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1690,
          name: "8 Golden Dragon Challenge",
          uuid: "38c39cbf4f5f4c28a1e73101b647d3a2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/38c39cbf4f5f4c28a1e73101b647d3a2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1691,
          name: "8 Golden Dragon Challenge Mobile",
          uuid: "0ae453fd7970417085b3f7f290e02696",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0ae453fd7970417085b3f7f290e02696.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1024,
          name: "888 Dragons",
          uuid: "2424737e6a444f2784e32011af2854cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2424737e6a444f2784e32011af2854cc.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1025,
          name: "888 Dragons Mobile",
          uuid: "d428fbd9178644d6bdc312f7ac29e86d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d428fbd9178644d6bdc312f7ac29e86d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1128,
          name: "888 Gold",
          uuid: "976f47c53be1484797d1f1ba304345b3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/976f47c53be1484797d1f1ba304345b3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1129,
          name: "888 Gold Mobile",
          uuid: "95560c9f26e44b1cb9a4712d584834bd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/95560c9f26e44b1cb9a4712d584834bd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1612,
          name: "African Elephant",
          uuid: "78327596e06e47eb88cfcb006448c7b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/78327596e06e47eb88cfcb006448c7b0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1613,
          name: "African Elephant Mobile",
          uuid: "18572a6aea5d437bba19726ba42af3c1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/18572a6aea5d437bba19726ba42af3c1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1238,
          name: "Aladdin and the Sorcerer",
          uuid: "0ba7b7d724cf4fd5a84525eae8bf11f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0ba7b7d724cf4fd5a84525eae8bf11f0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1239,
          name: "Aladdin and the Sorcerer Mobile",
          uuid: "c5f0407a7a924899a73702e0ae697767",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c5f0407a7a924899a73702e0ae697767.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1242,
          name: "Aladdin's Treasure",
          uuid: "ea4680974de64540be1c04a51dec3a65",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ea4680974de64540be1c04a51dec3a65.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1243,
          name: "Aladdin's Treasure Mobile",
          uuid: "7a42cf1a77334a9fa2bb34fa97b5a9c7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7a42cf1a77334a9fa2bb34fa97b5a9c7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1264,
          name: "American Blackjack",
          uuid: "980357dab44947d79610b15535bebc98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/980357dab44947d79610b15535bebc98.png",
          type: "blackjack",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1265,
          name: "American Blackjack Mobile",
          uuid: "1439bbb1141f47c2a6a3d5e48cc07e2b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1439bbb1141f47c2a6a3d5e48cc07e2b.png",
          type: "blackjack",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1232,
          name: "Ancient Egypt",
          uuid: "7f2af652a18344ccb71e91214d727422",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7f2af652a18344ccb71e91214d727422.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1106,
          name: "Ancient Egypt Classic",
          uuid: "4e38833e42e14ab3805ebac77f97d582",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4e38833e42e14ab3805ebac77f97d582.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1107,
          name: "Ancient Egypt Classic Mobile",
          uuid: "df477f42b80649099b3b949942298bea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/df477f42b80649099b3b949942298bea.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1233,
          name: "Ancient Egypt Mobile",
          uuid: "8dfcf57079ac45f1a6ee0c110857881f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8dfcf57079ac45f1a6ee0c110857881f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1875,
          name: "Angel vs Sinner",
          uuid: "21cbc65a21270e52f256fd5d2d21002170bfb450",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/21cbc65a21270e52f256fd5d2d21002170bfb450.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1874,
          name: "Angel vs Sinner Mobile",
          uuid: "4d6cadd5fe77782cf852b206db08ba31fc2a10e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4d6cadd5fe77782cf852b206db08ba31fc2a10e6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1120,
          name: "Asgard",
          uuid: "6243ba16385f4a95a644082b142ef571",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6243ba16385f4a95a644082b142ef571.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1121,
          name: "Asgard Mobile",
          uuid: "56a349d0dcf34b7a8aa8dbb90f98e45c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/56a349d0dcf34b7a8aa8dbb90f98e45c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1516,
          name: "Aztec Blaze",
          uuid: "53d88f2cd19a8935e539759d58fb98e211f0e043",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/53d88f2cd19a8935e539759d58fb98e211f0e043.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1517,
          name: "Aztec Blaze Mobile",
          uuid: "4fad3338da540ad1da908f35eee550fbae48ab71",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4fad3338da540ad1da908f35eee550fbae48ab71.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1042,
          name: "Aztec Bonanza",
          uuid: "624c8a2ef18f4d9bb9cc6d829ee3284f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/624c8a2ef18f4d9bb9cc6d829ee3284f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1043,
          name: "Aztec Bonanza Mobile",
          uuid: "f227a930bb3542f48d5936d89cf2cea1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f227a930bb3542f48d5936d89cf2cea1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1006,
          name: "Aztec Gems",
          uuid: "724e117e6ac44b8abba34c3b34db8794",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/724e117e6ac44b8abba34c3b34db8794.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1018,
          name: "Aztec Gems Deluxe",
          uuid: "3797a3a506444327926a6e459b08e37c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3797a3a506444327926a6e459b08e37c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1019,
          name: "Aztec Gems Deluxe Mobile",
          uuid: "9803741fa6d04ebe8f1cce53b2549ad9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9803741fa6d04ebe8f1cce53b2549ad9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1007,
          name: "Aztec Gems Mobile",
          uuid: "e8e8a6048f784e9ea657fa07d4f9585e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e8e8a6048f784e9ea657fa07d4f9585e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1796,
          name: "Aztec Powernudge",
          uuid: "e3eacff22bfa4a6a89b5de0f38783e15",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e3eacff22bfa4a6a89b5de0f38783e15.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1797,
          name: "Aztec Powernudge Mobile",
          uuid: "9509ec56474445719b4171ce612a9a76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9509ec56474445719b4171ce612a9a76.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1872,
          name: "Aztec Treasure Hunt",
          uuid: "901ed267bce441359188b94f7239aaec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/901ed267bce441359188b94f7239aaec.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1873,
          name: "Aztec Treasure Hunt Mobile",
          uuid: "f63a636b0d8b43868f8911e118c5ad03",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f63a636b0d8b43868f8911e118c5ad03.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1282,
          name: "Baccarat",
          uuid: "70321d67752243118849d54250580765",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/70321d67752243118849d54250580765.png",
          type: "baccarat",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1283,
          name: "Baccarat Mobile",
          uuid: "19ff6f3bcf4d47b29fd2bae9aa6cab6f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/19ff6f3bcf4d47b29fd2bae9aa6cab6f.png",
          type: "baccarat",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6783,
          name: "Badge Blitz",
          uuid: "00c7e7d5d3044c089b176382b069e085",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/00c7e7d5d3044c089b176382b069e085.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6784,
          name: "Badge Blitz Mobile",
          uuid: "9242da22f719459e89e6cbc9b77003f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9242da22f719459e89e6cbc9b77003f8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1432,
          name: "Barn Festival",
          uuid: "281bd8b698525c2ad2e3c10b24c7d766cb9df691",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/281bd8b698525c2ad2e3c10b24c7d766cb9df691.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1433,
          name: "Barn Festival Mobile",
          uuid: "32bf853e1e8f7fcd9c77a944e9b9844196b37b60",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/32bf853e1e8f7fcd9c77a944e9b9844196b37b60.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1802,
          name: "Barnyard Megahays Megaways",
          uuid: "1963bd4a6c774074b0b26145105fdcba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1963bd4a6c774074b0b26145105fdcba.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1803,
          name: "Barnyard Megahays Megaways Mobile",
          uuid: "5f1008987afa4156b8127dd173efbf7d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5f1008987afa4156b8127dd173efbf7d.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1782,
          name: "Beware The Deep Megaways",
          uuid: "d9160cfd6f704b07879b52c842e7c1c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d9160cfd6f704b07879b52c842e7c1c0.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1783,
          name: "Beware The Deep Megaways Mobile",
          uuid: "b77e4f41aeaa42289d2b6765270a2f95",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b77e4f41aeaa42289d2b6765270a2f95.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1606,
          name: "Big Bass - Hold & Spinner",
          uuid: "46fd364683164ddbb528466ff23987e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/46fd364683164ddbb528466ff23987e1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 1607,
          name: "Big Bass - Hold & Spinner Mobile",
          uuid: "0a2b97d8b6b54185af9b3156f050e1ac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0a2b97d8b6b54185af9b3156f050e1ac.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1530,
          name: "Big Bass - Keeping it Reel",
          uuid: "d1acf2e3d4bce1feaa8e86c73521b5337322b509",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d1acf2e3d4bce1feaa8e86c73521b5337322b509.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 1531,
          name: "Big Bass - Keeping it Reel Mobile",
          uuid: "5271019e5d963d62053d2f6354397e1297ac3395",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5271019e5d963d62053d2f6354397e1297ac3395.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1646,
          name: "Big Bass Amazon Xtreme",
          uuid: "cca9dda7cf64673f7c8a215439d62a7265b508ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/cca9dda7cf64673f7c8a215439d62a7265b508ea.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1647,
          name: "Big Bass Amazon Xtreme Mobile",
          uuid: "c3a25ae036f39c88d1c44010c5139a260b7d6721",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c3a25ae036f39c88d1c44010c5139a260b7d6721.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1288,
          name: "Big Bass Bonanza",
          uuid: "76b3846996214fa09c1f761a0228c032",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/76b3846996214fa09c1f761a0228c032.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 1825,
          name: "Big Bass Bonanza - Reel Action",
          uuid: "4a8d94baf3c144b48a8a43e6fd6ea751",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4a8d94baf3c144b48a8a43e6fd6ea751.jpeg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1824,
          name: "Big Bass Bonanza - Reel Action Mobile",
          uuid: "7c4f26ae772d49fc84932c3a234918b9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7c4f26ae772d49fc84932c3a234918b9.jpeg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1386,
          name: "Big Bass Bonanza Megaways",
          uuid: "d7142270d6fd89e3195f511115b7208f292dd7d3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d7142270d6fd89e3195f511115b7208f292dd7d3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1387,
          name: "Big Bass Bonanza Megaways Mobile",
          uuid: "18f23ee2d4dd244c710bace5ada9fe017c2f65f6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/18f23ee2d4dd244c710bace5ada9fe017c2f65f6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1289,
          name: "Big Bass Bonanza Mobile",
          uuid: "b3d0db8aadb84b77b3db43bf8b72ec4e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b3d0db8aadb84b77b3db43bf8b72ec4e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1730,
          name: "Big Bass Christmas Bash",
          uuid: "2e0d909e38dd4a6aba14ec3eb20c1a4d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2e0d909e38dd4a6aba14ec3eb20c1a4d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1731,
          name: "Big Bass Christmas Bash Mobile",
          uuid: "4431da3eaaa747ecb5b5b71518377f33",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4431da3eaaa747ecb5b5b71518377f33.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1696,
          name: "Big Bass Crash",
          uuid: "1209a101a43b49918232c26301c147c8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1209a101a43b49918232c26301c147c8.png",
          type: "crash",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1697,
          name: "Big Bass Crash Mobile",
          uuid: "77a09b5bcb28489b917adc64b72b4ba3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/77a09b5bcb28489b917adc64b72b4ba3.png",
          type: "crash",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1780,
          name: "Big Bass Day at the Races",
          uuid: "78d09ba7075d44cbb056e73299cce703",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/78d09ba7075d44cbb056e73299cce703.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1781,
          name: "Big Bass Day at the Races Mobile",
          uuid: "66f3d7927db043ce94fedb4619c1bc49",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/66f3d7927db043ce94fedb4619c1bc49.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1884,
          name: "Big Bass Double Down Deluxe",
          uuid: "bf03ab99344f46b8ab2af03005e722bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bf03ab99344f46b8ab2af03005e722bf.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 1885,
          name: "Big Bass Double Down Deluxe Mobile",
          uuid: "73ccd0a488244178828e1dd5ffd044e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/73ccd0a488244178828e1dd5ffd044e4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1768,
          name: "Big Bass Floats My Boat",
          uuid: "22b31fdcb04942cb8363a807724047c3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/22b31fdcb04942cb8363a807724047c3.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1769,
          name: "Big Bass Floats My Boat Mobile",
          uuid: "e64fba66958142508c67eed0aa27e6bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e64fba66958142508c67eed0aa27e6bc.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1710,
          name: "Big Bass Halloween",
          uuid: "07a9b42e374a4456a865b1faaaf78009",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/07a9b42e374a4456a865b1faaaf78009.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1711,
          name: "Big Bass Halloween Mobile",
          uuid: "6069d78d836448f8b5bed7b78047da4b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6069d78d836448f8b5bed7b78047da4b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1678,
          name: "Big Bass Hold & Spinner Megaways",
          uuid: "9f658e3ae2b5443393d0899e6181c9d6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9f658e3ae2b5443393d0899e6181c9d6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1679,
          name: "Big Bass Hold & Spinner Megaways Mobile",
          uuid: "bbeb02b821d24959a39b17f30ef8c7f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bbeb02b821d24959a39b17f30ef8c7f0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1836,
          name: "Big Bass Mission Fishin'",
          uuid: "5dfa9be0cef04287a3a621677dc6ced1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5dfa9be0cef04287a3a621677dc6ced1.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1837,
          name: "Big Bass Mission Fishin' Mobile",
          uuid: "00251bd9053943fd96cfc977dc4c5887",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/00251bd9053943fd96cfc977dc4c5887.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1798,
          name: "Big Bass Secrets of the Golden Lake",
          uuid: "a37c19ff02f74576a4ea3fe5c30b775f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a37c19ff02f74576a4ea3fe5c30b775f.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1799,
          name: "Big Bass Secrets of the Golden Lake Mobile",
          uuid: "f2607ebc649647e98c75bba34700f9a6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f2607ebc649647e98c75bba34700f9a6.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1466,
          name: "Big Bass Splash",
          uuid: "833cafd84e47f9464213d85968974a90f1e21304",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/833cafd84e47f9464213d85968974a90f1e21304.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1467,
          name: "Big Bass Splash Mobile",
          uuid: "2aa25b7011ccd53f8a9eed4732b38c667f9cd407",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2aa25b7011ccd53f8a9eed4732b38c667f9cd407.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1792,
          name: "Big Burger Load it up with Xtra Cheese",
          uuid: "07c73ed66056481e9db93257fc9ee3f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/07c73ed66056481e9db93257fc9ee3f4.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1793,
          name: "Big Burger Load it up with Xtra Cheese Mobile",
          uuid: "dc26a56455af4a71b28902a6703202dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/dc26a56455af4a71b28902a6703202dd.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1382,
          name: "Big Juan",
          uuid: "922337fede347c8bb8e0e273a4f06dc99f04bd9c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/922337fede347c8bb8e0e273a4f06dc99f04bd9c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 1383,
          name: "Big Juan Mobile",
          uuid: "7b23517cb555ea647a1960b7ff74ea3e614561a5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7b23517cb555ea647a1960b7ff74ea3e614561a5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1550,
          name: "Bigger Bass Blizzard - Christmas Catch",
          uuid: "1d11f58fc275467fb8063d20d25812d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1d11f58fc275467fb8063d20d25812d2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1551,
          name: "Bigger Bass Blizzard - Christmas Catch Mobile",
          uuid: "d21d45c7fec647389af5448e0ac0b42b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d21d45c7fec647389af5448e0ac0b42b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1364,
          name: "Bigger Bass Bonanza",
          uuid: "d27a551599ae87d4128471426bc030c9a49ceec1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d27a551599ae87d4128471426bc030c9a49ceec1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1365,
          name: "Bigger Bass Bonanza Mobile",
          uuid: "bd4c3254f07e3c22c63ba381198c0153106d7fa4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bd4c3254f07e3c22c63ba381198c0153106d7fa4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1490,
          name: "Black Bull",
          uuid: "4667b0f4bacfe71baea4285e6ca8854ccf4cb218",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4667b0f4bacfe71baea4285e6ca8854ccf4cb218.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1491,
          name: "Black Bull Mobile",
          uuid: "6f7795bb64f8066ab60d4533fe9449a50f4b3f4c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6f7795bb64f8066ab60d4533fe9449a50f4b3f4c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1746,
          name: "Blade & Fangs",
          uuid: "935c82ff0a344750b61ef4bd8875fa69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/935c82ff0a344750b61ef4bd8875fa69.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1747,
          name: "Blade & Fangs Mobile",
          uuid: "d7882e9cfe7d4262883d285814006e54",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d7882e9cfe7d4262883d285814006e54.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1756,
          name: "Blazing Wilds Megaways",
          uuid: "8d1c39989d474758976eaabc6a8794fd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8d1c39989d474758976eaabc6a8794fd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1757,
          name: "Blazing Wilds Megaways Mobile",
          uuid: "455d46d0ffbc46578517466aedcece49",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/455d46d0ffbc46578517466aedcece49.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1470,
          name: "Bomb Bonanza",
          uuid: "7d6429c68388b3245f611870f0d2582e1fa91a39",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7d6429c68388b3245f611870f0d2582e1fa91a39.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1471,
          name: "Bomb Bonanza Mobile",
          uuid: "2896baf7d7341b256dba442518025d827e5eda7d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2896baf7d7341b256dba442518025d827e5eda7d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1510,
          name: "Book of Golden Sands",
          uuid: "6f1fb8e4e7f5513390cbb94659ba98eee90614e5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6f1fb8e4e7f5513390cbb94659ba98eee90614e5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1511,
          name: "Book of Golden Sands Mobile",
          uuid: "d2d1143eaf7484e7aa6e17e0045a54516b27c4cd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d2d1143eaf7484e7aa6e17e0045a54516b27c4cd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1160,
          name: "Book Of Kingdoms",
          uuid: "cbb5b78d9922404e85b5bc0d1e02c16a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/cbb5b78d9922404e85b5bc0d1e02c16a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1161,
          name: "Book Of Kingdoms Mobile",
          uuid: "175f7a1d6ab347278bfc78ef1fc97e49",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/175f7a1d6ab347278bfc78ef1fc97e49.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1686,
          name: "Book of Tut Megaways",
          uuid: "4414e636cffd49fca8d85bdc424bf1e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4414e636cffd49fca8d85bdc424bf1e5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1687,
          name: "Book of Tut Megaways Mobile",
          uuid: "8489b68b4a514db68d825eb7ce4dead2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8489b68b4a514db68d825eb7ce4dead2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1356,
          name: "Book of Vikings",
          uuid: "4aaf8adba74e41b83987bfcf6c1e41a575f5aa50",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4aaf8adba74e41b83987bfcf6c1e41a575f5aa50.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1357,
          name: "Book of Vikings Mobile",
          uuid: "9091a2dd08d2930dd032f1754af8c0cb57cb0a79",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9091a2dd08d2930dd032f1754af8c0cb57cb0a79.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1384,
          name: "Bounty Gold",
          uuid: "bf433b7b81789c110e3a29a54e9846c057bfa25b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bf433b7b81789c110e3a29a54e9846c057bfa25b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1385,
          name: "Bounty Gold Mobile",
          uuid: "b0668aa6ded4e605528cfd342d84a69e751fca32",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b0668aa6ded4e605528cfd342d84a69e751fca32.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1858,
          name: "Bow of Artemis",
          uuid: "4cb113d615444460b88006202b2d6efa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4cb113d615444460b88006202b2d6efa.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1859,
          name: "Bow of Artemis Mobile",
          uuid: "91ed014bac2c47edb97fb0628b6c72ae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/91ed014bac2c47edb97fb0628b6c72ae.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1060,
          name: "Buffalo King",
          uuid: "f16e7ccf4bc74a90b3a3aedf472e96f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f16e7ccf4bc74a90b3a3aedf472e96f6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1314,
          name: "Buffalo King Megaways",
          uuid: "6dc00f3b4d2c1c761d48e952c44bc65fc5e3f84f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6dc00f3b4d2c1c761d48e952c44bc65fc5e3f84f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1315,
          name: "Buffalo King Megaways Mobile",
          uuid: "ec810fb736051b1720f4d1d94d74421c7c7fe73a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ec810fb736051b1720f4d1d94d74421c7c7fe73a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1061,
          name: "Buffalo King Mobile",
          uuid: "df5069b60fd242a4bc7ce1e671111a5b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/df5069b60fd242a4bc7ce1e671111a5b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1838,
          name: "Buffalo King Untamed Megaways",
          uuid: "bd9dec1da1524f68b7fff596ca532faf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bd9dec1da1524f68b7fff596ca532faf.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1839,
          name: "Buffalo King Untamed Megaways Mobile",
          uuid: "d1af44469c20432fb7afdfb0c2e74e87",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d1af44469c20432fb7afdfb0c2e74e87.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1082,
          name: "Caishen's Cash",
          uuid: "705684e4ff2446cb8e1d59d7a85e553b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/705684e4ff2446cb8e1d59d7a85e553b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1083,
          name: "Caishen's Cash Mobile",
          uuid: "df2abc5ae0174156b4724c534f1c90e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/df2abc5ae0174156b4724c534f1c90e3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1074,
          name: "Caishen's Gold",
          uuid: "7dcd9e790002493da6a47050f4e203c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7dcd9e790002493da6a47050f4e203c6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1075,
          name: "Caishen's Gold Mobile",
          uuid: "20ea1d3131124b5f8cbc28294924a367",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/20ea1d3131124b5f8cbc28294924a367.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1680,
          name: "Candy Blitz",
          uuid: "1a78d62a18e7442395feef4b4fe6fe53",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1a78d62a18e7442395feef4b4fe6fe53.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1808,
          name: "Candy Blitz Bombs",
          uuid: "8f2b3cd89f92467084ebcc21ba07f210",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8f2b3cd89f92467084ebcc21ba07f210.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1809,
          name: "Candy Blitz Bombs Mobile",
          uuid: "32ed9759ae184c93b6178ce98e6b138a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/32ed9759ae184c93b6178ce98e6b138a.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1681,
          name: "Candy Blitz Mobile",
          uuid: "4f1fc5bad7f74ae8a21f1d424f486274",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4f1fc5bad7f74ae8a21f1d424f486274.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1736,
          name: "Candy Jar Clusters",
          uuid: "5b35c39ebbb14f81b9c113fcba5bf9eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5b35c39ebbb14f81b9c113fcba5bf9eb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1737,
          name: "Candy Jar Clusters Mobile",
          uuid: "f9efbfb0203a468cb84092bc135e1f48",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f9efbfb0203a468cb84092bc135e1f48.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1528,
          name: "Candy Stars",
          uuid: "0f96164927130b45d1f72069642fd0c1a1b4060d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0f96164927130b45d1f72069642fd0c1a1b4060d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1529,
          name: "Candy Stars Mobile",
          uuid: "d347bf7aea9589b62a5b715964b7a2386beb70c4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d347bf7aea9589b62a5b715964b7a2386beb70c4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1374,
          name: "Cash Bonanza",
          uuid: "3428bec81d9e64101b40b878c05582aa525481a2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3428bec81d9e64101b40b878c05582aa525481a2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1375,
          name: "Cash Bonanza Mobile",
          uuid: "7aa33db7fe8408eda9713bf98ae50f3f2caeda86",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7aa33db7fe8408eda9713bf98ae50f3f2caeda86.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1658,
          name: "Cash Box",
          uuid: "b0a92b0a636b478d8b7cb1b556401083",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b0a92b0a636b478d8b7cb1b556401083.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1659,
          name: "Cash Box Mobile",
          uuid: "dd4ace88239d43c89efb360fe10ecb6b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/dd4ace88239d43c89efb360fe10ecb6b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1704,
          name: "Cash Chips",
          uuid: "c834ce428cf04caabc3045e7338b24a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c834ce428cf04caabc3045e7338b24a1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1705,
          name: "Cash Chips Mobile",
          uuid: "b089779f97d34aa6bdfc634139d402d8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b089779f97d34aa6bdfc634139d402d8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1308,
          name: "Cash Elevator",
          uuid: "656099d9d6b91e1c7aa35115577253fab0134c37",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/656099d9d6b91e1c7aa35115577253fab0134c37.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1309,
          name: "Cash Elevator Mobile",
          uuid: "d429ae9b8feb48dbf8a73d433a03cd417ed37c64",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d429ae9b8feb48dbf8a73d433a03cd417ed37c64.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1464,
          name: "Cash Patrol",
          uuid: "62f146b382c3b1d79e228764e1150a7f4a4cfd45",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/62f146b382c3b1d79e228764e1150a7f4a4cfd45.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1465,
          name: "Cash Patrol Mobile",
          uuid: "b15520aeb9aed45dc3b784679a489818513bf0bd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b15520aeb9aed45dc3b784679a489818513bf0bd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1750,
          name: "Castle of Fire",
          uuid: "b8818304dfad4bb5ba532f3de4b2c351",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b8818304dfad4bb5ba532f3de4b2c351.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1751,
          name: "Castle of Fire Mobile",
          uuid: "b7882b4d66364f6e8562f1f2eae40f02",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b7882b4d66364f6e8562f1f2eae40f02.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1722,
          name: "Chase for Glory",
          uuid: "5410137e709c45448db03fa0aed20a04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5410137e709c45448db03fa0aed20a04.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1723,
          name: "Chase for Glory Mobile",
          uuid: "274fa2a8da574a69b112814bb576e73c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/274fa2a8da574a69b112814bb576e73c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6791,
          name: "Chests of Cai Shen",
          uuid: "171e4168063845fba6d390d79aacfa79",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/171e4168063845fba6d390d79aacfa79.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6792,
          name: "Chests of Cai Shen Mobile",
          uuid: "0b0e1bfb0e4244e482a5c13b5be18410",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0b0e1bfb0e4244e482a5c13b5be18410.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1436,
          name: "Chicken Chase",
          uuid: "0253ed4c2c255462ab88d74c3b3a2adc9efbd7fd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0253ed4c2c255462ab88d74c3b3a2adc9efbd7fd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1437,
          name: "Chicken Chase Mobile",
          uuid: "917500dc0e53be91baa19252a2dce4667dc55abf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/917500dc0e53be91baa19252a2dce4667dc55abf.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1352,
          name: "Chicken Drop",
          uuid: "c986cf464dcf5f6c8520c3920188c961b29914fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c986cf464dcf5f6c8520c3920188c961b29914fb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1353,
          name: "Chicken Drop Mobile",
          uuid: "9a06caef70ea764ebf913f82721f4625c9dcd3f4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9a06caef70ea764ebf913f82721f4625c9dcd3f4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1054,
          name: "Chilli Heat",
          uuid: "0d3c86ac1ef4411c9db4bfc78bcc0343",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0d3c86ac1ef4411c9db4bfc78bcc0343.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1360,
          name: "Chilli Heat Megaways",
          uuid: "a93ccf418224958b31228036a617d8781d42ac31",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a93ccf418224958b31228036a617d8781d42ac31.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1361,
          name: "Chilli Heat Megaways Mobile",
          uuid: "1b5f83cea69e0bc28919725fd1a99a4bd69bc9fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1b5f83cea69e0bc28919725fd1a99a4bd69bc9fb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1055,
          name: "Chilli Heat Mobile",
          uuid: "d0e14396867f4b6d87cf0b248f69d7a8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d0e14396867f4b6d87cf0b248f69d7a8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1394,
          name: "Christmas Big Bass Bonanza",
          uuid: "b0451854a535650e4762635d2c8107260895ca4e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b0451854a535650e4762635d2c8107260895ca4e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1395,
          name: "Christmas Big Bass Bonanza Mobile",
          uuid: "a26a04ec4c7bceb07952cecbad23b8ac0124c649",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a26a04ec4c7bceb07952cecbad23b8ac0124c649.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1162,
          name: "Christmas Carol Megaways",
          uuid: "82bc786f894e4c789a1e847b701a770b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/82bc786f894e4c789a1e847b701a770b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1163,
          name: "Christmas Carol Megaways Mobile",
          uuid: "cabcdfc768504b40a0cca8d0373711bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/cabcdfc768504b40a0cca8d0373711bb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1454,
          name: "Cleocatra",
          uuid: "598e5ae52d6c40d6fa5201fef63a441147263bf0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/598e5ae52d6c40d6fa5201fef63a441147263bf0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1455,
          name: "Cleocatra Mobile",
          uuid: "89531ec78b58060169d4dcbde1d947dc35b8899a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/89531ec78b58060169d4dcbde1d947dc35b8899a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1440,
          name: "Clover Gold",
          uuid: "c49938177e7567364c2989e3154665e28e9e254c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c49938177e7567364c2989e3154665e28e9e254c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1441,
          name: "Clover Gold Mobile",
          uuid: "89404f19b97af676b4bd6c3ddbac16fccef59bf8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/89404f19b97af676b4bd6c3ddbac16fccef59bf8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1584,
          name: "Club Tropicana",
          uuid: "7255d5612c38444c921a601207175017",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7255d5612c38444c921a601207175017.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1585,
          name: "Club Tropicana Mobile",
          uuid: "7eddf7ea4060413a86e97ec0dacebc6f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7eddf7ea4060413a86e97ec0dacebc6f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1410,
          name: "Colossal Cash Zone",
          uuid: "28a01bf1e75890ac83ed3aa45a18fccd276aa79b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/28a01bf1e75890ac83ed3aa45a18fccd276aa79b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1411,
          name: "Colossal Cash Zone Mobile",
          uuid: "331a7c3d822b213effb38b3d8e3d25b39cf380c6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/331a7c3d822b213effb38b3d8e3d25b39cf380c6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1346,
          name: "Congo Cash",
          uuid: "1a874db7ccec3dc60c38fef4e9da7140b8a9a130",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1a874db7ccec3dc60c38fef4e9da7140b8a9a130.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1347,
          name: "Congo Cash Mobile",
          uuid: "744b80aa7a18944b7db8869a3024eecd84edb947",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/744b80aa7a18944b7db8869a3024eecd84edb947.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6777,
          name: "Congo Cash XL",
          uuid: "8f3aa47cc84541409ff4d9e4a83d1806",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8f3aa47cc84541409ff4d9e4a83d1806.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6778,
          name: "Congo Cash XL Mobile",
          uuid: "35857a6f3fa44785b857875aec4505da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/35857a6f3fa44785b857875aec4505da.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1472,
          name: "Cosmic Cash",
          uuid: "dcd04da127681d99c7725eccaa3ff771950c4676",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dcd04da127681d99c7725eccaa3ff771950c4676.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1473,
          name: "Cosmic Cash Mobile",
          uuid: "eb5c57e11031a5495972cfb0e9455572a0387447",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/eb5c57e11031a5495972cfb0e9455572a0387447.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1642,
          name: "Country Farming",
          uuid: "63aac2bed5f647b981e18fd435dac68d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/63aac2bed5f647b981e18fd435dac68d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1643,
          name: "Country Farming Mobile",
          uuid: "8e2ee4802b8c46b68099948134da7a6b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8e2ee4802b8c46b68099948134da7a6b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1598,
          name: "Cowboy Coins",
          uuid: "236e24af00ba472a855945af5c7104ba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/236e24af00ba472a855945af5c7104ba.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1599,
          name: "Cowboy Coins Mobile",
          uuid: "6ad42cece1514b6d855e91d96bbf900d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6ad42cece1514b6d855e91d96bbf900d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1164,
          name: "Cowboys Gold",
          uuid: "6545509fc99b4ac09d025b3e68aa421f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6545509fc99b4ac09d025b3e68aa421f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1165,
          name: "Cowboys Gold Mobile",
          uuid: "0b6dc423dc0e4eb8b7142241d6b8a92d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0b6dc423dc0e4eb8b7142241d6b8a92d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1843,
          name: "Crank It Up",
          uuid: "7816763ddd9eb176eddf15e431c83351e217f3db",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7816763ddd9eb176eddf15e431c83351e217f3db.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1842,
          name: "Crank It Up Mobile",
          uuid: "1bb70dc6673afd43fcdfa1789f6eac9fa6a8c7eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1bb70dc6673afd43fcdfa1789f6eac9fa6a8c7eb.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1512,
          name: "Crown of Fire",
          uuid: "f269148f55e6fe806cf4603ba103ec655fd49a25",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f269148f55e6fe806cf4603ba103ec655fd49a25.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1513,
          name: "Crown of Fire Mobile",
          uuid: "7d26918f74c3ba1065c1242abf8294ec2c58bf20",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7d26918f74c3ba1065c1242abf8294ec2c58bf20.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1398,
          name: "Crystal Caverns Megaways",
          uuid: "a2ffdf278d9b96ea076465dea72d6b5da4cbde3f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a2ffdf278d9b96ea076465dea72d6b5da4cbde3f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1399,
          name: "Crystal Caverns Megaways Mobile",
          uuid: "feb205dba07273613bb8fd27bde589d31356322d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/feb205dba07273613bb8fd27bde589d31356322d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1138,
          name: "Curse of the Werewolf Megaways",
          uuid: "67e7784ab3fb4ab092455069b4560b1b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/67e7784ab3fb4ab092455069b4560b1b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1139,
          name: "Curse of the Werewolf Megaways Mobile",
          uuid: "16338d5c6ff1481ebceaf46aa2543824",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/16338d5c6ff1481ebceaf46aa2543824.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1682,
          name: "Cyclops Smash",
          uuid: "25707da53bbb4a45b17ea242c4d25792",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/25707da53bbb4a45b17ea242c4d25792.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1683,
          name: "Cyclops Smash Mobile",
          uuid: "3e9982bdb2174228ad10b30043a02899",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3e9982bdb2174228ad10b30043a02899.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1190,
          name: "Da Vinci's Treasure",
          uuid: "a7041e7781894824aa017d0af7f1c2af",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a7041e7781894824aa017d0af7f1c2af.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1191,
          name: "Da Vinci's Treasure Mobile",
          uuid: "26644ae3462f4f7bacda04316225293a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/26644ae3462f4f7bacda04316225293a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1068,
          name: "Dance Party",
          uuid: "19de618e4bf64edd8c3bba33bea87f13",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/19de618e4bf64edd8c3bba33bea87f13.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1069,
          name: "Dance Party Mobile",
          uuid: "d1be95bbe1fe42c7b9a9ec99726fa7b3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d1be95bbe1fe42c7b9a9ec99726fa7b3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1376,
          name: "Day of Dead",
          uuid: "5875c2d87fb682840350f22a109fbc678d2b0e81",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5875c2d87fb682840350f22a109fbc678d2b0e81.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1377,
          name: "Day of Dead Mobile",
          uuid: "d569c0daeae7583c06a11bdf77cdafc7cc91253e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d569c0daeae7583c06a11bdf77cdafc7cc91253e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1702,
          name: "Demon Pots",
          uuid: "667a750c708d41518097dcb5b550daa3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/667a750c708d41518097dcb5b550daa3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1703,
          name: "Demon Pots Mobile",
          uuid: "304393ae9f704ecca13e1184273dfb36",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/304393ae9f704ecca13e1184273dfb36.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1831,
          name: "Devilicious",
          uuid: "e946b58eca4c286d49d993883032a3b1a0d161be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e946b58eca4c286d49d993883032a3b1a0d161be.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1830,
          name: "Devilicious Mobile",
          uuid: "a8a61b465b7d9b3bb9bc62819e40d5fe68e6a8cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a8a61b465b7d9b3bb9bc62819e40d5fe68e6a8cf.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1666,
          name: "Diamond Cascade",
          uuid: "58c3401b98e44518966a14673f44d5a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/58c3401b98e44518966a14673f44d5a1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1667,
          name: "Diamond Cascade Mobile",
          uuid: "f3bded639ec44f269b911f380532cdc3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f3bded639ec44f269b911f380532cdc3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1022,
          name: "Diamond Strike",
          uuid: "2d4fd44cdeb4450e9f0b8104db5fbd8c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2d4fd44cdeb4450e9f0b8104db5fbd8c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1276,
          name: "Diamond Strike 100,000",
          uuid: "7d4868b2cc4f41c2a9df352419dbb8c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7d4868b2cc4f41c2a9df352419dbb8c2.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1277,
          name: "Diamond Strike 100,000 Mobile",
          uuid: "7ff82ada8a504c23b5a3425724455814",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7ff82ada8a504c23b5a3425724455814.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1023,
          name: "Diamond Strike Mobile",
          uuid: "0520aa1fc1b446d6a3235da6f9d2fc8a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0520aa1fc1b446d6a3235da6f9d2fc8a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1628,
          name: "Diamonds Of Egypt",
          uuid: "0b9c63f961c74a41a49dbe1b17ead643",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0b9c63f961c74a41a49dbe1b17ead643.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1629,
          name: "Diamonds Of Egypt Mobile",
          uuid: "0a4db74a6fd84efe957bf3e73191028f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0a4db74a6fd84efe957bf3e73191028f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1734,
          name: "Ding Dong Christmas Bells",
          uuid: "b67ab23b3f934df689e0ad949b4ac7ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b67ab23b3f934df689e0ad949b4ac7ef.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1735,
          name: "Ding Dong Christmas Bells Mobile",
          uuid: "231d6e44f2374405a989a6cb3a2539e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/231d6e44f2374405a989a6cb3a2539e5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1494,
          name: "Down the Rails",
          uuid: "8b1623e97d89c63dbf84d2b7e7ace6b96a0ff1bf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8b1623e97d89c63dbf84d2b7e7ace6b96a0ff1bf.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1495,
          name: "Down the Rails Mobile",
          uuid: "5c859168023273102a7ecd78a9aa3d4c193ddb5b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5c859168023273102a7ecd78a9aa3d4c193ddb5b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1134,
          name: "Drago - Jewels of Fortune",
          uuid: "de0c296c3c8c48608773ee36406812f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/de0c296c3c8c48608773ee36406812f7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1135,
          name: "Drago - Jewels of Fortune Mobile",
          uuid: "8fb2b5718e4246b99874fb5bf91cb4e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8fb2b5718e4246b99874fb5bf91cb4e0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1862,
          name: "Dragon Gold 88",
          uuid: "c9921c977d0f48acb940ece9f98917f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c9921c977d0f48acb940ece9f98917f4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1863,
          name: "Dragon Gold 88 Mobile",
          uuid: "a7f0c2e72f4a40b28b26cffbe123e041",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a7f0c2e72f4a40b28b26cffbe123e041.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1560,
          name: "Dragon Hero",
          uuid: "be6fd3c9e05a469f9763cbe3aea296ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/be6fd3c9e05a469f9763cbe3aea296ff.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1561,
          name: "Dragon Hero Mobile",
          uuid: "fd7e6fa58bc543d3ac79d0fc3a9a8465",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/fd7e6fa58bc543d3ac79d0fc3a9a8465.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1300,
          name: "Dragon Hot Hold and Spin",
          uuid: "d77f04e35944b3de9e03a6e0ef79b28998d7bf07",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d77f04e35944b3de9e03a6e0ef79b28998d7bf07.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1301,
          name: "Dragon Hot Hold and Spin Mobile",
          uuid: "da7d799ceed004ec8d10d99db937de0c2eda411b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/da7d799ceed004ec8d10d99db937de0c2eda411b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1114,
          name: "Dragon Kingdom",
          uuid: "6e95cef1cef64c64a2772fb8c36b4d29",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6e95cef1cef64c64a2772fb8c36b4d29.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1342,
          name: "Dragon Kingdom - Eyes of Fire",
          uuid: "5159ccd5b5305f79d1464da3157cf2ba71739fc7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5159ccd5b5305f79d1464da3157cf2ba71739fc7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1343,
          name: "Dragon Kingdom - Eyes of Fire Mobile",
          uuid: "c9a2013816b940e5df981830a81c03cd93c6ef01",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c9a2013816b940e5df981830a81c03cd93c6ef01.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1115,
          name: "Dragon Kingdom Mobile",
          uuid: "7f130e05cff44db79748e12ffb54a820",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7f130e05cff44db79748e12ffb54a820.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1434,
          name: "Drill that Gold",
          uuid: "7bffe70168e87fc1c3163eb7a22657eb18caf4e0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7bffe70168e87fc1c3163eb7a22657eb18caf4e0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1435,
          name: "Drill that Gold Mobile",
          uuid: "8322f1cbc91deec636aa91bddb019a4757235921",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8322f1cbc91deec636aa91bddb019a4757235921.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1816,
          name: "Dwarf & Dragon",
          uuid: "c01c239130df4855952e657c3080119c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c01c239130df4855952e657c3080119c.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1817,
          name: "Dwarf & Dragon Mobile",
          uuid: "804caf9e9ec2464c9324d6d6b0433e5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/804caf9e9ec2464c9324d6d6b0433e5c.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1248,
          name: "Dwarven Gold",
          uuid: "5d1d24270c554f8bba4ef9c2181f3388",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5d1d24270c554f8bba4ef9c2181f3388.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1136,
          name: "Dwarven Gold Deluxe",
          uuid: "31b63895d71e45969020ad15ad8e9d5a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/31b63895d71e45969020ad15ad8e9d5a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1137,
          name: "Dwarven Gold Deluxe Mobile",
          uuid: "eab18af53a6041f69b17473cf27dcd01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/eab18af53a6041f69b17473cf27dcd01.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1249,
          name: "Dwarven Gold Mobile",
          uuid: "835b48b40b8645c1885a54ff39d920cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/835b48b40b8645c1885a54ff39d920cf.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1855,
          name: "Dynamite Diggin Doug",
          uuid: "9fac199d719341b3a8373c10287d6546",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9fac199d719341b3a8373c10287d6546.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1854,
          name: "Dynamite Diggin Doug Mobile",
          uuid: "67d3f48eb47646aaad89d423974ee051",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/67d3f48eb47646aaad89d423974ee051.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1150,
          name: "Egyptian Fortunes",
          uuid: "8b7a6795380744c8a5acbfa9443fd6f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8b7a6795380744c8a5acbfa9443fd6f0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1151,
          name: "Egyptian Fortunes Mobile",
          uuid: "07ba83cb076645c4978d71f0e57d70af",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/07ba83cb076645c4978d71f0e57d70af.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1414,
          name: "Elemental Gems Megaways",
          uuid: "f9e029c0570574268720872d5244005c3fb63c9d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f9e029c0570574268720872d5244005c3fb63c9d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1415,
          name: "Elemental Gems Megaways Mobile",
          uuid: "620965df73da361e57cf9dd5b6cb71d27173ac72",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/620965df73da361e57cf9dd5b6cb71d27173ac72.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1166,
          name: "Emerald King",
          uuid: "a44b61e79a604a0282ecfd796582181e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a44b61e79a604a0282ecfd796582181e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1167,
          name: "Emerald King Mobile",
          uuid: "f8cd95409e48445c8126b4f1d34726fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f8cd95409e48445c8126b4f1d34726fa.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1348,
          name: "Emerald King Rainbow Road",
          uuid: "3856c84397fbef488bf03fade831ff767878b14e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3856c84397fbef488bf03fade831ff767878b14e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1349,
          name: "Emerald King Rainbow Road Mobile",
          uuid: "a1e561348d9e4889df83980f544fabf8b599d478",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a1e561348d9e4889df83980f544fabf8b599d478.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1350,
          name: "Empty the Bank",
          uuid: "756f2bb4c37a78a248dd8a65fa0b3b0bb016f4b1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/756f2bb4c37a78a248dd8a65fa0b3b0bb016f4b1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1351,
          name: "Empty the Bank Mobile",
          uuid: "0a8000156f1c43687f71ae40a80d456841627f22",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0a8000156f1c43687f71ae40a80d456841627f22.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1622,
          name: "Excalibur Unleashed",
          uuid: "d1c7293ab50446c8a445843bcce5c263",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d1c7293ab50446c8a445843bcce5c263.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1623,
          name: "Excalibur Unleashed Mobile",
          uuid: "bcc83adb706143a2a72be47d3749aba5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bcc83adb706143a2a72be47d3749aba5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1088,
          name: "Extra Juicy",
          uuid: "1d681a4b6e704c65ab0921d610b44036",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1d681a4b6e704c65ab0921d610b44036.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1420,
          name: "Extra Juicy Megaways",
          uuid: "f7ea62a49939b56eb59dea3ff7e9ad4fe78a6b0e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f7ea62a49939b56eb59dea3ff7e9ad4fe78a6b0e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1421,
          name: "Extra Juicy Megaways Mobile",
          uuid: "78acb0cbade59b056cb4d3114c3677e547f36e8f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/78acb0cbade59b056cb4d3114c3677e547f36e8f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1089,
          name: "Extra Juicy Mobile",
          uuid: "629dcae332554c33ab99c381026ff62d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/629dcae332554c33ab99c381026ff62d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1438,
          name: "Eye of Cleopatra",
          uuid: "dcd40b821857475a91c4d38c8194debb3ec1e867",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dcd40b821857475a91c4d38c8194debb3ec1e867.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1439,
          name: "Eye of Cleopatra Mobile",
          uuid: "df8d74ba6f452670653935dcdeff2ed54e4023d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/df8d74ba6f452670653935dcdeff2ed54e4023d7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1340,
          name: "Eye of the Storm",
          uuid: "56f749eb70d5b54e4076549027e8e48c8d024ba5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/56f749eb70d5b54e4076549027e8e48c8d024ba5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1341,
          name: "Eye of the Storm Mobile",
          uuid: "ed8bdaadc456d10d20da5b9d3cb3440ec01c6ae3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ed8bdaadc456d10d20da5b9d3cb3440ec01c6ae3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1158,
          name: "Fairytale Fortune",
          uuid: "1d3d0ebd02b742ebb9ff1b20b6a65dc3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1d3d0ebd02b742ebb9ff1b20b6a65dc3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1159,
          name: "Fairytale Fortune Mobile",
          uuid: "20038fbf9157434887ed070650986d6e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/20038fbf9157434887ed070650986d6e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1644,
          name: "Fat Panda",
          uuid: "5c4142f887264a2484f0f56774aea359",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5c4142f887264a2484f0f56774aea359.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1645,
          name: "Fat Panda Mobile",
          uuid: "829cb4ba9ee042379bdaf6003db489b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/829cb4ba9ee042379bdaf6003db489b0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1036,
          name: "Fire 88",
          uuid: "06a6af2ba88042b581cae103275012cb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/06a6af2ba88042b581cae103275012cb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1037,
          name: "Fire 88 Mobile",
          uuid: "55723f0aaf4f49a89086bb9ac59e5cec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/55723f0aaf4f49a89086bb9ac59e5cec.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1576,
          name: "Fire Archer",
          uuid: "f2215891a7ed42eaa8f8def49390fc89",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f2215891a7ed42eaa8f8def49390fc89.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1577,
          name: "Fire Archer Mobile",
          uuid: "6971441995cc419dba1a09735a3a3e5b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6971441995cc419dba1a09735a3a3e5b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1498,
          name: "Fire Hot 100",
          uuid: "75440cf27ca6fb69d99eff604355ee29523292be",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/75440cf27ca6fb69d99eff604355ee29523292be.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1499,
          name: "Fire Hot 100 Mobile",
          uuid: "a56e8f972d6e560ef86a9a26ec3b40fced75c45b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a56e8f972d6e560ef86a9a26ec3b40fced75c45b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1502,
          name: "Fire Hot 20",
          uuid: "122ceaa096963aa5b1a073e735f336dcb2cca9ca",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/122ceaa096963aa5b1a073e735f336dcb2cca9ca.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1503,
          name: "Fire Hot 20 Mobile",
          uuid: "8e2338c9805efa7012d42c853d297d7c76ec421d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8e2338c9805efa7012d42c853d297d7c76ec421d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1500,
          name: "Fire Hot 40",
          uuid: "2f8879d921cb0bca56ab9b7c48c967ff0b52fbe3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2f8879d921cb0bca56ab9b7c48c967ff0b52fbe3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1501,
          name: "Fire Hot 40 Mobile",
          uuid: "78f82c8f4f13fb6336240c9c8a8767af19e84c16",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/78f82c8f4f13fb6336240c9c8a8767af19e84c16.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1504,
          name: "Fire Hot 5",
          uuid: "51c59667321210a91b5e5b9a7cb708132c7eb1f6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/51c59667321210a91b5e5b9a7cb708132c7eb1f6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1505,
          name: "Fire Hot 5 Mobile",
          uuid: "c30d82607a675d6cc2443a8a4c9cf49317a2d0b4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c30d82607a675d6cc2443a8a4c9cf49317a2d0b4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1790,
          name: "Fire Portals",
          uuid: "7c1d8a5b1539440893cbde6ff8e3733f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7c1d8a5b1539440893cbde6ff8e3733f.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1791,
          name: "Fire Portals Mobile",
          uuid: "287a4355190a46fabbbd146bbec6cc72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/287a4355190a46fabbbd146bbec6cc72.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1742,
          name: "Fire Stampede",
          uuid: "3af58812c6cf4b6d8b56b71f1cc7fe5b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3af58812c6cf4b6d8b56b71f1cc7fe5b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1743,
          name: "Fire Stampede Mobile",
          uuid: "92ea2bcd70504a1a9d528907fff07bed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/92ea2bcd70504a1a9d528907fff07bed.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1016,
          name: "Fire Strike",
          uuid: "cf72e6d8e61a43cba5d78cd04f03eec9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/cf72e6d8e61a43cba5d78cd04f03eec9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1446,
          name: "Fire Strike 2",
          uuid: "b8b6fdbdea059786f32b0cbcfa31787ae844add3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b8b6fdbdea059786f32b0cbcfa31787ae844add3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1447,
          name: "Fire Strike 2 Mobile",
          uuid: "d9abb15e7e080623de087789cdf4a561558d5b9c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d9abb15e7e080623de087789cdf4a561558d5b9c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1017,
          name: "Fire Strike Mobile",
          uuid: "4e3c277ecf304aaaadcf39a8bd005130",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4e3c277ecf304aaaadcf39a8bd005130.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1524,
          name: "Firebird Spirit",
          uuid: "f2f3d48935ef275389a83ac6575572df5805571a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f2f3d48935ef275389a83ac6575572df5805571a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1525,
          name: "Firebird Spirit Mobile",
          uuid: "f04cce59f4530c773bbed5feb8f6eb9cfcd93978",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f04cce59f4530c773bbed5feb8f6eb9cfcd93978.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1572,
          name: "Fish Eye",
          uuid: "203b7409a8bc4dac81132484fec514ab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/203b7409a8bc4dac81132484fec514ab.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1573,
          name: "Fish Eye Mobile",
          uuid: "0f7253ba04c642ef81701b265bcac136",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0f7253ba04c642ef81701b265bcac136.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1330,
          name: "Fishin' Reels",
          uuid: "dd8e2edda7d4ca20308abb64334a33b50b65c735",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dd8e2edda7d4ca20308abb64334a33b50b65c735.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1331,
          name: "Fishin' Reels Mobile",
          uuid: "4d0acf49e63bfe7430b6488aefedfb486e89feee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4d0acf49e63bfe7430b6488aefedfb486e89feee.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1640,
          name: "Floating Dragon - Dragon Boat Festival",
          uuid: "d75ab207d0a4419eb2f803f67e9c6f75",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d75ab207d0a4419eb2f803f67e9c6f75.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1641,
          name: "Floating Dragon - Dragon Boat Festival Mobile",
          uuid: "7f98eb04150644dc81cc2104365fe383",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7f98eb04150644dc81cc2104365fe383.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1318,
          name: "Floating Dragon Hold&Spin",
          uuid: "4a83743d842174b2904ef23f9e7224513abdce52",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4a83743d842174b2904ef23f9e7224513abdce52.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1319,
          name: "Floating Dragon Hold&Spin Mobile",
          uuid: "7050b3eb4ad07475755e8cdbab6faeb6705f2d8c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7050b3eb4ad07475755e8cdbab6faeb6705f2d8c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1519,
          name: "Floating Dragon Megaways",
          uuid: "9f81c66b29dc4e94a043bb0173c39351",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9f81c66b29dc4e94a043bb0173c39351.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1518,
          name: "Floating Dragon Megaways Mobile",
          uuid: "bd401ffcb8004b2bbd4ded5bc8c6377e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bd401ffcb8004b2bbd4ded5bc8c6377e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1740,
          name: "Floating Dragon New Year Festival Ultra Megaways Hold & Spin",
          uuid: "721c23cc77e4463fb8d87e29d0176ebd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/721c23cc77e4463fb8d87e29d0176ebd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1741,
          name: "Floating Dragon New Year Festival Ultra Megaways Hold & Spin Mobile",
          uuid: "650b8379996241d58676412c98df889c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/650b8379996241d58676412c98df889c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1674,
          name: "Forge of Olympus",
          uuid: "e919448a2aa14f339793d3e5ff7c1338",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e919448a2aa14f339793d3e5ff7c1338.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1675,
          name: "Forge of Olympus Mobile",
          uuid: "5d45c6b271a74cc094183380a86cca5f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5d45c6b271a74cc094183380a86cca5f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1876,
          name: "Forging Wilds",
          uuid: "34652961405f445383eccb7c4fe2bebe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/34652961405f445383eccb7c4fe2bebe.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1877,
          name: "Forging Wilds Mobile",
          uuid: "35a6db4c079b4af98dd2aed8f22c5f62",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/35a6db4c079b4af98dd2aed8f22c5f62.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6789,
          name: "Fortune Hit'n Roll",
          uuid: "532ee6d669df453fbd0553358437f959",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/532ee6d669df453fbd0553358437f959.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6790,
          name: "Fortune Hit'n Roll Mobile",
          uuid: "396f5b64cac846bbad11eed66f4bca6c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/396f5b64cac846bbad11eed66f4bca6c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1460,
          name: "Fortune of Giza",
          uuid: "7844e18ba8be57a61988fb987423564148da386f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7844e18ba8be57a61988fb987423564148da386f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1461,
          name: "Fortune of Giza Mobile",
          uuid: "52493d40ffd37245662f0e928cb73a235c871a1d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/52493d40ffd37245662f0e928cb73a235c871a1d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1688,
          name: "Fortunes of Aztec",
          uuid: "2b2c679a748244eead9d9fbaf5002704",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2b2c679a748244eead9d9fbaf5002704.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1689,
          name: "Fortunes of Aztec Mobile",
          uuid: "db08ee6cdd114cb2b463b80d23d5d4bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/db08ee6cdd114cb2b463b80d23d5d4bf.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1815,
          name: "Front Runner Odds On",
          uuid: "bc8d0c5fc3e247d6823ba581c524c059",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bc8d0c5fc3e247d6823ba581c524c059.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1814,
          name: "Front Runner Odds On Mobile",
          uuid: "e9880595a8274621a15e2aaa0bb01cbf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e9880595a8274621a15e2aaa0bb01cbf.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1684,
          name: "Frozen Tropics",
          uuid: "4a5b175fa0684da8b2ebb262f633135e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4a5b175fa0684da8b2ebb262f633135e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1685,
          name: "Frozen Tropics Mobile",
          uuid: "951c38398f9b4399be6b031f4f964579",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/951c38398f9b4399be6b031f4f964579.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1180,
          name: "Fruit Party",
          uuid: "ecd393d50486491a8378a5937ba9f5a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ecd393d50486491a8378a5937ba9f5a9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1354,
          name: "Fruit Party 2",
          uuid: "d3458eff5d4b83412f9cd0151eacb1746bdf9006",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d3458eff5d4b83412f9cd0151eacb1746bdf9006.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1355,
          name: "Fruit Party 2 Mobile",
          uuid: "7b2bad4b010725295e99c98ee6b43e5b47b90ca0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7b2bad4b010725295e99c98ee6b43e5b47b90ca0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1181,
          name: "Fruit Party Mobile",
          uuid: "e2f6f13f287f472bbc10c79820583f11",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e2f6f13f287f472bbc10c79820583f11.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1130,
          name: "Fruit Rainbow",
          uuid: "8c27604ce0984184920933d22d3f603f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8c27604ce0984184920933d22d3f603f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1131,
          name: "Fruit Rainbow Mobile",
          uuid: "d3b7c9169f134cb7ba876c7822e5dc9a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d3b7c9169f134cb7ba876c7822e5dc9a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1810,
          name: "Fruity Treats",
          uuid: "13aa8023bf1b4be0a033d6a1cb26bdb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/13aa8023bf1b4be0a033d6a1cb26bdb3.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1811,
          name: "Fruity Treats Mobile",
          uuid: "71b5bf15aad44126b6eb9dd7cc6851b5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/71b5bf15aad44126b6eb9dd7cc6851b5.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1548,
          name: "Fury of Odin Megaways",
          uuid: "2d5dffb6db3b4180b6128ed1b603ae86",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2d5dffb6db3b4180b6128ed1b603ae86.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1549,
          name: "Fury of Odin Megaways Mobile",
          uuid: "c59be3ce2f36463b8e272bc7a64fce0a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c59be3ce2f36463b8e272bc7a64fce0a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1334,
          name: "Gates of Olympus",
          uuid: "5878c98bf96de5c3ecb4fa37ddb45928da161525",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5878c98bf96de5c3ecb4fa37ddb45928da161525.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1744,
          name: "Gates of Olympus 1000",
          uuid: "221101cf569449de93de210b3e0eadfa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/221101cf569449de93de210b3e0eadfa.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1745,
          name: "Gates of Olympus 1000 Mobile",
          uuid: "fec310babd42482597380c8933cd761a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/fec310babd42482597380c8933cd761a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1335,
          name: "Gates of Olympus Mobile",
          uuid: "260d4a6e9f99c91940517ac650330683f6f42408",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/260d4a6e9f99c91940517ac650330683f6f42408.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1408,
          name: "Gates of Valhalla",
          uuid: "6040bd3fd53892b784ea446ee1b9dfb8cfa3a25d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6040bd3fd53892b784ea446ee1b9dfb8cfa3a25d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1409,
          name: "Gates of Valhalla Mobile",
          uuid: "0738ca0dfd8b4f93255335571cdf1d65bc9d3758",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0738ca0dfd8b4f93255335571cdf1d65bc9d3758.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1778,
          name: "Gears of Horus",
          uuid: "37a337a1a17f4070855919c420a7d0bd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/37a337a1a17f4070855919c420a7d0bd.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1779,
          name: "Gears of Horus Mobile",
          uuid: "17e3ad6fd61b4fb08987b4507ab42c96",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/17e3ad6fd61b4fb08987b4507ab42c96.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1878,
          name: "Gem Elevator",
          uuid: "a36519e2b440425295fd66d034e252ee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a36519e2b440425295fd66d034e252ee.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1879,
          name: "Gem Elevator Mobile",
          uuid: "de0d25099e23463d9d4df3017d90a2a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/de0d25099e23463d9d4df3017d90a2a9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1010,
          name: "Gems Bonanza",
          uuid: "e268b9d3475b4409ac5db12ba52d82df",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e268b9d3475b4409ac5db12ba52d82df.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1011,
          name: "Gems Bonanza Mobile",
          uuid: "447bad2f3ce7437d988dc3c36a4b0172",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/447bad2f3ce7437d988dc3c36a4b0172.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1542,
          name: "Gems of Serengeti",
          uuid: "3dd40b62654f46589479391f492ec23c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3dd40b62654f46589479391f492ec23c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1543,
          name: "Gems of Serengeti Mobile",
          uuid: "b8d42b1d77614a85b91797e4f0fd6d77",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b8d42b1d77614a85b91797e4f0fd6d77.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1244,
          name: "Glorious Rome",
          uuid: "080ad435821944ccabffb34749fed889",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/080ad435821944ccabffb34749fed889.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1245,
          name: "Glorious Rome Mobile",
          uuid: "512e51bd8bb64b8c9ba31ff34f4671ee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/512e51bd8bb64b8c9ba31ff34f4671ee.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1448,
          name: "Goblin Heist Powernudge",
          uuid: "89891e59f654a6910f4b7f58bb9a7360303d6d9f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/89891e59f654a6910f4b7f58bb9a7360303d6d9f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1449,
          name: "Goblin Heist Powernudge Mobile",
          uuid: "906f639d196e5a125ab7c45843cff58c88d55341",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/906f639d196e5a125ab7c45843cff58c88d55341.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1610,
          name: "Gods of Giza",
          uuid: "963ac9a51b2e40a085da4f7c4260591b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/963ac9a51b2e40a085da4f7c4260591b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1611,
          name: "Gods of Giza Mobile",
          uuid: "ace3098542a64d58bb2248dcc75f8708",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ace3098542a64d58bb2248dcc75f8708.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1692,
          name: "Gold Oasis",
          uuid: "03db48f63876416c99ee6bd7a24b98e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/03db48f63876416c99ee6bd7a24b98e7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1693,
          name: "Gold Oasis Mobile",
          uuid: "b624acc68ee7407998c85100c4da427c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b624acc68ee7407998c85100c4da427c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1404,
          name: "Gold Party",
          uuid: "c1beef818ada827c3f4926d3775349b4970ed70d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c1beef818ada827c3f4926d3775349b4970ed70d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1405,
          name: "Gold Party Mobile",
          uuid: "3f0dc2ce803c18ae99484de7e2fba7b6bc4da1fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3f0dc2ce803c18ae99484de7e2fba7b6bc4da1fb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1116,
          name: "Gold Rush",
          uuid: "bea8862dd2ee43209075202a2057f504",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bea8862dd2ee43209075202a2057f504.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1274,
          name: "Gold Rush 250,000",
          uuid: "5de34ecc53d045a0b35b79ff4df4cb4d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5de34ecc53d045a0b35b79ff4df4cb4d.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1275,
          name: "Gold Rush 250,000 Mobile",
          uuid: "8431246a2ff44e938bf89310c6d4ee3e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8431246a2ff44e938bf89310c6d4ee3e.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1117,
          name: "Gold Rush Mobile",
          uuid: "824526741bad4909a63314e8a4d62bb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/824526741bad4909a63314e8a4d62bb3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1194,
          name: "Gold Train",
          uuid: "73c8b5303c2e4245b75ce0b4debd77a2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/73c8b5303c2e4245b75ce0b4debd77a2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1195,
          name: "Gold Train Mobile",
          uuid: "0b1ba16121494eb4acf54ce721f3a3b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0b1ba16121494eb4acf54ce721f3a3b0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1761,
          name: "Good Luck & Good Fortune",
          uuid: "12b43e1e304345339e90ebad7dd64c20",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/12b43e1e304345339e90ebad7dd64c20.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1760,
          name: "Good Luck & Good Fortune Mobile",
          uuid: "7d30caaa4590402f830f16d95937ea42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7d30caaa4590402f830f16d95937ea42.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1486,
          name: "Gorilla Mayhem",
          uuid: "3ae8639c456570b081d0521636b351537ebda961",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3ae8639c456570b081d0521636b351537ebda961.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1487,
          name: "Gorilla Mayhem Mobile",
          uuid: "452b56ca11d698372fbc2196ea85444eb9b76611",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/452b56ca11d698372fbc2196ea85444eb9b76611.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1698,
          name: "Gravity Bonanza",
          uuid: "e7c4c3757e914d289393d5b4ab8fb076",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e7c4c3757e914d289393d5b4ab8fb076.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1699,
          name: "Gravity Bonanza Mobile",
          uuid: "5a7ed51750ff40a5a432db349897a7ca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5a7ed51750ff40a5a432db349897a7ca.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1240,
          name: "Great Reef",
          uuid: "6787f784da7240d5ba585c78317e4878",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6787f784da7240d5ba585c78317e4878.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1241,
          name: "Great Reef Mobile",
          uuid: "111cace5d5c7471f95b10da16d3d595e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/111cace5d5c7471f95b10da16d3d595e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1020,
          name: "Great Rhino",
          uuid: "961b769416764ed294beac933f569549",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/961b769416764ed294beac933f569549.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1062,
          name: "Great Rhino Deluxe",
          uuid: "b394c892640f45458e9df5156feaad28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b394c892640f45458e9df5156feaad28.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1063,
          name: "Great Rhino Deluxe Mobile",
          uuid: "f0ce6557cee247ccb6f064942ad9a8c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f0ce6557cee247ccb6f064942ad9a8c0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1038,
          name: "Great Rhino Megaways",
          uuid: "80f81c30c07b4a1d9dc6faf9b8b64294",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/80f81c30c07b4a1d9dc6faf9b8b64294.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1039,
          name: "Great Rhino Megaways Mobile",
          uuid: "3049fe6c697d4da98fecfde5901cc47e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3049fe6c697d4da98fecfde5901cc47e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1021,
          name: "Great Rhino Mobile",
          uuid: "00bbc59c17f7449186f4bc4b0ad40a20",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/00bbc59c17f7449186f4bc4b0ad40a20.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1488,
          name: "Greedy Wolf",
          uuid: "e24538cc5ad5a282bd10ee9ff1d67962608b282a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e24538cc5ad5a282bd10ee9ff1d67962608b282a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1489,
          name: "Greedy Wolf Mobile",
          uuid: "6ca4112b1a3c52934b60515abfe9c10d5c39fb43",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6ca4112b1a3c52934b60515abfe9c10d5c39fb43.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1092,
          name: "Greek Gods",
          uuid: "8e3b909218164e2281b744018ac8598f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8e3b909218164e2281b744018ac8598f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1093,
          name: "Greek Gods Mobile",
          uuid: "dc532c6ddb1e410b84a4d82bb1faa1dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/dc532c6ddb1e410b84a4d82bb1faa1dd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1846,
          name: "Hand of Midas 2",
          uuid: "8ce7938dd1b44efabe88e632fdfdeac6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8ce7938dd1b44efabe88e632fdfdeac6.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1847,
          name: "Hand of Midas 2 Mobile",
          uuid: "c3114bfd93f4407dbc98bf0be785fa35",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c3114bfd93f4407dbc98bf0be785fa35.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1506,
          name: "Happy Hooves",
          uuid: "8336b65e7cc7675c8d590d860385f72ab66d3f9c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8336b65e7cc7675c8d590d860385f72ab66d3f9c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1507,
          name: "Happy Hooves Mobile",
          uuid: "2c6ca8bf764c2ffd1c91527f7a4a240de056c901",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2c6ca8bf764c2ffd1c91527f7a4a240de056c901.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1813,
          name: "Heart of Cleopatra",
          uuid: "78b3328adc5f418f9be47461d6d96597",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/78b3328adc5f418f9be47461d6d96597.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1812,
          name: "Heart of Cleopatra Mobile",
          uuid: "3c29d20b35764ee2a242ab3b06b4918e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3c29d20b35764ee2a242ab3b06b4918e.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1302,
          name: "Heart of Rio",
          uuid: "bf78ace128e48346f306c02e80a3a62dc4417257",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bf78ace128e48346f306c02e80a3a62dc4417257.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1303,
          name: "Heart of Rio Mobile",
          uuid: "afe6f08595c7a5d621bd4ead0530bab74da856d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/afe6f08595c7a5d621bd4ead0530bab74da856d7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1648,
          name: "Heist for the Golden Nuggets",
          uuid: "5a062bf77f5362a4c127a61035bb37ad668480a3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5a062bf77f5362a4c127a61035bb37ad668480a3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1649,
          name: "Heist for the Golden Nuggets Mobile",
          uuid: "682cb5ab1c5bdb152008c27140d7650e7869df2b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/682cb5ab1c5bdb152008c27140d7650e7869df2b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1654,
          name: "Hellvis Wild",
          uuid: "68242a43d20b466caca1a89ef403191a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/68242a43d20b466caca1a89ef403191a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1655,
          name: "Hellvis Wild Mobile",
          uuid: "9a83e7e5ff72400aac973e42ed844028",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9a83e7e5ff72400aac973e42ed844028.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1142,
          name: "Hercules and Pegasus",
          uuid: "2952355e077f4ed1841605e84784c1bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2952355e077f4ed1841605e84784c1bb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1143,
          name: "Hercules and Pegasus Mobile",
          uuid: "5d78230caae2405eaff2ff9d2c82476a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5d78230caae2405eaff2ff9d2c82476a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1214,
          name: "Hercules Son of Zeus",
          uuid: "bb0edfb06c154e70bcd42e026cbad665",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bb0edfb06c154e70bcd42e026cbad665.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1215,
          name: "Hercules Son of Zeus Mobile",
          uuid: "4db2dca1ec4140c6b66aa45a63e78ed1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4db2dca1ec4140c6b66aa45a63e78ed1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1822,
          name: "Heroic Spins",
          uuid: "2c85350f9c274ec0b075a5392b02befe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2c85350f9c274ec0b075a5392b02befe.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1823,
          name: "Heroic Spins Mobile",
          uuid: "97f377a3cad6453aaa371e007bdd7680",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/97f377a3cad6453aaa371e007bdd7680.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6779,
          name: "High Flyer",
          uuid: "930c4ac198024823bfc771c1cd9499e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/930c4ac198024823bfc771c1cd9499e3.png",
          type: "crash",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6780,
          name: "High Flyer Mobile",
          uuid: "f9d26ee3c20c455180ef02ff6a4b748e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f9d26ee3c20c455180ef02ff6a4b748e.png",
          type: "crash",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1254,
          name: "Hockey League",
          uuid: "9317b2c0100748ce9d642368b929e27e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9317b2c0100748ce9d642368b929e27e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1255,
          name: "Hockey League Mobile",
          uuid: "6c8b88865a834beaabfac5c698b0f7bd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6c8b88865a834beaabfac5c698b0f7bd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1256,
          name: "Hockey League Wild Match",
          uuid: "4df4bc8efad247cf9b3de818610f0bc9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4df4bc8efad247cf9b3de818610f0bc9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1257,
          name: "Hockey League Wild Match Mobile",
          uuid: "a839b50e38bb4dac870db95c6e2f69b3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a839b50e38bb4dac870db95c6e2f69b3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1182,
          name: "Honey Honey Honey",
          uuid: "5a1e329daccf4d03a5c1d83b2d4ba79f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5a1e329daccf4d03a5c1d83b2d4ba79f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1183,
          name: "Honey Honey Honey Mobile",
          uuid: "0b012e7c32bc41f390467d647e0be235",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0b012e7c32bc41f390467d647e0be235.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1192,
          name: "Hot Chilli",
          uuid: "83ea9ad0c1eb41c69e191dc8e179bd8a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/83ea9ad0c1eb41c69e191dc8e179bd8a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1193,
          name: "Hot Chilli Mobile",
          uuid: "4c31c684766c4556b3d39af43674ca23",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4c31c684766c4556b3d39af43674ca23.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1316,
          name: "Hot Fiesta",
          uuid: "6ff58191c2bcf1b1c87c7cbb8900265c27af36ef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6ff58191c2bcf1b1c87c7cbb8900265c27af36ef.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1317,
          name: "Hot Fiesta Mobile",
          uuid: "b8f798682a66fb3e444dec96b2819c8b301ddda9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b8f798682a66fb3e444dec96b2819c8b301ddda9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1554,
          name: "Hot Pepper",
          uuid: "6eb68c475bd14ac8959a824d3a170735",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6eb68c475bd14ac8959a824d3a170735.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1555,
          name: "Hot Pepper Mobile",
          uuid: "c9a897bc6f114385a93a3efd674e9ae9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c9a897bc6f114385a93a3efd674e9ae9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1156,
          name: "Hot Safari",
          uuid: "384c22a971514df883c4ed0253de938e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/384c22a971514df883c4ed0253de938e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1272,
          name: "Hot Safari 50,000",
          uuid: "f261dfc07221415597ec13941de9b87b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f261dfc07221415597ec13941de9b87b.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1273,
          name: "Hot Safari 50,000 Mobile",
          uuid: "98e76ce8a46547c092055c088483b3f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/98e76ce8a46547c092055c088483b3f8.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1157,
          name: "Hot Safari Mobile",
          uuid: "360490a9a2174c40a38dd4274a1d2ade",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/360490a9a2174c40a38dd4274a1d2ade.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1086,
          name: "Hot to Burn",
          uuid: "da0f4cdea1a74516b2e0e704cf3392c8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/da0f4cdea1a74516b2e0e704cf3392c8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1492,
          name: "Hot to Burn Extreme",
          uuid: "c496882255741fac4bcec0dccaa65e67b3a42634",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c496882255741fac4bcec0dccaa65e67b3a42634.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1493,
          name: "Hot to Burn Extreme Mobile",
          uuid: "0621ae3dfffb49a10fb4ac07c263ea2ff0d5d780",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0621ae3dfffb49a10fb4ac07c263ea2ff0d5d780.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1332,
          name: "Hot to Burn Hold and Spin",
          uuid: "a710a41ad9b1323f80f805f0b082271eace78da6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a710a41ad9b1323f80f805f0b082271eace78da6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1333,
          name: "Hot to Burn Hold and Spin Mobile",
          uuid: "20169f5f3338fd0409dfff6b43b2f2330ddaf0ee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/20169f5f3338fd0409dfff6b43b2f2330ddaf0ee.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1087,
          name: "Hot to Burn Mobile",
          uuid: "586fc83dca8441e9bdb8c10b7253bcb5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/586fc83dca8441e9bdb8c10b7253bcb5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1844,
          name: "Hot to Burn Multiplier",
          uuid: "81b0c3a900b94ed1bed03812269e037e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/81b0c3a900b94ed1bed03812269e037e.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1845,
          name: "Hot to Burn Multiplier Mobile",
          uuid: "8228fa04b3ee440d855257e142107958",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8228fa04b3ee440d855257e142107958.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1869,
          name: "Hot to Burn – 7 Deadly Free Spins",
          uuid: "dc30456a23294f24b91161104847070b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/dc30456a23294f24b91161104847070b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1868,
          name: "Hot to Burn – 7 Deadly Free Spins Mobile",
          uuid: "346efdfab11f4f6ca4d2cbf56a07e948",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/346efdfab11f4f6ca4d2cbf56a07e948.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1800,
          name: "Ice Lobster",
          uuid: "bac051d9571c479a88fe2cdef21c0eba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bac051d9571c479a88fe2cdef21c0eba.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1801,
          name: "Ice Lobster Mobile",
          uuid: "f457daff098b425d8c1bc1e1b24076b9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f457daff098b425d8c1bc1e1b24076b9.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1708,
          name: "Infective Wild",
          uuid: "d366f18d7201411e8141c2aeb6785972",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d366f18d7201411e8141c2aeb6785972.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1709,
          name: "Infective Wild Mobile",
          uuid: "2ac09de8c0b94b739038c01227238120",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2ac09de8c0b94b739038c01227238120.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1856,
          name: "Jackpot Hunter",
          uuid: "c1ae5c6262a943e8b7ea389861112f45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c1ae5c6262a943e8b7ea389861112f45.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1857,
          name: "Jackpot Hunter Mobile",
          uuid: "ac84ee07a59f460f9094ee9a28357b28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ac84ee07a59f460f9094ee9a28357b28.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1234,
          name: "Jade Butterfly",
          uuid: "234e11dff3d84cdf82adc454133adfc1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/234e11dff3d84cdf82adc454133adfc1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1235,
          name: "Jade Butterfly Mobile",
          uuid: "f0a5957008f84f628a8f71d48a3095ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f0a5957008f84f628a8f71d48a3095ec.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1618,
          name: "Jane Hunter and the Mask of Montezuma",
          uuid: "81dfc21caeb542fc9919dc398dff828e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/81dfc21caeb542fc9919dc398dff828e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1619,
          name: "Jane Hunter and the Mask of Montezuma Mobile",
          uuid: "99f8d997f29e419f85603e220f22459e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/99f8d997f29e419f85603e220f22459e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1602,
          name: "Jasmine Dreams",
          uuid: "60d4f897e14c4a4ea4b9d08092144d19",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/60d4f897e14c4a4ea4b9d08092144d19.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1603,
          name: "Jasmine Dreams Mobile",
          uuid: "60b5be3a8fa04594870e6e97e5c59e1f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/60b5be3a8fa04594870e6e97e5c59e1f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1632,
          name: "Jewel Rush",
          uuid: "de28ffc0e67e44278f5b2d1552422a1c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/de28ffc0e67e44278f5b2d1552422a1c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1633,
          name: "Jewel Rush Mobile",
          uuid: "12139cabf6c8478582b1862ce401d8f5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/12139cabf6c8478582b1862ce401d8f5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1154,
          name: "John Hunter and the Aztec Treasure",
          uuid: "0ebf913f49c54ff583d2a0ffa3e33b0a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0ebf913f49c54ff583d2a0ffa3e33b0a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1155,
          name: "John Hunter and the Aztec Treasure Mobile",
          uuid: "1c8592c1b64a42b79dcd1708101461e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1c8592c1b64a42b79dcd1708101461e8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1390,
          name: "John Hunter and the Book of the Fallen",
          uuid: "dc12204fd0c7d4128e7b86ef66c125026b1eb8b0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dc12204fd0c7d4128e7b86ef66c125026b1eb8b0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1391,
          name: "John Hunter and the Book of the Fallen Mobile",
          uuid: "c6279af326f3f1093bc13a332f2cbc5a98b19638",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c6279af326f3f1093bc13a332f2cbc5a98b19638.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1094,
          name: "John Hunter and the Book of Tut",
          uuid: "07cf95b267844ec3a6e759fae91e08cb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/07cf95b267844ec3a6e759fae91e08cb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1095,
          name: "John Hunter and the Book of Tut Mobile",
          uuid: "43944899feea4ba2b5dc2a15d121adf5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/43944899feea4ba2b5dc2a15d121adf5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1523,
          name: "John Hunter and the Book of Tut Respin",
          uuid: "8c57697db6724b349a257c1594312ce8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8c57697db6724b349a257c1594312ce8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1522,
          name: "John Hunter and the Book of Tut Respin Mobile",
          uuid: "3c9f596b707d4d2fa9410818e0d3f710",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3c9f596b707d4d2fa9410818e0d3f710.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1290,
          name: "John Hunter And The Mayan Gods",
          uuid: "c4b0f2f3724b4f55856995f35e4f28c8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c4b0f2f3724b4f55856995f35e4f28c8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1291,
          name: "John Hunter And The Mayan Gods Mobile",
          uuid: "8d24f42badaa4d409e156d0527b18f4b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8d24f42badaa4d409e156d0527b18f4b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1380,
          name: "John Hunter and the Quest for Bermuda Riches",
          uuid: "959a75ea24810b12441c50b4ecbcec776089270f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/959a75ea24810b12441c50b4ecbcec776089270f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1381,
          name: "John Hunter and the Quest for Bermuda Riches Mobile",
          uuid: "5ea7310442507681769dc5d3961b960fa9fa4f05",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5ea7310442507681769dc5d3961b960fa9fa4f05.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1040,
          name: "John Hunter and the Tomb of the Scarab Queen",
          uuid: "c310e4fb2e324b1da17672ef7bb10a0e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c310e4fb2e324b1da17672ef7bb10a0e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1041,
          name: "John Hunter and the Tomb of the Scarab Queen Mobile",
          uuid: "c3babd359cc248999ffa4e872ff111e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c3babd359cc248999ffa4e872ff111e1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1336,
          name: "Joker King",
          uuid: "03ab2a1f6369f356e4e6a3e077c6eee878ba2dc0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/03ab2a1f6369f356e4e6a3e077c6eee878ba2dc0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1337,
          name: "Joker King Mobile",
          uuid: "e48257f204566bc45d78cae0fde10b24a127b4c4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e48257f204566bc45d78cae0fde10b24a127b4c4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1008,
          name: "Joker's Jewels",
          uuid: "b76d43971be244bbae42afebb785c176",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b76d43971be244bbae42afebb785c176.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1581,
          name: "Joker's Jewels Dice",
          uuid: "350926473b5861a1d7c485abb2a0697b29123a5e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/350926473b5861a1d7c485abb2a0697b29123a5e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1580,
          name: "Joker's Jewels Dice Mobile",
          uuid: "adc07e8f321b89c87e0fda693512dfaf80efc382",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/adc07e8f321b89c87e0fda693512dfaf80efc382.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1009,
          name: "Joker's Jewels Mobile",
          uuid: "52ff968b6ddd44f98c82fbe601aec123",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/52ff968b6ddd44f98c82fbe601aec123.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6775,
          name: "Joker’s Jewels Hot",
          uuid: "63503a7a39a640cf834dfb27dad83f0f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/63503a7a39a640cf834dfb27dad83f0f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6776,
          name: "Joker’s Jewels Hot Mobile",
          uuid: "daffaee7449244bcb3ccc66e06637b07",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/daffaee7449244bcb3ccc66e06637b07.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1849,
          name: "Joker’s Jewels Wild",
          uuid: "1cfb216b4eda8808697da4c449ecf729d89e1298",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1cfb216b4eda8808697da4c449ecf729d89e1298.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1848,
          name: "Joker’s Jewels Wild Mobile",
          uuid: "d9214ecff77c81c1012bb8b273f561862adb17a4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d9214ecff77c81c1012bb8b273f561862adb17a4.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1124,
          name: "Journey to the West",
          uuid: "af26d338964d4a228d3c076d8fc97e90",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/af26d338964d4a228d3c076d8fc97e90.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1125,
          name: "Journey to the West Mobile",
          uuid: "82100f88cf94447d938ec17d5522600b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/82100f88cf94447d938ec17d5522600b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1326,
          name: "Juicy Fruits",
          uuid: "8eebe503eea892b14a68e514af1a38098acd5451",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8eebe503eea892b14a68e514af1a38098acd5451.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1327,
          name: "Juicy Fruits Mobile",
          uuid: "e1c85036f09075f7efa2d9f7a74d3dd951980519",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e1c85036f09075f7efa2d9f7a74d3dd951980519.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1738,
          name: "Juicy Fruits Multihold",
          uuid: "dc9fef6e971446689ea0a2c180f444d4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/dc9fef6e971446689ea0a2c180f444d4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1739,
          name: "Juicy Fruits Multihold Mobile",
          uuid: "429e8d0902514deb94dba616e1adcbef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/429e8d0902514deb94dba616e1adcbef.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1206,
          name: "Jungle Gorilla",
          uuid: "9ff55e3bb43f4819a41aa270ea63bad9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9ff55e3bb43f4819a41aa270ea63bad9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1207,
          name: "Jungle Gorilla Mobile",
          uuid: "cab6b6d1ee454fb0921d3220c52cc126",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/cab6b6d1ee454fb0921d3220c52cc126.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1220,
          name: "Jurassic Giants",
          uuid: "b227e0a85c7b4626a824dd4c76251eb1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b227e0a85c7b4626a824dd4c76251eb1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1221,
          name: "Jurassic Giants Mobile",
          uuid: "1fcb7e96921e41c3ac744539cd29d459",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1fcb7e96921e41c3ac744539cd29d459.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1620,
          name: "Kingdom of The Dead",
          uuid: "aa5c8a6ce00f4a92b9645186479f9878",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/aa5c8a6ce00f4a92b9645186479f9878.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1621,
          name: "Kingdom of The Dead Mobile",
          uuid: "e560bbe539ed402ba22d8c80a059ba69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e560bbe539ed402ba22d8c80a059ba69.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1626,
          name: "Knight Hot Spotz",
          uuid: "6e64f59de39b4e9e9e17cb66ddcb67ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6e64f59de39b4e9e9e17cb66ddcb67ff.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1627,
          name: "Knight Hot Spotz Mobile",
          uuid: "4808249bbf014237b8e4b95a6ebb953c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4808249bbf014237b8e4b95a6ebb953c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1072,
          name: "Lady Godiva",
          uuid: "043bab077d4e43c1ad5749962e3755f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/043bab077d4e43c1ad5749962e3755f0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1073,
          name: "Lady Godiva Mobile",
          uuid: "857cc413f0094510bfc9a6a984c9049e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/857cc413f0094510bfc9a6a984c9049e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1186,
          name: "Lady of the Moon",
          uuid: "3072599876d04ecfb9c44b637e3e9c9e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3072599876d04ecfb9c44b637e3e9c9e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1187,
          name: "Lady of the Moon Mobile",
          uuid: "1c791e08c2dd4e4d8e038202a869cb62",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1c791e08c2dd4e4d8e038202a869cb62.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1630,
          name: "Lamp Of Infinity",
          uuid: "9c1a8f49037845e393cc991ab210fff3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9c1a8f49037845e393cc991ab210fff3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1631,
          name: "Lamp Of Infinity Mobile",
          uuid: "90b5f6999c2b41aabd693dcb71bd157b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/90b5f6999c2b41aabd693dcb71bd157b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1228,
          name: "Leprechaun Carol",
          uuid: "6e002d18049f4cc2a025aae1b3824782",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6e002d18049f4cc2a025aae1b3824782.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1229,
          name: "Leprechaun Carol Mobile",
          uuid: "a589e01497154b849bb0341e18286e73",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a589e01497154b849bb0341e18286e73.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1224,
          name: "Leprechaun Song",
          uuid: "7990d971f4ac4c9296d2c14ec0bd6ac9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7990d971f4ac4c9296d2c14ec0bd6ac9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1225,
          name: "Leprechaun Song Mobile",
          uuid: "7132f05b6b8d4a6ea54761a0313f7aad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7132f05b6b8d4a6ea54761a0313f7aad.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1452,
          name: "Little Gem",
          uuid: "d3d36aee7f1fe23c7600c09b2ddb8113cbd78b00",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d3d36aee7f1fe23c7600c09b2ddb8113cbd78b00.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1453,
          name: "Little Gem Mobile",
          uuid: "e9a8ff4d440821fdcb887aed41a7573732e2ad42",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e9a8ff4d440821fdcb887aed41a7573732e2ad42.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1662,
          name: "Lobster Bob's Crazy Crab Shack",
          uuid: "03e2c42c3d4e448fac355eb0a4727c15",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/03e2c42c3d4e448fac355eb0a4727c15.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1663,
          name: "Lobster Bob's Crazy Crab Shack Mobile",
          uuid: "5f33a720c5144cc0af48abf9152c9f45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5f33a720c5144cc0af48abf9152c9f45.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1804,
          name: "Lobster Bob's Sea Food and Win It",
          uuid: "8a68ffba2a204f67b81c58b630e0495c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8a68ffba2a204f67b81c58b630e0495c.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1805,
          name: "Lobster Bob's Sea Food and Win It Mobile",
          uuid: "db4a9315e1874ecca8e95388b97a331e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/db4a9315e1874ecca8e95388b97a331e.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1759,
          name: "Loki’s Riches",
          uuid: "cd4c1b14fe624aa480f58ab17c17670d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/cd4c1b14fe624aa480f58ab17c17670d.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1758,
          name: "Loki’s Riches Mobile",
          uuid: "ff8c17f4a49d4364910a1a44c306c3e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ff8c17f4a49d4364910a1a44c306c3e9.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1188,
          name: "Lucky Dragons",
          uuid: "9f43bd1f38cd415ebd20a7f447b15a95",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9f43bd1f38cd415ebd20a7f447b15a95.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1189,
          name: "Lucky Dragons Mobile",
          uuid: "9654b7c804dc4904af4458fe858f3348",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9654b7c804dc4904af4458fe858f3348.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1298,
          name: "Lucky Lightning",
          uuid: "8977099d1d11f0eb3a4515a213f41644b9506706",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8977099d1d11f0eb3a4515a213f41644b9506706.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1299,
          name: "Lucky Lightning Mobile",
          uuid: "780c54e494733db8f48caea591876d07fe2c187e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/780c54e494733db8f48caea591876d07fe2c187e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1102,
          name: "Lucky New Year",
          uuid: "42919aa54cbd49c7a75416e00c68fbbd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/42919aa54cbd49c7a75416e00c68fbbd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1103,
          name: "Lucky New Year Mobile",
          uuid: "4de0f88272364c1b904e8e3e9037b296",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4de0f88272364c1b904e8e3e9037b296.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1178,
          name: "Madame Destiny",
          uuid: "e6e1cf389578444e90636d93a8937f42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e6e1cf389578444e90636d93a8937f42.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1344,
          name: "Madame Destiny Megaways",
          uuid: "1149fe8b5e19802809f9128c6d2beddcedae2179",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1149fe8b5e19802809f9128c6d2beddcedae2179.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1345,
          name: "Madame Destiny Megaways Mobile",
          uuid: "9ce4b16ebc98a6171a4bfe2029f9f4b706429e76",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9ce4b16ebc98a6171a4bfe2029f9f4b706429e76.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1179,
          name: "Madame Destiny Mobile",
          uuid: "d2c03f54d1934ba88f11c02ca30fbda9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d2c03f54d1934ba88f11c02ca30fbda9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1246,
          name: "Magic Crystals",
          uuid: "45787950b5274491bf64b0de9994ea14",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/45787950b5274491bf64b0de9994ea14.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1247,
          name: "Magic Crystals Mobile",
          uuid: "88bc0fa620d04f22894a43b709e1b030",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/88bc0fa620d04f22894a43b709e1b030.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1202,
          name: "Magic Journey",
          uuid: "aa5e94997f7c437094e10e247ad99297",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/aa5e94997f7c437094e10e247ad99297.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1203,
          name: "Magic Journey Mobile",
          uuid: "b1be7e6974334eabb6de5b22b5e8c9b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b1be7e6974334eabb6de5b22b5e8c9b0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1484,
          name: "Magic Money Maze",
          uuid: "4d59dacc5a70f54164d44fff6d9f94e945afaab7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4d59dacc5a70f54164d44fff6d9f94e945afaab7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1485,
          name: "Magic Money Maze Mobile",
          uuid: "2cbb7e3d0370459a6710b146abb3df152fce0e32",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2cbb7e3d0370459a6710b146abb3df152fce0e32.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1400,
          name: "Magician's Secrets",
          uuid: "3ade0f84bb96aa838b28625f5f45d6a5b8190de7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3ade0f84bb96aa838b28625f5f45d6a5b8190de7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1401,
          name: "Magician's Secrets Mobile",
          uuid: "b4dae17275708640b22c1d60bde3519730e87742",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b4dae17275708640b22c1d60bde3519730e87742.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1570,
          name: "Mammoth Gold Megaways",
          uuid: "89c6baec43a2416dbba82d084fa12b6a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/89c6baec43a2416dbba82d084fa12b6a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1571,
          name: "Mammoth Gold Megaways Mobile",
          uuid: "922da8ddae7e426383a309b772b96fa0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/922da8ddae7e426383a309b772b96fa0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1174,
          name: "Master Chen's Fortune",
          uuid: "80e0bc1864ba4d618d4d42707376802f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/80e0bc1864ba4d618d4d42707376802f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1175,
          name: "Master Chen's Fortune Mobile",
          uuid: "a0889e2e312344659d060685b28809cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a0889e2e312344659d060685b28809cc.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1052,
          name: "Master Joker",
          uuid: "2b05786293aa4cabb1bcae256cc163a9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2b05786293aa4cabb1bcae256cc163a9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1053,
          name: "Master Joker Mobile",
          uuid: "0dd0fa88352e42dcadda9851870427d8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0dd0fa88352e42dcadda9851870427d8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1834,
          name: "Medusa’s Stone",
          uuid: "131433cd5eb14a53acc467ea07179e00",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/131433cd5eb14a53acc467ea07179e00.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1835,
          name: "Medusa’s Stone Mobile",
          uuid: "0546cf04eb00444d84d3a68f6442896e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0546cf04eb00444d84d3a68f6442896e.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1416,
          name: "Might of Ra",
          uuid: "14c271a687299ecdbf2d086a23a17f37cbbae7ac",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/14c271a687299ecdbf2d086a23a17f37cbbae7ac.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1417,
          name: "Might of Ra Mobile",
          uuid: "6eb267a963c745671c2990e2e74b3b1cbabffd6a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6eb267a963c745671c2990e2e74b3b1cbabffd6a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1146,
          name: "Mighty Kong",
          uuid: "5c86670741ae45e488a9739c1bbc8dd1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5c86670741ae45e488a9739c1bbc8dd1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1147,
          name: "Mighty Kong Mobile",
          uuid: "37741edd391544b7bb56836d6fb76726",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/37741edd391544b7bb56836d6fb76726.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1774,
          name: "Mighty Munching Melons",
          uuid: "b9ad29837a9e4585a13f270d24a2d118",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b9ad29837a9e4585a13f270d24a2d118.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1775,
          name: "Mighty Munching Melons Mobile",
          uuid: "a44411e4f04e4cc6b5f20a5fdb3ac7b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a44411e4f04e4cc6b5f20a5fdb3ac7b7.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1600,
          name: "Mochimon",
          uuid: "bfe1e444b8f44e26a100a8725f304e6d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bfe1e444b8f44e26a100a8725f304e6d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1601,
          name: "Mochimon Mobile",
          uuid: "d4553f1230e247acbed90f76424001ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d4553f1230e247acbed90f76424001ff.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1104,
          name: "Money Mouse",
          uuid: "ff1353d464404fc0a22481620026c168",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ff1353d464404fc0a22481620026c168.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1105,
          name: "Money Mouse Mobile",
          uuid: "5c805d6beee04ddab039a3a59bda7269",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5c805d6beee04ddab039a3a59bda7269.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1861,
          name: "Money Stacks",
          uuid: "e16f025fd535b5e24e0648afd1ba61ba85108ec8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e16f025fd535b5e24e0648afd1ba61ba85108ec8.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1860,
          name: "Money Stacks Mobile",
          uuid: "83aab778374eabb27cda2e2a5069b41813aa4811",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/83aab778374eabb27cda2e2a5069b41813aa4811.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1076,
          name: "Monkey Madness",
          uuid: "cbca724e35b142a08cf9230da717f3c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/cbca724e35b142a08cf9230da717f3c2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1077,
          name: "Monkey Madness Mobile",
          uuid: "bbee9c3fa3f2491eba3b54e553220a05",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bbee9c3fa3f2491eba3b54e553220a05.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1112,
          name: "Monkey Warrior",
          uuid: "35aebe2fd16d4d33a21eca664de99e18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/35aebe2fd16d4d33a21eca664de99e18.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1113,
          name: "Monkey Warrior Mobile",
          uuid: "75a69ff224c349a68e0f4c19f62e7842",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/75a69ff224c349a68e0f4c19f62e7842.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1574,
          name: "Monster Superlanche",
          uuid: "99a9f667ee2b4380aa3f0642c7c27728",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/99a9f667ee2b4380aa3f0642c7c27728.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1575,
          name: "Monster Superlanche Mobile",
          uuid: "dceed567c9544b8283ac59423c659c91",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/dceed567c9544b8283ac59423c659c91.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1526,
          name: "Muertos Multiplier Megaways",
          uuid: "b9a42ecf23ec5d9405b0713fa824214d68a94451",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b9a42ecf23ec5d9405b0713fa824214d68a94451.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1527,
          name: "Muertos Multiplier Megaways Mobile",
          uuid: "887618913b76a587a865560ecc837b71d86df074",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/887618913b76a587a865560ecc837b71d86df074.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1284,
          name: "Multihand Blackjack",
          uuid: "a9bf613c332944c1bac42909b6162048",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a9bf613c332944c1bac42909b6162048.png",
          type: "blackjack",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1285,
          name: "Multihand Blackjack Mobile",
          uuid: "0f1e54bb05d9432b8b013563f5cddaa2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0f1e54bb05d9432b8b013563f5cddaa2.png",
          type: "blackjack",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1056,
          name: "Mustang Gold",
          uuid: "6053d847bc8447da81b9f0fdb26d01d6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6053d847bc8447da81b9f0fdb26d01d6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1880,
          name: "Mustang Gold Megaways",
          uuid: "7b9367b176454736ac2327c700e49969",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7b9367b176454736ac2327c700e49969.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1881,
          name: "Mustang Gold Megaways Mobile",
          uuid: "70944777308c4c1f8d3185f905a5bf7d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/70944777308c4c1f8d3185f905a5bf7d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1057,
          name: "Mustang Gold Mobile",
          uuid: "c6793589d00f4c0194ac0732cbf14346",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c6793589d00f4c0194ac0732cbf14346.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1676,
          name: "Mustang Trail",
          uuid: "c5e42836b3284755985ee7f7a9a28cd3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c5e42836b3284755985ee7f7a9a28cd3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1677,
          name: "Mustang Trail Mobile",
          uuid: "2954135f80484bd29d2fbaa4e270e9e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2954135f80484bd29d2fbaa4e270e9e4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1200,
          name: "Mysterious",
          uuid: "88750c558dff464dbf5e3e8d3abef5aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/88750c558dff464dbf5e3e8d3abef5aa.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1292,
          name: "Mysterious Egypt",
          uuid: "afcb6627bf714d7cbc3c66b16db76b33",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/afcb6627bf714d7cbc3c66b16db76b33.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1293,
          name: "Mysterious Egypt Mobile",
          uuid: "92f55c7ba94b45dc8febc3f64f1a8253",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/92f55c7ba94b45dc8febc3f64f1a8253.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1201,
          name: "Mysterious Mobile",
          uuid: "382b8247f40443eea3a53d3ddd18e14b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/382b8247f40443eea3a53d3ddd18e14b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1882,
          name: "Mystery Mice",
          uuid: "ef7b6e731324496ba39269506b29a09c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ef7b6e731324496ba39269506b29a09c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1883,
          name: "Mystery Mice Mobile",
          uuid: "1d51fea7526d4f2bb9677fb11e1b369c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1d51fea7526d4f2bb9677fb11e1b369c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1590,
          name: "Mystery of the Orient",
          uuid: "5d8bcd7edc3a470d91d409ec95484612",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5d8bcd7edc3a470d91d409ec95484612.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1591,
          name: "Mystery of the Orient Mobile",
          uuid: "160485470b5c47c281421a43f3d534ca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/160485470b5c47c281421a43f3d534ca.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1372,
          name: "Mystic Chief",
          uuid: "4edc6a2c885257991ec8634eb199a20cd5b60c88",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4edc6a2c885257991ec8634eb199a20cd5b60c88.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1373,
          name: "Mystic Chief Mobile",
          uuid: "4e61295f66d8d27b652e15d206099c20b009a619",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4e61295f66d8d27b652e15d206099c20b009a619.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1724,
          name: "Nile Fortune",
          uuid: "d9ebc3bf51a5422e9319b7ebf82ed0f5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d9ebc3bf51a5422e9319b7ebf82ed0f5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1725,
          name: "Nile Fortune Mobile",
          uuid: "e0a6df3cef4f46468f2f119550bdcd0d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e0a6df3cef4f46468f2f119550bdcd0d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1444,
          name: "North Guardians",
          uuid: "32a3539f3f32469a8d11690b66cbbc50d1cf0adb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/32a3539f3f32469a8d11690b66cbbc50d1cf0adb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1445,
          name: "North Guardians Mobile",
          uuid: "fcb1e5c6472fd988f1d6b737dad9f4757b349d2a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fcb1e5c6472fd988f1d6b737dad9f4757b349d2a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1496,
          name: "Octobeer Fortunes",
          uuid: "4545df2dea3edc399b266103e32f8cba5604f23a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4545df2dea3edc399b266103e32f8cba5604f23a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1497,
          name: "Octobeer Fortunes Mobile",
          uuid: "5c51439059954c0c297ca1399b98337e85dc2719",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5c51439059954c0c297ca1399b98337e85dc2719.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6781,
          name: "Oodles of Noodles",
          uuid: "ab0a30dafa5c44a69652e84afa2a303c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ab0a30dafa5c44a69652e84afa2a303c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6782,
          name: "Oodles of Noodles Mobile",
          uuid: "25c235e20d45450a9ab5f37f9968d006",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/25c235e20d45450a9ab5f37f9968d006.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1270,
          name: "Panda Gold 10,000",
          uuid: "e08fcf3509834f96ac4ec32054805028",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e08fcf3509834f96ac4ec32054805028.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1271,
          name: "Panda Gold 10,000 Mobile",
          uuid: "9256b60347194790bfe318070faa11af",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9256b60347194790bfe318070faa11af.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1064,
          name: "Panda's Fortune",
          uuid: "56e16897bce240cdb6ffffa4deffb808",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/56e16897bce240cdb6ffffa4deffb808.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1304,
          name: "Panda's Fortune 2",
          uuid: "0029702c90bfe1883003a98ae549a2555d84d44c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0029702c90bfe1883003a98ae549a2555d84d44c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1305,
          name: "Panda's Fortune 2 Mobile",
          uuid: "eff2cdbefaa1930a5ef026b0202e54dcf13177ee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/eff2cdbefaa1930a5ef026b0202e54dcf13177ee.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1065,
          name: "Panda's Fortune Mobile",
          uuid: "b8cf1444574b4831ab9ec866f7d390d6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b8cf1444574b4831ab9ec866f7d390d6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1236,
          name: "Panther Queen",
          uuid: "e524d937c5cd405fb2205e8aa48364ab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e524d937c5cd405fb2205e8aa48364ab.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1237,
          name: "Panther Queen Mobile",
          uuid: "71575a73846e4013abadaa56f9e04bf7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/71575a73846e4013abadaa56f9e04bf7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1586,
          name: "Peak Power",
          uuid: "d6c2368d5e604de3b77481933369817f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d6c2368d5e604de3b77481933369817f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1587,
          name: "Peak Power Mobile",
          uuid: "0cb0e2fc463d43b381411d50892ed61e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0cb0e2fc463d43b381411d50892ed61e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1144,
          name: "Peking Luck",
          uuid: "1e1c8957fe5e491794afe51f4065c391",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1e1c8957fe5e491794afe51f4065c391.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1145,
          name: "Peking Luck Mobile",
          uuid: "374e00a7332a4c3b80762ad1195ed88b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/374e00a7332a4c3b80762ad1195ed88b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1310,
          name: "Phoenix Forge",
          uuid: "126a077adc1a3feaa5bc1bcb770139de7d04805b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/126a077adc1a3feaa5bc1bcb770139de7d04805b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1311,
          name: "Phoenix Forge Mobile",
          uuid: "11dc268c2ce79f6eec0dd4beb254a9fea1c7d0b2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/11dc268c2ce79f6eec0dd4beb254a9fea1c7d0b2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1370,
          name: "Piggy Bank Bills",
          uuid: "d6b719a8b72208981b9fa195c3d1f4e8ac2c2228",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d6b719a8b72208981b9fa195c3d1f4e8ac2c2228.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1371,
          name: "Piggy Bank Bills Mobile",
          uuid: "5d045c5b54026a6e87df989fd87d5e6afb57caee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5d045c5b54026a6e87df989fd87d5e6afb57caee.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1670,
          name: "Piggy Bankers",
          uuid: "3070e12c540e4c4993ff4ce8ce845615",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3070e12c540e4c4993ff4ce8ce845615.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1671,
          name: "Piggy Bankers Mobile",
          uuid: "3ac99e4548dc42ad9667cb39f1e0f41e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3ac99e4548dc42ad9667cb39f1e0f41e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1566,
          name: "Pinup Girls",
          uuid: "f8b088615df043a8b32eeab3d497d78e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f8b088615df043a8b32eeab3d497d78e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1567,
          name: "Pinup Girls Mobile",
          uuid: "889b3613689e45b9b21589c7492e5537",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/889b3613689e45b9b21589c7492e5537.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1110,
          name: "Pirate Gold",
          uuid: "47ce3e4461634e1aad79130a146ea921",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/47ce3e4461634e1aad79130a146ea921.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1168,
          name: "Pirate Gold Deluxe",
          uuid: "9ca52dafffc04391a578dbb80fcd075f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9ca52dafffc04391a578dbb80fcd075f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1169,
          name: "Pirate Gold Deluxe Mobile",
          uuid: "481ab492925f42f99545a3f94bcb1f59",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/481ab492925f42f99545a3f94bcb1f59.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1111,
          name: "Pirate Gold Mobile",
          uuid: "a017bfbac2cf451da47b16f68e4a8021",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a017bfbac2cf451da47b16f68e4a8021.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1521,
          name: "Pirate Golden Age",
          uuid: "53d87bca6bf84f3fb4020fd7ca6629ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/53d87bca6bf84f3fb4020fd7ca6629ff.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1520,
          name: "Pirate Golden Age Mobile",
          uuid: "4198b22b97934ad1b2a6f98c052fb21f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4198b22b97934ad1b2a6f98c052fb21f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1638,
          name: "Pirates Pub",
          uuid: "d9cf53595fb24695b32515f7a32479c9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d9cf53595fb24695b32515f7a32479c9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1639,
          name: "Pirates Pub Mobile",
          uuid: "bec9790b181e48318eee18b355ae94a3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bec9790b181e48318eee18b355ae94a3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1148,
          name: "Pixie Wings",
          uuid: "0c41a1aa3b264dd081c9694aa498bf83",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0c41a1aa3b264dd081c9694aa498bf83.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1149,
          name: "Pixie Wings Mobile",
          uuid: "0c44c6580bf645adaea6e4a6c4cfb1c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0c44c6580bf645adaea6e4a6c4cfb1c2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1556,
          name: "PIZZA! PIZZA? PIZZA!",
          uuid: "df135c75a9c340d599e53da2f924b0d8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/df135c75a9c340d599e53da2f924b0d8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1557,
          name: "PIZZA! PIZZA? PIZZA! Mobile",
          uuid: "a679065dbe734e13bfeec785067c553d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a679065dbe734e13bfeec785067c553d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1770,
          name: "Pompeii Megareels Megaways",
          uuid: "3f5c6d704dbc456cb9fbb71692110ce7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3f5c6d704dbc456cb9fbb71692110ce7.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1771,
          name: "Pompeii Megareels Megaways Mobile",
          uuid: "cd4f8bd3f6a94379b90ad9c2e9dfd2ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/cd4f8bd3f6a94379b90ad9c2e9dfd2ce.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1784,
          name: "Pot of Fortune",
          uuid: "f6d2e23c3dea45da8bcacf2c6f556758",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f6d2e23c3dea45da8bcacf2c6f556758.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1785,
          name: "Pot of Fortune Mobile",
          uuid: "49236306b11b405da2a1827983739e30",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/49236306b11b405da2a1827983739e30.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1656,
          name: "Power of Merlin Megaways",
          uuid: "e9bc766f76e44127882a2b13ef27a578",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e9bc766f76e44127882a2b13ef27a578.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1657,
          name: "Power of Merlin Megaways Mobile",
          uuid: "46e4c42bb24940169e199a4583bafdda",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/46e4c42bb24940169e199a4583bafdda.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1322,
          name: "Power of Thor Megaways",
          uuid: "73231bfff8f4e40323646993d6da783e67f86b9b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/73231bfff8f4e40323646993d6da783e67f86b9b.jpeg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1323,
          name: "Power of Thor Megaways Mobile",
          uuid: "f8e5609836db2ac7052f86157e2642b01047c309",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f8e5609836db2ac7052f86157e2642b01047c309.jpeg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1672,
          name: "Pub Kings",
          uuid: "8f6c6f3a0e51465483784206eb9780e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8f6c6f3a0e51465483784206eb9780e0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1673,
          name: "Pub Kings Mobile",
          uuid: "e4561554f45c406f9752be33531676b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e4561554f45c406f9752be33531676b7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1078,
          name: "Pyramid King",
          uuid: "c75a3df627864bf79a1d1a73d20dec88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c75a3df627864bf79a1d1a73d20dec88.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1079,
          name: "Pyramid King Mobile",
          uuid: "f85e0bef72c442798bf19b1a287b3c00",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f85e0bef72c442798bf19b1a287b3c00.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1252,
          name: "Queen of Atlantis",
          uuid: "bd4d1ce29b7b4211909e3376c6d08439",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bd4d1ce29b7b4211909e3376c6d08439.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1253,
          name: "Queen of Atlantis Mobile",
          uuid: "03d6f2c755c8434ab49541212c261493",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/03d6f2c755c8434ab49541212c261493.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1462,
          name: "Queen of Gods",
          uuid: "672455e07c2687ca6bf3bc7af06960edef3290fa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/672455e07c2687ca6bf3bc7af06960edef3290fa.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1463,
          name: "Queen of Gods Mobile",
          uuid: "eb6175e150dcd667cdcbd422eaa5eb08341c4421",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/eb6175e150dcd667cdcbd422eaa5eb08341c4421.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1268,
          name: "Queen of Gold 100,000",
          uuid: "bcec14e5115c45cfbe075f721f163a91",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bcec14e5115c45cfbe075f721f163a91.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1269,
          name: "Queen of Gold 100,000 Mobile",
          uuid: "9b8844347cdf44c1a62cb54396e66854",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9b8844347cdf44c1a62cb54396e66854.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1422,
          name: "Queenie",
          uuid: "45fc3b1d620c0d7c9592298cadff5fed623cf74a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/45fc3b1d620c0d7c9592298cadff5fed623cf74a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1423,
          name: "Queenie Mobile",
          uuid: "65de5f97e07a82fd2911272ce8c8f5b867968162",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/65de5f97e07a82fd2911272ce8c8f5b867968162.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1604,
          name: "Rabbit Garden",
          uuid: "b6e135f59b0b4958a1281dc4b425b800",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b6e135f59b0b4958a1281dc4b425b800.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1605,
          name: "Rabbit Garden Mobile",
          uuid: "acd4bf1c0c2840a381665486f5b68fbd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/acd4bf1c0c2840a381665486f5b68fbd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1426,
          name: "Rainbow Gold",
          uuid: "351395c796c70799f80b1db73b6c68920899598c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/351395c796c70799f80b1db73b6c68920899598c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1427,
          name: "Rainbow Gold Mobile",
          uuid: "12520c4d643556f41f818c379ad72aa550790b18",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/12520c4d643556f41f818c379ad72aa550790b18.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1694,
          name: "Rainbow Reels",
          uuid: "2436d09b94444708bb4a48a40d8c8147",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2436d09b94444708bb4a48a40d8c8147.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1695,
          name: "Rainbow Reels Mobile",
          uuid: "8f80d882305948c79fa29d464a7f6898",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8f80d882305948c79fa29d464a7f6898.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1762,
          name: "Red Hot Luck",
          uuid: "524d7427e6df41b190591c38f92e5892",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/524d7427e6df41b190591c38f92e5892.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1763,
          name: "Red Hot Luck Mobile",
          uuid: "db64bd83fc334a6da96beb2814261722",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/db64bd83fc334a6da96beb2814261722.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1552,
          name: "Reel Banks",
          uuid: "ee7a358afa08459780a49d57fa74a797",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ee7a358afa08459780a49d57fa74a797.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1553,
          name: "Reel Banks Mobile",
          uuid: "fbf3568d1e5b428c8102d9e6710fb58e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/fbf3568d1e5b428c8102d9e6710fb58e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1806,
          name: "Release the Bison",
          uuid: "be504b63f2bf44b68bf3537573b55f01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/be504b63f2bf44b68bf3537573b55f01.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1807,
          name: "Release the Bison Mobile",
          uuid: "019c14a0fbe842df9dba7d2d25eafd9e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/019c14a0fbe842df9dba7d2d25eafd9e.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1126,
          name: "Release the Kraken",
          uuid: "7cf4cb224da44bf7a5a76b057dc75794",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7cf4cb224da44bf7a5a76b057dc75794.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1534,
          name: "Release the Kraken 2",
          uuid: "a23d665a102331833db91fcf1c947aef307f001a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a23d665a102331833db91fcf1c947aef307f001a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1535,
          name: "Release the Kraken 2 Mobile",
          uuid: "d030dd54ab5f98422be98f3a526f2d1b3a103648",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d030dd54ab5f98422be98f3a526f2d1b3a103648.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6793,
          name: "Release the Kraken Megaways™",
          uuid: "0fa8ff2c52154ab8948b3eb0487638ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0fa8ff2c52154ab8948b3eb0487638ff.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6794,
          name: "Release the Kraken Megaways™ Mobile",
          uuid: "32e295fe830141688c0a1014b1d41171",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/32e295fe830141688c0a1014b1d41171.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1127,
          name: "Release the Kraken Mobile",
          uuid: "40daf9ad21df4194b48d7926cc52c320",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/40daf9ad21df4194b48d7926cc52c320.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1050,
          name: "Return of the Dead",
          uuid: "c3be163cd78943879fffa8023cb1d86c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c3be163cd78943879fffa8023cb1d86c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1051,
          name: "Return of the Dead Mobile",
          uuid: "961b9cda91004918976e612540edd40d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/961b9cda91004918976e612540edd40d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1827,
          name: "Revenge of Loki Megaways",
          uuid: "327c58ed350d4c648047fb83fdd11857",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/327c58ed350d4c648047fb83fdd11857.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1826,
          name: "Revenge of Loki Megaways Mobile",
          uuid: "35c18e96488f43199b90a1db4409669a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/35c18e96488f43199b90a1db4409669a.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1794,
          name: "Ripe Rewards",
          uuid: "8eb8a1048ace4d7daf1be1ef36b4b727",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8eb8a1048ace4d7daf1be1ef36b4b727.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1795,
          name: "Ripe Rewards Mobile",
          uuid: "0afb9935a80a445c96d1f8efcef3e4af",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0afb9935a80a445c96d1f8efcef3e4af.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1358,
          name: "Rise of Giza PowerNudge",
          uuid: "55bfd02b295203858a5a9728eb0dade47f7cf1b0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/55bfd02b295203858a5a9728eb0dade47f7cf1b0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1359,
          name: "Rise of Giza PowerNudge Mobile",
          uuid: "b025ad318b9dad6f487b49b474a7bf09f6f3747f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b025ad318b9dad6f487b49b474a7bf09f6f3747f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1818,
          name: "Rise of Pyramids",
          uuid: "987471710287481695b2a78ed41f49ba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/987471710287481695b2a78ed41f49ba.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1819,
          name: "Rise of Pyramids Mobile",
          uuid: "5a1640207d684509b1bf600c71b8c839",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5a1640207d684509b1bf600c71b8c839.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1406,
          name: "Rock Vegas Mega Hold & Spin",
          uuid: "e6a00864ee60a0f2b23e75649574874435eb75da",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e6a00864ee60a0f2b23e75649574874435eb75da.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1407,
          name: "Rock Vegas Mega Hold & Spin Mobile",
          uuid: "530801811fa1bfcfc5411b545b17671b69b74de8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/530801811fa1bfcfc5411b545b17671b69b74de8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1668,
          name: "Rocket Blast Megaways",
          uuid: "b3cfcf6ef0f844a5abfab64312816a27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b3cfcf6ef0f844a5abfab64312816a27.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1669,
          name: "Rocket Blast Megaways Mobile",
          uuid: "f2a5a16b77584ab8a98b92d922023537",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f2a5a16b77584ab8a98b92d922023537.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1250,
          name: "Romeo and Juliet",
          uuid: "6b21faf4c5d94ee2a0206aeaebe6a432",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6b21faf4c5d94ee2a0206aeaebe6a432.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1251,
          name: "Romeo and Juliet Mobile",
          uuid: "79d566390f1c4eb982d71e051f8018b5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/79d566390f1c4eb982d71e051f8018b5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1286,
          name: "Roulette",
          uuid: "fc9ed97d23d045ffb1ad6c76af6d3790",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/fc9ed97d23d045ffb1ad6c76af6d3790.png",
          type: "roulette",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1287,
          name: "Roulette Mobile",
          uuid: "60b61a6fc09e4fe5ac5c79d650748d6a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/60b61a6fc09e4fe5ac5c79d650748d6a.png",
          type: "roulette",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1864,
          name: "Running Sushi",
          uuid: "425888b814184a948830fc1488855e16",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/425888b814184a948830fc1488855e16.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1865,
          name: "Running Sushi Mobile",
          uuid: "3574e13de85644658c2c05bfddb47213",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3574e13de85644658c2c05bfddb47213.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1204,
          name: "Safari King",
          uuid: "e1a23a12880f4b94be5c25f56c518713",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e1a23a12880f4b94be5c25f56c518713.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1205,
          name: "Safari King Mobile",
          uuid: "ad5bdf7cd2f3424793de781e1548ba88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ad5bdf7cd2f3424793de781e1548ba88.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1841,
          name: "Samurai Code",
          uuid: "e882b19d824525205dfecd5a805a74238b25d96f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e882b19d824525205dfecd5a805a74238b25d96f.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1840,
          name: "Samurai Code Mobile",
          uuid: "d1a673dfa9155a2546115e09df871a21ccb0ffff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d1a673dfa9155a2546115e09df871a21ccb0ffff.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1208,
          name: "Santa",
          uuid: "b7558c93bcbc45128b6e4f60bc607bc9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b7558c93bcbc45128b6e4f60bc607bc9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1209,
          name: "Santa Mobile",
          uuid: "132c9d13a5a34854b751bb612fc916e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/132c9d13a5a34854b751bb612fc916e9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1546,
          name: "Santa's Great Gifts",
          uuid: "259c63079c214cd8af0f080350128040",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/259c63079c214cd8af0f080350128040.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1547,
          name: "Santa's Great Gifts Mobile",
          uuid: "e8857476e5d040ac9f6153d6050bcb21",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e8857476e5d040ac9f6153d6050bcb21.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1392,
          name: "Santa's Wonderland",
          uuid: "9a5606a1ea01f4d22c71bc2b1a45c83de6d6eee8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9a5606a1ea01f4d22c71bc2b1a45c83de6d6eee8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1393,
          name: "Santa's Wonderland Mobile",
          uuid: "e9b581d6a052725d28733c133da81ed8efa0bc0f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e9b581d6a052725d28733c133da81ed8efa0bc0f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1754,
          name: "Saray Ruyasi",
          uuid: "9be25b9f4d5149e99c691d4060f688c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9be25b9f4d5149e99c691d4060f688c2.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1755,
          name: "Saray Ruyasi Mobile",
          uuid: "3058b3c435ab411dbead690116c303ee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3058b3c435ab411dbead690116c303ee.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1568,
          name: "Secret City Gold",
          uuid: "de948729e0904f989e1a49271bfc40fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/de948729e0904f989e1a49271bfc40fa.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1569,
          name: "Secret City Gold Mobile",
          uuid: "8bf3209b1a904440b29ceb5b3a49f495",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8bf3209b1a904440b29ceb5b3a49f495.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1540,
          name: "Shield of Sparta",
          uuid: "197339aa89dc4e98bf5e73bc802a5d19",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/197339aa89dc4e98bf5e73bc802a5d19.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1541,
          name: "Shield of Sparta Mobile",
          uuid: "c2dfec19871749e9a27ca59b30407c80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c2dfec19871749e9a27ca59b30407c80.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1476,
          name: "Shining Hot 100",
          uuid: "b62b904446ce1e7218e80b14349822f1ff16aef4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b62b904446ce1e7218e80b14349822f1ff16aef4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1477,
          name: "Shining Hot 100 Mobile",
          uuid: "d88d2d54524f81d1d542bb014034287c7de59386",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d88d2d54524f81d1d542bb014034287c7de59386.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1480,
          name: "Shining Hot 20",
          uuid: "517a7cf6076df2c421a1e7799382f424de51a53d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/517a7cf6076df2c421a1e7799382f424de51a53d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1481,
          name: "Shining Hot 20 Mobile",
          uuid: "416bc42c44cffa6a077c5d2559a8f604bf84ab37",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/416bc42c44cffa6a077c5d2559a8f604bf84ab37.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1478,
          name: "Shining Hot 40",
          uuid: "d2abb240efacb5a855e9ed34bad27bb844fc6ec6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d2abb240efacb5a855e9ed34bad27bb844fc6ec6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1479,
          name: "Shining Hot 40 Mobile",
          uuid: "2191bc95305eb6052f853c4623fd324dcd92577c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2191bc95305eb6052f853c4623fd324dcd92577c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1482,
          name: "Shining Hot 5",
          uuid: "5d66344ee018225ac20c106d89d76b91f2894bf7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5d66344ee018225ac20c106d89d76b91f2894bf7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1483,
          name: "Shining Hot 5 Mobile",
          uuid: "176dce4446d4bc96f2e9432bb14014dd4dc3e7ee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/176dce4446d4bc96f2e9432bb14014dd4dc3e7ee.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1664,
          name: "Sky Bounty",
          uuid: "4193eb5397f64b579d6a6cf1f97998c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4193eb5397f64b579d6a6cf1f97998c6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1665,
          name: "Sky Bounty Mobile",
          uuid: "89d7d139f1f64876ace29a7b9f580acd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/89d7d139f1f64876ace29a7b9f580acd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1396,
          name: "Smugglers Cove",
          uuid: "fa05fbab709a913d75fbbfc1b2413fb2f8b7ff89",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fa05fbab709a913d75fbbfc1b2413fb2f8b7ff89.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1397,
          name: "Smugglers Cove Mobile",
          uuid: "70234d3e5601bf048453bdfcc540e4b79199467c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/70234d3e5601bf048453bdfcc540e4b79199467c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1544,
          name: "Snakes & Ladders 2 - Snake Eyes",
          uuid: "ccbb76fdf7dd444ba3d5f68d8a09d380",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ccbb76fdf7dd444ba3d5f68d8a09d380.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1545,
          name: "Snakes & Ladders 2 - Snake Eyes Mobile",
          uuid: "9fe2309bb256485c8fba13d74557a9f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9fe2309bb256485c8fba13d74557a9f0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1418,
          name: "Snakes and Ladders Megadice",
          uuid: "ea85ccc259c14e28cb70f21a88186f63f093bb6f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ea85ccc259c14e28cb70f21a88186f63f093bb6f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1419,
          name: "Snakes and Ladders Megadice Mobile",
          uuid: "a379011d50af1b47c3a54abf4b9ab095fd006a96",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a379011d50af1b47c3a54abf4b9ab095fd006a96.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1430,
          name: "Spaceman",
          uuid: "3411729144840da3eef10f613c4f140f9c1db699",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3411729144840da3eef10f613c4f140f9c1db699.png",
          type: "crash",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1431,
          name: "Spaceman Mobile",
          uuid: "ad89aeb8f763dc4a446b1998f1799ecdd9c37019",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ad89aeb8f763dc4a446b1998f1799ecdd9c37019.png",
          type: "crash",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1294,
          name: "Spartan King",
          uuid: "71c8c7aac2f94c65bb994ff83c8866ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/71c8c7aac2f94c65bb994ff83c8866ec.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1295,
          name: "Spartan King Mobile",
          uuid: "b53e531b7c75432e9951c4a02069ab54",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b53e531b7c75432e9951c4a02069ab54.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1652,
          name: "Spellbinding Mystery",
          uuid: "b1beeb92f772474a84c92fd51832234c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b1beeb92f772474a84c92fd51832234c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1653,
          name: "Spellbinding Mystery Mobile",
          uuid: "5f406d18242d4d99b988ecfcd0d95b98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5f406d18242d4d99b988ecfcd0d95b98.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1536,
          name: "Spin & Score Megaways",
          uuid: "1d59360462e71c7b6dc7eac205333c05d5f31aba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1d59360462e71c7b6dc7eac205333c05d5f31aba.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1537,
          name: "Spin & Score Megaways Mobile",
          uuid: "f365eea9c36984bf239005826f1e32ee82979d09",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f365eea9c36984bf239005826f1e32ee82979d09.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1442,
          name: "Spirit of Adventure",
          uuid: "ab1c2723256b4abd22d83d9b22541169d85a2aff",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ab1c2723256b4abd22d83d9b22541169d85a2aff.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1443,
          name: "Spirit of Adventure Mobile",
          uuid: "6ea6484b384546c1b164d3f125fda580a86ce596",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6ea6484b384546c1b164d3f125fda580a86ce596.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1090,
          name: "Star Bounty",
          uuid: "edf86dc650b648039885d29e1903cda1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/edf86dc650b648039885d29e1903cda1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1091,
          name: "Star Bounty Mobile",
          uuid: "820bd10ce4b04f38a8586f41c7234b30",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/820bd10ce4b04f38a8586f41c7234b30.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1378,
          name: "Star Pirates Code",
          uuid: "81c8a9717939268d174e905a8769a8afe55a5a12",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/81c8a9717939268d174e905a8769a8afe55a5a12.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1379,
          name: "Star Pirates Code Mobile",
          uuid: "a31b3bb308f9ac9600646aad985f66bce2b8a557",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a31b3bb308f9ac9600646aad985f66bce2b8a557.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1562,
          name: "Starlight Christmas",
          uuid: "c705694338fc6a1b91e814b20fac16850a06133e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c705694338fc6a1b91e814b20fac16850a06133e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1563,
          name: "Starlight Christmas Mobile",
          uuid: "50723b629ec3c9eb49b48aa6362010ef81220745",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/50723b629ec3c9eb49b48aa6362010ef81220745.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1366,
          name: "Starlight Princess",
          uuid: "313efcd80394003d9f57a75d99356f4296055670",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/313efcd80394003d9f57a75d99356f4296055670.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1714,
          name: "Starlight Princess 1000",
          uuid: "37581d2d68be49e29d989c2d5b9674f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/37581d2d68be49e29d989c2d5b9674f8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1715,
          name: "Starlight Princess 1000 Mobile",
          uuid: "b396a0f15c5e411d93a3543ce2eb62ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b396a0f15c5e411d93a3543ce2eb62ea.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1367,
          name: "Starlight Princess Mobile",
          uuid: "6fbbc9aa34be0410d736c3144115dde142fe1d1d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6fbbc9aa34be0410d736c3144115dde142fe1d1d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1820,
          name: "Starlight Princess Pachi",
          uuid: "ebf822796203473abc8c9cf64eed6b3e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ebf822796203473abc8c9cf64eed6b3e.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1821,
          name: "Starlight Princess Pachi Mobile",
          uuid: "dfbe10b1260546a9ae4e798a9f2fe7fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/dfbe10b1260546a9ae4e798a9f2fe7fe.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1184,
          name: "Starz Megaways",
          uuid: "438fe862e32b485b8dd3e5848995acae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/438fe862e32b485b8dd3e5848995acae.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1185,
          name: "Starz Megaways Mobile",
          uuid: "4158ad32889f4404913ecf00898bfdbe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4158ad32889f4404913ecf00898bfdbe.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1636,
          name: "Sticky Bees",
          uuid: "0e1e273ae5e84b3c9f861cd42813fdfb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0e1e273ae5e84b3c9f861cd42813fdfb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1637,
          name: "Sticky Bees Mobile",
          uuid: "fd0c74e9533d4e889363cd4bd9a0f91c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/fd0c74e9533d4e889363cd4bd9a0f91c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1772,
          name: "Strawberry Cocktail",
          uuid: "0f909d74565a4503afca3cc95022942f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0f909d74565a4503afca3cc95022942f.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1773,
          name: "Strawberry Cocktail Mobile",
          uuid: "e2d1f9aca39848258cc03ba29f05c398",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e2d1f9aca39848258cc03ba29f05c398.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1226,
          name: "Street Racer",
          uuid: "aea7094ca363498dba954f9084900a45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/aea7094ca363498dba954f9084900a45.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1227,
          name: "Street Racer Mobile",
          uuid: "937af30214c1441ebcf8ad5bbe624c1f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/937af30214c1441ebcf8ad5bbe624c1f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1508,
          name: "Striking Hot 5",
          uuid: "e77f3e72cb1318aad696f0a76ffe24f636923434",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e77f3e72cb1318aad696f0a76ffe24f636923434.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1509,
          name: "Striking Hot 5 Mobile",
          uuid: "6df2898c50bed9be9af3b65fe59d5ff455b1b18e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6df2898c50bed9be9af3b65fe59d5ff455b1b18e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1468,
          name: "Sugar Rush",
          uuid: "d8f118e9bfd01ace4f417a8adf3c72845788a658",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d8f118e9bfd01ace4f417a8adf3c72845788a658.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1788,
          name: "Sugar Rush 1000",
          uuid: "f1ea0268c65d44babe977efe24e26c4e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f1ea0268c65d44babe977efe24e26c4e.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1789,
          name: "Sugar Rush 1000 Mobile",
          uuid: "9b4100a8173d4eb38d65105b4583e74d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9b4100a8173d4eb38d65105b4583e74d.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1887,
          name: "Sugar Rush Dice",
          uuid: "0c622b013ca9d577650f38539ff7d5c06b80d2b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0c622b013ca9d577650f38539ff7d5c06b80d2b7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1886,
          name: "Sugar Rush Dice Mobile",
          uuid: "38c8868618410a3838463f349c84f3c2bb48bd80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/38c8868618410a3838463f349c84f3c2bb48bd80.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1469,
          name: "Sugar Rush Mobile",
          uuid: "a90e2dfc927a7af291051b192c0ab32376cc5c2d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a90e2dfc927a7af291051b192c0ab32376cc5c2d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1728,
          name: "Sugar Rush Xmas",
          uuid: "eaf5e5bc31534eaeb03d4076feb64526",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/eaf5e5bc31534eaeb03d4076feb64526.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1729,
          name: "Sugar Rush Xmas Mobile",
          uuid: "e6708ff7085647a0bd86ec5f2071fe14",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e6708ff7085647a0bd86ec5f2071fe14.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1718,
          name: "Sugar Supreme Powernudge",
          uuid: "f7217d56fe7542be984cd20ac8f57792",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f7217d56fe7542be984cd20ac8f57792.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1719,
          name: "Sugar Supreme Powernudge Mobile",
          uuid: "d08cf99443e44cc2be9a69d703cfadfc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d08cf99443e44cc2be9a69d703cfadfc.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1870,
          name: "Sumo Supreme Megaway",
          uuid: "b60ea48d1bf54dbbb52e3fb7f3902207",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b60ea48d1bf54dbbb52e3fb7f3902207.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1871,
          name: "Sumo Supreme Megaway Mobile",
          uuid: "178922d5552e4634821a4c5462256ad5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/178922d5552e4634821a4c5462256ad5.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1108,
          name: "Super 7s",
          uuid: "a127a5149f2447508dce2a5e96535a28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a127a5149f2447508dce2a5e96535a28.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1109,
          name: "Super 7s  Mobile",
          uuid: "152f7d72ccfe4291b73514e0b207fa15",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/152f7d72ccfe4291b73514e0b207fa15.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1098,
          name: "Super Joker",
          uuid: "12d9eb7b20b44c01808d7da3a8cf00ca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/12d9eb7b20b44c01808d7da3a8cf00ca.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1099,
          name: "Super Joker Mobile",
          uuid: "7d6037c121224cf7a79d350602571934",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7d6037c121224cf7a79d350602571934.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1388,
          name: "Super X",
          uuid: "b05e9c3ad344decc1bc43c3f7ae6dde5c0dbbcc5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b05e9c3ad344decc1bc43c3f7ae6dde5c0dbbcc5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1389,
          name: "Super X Mobile",
          uuid: "2f800f1cf78ff35137188a764fbcb714bdda5787",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2f800f1cf78ff35137188a764fbcb714bdda5787.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1014,
          name: "Sweet Bonanza",
          uuid: "0b6e2e38d76c4a40bf6ab7235f92c5e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0b6e2e38d76c4a40bf6ab7235f92c5e7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1829,
          name: "Sweet Bonanza 1000",
          uuid: "df61abd27adc941e19df1accbe88208ba21e4f70",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/df61abd27adc941e19df1accbe88208ba21e4f70.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1828,
          name: "Sweet Bonanza 1000 Mobile",
          uuid: "212234fd5214a67d91717fa6edda6d8e54640eec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/212234fd5214a67d91717fa6edda6d8e54640eec.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1583,
          name: "Sweet Bonanza Dice",
          uuid: "6f98c88fd123031accd2512d7fab0a059335de0c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6f98c88fd123031accd2512d7fab0a059335de0c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1582,
          name: "Sweet Bonanza Dice Mobile",
          uuid: "d1840f09fdea984e5b7c61605e8847faa59ad323",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d1840f09fdea984e5b7c61605e8847faa59ad323.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1015,
          name: "Sweet Bonanza Mobile",
          uuid: "f5470d59bedf4dfca216bb37f8c2e3ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f5470d59bedf4dfca216bb37f8c2e3ff.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1048,
          name: "Sweet Bonanza Xmas",
          uuid: "8a0ed0127b29422b97e0390177441407",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8a0ed0127b29422b97e0390177441407.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1049,
          name: "Sweet Bonanza Xmas Mobile",
          uuid: "f9d0e6d6fa5740a5b47c77668c63ede6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f9d0e6d6fa5740a5b47c77668c63ede6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1851,
          name: "Sweet Kingdom",
          uuid: "1966e18b503e6df312fbe58843f54331ca38c103",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1966e18b503e6df312fbe58843f54331ca38c103.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1850,
          name: "Sweet Kingdom Mobile",
          uuid: "1786dc221f50e226bb16765f79b9c1080c5bb357",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1786dc221f50e226bb16765f79b9c1080c5bb357.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1558,
          name: "Sweet Powernudge",
          uuid: "83d4bd279ba5491fb945c7119822dea9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/83d4bd279ba5491fb945c7119822dea9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1559,
          name: "Sweet Powernudge Mobile",
          uuid: "026bbfae19df4a7e8c370974c45edc0f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/026bbfae19df4a7e8c370974c45edc0f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1532,
          name: "Sword of Ares",
          uuid: "915addd1fe104863e65027dafc233577df3f690f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/915addd1fe104863e65027dafc233577df3f690f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1533,
          name: "Sword of Ares Mobile",
          uuid: "de92ca4f6ddeecc4780e175ddb00d74ab0b43bac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/de92ca4f6ddeecc4780e175ddb00d74ab0b43bac.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1230,
          name: "Tales of Egypt",
          uuid: "58732f8b75944836bfb01dfc37d3c848",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/58732f8b75944836bfb01dfc37d3c848.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1231,
          name: "Tales of Egypt Mobile",
          uuid: "8b891a1e59fa48e2a815b3c7fb1b4ecb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8b891a1e59fa48e2a815b3c7fb1b4ecb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1328,
          name: "Temujin Treasures",
          uuid: "99c9b6b97a6f2521e512f13453701ed9de01ed05",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/99c9b6b97a6f2521e512f13453701ed9de01ed05.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1329,
          name: "Temujin Treasures Mobile",
          uuid: "2d3d06a1ac3d65705f8690aab54ea1fceb1243e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2d3d06a1ac3d65705f8690aab54ea1fceb1243e4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1764,
          name: "The Alter Ego",
          uuid: "14737dfe24ba4367af30764616a483c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/14737dfe24ba4367af30764616a483c0.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1765,
          name: "The Alter Ego Mobile",
          uuid: "d15126fb59b441079f11c761c7f634d9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d15126fb59b441079f11c761c7f634d9.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1312,
          name: "The Amazing Money Machine",
          uuid: "70ef63133635c6a79c9a6b90913180bb3dad72f5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/70ef63133635c6a79c9a6b90913180bb3dad72f5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1313,
          name: "The Amazing Money Machine Mobile",
          uuid: "9012ee8463aeedb4d3165a65ab16c2d76f94816d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9012ee8463aeedb4d3165a65ab16c2d76f94816d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1749,
          name: "The Big Dawgs",
          uuid: "a51e3ca7c0fd4f2d944580a326e2e9f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a51e3ca7c0fd4f2d944580a326e2e9f7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1748,
          name: "The Big Dawgs Mobile",
          uuid: "baaa822aa29a4bd6891cb52c17949483",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/baaa822aa29a4bd6891cb52c17949483.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1258,
          name: "The Catfather",
          uuid: "a48824bcf809420b872c22cef172bab2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a48824bcf809420b872c22cef172bab2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1259,
          name: "The Catfather Mobile",
          uuid: "0f584dcba06349b0b9fa55dee4873fc9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0f584dcba06349b0b9fa55dee4873fc9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1260,
          name: "The Catfather Part II",
          uuid: "bc685bc0aebc4bcc8e209af62b387fef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bc685bc0aebc4bcc8e209af62b387fef.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1261,
          name: "The Catfather Part II Mobile",
          uuid: "83b5e7205aef4cc4bcde5f79da16e169",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/83b5e7205aef4cc4bcde5f79da16e169.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1262,
          name: "The Champions",
          uuid: "75fd75792401499f96fd0640ae1e9126",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/75fd75792401499f96fd0640ae1e9126.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1263,
          name: "The Champions Mobile",
          uuid: "eca86440699f4dca9e259d92b366e8eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/eca86440699f4dca9e259d92b366e8eb.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1028,
          name: "The Dog House",
          uuid: "24af37f89f48429eb57f315f3c5c80d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/24af37f89f48429eb57f315f3c5c80d7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6787,
          name: "The Dog House - Mutley Crew",
          uuid: "b03e5f6e9a664865925dbe4272e01c0a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b03e5f6e9a664865925dbe4272e01c0a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6788,
          name: "The Dog House - Mutley Crew Mobile",
          uuid: "5485b0fb13ee40118fb03152be012f36",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5485b0fb13ee40118fb03152be012f36.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1579,
          name: "The Dog House Dice",
          uuid: "6e526075a5fc443d1a4a8fc827d79efb5b4bcaf2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6e526075a5fc443d1a4a8fc827d79efb5b4bcaf2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1578,
          name: "The Dog House Dice Mobile",
          uuid: "ed4d6c0a0bc7d20c700ea17bcbd90a77dafe123b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ed4d6c0a0bc7d20c700ea17bcbd90a77dafe123b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1058,
          name: "The Dog House Megaways",
          uuid: "b3a22c28284e4de69efb358940838cb1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b3a22c28284e4de69efb358940838cb1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        },
        {
          game_id: 1059,
          name: "The Dog House Megaways Mobile",
          uuid: "bc68834c779e4d00b757605f4e83aab9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bc68834c779e4d00b757605f4e83aab9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1029,
          name: "The Dog House Mobile",
          uuid: "fddb47d4f90e4476855b02a931279291",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/fddb47d4f90e4476855b02a931279291.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1596,
          name: "The Dog House Multihold",
          uuid: "03f4c89207be4570bd2d14c86cc3ccc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/03f4c89207be4570bd2d14c86cc3ccc8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1597,
          name: "The Dog House Multihold Mobile",
          uuid: "3d39a43c600a4ce3b2a2bd280d01f11a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3d39a43c600a4ce3b2a2bd280d01f11a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1787,
          name: "The Dog House – Dog or Alive",
          uuid: "93edebf16500254c4e6e97965df90d8e1694d21f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/93edebf16500254c4e6e97965df90d8e1694d21f.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1786,
          name: "The Dog House – Dog or Alive Mobile",
          uuid: "62e2ce45ef5341894860be22e85aa341640b8186",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/62e2ce45ef5341894860be22e85aa341640b8186.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1170,
          name: "The Dragon Tiger",
          uuid: "41d9bbcd73c24e7faef82347e188f0e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/41d9bbcd73c24e7faef82347e188f0e4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1171,
          name: "The Dragon Tiger Mobile",
          uuid: "8850dcd5efe5454b8f8cb32d2e668163",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8850dcd5efe5454b8f8cb32d2e668163.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1172,
          name: "The Great Chicken Escape",
          uuid: "609d555a9b1e4a9ab21fcbd415f77b60",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/609d555a9b1e4a9ab21fcbd415f77b60.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1173,
          name: "The Great Chicken Escape Mobile",
          uuid: "d8e0a6b791394db59728312cc2a85537",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d8e0a6b791394db59728312cc2a85537.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1450,
          name: "The Great Stick-Up",
          uuid: "12d7e210cad6cffa7f7d7f1288647aad8e89a948",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/12d7e210cad6cffa7f7d7f1288647aad8e89a948.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1451,
          name: "The Great Stick-Up Mobile",
          uuid: "2480f9172a88d19b4f9ea4a9c032080e6994cf0a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2480f9172a88d19b4f9ea4a9c032080e6994cf0a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1338,
          name: "The Hand of Midas",
          uuid: "10e5227c3e47bcf6fe5464370ece50caa4c6157b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/10e5227c3e47bcf6fe5464370ece50caa4c6157b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1339,
          name: "The Hand of Midas Mobile",
          uuid: "d42203952a8123ebea2eb0c1e4e7f28db8930293",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d42203952a8123ebea2eb0c1e4e7f28db8930293.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1594,
          name: "The Knight King",
          uuid: "a12757a2601841b29b31843d2f01a1e2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a12757a2601841b29b31843d2f01a1e2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1595,
          name: "The Knight King Mobile",
          uuid: "3129c78bcc4f44cbaf0de3892f80c94d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3129c78bcc4f44cbaf0de3892f80c94d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1306,
          name: "The Magic Cauldron - Enchanted Brew",
          uuid: "e50f860dde1387888368b53ff2810cc225ed46ad",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e50f860dde1387888368b53ff2810cc225ed46ad.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1307,
          name: "The Magic Cauldron - Enchanted Brew Mobile",
          uuid: "47b1faff116f1a7bec564afff033451db3b8f48b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/47b1faff116f1a7bec564afff033451db3b8f48b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1712,
          name: "The Money Men Megaways",
          uuid: "9c9f398686da4b0a8523e224f573e60f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9c9f398686da4b0a8523e224f573e60f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1713,
          name: "The Money Men Megaways Mobile",
          uuid: "38eb7907be854f1c95a259774ae79a1b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/38eb7907be854f1c95a259774ae79a1b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1608,
          name: "The Red Queen",
          uuid: "f818272ac69c4d06b8a3c92410bc7744",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f818272ac69c4d06b8a3c92410bc7744.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1609,
          name: "The Red Queen Mobile",
          uuid: "189235d7ad97425f8f1d98d0b3013ae1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/189235d7ad97425f8f1d98d0b3013ae1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1412,
          name: "The Ultimate 5",
          uuid: "5b21f9105be1db0def92bdfa3c19d3ac7f674e24",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5b21f9105be1db0def92bdfa3c19d3ac7f674e24.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1413,
          name: "The Ultimate 5 Mobile",
          uuid: "49ccc0f602cf6b702067efff95148190c9e298f0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/49ccc0f602cf6b702067efff95148190c9e298f0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1726,
          name: "The Wild Gang",
          uuid: "8720d4f44dca43e2a66e122c0ee8a6cd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8720d4f44dca43e2a66e122c0ee8a6cd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1727,
          name: "The Wild Gang Mobile",
          uuid: "0ea3fb8bc39f4f80848bb58f2879280e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0ea3fb8bc39f4f80848bb58f2879280e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1132,
          name: "The Wild Machine",
          uuid: "1ef11118420344d7a974029c73165e8b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1ef11118420344d7a974029c73165e8b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1133,
          name: "The Wild Machine Mobile",
          uuid: "b1f4f5282d1040caa04151d573674e54",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b1f4f5282d1040caa04151d573674e54.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1100,
          name: "Three Star Fortune",
          uuid: "cd002eeab2a7479185a2b7648765c966",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/cd002eeab2a7479185a2b7648765c966.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1101,
          name: "Three Star Fortune Mobile",
          uuid: "284ccb619b7f4f69857c04481fea847c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/284ccb619b7f4f69857c04481fea847c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1428,
          name: "Tic Tac Take",
          uuid: "49ffaba1ed0f6c8dc6fc19def4ae1fd2761a6e29",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/49ffaba1ed0f6c8dc6fc19def4ae1fd2761a6e29.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1429,
          name: "Tic Tac Take Mobile",
          uuid: "13a02a536df2793856df041d243447123954afff",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/13a02a536df2793856df041d243447123954afff.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1720,
          name: "Timber Stacks",
          uuid: "e12d150e055a4480a9c5337729bc39ae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e12d150e055a4480a9c5337729bc39ae.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1721,
          name: "Timber Stacks Mobile",
          uuid: "5193d934c9374734b93a098d726a7916",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5193d934c9374734b93a098d726a7916.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1538,
          name: "Towering Fortunes",
          uuid: "13d33093ccd208fd2827300e5d415e7f57940d13",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/13d33093ccd208fd2827300e5d415e7f57940d13.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1539,
          name: "Towering Fortunes Mobile",
          uuid: "6912c0baa402e0bdbdac9a7a9586bfe351e7ece9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/6912c0baa402e0bdbdac9a7a9586bfe351e7ece9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1122,
          name: "Treasure Horse",
          uuid: "5df187cfc28b41daac1712530a60fe82",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5df187cfc28b41daac1712530a60fe82.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1123,
          name: "Treasure Horse Mobile",
          uuid: "f4526201a90448f2b3fe4fae71aa5ca0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f4526201a90448f2b3fe4fae71aa5ca0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1368,
          name: "Treasure Wild",
          uuid: "3708bf80a63c5fd7146d83545e5789ae6a91e814",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3708bf80a63c5fd7146d83545e5789ae6a91e814.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1369,
          name: "Treasure Wild Mobile",
          uuid: "ce897f5f9bdaad749fc26f22d46cdc631b862db7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ce897f5f9bdaad749fc26f22d46cdc631b862db7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1066,
          name: "Tree of Riches",
          uuid: "b66f7b4698be4d468baf3aa1d7c1da67",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b66f7b4698be4d468baf3aa1d7c1da67.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1067,
          name: "Tree of Riches Mobile",
          uuid: "b4aa9e96216d46f5ad80a00f5c504a04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b4aa9e96216d46f5ad80a00f5c504a04.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1766,
          name: "Trees of Treasure",
          uuid: "fd57156ede4a4696ac393adfdf90f786",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/fd57156ede4a4696ac393adfdf90f786.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1767,
          name: "Trees of Treasure Mobile",
          uuid: "3ff78e6d87b843c1a8b06f39f3270161",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/3ff78e6d87b843c1a8b06f39f3270161.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1198,
          name: "Triple Dragons",
          uuid: "0810d4b865964649ba40456078d3dbf3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0810d4b865964649ba40456078d3dbf3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1199,
          name: "Triple Dragons Mobile",
          uuid: "9601ee74f02e45dcb46ba331f666437e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9601ee74f02e45dcb46ba331f666437e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1176,
          name: "Triple Jokers",
          uuid: "c0f2556c569c4945ae5fa10aff7f94fc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c0f2556c569c4945ae5fa10aff7f94fc.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1177,
          name: "Triple Jokers Mobile",
          uuid: "2c8b031e5d664a94b1cc0aef695ff186",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2c8b031e5d664a94b1cc0aef695ff186.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1096,
          name: "Triple Tigers",
          uuid: "621ead271103416a8bfb1713135c3017",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/621ead271103416a8bfb1713135c3017.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1097,
          name: "Triple Tigers Mobile",
          uuid: "ba475ede073f4f609abf62046155be2b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ba475ede073f4f609abf62046155be2b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1474,
          name: "Tropical Tiki",
          uuid: "1daa099203842ad2fd9ef389d3a259364001894e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1daa099203842ad2fd9ef389d3a259364001894e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1475,
          name: "Tropical Tiki Mobile Mobile",
          uuid: "473b8a1c5b5d8f7fa0415d80f854c38e63ea7b43",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/473b8a1c5b5d8f7fa0415d80f854c38e63ea7b43.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1706,
          name: "Tundra’s Fortune",
          uuid: "5f0094a4947a4980aa461377204a182f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5f0094a4947a4980aa461377204a182f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1707,
          name: "Tundra’s Fortune Mobile",
          uuid: "92cdd36f5ae445529b2915f126d34a87",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/92cdd36f5ae445529b2915f126d34a87.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1700,
          name: "Twilight Princess",
          uuid: "b64ff516615c425c9d4a78420c24ac3b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b64ff516615c425c9d4a78420c24ac3b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1701,
          name: "Twilight Princess Mobile",
          uuid: "d5c16b25f1734b6a8b3bfa649b3488b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/d5c16b25f1734b6a8b3bfa649b3488b2.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1152,
          name: "Ultra Burn",
          uuid: "643d8283848a46a5878048f18bdd4bb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/643d8283848a46a5878048f18bdd4bb3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1153,
          name: "Ultra Burn Mobile",
          uuid: "8914af790d18495f95b4e389b9a982e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8914af790d18495f95b4e389b9a982e0.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1034,
          name: "Ultra Hold and Spin",
          uuid: "14833412b2c543e987c912f79ddd1959",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/14833412b2c543e987c912f79ddd1959.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1035,
          name: "Ultra Hold and Spin Mobile",
          uuid: "eec15eace75041119ae6a91f45bd733b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/eec15eace75041119ae6a91f45bd733b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1216,
          name: "Vampires vs Wolves",
          uuid: "7dfdba859a3f4071921e788fe4e694ab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/7dfdba859a3f4071921e788fe4e694ab.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1217,
          name: "Vampires vs Wolves Mobile",
          uuid: "1a62e0746fe14a84a815302ff1a569c8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1a62e0746fe14a84a815302ff1a569c8.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1012,
          name: "Vegas Magic",
          uuid: "0aac8b0bec1d41b789870d690babeb75",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0aac8b0bec1d41b789870d690babeb75.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1013,
          name: "Vegas Magic Mobile",
          uuid: "42637c6e51bb42ab97c5b496de89ef8c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/42637c6e51bb42ab97c5b496de89ef8c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1212,
          name: "Vegas Nights",
          uuid: "ef797eb3b3fa46e8b64fa5b878346d1d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ef797eb3b3fa46e8b64fa5b878346d1d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1213,
          name: "Vegas Nights Mobile",
          uuid: "0f147fe760d042339f178657d998123b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0f147fe760d042339f178657d998123b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1716,
          name: "Viking Forge",
          uuid: "99a4a20f306f47148b1332f8e2a4919c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/99a4a20f306f47148b1332f8e2a4919c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1717,
          name: "Viking Forge Mobile",
          uuid: "25b43202ed724a479d299ecdd18ad2b3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/25b43202ed724a479d299ecdd18ad2b3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1296,
          name: "Voodoo Magic",
          uuid: "15f7776903a744c6aab085ca1d6e8a14",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/15f7776903a744c6aab085ca1d6e8a14.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1297,
          name: "Voodoo Magic Mobile",
          uuid: "9f2cdd85fea242f6a7ffe0423b28fc1f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9f2cdd85fea242f6a7ffe0423b28fc1f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1776,
          name: "Wheel O’Gold",
          uuid: "ebc56fdbfca946618e7e9955ecaad099",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/ebc56fdbfca946618e7e9955ecaad099.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1777,
          name: "Wheel O’Gold Mobile",
          uuid: "9077b6a0d8774d6cbfae56b2da727998",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/9077b6a0d8774d6cbfae56b2da727998.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1424,
          name: "Wild Beach Party",
          uuid: "853793bc3cf809a8943103470733dfe4b709c441",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/853793bc3cf809a8943103470733dfe4b709c441.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1425,
          name: "Wild Beach Party Mobile",
          uuid: "b9bd9792872af17809fd9578fa47b62a44f43ce6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b9bd9792872af17809fd9578fa47b62a44f43ce6.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1624,
          name: "Wild Bison Charge",
          uuid: "c9244c69578d48c28b84952ae2a6ea2f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c9244c69578d48c28b84952ae2a6ea2f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1625,
          name: "Wild Bison Charge Mobile",
          uuid: "1b2a3ed83cfa441093dbc892a1341acc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1b2a3ed83cfa441093dbc892a1341acc.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1320,
          name: "Wild Booster",
          uuid: "26d0a2ea75360c1e9973ef349941d1ae9391cb5c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/26d0a2ea75360c1e9973ef349941d1ae9391cb5c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1321,
          name: "Wild Booster Mobile",
          uuid: "2c8eb36442ff68b356045d8a54b72c80ce15efc4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2c8eb36442ff68b356045d8a54b72c80ce15efc4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1614,
          name: "Wild Celebrity Bus Megaways",
          uuid: "e2862efa81354e87b9e1bf2abc29ed35",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e2862efa81354e87b9e1bf2abc29ed35.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1615,
          name: "Wild Celebrity Bus Megaways Mobile",
          uuid: "5dc884356b974b8eb998a63c9262abd4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5dc884356b974b8eb998a63c9262abd4.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1402,
          name: "Wild Depths",
          uuid: "4b7ac824daec2a004fe2a414cc089f582a5cc67f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4b7ac824daec2a004fe2a414cc089f582a5cc67f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1403,
          name: "Wild Depths Mobile",
          uuid: "316ddc581b4336e99d0f07df1b721ed0065d819a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/316ddc581b4336e99d0f07df1b721ed0065d819a.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1222,
          name: "Wild Gladiator",
          uuid: "b6eb6967e89c48a49ef6416cdc7cd83d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/b6eb6967e89c48a49ef6416cdc7cd83d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1223,
          name: "Wild Gladiator Mobile",
          uuid: "23ecc40fc8c543c4bbbecfc5bec1dd39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/23ecc40fc8c543c4bbbecfc5bec1dd39.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1514,
          name: "Wild Hop&Drop",
          uuid: "d7988d1b13f87ee71fb538a92c53cbbce22ca97e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d7988d1b13f87ee71fb538a92c53cbbce22ca97e.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1515,
          name: "Wild Hop&Drop Mobile",
          uuid: "3dd15e8367c4938313e8845dc8ab21f43d952e7b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3dd15e8367c4938313e8845dc8ab21f43d952e7b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1140,
          name: "Wild Pixies",
          uuid: "8f8910fa7a8144aaa22acde49827b95b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8f8910fa7a8144aaa22acde49827b95b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1141,
          name: "Wild Pixies Mobile",
          uuid: "afe9f804cbfa488c82233f2e0bec5e75",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/afe9f804cbfa488c82233f2e0bec5e75.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1084,
          name: "Wild Spells",
          uuid: "467ed41697f84143850e4aae541be2c5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/467ed41697f84143850e4aae541be2c5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1085,
          name: "Wild Spells Mobile",
          uuid: "775baef293cc46c8aff32337b944c669",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/775baef293cc46c8aff32337b944c669.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1118,
          name: "Wild Walker",
          uuid: "72453c16f8624ae889802612b8bc3eb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/72453c16f8624ae889802612b8bc3eb3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1119,
          name: "Wild Walker Mobile",
          uuid: "0ad4054f9deb4b5495fb28b888671dad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/0ad4054f9deb4b5495fb28b888671dad.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1592,
          name: "Wild West Duels",
          uuid: "34a045d02d32406a80b068499b669965",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/34a045d02d32406a80b068499b669965.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1593,
          name: "Wild West Duels Mobile",
          uuid: "683bfdd3fb604799be523380a615f2bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/683bfdd3fb604799be523380a615f2bc.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1026,
          name: "Wild West Gold",
          uuid: "70b4d615e17f49a78986cb07b284b3ed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/70b4d615e17f49a78986cb07b284b3ed.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1456,
          name: "Wild West Gold Megaways",
          uuid: "e192c07b0e432aab7517995db33b2c17d921de45",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e192c07b0e432aab7517995db33b2c17d921de45.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1457,
          name: "Wild West Gold Megaways Mobile",
          uuid: "ffcf01adc4d07bdfeada292c02ca37f80b3168b3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ffcf01adc4d07bdfeada292c02ca37f80b3168b3.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1027,
          name: "Wild West Gold Mobile",
          uuid: "4276225f34e64fc6ae7454e6970d28e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/4276225f34e64fc6ae7454e6970d28e7.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1564,
          name: "Wild Wild Bananas",
          uuid: "449fa4701dd74003926a4cca27d839d5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/449fa4701dd74003926a4cca27d839d5.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1565,
          name: "Wild Wild Bananas Mobile",
          uuid: "24e978bb924048bd8392e37a524a8618",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/24e978bb924048bd8392e37a524a8618.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1070,
          name: "Wild Wild Riches",
          uuid: "8e3785e54136463b83996af7d9368146",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8e3785e54136463b83996af7d9368146.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1588,
          name: "Wild Wild Riches Megaways",
          uuid: "1cda4047f9374ea9bc829657a63fcb5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/1cda4047f9374ea9bc829657a63fcb5d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1589,
          name: "Wild Wild Riches Megaways Mobile",
          uuid: "8a20c3eda6c043a49f32c43c7d6c93a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/8a20c3eda6c043a49f32c43c7d6c93a9.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1071,
          name: "Wild Wild Riches Mobile",
          uuid: "157b9af2452a4ef98a7e489d8bae4b68",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/157b9af2452a4ef98a7e489d8bae4b68.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1832,
          name: "Wildies",
          uuid: "bd56f1dcddec48298e1ba11d10e05e18",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/bd56f1dcddec48298e1ba11d10e05e18.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1833,
          name: "Wildies Mobile",
          uuid: "96466926cdbf4f8eb71207ed65359564",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/96466926cdbf4f8eb71207ed65359564.jpg",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1650,
          name: "Wisdom of Athena",
          uuid: "c8973f1989ab45d7bf7229b62cdbac21",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/c8973f1989ab45d7bf7229b62cdbac21.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6785,
          name: "Wisdom of Athena 1000",
          uuid: "5b96b6af212e4e93a34dba18c0d2f07b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/5b96b6af212e4e93a34dba18c0d2f07b.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6786,
          name: "Wisdom of Athena 1000 Mobile",
          uuid: "e5640f84da794461bb5bb39d9e0faa7d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/e5640f84da794461bb5bb39d9e0faa7d.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1651,
          name: "Wisdom of Athena Mobile",
          uuid: "2c3921f7481b414d8d0dd04d14def251",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/2c3921f7481b414d8d0dd04d14def251.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1080,
          name: "Wolf Gold",
          uuid: "a46778b49c8e480f95ec530de24b8e81",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/a46778b49c8e480f95ec530de24b8e81.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1266,
          name: "Wolf Gold 1 Million",
          uuid: "21874c98bc0a4d39870d9261307cb03b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/21874c98bc0a4d39870d9261307cb03b.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1267,
          name: "Wolf Gold 1 Million Mobile",
          uuid: "43bc458a1fe24867aa0fee4486ccd03d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/43bc458a1fe24867aa0fee4486ccd03d.png",
          type: "scratch card",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1081,
          name: "Wolf Gold Mobile",
          uuid: "63eaff980b7b4891bd928d22906d7036",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/63eaff980b7b4891bd928d22906d7036.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1752,
          name: "Year of the Dragon King",
          uuid: "f19d381e9a934090ba843ca78cb264fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/f19d381e9a934090ba843ca78cb264fe.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1753,
          name: "Year of the Dragon King Mobile",
          uuid: "acb3a19c52a14aaf8e70ac051e843f5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/acb3a19c52a14aaf8e70ac051e843f5c.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1867,
          name: "Yeti Quest",
          uuid: "051869f0ee6943d68e88a7acd991ab38",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/051869f0ee6943d68e88a7acd991ab38.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1866,
          name: "Yeti Quest Mobile",
          uuid: "39a7dc4aea4c440395a335195e49b8ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/39a7dc4aea4c440395a335195e49b8ff.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1362,
          name: "Yum Yum Powerways",
          uuid: "63d6866a7465a5c194d651b8aa24f22aa5f49f2f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/63d6866a7465a5c194d651b8aa24f22aa5f49f2f.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1363,
          name: "Yum Yum Powerways Mobile",
          uuid: "16a552a90ae7bdcad292d948c3e2e8fb82db58bd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/16a552a90ae7bdcad292d948c3e2e8fb82db58bd.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1634,
          name: "Zeus vs Hades - Gods of War",
          uuid: "517d2e03971d41f6875781c9d5bd4443",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/517d2e03971d41f6875781c9d5bd4443.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1635,
          name: "Zeus vs Hades - Gods of War Mobile",
          uuid: "39a54043488743e1b2ba7b95cf26a9a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/PragmaticPlay/39a54043488743e1b2ba7b95cf26a9a1.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1458,
          name: "Zombie Carnival",
          uuid: "e42c47747a3bc40ef55c66438e693c24d967d390",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e42c47747a3bc40ef55c66438e693c24d967d390.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1459,
          name: "Zombie Carnival Mobile",
          uuid: "3252d142fa05afa7cd363d8574128eb697020c50",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3252d142fa05afa7cd363d8574128eb697020c50.png",
          type: "slots",
          provider: "PragmaticPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 141,
      name: "PushGaming",
      providerId: 15,
      providerName: "PushGaming",
      games: [
        {
          game_id: 661,
          name: "Deadly 5",
          uuid: "baaafdaa01524b888cfbe3c9e1f17214",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/baaafdaa01524b888cfbe3c9e1f17214.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 109,
          name: "Fat Banker",
          uuid: "3d5fb543aacc4bd0aa413aaa12edcb69",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/3d5fb543aacc4bd0aa413aaa12edcb69.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 662,
          name: "Fat Rabbit",
          uuid: "e6f5cf3e37ed46e48249aa0d99a8582c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/e6f5cf3e37ed46e48249aa0d99a8582c.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 95,
          name: "Fat Santa",
          uuid: "69ecc1ed18f04189ad4411cb18d0b74a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/69ecc1ed18f04189ad4411cb18d0b74a.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 663,
          name: "Fire Hopper",
          uuid: "226f6eeb54914aff99e3bd2fcc0dda13",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/226f6eeb54914aff99e3bd2fcc0dda13.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 664,
          name: "Generous Jack",
          uuid: "a3d7a11fe5b04dab9bc78d8e729dc391",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/a3d7a11fe5b04dab9bc78d8e729dc391.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 108,
          name: "Giga Jar",
          uuid: "7ff30fdc4f7648778a6a4d49c9929554",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/7ff30fdc4f7648778a6a4d49c9929554.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 107,
          name: "Jammin' Jars",
          uuid: "8f79688b6d454c87b2bc10fd2ddb6d61",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/8f79688b6d454c87b2bc10fd2ddb6d61.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 665,
          name: "Joker Troupe",
          uuid: "e17bb4f8a3d44ec8bfca929bca4483a7",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/e17bb4f8a3d44ec8bfca929bca4483a7.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 106,
          name: "Mystery Mission - To The Moon",
          uuid: "aede91876cdf46da8cf519c2c6cebf86",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/aede91876cdf46da8cf519c2c6cebf86.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 666,
          name: "Nightfall",
          uuid: "1dfcd03957064c2897e0c7078acb77e8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/1dfcd03957064c2897e0c7078acb77e8.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 667,
          name: "Retro Tapes",
          uuid: "7da35dd5e2ac49fa8accf199df8d3711",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/7da35dd5e2ac49fa8accf199df8d3711.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 668,
          name: "Viking Clash",
          uuid: "7fe8b69af0da453aaf4b305df07fe237",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/7fe8b69af0da453aaf4b305df07fe237.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 669,
          name: "Wheel of Wonders",
          uuid: "0d03702702fa40e993c534b9aae3b3bf",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/0d03702702fa40e993c534b9aae3b3bf.png",
          type: "slots",
          provider: "PushGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 142,
      name: "Qilin",
      providerId: 7,
      providerName: "Qilin",
      games: [
        {
          game_id: 695,
          name: "Dragon King",
          uuid: "22d801eaa2646e5235920812016c1343e5d9c1b9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/22d801eaa2646e5235920812016c1343e5d9c1b9.jpg",
          type: "slots",
          provider: "Qilin",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 696,
          name: "God of Wealth",
          uuid: "3d85b9d8b13c5cfad8f32dadff8e3a7e4b8ad86e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3d85b9d8b13c5cfad8f32dadff8e3a7e4b8ad86e.jpg",
          type: "slots",
          provider: "Qilin",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 697,
          name: "Great Fortune",
          uuid: "4489d091702ff038b78f9364ef242c503f33668c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4489d091702ff038b78f9364ef242c503f33668c.jpg",
          type: "slots",
          provider: "Qilin",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 698,
          name: "Journey to the West",
          uuid: "71ecf69521e34d45803c9f1cbb2cce41741599bc",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/71ecf69521e34d45803c9f1cbb2cce41741599bc.jpg",
          type: "slots",
          provider: "Qilin",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 699,
          name: "Lucky Eights",
          uuid: "87c0f1ea5f47e3b6e378d2c0937d7cbfe8dff580",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/87c0f1ea5f47e3b6e378d2c0937d7cbfe8dff580.jpg",
          type: "slots",
          provider: "Qilin",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 700,
          name: "Panda's Treasures",
          uuid: "028447689689c5aca9af2a13268b3070cbb3a938",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/028447689689c5aca9af2a13268b3070cbb3a938.jpg",
          type: "slots",
          provider: "Qilin",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 701,
          name: "Phoenix Fortune",
          uuid: "2e7e0389b2e8ef779828555344c027c1249ccfca",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2e7e0389b2e8ef779828555344c027c1249ccfca.jpg",
          type: "slots",
          provider: "Qilin",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 702,
          name: "Princess Xiaoming",
          uuid: "565270d3b987b8fd7bca93a387aaee00a490b701",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/565270d3b987b8fd7bca93a387aaee00a490b701.jpg",
          type: "slots",
          provider: "Qilin",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 703,
          name: "Qilin",
          uuid: "eef296f42d2c29f415cd4cdfc6d8feebb89bfb3f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/eef296f42d2c29f415cd4cdfc6d8feebb89bfb3f.jpg",
          type: "slots",
          provider: "Qilin",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 143,
      name: "RTG SLOTS",
      providerId: 31,
      providerName: "RTG SLOTS",
      games: [
        {
          game_id: 6895,
          name: "32 Cards",
          uuid: "793646d889eae7abe8d243d7908df58d16a0d013",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/793646d889eae7abe8d243d7908df58d16a0d013.png",
          type: "table",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6901,
          name: "Akbar Romeo Walter",
          uuid: "7f6a0025f6b54ca9851c5bc30696eec4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/7f6a0025f6b54ca9851c5bc30696eec4.png",
          type: "table",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6897,
          name: "Alien Wins",
          uuid: "98700f79d32f7d773e4ae9c07e5a8869ebaf5051",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/98700f79d32f7d773e4ae9c07e5a8869ebaf5051.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6795,
          name: "Ancient Gods",
          uuid: "a759e6b0c6275ac5000d5bdb3706ac53",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/a759e6b0c6275ac5000d5bdb3706ac53.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6899,
          name: "Andar Bahar",
          uuid: "c357a6c0757d6ebd3fa2f9970b83f63f1dbc4e85",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/c357a6c0757d6ebd3fa2f9970b83f63f1dbc4e85.png",
          type: "table",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6870,
          name: "Baccarat",
          uuid: "ecad8a5d73404dd08ef11667a44811da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/ecad8a5d73404dd08ef11667a44811da.png",
          type: "baccarat",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6796,
          name: "BAO NI 8",
          uuid: "358cc1c3f89b8bfbb324db7eaf60f90c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/358cc1c3f89b8bfbb324db7eaf60f90c.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6879,
          name: "Big Santa",
          uuid: "90cf875aef334c0c9c3b87ff8798ede6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/90cf875aef334c0c9c3b87ff8798ede6.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6797,
          name: "Bubble Bubble",
          uuid: "4d43ebca50bc21ad8af01136e4b7683a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/4d43ebca50bc21ad8af01136e4b7683a.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6798,
          name: "Bubble Bubble 2",
          uuid: "407f8a37a7f9c373e4c1c56c43727dac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/407f8a37a7f9c373e4c1c56c43727dac.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6799,
          name: "Cai Hong",
          uuid: "ae829954ec827ed0a1f972c57e8d1f9b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/ae829954ec827ed0a1f972c57e8d1f9b.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6800,
          name: "Cash Bandits 2",
          uuid: "709d6abbe73907480d92cb628ef95c84",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/709d6abbe73907480d92cb628ef95c84.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6902,
          name: "Cash Bandits Museum Heist",
          uuid: "96c00f64b2a79f84cd09300f3c8031cb8b48f678",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/96c00f64b2a79f84cd09300f3c8031cb8b48f678.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6860,
          name: "Chunky Monkey",
          uuid: "cae3acc29d8c41d4a458b72dbe101bb6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/cae3acc29d8c41d4a458b72dbe101bb6.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6801,
          name: "Coyote Cash",
          uuid: "6efb7a330db82857104c750d4af3fc0f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/6efb7a330db82857104c750d4af3fc0f.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6802,
          name: "Cubee",
          uuid: "c922c3b123d67699362fefd5f5f41ee5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/c922c3b123d67699362fefd5f5f41ee5.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6853,
          name: "Dancing Wins",
          uuid: "bf934b3931fe00663a6cd448bd49eee128c09692",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bf934b3931fe00663a6cd448bd49eee128c09692.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6893,
          name: "Desert Raider",
          uuid: "e6c900b36a2b40f1b412df1800e9e30d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/e6c900b36a2b40f1b412df1800e9e30d.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6890,
          name: "Diamond Fiesta",
          uuid: "879183c56eaae54aa38fbbff8ebcbff1a699cc87",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/879183c56eaae54aa38fbbff8ebcbff1a699cc87.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6894,
          name: "Divas of Darkness",
          uuid: "5527222c8f9aed3d08a1e9850ec08f189ef1c5d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/5527222c8f9aed3d08a1e9850ec08f189ef1c5d7.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6900,
          name: "Doragon's Gems",
          uuid: "2f5d8fec4a9f5332273b7a3df10ed2e2566bad65",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/2f5d8fec4a9f5332273b7a3df10ed2e2566bad65.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6803,
          name: "Dragon Orb",
          uuid: "2c03977cb3204ac175fa15c2a64fb7ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/2c03977cb3204ac175fa15c2a64fb7ea.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6804,
          name: "Eagle Shadow Fist",
          uuid: "2e96683ccd9eacbf98bcb648062127e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/2e96683ccd9eacbf98bcb648062127e6.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6854,
          name: "Emperor Panda",
          uuid: "c7be3e8f38b65bdb5c52ad8ca0938d652c96f0b8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c7be3e8f38b65bdb5c52ad8ca0938d652c96f0b8.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6805,
          name: "Enchanted Garden",
          uuid: "5f9f029f251c9577156d1060a1bc9d38",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/5f9f029f251c9577156d1060a1bc9d38.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6806,
          name: "Enchanted Garden II",
          uuid: "6321eb7c415af8dfbaed58f9173bf90d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/6321eb7c415af8dfbaed58f9173bf90d.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6807,
          name: "Eternal Love",
          uuid: "6b0ead221dbb41750b1051b15325a6bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/6b0ead221dbb41750b1051b15325a6bf.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6808,
          name: "Fantasy Mission Force",
          uuid: "79d679a6e9620012b88240c319aa4a22",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/79d679a6e9620012b88240c319aa4a22.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6809,
          name: "Fat Cat Café",
          uuid: "e3db0d53d22e21f36db0c2d368e039e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/e3db0d53d22e21f36db0c2d368e039e4.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6810,
          name: "Fire Dragon",
          uuid: "af5d19e0ae9038afa822b6b7ac350bc2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/af5d19e0ae9038afa822b6b7ac350bc2.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6880,
          name: "Fish Catch",
          uuid: "3eaf6a8b0bf9479ebc885ed24ff2c54a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/3eaf6a8b0bf9479ebc885ed24ff2c54a.png",
          type: "shooting",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6811,
          name: "Football Fortunes",
          uuid: "7c35b0224eb58d770fe3ac3aa280a5d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/7c35b0224eb58d770fe3ac3aa280a5d7.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6812,
          name: "Football Frenzy",
          uuid: "b8660244ad1dc13770c62f46d4977b37",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/b8660244ad1dc13770c62f46d4977b37.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6863,
          name: "Fortunate Rabbit",
          uuid: "280c468542a1ba1853a9e5a58b251387a4a5248a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/280c468542a1ba1853a9e5a58b251387a4a5248a.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6813,
          name: "Fortune Frog",
          uuid: "fcfb26aadbf567686708c1574ccc8629",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/fcfb26aadbf567686708c1574ccc8629.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6874,
          name: "Fortunes of Olympus",
          uuid: "2d45589a8dfa4d2d9d76bc4f0e3b25a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/2d45589a8dfa4d2d9d76bc4f0e3b25a9.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6814,
          name: "Fu Chi",
          uuid: "c9053ebc4ed52c2026fa436e5b44d6c1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/c9053ebc4ed52c2026fa436e5b44d6c1.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6815,
          name: "Fucanglong",
          uuid: "21a787cc1aca91e441fcf0f62c3bd780",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/21a787cc1aca91e441fcf0f62c3bd780.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6858,
          name: "Ghana Win",
          uuid: "fa4f7ff2bb6a42d6a4b834ce14bed92c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/fa4f7ff2bb6a42d6a4b834ce14bed92c.jpeg",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6872,
          name: "Goblins: Gluttony of Gems",
          uuid: "5cb961ba1e03404a9db89c155d07057d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/5cb961ba1e03404a9db89c155d07057d.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6818,
          name: "Gods of Nature",
          uuid: "743f141f291d9ce0076610ec662c835b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/743f141f291d9ce0076610ec662c835b.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6819,
          name: "Goldbeard",
          uuid: "e125608dfe75bd766347bd3b3444426d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/e125608dfe75bd766347bd3b3444426d.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6816,
          name: "Heavenly Treasure",
          uuid: "323c4028e5021c5636d9fba251a09133",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/323c4028e5021c5636d9fba251a09133.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6817,
          name: "High Fashion",
          uuid: "208d7a2f1e6c211d63a0511ca8139394",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/208d7a2f1e6c211d63a0511ca8139394.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6820,
          name: "I, Zombie",
          uuid: "79d13121efe7e7deec61eaa569bea9bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/79d13121efe7e7deec61eaa569bea9bf.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6859,
          name: "Ibiza Hotpots",
          uuid: "4f3a333a95e04311b4edc2736d2cc2ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/4f3a333a95e04311b4edc2736d2cc2ec.jpeg",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6881,
          name: "Jackpot Burn",
          uuid: "46a90bae9dc04fc8a3476f798d6ca49d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/46a90bae9dc04fc8a3476f798d6ca49d.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6882,
          name: "Legendary Luck",
          uuid: "83f0bf2e4682495db41fa8e30e204402",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/83f0bf2e4682495db41fa8e30e204402.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6857,
          name: "Lovable Pets",
          uuid: "4544f4fc92644505b43f01b96d8f6cd6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/4544f4fc92644505b43f01b96d8f6cd6.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6821,
          name: "Lucha Libre 2",
          uuid: "9aa390813140c7cb79562affe80f9150",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/9aa390813140c7cb79562affe80f9150.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6822,
          name: "Lucky 6",
          uuid: "76a9883c588541999c61108caa3d332d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/76a9883c588541999c61108caa3d332d.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6888,
          name: "Lucky 7",
          uuid: "8f03fea2875b60da1ee520778ed33c3e02963bda",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/8f03fea2875b60da1ee520778ed33c3e02963bda.png",
          type: "table",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6823,
          name: "Lucky Rat",
          uuid: "9186c827708b899416b530e9d590cf28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/9186c827708b899416b530e9d590cf28.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6867,
          name: "Medieval Crusades",
          uuid: "7907396d56be49103cc31c098e5dc7a8038e428e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/7907396d56be49103cc31c098e5dc7a8038e428e.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6896,
          name: "Merlin's Riches",
          uuid: "775d2dbad92b5c4e62b5911a3bbfc941ad4b6d41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/775d2dbad92b5c4e62b5911a3bbfc941ad4b6d41.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6883,
          name: "Mermaid Royale",
          uuid: "9ebc1c17c4434551981a7174f9d2c410",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/9ebc1c17c4434551981a7174f9d2c410.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6824,
          name: "Mermaid's Pearls",
          uuid: "2beb111f459de390ff4885e9d90fbd84",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/2beb111f459de390ff4885e9d90fbd84.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6862,
          name: "Monarch Mayhem",
          uuid: "9eaaeadd8ad741c3970a32d41e4a835f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/9eaaeadd8ad741c3970a32d41e4a835f.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6825,
          name: "Naughty Or Nice III",
          uuid: "704adaaafaa8101bbc749708280d254c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/704adaaafaa8101bbc749708280d254c.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6871,
          name: "Neon Wheel 7s",
          uuid: "b8d069e9107a481ab2bfa751c16d2f2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/b8d069e9107a481ab2bfa751c16d2f2d.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6877,
          name: "Nine Realms",
          uuid: "0d3f7a77bdf0a2938881665372dc814d5b3d9c3c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/0d3f7a77bdf0a2938881665372dc814d5b3d9c3c.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6826,
          name: "Nova 7s",
          uuid: "0882f19c3ddc5f88e7a020720df4eae1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/0882f19c3ddc5f88e7a020720df4eae1.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6828,
          name: "Panda Magic",
          uuid: "a4cb71033dd3fde229ae0464344bcca0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/a4cb71033dd3fde229ae0464344bcca0.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6827,
          name: "Panda's Gold",
          uuid: "bbad8caf48aa275a0cce657cc3794aeb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/bbad8caf48aa275a0cce657cc3794aeb.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6884,
          name: "Persian Treasures",
          uuid: "7f4c1b3d723b47ca95e1cc5b751b47f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/7f4c1b3d723b47ca95e1cc5b751b47f4.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6885,
          name: "Pharaoh Mysteries",
          uuid: "86e8f3fb872e437ab387e589b55a2ba0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/86e8f3fb872e437ab387e589b55a2ba0.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6869,
          name: "Pick ‘Em Poker",
          uuid: "ba8d07a40fa14bec92d013f24fefec47",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/ba8d07a40fa14bec92d013f24fefec47.png",
          type: "poker",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6829,
          name: "Pig Winner",
          uuid: "8511b41aebed49c56fed6d9694e47f04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/8511b41aebed49c56fed6d9694e47f04.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6831,
          name: "Plentiful Treasure",
          uuid: "f7e2626efdfa16ca99f6d5d94b3202c1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/f7e2626efdfa16ca99f6d5d94b3202c1.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6830,
          name: "Popiñata",
          uuid: "c2a8ebb78ed0e6094c918c95aab19d73",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/c2a8ebb78ed0e6094c918c95aab19d73.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6878,
          name: "Prosperity Pots: Lucky Coins",
          uuid: "7f7cb315a6893d828c6e26c03b2f728164181672",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/7f7cb315a6893d828c6e26c03b2f728164181672.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6832,
          name: "Purrfect Pets",
          uuid: "39e92666c463f2c84ab925521f818081",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/39e92666c463f2c84ab925521f818081.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6868,
          name: "Pyramid Pets",
          uuid: "a45d83956bcf4b0c8b31ae2df088bb30",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/a45d83956bcf4b0c8b31ae2df088bb30.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6856,
          name: "Rave Riches",
          uuid: "d34ac9e8e5a348f9ba9519ec89f077c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/d34ac9e8e5a348f9ba9519ec89f077c2.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6892,
          name: "Rich Harvest",
          uuid: "00d6ae803867424d912b385f5d942c5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/00d6ae803867424d912b385f5d942c5d.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6833,
          name: "Ritchie Valens™ La Bamba",
          uuid: "51bf0ca019f3c81bb3d9ee18274393a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/51bf0ca019f3c81bb3d9ee18274393a9.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6834,
          name: "RTG 777",
          uuid: "562bc5a5229605727022e62734a6b9aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/562bc5a5229605727022e62734a6b9aa.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6835,
          name: "Samba Sunset",
          uuid: "55d21b29745b8d764235cf0d8cd2968e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/55d21b29745b8d764235cf0d8cd2968e.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6903,
          name: "Scroll of Sakura",
          uuid: "0b8f287be972e668a98ca70d40869f2b19dc10ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/0b8f287be972e668a98ca70d40869f2b19dc10ce.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6836,
          name: "Scuba Fishing",
          uuid: "5eaed12ac553c6bb926d33d80f9d68c7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/5eaed12ac553c6bb926d33d80f9d68c7.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6889,
          name: "Seahorse Surge",
          uuid: "1ff1c1612ded694b121724aa0ebb2f18a3515d2b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/1ff1c1612ded694b121724aa0ebb2f18a3515d2b.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6837,
          name: "Secret Jungle",
          uuid: "fcd61b03f5f480a2716ae6590908f3b6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/fcd61b03f5f480a2716ae6590908f3b6.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6838,
          name: "Secret Symbol",
          uuid: "7037e2cb75f9adb75f1a4fd347a9a49e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/7037e2cb75f9adb75f1a4fd347a9a49e.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6839,
          name: "Shadow Gods",
          uuid: "07484a48a494854a4fb4e25036a1205e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/07484a48a494854a4fb4e25036a1205e.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6840,
          name: "Shanghai Lights",
          uuid: "a8fd06fbf1b18a3a0db0a6950d3eebda",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/a8fd06fbf1b18a3a0db0a6950d3eebda.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6861,
          name: "Shelltastic Wins",
          uuid: "d3651afe2850459c9a9b08c30639940e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/d3651afe2850459c9a9b08c30639940e.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6865,
          name: "Space Paws",
          uuid: "a4c7a9cf1952b9e238daadced6c3926ae78a494c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/a4c7a9cf1952b9e238daadced6c3926ae78a494c.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6841,
          name: "Stardust",
          uuid: "98cd3bff6c555f1b24871653d64ef7bd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/98cd3bff6c555f1b24871653d64ef7bd.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6842,
          name: "Storm Lords",
          uuid: "b8eb4acc8c47fc5959a86f7869461ea4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/b8eb4acc8c47fc5959a86f7869461ea4.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6843,
          name: "Sweet 16",
          uuid: "7473ec205863d662bfa9e932f33df8f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/7473ec205863d662bfa9e932f33df8f4.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6886,
          name: "Sweet 16 Blast!",
          uuid: "a828c7577e7e4c208b4bd6043ae67654",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/a828c7577e7e4c208b4bd6043ae67654.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6876,
          name: "Sweet Shop Collect",
          uuid: "10c6a3effa744b4db78eaaf8c41b7f6e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/10c6a3effa744b4db78eaaf8c41b7f6e.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6864,
          name: "Sword of the Elements",
          uuid: "8e7befb6ca57df617c1359aded3b0e0bbee13da2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/8e7befb6ca57df617c1359aded3b0e0bbee13da2.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6850,
          name: "T-REX 2",
          uuid: "5ad546a85b27b37793141e98f888e331",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/5ad546a85b27b37793141e98f888e331.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6898,
          name: "Teen Patti",
          uuid: "7236542f3d9c4d9d22409012819608c3542d77e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/7236542f3d9c4d9d22409012819608c3542d77e1.png",
          type: "table",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6844,
          name: "Texan Tycoon",
          uuid: "e0e71647e3f3718c7620ff47e296b375",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/e0e71647e3f3718c7620ff47e296b375.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6866,
          name: "Thai Emerald",
          uuid: "7fa422525de0f97f2670abbdc263ee64df93020d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/7fa422525de0f97f2670abbdc263ee64df93020d.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6845,
          name: "The Big Bopper®",
          uuid: "8ae4636f4c2b57db6d4f9cdd319d55b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/8ae4636f4c2b57db6d4f9cdd319d55b2.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6846,
          name: "The Mariachi 5",
          uuid: "eedbbca1c3cf573dacae786542aacdce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/eedbbca1c3cf573dacae786542aacdce.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6847,
          name: "Three Kingdom Wars",
          uuid: "85f64350711000da99b299d1b2f55963",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/85f64350711000da99b299d1b2f55963.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6875,
          name: "Toltec Gems",
          uuid: "3f4c32243e6e46358769e3c8703a8542",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/3f4c32243e6e46358769e3c8703a8542.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6848,
          name: "Trigger Happy",
          uuid: "c2733a8a5ed4e359be8c7d38a5345bcb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/c2733a8a5ed4e359be8c7d38a5345bcb.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6855,
          name: "Triple Tigers",
          uuid: "58447f8ff9994e9586b8d36a242dca28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/58447f8ff9994e9586b8d36a242dca28.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6849,
          name: "Triple Twister",
          uuid: "7d8b93be921fb3419759bb3135d02807",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/7d8b93be921fb3419759bb3135d02807.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6891,
          name: "Vegas XL",
          uuid: "02c4cba0a0f9402b9a7e920925c0e419",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/02c4cba0a0f9402b9a7e920925c0e419.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6873,
          name: "Warrior Conquest",
          uuid: "9f1f4a7b24b144bc905a1166b0912bcb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/9f1f4a7b24b144bc905a1166b0912bcb.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6887,
          name: "Wild Hog Luau",
          uuid: "8157a9253c604d8b897c641db9296b96",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/8157a9253c604d8b897c641db9296b96.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6851,
          name: "Witch's Brew",
          uuid: "52e6e96e5cb2af3a48dd9869b8bcca95",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/52e6e96e5cb2af3a48dd9869b8bcca95.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6852,
          name: "Wu Zetian",
          uuid: "0efe388e6d4764f7f841724327ddc2d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/RTG SLOTS/0efe388e6d4764f7f841724327ddc2d2.png",
          type: "slots",
          provider: "RTG SLOTS",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 144,
      name: "RedTiger",
      providerId: 10,
      providerName: "RedTiger",
      games: [
        {
          game_id: 435,
          name: "1942: Sky Warrior",
          uuid: "ba3ed943720c4e091ac3b499440599183c3217b2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ba3ed943720c4e091ac3b499440599183c3217b2.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 90,
          name: "24 Hour Grand Prix",
          uuid: "5c6b0f3bfbb044e91e5d02692580c47fbe4b8a57",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5c6b0f3bfbb044e91e5d02692580c47fbe4b8a57.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 503,
          name: "4Squad",
          uuid: "67f99dbbbb5b737080d1e91181f80fc4b18396fa",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/67f99dbbbb5b737080d1e91181f80fc4b18396fa.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 144,
          name: "777 Strike",
          uuid: "3edfd52d8ae619b31bc1f013e3dcf5b06a119ca4",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3edfd52d8ae619b31bc1f013e3dcf5b06a119ca4.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 502,
          name: "Ancient Script",
          uuid: "e6554e086ab6841c0381441a41e0a0c4a393ac95",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e6554e086ab6841c0381441a41e0a0c4a393ac95.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 501,
          name: "Blue Diamond",
          uuid: "957ec00871067f51bb5402d3ff6e6f15d349a614",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/957ec00871067f51bb5402d3ff6e6f15d349a614.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 500,
          name: "Bounty Raid",
          uuid: "cb739dc343ef75ca8a11188de7f9c363c8b1d800",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/cb739dc343ef75ca8a11188de7f9c363c8b1d800.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 499,
          name: "Cash Volt",
          uuid: "afd2c00486bfe31f42e8b5618bfea0c28b32ea5a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/afd2c00486bfe31f42e8b5618bfea0c28b32ea5a.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 498,
          name: "Chinese Treasures",
          uuid: "a1ace081acbaf3d21a5d4c119fc02b1c81b6f857",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a1ace081acbaf3d21a5d4c119fc02b1c81b6f857.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 497,
          name: "Cirque De La Fortune",
          uuid: "ce6dc1c713b6177c235d32f4eb7acb9d9bdc7a6d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ce6dc1c713b6177c235d32f4eb7acb9d9bdc7a6d.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 496,
          name: "Clash of the Beasts",
          uuid: "7b6bc04ae824f76e03488bd94979a204550744a6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7b6bc04ae824f76e03488bd94979a204550744a6.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 495,
          name: "Crazy Genie",
          uuid: "f04bf72da649a546ffcfe46b57dfc45077f78631",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f04bf72da649a546ffcfe46b57dfc45077f78631.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 494,
          name: "Crystal Mirror",
          uuid: "006e48551793416035255d458a62c3997b2862aa",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/006e48551793416035255d458a62c3997b2862aa.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 493,
          name: "Devil`s Number",
          uuid: "2244918849b00bf1067d225fc8a818a18a114ea6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2244918849b00bf1067d225fc8a818a18a114ea6.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 492,
          name: "Dice Dice Dice",
          uuid: "2a069bc0465b310af608836102000833a751837f",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2a069bc0465b310af608836102000833a751837f.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 491,
          name: "Dragon King: Legend of the Seas",
          uuid: "492eb84a7c7d7709a6dc0db727aa09924a1591c1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/492eb84a7c7d7709a6dc0db727aa09924a1591c1.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 490,
          name: "Dragon`s Luck",
          uuid: "0d68c0cae26977c073851cefc69134b6eabac1b3",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0d68c0cae26977c073851cefc69134b6eabac1b3.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 489,
          name: "Dragon`s Luck MegaWays",
          uuid: "56db2446bf6023760d0ffa78d9c8e610e7f580ca",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/56db2446bf6023760d0ffa78d9c8e610e7f580ca.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 488,
          name: "Elven Magic",
          uuid: "580fbd46d6914002410d0a8c50b74cafa68e9468",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/580fbd46d6914002410d0a8c50b74cafa68e9468.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 487,
          name: "Epic Journey",
          uuid: "ce7c8bd91277e3e933a2f5aed2dbb2186a27e645",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ce7c8bd91277e3e933a2f5aed2dbb2186a27e645.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 486,
          name: "Esqueleto Mariachi",
          uuid: "f7a4a0723a5fd004ad14222039cf47a375baf2db",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f7a4a0723a5fd004ad14222039cf47a375baf2db.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 485,
          name: "Five Star",
          uuid: "5da3c19280210a757773da89e90d0626d84e1439",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5da3c19280210a757773da89e90d0626d84e1439.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 484,
          name: "Five Star Power Reels",
          uuid: "a0aa2c6796f98e7aad61d0c6e4936b7a3c9e1b36",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a0aa2c6796f98e7aad61d0c6e4936b7a3c9e1b36.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 482,
          name: "Flaming Fox",
          uuid: "1f78a08ca60ab11581069bfaae7578d92b08fb20",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1f78a08ca60ab11581069bfaae7578d92b08fb20.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 143,
          name: "Forever 7`s",
          uuid: "d2664beec6ab3644aec3a4c38d9699cc62e52dc7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d2664beec6ab3644aec3a4c38d9699cc62e52dc7.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 481,
          name: "Fortune Charm",
          uuid: "bb72bee89dd53b0cd31f92750d9ad93c432e745a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bb72bee89dd53b0cd31f92750d9ad93c432e745a.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 480,
          name: "Fortune Fest",
          uuid: "1019cc60d36e74a5061b19ff55eeb6fdfa071ea1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1019cc60d36e74a5061b19ff55eeb6fdfa071ea1.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 479,
          name: "Fortune House",
          uuid: "d574b68a8ea94b8880080c72a4d7d88772bc2532",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d574b68a8ea94b8880080c72a4d7d88772bc2532.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 478,
          name: "Fortune House Power Reels",
          uuid: "074a405973345a7a5cfd1a8d80d0d4d0d1b45ca7",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/074a405973345a7a5cfd1a8d80d0d4d0d1b45ca7.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 483,
          name: "Fruit Blox",
          uuid: "a674f8eccab8d18cb05f852ed92fb117adb60d5d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a674f8eccab8d18cb05f852ed92fb117adb60d5d.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 477,
          name: "Gems Gone Wild",
          uuid: "1a11b1a6dd552c1bbd1dd130b45bbad2c5355309",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1a11b1a6dd552c1bbd1dd130b45bbad2c5355309.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 476,
          name: "Gemtastic",
          uuid: "51d7792c5cac30c05a5d5aa7f5078b143b665e8e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/51d7792c5cac30c05a5d5aa7f5078b143b665e8e.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 475,
          name: "God of Wealth",
          uuid: "c1fea7ff487523ecde117c71d09d9acddc2266cc",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c1fea7ff487523ecde117c71d09d9acddc2266cc.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 474,
          name: "Golden Cryptex",
          uuid: "b7d7e2d1afa2c52504994fb1c1f58efca1aa0182",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b7d7e2d1afa2c52504994fb1c1f58efca1aa0182.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 473,
          name: "Golden Leprechaun MegaWays",
          uuid: "7a980914ee6315a0bd45cb54aef14eac4ba100d5",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/7a980914ee6315a0bd45cb54aef14eac4ba100d5.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 472,
          name: "Gonzo's Quest Megaways",
          uuid: "9d63bb9b0987ba95855edd574732743c80fbdca6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9d63bb9b0987ba95855edd574732743c80fbdca6.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 471,
          name: "Grand Wheel",
          uuid: "51c660869d7c8e6802d43b246ce596a6b54eb0ec",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/51c660869d7c8e6802d43b246ce596a6b54eb0ec.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 470,
          name: "Hammer Gods",
          uuid: "d40edc6b81309732b81fa2a24c451795bc345c8d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d40edc6b81309732b81fa2a24c451795bc345c8d.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 469,
          name: "Hot Hot Chilli Pot",
          uuid: "b63cbae78c9fdc55f780718c9bbcceaedb3a8d8e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b63cbae78c9fdc55f780718c9bbcceaedb3a8d8e.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 468,
          name: "Imperial Palace",
          uuid: "9824322c03b25598470f700411919fc040757c7e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9824322c03b25598470f700411919fc040757c7e.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 467,
          name: "Jackpot Quest",
          uuid: "f01f52a048fe2b2977690829d84ea04d57a2c71e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/f01f52a048fe2b2977690829d84ea04d57a2c71e.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 466,
          name: "Jade Charms",
          uuid: "c5f8b679280a8736405edec522e489413a56b151",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c5f8b679280a8736405edec522e489413a56b151.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 465,
          name: "Jester Spins",
          uuid: "2184a9a052437ad423ee19c3313ae0e0bc01f914",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2184a9a052437ad423ee19c3313ae0e0bc01f914.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 103,
          name: "Jingle Bells",
          uuid: "aa529e705a93fbf047339e9f1e8f59fa854d386c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/aa529e705a93fbf047339e9f1e8f59fa854d386c.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 102,
          name: "Jingle Ways Megaways",
          uuid: "78134d9d9b37ae65fcd17986b27b42c0823dcbc1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/78134d9d9b37ae65fcd17986b27b42c0823dcbc1.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 464,
          name: "Joe Exotic",
          uuid: "3dcdf835ebd8f14d40d5ab43177268f4f7fca7f1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3dcdf835ebd8f14d40d5ab43177268f4f7fca7f1.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 463,
          name: "Laser Fruit",
          uuid: "3d0519ee71e77eb65b917046024c60e49e137eac",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3d0519ee71e77eb65b917046024c60e49e137eac.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 461,
          name: "Legend of Athena",
          uuid: "83a404ff950e80a23a6e5ba412bb1f1815c9442a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/83a404ff950e80a23a6e5ba412bb1f1815c9442a.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 462,
          name: "Legendary Excalibur",
          uuid: "857f2b7646c42f85c5378a6e4741fffbb4b2cfdd",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/857f2b7646c42f85c5378a6e4741fffbb4b2cfdd.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 460,
          name: "Lion Dance",
          uuid: "ff3b2b771d6731dead14a38f6a025f9ef60fb997",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ff3b2b771d6731dead14a38f6a025f9ef60fb997.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 459,
          name: "Lucky Easter",
          uuid: "0bed2ef8512c3ecc415bd736cf5b4905b6b7b442",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0bed2ef8512c3ecc415bd736cf5b4905b6b7b442.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 458,
          name: "Lucky Wizard",
          uuid: "229d983a6cf13463ebea4617ca98fd8b50afa33c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/229d983a6cf13463ebea4617ca98fd8b50afa33c.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 457,
          name: "Masquerade",
          uuid: "6185b94163bf2089de54457a28f3fef992a56e71",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6185b94163bf2089de54457a28f3fef992a56e71.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 456,
          name: "Mayan Gods",
          uuid: "d08c8492919d302cf098d5fe4ffb98094270480a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d08c8492919d302cf098d5fe4ffb98094270480a.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 455,
          name: "Mega Dragon",
          uuid: "25602411cb433c2e616c79e591493be311be460b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/25602411cb433c2e616c79e591493be311be460b.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 454,
          name: "Mystery Reels Power Reels",
          uuid: "ecaabedb1c6a5ec5a2057b21ee3c04b57c606e1a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/ecaabedb1c6a5ec5a2057b21ee3c04b57c606e1a.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 453,
          name: "Mystic Wheel",
          uuid: "1c0e9f629fc02657c6a5a162906955e11de79f3e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1c0e9f629fc02657c6a5a162906955e11de79f3e.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 452,
          name: "Neon Links",
          uuid: "5c15e09bf8d0e3a67b17c555d3665d69f51ecd34",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5c15e09bf8d0e3a67b17c555d3665d69f51ecd34.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 122,
          name: "NFT Megaways",
          uuid: "d1a8dcec130abf14a67c3f7569013c22a2958203",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d1a8dcec130abf14a67c3f7569013c22a2958203.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 451,
          name: "Ninja Ways",
          uuid: "5cd38539241ac3af6eb72c52deaa4a5aec1f998c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/5cd38539241ac3af6eb72c52deaa4a5aec1f998c.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 450,
          name: "Ocean Fortune",
          uuid: "e32ab6e48906a5e420692c4f434cd35d3f236659",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e32ab6e48906a5e420692c4f434cd35d3f236659.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 449,
          name: "Path of Destiny",
          uuid: "27a9a0d2d1b4ca181364e1bd284b2cd29f71c98d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/27a9a0d2d1b4ca181364e1bd284b2cd29f71c98d.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 448,
          name: "Persian Fortune",
          uuid: "92f0018e43d55d4d7c7c0e36a1234c708c5efbb1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/92f0018e43d55d4d7c7c0e36a1234c708c5efbb1.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 447,
          name: "Phoenix Fire Power Reels",
          uuid: "4d98ef363b360565d2345948d32bbeb34f9c8907",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4d98ef363b360565d2345948d32bbeb34f9c8907.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 446,
          name: "Piggy Riches Megaways",
          uuid: "441a38bd86d32d607892c00a6f07b3fd7dbe36c1",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/441a38bd86d32d607892c00a6f07b3fd7dbe36c1.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 445,
          name: "Puss`n Boots",
          uuid: "2273fd5a37302d6ec9855accdaa7a2a7fcfcc982",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2273fd5a37302d6ec9855accdaa7a2a7fcfcc982.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 444,
          name: "Rainbow Jackpots",
          uuid: "4b2aaef99ae67781d8d4f71edbdd7f0f6ac861fa",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4b2aaef99ae67781d8d4f71edbdd7f0f6ac861fa.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 443,
          name: "Rainbow Jackpots Power Lines",
          uuid: "a8ed17436d2aa1e0822f4b06d50e37df09ed15be",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/a8ed17436d2aa1e0822f4b06d50e37df09ed15be.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 442,
          name: "Reactor",
          uuid: "2a3ea8a99b3f41da87148b794911447fc136425d",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/2a3ea8a99b3f41da87148b794911447fc136425d.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 441,
          name: "Reel King Mega",
          uuid: "0e4f378b495b32060493bea2a7159ff1437a33ef",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0e4f378b495b32060493bea2a7159ff1437a33ef.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 440,
          name: "Regal Beasts",
          uuid: "1c85ae48a4a87c1a992390eb7277bf0f13311047",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1c85ae48a4a87c1a992390eb7277bf0f13311047.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 439,
          name: "Regal Streak",
          uuid: "aaf6b86c957033adc7b1996fcefcf1cdff5ef5a6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/aaf6b86c957033adc7b1996fcefcf1cdff5ef5a6.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 438,
          name: "Robin Hoods Wild Forest",
          uuid: "092347635031162ef2ff2794c791aa5951bafb58",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/092347635031162ef2ff2794c791aa5951bafb58.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 437,
          name: "Rocket Men",
          uuid: "d2fdd40071b683470b67059d244b699b8815f3ff",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d2fdd40071b683470b67059d244b699b8815f3ff.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 436,
          name: "Shah Mat",
          uuid: "9788b3ec8c5bd28ad1f2a1d7dc6c5ea72b513c87",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9788b3ec8c5bd28ad1f2a1d7dc6c5ea72b513c87.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 434,
          name: "Snow Wild and the 7 Features",
          uuid: "4aa7c925524266bbd7174d1c063e30f1d984146c",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4aa7c925524266bbd7174d1c063e30f1d984146c.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 433,
          name: "Ten Elements",
          uuid: "0bf17cff663517ca5e7b6276655870dd2c1c4889",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/0bf17cff663517ca5e7b6276655870dd2c1c4889.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 432,
          name: "The Equalizer",
          uuid: "6669322f3e5ba6c730066d6b165c74564b91d9b2",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6669322f3e5ba6c730066d6b165c74564b91d9b2.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 431,
          name: "The Greatest Train Robbery",
          uuid: "b7682e0a41515c604ef912122511dcd7f5b005e6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/b7682e0a41515c604ef912122511dcd7f5b005e6.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 430,
          name: "The Wild Hatter",
          uuid: "9d482dc5ee659d1ba8c56d021b550c5ceaac6293",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/9d482dc5ee659d1ba8c56d021b550c5ceaac6293.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 429,
          name: "Thor`s Vengeance",
          uuid: "13de706e4824ad72ce03aa56ac9346efc9c51e66",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/13de706e4824ad72ce03aa56ac9346efc9c51e66.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 428,
          name: "Totem Lightning Power Reels",
          uuid: "3ac5164d8f8b233e09f47fa18b9a31eeccd592ed",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/3ac5164d8f8b233e09f47fa18b9a31eeccd592ed.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 427,
          name: "Treasure Mine",
          uuid: "c46af1ece7aaa6b54ae0356b2fcc16d11a982702",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c46af1ece7aaa6b54ae0356b2fcc16d11a982702.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 426,
          name: "Treasure Mine Power Reels",
          uuid: "e737c9e598f386f3dfc0eb47964b4111e61f3f7b",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e737c9e598f386f3dfc0eb47964b4111e61f3f7b.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 425,
          name: "Vault of Anubis",
          uuid: "1c3df5dd9b90565d917cd697bcdf3e4740a22807",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/1c3df5dd9b90565d917cd697bcdf3e4740a22807.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 424,
          name: "Vicky Ventura",
          uuid: "54040482886248b7869410dc28df9e5f300d4768",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/54040482886248b7869410dc28df9e5f300d4768.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 423,
          name: "War Of Gods",
          uuid: "e436e2d3ebdce3b540766429ae916c4ff5ad72ac",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e436e2d3ebdce3b540766429ae916c4ff5ad72ac.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 422,
          name: "What the Fox Megaways",
          uuid: "6c5271344d2e4a9ee58fbe57af5512ae68a4c50a",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/6c5271344d2e4a9ee58fbe57af5512ae68a4c50a.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 421,
          name: "Wild Cats Multiline",
          uuid: "c551038bcf276b5abfcbc556f4d9cd48f2a4011e",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/c551038bcf276b5abfcbc556f4d9cd48f2a4011e.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 420,
          name: "Wild Fight",
          uuid: "eeb9c4581da2045dcf92d69da0b1318bf49a7107",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/eeb9c4581da2045dcf92d69da0b1318bf49a7107.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 419,
          name: "Wild Nords",
          uuid: "e9ce1c65095e3ba2e874f8aa982a0e4c802c8530",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/e9ce1c65095e3ba2e874f8aa982a0e4c802c8530.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 418,
          name: "Wild Wild Chest",
          uuid: "bcf58aefa21ad915299f8ea2b9d14a9f460688a9",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/bcf58aefa21ad915299f8ea2b9d14a9f460688a9.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 417,
          name: "Wings of Ra",
          uuid: "d176af54d0beab309f1ed24d49159531a945e5f6",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/d176af54d0beab309f1ed24d49159531a945e5f6.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 416,
          name: "Winter Wonders",
          uuid: "508f8d27e321343c948d3ec711f4efb0b0983e32",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/508f8d27e321343c948d3ec711f4efb0b0983e32.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 415,
          name: "Yucatan`s Mystery",
          uuid: "4653361ae3783b0e65252a18ae58d84c67c55316",
          source: "win2power:0",
          image: "https://r.gamesstatic.com/games/4653361ae3783b0e65252a18ae58d84c67c55316.png",
          type: "slots",
          provider: "RedTiger",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 145,
      name: "Reevo",
      providerId: 55,
      providerName: "Reevo",
      games: [
        {
          game_id: 6942,
          name: "Altar de Muertos",
          uuid: "cad9aba7379c49d4a46d0bb90504abab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/cad9aba7379c49d4a46d0bb90504abab.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6960,
          name: "ASTRO REELS",
          uuid: "b1f1d4e851b8773d169bde77a8a8f9394ad62925",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/b1f1d4e851b8773d169bde77a8a8f9394ad62925.jpg",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6910,
          name: "Baccarat",
          uuid: "dbbd23e5cf6c46789e2eefff084655c2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/dbbd23e5cf6c46789e2eefff084655c2.png",
          type: "table",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6915,
          name: "Blackjack",
          uuid: "4eb87ee873df45bd9dfbf3f6e1db65f5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/4eb87ee873df45bd9dfbf3f6e1db65f5.png",
          type: "table",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6935,
          name: "Blue Beard’s Chest",
          uuid: "ccf8f372287b4a05a0ec1beff8a85ccd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/ccf8f372287b4a05a0ec1beff8a85ccd.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6959,
          name: "Bullfighting Champion",
          uuid: "6097dc807c8d47a29c32bf9ba8c514b0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/6097dc807c8d47a29c32bf9ba8c514b0.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6911,
          name: "Caribbean Stud Poker",
          uuid: "6867ec22d954487188c1df6dbcd64c30",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/6867ec22d954487188c1df6dbcd64c30.png",
          type: "table",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6917,
          name: "Casino Chic VIP",
          uuid: "d7e6d2ea1a1fe409b1d342f7757beb7598fa1bd2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/d7e6d2ea1a1fe409b1d342f7757beb7598fa1bd2.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6961,
          name: "CHARM & DATE",
          uuid: "499c8a05e3ea4bf4a9fc202d747c679f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/499c8a05e3ea4bf4a9fc202d747c679f.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6945,
          name: "Chubby Santa",
          uuid: "f9b4691d3ceb1afaef8ab9b8563444c41494e39c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/f9b4691d3ceb1afaef8ab9b8563444c41494e39c.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6925,
          name: "Crazy Crocs",
          uuid: "8d1e34d3618c42c1855334b1b18b3ee7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/8d1e34d3618c42c1855334b1b18b3ee7.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6940,
          name: "Crown Heist",
          uuid: "b45301ebb7524556a8b1248b3d69f1d9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/b45301ebb7524556a8b1248b3d69f1d9.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6908,
          name: "Diamond Luck",
          uuid: "fda1c4a26c254b9ab9cf0ed9314ceae9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/fda1c4a26c254b9ab9cf0ed9314ceae9.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6941,
          name: "Dracula’s Bloody Reels",
          uuid: "b844b086cbcc5e9363ee628588e7727782d27c88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/b844b086cbcc5e9363ee628588e7727782d27c88.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6928,
          name: "Eggs With Legs",
          uuid: "b280145458f64993b9ab8d7b986f5dba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/b280145458f64993b9ab8d7b986f5dba.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6958,
          name: "Electro Goals",
          uuid: "3c2e6898528f44e8af047f366e878b32",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/3c2e6898528f44e8af047f366e878b32.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6912,
          name: "Electro Reels",
          uuid: "9421fca77f2545988172144e703c54b4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/9421fca77f2545988172144e703c54b4.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6964,
          name: "Electro Reels Power Portal",
          uuid: "9d8593218bd84c179a97397336880d1d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/9d8593218bd84c179a97397336880d1d.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6916,
          name: "European Roulette",
          uuid: "306389b5be3b44a1b8ca20d018ebb42e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/306389b5be3b44a1b8ca20d018ebb42e.png",
          type: "table",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6948,
          name: "Expanding Naughty Cupid",
          uuid: "5586299f52e34a04a633386119a6179b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/5586299f52e34a04a633386119a6179b.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6950,
          name: "Expanding Wild Leprechaun",
          uuid: "215abadded6f7a4bd12bd729185653a142051f95",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/215abadded6f7a4bd12bd729185653a142051f95.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6921,
          name: "Football Ultimate Champions' King",
          uuid: "04b1f9d8c94643b960be8ead4deaa00b01538ae3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/04b1f9d8c94643b960be8ead4deaa00b01538ae3.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6954,
          name: "Four Celestial Creatures",
          uuid: "9e97365e982445efb292ba75f9ed9bfa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/9e97365e982445efb292ba75f9ed9bfa.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6957,
          name: "Goals & Super Wins",
          uuid: "877c72f605bb44f89569875f12ba57d8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/877c72f605bb44f89569875f12ba57d8.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6914,
          name: "Gold Rush Shooter",
          uuid: "f6460e0669624d4fadf7ede8609bf0b1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/f6460e0669624d4fadf7ede8609bf0b1.png",
          type: "other",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6939,
          name: "Heidi, Hilde and Helga Go Wild",
          uuid: "66c5c27651e34a7fa6dc02cf30311bd8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/66c5c27651e34a7fa6dc02cf30311bd8.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6920,
          name: "Hell O'Win",
          uuid: "3ae16cb17ae9b11ca8e858791a77f2e545bf4c98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/3ae16cb17ae9b11ca8e858791a77f2e545bf4c98.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6963,
          name: "Hook the Gold",
          uuid: "f6feccd898ac46c0a0d90c604f7cc05a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/f6feccd898ac46c0a0d90c604f7cc05a.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6944,
          name: "Joker’s Super Wins",
          uuid: "a0fbdb3b97763608fcbbd69f982842e813d3c3e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/a0fbdb3b97763608fcbbd69f982842e813d3c3e9.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6932,
          name: "Le Bo$$u: Richesse Royale",
          uuid: "2df4fa1977a34ed38d72c97206ef1c53",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/2df4fa1977a34ed38d72c97206ef1c53.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6952,
          name: "Lucky Comanche",
          uuid: "8080afffe94b40e3bdcfcc5f1c4b7e79",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/8080afffe94b40e3bdcfcc5f1c4b7e79.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6951,
          name: "Mackereels",
          uuid: "24171a0ef9c34c739cd6e210a7fdb9d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/24171a0ef9c34c739cd6e210a7fdb9d7.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6926,
          name: "Mardi Gras Reels",
          uuid: "0a85d441acc44029a557113901b3e822",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/0a85d441acc44029a557113901b3e822.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6930,
          name: "Mayan Magic Gold",
          uuid: "e0b9d58c728243cfb507e08b9308245a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/e0b9d58c728243cfb507e08b9308245a.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6943,
          name: "Miceketeers The Cheese Guardian",
          uuid: "3aae24aaf6e940ba97e77585218c7ea4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/3aae24aaf6e940ba97e77585218c7ea4.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6955,
          name: "Miceketeers: All for Cheese",
          uuid: "a6f52792c3b24fa5893368d0fc8571c2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/a6f52792c3b24fa5893368d0fc8571c2.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6918,
          name: "Moulin Reels",
          uuid: "fd2f063f254cecf379a8d53ebe6a1769e3e25463",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/fd2f063f254cecf379a8d53ebe6a1769e3e25463.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6947,
          name: "Naughty Cupid",
          uuid: "7c9999c07a7146108671b1e695d4f001",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/7c9999c07a7146108671b1e695d4f001.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6919,
          name: "OktoBeerFest",
          uuid: "58b24965eac94f4d7d119d98493c4d6b18719592",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/58b24965eac94f4d7d119d98493c4d6b18719592.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6906,
          name: "Parisian Dreams",
          uuid: "f665dbf7f6634adaa704f7ad27dfb4ee",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/f665dbf7f6634adaa704f7ad27dfb4ee.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6936,
          name: "Pokie Vegas",
          uuid: "22d2ad9669d348ecaa0d870672f4d166",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/22d2ad9669d348ecaa0d870672f4d166.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6953,
          name: "Rails & Riches",
          uuid: "51709f8e45ad4e60bbe71946b5c6a357",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/51709f8e45ad4e60bbe71946b5c6a357.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6938,
          name: "Rays of Ra",
          uuid: "9ebd0daa0e0773699d3c87f8e420a4c7612483cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/9ebd0daa0e0773699d3c87f8e420a4c7612483cc.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6946,
          name: "Ra’s Expansion",
          uuid: "b00b7f977ee14b269a2541d1329774a9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/b00b7f977ee14b269a2541d1329774a9.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6907,
          name: "Reel Fortune",
          uuid: "d89bbc6f489c4880bac8a48da1d2d964",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/d89bbc6f489c4880bac8a48da1d2d964.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6929,
          name: "Royal Bets",
          uuid: "ed4c91f836e2443dbf8bc8cee1c50413",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/ed4c91f836e2443dbf8bc8cee1c50413.jpg",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6937,
          name: "Ruby Treasures",
          uuid: "89e96b52db80fd1100ffd49fc1c0b0613c96166d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/89e96b52db80fd1100ffd49fc1c0b0613c96166d.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6923,
          name: "Santa’s Gifts",
          uuid: "8aea36e4086dd0a58905b451002f5adadc1e4344",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/8aea36e4086dd0a58905b451002f5adadc1e4344.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6905,
          name: "Snow King",
          uuid: "73562fd027cf4333aad2c724647973bb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/73562fd027cf4333aad2c724647973bb.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6904,
          name: "Spartan Shields",
          uuid: "5db178ae51aa4b74872f68c3953cba5e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/5db178ae51aa4b74872f68c3953cba5e.png",
          type: "table",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6933,
          name: "Spinnerix & Winnerix: The Magic Potion",
          uuid: "02138c5dff4cf1234aa461bdbc0c8ae6b5d866ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Reevo/02138c5dff4cf1234aa461bdbc0c8ae6b5d866ef.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6962,
          name: "Spinnerix & Winnerix: Wild Puppix",
          uuid: "a9f7763747fa422998c4854d99e09c06",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/a9f7763747fa422998c4854d99e09c06.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6927,
          name: "Submarine Riches",
          uuid: "2df1905686414dce88171cdab48b6da5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/2df1905686414dce88171cdab48b6da5.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6913,
          name: "Sugar Fruit Frenzy",
          uuid: "8cc0c3a942d648809efc51047dd5ded0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/8cc0c3a942d648809efc51047dd5ded0.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6956,
          name: "Super Goals & Beers",
          uuid: "ff604a8d17714ad681ade8f8ba9dae5f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/ff604a8d17714ad681ade8f8ba9dae5f.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6922,
          name: "Super Goals & Reels",
          uuid: "b46575e189e14f878825f0aaaf4a00fe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/b46575e189e14f878825f0aaaf4a00fe.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6965,
          name: "Super Hell O’Wins",
          uuid: "752d598ea3e14d32b9afc05b0b29e0e2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/752d598ea3e14d32b9afc05b0b29e0e2.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6931,
          name: "Treasures God",
          uuid: "9326192661a8482da942b1f836f3c4cd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/9326192661a8482da942b1f836f3c4cd.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6909,
          name: "Video Poker",
          uuid: "860ed6cd40a344fc87e84f02240140c8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/860ed6cd40a344fc87e84f02240140c8.png",
          type: "table",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6949,
          name: "Wild Leprechaun",
          uuid: "d9220b698f1042f287de510f0458cf63",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/d9220b698f1042f287de510f0458cf63.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6924,
          name: "Wolf Wild",
          uuid: "b75405c3947d4d9fa0dd7cb7d99b30c8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/b75405c3947d4d9fa0dd7cb7d99b30c8.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6934,
          name: "Yin Yang Twins",
          uuid: "6d884972b4fa46a2864a7024f5fdcf47",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Reevo/6d884972b4fa46a2864a7024f5fdcf47.png",
          type: "slots",
          provider: "Reevo",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 146,
      name: "RelaxGaming",
      providerId: 14,
      providerName: "RelaxGaming",
      games: [
        {
          game_id: 613,
          name: "1 2 3 BOOM!",
          uuid: "e0cfc16ad6584f8c847301f03bf52cd6",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/e0cfc16ad6584f8c847301f03bf52cd6.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 98,
          name: "Christmas Santa",
          uuid: "711ad1f2c27f424d88a9244a16ab1b56",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/711ad1f2c27f424d88a9244a16ab1b56.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 614,
          name: "Dead Man's Trail",
          uuid: "12927f94801a4d069258a1d364b4ac7b",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/12927f94801a4d069258a1d364b4ac7b.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 615,
          name: "Deep Descent",
          uuid: "320a976cc6b24be38a4f705eb955e111",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/320a976cc6b24be38a4f705eb955e111.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 616,
          name: "Dragons' Awakening",
          uuid: "8a9d8652a18241d89d88a453d5aa5fcf",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/8a9d8652a18241d89d88a453d5aa5fcf.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 617,
          name: "Epic Joker",
          uuid: "8cdeb1c280134ebf8e1f4549656b1191",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/8cdeb1c280134ebf8e1f4549656b1191.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 618,
          name: "Erik the Red",
          uuid: "54e635081b2e46068185aefb064474d8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/54e635081b2e46068185aefb064474d8.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 619,
          name: "Fruit Strike",
          uuid: "9cb77f35d0f3433dbd8c95dfe485f639",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/9cb77f35d0f3433dbd8c95dfe485f639.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 620,
          name: "Golden Gods",
          uuid: "bdb5290fe2d04c5596079430afb5ea71",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/bdb5290fe2d04c5596079430afb5ea71.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 621,
          name: "Helios’ Fury",
          uuid: "72be0f1e569c4569b75b50d59e8b0213",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/72be0f1e569c4569b75b50d59e8b0213.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 622,
          name: "Hex",
          uuid: "9dab000cb4ca4e618d441fb2b4d13648",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/9dab000cb4ca4e618d441fb2b4d13648.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 623,
          name: "Ignite the Night",
          uuid: "67ea623756d94b3fa915b464325c3f62",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/67ea623756d94b3fa915b464325c3f62.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 624,
          name: "It's Time",
          uuid: "63e0e97492584083aa092a493d3c30b9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/63e0e97492584083aa092a493d3c30b9.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 625,
          name: "Kluster Krystals Megaclusters",
          uuid: "0e5b03d86f9e4ed883f37c0761f10a7d",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/0e5b03d86f9e4ed883f37c0761f10a7d.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 636,
          name: "Let's Get Ready To Rumble",
          uuid: "721ff6045f364ec988224d8be07d2e22",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/721ff6045f364ec988224d8be07d2e22.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 626,
          name: "Mega Flip",
          uuid: "a6dadcf7769f444cb30a7761b44df908",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/a6dadcf7769f444cb30a7761b44df908.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 627,
          name: "Mega Mine",
          uuid: "927e01042b7c435495be16cfb14bb87e",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/927e01042b7c435495be16cfb14bb87e.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 628,
          name: "Money Cart",
          uuid: "c8356dd1372d4aedac18bf27e5d3a46f",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/c8356dd1372d4aedac18bf27e5d3a46f.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 629,
          name: "Money Cart 2",
          uuid: "c2faa738edc44f59ac0ce2e7aeec81ec",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/c2faa738edc44f59ac0ce2e7aeec81ec.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 630,
          name: "Money Train",
          uuid: "04e9446563e3410a98609fc114315269",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/04e9446563e3410a98609fc114315269.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 631,
          name: "Money Train 2",
          uuid: "6c6cb9b7fee74f74820b266c7ccef11f",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/6c6cb9b7fee74f74820b266c7ccef11f.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 655,
          name: "Money Train 3",
          uuid: "a1e37ef5801d499492a7b1634e8412ae",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/a1e37ef5801d499492a7b1634e8412ae.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 632,
          name: "Multiplier Odyssey",
          uuid: "3194feb7da184a1c98d0798943ff5b77",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/3194feb7da184a1c98d0798943ff5b77.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 633,
          name: "Mystery Stacks",
          uuid: "70e39b24264447f2bb151dd77a92976a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/70e39b24264447f2bb151dd77a92976a.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 634,
          name: "Plunderland",
          uuid: "e6b82c1f6051450f9e4ae59c956012be",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/e6b82c1f6051450f9e4ae59c956012be.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 635,
          name: "Ramses Revenge",
          uuid: "0369524ac5c04289acd95588766c3961",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/0369524ac5c04289acd95588766c3961.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 637,
          name: "Sails of Fortune",
          uuid: "b1c76f6886f94519b85ec68963b3f287",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/b1c76f6886f94519b85ec68963b3f287.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 97,
          name: "Santa's Stack",
          uuid: "54e8519ccc134f44ab21b0da85269686",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/54e8519ccc134f44ab21b0da85269686.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 638,
          name: "Snake Arena",
          uuid: "efee2139ff024213883823e07752a070",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/efee2139ff024213883823e07752a070.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 639,
          name: "Space Miners",
          uuid: "fccbd81f21324bf086a766a5ff678c69",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/fccbd81f21324bf086a766a5ff678c69.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 640,
          name: "Super Boost",
          uuid: "8e757690e38c4ced89152c2112bf9533",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/8e757690e38c4ced89152c2112bf9533.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 641,
          name: "Templar Tumble",
          uuid: "56e95c6331aa4af7951aecf8818e3752",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/56e95c6331aa4af7951aecf8818e3752.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 642,
          name: "The Great Pigsby",
          uuid: "800ded6fb57d456c909f9fbfdf1feeaf",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/800ded6fb57d456c909f9fbfdf1feeaf.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 643,
          name: "Tiger Kingdom",
          uuid: "e8412093ce6d4fe9a1474311a0d9ecef",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/e8412093ce6d4fe9a1474311a0d9ecef.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 644,
          name: "Top Dawg$",
          uuid: "bb808c587fa94563be7f4085fdf35314",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/bb808c587fa94563be7f4085fdf35314.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 645,
          name: "Trolls’ Gold",
          uuid: "1b1e428683414b9aa1519b78dd576f90",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/1b1e428683414b9aa1519b78dd576f90.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 646,
          name: "Volatile Vikings",
          uuid: "2589617356c64ec28b0a96b2af6a79d6",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/2589617356c64ec28b0a96b2af6a79d6.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 647,
          name: "Wild Chapo",
          uuid: "27cfe55f97974a909c7098ddc27e12df",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/27cfe55f97974a909c7098ddc27e12df.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 648,
          name: "Wildchemy",
          uuid: "397bd6c3f262479487e6e881ffc67541",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/397bd6c3f262479487e6e881ffc67541.png",
          type: "slots",
          provider: "RelaxGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 147,
      name: "RevolverGaming",
      providerId: 27,
      providerName: "RevolverGaming",
      games: [
        {
          game_id: 6982,
          name: "288",
          uuid: "4d02fecd84d845884c1ed17657be9f8d2a28e16b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4d02fecd84d845884c1ed17657be9f8d2a28e16b.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6985,
          name: "Badlands",
          uuid: "1b6d0a859f02a4f2405e4fff02aaf884a919cfb6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RevolverGaming/1b6d0a859f02a4f2405e4fff02aaf884a919cfb6.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6983,
          name: "Deadly Outlaw",
          uuid: "1d481688698b452f6bbaaf45844ea7a247966bcd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1d481688698b452f6bbaaf45844ea7a247966bcd.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6976,
          name: "Dragon Coins",
          uuid: "9fb475906ae34ce592412736b74ea6722625b12e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9fb475906ae34ce592412736b74ea6722625b12e.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6968,
          name: "GoodFishes",
          uuid: "a4a8a2f49d2d3bbf7f293b88846f34fcdddced42",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a4a8a2f49d2d3bbf7f293b88846f34fcdddced42.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6981,
          name: "Gumball 7's",
          uuid: "07c87b0500eff404e378f277f83b58f1390b841e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/07c87b0500eff404e378f277f83b58f1390b841e.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6977,
          name: "Irish Coins",
          uuid: "56f07438eaa705d112b8be80967ae74c7357195d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/56f07438eaa705d112b8be80967ae74c7357195d.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6986,
          name: "Irish Coins - Christmas",
          uuid: "b02136b1d509f217262c2ddd16bf046f2cec6a6e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RevolverGaming/b02136b1d509f217262c2ddd16bf046f2cec6a6e.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6970,
          name: "Lotto Lucky",
          uuid: "1ae5f4f9542722b0eac6b06849bb6a673afa2f94",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1ae5f4f9542722b0eac6b06849bb6a673afa2f94.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6988,
          name: "Lotto Lucky Easter",
          uuid: "6be0fcac98cd4e628ccfbaddfc940114",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RevolverGaming/6be0fcac98cd4e628ccfbaddfc940114.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6967,
          name: "Multiplier Man",
          uuid: "050608cf6cd43d1bf791ccdfb7e56292856ff67c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/050608cf6cd43d1bf791ccdfb7e56292856ff67c.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6978,
          name: "Neon Blaze",
          uuid: "aad60e43266b22cf533ed73c720519f665d7fc0d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aad60e43266b22cf533ed73c720519f665d7fc0d.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6969,
          name: "Parrots of the Caribbean",
          uuid: "b633bfae7ea3604d2b2af8e5af7d1a66f71fe597",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b633bfae7ea3604d2b2af8e5af7d1a66f71fe597.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6980,
          name: "Pets Payday",
          uuid: "a4bcd6c17c42faa2c91ca89b10f62320b1a08bac",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a4bcd6c17c42faa2c91ca89b10f62320b1a08bac.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6984,
          name: "Rainbow Stacks",
          uuid: "98d30e487850d25ac63f2afc582ead8eab5bf56f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RevolverGaming/98d30e487850d25ac63f2afc582ead8eab5bf56f.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6966,
          name: "Reign of Gnomes",
          uuid: "2267675c0331f2b9464032cbe3e85027f130c953",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2267675c0331f2b9464032cbe3e85027f130c953.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6971,
          name: "Robin Hood",
          uuid: "a3c8ddf33c50ccddee9d7396ccdb9dce6ed8e316",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a3c8ddf33c50ccddee9d7396ccdb9dce6ed8e316.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6987,
          name: "Robin Hood-Valentine",
          uuid: "997bc5fc5bba1a4bafa6133a37f403ea0a282b5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RevolverGaming/997bc5fc5bba1a4bafa6133a37f403ea0a282b5c.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6974,
          name: "Space Traders",
          uuid: "0b4fa51ec8a659c2702e30acbf8afebff470bb5d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0b4fa51ec8a659c2702e30acbf8afebff470bb5d.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6975,
          name: "Squish",
          uuid: "af60e77a6a2698dce5eea2eedbbbfbf302dfcfb8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/af60e77a6a2698dce5eea2eedbbbfbf302dfcfb8.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6973,
          name: "The Big Deal",
          uuid: "dab7380434672bc3d0c3bc80161c54c1d4047b87",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dab7380434672bc3d0c3bc80161c54c1d4047b87.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6979,
          name: "Thor of Asgard",
          uuid: "601ed3966948da89a00bc4de44da82d7e8816b39",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/601ed3966948da89a00bc4de44da82d7e8816b39.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6972,
          name: "Wishes",
          uuid: "48ecc8eae12a75089505f8f1ca03ff034f552751",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/48ecc8eae12a75089505f8f1ca03ff034f552751.png",
          type: "slots",
          provider: "RevolverGaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 148,
      name: "RivalGames",
      providerId: 63,
      providerName: "RivalGames",
      games: [
        {
          game_id: 7012,
          name: "5-Reel Circus",
          uuid: "59a6e1a23faf491aacf705ed6d4cf910",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/59a6e1a23faf491aacf705ed6d4cf910.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7030,
          name: "A Day at the Derby",
          uuid: "5ae8ee072f0f4092ad2cfbbb6c1547dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/5ae8ee072f0f4092ad2cfbbb6c1547dc.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7041,
          name: "Aces and Faces",
          uuid: "bed273c6fba148d0ace8212679362064",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/bed273c6fba148d0ace8212679362064.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7119,
          name: "Aces and Faces (Multi-Hand)",
          uuid: "6273a7495adc4d578310fc29876c7763",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/6273a7495adc4d578310fc29876c7763.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7089,
          name: "Alien Spinvasion",
          uuid: "e22bb94fa3fd47f397e9d2494a61d84c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/e22bb94fa3fd47f397e9d2494a61d84c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7104,
          name: "Almighty Dollar",
          uuid: "9eec17cb39704f998af89d1be5d82405",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/9eec17cb39704f998af89d1be5d82405.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7066,
          name: "Arabian Tales",
          uuid: "4aab6e72ab954d6c97c3ecdc2f25bf05",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/4aab6e72ab954d6c97c3ecdc2f25bf05.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7043,
          name: "Astral Luck",
          uuid: "7702474f38c44a5a8e44eb3112f41569",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/7702474f38c44a5a8e44eb3112f41569.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6993,
          name: "Baccarat",
          uuid: "ac966715a50e43e3bcb4515e679a823c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/ac966715a50e43e3bcb4515e679a823c.png",
          type: "table",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7155,
          name: "Bankers Gone Bonkers",
          uuid: "638d690735b42c80b61f4c1971d66ababe0e4679",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/638d690735b42c80b61f4c1971d66ababe0e4679.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7035,
          name: "Beach Bums",
          uuid: "56b9def5bf614535865b4d6d46c91f31",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/56b9def5bf614535865b4d6d46c91f31.png",
          type: "scratch card",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7026,
          name: "Best of Luck",
          uuid: "20210a4fb3fb43189ec51dbab6b8909f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/20210a4fb3fb43189ec51dbab6b8909f.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7170,
          name: "Bierfest Bonanza",
          uuid: "cbf2001ebf434438a2a8af45483e98ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/cbf2001ebf434438a2a8af45483e98ce.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7099,
          name: "Big Bang Buckaroo",
          uuid: "787ffb6115ce49c890f954d2ba0a2357",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/787ffb6115ce49c890f954d2ba0a2357.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7003,
          name: "Big Cash Win",
          uuid: "8a547544ff2b4fefbe9f9d38c77f618e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/8a547544ff2b4fefbe9f9d38c77f618e.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7160,
          name: "Big Shrimpin’",
          uuid: "9b3ecedd98064a308a2a84b893e780f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/9b3ecedd98064a308a2a84b893e780f8.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7161,
          name: "BIGFOOT FORTUNES",
          uuid: "8fa929e17b384577b97f74d9190c0d77",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/8fa929e17b384577b97f74d9190c0d77.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7137,
          name: "Bigger Cash Win",
          uuid: "fb8dc386cb9340718149aa155f8a397f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/fb8dc386cb9340718149aa155f8a397f.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6989,
          name: "Blackjack",
          uuid: "20662a8f21c24b83a284cfdd845eb40c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/20662a8f21c24b83a284cfdd845eb40c.png",
          type: "table",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7124,
          name: "Blackjack (Multi-Hand)",
          uuid: "7cfd65d406414471843cad48c2ec915c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/7cfd65d406414471843cad48c2ec915c.png",
          type: "table",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7059,
          name: "Blazin' Buffalo",
          uuid: "208b2e1599fb4bc4917ebd438e640143",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/208b2e1599fb4bc4917ebd438e640143.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7021,
          name: "Bust-A-Vault",
          uuid: "10e43d8c49054ff6aac6590f22bb08b5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/10e43d8c49054ff6aac6590f22bb08b5.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7158,
          name: "Ca$hablanca",
          uuid: "f197b1df1da04765b0e7c347e10bd39f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/f197b1df1da04765b0e7c347e10bd39f.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6991,
          name: "Card Clash",
          uuid: "b42c18f3768247b29fcee472fa0df426",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/b42c18f3768247b29fcee472fa0df426.png",
          type: "table",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7034,
          name: "Cash for Cash",
          uuid: "f0f5c6883a1c46fa9dfff3e7909d2780",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/f0f5c6883a1c46fa9dfff3e7909d2780.png",
          type: "scratch card",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7079,
          name: "Catsino",
          uuid: "e05a895b74c147a8bd52725c5f95039f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/e05a895b74c147a8bd52725c5f95039f.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7080,
          name: "Champs-Élysées",
          uuid: "f8457228e1fd45dcaeab961a7c380fa8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/f8457228e1fd45dcaeab961a7c380fa8.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7090,
          name: "Chariots of Fire",
          uuid: "7bf006dce15b475093eaa277975315e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/7bf006dce15b475093eaa277975315e4.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7000,
          name: "Chicken Little",
          uuid: "fe732576b16f4496ba065029c2361bbd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/fe732576b16f4496ba065029c2361bbd.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7075,
          name: "Cirque du Slots",
          uuid: "3010f1ffd0bf4d9eb8b370df41e7dc5e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/3010f1ffd0bf4d9eb8b370df41e7dc5e.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7013,
          name: "Cleopatra's Coins",
          uuid: "87524f96738c436e9023d05945eed9f3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/87524f96738c436e9023d05945eed9f3.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7145,
          name: "Cobra King",
          uuid: "804edca4e215497b88b347ebf6c25e11",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/804edca4e215497b88b347ebf6c25e11.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7050,
          name: "Coins of Olympus",
          uuid: "9ce560103c234d94a549349abf6f2f7a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/9ce560103c234d94a549349abf6f2f7a.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6992,
          name: "Costume Party",
          uuid: "6f0fc48344274a7191880ab14ccbec6c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/6f0fc48344274a7191880ab14ccbec6c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7166,
          name: "COYOTE CANYON",
          uuid: "10f2949636684fbda25ac1f636f25738",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/10f2949636684fbda25ac1f636f25738.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7049,
          name: "Crazy Camel Cash",
          uuid: "28198afd90464a6da265f62887206768",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/28198afd90464a6da265f62887206768.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7028,
          name: "Cream of the Crop",
          uuid: "cd7fe855654448048deca9004ec2cba4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/cd7fe855654448048deca9004ec2cba4.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7152,
          name: "Crocodile Hunt",
          uuid: "6e16365b2a6b478fa8ca998e1c761d66",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/6e16365b2a6b478fa8ca998e1c761d66.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7164,
          name: "Crown Of Camelot",
          uuid: "3df66f0e85554f03a629fdb126199f2e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/3df66f0e85554f03a629fdb126199f2e.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7116,
          name: "Daikoku Blessings",
          uuid: "39d39034d7554954848813f3ed088b7b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/39d39034d7554954848813f3ed088b7b.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7096,
          name: "Dark Hearts",
          uuid: "5377eb4fe3d8493cbade025066cb3b98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/5377eb4fe3d8493cbade025066cb3b98.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7162,
          name: "Dawn of El Dorado",
          uuid: "7de57b178c244eb096d7e6665df9ff29",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/7de57b178c244eb096d7e6665df9ff29.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7141,
          name: "Demon’s Delight",
          uuid: "37edce84de464dc491c7ac7df49c2e17",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/37edce84de464dc491c7ac7df49c2e17.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7006,
          name: "Deuces and Joker",
          uuid: "1591a1ffd5e94ec3ab473f25bc233100",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/1591a1ffd5e94ec3ab473f25bc233100.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7121,
          name: "Deuces and Joker (Multi-Hand)",
          uuid: "7c7db8a16b754d2eb92d964e813214b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/7c7db8a16b754d2eb92d964e813214b2.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7009,
          name: "Deuces Wild",
          uuid: "dc1ac29315f941ff8ec05fe54353e216",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/dc1ac29315f941ff8ec05fe54353e216.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7048,
          name: "Deuces Wild (Multi-Hand)",
          uuid: "7a478496f446424986778653caac12aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/7a478496f446424986778653caac12aa.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7076,
          name: "Diamond Cherries",
          uuid: "152341098e6d46249fc355c9f6e27f06",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/152341098e6d46249fc355c9f6e27f06.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7073,
          name: "Diamond Dazzle",
          uuid: "17932e8fbd54454bbd00400fa2193f19",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/17932e8fbd54454bbd00400fa2193f19.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7087,
          name: "Diamond Dragon",
          uuid: "36a2b7b37a3944f99f08840046f2f823",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/36a2b7b37a3944f99f08840046f2f823.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7113,
          name: "Diamond Rhino Classic",
          uuid: "dc6034821c524228a15096d6cbbcc55f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/dc6034821c524228a15096d6cbbcc55f.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7040,
          name: "Diggin' Deep",
          uuid: "27af674a88d54f50a216f2e91056daf3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/27af674a88d54f50a216f2e91056daf3.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7002,
          name: "Dog Pound",
          uuid: "4c302538fb084e8bbb6b31d2d85cce53",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/4c302538fb084e8bbb6b31d2d85cce53.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7088,
          name: "Dollars to Donuts",
          uuid: "4e064212203f47a8be2c9ab2d113ad5f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/4e064212203f47a8be2c9ab2d113ad5f.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7008,
          name: "Double Joker",
          uuid: "6573514bfb394c2384f3fb200c034583",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/6573514bfb394c2384f3fb200c034583.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7123,
          name: "Double Joker (Multi-Hand)",
          uuid: "a6b1c53e850a49e0970f34f4bface4b6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/a6b1c53e850a49e0970f34f4bface4b6.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7105,
          name: "Dr. Acula",
          uuid: "13f7a9089c5f4c8394560584101a5dd8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/13f7a9089c5f4c8394560584101a5dd8.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7156,
          name: "Dragon Harmony",
          uuid: "1f181254ef6345e5be5775e39546ef80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/1f181254ef6345e5be5775e39546ef80.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7127,
          name: "Dublin Your Dough",
          uuid: "d5410e8e8f27466784bde61ac13e50b5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/d5410e8e8f27466784bde61ac13e50b5.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7159,
          name: "Dublin Your Dough: Rainbow Clusters",
          uuid: "1180e7ea00484825ab77b6ca438b981d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/1180e7ea00484825ab77b6ca438b981d.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7020,
          name: "Eggstravaganza",
          uuid: "f49d04ad28434259b72a3701802c1be5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/f49d04ad28434259b72a3701802c1be5.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7125,
          name: "European Roulette",
          uuid: "73ccab9fb046476e8e1e9682b34a196a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/73ccab9fb046476e8e1e9682b34a196a.png",
          type: "table",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7150,
          name: "Fairytale Fortunes: Jack and the Giants",
          uuid: "9868522e98170acee8616bd5a6f82d4986e3f353",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/9868522e98170acee8616bd5a6f82d4986e3f353.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7134,
          name: "Fairytale Fortunes: Queen of Hearts",
          uuid: "9a06ceaeecc047c2afccfbc1a53b9e27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/9a06ceaeecc047c2afccfbc1a53b9e27.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7147,
          name: "Fantasy Fortune",
          uuid: "0e9767d72dff4b0fadd1f077e043bd28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/0e9767d72dff4b0fadd1f077e043bd28.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7047,
          name: "Firestorm 7",
          uuid: "799c01c6dcbe4efc997dcdb0aeba0915",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/799c01c6dcbe4efc997dcdb0aeba0915.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7078,
          name: "Five Times Wins",
          uuid: "d180105724094ee78b770c526f16784b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/d180105724094ee78b770c526f16784b.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6990,
          name: "Flea Market",
          uuid: "b635ca9935b14fa4b1062a434b2366a8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/b635ca9935b14fa4b1062a434b2366a8.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7077,
          name: "Frogged",
          uuid: "227313bdc1314d99bbc0be93a5015a5f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/227313bdc1314d99bbc0be93a5015a5f.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7110,
          name: "Fruit Splash",
          uuid: "291c4a928542464c9ce1edc9882a9003",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/291c4a928542464c9ce1edc9882a9003.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7126,
          name: "Future Fortunes",
          uuid: "f5fd3a3521084718b91f7d3ab2ad8550",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/f5fd3a3521084718b91f7d3ab2ad8550.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7025,
          name: "Global Cup Soccer",
          uuid: "77f4c7fb14064aec9d9314b1232a7de8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/77f4c7fb14064aec9d9314b1232a7de8.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7053,
          name: "Gnome Sweet Home",
          uuid: "ee02c12bfea14ce3bd9b018da7c99e20",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/ee02c12bfea14ce3bd9b018da7c99e20.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7018,
          name: "Gobbler’s Gold",
          uuid: "97d4802ccb504407bcebd9815cec4e02",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/97d4802ccb504407bcebd9815cec4e02.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7094,
          name: "Gold Bricks",
          uuid: "9e8408750ef94a28b9e8b1ef2fd0079d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/9e8408750ef94a28b9e8b1ef2fd0079d.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7001,
          name: "Gold Rush",
          uuid: "3ad118b4f47c444eb042829f0d9a7cae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/3ad118b4f47c444eb042829f0d9a7cae.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7129,
          name: "Golden Boot Football",
          uuid: "ab2060e7945445f4bbd55878bb6b9a3c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/ab2060e7945445f4bbd55878bb6b9a3c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7063,
          name: "Golden Gorilla",
          uuid: "7c31763188ef4414b91866b2bba0493d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/7c31763188ef4414b91866b2bba0493d.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7169,
          name: "Grandma's Attic",
          uuid: "c165657b9af2353c7342f510a26f4efe85e246fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/c165657b9af2353c7342f510a26f4efe85e246fe.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7023,
          name: "Gunslinger’s Gold",
          uuid: "563b508e679944fea8b7a7f680da5f2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/563b508e679944fea8b7a7f680da5f2d.png",
          type: "scratch card",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7031,
          name: "Gushers Gold",
          uuid: "d8fccb1dc7464742aa58108cc11e26bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/d8fccb1dc7464742aa58108cc11e26bc.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7139,
          name: "Hail Caesar",
          uuid: "644b0df220954ff78a44d57917932898",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/644b0df220954ff78a44d57917932898.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7032,
          name: "Heroes’ Realm",
          uuid: "8ae63719715843cba341314fcd1f0e3a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/8ae63719715843cba341314fcd1f0e3a.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6996,
          name: "Hobo's Hoard",
          uuid: "41521f81d44c4a6ebc8f6ff449c49388",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/41521f81d44c4a6ebc8f6ff449c49388.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7148,
          name: "Honey Hive XL",
          uuid: "93d5b2861f8797f1f7065f440b708baeca8e8f59",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/93d5b2861f8797f1f7065f440b708baeca8e8f59.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7108,
          name: "Hot Hand",
          uuid: "47c795c8509542109b8c75f6da23b313",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/47c795c8509542109b8c75f6da23b313.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7038,
          name: "Ice Picks",
          uuid: "c02a9b2b523747e49b3093234681f4cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/c02a9b2b523747e49b3093234681f4cf.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7138,
          name: "Incan Rich",
          uuid: "88a4b97cf7d247a2b47df6829b7608f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/88a4b97cf7d247a2b47df6829b7608f0.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7107,
          name: "Jack Frost",
          uuid: "d20918296716403da8bc157d4ccda1eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/d20918296716403da8bc157d4ccda1eb.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7004,
          name: "Jacks or Better",
          uuid: "8dd59957f6074350a9aef3351f3dbb63",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/8dd59957f6074350a9aef3351f3dbb63.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7118,
          name: "Jacks or Better (Multi-Hand)",
          uuid: "a7e3999a149b40d48888267befdf390a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/a7e3999a149b40d48888267befdf390a.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7153,
          name: "Jingle Jewels",
          uuid: "1886267f10d6d31e2917e85adf6000db72418758",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/1886267f10d6d31e2917e85adf6000db72418758.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7095,
          name: "Johnny Jungle",
          uuid: "1212708d513a4df7856c5b058c73b0f8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/1212708d513a4df7856c5b058c73b0f8.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7007,
          name: "Joker Poker",
          uuid: "4c2b03cd70d54fbb8881a4dd3c8a801a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/4c2b03cd70d54fbb8881a4dd3c8a801a.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7122,
          name: "Joker Poker (Multi-Hand)",
          uuid: "d368fb68a34b4c4794773faa25e5fd37",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/d368fb68a34b4c4794773faa25e5fd37.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7052,
          name: "Jolly Roger's Jackpot",
          uuid: "1feaf85b025b44299c86ad0faf75e85d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/1feaf85b025b44299c86ad0faf75e85d.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7097,
          name: "Juicy Jewels",
          uuid: "81ad4c3d2de04bdd9232ec767d0d9c2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/81ad4c3d2de04bdd9232ec767d0d9c2c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7093,
          name: "Jumping Jaguar",
          uuid: "f0e3e8298ea74a61a36063eb7d58b38b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/f0e3e8298ea74a61a36063eb7d58b38b.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7144,
          name: "Kaboom",
          uuid: "3415228d0bcf48b7b66eac8c1ff02b7d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/3415228d0bcf48b7b66eac8c1ff02b7d.png",
          type: "other",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7010,
          name: "Keno",
          uuid: "c5bb37eddd7a4515b77282aec9718950",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/c5bb37eddd7a4515b77282aec9718950.png",
          type: "other",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7106,
          name: "King Winalot",
          uuid: "1995cc609d304695916eb6d904d33668",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/1995cc609d304695916eb6d904d33668.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7143,
          name: "Klondike Gold",
          uuid: "67936042ce22481a92bad87b5f45c6da",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/67936042ce22481a92bad87b5f45c6da.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7062,
          name: "Leonardo's Loot",
          uuid: "1a5c4adfb3d54c78bc029421a73c13e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/1a5c4adfb3d54c78bc029421a73c13e9.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7060,
          name: "Lion's Roar",
          uuid: "003613d7e8d94c85868e4eadd6475e4b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/003613d7e8d94c85868e4eadd6475e4b.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7067,
          name: "Loco 7's",
          uuid: "0794173524764b3fba8c8b7827211a9b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/0794173524764b3fba8c8b7827211a9b.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7055,
          name: "Lost Secret of Atlantis",
          uuid: "9e0e7506813c4d5dbd4eb9a0445f8b9c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/9e0e7506813c4d5dbd4eb9a0445f8b9c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7027,
          name: "Love and Money",
          uuid: "a6f980fb48274bd693bfb17b675c51e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/a6f980fb48274bd693bfb17b675c51e9.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7102,
          name: "Lucky Labyrinth",
          uuid: "764d7bd9145e4515a42ca0bf3f0eab8e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/764d7bd9145e4515a42ca0bf3f0eab8e.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7130,
          name: "Lucky Ox Jackpots",
          uuid: "7cdc75f7ae47403fa060fa17a03ca47b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/7cdc75f7ae47403fa060fa17a03ca47b.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7146,
          name: "Majestic Mermaid",
          uuid: "27074138aafc4d76adeb9f944fc6611e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/27074138aafc4d76adeb9f944fc6611e.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7171,
          name: "Mandarin Tiger",
          uuid: "0c8550c460cb4db4825e7d8dd48c407c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/0c8550c460cb4db4825e7d8dd48c407c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7082,
          name: "Megawins",
          uuid: "db4a7a4a86964c739d28594e75df9010",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/db4a7a4a86964c739d28594e75df9010.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7117,
          name: "Merlin's Mystical Multipliers",
          uuid: "478c3f8f432048d28141e9c7ce75b1eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/478c3f8f432048d28141e9c7ce75b1eb.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7163,
          name: "Metal Detector: Mayan Magic",
          uuid: "beffe7d4ed7e489697f1db6274d00605",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/beffe7d4ed7e489697f1db6274d00605.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7091,
          name: "Midas Touch",
          uuid: "3a7d64f1e5f94908bdad0aa1fd319ec9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/3a7d64f1e5f94908bdad0aa1fd319ec9.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7168,
          name: "Midnight Mustang",
          uuid: "b90c7ee14ea34cae82fdbc11d928a98c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/b90c7ee14ea34cae82fdbc11d928a98c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7103,
          name: "Mighty Aphrodite",
          uuid: "e4870d5b46e7408d91eeb5b33a2a3ae6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/e4870d5b46e7408d91eeb5b33a2a3ae6.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6994,
          name: "Milk the Cash Cow",
          uuid: "4f8321676249400a942f7176d0a8e63d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/4f8321676249400a942f7176d0a8e63d.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7098,
          name: "Misfit Toyland",
          uuid: "78f3aed3e9304a5e88ffa32cb5220f51",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/78f3aed3e9304a5e88ffa32cb5220f51.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7056,
          name: "Mobile",
          uuid: "3a0f07acc03f410996a9c756862fde32",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/3a0f07acc03f410996a9c756862fde32.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7057,
          name: "Mythic Wolf",
          uuid: "1d5b9e78d3544d9db84bad08e454064a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/1d5b9e78d3544d9db84bad08e454064a.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7136,
          name: "Mythic Wolf: Sacred Moon",
          uuid: "011d52160dae4d47a3078452775ffa85",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/011d52160dae4d47a3078452775ffa85.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7039,
          name: "Nuclear Fishing",
          uuid: "8971203e5e624c379e1fff5971ca0189",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/8971203e5e624c379e1fff5971ca0189.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7016,
          name: "Ocean Treasure",
          uuid: "c9d857bcfe2142929db09477e7eaa629",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/c9d857bcfe2142929db09477e7eaa629.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7154,
          name: "Pai Gow",
          uuid: "175b4c3f4a73434f807d6de8b4784bcd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/175b4c3f4a73434f807d6de8b4784bcd.png",
          type: "table",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7070,
          name: "Panda Party",
          uuid: "14c44c5684714e0183f3e70837a40fb7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/14c44c5684714e0183f3e70837a40fb7.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7100,
          name: "Party Parrot",
          uuid: "18b07311f9bf45cab665f477dd0866ee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/18b07311f9bf45cab665f477dd0866ee.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7022,
          name: "Penguin Payday",
          uuid: "8dfd35a337954fe49d36f29530c96678",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/8dfd35a337954fe49d36f29530c96678.png",
          type: "scratch card",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7033,
          name: "Pirate’s Pillage",
          uuid: "49823f38a8b94d73b086916d8a669d7e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/49823f38a8b94d73b086916d8a669d7e.png",
          type: "scratch card",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7072,
          name: "Pistols & Roses",
          uuid: "bed76d77aa7348c0a153920eb6cf19a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/bed76d77aa7348c0a153920eb6cf19a9.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7142,
          name: "Plucky Lucky",
          uuid: "0c97597babfa4c518978683ab6b25a4d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/0c97597babfa4c518978683ab6b25a4d.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7101,
          name: "Plunk-Oh",
          uuid: "aa0d6659b4af4c1a876f877dd5ecbc80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/aa0d6659b4af4c1a876f877dd5ecbc80.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7112,
          name: "Popping Pinatas",
          uuid: "45a3a77c4aa146f08b70badd8a8a649c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/45a3a77c4aa146f08b70badd8a8a649c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7058,
          name: "Red White & Bleu",
          uuid: "cc545e38aa374f6c97e93d3372e04355",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/cc545e38aa374f6c97e93d3372e04355.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7151,
          name: "Reel Crime: Coffin Up Cash",
          uuid: "81a2ea781d37e79138e3d460de76ef12b186d193",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/81a2ea781d37e79138e3d460de76ef12b186d193.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7135,
          name: "Reel Crime: Stealing Christmas",
          uuid: "24364fb547cb438ea5760c17fde1663b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/24364fb547cb438ea5760c17fde1663b.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7029,
          name: "Reel Party Platinum",
          uuid: "f835fd9269d04324bcccacd5b214f1b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/f835fd9269d04324bcccacd5b214f1b8.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6997,
          name: "Ride 'em Poker",
          uuid: "bf1c69693c634bb7997e85387d324584",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/bf1c69693c634bb7997e85387d324584.png",
          type: "table",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7083,
          name: "Rise of Poseidon",
          uuid: "0dbae20f4eb44e1e8f4209154dc3c218",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/0dbae20f4eb44e1e8f4209154dc3c218.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7132,
          name: "Roll Out the Barrels",
          uuid: "94cc1ac2e2834a8c84a68e8c3f795cb8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/94cc1ac2e2834a8c84a68e8c3f795cb8.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7131,
          name: "Rolling Stack Blackjack",
          uuid: "781821dab804420885b3a95fb6182f79",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/781821dab804420885b3a95fb6182f79.png",
          type: "table",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7017,
          name: "Scary Rich",
          uuid: "83a4552c74e2489e984392e0cdc61253",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/83a4552c74e2489e984392e0cdc61253.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7019,
          name: "Scary Rich 2",
          uuid: "2d48a52528fe461bbc87e1a8fce077d2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/2d48a52528fe461bbc87e1a8fce077d2.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7051,
          name: "Scary Rich 3",
          uuid: "ffd5b054e7f146bc904af0338d93b29c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/ffd5b054e7f146bc904af0338d93b29c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7092,
          name: "Sensational Sixes",
          uuid: "c95907d6819e46f6bf9e2883f2bf2881",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/c95907d6819e46f6bf9e2883f2bf2881.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6998,
          name: "Sevens and Bars",
          uuid: "10967006a54047f3ab6a40a9110991df",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/10967006a54047f3ab6a40a9110991df.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7172,
          name: "Shake Your Mummymaker",
          uuid: "3062b82825ab4842ba65efac2900e0f1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/3062b82825ab4842ba65efac2900e0f1.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7024,
          name: "Shamrock Isle",
          uuid: "7146350dbc0748b59a31d93b067bd056",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/7146350dbc0748b59a31d93b067bd056.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7167,
          name: "Shell Shock",
          uuid: "93ef6ec9762446f7a3a77f5fad806081",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/93ef6ec9762446f7a3a77f5fad806081.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7036,
          name: "Sherwood Forest Fortunes",
          uuid: "6ed4aaa03a63430a893767037ea893ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/6ed4aaa03a63430a893767037ea893ce.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7054,
          name: "Silver Unicorn",
          uuid: "9d8c7cfc04ca428f88db6f6f663f7305",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/9d8c7cfc04ca428f88db6f6f663f7305.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7109,
          name: "Smoking Gun",
          uuid: "d8f134f665a740c884be79ecbd2676ab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/d8f134f665a740c884be79ecbd2676ab.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7064,
          name: "Snow Wonder",
          uuid: "2a454098d3a845cb8003c22f3624852f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/2a454098d3a845cb8003c22f3624852f.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6999,
          name: "So 80's",
          uuid: "bacc891601e847bab5f97debd975e0de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/bacc891601e847bab5f97debd975e0de.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7037,
          name: "Spartan Warrior",
          uuid: "28cd8018a0314f568580faf06373727a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/28cd8018a0314f568580faf06373727a.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7074,
          name: "Star Jewels",
          uuid: "12d4a7a42fb242dc878feb4e44fd90a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/12d4a7a42fb242dc878feb4e44fd90a1.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 6995,
          name: "Surf Paradise",
          uuid: "93152b6efb9e4d6ba9dad595e1ee6480",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/93152b6efb9e4d6ba9dad595e1ee6480.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7068,
          name: "Swinging Sweethearts",
          uuid: "6c8b27457fd94296b05c3b1ac73742a4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/6c8b27457fd94296b05c3b1ac73742a4.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7045,
          name: "Tahiti Time",
          uuid: "5a97d6c743264a2ab1c36d3a0f6aed1b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/5a97d6c743264a2ab1c36d3a0f6aed1b.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7157,
          name: "Teen Patty",
          uuid: "3fc27f37dcf649bd8e4148c053a997c3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/3fc27f37dcf649bd8e4148c053a997c3.png",
          type: "table",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7149,
          name: "Ten Suns",
          uuid: "1680aa13f42c7050930c0e1b7f8840da30711d3c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/1680aa13f42c7050930c0e1b7f8840da30711d3c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7085,
          name: "Ten Times Wins",
          uuid: "3cab51cf6bc8480abd71678dfc977a92",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/3cab51cf6bc8480abd71678dfc977a92.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7005,
          name: "Tens or Better",
          uuid: "ceaef8eaff10445ba5f5693d411ba7cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/ceaef8eaff10445ba5f5693d411ba7cf.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7120,
          name: "Tens or Better (Multi-Hand)",
          uuid: "88debfc947ab42319ca2df1f0650dc15",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/88debfc947ab42319ca2df1f0650dc15.png",
          type: "poker",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7081,
          name: "Thunderbird",
          uuid: "ddfdd241bf344f3bb14797d83fdb2e9e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/ddfdd241bf344f3bb14797d83fdb2e9e.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7015,
          name: "Tiki Treasure",
          uuid: "849d95b14b2e4978abfa4d020ff5fa22",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/849d95b14b2e4978abfa4d020ff5fa22.png",
          type: "scratch card",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7114,
          name: "Treasure of the Nile",
          uuid: "2ff29b1fc2e443c8b26095ab37963ef9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/2ff29b1fc2e443c8b26095ab37963ef9.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7071,
          name: "Tycoon Towers",
          uuid: "0b42e79e39fe4a7bbedba7de4d7183d5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/0b42e79e39fe4a7bbedba7de4d7183d5.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7011,
          name: "Vegas Jackpot Keno",
          uuid: "bcb6ecab0e41489c8f4045e6900d7c05",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/bcb6ecab0e41489c8f4045e6900d7c05.png",
          type: "other",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7128,
          name: "Viking Victory",
          uuid: "65a60d27011b4703a93a96e3c13da79e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/65a60d27011b4703a93a96e3c13da79e.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7061,
          name: "Vintage Vegas",
          uuid: "077e745ee19243408fc8c6d77eafe7b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/077e745ee19243408fc8c6d77eafe7b8.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7140,
          name: "Volcano Blast 10X",
          uuid: "4aa6ce819d2040aa9bc943bbda02842a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/4aa6ce819d2040aa9bc943bbda02842a.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7065,
          name: "Whale O'Winnings",
          uuid: "a2b5333981094021a9a30a1db41b9af6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/a2b5333981094021a9a30a1db41b9af6.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7044,
          name: "Wild Carnival",
          uuid: "32c9eedb32fb43e8bcbacb339f5848bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/32c9eedb32fb43e8bcbacb339f5848bc.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7042,
          name: "Win Mill",
          uuid: "5dcc174b9b4d40a19e387a7c5ccad2e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/5dcc174b9b4d40a19e387a7c5ccad2e0.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7084,
          name: "Windy Farm",
          uuid: "27f0b9fc8b1f4ecab5110fc0adf74597",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/27f0b9fc8b1f4ecab5110fc0adf74597.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7111,
          name: "Winsanity",
          uuid: "580a80f2376e44f7b17ff8249c82de6c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/580a80f2376e44f7b17ff8249c82de6c.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7014,
          name: "Winter Wonders",
          uuid: "d7c63f7e3e6a41b0a169d71ef6ddeac6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/d7c63f7e3e6a41b0a169d71ef6ddeac6.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7086,
          name: "Wishing Cup",
          uuid: "78b9fd35230744558ef52227245fef9f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/78b9fd35230744558ef52227245fef9f.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7133,
          name: "Witches of Salem",
          uuid: "2c58b7b2d3b449f79b5c03d6d621a423",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/2c58b7b2d3b449f79b5c03d6d621a423.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7069,
          name: "World of Oz",
          uuid: "b7158889704847f39d413fd4a611e369",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/b7158889704847f39d413fd4a611e369.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7115,
          name: "Wrath of Medusa",
          uuid: "fc0d503b9de8471b91e87e0e9db466ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/fc0d503b9de8471b91e87e0e9db466ad.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7165,
          name: "Zeus Thunder Fortunes",
          uuid: "e6671b3001c24734bcf088087beb11aa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/e6671b3001c24734bcf088087beb11aa.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7046,
          name: "Zombiezee Money",
          uuid: "7564d249254f4c52a0fe3721f4a1dea1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/RivalGames/7564d249254f4c52a0fe3721f4a1dea1.png",
          type: "slots",
          provider: "RivalGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 149,
      name: "Salsa",
      providerId: 62,
      providerName: "Salsa",
      games: [
        {
          game_id: 7183,
          name: "Candy Bingo",
          uuid: "2192b203356e4de0bf8facba4aee0b58",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/2192b203356e4de0bf8facba4aee0b58.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7177,
          name: "Candy Bingo 3D",
          uuid: "b445f4300dbf4cf8bad8b4f6c16d7dfe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/b445f4300dbf4cf8bad8b4f6c16d7dfe.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7174,
          name: "Crystal Monster",
          uuid: "6f8963c7fca94975ab244c499098d3a3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/6f8963c7fca94975ab244c499098d3a3.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7173,
          name: "Cuca´s Swamp Bingo",
          uuid: "2e1ce3546f044f0880e9b3e23bd1177f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/2e1ce3546f044f0880e9b3e23bd1177f.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7180,
          name: "Farm Bingo",
          uuid: "7aa58eed1e9c4e2fae46c89b13e9724f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/7aa58eed1e9c4e2fae46c89b13e9724f.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7182,
          name: "Goal Bingo",
          uuid: "130489681f80477083044a010aab13d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/130489681f80477083044a010aab13d7.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7176,
          name: "Halloween Groove",
          uuid: "d8460278bf55477f9334ab07b530da9b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/d8460278bf55477f9334ab07b530da9b.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7175,
          name: "Jogo dos Bichos Praia",
          uuid: "d38a6d0825e1455c89e4976c22c515e5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/d38a6d0825e1455c89e4976c22c515e5.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7189,
          name: "Melcochita",
          uuid: "11cfa0c3e1d2cfc31b29e939343717ee56ea0945",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Salsa/11cfa0c3e1d2cfc31b29e939343717ee56ea0945.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7178,
          name: "Pachinko 3D",
          uuid: "c1be72fb58364c4b904a67f6b3c479c5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/c1be72fb58364c4b904a67f6b3c479c5.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7181,
          name: "Pirates",
          uuid: "62a4a1109ca9415c9070c8ac85ba08ed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/62a4a1109ca9415c9070c8ac85ba08ed.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7186,
          name: "ShowBall",
          uuid: "81712161e97f471abf324fa9b175a564",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/81712161e97f471abf324fa9b175a564.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7184,
          name: "Super Flex Bingo",
          uuid: "0e09895c77e247dcacf03d9fe9244488",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/0e09895c77e247dcacf03d9fe9244488.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7188,
          name: "Super Hot Bingo",
          uuid: "f809ea2eb2bb4fce91c6d753b3bf3c2d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/f809ea2eb2bb4fce91c6d753b3bf3c2d.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7187,
          name: "Super Pachinko",
          uuid: "62613cd1511640fd92af697dd8d5cfcd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/62613cd1511640fd92af697dd8d5cfcd.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7179,
          name: "Super Pachinko Plus",
          uuid: "113d5d4d9a6c4916b4f32d99350d7621",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/113d5d4d9a6c4916b4f32d99350d7621.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7185,
          name: "Super Zodiac Bingo",
          uuid: "169d938150b64767b46f2feb4daf12d9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Salsa/169d938150b64767b46f2feb4daf12d9.png",
          type: "bingo",
          provider: "Salsa",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 150,
      name: "Spadegaming",
      providerId: 33,
      providerName: "Spadegaming",
      games: [
        {
          game_id: 7235,
          name: "5 Fortune Dragons",
          uuid: "4b154fd96s933gf0115646e14f2fe738b4a096bb0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/4b154fd96s933gf0115646e14f2fe738b4a096bb0.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7227,
          name: "5 Fortune SA",
          uuid: "2fd3a0678sc354c8c4065d7g3f0c4e592a5c00g02",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/2fd3a0678sc354c8c4065d7g3f0c4e592a5c00g02.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7196,
          name: "888",
          uuid: "e21ag26cb1e76f05a7f28584s4e71e46635f3c0gw",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/e21ag26cb1e76f05a7f28584s4e71e46635f3c0gw.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7295,
          name: "Alchemy Quest Level Up",
          uuid: "8f5f2ce1ffa84a86b7558beba7075728",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/8f5f2ce1ffa84a86b7558beba7075728.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7256,
          name: "Alien Hunter",
          uuid: "f0cbade3a85414f1c277dc9c8d472239a24a07f5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f0cbade3a85414f1c277dc9c8d472239a24a07f5.png",
          type: "shooting",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7228,
          name: "Baby Cai Shen",
          uuid: "57ada9720s239ad02724371gfc1d4923ef7215g22",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/57ada9720s239ad02724371gfc1d4923ef7215g22.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7223,
          name: "Big Prosperity SA",
          uuid: "90830b8e5s937163bb254f30e148f4b450166c863",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/90830b8e5s937163bb254f30e148f4b450166c863.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7194,
          name: "Book of Myth",
          uuid: "472dc55a6s834f2f4521e738541c1c9b158ff60gs",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/472dc55a6s834f2f4521e738541c1c9b158ff60gs.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7205,
          name: "Brothers kingdom",
          uuid: "e3956145112516cc1aeede042s23b8194cd143de4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/e3956145112516cc1aeede042s23b8194cd143de4.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7293,
          name: "Brothers Kingdom 2",
          uuid: "0fbb5fc8f0464ddb9b1c92221b4eedad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/0fbb5fc8f0464ddb9b1c92221b4eedad.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7233,
          name: "Cai Shen 888",
          uuid: "75def4c74s232gfda24a790f45614b11109581e71",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/75def4c74s232gfda24a790f45614b11109581e71.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7243,
          name: "Cai Yuan Guang Jin",
          uuid: "aeb1eb6gdsc35g261bb176e6429fb581527bf4ee1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/aeb1eb6gdsc35g261bb176e6429fb581527bf4ee1.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7271,
          name: "Caishen",
          uuid: "3051ec0ff85c0009904fcacd43fe01609fea3d84",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3051ec0ff85c0009904fcacd43fe01609fea3d84.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7281,
          name: "Caishen Deluxe Maxways",
          uuid: "1b84b4623f2547a4ad404868fcc928b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/1b84b4623f2547a4ad404868fcc928b0.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7265,
          name: "Candy Candy",
          uuid: "08781db56bed435eba116dc4a74e06aa6a4f09b3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/08781db56bed435eba116dc4a74e06aa6a4f09b3.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7207,
          name: "Candy Pop",
          uuid: "c3633a46d1efb520af0c8e7f7s8387504fb11e26a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/c3633a46d1efb520af0c8e7f7s8387504fb11e26a.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7284,
          name: "Candy Pop 2",
          uuid: "a52c4a5e82274fd834cece1c151cf6d7de973238",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/a52c4a5e82274fd834cece1c151cf6d7de973238.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7276,
          name: "Captain Golds Fortune",
          uuid: "37480fd5edf00eb9b2670818203ae6895119ffaa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/37480fd5edf00eb9b2670818203ae6895119ffaa.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7279,
          name: "Christmas Miracles",
          uuid: "4233d8c02673126fdf9125b5cdb0dab33981ec7c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/4233d8c02673126fdf9125b5cdb0dab33981ec7c.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7289,
          name: "Clash of the Giants",
          uuid: "19a743576f3e4832b25230fabc2f4be1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/19a743576f3e4832b25230fabc2f4be1.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7251,
          name: "Crazy Bomber",
          uuid: "5d1ce20a21e0b7c8c3747a696e34c87ef1cbe358",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/5d1ce20a21e0b7c8c3747a696e34c87ef1cbe358.jpeg",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7244,
          name: "Da Fu Xiao Fu",
          uuid: "a1111c462sb30gad6296d84aebf4f215747334281",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/a1111c462sb30gad6296d84aebf4f215747334281.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7200,
          name: "Dancing Fever",
          uuid: "ed27gb9fb4a096b57ada9720s4051d6b43fad20gf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/ed27gb9fb4a096b57ada9720s4051d6b43fad20gf.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7193,
          name: "Double Flame",
          uuid: "e050ebdb4s13651b46514ab934401f33418bf65gf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/e050ebdb4s13651b46514ab934401f33418bf65gf.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7234,
          name: "Double Fortunes",
          uuid: "d29765bb1sc35g261bb176e6651b4ab95fb98b7c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/d29765bb1sc35g261bb176e6651b4ab95fb98b7c0.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7221,
          name: "Dragon Gold SA",
          uuid: "e050ebdb4s13651b4ab95fb98b7c01b4881097bfd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/e050ebdb4s13651b4ab95fb98b7c01b4881097bfd.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7292,
          name: "Dragon Wish",
          uuid: "0aab74569b174f8291eb083d8d1b5522",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/0aab74569b174f8291eb083d8d1b5522.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7222,
          name: "Emperor Gate SA",
          uuid: "472dc55a6s834f2fe738b4a096bb0ab4b01e2cd14",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/472dc55a6s834f2fe738b4a096bb0ab4b01e2cd14.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7238,
          name: "Fafafa",
          uuid: "bd6549c83sd3b8194cde4404de1439aeb1eb6gd42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/bd6549c83sd3b8194cde4404de1439aeb1eb6gd42.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7209,
          name: "FaFaFa2",
          uuid: "b37c0b58esc3d5de2d763e84d723c01b4881090d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/b37c0b58esc3d5de2d763e84d723c01b4881090d7.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7283,
          name: "Farmland Frenzy Maxways",
          uuid: "8a0628de21828f8b35384e0b3f5d03482e9ded76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/8a0628de21828f8b35384e0b3f5d03482e9ded76.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7242,
          name: "Festive Lion",
          uuid: "a8ed5cba5s6341c1c01c27a28d35dg981e75617g5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/a8ed5cba5s6341c1c01c27a28d35dg981e75617g5.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7252,
          name: "Fiery Sevens",
          uuid: "f29fabc9b87b05033d44a78088b1fd3e2de0e40d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f29fabc9b87b05033d44a78088b1fd3e2de0e40d.jpeg",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7268,
          name: "Fiery Sevens Exclusive",
          uuid: "ff58da7fbab8eedbdbb94599ff9eb0f2249d1607",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ff58da7fbab8eedbdbb94599ff9eb0f2249d1607.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7212,
          name: "First Love",
          uuid: "75def4c74s238a14fda24a790f404d5g8a402cd14",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/75def4c74s238a14fda24a790f404d5g8a402cd14.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7190,
          name: "Fishing God",
          uuid: "46db64427se391c74141bc4169d41cgcdeb646d3e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/46db64427se391c74141bc4169d41cgcdeb646d3e.png",
          type: "shooting",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7191,
          name: "Fishing War",
          uuid: "05bbbc22fs8397f0434145cd5498b5g7f901418f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/05bbbc22fs8397f0434145cd5498b5g7f901418f7.png",
          type: "shooting",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7215,
          name: "Fist of Gold",
          uuid: "a8ed5cba5s631cda4601c27a28d35dg9819d65536",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/a8ed5cba5s631cda4601c27a28d35dg9819d65536.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7274,
          name: "Fruits Mania",
          uuid: "93f8df3cf2ba880e4f9a415069ab4646309aed97",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/93f8df3cf2ba880e4f9a415069ab4646309aed97.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7296,
          name: "Fury Max Lucky Road",
          uuid: "7afa5a46a9473256f1b9742165a1dfcacdd4b63f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/7afa5a46a9473256f1b9742165a1dfcacdd4b63f.jpg",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7290,
          name: "Galaxy Guardian",
          uuid: "44f06f96b284067f730bff4ad7dd840bbd779548",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/44f06f96b284067f730bff4ad7dd840bbd779548.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7286,
          name: "Gemstone Rush",
          uuid: "c4eb760d2f8148278d24b7a7a4230900",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/c4eb760d2f8148278d24b7a7a4230900.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7236,
          name: "God's Kitchen",
          uuid: "4268fe924sb30gad6296d84a7163bb254f30e148f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/4268fe924sb30gad6296d84a7163bb254f30e148f.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7203,
          name: "Gold Panther",
          uuid: "f6587ge680de2b8dc1c33827ds138f4b450166a3e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/f6587ge680de2b8dc1c33827ds138f4b450166a3e.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7273,
          name: "Gold Panther Maxways",
          uuid: "d6605cfba79bcf54c69cdb42b639e07be073d548",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d6605cfba79bcf54c69cdb42b639e07be073d548.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7264,
          name: "Gold Rush Cowboy",
          uuid: "084a8329d3629f7aa36fc8635f7e62baefa9925e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/084a8329d3629f7aa36fc8635f7e62baefa9925e.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7299,
          name: "Gold West",
          uuid: "3d513edabee33929112bd2fb34d4f06899c6c430",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/3d513edabee33929112bd2fb34d4f06899c6c430.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7220,
          name: "Golden Chicken",
          uuid: "9c979852esf345614b11109581e713e0ecf453183",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/9c979852esf345614b11109581e713e0ecf453183.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7208,
          name: "Golden Fist",
          uuid: "230ecf453183f4d2df254027bs739a1a4601f4649",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/230ecf453183f4d2df254027bs739a1a4601f4649.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7229,
          name: "Golden Lotus SE",
          uuid: "a87628c08s233b1405g67116587ge680de2b8d2d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/a87628c08s233b1405g67116587ge680de2b8d2d7.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7213,
          name: "Golden Monkey",
          uuid: "d29765bb1sc37301261bb176e6f68a7gf8215c863",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/d29765bb1sc37301261bb176e6f68a7gf8215c863.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7245,
          name: "Golden Whale",
          uuid: "a9fb58159s336g3f11ebca7456145112527bf4ee1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/a9fb58159s336g3f11ebca7456145112527bf4ee1.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7224,
          name: "Great Stars SA",
          uuid: "5a7f28584se3c7bef1cd41cgcdeb646d3e78b4g14",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/5a7f28584se3c7bef1cd41cgcdeb646d3e78b4g14.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7287,
          name: "Halloween Vacation",
          uuid: "b3b021d2e08caded09d86c603385a02b69ef30d1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/b3b021d2e08caded09d86c603385a02b69ef30d1.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7285,
          name: "Hammer of Thunder",
          uuid: "456747f6f78f46708f0cfca7b550bd58",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/456747f6f78f46708f0cfca7b550bd58.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7198,
          name: "Heroes",
          uuid: "d0c4ga2781e713ea9cb1a512s48d149d1759a75gn",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/d0c4ga2781e713ea9cb1a512s48d149d1759a75gn.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7241,
          name: "Highway Fortune",
          uuid: "d03c1d12es334401f71270159a406g3f1d5de4e71",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/d03c1d12es334401f71270159a406g3f1d5de4e71.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7219,
          name: "Ho Yeah Monkey",
          uuid: "05bbbc22fs8397f0434145cd6f8592d633a46d1ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/05bbbc22fs8397f0434145cd6f8592d633a46d1ef.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7254,
          name: "Hugon Quest",
          uuid: "2ac376b85722d4e674d20457b7d64139824e7e76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/2ac376b85722d4e674d20457b7d64139824e7e76.jpeg",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7225,
          name: "Iceland SA",
          uuid: "65d0b4184se3eb1e42c98b5g7f901418f78a47g85",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/65d0b4184se3eb1e42c98b5g7f901418f78a47g85.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7192,
          name: "Joker's Treasure",
          uuid: "9c979852esf345614b1110959a65acg107cc7dfa6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/9c979852esf345614b1110959a65acg107cc7dfa6.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7270,
          name: "Jokers Treasure Exclusive",
          uuid: "34b211e8c15d1861fca5e2b87c172ab49a3683dd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/34b211e8c15d1861fca5e2b87c172ab49a3683dd.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7275,
          name: "Journey to the Wild",
          uuid: "74594067192c0c727419558d2da82638a1b02135",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/74594067192c0c727419558d2da82638a1b02135.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7214,
          name: "Jungle King",
          uuid: "d03c1d12es33004c4f71270159a406g3f11ebca74",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/d03c1d12es33004c4f71270159a406g3f11ebca74.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7231,
          name: "King Pharaoh",
          uuid: "c1c33827ds13b95854633fc91cda46e391c745cd6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/c1c33827ds13b95854633fc91cda46e391c745cd6.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7255,
          name: "Kungfu Dragon",
          uuid: "932892f9142b092d2b62c9321492c659a0ef3984",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/932892f9142b092d2b62c9321492c659a0ef3984.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7278,
          name: "Legacy of Kong",
          uuid: "6fc371e7b75724a36a9c860cbfdb9e37d104e4e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/6fc371e7b75724a36a9c860cbfdb9e37d104e4e3.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7259,
          name: "Love Idol",
          uuid: "54a53e33acc98f843c1ac170e51cfec3a9687a93",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/54a53e33acc98f843c1ac170e51cfec3a9687a93.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7246,
          name: "Lucky Cai Shen",
          uuid: "1b1e76f0fs636ff041gf07cg0624041a991c74141",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/1b1e76f0fs636ff041gf07cg0624041a991c74141.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7239,
          name: "Lucky Feng Shui",
          uuid: "d29765bb1sc3df9a43cb9f68ad149d1759a75g261",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/d29765bb1sc3df9a43cb9f68ad149d1759a75g261.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7237,
          name: "Lucky Koi",
          uuid: "d03c1d12es336g3f11ebca74c7bef1cd41cgcdeb6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/d03c1d12es336g3f11ebca74c7bef1cd41cgcdeb6.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7269,
          name: "Lucky Koi Exclusive",
          uuid: "e9ca37729ac76818e9a6125df38338d904454b1d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e9ca37729ac76818e9a6125df38338d904454b1d.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7247,
          name: "Lucky Meow",
          uuid: "d6f8592d6s13cd5b48f91b5b33a46d1ef97f04341",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/d6f8592d6s13cd5b48f91b5b33a46d1ef97f04341.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7298,
          name: "Lucky Twin Sea",
          uuid: "b45eccb6a09c4ba59afae7260e28b7cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/b45eccb6a09c4ba59afae7260e28b7cc.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7258,
          name: "Magic Kitty",
          uuid: "41bf233c6a787411e380b2c5840fc3afc15eb00c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/41bf233c6a787411e380b2c5840fc3afc15eb00c.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7201,
          name: "Magical lamp",
          uuid: "b65d7g3f0c4e592af041gc6b4f30e14a87628c08s",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/b65d7g3f0c4e592af041gc6b4f30e14a87628c08s.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7250,
          name: "Mayan Gems",
          uuid: "ebb2ac58d0a1c63d6debf942b04a5630ed3b1a5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ebb2ac58d0a1c63d6debf942b04a5630ed3b1a5d.jpeg",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7253,
          name: "Mega 7",
          uuid: "66f1182de447ec3e74eeb5a837caadb023be89e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/66f1182de447ec3e74eeb5a837caadb023be89e8.jpeg",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7230,
          name: "Mermaid",
          uuid: "2ffc0b220s7350ed27gb9fe9945gb2522ae9204a6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/2ffc0b220s7350ed27gb9fe9945gb2522ae9204a6.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7288,
          name: "Moji Mania",
          uuid: "6303de35289fd137605d2e5df4711b06c68a0983",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/6303de35289fd137605d2e5df4711b06c68a0983.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7195,
          name: "Money Mouse",
          uuid: "90830b8e5s9371634e71bb2564991594c73d525gj",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/90830b8e5s9371634e71bb2564991594c73d525gj.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7204,
          name: "Mr Chu Tycoon",
          uuid: "e32ebf4f21579d1cb0fbbcb5asd3cd5b48f10e791",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/e32ebf4f21579d1cb0fbbcb5asd3cd5b48f10e791.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7240,
          name: "Pirate King",
          uuid: "4b154fd96s93875046514fb11418b2eed0f83gf01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/4b154fd96s93875046514fb11418b2eed0f83gf01.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7261,
          name: "Poker Ways",
          uuid: "dfad67bc66cc0fcf251ca498677db4a5016faf18",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dfad67bc66cc0fcf251ca498677db4a5016faf18.png",
          type: "poker",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7210,
          name: "Princess Wang",
          uuid: "4c4d498a1sa34d8afc9226af352f07cg5b9585463",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/4c4d498a1sa34d8afc9226af352f07cg5b9585463.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7206,
          name: "Prosperity Gods",
          uuid: "43f0624041a93c8726d18abc9s53df9a43c1bd3b9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/43f0624041a93c8726d18abc9s53df9a43c1bd3b9.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7280,
          name: "Rabbit Riches",
          uuid: "f8bf4e3d46174c9bb61d650da760ae96",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/f8bf4e3d46174c9bb61d650da760ae96.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7249,
          name: "Rise Of Werewolves",
          uuid: "c9a8f7bdb6049d0241508d242a60b8551b274b64",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/c9a8f7bdb6049d0241508d242a60b8551b274b64.jpeg",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7266,
          name: "Roma",
          uuid: "17c9cba76a1de96a485c71716b5a60faafad1dd6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/17c9cba76a1de96a485c71716b5a60faafad1dd6.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7263,
          name: "Royal Katt",
          uuid: "9ad79add2705acc2b8f5c928e0c11ac320330f49",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9ad79add2705acc2b8f5c928e0c11ac320330f49.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7291,
          name: "Royale Vegas",
          uuid: "1dcaed3517504115bee9663814021277",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/1dcaed3517504115bee9663814021277.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7260,
          name: "Ruby Hood",
          uuid: "03d8487132e607b8d4103103663c52bbf2547d29",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/03d8487132e607b8d4103103663c52bbf2547d29.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7248,
          name: "Santa Gifts",
          uuid: "98b7c01b4sc3df9a43cb9f68881097bfd651b4ab9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/98b7c01b4sc3df9a43cb9f68881097bfd651b4ab9.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7217,
          name: "Sea Emperor",
          uuid: "bd6549c83sd327bf4ee1439a9fb58159561451125",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/bd6549c83sd327bf4ee1439a9fb58159561451125.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7294,
          name: "Secrets of Anubis",
          uuid: "f4f09040939e4e2fbbbdea4f95dba24d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/f4f09040939e4e2fbbbdea4f95dba24d.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7267,
          name: "Sexy Vegas",
          uuid: "1ab0ebd2af98c4d2b515afb70004458f4bce6488",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1ab0ebd2af98c4d2b515afb70004458f4bce6488.jpeg",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7272,
          name: "Space Conquest",
          uuid: "0fc2de4d67aee83ba4bacac393865834963b4f77",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0fc2de4d67aee83ba4bacac393865834963b4f77.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7297,
          name: "Space Crasher",
          uuid: "deed17e5429064091def3fcaa5bb7105c536582b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/deed17e5429064091def3fcaa5bb7105c536582b.png",
          type: "crash",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7277,
          name: "Sugar Party",
          uuid: "c70a0df166572c7876a8dd3e319d8fd2ccb7877a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c70a0df166572c7876a8dd3e319d8fd2ccb7877a.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7199,
          name: "Sweet Bakery",
          uuid: "1405g6715fb98b72fd3a0678s4b418b2eed0f83gw",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/1405g6715fb98b72fd3a0678s4b418b2eed0f83gw.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7197,
          name: "Three Lucky Stars",
          uuid: "c8dbg9c46f8592d65d0b4184s49d126aa32a9d2gv",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/c8dbg9c46f8592d65d0b4184s49d126aa32a9d2gv.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7282,
          name: "Tiger Dance",
          uuid: "696249b2f7e453a74cdcf9bef49e970e7e572034",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/696249b2f7e453a74cdcf9bef49e970e7e572034.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7216,
          name: "Tiger Warrior",
          uuid: "fa63975fcs13473342818eaa1111c462ebf4f2157",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/fa63975fcs13473342818eaa1111c462ebf4f2157.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7202,
          name: "Triple Panda",
          uuid: "04371gfc1d4923ef2ffc0b220s73b0ab4b01e2900",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/04371gfc1d4923ef2ffc0b220s73b0ab4b01e2900.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7262,
          name: "Wild Wet Win",
          uuid: "1126c783d06cb0ae38109d9d7b0f14520a15eaa7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1126c783d06cb0ae38109d9d7b0f14520a15eaa7.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7226,
          name: "Wong Choy SA",
          uuid: "a9cb1a512s4369e26c765acg107cc7dfa6f825g63",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/a9cb1a512s4369e26c765acg107cc7dfa6f825g63.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7232,
          name: "Wong Po",
          uuid: "4c4d498a1sa33d526af35a3e91c74141bc41b1e76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/4c4d498a1sa33d526af35a3e91c74141bc41b1e76.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7211,
          name: "Wow Prosperity",
          uuid: "264dda0d7s03f7dd61c5077cdfb5b58g78be97bfd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/264dda0d7s03f7dd61c5077cdfb5b58g78be97bfd.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7218,
          name: "ZEUS",
          uuid: "46db64427se391c74141bc41b1e76f0f0624041a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Spadegaming/46db64427se391c74141bc41b1e76f0f0624041a9.png",
          type: "slots",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7257,
          name: "Zombie Party",
          uuid: "09e822a9345ef9ff16c98b2c7e1c4c1b6034b958",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/09e822a9345ef9ff16c98b2c7e1c4c1b6034b958.png",
          type: "shooting",
          provider: "Spadegaming",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 151,
      name: "Spinmatic",
      providerId: 38,
      providerName: "Spinmatic",
      games: [
        {
          game_id: 7416,
          name: "3 Amigos",
          uuid: "a1eef5d7ddf84a0ba8e3ab6a3e81a35d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/a1eef5d7ddf84a0ba8e3ab6a3e81a35d.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7417,
          name: "3 Amigos Mobile",
          uuid: "5694a7b5a6944b5c90944ea1e34e1556",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/5694a7b5a6944b5c90944ea1e34e1556.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7326,
          name: "Baby Blue",
          uuid: "0e9edeb1f3a64571938f76619e08217a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/0e9edeb1f3a64571938f76619e08217a.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7327,
          name: "Baby Blue Mobile",
          uuid: "7b128e46d9354c02b12f4830efdefca3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/7b128e46d9354c02b12f4830efdefca3.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7436,
          name: "Balloon Run",
          uuid: "f267c7133e7a208448174074f81edfe3957f495a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f267c7133e7a208448174074f81edfe3957f495a.png",
          type: "crash",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7437,
          name: "Balloon Run Mobile",
          uuid: "cbd75ea0a8a13555a95a702c733232aea9d6fc27",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cbd75ea0a8a13555a95a702c733232aea9d6fc27.png",
          type: "crash",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7470,
          name: "Bank Run",
          uuid: "d0eb028edf1e3dbe20cb89899b3b34fbce6e96d5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/d0eb028edf1e3dbe20cb89899b3b34fbce6e96d5.png",
          type: "crash",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7471,
          name: "Bank Run Mobile",
          uuid: "0fd15965e7edb56d0b86eff18611cc6d80f567fd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/0fd15965e7edb56d0b86eff18611cc6d80f567fd.png",
          type: "crash",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7424,
          name: "Betrick: Scratch",
          uuid: "fbd43fc64d3133f50d722660a7b5f3365ef741e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fbd43fc64d3133f50d722660a7b5f3365ef741e4.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7425,
          name: "Betrick: Scratch Mobile",
          uuid: "9707ed325e4f09cd8c271c46d4f961489e9bc36e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9707ed325e4f09cd8c271c46d4f961489e9bc36e.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7384,
          name: "Betrick: Son of a Leprechaun",
          uuid: "7415c2f5c1854fc0a08672e30cd1bc74",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/7415c2f5c1854fc0a08672e30cd1bc74.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7385,
          name: "Betrick: Son of a Leprechaun Mobile",
          uuid: "270c38f48a864c2ca3e16c8173a09c8a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/270c38f48a864c2ca3e16c8173a09c8a.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7342,
          name: "Bingo Machine",
          uuid: "0a255f8eeeb7467396410393344bc53b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/0a255f8eeeb7467396410393344bc53b.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7343,
          name: "Bingo Machine Mobile",
          uuid: "ea81c2ce2824411e9b76cadde40ca117",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/ea81c2ce2824411e9b76cadde40ca117.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7476,
          name: "Bingooo Scratch",
          uuid: "419544beabd1419d9aa3b72d912b14cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/419544beabd1419d9aa3b72d912b14cf.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7477,
          name: "Bingooo Scratch Mobile",
          uuid: "c3bd33c3722844a197ffd1c8b8942cb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c3bd33c3722844a197ffd1c8b8942cb3.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7404,
          name: "Boat Trip Mississippi",
          uuid: "9886e8a1d4f54b5fbb584218bda12a9b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/9886e8a1d4f54b5fbb584218bda12a9b.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7405,
          name: "Boat Trip Mississippi Mobile",
          uuid: "b39d000d424845ecab8ed6908b8873cb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/b39d000d424845ecab8ed6908b8873cb.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7324,
          name: "Book Of Eon",
          uuid: "aee029d65d5146d98ee9ed91c8dbeef1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/aee029d65d5146d98ee9ed91c8dbeef1.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7325,
          name: "Book Of Eon Mobile",
          uuid: "561883fe568f42d69fd94259f02f8968",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/561883fe568f42d69fd94259f02f8968.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7332,
          name: "Boss Vegas",
          uuid: "56273c95fb2b4a798fee88bbeb9130d7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/56273c95fb2b4a798fee88bbeb9130d7.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7333,
          name: "Boss Vegas Mobile",
          uuid: "e41d4b456cc94ada9e6d98055c734bee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e41d4b456cc94ada9e6d98055c734bee.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7426,
          name: "Buffalo Boost",
          uuid: "5c6a7fb6050e48eba0ad3b0de88d0f3b6b97945b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5c6a7fb6050e48eba0ad3b0de88d0f3b6b97945b.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7427,
          name: "Buffalo Boost Mobile",
          uuid: "c2669c4b3e09ad6b207836ea9c5133ec5e0b55c2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c2669c4b3e09ad6b207836ea9c5133ec5e0b55c2.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7312,
          name: "Caishen's Treasure",
          uuid: "2ab28505c98c4136bdd55722bcb26dcb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/2ab28505c98c4136bdd55722bcb26dcb.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7313,
          name: "Caishen's Treasure Mobile",
          uuid: "5ad8638ae46d4375abd1a483fd968138",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/5ad8638ae46d4375abd1a483fd968138.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7334,
          name: "Calico Jack",
          uuid: "7a7eb23d419c420380b7437c5f61588d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/7a7eb23d419c420380b7437c5f61588d.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7492,
          name: "Calico Jack Jackpot",
          uuid: "63c78e1412ca4a44b71c10cb2a48b416",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/63c78e1412ca4a44b71c10cb2a48b416.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7493,
          name: "Calico Jack Jackpot Mobile",
          uuid: "0ffced1f0957455a85cc43768c60f86e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/0ffced1f0957455a85cc43768c60f86e.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7335,
          name: "Calico Jack Mobile",
          uuid: "2b8d408663c849a791622c32d013f862",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/2b8d408663c849a791622c32d013f862.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7376,
          name: "Christmas Joy",
          uuid: "77273abb749c42a5ae81e96367b2cc6e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/77273abb749c42a5ae81e96367b2cc6e.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7377,
          name: "Christmas Joy Mobile",
          uuid: "e5757c05df7f493ab7d9c7a36cda43dd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e5757c05df7f493ab7d9c7a36cda43dd.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7314,
          name: "Code 243-0",
          uuid: "23e77688874c4c8c86836dbb0fca747e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/23e77688874c4c8c86836dbb0fca747e.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7315,
          name: "Code 243-0 Mobile",
          uuid: "aba0fbf0e4c343c9b2ecdd02627a6b1e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/aba0fbf0e4c343c9b2ecdd02627a6b1e.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7458,
          name: "Copper Diggers",
          uuid: "50fc97f296ab4ae5bb0f53d8438ff41b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/50fc97f296ab4ae5bb0f53d8438ff41b.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7459,
          name: "Copper Diggers Mobile",
          uuid: "a926c8957bdd4f98b4ef0b93c40b763a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/a926c8957bdd4f98b4ef0b93c40b763a.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7308,
          name: "Cosmo Mix",
          uuid: "7e70718fd674457ea9fb0d91f52191c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/7e70718fd674457ea9fb0d91f52191c6.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7309,
          name: "Cosmo Mix Mobile",
          uuid: "80c0021e3822445aaf38308711cbb50f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/80c0021e3822445aaf38308711cbb50f.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7482,
          name: "CosmoMix V2",
          uuid: "ab060954ed9070759808add390695357b8e416d9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/ab060954ed9070759808add390695357b8e416d9.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7483,
          name: "CosmoMix V2 Mobile",
          uuid: "1b0f17bcaf802857fece0fc31b50b0588a677018",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/1b0f17bcaf802857fece0fc31b50b0588a677018.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7370,
          name: "Danza De Los Muertos",
          uuid: "d6c60d4e259a452b82b3e1906848b7de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/d6c60d4e259a452b82b3e1906848b7de.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7371,
          name: "Danza De Los Muertos Mobile",
          uuid: "c106f71a7fcd4956a42a56567746a9a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c106f71a7fcd4956a42a56567746a9a1.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7468,
          name: "Ed Jones & Book of Bastet Deluxe",
          uuid: "1c4262e0532e468986a240b4370ffccf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/1c4262e0532e468986a240b4370ffccf.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7469,
          name: "Ed Jones & Book of Bastet Deluxe Mobile",
          uuid: "1d78a96bc610406588be766ad23d9832",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/1d78a96bc610406588be766ad23d9832.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7466,
          name: "Ed Jones & Book of Bastet Xtreme! Deluxe",
          uuid: "32dbde94923a41fc9abf3aec022fa30a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/32dbde94923a41fc9abf3aec022fa30a.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7467,
          name: "Ed Jones & Book of Bastet Xtreme! Deluxe Mobile",
          uuid: "e67efd98364c4112b8405daa3ca7b0bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e67efd98364c4112b8405daa3ca7b0bf.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7362,
          name: "Ed Jones & Book of Seth",
          uuid: "d5025867155f427089db4f733a7df8e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/d5025867155f427089db4f733a7df8e3.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7363,
          name: "Ed Jones & Book of Seth Mobile",
          uuid: "b6f857de58ca4fc9a3bf3c5ef5c1f4d5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/b6f857de58ca4fc9a3bf3c5ef5c1f4d5.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7432,
          name: "Ed Jones and Book of Seth Xtreme!",
          uuid: "9435c3df5ab8b81d707250d638f84ab55761b90f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9435c3df5ab8b81d707250d638f84ab55761b90f.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7433,
          name: "Ed Jones and Book of Seth Xtreme! Mobile",
          uuid: "0ee1ef59ee44f50eb6a21559e09af21b027394b1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0ee1ef59ee44f50eb6a21559e09af21b027394b1.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7300,
          name: "Egyptian Stone",
          uuid: "e3015a2cf29c422f85cc2eeb28627947",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e3015a2cf29c422f85cc2eeb28627947.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7301,
          name: "Egyptian Stone Mobile",
          uuid: "f6c6344fd40f4fc78033e47383b88e82",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/f6c6344fd40f4fc78033e47383b88e82.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7474,
          name: "Ekeko",
          uuid: "c6d69ae174544006b4782aaed624d5e145978529",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c6d69ae174544006b4782aaed624d5e145978529.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7475,
          name: "Ekeko Mobile",
          uuid: "140759cf64c06cb65777d83dc440f205c50bb8d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/140759cf64c06cb65777d83dc440f205c50bb8d3.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7318,
          name: "El Fuego",
          uuid: "73a101bd987348a7b7a7df3911b4098e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/73a101bd987348a7b7a7df3911b4098e.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7319,
          name: "El Fuego Mobile",
          uuid: "42a57959bb53408c83529f42e8896dbd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/42a57959bb53408c83529f42e8896dbd.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7410,
          name: "Fairy Dust",
          uuid: "43b0b4893c1542438041858fe052a190",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/43b0b4893c1542438041858fe052a190.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7411,
          name: "Fairy Dust Mobile",
          uuid: "5d440267ea0c482b9946b2b6f04de48c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/5d440267ea0c482b9946b2b6f04de48c.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7430,
          name: "Fairy Dust Xtreme!",
          uuid: "acb8c4673f237ecdd94fbf4f6262a53556d00953",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/acb8c4673f237ecdd94fbf4f6262a53556d00953.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7431,
          name: "Fairy Dust Xtreme! Mobile",
          uuid: "4a6b9d983157cf8c5ce89a84884ca912c64bb6bc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4a6b9d983157cf8c5ce89a84884ca912c64bb6bc.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7398,
          name: "Fall of the Beast",
          uuid: "acaca60879df4c9cac880c8f963aef39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/acaca60879df4c9cac880c8f963aef39.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7399,
          name: "Fall of the Beast Mobile",
          uuid: "9662ca09597040d0a3b5d44191223243",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/9662ca09597040d0a3b5d44191223243.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7310,
          name: "Fancy Jungle",
          uuid: "c9c1449dd7c244d3884c82cf26a4baeb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c9c1449dd7c244d3884c82cf26a4baeb.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7311,
          name: "Fancy Jungle Mobile",
          uuid: "d4f7cb6a40444dbf9731e86dd8a41ace",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/d4f7cb6a40444dbf9731e86dd8a41ace.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7364,
          name: "Fortune Dynasty",
          uuid: "a610a071935f485494e35d24716f8ccd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/a610a071935f485494e35d24716f8ccd.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7365,
          name: "Fortune Dynasty Mobile",
          uuid: "13bcf70f25994ec6a53a22e92d48b0c1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/13bcf70f25994ec6a53a22e92d48b0c1.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7336,
          name: "Fruit Cube",
          uuid: "862a9f190f8d47f3b772f202ac120931",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/862a9f190f8d47f3b772f202ac120931.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7337,
          name: "Fruit Cube Mobile",
          uuid: "d031da518ac948398486b4944625f517",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/d031da518ac948398486b4944625f517.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7348,
          name: "Fruit Farm",
          uuid: "9247b7aeae0646d995226cb62cf016e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/9247b7aeae0646d995226cb62cf016e8.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7349,
          name: "Fruit Farm Mobile",
          uuid: "c635ff6129e9410db2b8d721787c0bd1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c635ff6129e9410db2b8d721787c0bd1.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7352,
          name: "Fruit Monster",
          uuid: "99e30e323294485fa06cc79db3f3d4bd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/99e30e323294485fa06cc79db3f3d4bd.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7346,
          name: "Fruit Monster Christmas",
          uuid: "9d445ca0af914816a98391b5917765ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/9d445ca0af914816a98391b5917765ff.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7347,
          name: "Fruit Monster Christmas Mobile",
          uuid: "4a74a08447de465aa47f3933559de1ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/4a74a08447de465aa47f3933559de1ec.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7353,
          name: "Fruit Monster Mobile",
          uuid: "8902a88f400c4d3bae398403962d3d88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/8902a88f400c4d3bae398403962d3d88.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7408,
          name: "Fruity Beats",
          uuid: "73cd2c2f990a450fad3261a9c2931a5a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/73cd2c2f990a450fad3261a9c2931a5a.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7409,
          name: "Fruity Beats Mobile",
          uuid: "72fce0e7b9fe44ff954bbb5f9ca94f72",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/72fce0e7b9fe44ff954bbb5f9ca94f72.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7422,
          name: "Fruity Beats Xtreme!",
          uuid: "030737837d67144f4ea6d56d5558a19563b8eda1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/030737837d67144f4ea6d56d5558a19563b8eda1.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7423,
          name: "Fruity Beats Xtreme! Mobile",
          uuid: "6fa738733d3aab3e30b1ae3920c10f658a3d778a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6fa738733d3aab3e30b1ae3920c10f658a3d778a.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7302,
          name: "Galacnica",
          uuid: "e97b963a56864b20a0ae3ab3e0242e75",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e97b963a56864b20a0ae3ab3e0242e75.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7303,
          name: "Galacnica Mobile",
          uuid: "acc1112b41744947ab3b4ba9545833dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/acc1112b41744947ab3b4ba9545833dc.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7434,
          name: "Galacnica Xmas",
          uuid: "9e8212e7b152f20df21997a654459e3d4f26e811",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9e8212e7b152f20df21997a654459e3d4f26e811.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7435,
          name: "Galacnica Xmas Mobile",
          uuid: "5bfc9692ac35aa2314ff2cd2e11b5cdb9938f3eb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5bfc9692ac35aa2314ff2cd2e11b5cdb9938f3eb.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7456,
          name: "Gift From Santa Blue",
          uuid: "bfbf41eca65446a38340014a0ecb61b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/bfbf41eca65446a38340014a0ecb61b8.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7457,
          name: "Gift From Santa Blue Mobile",
          uuid: "dea9949e58fe4e82ba3f40432995ecc2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/dea9949e58fe4e82ba3f40432995ecc2.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7452,
          name: "Gift From Santa Green",
          uuid: "5442b8c959284ea5b2c274f44ba66e2e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/5442b8c959284ea5b2c274f44ba66e2e.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7453,
          name: "Gift From Santa Green Mobile",
          uuid: "2e288414fc7e4139b7c021457eebcaa4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/2e288414fc7e4139b7c021457eebcaa4.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7450,
          name: "Gift From Santa Red",
          uuid: "2eaeea5b6642476db5b36e2a6e2268c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/2eaeea5b6642476db5b36e2a6e2268c0.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7451,
          name: "Gift From Santa Red Mobile",
          uuid: "c7514809e553495a969ec8a36c18bd93",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c7514809e553495a969ec8a36c18bd93.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7446,
          name: "Gold Diggers",
          uuid: "f30532c6715ed56abc1461c3f4b2302588eac4bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/f30532c6715ed56abc1461c3f4b2302588eac4bc.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7447,
          name: "Gold Diggers Mobile",
          uuid: "71dcaf4da17aaf7a7116f368e1cff1379347fb80",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/71dcaf4da17aaf7a7116f368e1cff1379347fb80.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7420,
          name: "Haunted Chateau",
          uuid: "e9e83c9da9714431918fbfc7a567dfea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e9e83c9da9714431918fbfc7a567dfea.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7421,
          name: "Haunted Chateau Mobile",
          uuid: "a81cf4f667204272b646a8f0bcb4b21a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/a81cf4f667204272b646a8f0bcb4b21a.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7400,
          name: "Helio Luna Revenge",
          uuid: "c1d02a00b65943938fda2ddb0ee8d7ac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c1d02a00b65943938fda2ddb0ee8d7ac.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7401,
          name: "Helio Luna Revenge Mobile",
          uuid: "142fcf4163a645629080101ff50826e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/142fcf4163a645629080101ff50826e0.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7338,
          name: "Holly Molly Hole",
          uuid: "c27b3d6d8bce4217854d9a9586495642",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c27b3d6d8bce4217854d9a9586495642.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7339,
          name: "Holly Molly Hole Mobile",
          uuid: "e8ba2b42bb9c4534a3a7699605340ed7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e8ba2b42bb9c4534a3a7699605340ed7.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7380,
          name: "Infectus",
          uuid: "20236920a53e4315b7d8d5fbd487fe90",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/20236920a53e4315b7d8d5fbd487fe90.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7381,
          name: "Infectus Mobile",
          uuid: "11f789983c1b467097dff6de5afb96bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/11f789983c1b467097dff6de5afb96bb.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7454,
          name: "Krampus",
          uuid: "f44930e1731241068c5b05156fb87539",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/f44930e1731241068c5b05156fb87539.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7455,
          name: "Krampus Mobile",
          uuid: "3e44fd9c51424674a3dca5e02b7435c1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/3e44fd9c51424674a3dca5e02b7435c1.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7320,
          name: "Lama Glama",
          uuid: "29c3efdd4f6d4626a5ac67b882a4c647",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/29c3efdd4f6d4626a5ac67b882a4c647.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7321,
          name: "Lama Glama Mobile",
          uuid: "aae6157a2cca45eb8a875a130c4a8a32",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/aae6157a2cca45eb8a875a130c4a8a32.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7484,
          name: "Lama Glama V2",
          uuid: "66f3ffde48e266641525b9050e1a520be1340563",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/66f3ffde48e266641525b9050e1a520be1340563.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7485,
          name: "Lama Glama V2 Mobile",
          uuid: "bc33c079792a0b59645934c023be404ab240d5cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/bc33c079792a0b59645934c023be404ab240d5cc.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7366,
          name: "Lazy Bones Freeway",
          uuid: "6cac06fe3dd2431dbd990fbf0777b409",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/6cac06fe3dd2431dbd990fbf0777b409.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7367,
          name: "Lazy Bones Freeway Mobile",
          uuid: "6477c6a45d034717a506af5073c28a30",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/6477c6a45d034717a506af5073c28a30.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7462,
          name: "Love Balloon",
          uuid: "e7dc14959d32475bad4e3edffb16ce1a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e7dc14959d32475bad4e3edffb16ce1a.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7463,
          name: "Love Balloon Mobile",
          uuid: "ef20bc86c079408a812d6f94b3b77173",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/ef20bc86c079408a812d6f94b3b77173.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7460,
          name: "Love Birds",
          uuid: "f4523d12f59b4d99b98f34f7d13a4695",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/f4523d12f59b4d99b98f34f7d13a4695.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7461,
          name: "Love Birds Mobile",
          uuid: "c0ff69817e904426a73f91033f40f0c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c0ff69817e904426a73f91033f40f0c0.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7378,
          name: "Madame Moustache",
          uuid: "ee320611070c4ec28b1f8937e29c988d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/ee320611070c4ec28b1f8937e29c988d.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7379,
          name: "Madame Moustache Mobile",
          uuid: "5a4f6121eeba4ed895a268248ea21e88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/5a4f6121eeba4ed895a268248ea21e88.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7396,
          name: "Mafioso",
          uuid: "5d73314e385745c2a0541cbb8ba527b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/5d73314e385745c2a0541cbb8ba527b0.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7486,
          name: "Mafioso Deluxe",
          uuid: "4a6922a77d724520a4436e3db1103158",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/4a6922a77d724520a4436e3db1103158.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7487,
          name: "Mafioso Deluxe Mobile",
          uuid: "fab7c31cf17e4dd08f79a566e686520d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/fab7c31cf17e4dd08f79a566e686520d.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7397,
          name: "Mafioso Mobile",
          uuid: "6f5b6868941c4d65a3853bfa418fd694",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/6f5b6868941c4d65a3853bfa418fd694.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7354,
          name: "Mahala",
          uuid: "4a0f994c9f494c4793645e0442f99429",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/4a0f994c9f494c4793645e0442f99429.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7355,
          name: "Mahala Mobile",
          uuid: "d2c942e83a7c48059f568fda7218f33a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/d2c942e83a7c48059f568fda7218f33a.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7406,
          name: "Mayanera Return",
          uuid: "99359576813b41b1b1d1b999713aca97",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/99359576813b41b1b1d1b999713aca97.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7407,
          name: "Mayanera Return Mobile",
          uuid: "227892906b6447d9bd2b38dd5cbb5b99",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/227892906b6447d9bd2b38dd5cbb5b99.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7442,
          name: "Meteoroid",
          uuid: "08d59c2d01b4711d49d3a5e16870273929d7a77d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/08d59c2d01b4711d49d3a5e16870273929d7a77d.jpg",
          type: "crash",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7490,
          name: "Meteoroid Deluxe",
          uuid: "ad5d07ae5aaf4fa89d31f85462188a42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/ad5d07ae5aaf4fa89d31f85462188a42.png",
          type: "crash",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7491,
          name: "Meteoroid Deluxe Mobile",
          uuid: "94273a243af24d0c94a608e27aeb62a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/94273a243af24d0c94a608e27aeb62a1.png",
          type: "crash",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7443,
          name: "Meteoroid Mobile",
          uuid: "1c9043015e872edfe2ab924c7cdafb5f6038b043",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/1c9043015e872edfe2ab924c7cdafb5f6038b043.jpg",
          type: "crash",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7480,
          name: "Moon Scratch",
          uuid: "05c2145c9050ebf628c9a3f9628b13b1a6f0eca8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/05c2145c9050ebf628c9a3f9628b13b1a6f0eca8.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7481,
          name: "Moon Scratch Mobile",
          uuid: "e1c3d6591952e62ce1a24c937e11247b59e176b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e1c3d6591952e62ce1a24c937e11247b59e176b2.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7304,
          name: "Mr. Hat: Before The Sunrise",
          uuid: "0a951dfa11a44219a30cf4153fbaac67",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/0a951dfa11a44219a30cf4153fbaac67.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7305,
          name: "Mr. Hat: Before The Sunrise Mobile",
          uuid: "9ba2381e478840fa8ef01fe99124c376",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/9ba2381e478840fa8ef01fe99124c376.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7306,
          name: "Mr. Hat: Sunshine",
          uuid: "a45ec9d067da4d839c91d90515cc730c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/a45ec9d067da4d839c91d90515cc730c.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7307,
          name: "Mr. Hat: Sunshine Mobile",
          uuid: "ff67bd1976bc420682ca5ce9a7ed3ba6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/ff67bd1976bc420682ca5ce9a7ed3ba6.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7360,
          name: "Need For Space",
          uuid: "9fb27bd4005c4800b6608da4ea06ce5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/9fb27bd4005c4800b6608da4ea06ce5c.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7361,
          name: "Need For Space Mobile",
          uuid: "c4ac3ad8340644c4803e11ea81a6c162",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c4ac3ad8340644c4803e11ea81a6c162.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7372,
          name: "Nice Cream",
          uuid: "6bca179c7c684f4ca231b3e5ad56be69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/6bca179c7c684f4ca231b3e5ad56be69.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7373,
          name: "Nice Cream Mobile",
          uuid: "3d9f7561409d4ed7887064afd0e9d1be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/3d9f7561409d4ed7887064afd0e9d1be.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7438,
          name: "Patanjali Chakra",
          uuid: "11c3c7b203633a446f148b17908ce73e74f83c48",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/11c3c7b203633a446f148b17908ce73e74f83c48.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7439,
          name: "Patanjali Chakra Mobile",
          uuid: "af9a1a024ebd745886b6d7f877599fd20ffdfb2e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/af9a1a024ebd745886b6d7f877599fd20ffdfb2e.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7340,
          name: "Plata O Plomo",
          uuid: "e59c63ea83b54ef6911ec12c5cc7e5db",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e59c63ea83b54ef6911ec12c5cc7e5db.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7496,
          name: "Plata o Plomo Deluxe",
          uuid: "59ec745e477f404bb30d69d5c622e9b9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/59ec745e477f404bb30d69d5c622e9b9.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7497,
          name: "Plata o Plomo Deluxe Mobile",
          uuid: "cb718237e11647b7ab119f3a0e6555c3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/cb718237e11647b7ab119f3a0e6555c3.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7341,
          name: "Plata O Plomo Mobile",
          uuid: "78babc7d2c8145d3b62e71802eb45447",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/78babc7d2c8145d3b62e71802eb45447.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7344,
          name: "Poseidon",
          uuid: "c6ae71339f9e4104b5fc35de73320d7f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c6ae71339f9e4104b5fc35de73320d7f.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7345,
          name: "Poseidon Mobile",
          uuid: "7f1049ed57564a4f9669d87e3f1ed987",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/7f1049ed57564a4f9669d87e3f1ed987.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7440,
          name: "Poseidon Xtreme",
          uuid: "3bbe1c416d98aa1f95c32fb6636cc4cc0b847e34",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3bbe1c416d98aa1f95c32fb6636cc4cc0b847e34.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7441,
          name: "Poseidon Xtreme Mobile",
          uuid: "0cdd30327447f8e75e4dd2402d0d5fefc9aa9cc9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0cdd30327447f8e75e4dd2402d0d5fefc9aa9cc9.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7330,
          name: "Real Life Super Heroes",
          uuid: "170286882b0e4b789702f0d41a16e536",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/170286882b0e4b789702f0d41a16e536.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7331,
          name: "Real Life Super Heroes Mobile",
          uuid: "91721d0221ca42fb951ab58341bf80dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/91721d0221ca42fb951ab58341bf80dc.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7392,
          name: "RLSH Bonus",
          uuid: "5153a0efd3f44a1baf45653657e1a660",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/5153a0efd3f44a1baf45653657e1a660.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7393,
          name: "RLSH Bonus Mobile",
          uuid: "5ecd37f812ca4d688f3cb615a8e25aee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/5ecd37f812ca4d688f3cb615a8e25aee.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7322,
          name: "RoboLand",
          uuid: "b1bcb5d74d0c4f4182f300afb8c86fca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/b1bcb5d74d0c4f4182f300afb8c86fca.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7323,
          name: "RoboLand Mobile",
          uuid: "c691da6d71434888b099dee59302d504",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c691da6d71434888b099dee59302d504.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7316,
          name: "Rooster",
          uuid: "de41e9badabe46259a3d51eb3906a05f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/de41e9badabe46259a3d51eb3906a05f.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7317,
          name: "Rooster Mobile",
          uuid: "09c08261292f4ebb951d8215341622b4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/09c08261292f4ebb951d8215341622b4.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7358,
          name: "Rosemary",
          uuid: "68b70c887a88436dbfc78b66d8f202ba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/68b70c887a88436dbfc78b66d8f202ba.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7359,
          name: "Rosemary Mobile",
          uuid: "82a76262a1394ad89c532f328f0a65ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/82a76262a1394ad89c532f328f0a65ec.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7448,
          name: "Scratch or Treat",
          uuid: "a982e65e7c4997ff66395a5964e22e5798e01533",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/a982e65e7c4997ff66395a5964e22e5798e01533.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7449,
          name: "Scratch or Treat Mobile",
          uuid: "77568104096d36c6055ed63b5580dc9ba2ae0957",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/77568104096d36c6055ed63b5580dc9ba2ae0957.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7390,
          name: "Shaolin Twins",
          uuid: "39801ae8682540c39925e399a722a1ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/39801ae8682540c39925e399a722a1ff.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7391,
          name: "Shaolin Twins Mobile",
          uuid: "07d89218fc9243d3953d9e1916644571",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/07d89218fc9243d3953d9e1916644571.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7464,
          name: "Silver Diggers",
          uuid: "3df63b225ca54cb0988807fc5b670af5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/3df63b225ca54cb0988807fc5b670af5.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7465,
          name: "Silver Diggers Mobile",
          uuid: "83f1ca28c2ef49058a53dc0b9bc24afd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/83f1ca28c2ef49058a53dc0b9bc24afd.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7428,
          name: "Sizzling Blaze",
          uuid: "da9abf4f35de4cb8829e5e8e9be4e9fde13812d0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/da9abf4f35de4cb8829e5e8e9be4e9fde13812d0.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7488,
          name: "Sizzling Blaze Deluxe",
          uuid: "e4e998235e874918b977725a3d6586c0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e4e998235e874918b977725a3d6586c0.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7489,
          name: "Sizzling Blaze Deluxe Mobile",
          uuid: "2f8e9b5d1adf41159b2336d427a77227",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/2f8e9b5d1adf41159b2336d427a77227.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7444,
          name: "Sizzling Blaze Jackpot",
          uuid: "e9b7d4bd5f6d069f936dfe6a09f42b6f1d4ef815",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e9b7d4bd5f6d069f936dfe6a09f42b6f1d4ef815.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7472,
          name: "Sizzling Blaze Jackpot Deluxe",
          uuid: "c4628be670a242c1992a6b92581dd561",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c4628be670a242c1992a6b92581dd561.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7473,
          name: "Sizzling Blaze Jackpot Deluxe Mobile",
          uuid: "3f26889ef7ff4772b53d50877d02776b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/3f26889ef7ff4772b53d50877d02776b.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7445,
          name: "Sizzling Blaze Jackpot Mobile",
          uuid: "bcb639e1e0029e1f9e2e4b479e12725c5f878461",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bcb639e1e0029e1f9e2e4b479e12725c5f878461.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7429,
          name: "Sizzling Blaze Mobile",
          uuid: "87bd51b5bdd8f8f7ec4bb151e212f9e605dedb64",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/87bd51b5bdd8f8f7ec4bb151e212f9e605dedb64.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7494,
          name: "Sizzling Neon Jackpot",
          uuid: "9fa23b075b6d4234a82243b82201718d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/9fa23b075b6d4234a82243b82201718d.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7495,
          name: "Sizzling Neon Jackpot Mobile",
          uuid: "c9a203ceb42147aaa6ad642d789b189f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c9a203ceb42147aaa6ad642d789b189f.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7402,
          name: "Space Oddity",
          uuid: "148657611f0144a7b60189538d0a3c01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/148657611f0144a7b60189538d0a3c01.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7403,
          name: "Space Oddity Mobile",
          uuid: "4a5b978f7a8b494f8055100cf1e76905",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/4a5b978f7a8b494f8055100cf1e76905.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7368,
          name: "Spin Circus",
          uuid: "abc8c1518d6647aa9aa54fc6e3e4ef68",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/abc8c1518d6647aa9aa54fc6e3e4ef68.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7369,
          name: "Spin Circus Mobile",
          uuid: "cd509999f5af41d792fd03e3507d11e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/cd509999f5af41d792fd03e3507d11e9.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7374,
          name: "SPRING INVADERS",
          uuid: "f20548371d424194bd98d925ab2900a4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/f20548371d424194bd98d925ab2900a4.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7375,
          name: "SPRING INVADERS Mobile",
          uuid: "c5296e1f7f05400e8b1a0aad4f233f62",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c5296e1f7f05400e8b1a0aad4f233f62.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7394,
          name: "Starbatch",
          uuid: "e7e2c64061624bac8db4c9d322f96b6b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e7e2c64061624bac8db4c9d322f96b6b.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7395,
          name: "Starbatch Mobile",
          uuid: "c2865ad35bc84228811a81832e5dedad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/c2865ad35bc84228811a81832e5dedad.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7388,
          name: "Sushi House",
          uuid: "6d47c1ac29d64df29c9af25015dd1ff3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/6d47c1ac29d64df29c9af25015dd1ff3.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7389,
          name: "Sushi House Mobile",
          uuid: "bb5a9d23c76a41b7a9cc2d7cf316e0e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/bb5a9d23c76a41b7a9cc2d7cf316e0e1.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7386,
          name: "Synthway",
          uuid: "d6d93bc170d74741980de4b0c263961d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/d6d93bc170d74741980de4b0c263961d.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7387,
          name: "Synthway Mobile",
          uuid: "14bae2d29d3145c781544b08ed5e213e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/14bae2d29d3145c781544b08ed5e213e.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7350,
          name: "The Golden Egg",
          uuid: "f3c0e35ad71b4deebbbeea4709b4f47c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/f3c0e35ad71b4deebbbeea4709b4f47c.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7356,
          name: "The Golden Egg Easter",
          uuid: "41f401b5b4924c19bfd440bfb251a62b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/41f401b5b4924c19bfd440bfb251a62b.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7357,
          name: "The Golden Egg Easter Mobile",
          uuid: "b286fda310884393b35095bcd6086397",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/b286fda310884393b35095bcd6086397.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7351,
          name: "The Golden Egg Mobile",
          uuid: "2eb57d80dc764bb593e761bfbf961160",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/2eb57d80dc764bb593e761bfbf961160.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7478,
          name: "What The Duck",
          uuid: "e2e70b567d57f4f2c355e6c1f42a7ec3134c0116",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/e2e70b567d57f4f2c355e6c1f42a7ec3134c0116.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7479,
          name: "What The Duck Mobile",
          uuid: "816e517ea3476fe93f3db49f93e85abdc89ef0ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/816e517ea3476fe93f3db49f93e85abdc89ef0ec.png",
          type: "scratch card",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7328,
          name: "Wild Cat",
          uuid: "093bed18bfbb4fb6888be85b9a75b612",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/093bed18bfbb4fb6888be85b9a75b612.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7329,
          name: "Wild Cat Mobile",
          uuid: "d704e92891d148c59c88679625490440",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/d704e92891d148c59c88679625490440.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7382,
          name: "Wild Valentines",
          uuid: "bc5d0643998c4b31b85701f9bd68f345",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/bc5d0643998c4b31b85701f9bd68f345.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7383,
          name: "Wild Valentines Mobile",
          uuid: "6e74f5bc4b96431fb90b82a1c9a6157a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/6e74f5bc4b96431fb90b82a1c9a6157a.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7412,
          name: "Wild Valentines Xmas",
          uuid: "2aedf5d715a6457090a6233ecf41e3e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/2aedf5d715a6457090a6233ecf41e3e8.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7413,
          name: "Wild Valentines Xmas Mobile",
          uuid: "89e0da72f1b84260bc1011f43b878549",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/89e0da72f1b84260bc1011f43b878549.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7418,
          name: "World of Gods: Niflheim",
          uuid: "b5378f571c2a4914a519041f03e3776b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/b5378f571c2a4914a519041f03e3776b.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7419,
          name: "World of Gods: Niflheim Mobile",
          uuid: "3970e379d50f4dd1bb6ac22cd1d5e659",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/3970e379d50f4dd1bb6ac22cd1d5e659.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7414,
          name: "Zombcity",
          uuid: "2b4b9b6836cd4aaba0f48ed48f8e2c6a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/2b4b9b6836cd4aaba0f48ed48f8e2c6a.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7415,
          name: "Zombcity Mobile",
          uuid: "3ff41cf546664fe086d5dfbe23b18357",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/7ca5e71c8cea77b2f8a2c5a6d80d2564/Spinmatic/3ff41cf546664fe086d5dfbe23b18357.png",
          type: "slots",
          provider: "Spinmatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 152,
      name: "Spinomenal",
      providerId: 17,
      providerName: "Spinomenal",
      games: [
        {
          game_id: 670,
          name: "4Horsemen",
          uuid: "77a989b9ac554226a27a7b736c3ccfd8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/77a989b9ac554226a27a7b736c3ccfd8.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 671,
          name: "AztecSpell-10E",
          uuid: "6edfc8b8fdd9425ebd40b97c738c21b6",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/6edfc8b8fdd9425ebd40b97c738c21b6.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 672,
          name: "Beer Collection - 10 Lines",
          uuid: "79ac88cbe3094e0ea923362714053299",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/79ac88cbe3094e0ea923362714053299.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 673,
          name: "Beer Collection - 20 Lines",
          uuid: "f4bf75acd7d1406388a3482124549f4f",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/f4bf75acd7d1406388a3482124549f4f.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 674,
          name: "Blue Panther",
          uuid: "384fac1575ad4ceca20c0545e07335aa",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/384fac1575ad4ceca20c0545e07335aa.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 675,
          name: "Blue Panther Christmas Edition",
          uuid: "df7bfc9ba84546d3ac28a144da9af0fa",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/df7bfc9ba84546d3ac28a144da9af0fa.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 67,
          name: "Book Of Aphrodite - The Golden Era",
          uuid: "ddac24f2264e42d5819df493e2d061eb",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/ddac24f2264e42d5819df493e2d061eb.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 68,
          name: "Book Of Aphrodite - The Love Spell",
          uuid: "7a6b8f78dccb41ea82670b31d484a61d",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/7a6b8f78dccb41ea82670b31d484a61d.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 69,
          name: "Book Of Baba Yaga",
          uuid: "a7e8e703663241028e5fa2b95575b1a6",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/a7e8e703663241028e5fa2b95575b1a6.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 70,
          name: "Book Of Baba Yaga - Winter Spell",
          uuid: "959e4f98898544f8a1e93eeed9bdbd17",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/959e4f98898544f8a1e93eeed9bdbd17.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 71,
          name: "Book Of Champions",
          uuid: "8db5789bedfa4934b2310dad03a9dee2",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/8db5789bedfa4934b2310dad03a9dee2.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 72,
          name: "Book Of Champions - World Glory",
          uuid: "7b0456045bba4c47b459bbf805b26cc6",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/7b0456045bba4c47b459bbf805b26cc6.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 73,
          name: "Book Of Clovers",
          uuid: "56aa50221cea4f8392a1202f6e608e17",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/56aa50221cea4f8392a1202f6e608e17.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 74,
          name: "Book Of Demi Gods 3",
          uuid: "271303fa20e341d8b99754582af13616",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/271303fa20e341d8b99754582af13616.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 75,
          name: "Book Of Demi Gods II",
          uuid: "3a5a051386744b98bc7813576f53e149",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/3a5a051386744b98bc7813576f53e149.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 76,
          name: "Book Of Demi Gods II - Christmas Edition",
          uuid: "09e71590962a4c4ba67183e77077c050",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/09e71590962a4c4ba67183e77077c050.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 77,
          name: "Book Of Demi Gods IV",
          uuid: "5677c1ff9fa74acba355a023ef9269ed",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/5677c1ff9fa74acba355a023ef9269ed.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 78,
          name: "Book Of Easter Piggy Bank",
          uuid: "d8b0e97e61a947f4aba1301908a3976a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/d8b0e97e61a947f4aba1301908a3976a.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 79,
          name: "Book Of Irish Treasures",
          uuid: "cb5817d5374047e2834079429f8ddd51",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/cb5817d5374047e2834079429f8ddd51.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 80,
          name: "Book Of Lucky Jack - Baba Yaga’s Tales",
          uuid: "e53e5a260aca4c70a3e8017d5a652a85",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/e53e5a260aca4c70a3e8017d5a652a85.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 81,
          name: "Book of Panther",
          uuid: "c67b0ee888e14a73a45a82fe8bb8cc0b",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/c67b0ee888e14a73a45a82fe8bb8cc0b.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 82,
          name: "Book of Piggy Bank",
          uuid: "c2c424bdc9f34a529f19f55f15fafe4a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/c2c424bdc9f34a529f19f55f15fafe4a.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 83,
          name: "Book of Rampage",
          uuid: "c962c9a34f22476daf1f39a5a5f185bf",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/c962c9a34f22476daf1f39a5a5f185bf.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 84,
          name: "Book of Rampage - Christmas Edition",
          uuid: "791071ba80934aa0b640026b90e65fb2",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/791071ba80934aa0b640026b90e65fb2.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 85,
          name: "Book Of Rebirth",
          uuid: "47cfe0c811c74ee2aa19ed16e1181057",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/47cfe0c811c74ee2aa19ed16e1181057.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 86,
          name: "Book Of Rebirth - Reloaded",
          uuid: "8538667f03c840b2bcbd08a385986d52",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/8538667f03c840b2bcbd08a385986d52.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 87,
          name: "Book Of Sirens",
          uuid: "7c755fed56b741dfb599b5e365e55e61",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/7c755fed56b741dfb599b5e365e55e61.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 88,
          name: "Book Of Sirens - The Golden Era",
          uuid: "8b5627f9c82d4d6288bacfec150d1f76",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/8b5627f9c82d4d6288bacfec150d1f76.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 89,
          name: "Book Of Tribes",
          uuid: "81e9649390b846a6a753a2296a65abd4",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/81e9649390b846a6a753a2296a65abd4.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 94,
          name: "Boxing Ring Champions",
          uuid: "6ee29941659a4315acb41dc07a8f1563",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/6ee29941659a4315acb41dc07a8f1563.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 676,
          name: "Colossus Fruits",
          uuid: "f466c4d1dbf9487aa67ebde1ff60b2e1",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/f466c4d1dbf9487aa67ebde1ff60b2e1.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 677,
          name: "Colossus Fruits - Christmas Edition",
          uuid: "0f51a5e888a64ef1a9626999371f9579",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/0f51a5e888a64ef1a9626999371f9579.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 678,
          name: "Dark Wolf",
          uuid: "5bba9d65bb2d4295ace29b20df6bb510",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/5bba9d65bb2d4295ace29b20df6bb510.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 679,
          name: "Frozen Fruits - Chase'N’Win",
          uuid: "da89debbc5664884b5216538964f8f31",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/da89debbc5664884b5216538964f8f31.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 155,
          name: "Fruits Collection - 40 Lines",
          uuid: "a41d1f0e5db0449bbbd1adf934aeb374",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/a41d1f0e5db0449bbbd1adf934aeb374.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 156,
          name: "Fruits Collection – 10 Lines",
          uuid: "a17042cfdd874cf5a968ab52567a2e3f",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/a17042cfdd874cf5a968ab52567a2e3f.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 157,
          name: "Fruits On Ice Collection 10 Lines",
          uuid: "37883cce24c445b4b5c7ee2269569874",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/37883cce24c445b4b5c7ee2269569874.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 158,
          name: "Fruits On Ice Collection 20 Lines",
          uuid: "793efe01e71546bd87beb55b50e577eb",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/793efe01e71546bd87beb55b50e577eb.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 159,
          name: "Fruits On Ice Collection 30 Lines",
          uuid: "f8956b332ac5435c80ef75c63e41ca9f",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/f8956b332ac5435c80ef75c63e41ca9f.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 680,
          name: "Irish Treasures",
          uuid: "49648994a5804d26b030cc1d2437adf6",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/49648994a5804d26b030cc1d2437adf6.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 160,
          name: "Joker Win",
          uuid: "fce45762911f4339a3a02e21263e8efa",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/fce45762911f4339a3a02e21263e8efa.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 681,
          name: "Lucky Jack - Book Of Rebirth",
          uuid: "cb6692b4970145abba91e117bb73d52c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/cb6692b4970145abba91e117bb73d52c.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 682,
          name: "Lucky Jack - Book Of Rebirth - Egyptian Darkness",
          uuid: "ac9882f4e4ce4efaa907a54e76576029",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/ac9882f4e4ce4efaa907a54e76576029.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 683,
          name: "Lucky Mr. Patrick",
          uuid: "ba53581367354278a70087324b96744e",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/ba53581367354278a70087324b96744e.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 684,
          name: "Majestic King",
          uuid: "76b340a73ee246f598ee6c62efdcdf75",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/76b340a73ee246f598ee6c62efdcdf75.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 685,
          name: "MajesticKing-EE",
          uuid: "7088f598a7d942eb84693299e775ce41",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/7088f598a7d942eb84693299e775ce41.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 92,
          name: "MMA Champions",
          uuid: "e3564caca7804448a586858c777fa012",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/e3564caca7804448a586858c777fa012.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 686,
          name: "Nights of Egypt",
          uuid: "03063440613a447fa80dc7f70157eeb3",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/03063440613a447fa80dc7f70157eeb3.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 687,
          name: "Nights Of Magic",
          uuid: "2200561696544d7ebcaee94ac34d152c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/2200561696544d7ebcaee94ac34d152c.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 688,
          name: "Savannah's Queen",
          uuid: "0f28ab10a09049fbbce81086c8d10500",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/0f28ab10a09049fbbce81086c8d10500.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 689,
          name: "Story Of Medusa",
          uuid: "044f702afbf54217970688e3c9161619",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/044f702afbf54217970688e3c9161619.png",
          type: "slots",
          provider: "Spinomenal",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 153,
      name: "Spribe",
      providerId: 12,
      providerName: "Spribe",
      games: [
        {
          game_id: 54,
          name: "Aviator",
          uuid: "88e106622db162f752e9fbf6fc492631f0f7c6ba",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/88e106622db162f752e9fbf6fc492631f0f7c6ba.png",
          type: "crash",
          provider: "Spribe",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 118,
          name: "Dice",
          uuid: "bbcce2c3acf54d68b0757fac314fd47f",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/bbcce2c3acf54d68b0757fac314fd47f.png",
          type: "minigame",
          provider: "Spribe",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 117,
          name: "Goal",
          uuid: "3106fab94e9a4c47b80a33bafb5da452",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/3106fab94e9a4c47b80a33bafb5da452.png",
          type: "minigame",
          provider: "Spribe",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 116,
          name: "Hilo",
          uuid: "d7d7988f2e434cbc87b70ca79505f490",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/d7d7988f2e434cbc87b70ca79505f490.png",
          type: "minigame",
          provider: "Spribe",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 115,
          name: "Hotline",
          uuid: "fc27d12f03ed45fa847e12fc3f86b2f4",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/fc27d12f03ed45fa847e12fc3f86b2f4.png",
          type: "minigame",
          provider: "Spribe",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 114,
          name: "Keno",
          uuid: "edeeec0277ad491ebd842faf5e6d51e5",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/edeeec0277ad491ebd842faf5e6d51e5.png",
          type: "minigame",
          provider: "Spribe",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 113,
          name: "Mines",
          uuid: "c96f30074d9d43fc95661919c1358eb5",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/c96f30074d9d43fc95661919c1358eb5.png",
          type: "minigame",
          provider: "Spribe",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 112,
          name: "Mini Roulette",
          uuid: "100d5a3dec664588a46fbbf0e1498037",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/100d5a3dec664588a46fbbf0e1498037.png",
          type: "minigame",
          provider: "Spribe",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 111,
          name: "Plinko",
          uuid: "a475050d2627431a9fbe9739a19ddf80",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/a475050d2627431a9fbe9739a19ddf80.png",
          type: "minigame",
          provider: "Spribe",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 154,
      name: "SuperlottoFast",
      providerId: 51,
      providerName: "SuperlottoFast",
      games: [
        {
          game_id: 7530,
          name: "5x",
          uuid: "5dfdde7c56ab4973ba6ae7a139101b28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/5dfdde7c56ab4973ba6ae7a139101b28.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7542,
          name: "Anubis",
          uuid: "41e2d7beb3284613a3fa564b23d560a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/41e2d7beb3284613a3fa564b23d560a5.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7498,
          name: "Aquarium",
          uuid: "51dfd00c4d1c409a98a4e40056f4d790",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/51dfd00c4d1c409a98a4e40056f4d790.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7499,
          name: "Arabeska",
          uuid: "37defe4b8f0d40d4a70f4810224b52a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/37defe4b8f0d40d4a70f4810224b52a9.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7514,
          name: "Awesome Money Fast",
          uuid: "044615a7ae1242939fc2c628765341ee",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/044615a7ae1242939fc2c628765341ee.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7525,
          name: "Bankman",
          uuid: "2b5f903efae4460d91d03d4471e751a8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/2b5f903efae4460d91d03d4471e751a8.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7506,
          name: "Basketball",
          uuid: "7cb72fbb5713492d897091560488c6fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/7cb72fbb5713492d897091560488c6fa.png",
          type: "other",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7501,
          name: "Battleships",
          uuid: "cdc63048ea12410195c8d1f457d19ccd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/cdc63048ea12410195c8d1f457d19ccd.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7517,
          name: "Cards",
          uuid: "3808671851ba4da6b9d5f02168a953e3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/3808671851ba4da6b9d5f02168a953e3.png",
          type: "card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7519,
          name: "Chef Menu",
          uuid: "abf27990f0e64183a7e73b6c4b4f05fb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/abf27990f0e64183a7e73b6c4b4f05fb.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7505,
          name: "Christmas",
          uuid: "90a204cf420243e3ae5354d61bf43ba7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/90a204cf420243e3ae5354d61bf43ba7.png",
          type: "other",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7522,
          name: "Cowboy",
          uuid: "2a17299cebce4c83a090b74dc26699ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/2a17299cebce4c83a090b74dc26699ec.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7535,
          name: "Diamonds",
          uuid: "fe5196bc6844433b8f23aed6d84cfe04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/fe5196bc6844433b8f23aed6d84cfe04.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7521,
          name: "Diamonds Valley",
          uuid: "1e58c0e8710545c78dc42f22d36b9008",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/1e58c0e8710545c78dc42f22d36b9008.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7529,
          name: "Double lucky",
          uuid: "81b7bd9c999945cf8c48c91012744f74",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/81b7bd9c999945cf8c48c91012744f74.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7536,
          name: "Dragon's Tomb",
          uuid: "9f6fd8cd65504699898a88587a69b394",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/9f6fd8cd65504699898a88587a69b394.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7547,
          name: "El Porko Mafioso",
          uuid: "265465180c144fe9b81687925d06167e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/265465180c144fe9b81687925d06167e.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7538,
          name: "Flash Wins",
          uuid: "bb5aef4a53444ba0ad4aaed2367c25f2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/bb5aef4a53444ba0ad4aaed2367c25f2.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7510,
          name: "Football",
          uuid: "cd27ad16605e438dae923ace86349040",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/cd27ad16605e438dae923ace86349040.png",
          type: "other",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7550,
          name: "Forest of Spirits",
          uuid: "a8d612003245085568f55e311f746ced6f880a29",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a8d612003245085568f55e311f746ced6f880a29.png",
          type: "instant win",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7512,
          name: "Fortune",
          uuid: "78432d0b6abc44b4b18ac53b0e9c0ad6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/78432d0b6abc44b4b18ac53b0e9c0ad6.png",
          type: "wheel of fortune",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7502,
          name: "Gangsters",
          uuid: "0ea460e38f17448c8936990965f094b5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/0ea460e38f17448c8936990965f094b5.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7508,
          name: "Golden Bank",
          uuid: "2ce4a765663f47d0ac3e0a3844c58bd6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/2ce4a765663f47d0ac3e0a3844c58bd6.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7533,
          name: "Golden Nugget",
          uuid: "ef8c3f8d6372400da41ba8447fe8f027",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/ef8c3f8d6372400da41ba8447fe8f027.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7544,
          name: "Happy Daisies",
          uuid: "53eb8ec5c9994fa6bab47b2befdd478f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/53eb8ec5c9994fa6bab47b2befdd478f.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7513,
          name: "Hockey",
          uuid: "66448b55cfd249219b87ad1537eb4039",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/66448b55cfd249219b87ad1537eb4039.png",
          type: "other",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7515,
          name: "Las Vegas",
          uuid: "2afad306853c4b4ea15162ab3d1ac421",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/2afad306853c4b4ea15162ab3d1ac421.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7507,
          name: "Lucky 7",
          uuid: "f41d53918d254eacba075e1dbe4cd614",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/f41d53918d254eacba075e1dbe4cd614.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7520,
          name: "Lucky Coins",
          uuid: "1e3f3620ca5f469eabdae03d72643f53",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/1e3f3620ca5f469eabdae03d72643f53.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7554,
          name: "Lucky Santa",
          uuid: "0307bff7be247ad0e2a2a1440f2d43de2fffcef5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/0307bff7be247ad0e2a2a1440f2d43de2fffcef5.png",
          type: "other",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7528,
          name: "Magic Pearl",
          uuid: "f6b58987dc924f3ebe6df5736e28d154",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/f6b58987dc924f3ebe6df5736e28d154.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7543,
          name: "Magical Elixir",
          uuid: "b1475d7546f348ccb63efb93c05842b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/b1475d7546f348ccb63efb93c05842b8.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7534,
          name: "Mega",
          uuid: "3cdb56d63b974b03b3099e5ddc856ec4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/3cdb56d63b974b03b3099e5ddc856ec4.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7551,
          name: "Midsummer Night",
          uuid: "b181a828209a06d4f8682a2bcb402059b5323e48",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b181a828209a06d4f8682a2bcb402059b5323e48.png",
          type: "instant win",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7539,
          name: "Money Wheel",
          uuid: "2cd644c98e964fb19428d49ff2eed73d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/2cd644c98e964fb19428d49ff2eed73d.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7524,
          name: "Ocean",
          uuid: "269f381542fd42b0afe1ecb963a5cf41",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/269f381542fd42b0afe1ecb963a5cf41.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7527,
          name: "Pirate",
          uuid: "e3b78d4504da412795b5494b0664534e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/e3b78d4504da412795b5494b0664534e.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7532,
          name: "Planets",
          uuid: "9711ede1874b48c79ea8efa1bfedf6b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/9711ede1874b48c79ea8efa1bfedf6b8.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7504,
          name: "Pyramid",
          uuid: "c38e120ce40140fba13f6f8ef16f112d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/c38e120ce40140fba13f6f8ef16f112d.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7523,
          name: "Pyramid Treasures",
          uuid: "4e9428f3c61745618ef310b125a19200",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/4e9428f3c61745618ef310b125a19200.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7531,
          name: "Quarter Million",
          uuid: "13ed3a56d846439da52282d3c2ce9477",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/13ed3a56d846439da52282d3c2ce9477.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7516,
          name: "Rocky Mocky",
          uuid: "b7f9a341f2fe427b9da66b9d990632e7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/b7f9a341f2fe427b9da66b9d990632e7.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7511,
          name: "Royal Riches Fast",
          uuid: "dd3057a9e99c42a28c7250f52cacb79b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/dd3057a9e99c42a28c7250f52cacb79b.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7500,
          name: "Saloon",
          uuid: "20cad226d42b48ab962a4ab925d47d28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/20cad226d42b48ab962a4ab925d47d28.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7526,
          name: "Spaceships",
          uuid: "ef31f04b6532468daa7e23d99702a9f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/ef31f04b6532468daa7e23d99702a9f6.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7553,
          name: "Spooky Witchcraft",
          uuid: "4a6ab315f7a3a1fcdcdc466f8e5d2ab4a4ea81b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/4a6ab315f7a3a1fcdcdc466f8e5d2ab4a4ea81b2.png",
          type: "other",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7503,
          name: "Super 7",
          uuid: "22e2ac4cb9484a47b054eca83628bb52",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/22e2ac4cb9484a47b054eca83628bb52.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7548,
          name: "Superlotto",
          uuid: "16b8dee37a534a88a84f805dc790903d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/16b8dee37a534a88a84f805dc790903d.png",
          type: "keno",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7545,
          name: "The Charm of Cleopatra",
          uuid: "64e3e677e5724548a3220257da8559ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/64e3e677e5724548a3220257da8559ea.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7549,
          name: "The Shield of Zeus",
          uuid: "c099411ddcaf4191a92528d9568a0719",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/c099411ddcaf4191a92528d9568a0719.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7541,
          name: "Treasure Island",
          uuid: "01e0d3ce5efd4edeafdee55bbb706201",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/01e0d3ce5efd4edeafdee55bbb706201.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7509,
          name: "Vegas",
          uuid: "36c23a4338c0427b97ce25d83487401e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/36c23a4338c0427b97ce25d83487401e.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7540,
          name: "Vegas_2",
          uuid: "354092e643cd4b758dd1c53e00d5dee2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/354092e643cd4b758dd1c53e00d5dee2.png",
          type: "scratch card",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7518,
          name: "Wheel of Fortune",
          uuid: "5fdc18a77b7442028c0f9887bec76cb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/5fdc18a77b7442028c0f9887bec76cb3.png",
          type: "wheel of fortune",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7552,
          name: "Wheel of Magic",
          uuid: "714af394f74045239a67ab822ab3e2e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/714af394f74045239a67ab822ab3e2e6.png",
          type: "other",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7537,
          name: "Winning Calendar",
          uuid: "a83b1723cbfc4e22ba53d954face125c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/a83b1723cbfc4e22ba53d954face125c.png",
          type: "other",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7546,
          name: "Winter Night",
          uuid: "29e4354d8b164e789432235a11e074ba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoFast/29e4354d8b164e789432235a11e074ba.png",
          type: "instant game",
          provider: "SuperlottoFast",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 155,
      name: "SuperlottoSlots",
      providerId: 50,
      providerName: "SuperlottoSlots",
      games: [
        {
          game_id: 7555,
          name: "Aagard the Berserker",
          uuid: "25628cecf51d45d2898f2481a6dbe3ab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/25628cecf51d45d2898f2481a6dbe3ab.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7573,
          name: "Awesome Money",
          uuid: "f1d76a7b6eb2410aa0d3384da344fb2a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/f1d76a7b6eb2410aa0d3384da344fb2a.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7566,
          name: "Bet Garage",
          uuid: "76b5fb3c4f5e46b28b8b5098ce7ffcb7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/76b5fb3c4f5e46b28b8b5098ce7ffcb7.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7558,
          name: "Book of Tale",
          uuid: "5014cc2079db45feabff938f0e8c0100",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/5014cc2079db45feabff938f0e8c0100.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7561,
          name: "Crypto Chain",
          uuid: "258c894db79d4f7cabeff81559494de0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/258c894db79d4f7cabeff81559494de0.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7574,
          name: "Diamonds Theft",
          uuid: "9a384a655fcd4dd78a667c09680fe069",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/9a384a655fcd4dd78a667c09680fe069.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7556,
          name: "Dragon Chaser",
          uuid: "0b550e2d400f4334a54459057db742dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/0b550e2d400f4334a54459057db742dc.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7571,
          name: "Fantasy Gems",
          uuid: "2da31d5feadc42b1963814c968d97a02",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/2da31d5feadc42b1963814c968d97a02.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7576,
          name: "Fierce Vikings",
          uuid: "af95937acb8a4d179bdba544b9430e94",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/af95937acb8a4d179bdba544b9430e94.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7562,
          name: "Fruitocado",
          uuid: "e270a871f431412f8b841f480ed0fa8d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/e270a871f431412f8b841f480ed0fa8d.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7563,
          name: "Gemstone",
          uuid: "cdba661e36144c799177142425b0f207",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/cdba661e36144c799177142425b0f207.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7570,
          name: "Magic Puzzle",
          uuid: "a367b45421824f92b1d169b2a501d46a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/a367b45421824f92b1d169b2a501d46a.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7557,
          name: "Miner",
          uuid: "a8e18a0693d0452290a48db6ab900fb4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/a8e18a0693d0452290a48db6ab900fb4.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7565,
          name: "Muscle Power",
          uuid: "eeff09f9bc0849318eebe02451ab05a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/eeff09f9bc0849318eebe02451ab05a5.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7564,
          name: "Ninja",
          uuid: "f4521a6c6189481dae4a03c95fe28b38",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/f4521a6c6189481dae4a03c95fe28b38.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7560,
          name: "Octazilla",
          uuid: "b4aaad58795f4eb2a5f78196fce84bf7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/b4aaad58795f4eb2a5f78196fce84bf7.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7568,
          name: "Racing Power",
          uuid: "254a1182fff54fcbaad00eb0e3798b8c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/254a1182fff54fcbaad00eb0e3798b8c.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7577,
          name: "Rich Life",
          uuid: "e88846d85fc94684847de27c46a87cd5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/e88846d85fc94684847de27c46a87cd5.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7559,
          name: "Robo Chef",
          uuid: "85492e5b8d314da0b0652f499c82e2e2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/85492e5b8d314da0b0652f499c82e2e2.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7569,
          name: "Royal Riches",
          uuid: "ac62335868664f3fb4db8ce2a30d406d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/ac62335868664f3fb4db8ce2a30d406d.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7567,
          name: "Tasty Win",
          uuid: "126d7563a4fd4e7cbc5aa143ab7acf81",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/126d7563a4fd4e7cbc5aa143ab7acf81.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7572,
          name: "The Great London Voyage",
          uuid: "5f13ca84ed234edd95ccafb42f8f0f5d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/5f13ca84ed234edd95ccafb42f8f0f5d.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7575,
          name: "Untold Treasures",
          uuid: "d52f415f785f4ecf8769d64eb0f4c8a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/d52f415f785f4ecf8769d64eb0f4c8a5.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7578,
          name: "Wicked Joker",
          uuid: "b1a1215161b14de69201fcdcc73dede6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoSlots/b1a1215161b14de69201fcdcc73dede6.png",
          type: "slots",
          provider: "SuperlottoSlots",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 156,
      name: "SuperlottoTV",
      providerId: 52,
      providerName: "SuperlottoTV",
      games: [
        {
          game_id: 7579,
          name: "5 from 36",
          uuid: "5c0c6bc6033a4e299f453b1713cb6fab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoTV/5c0c6bc6033a4e299f453b1713cb6fab.png",
          type: "lottery",
          provider: "SuperlottoTV",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7580,
          name: "7 from 42",
          uuid: "c1a419d8b9b444c78a633c1f5736d332",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoTV/c1a419d8b9b444c78a633c1f5736d332.png",
          type: "lottery",
          provider: "SuperlottoTV",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7581,
          name: "Dice",
          uuid: "b6cd411a71ac487cb209c3107c17f6d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/be9ccd37c82dbd3e31e2aed7f8dfce53/SuperlottoTV/b6cd411a71ac487cb209c3107c17f6d3.png",
          type: "lottery",
          provider: "SuperlottoTV",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 157,
      name: "Thunderkick",
      providerId: 20,
      providerName: "Thunderkick",
      games: [
        {
          game_id: 7739,
          name: "12 Bolts of Thunder",
          uuid: "d9e9879ea754405e8809ef4d3f580eb3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/d9e9879ea754405e8809ef4d3f580eb3.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7738,
          name: "12 Bolts of Thunder Mobile",
          uuid: "c891cd960dba46dcb12092d6866a9244",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/c891cd960dba46dcb12092d6866a9244.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7582,
          name: "1429 Uncharted Seas",
          uuid: "7378e1c89f1ed9e9dd0893185af911692cd7c109",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7378e1c89f1ed9e9dd0893185af911692cd7c109.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7625,
          name: "1429 Uncharted Seas Mobile",
          uuid: "bd1c170c48c7f5c5bedab4254b6c0b6ce0469418",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bd1c170c48c7f5c5bedab4254b6c0b6ce0469418.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7583,
          name: "Arcader",
          uuid: "0a4d191212ffc4f8c4e9764aa0d640398af8a56b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0a4d191212ffc4f8c4e9764aa0d640398af8a56b.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7613,
          name: "Arcader Mobile",
          uuid: "49f27ae7ba1329ec8a9d612242cd6dd0120b4044",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/49f27ae7ba1329ec8a9d612242cd6dd0120b4044.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7584,
          name: "Babushkas",
          uuid: "0f06b62de3786bbf5f4f897c265c285844059c42",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0f06b62de3786bbf5f4f897c265c285844059c42.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7615,
          name: "Babushkas Mobile",
          uuid: "90797692ef69d103b51147e9aa60dcf3a7308a1d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/90797692ef69d103b51147e9aa60dcf3a7308a1d.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7585,
          name: "Barber Shop Uncut",
          uuid: "b28f9a42b34231f805d994d48fe205f6a42ebc23",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b28f9a42b34231f805d994d48fe205f6a42ebc23.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7633,
          name: "Barber Shop Uncut Mobile",
          uuid: "babc7a7b661a0162bfbb95ad10e8ed56d6a10a32",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/babc7a7b661a0162bfbb95ad10e8ed56d6a10a32.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7678,
          name: "Baron Bloodmore and the Crimson Castle",
          uuid: "ef9c41a012035475eb982dbee5f872936f827d35",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ef9c41a012035475eb982dbee5f872936f827d35.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7679,
          name: "Baron Bloodmore And The Crimson Castle Mobile",
          uuid: "5e790e5e5fd9dd2f8a00e4a524259ffdf10b1693",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5e790e5e5fd9dd2f8a00e4a524259ffdf10b1693.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7737,
          name: "Beat the Beast Dragon's Wrath",
          uuid: "046f45b638bc42238753eb8c0e61cf88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/046f45b638bc42238753eb8c0e61cf88.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7736,
          name: "Beat the Beast Dragon's Wrath Mobile",
          uuid: "123b31a1fa2c42babc6ae9543d313580",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/123b31a1fa2c42babc6ae9543d313580.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7660,
          name: "Beat the Beast: CERBERUS' INFERNO",
          uuid: "30d5502d42824fabd0a410e529c2e4b41ed56fab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/30d5502d42824fabd0a410e529c2e4b41ed56fab.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7661,
          name: "Beat the Beast: CERBERUS' INFERNO Mobile",
          uuid: "df1d9e3dfa1e7cdb572223be3eb97dc568044792",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/df1d9e3dfa1e7cdb572223be3eb97dc568044792.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7668,
          name: "Beat the Beast: Griffin´s Gold",
          uuid: "ae0f44ccc0abbc7415cc6954e4901a1e2f80b496",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ae0f44ccc0abbc7415cc6954e4901a1e2f80b496.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7669,
          name: "Beat the Beast: Griffin´s Gold Mobile",
          uuid: "e7679bdf2fb609e74e04387eb585e33405128092",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e7679bdf2fb609e74e04387eb585e33405128092.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7735,
          name: "Beat the Beast: Griffin’s Gold Reborn",
          uuid: "77e8fe510a624da88cfa07dc142a2767",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/77e8fe510a624da88cfa07dc142a2767.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7734,
          name: "Beat the Beast: Griffin’s Gold Reborn Mobile",
          uuid: "bfeaf15c839e438399699ed2d25ade7b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/bfeaf15c839e438399699ed2d25ade7b.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7657,
          name: "Beat the Beast: Kraken's Lair",
          uuid: "7d45a0b6e47f6b19452bf712a6d7d8caf73d4cf8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7d45a0b6e47f6b19452bf712a6d7d8caf73d4cf8.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7656,
          name: "Beat the Beast: Kraken's Lair Mobile",
          uuid: "d051da7c841ca8bb397e735062276c254d42867e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d051da7c841ca8bb397e735062276c254d42867e.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7662,
          name: "Beat the Beast: Quetzalcoatl's Trial",
          uuid: "08721d9ca32e77543ba78a067ff5bb9278fdfa24",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/08721d9ca32e77543ba78a067ff5bb9278fdfa24.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7663,
          name: "Beat the Beast: Quetzalcoatl's Trial Mobile",
          uuid: "9e7a58f27fed863bd3c8c95a70a2ee210e04c5d3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9e7a58f27fed863bd3c8c95a70a2ee210e04c5d3.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7674,
          name: "Big Fin Bay",
          uuid: "c8715fdc5b35239aa397361798399629b2a559d4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c8715fdc5b35239aa397361798399629b2a559d4.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7675,
          name: "Big Fin Bay Mobile",
          uuid: "54e7d54e2973d0e4c7f9973ce79efcfb2e90592b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/54e7d54e2973d0e4c7f9973ce79efcfb2e90592b.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7586,
          name: "Birds On A Wire",
          uuid: "5d4fc4b3efbf45465c4991d6f6b545d60b97c052",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5d4fc4b3efbf45465c4991d6f6b545d60b97c052.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7622,
          name: "Birds On A Wire Mobile",
          uuid: "f54e222ca7c7116d7d6a665b35b868035ec0163b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f54e222ca7c7116d7d6a665b35b868035ec0163b.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7733,
          name: "Bones & Bounty",
          uuid: "3d376a6a75bc4db5854006864c2d1e2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/3d376a6a75bc4db5854006864c2d1e2d.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7732,
          name: "Bones & Bounty Mobile",
          uuid: "38cb04385843476aacdd16abee8bdb9b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/38cb04385843476aacdd16abee8bdb9b.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7587,
          name: "Bork the Berzerker",
          uuid: "89336fe9861f1f8802765ff20d436d6891e0f4e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/89336fe9861f1f8802765ff20d436d6891e0f4e3.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7628,
          name: "Bork the Berzerker Mobile",
          uuid: "7beef8809d2f1734d2bd9d2c2d6e60d7f4a7d815",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7beef8809d2f1734d2bd9d2c2d6e60d7f4a7d815.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7609,
          name: "Carnival Queen",
          uuid: "a3a3eaa23396482a8240d110ce7d2a73ba1a7873",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a3a3eaa23396482a8240d110ce7d2a73ba1a7873.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7631,
          name: "Carnival Queen Mobile",
          uuid: "d0f93dde41c1482e9417813bc07fad49f2c77867",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d0f93dde41c1482e9417813bc07fad49f2c77867.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7672,
          name: "Cosmic Voyager",
          uuid: "f0f5c51d0890b014eede6e32b2ff8d67980fdcf3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/f0f5c51d0890b014eede6e32b2ff8d67980fdcf3.jpeg",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7673,
          name: "Cosmic Voyager Mobile",
          uuid: "351d22380116984463f278883e8882cd864e1fa6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/351d22380116984463f278883e8882cd864e1fa6.jpeg",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7666,
          name: "Crystal Quest : DEEP JUNGLE",
          uuid: "16aadbbebde618b432e1f828e64797810cd181ef",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/16aadbbebde618b432e1f828e64797810cd181ef.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7667,
          name: "Crystal Quest : DEEP JUNGLE Mobile",
          uuid: "cdad813f7a6fd794acb2e9954ad489ad908e3f16",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cdad813f7a6fd794acb2e9954ad489ad908e3f16.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7670,
          name: "Crystal Quest: Arcane Tower",
          uuid: "47d466ce50936c4392357a03b4bd59ae237db309",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/47d466ce50936c4392357a03b4bd59ae237db309.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7671,
          name: "Crystal Quest: Arcane Tower Mobile",
          uuid: "586e846f659a152d61c10679dcb6973ce1dce573",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/586e846f659a152d61c10679dcb6973ce1dce573.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7676,
          name: "CRYSTAL QUEST: Frostlands",
          uuid: "abd1197c4fed8a99e6fe746aebc77d26a95352c8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/abd1197c4fed8a99e6fe746aebc77d26a95352c8.jpeg",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7677,
          name: "CRYSTAL QUEST: Frostlands Mobile",
          uuid: "615b171f11cef1b123b1c5d5b199a46f4d4e3194",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/615b171f11cef1b123b1c5d5b199a46f4d4e3194.jpeg",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7700,
          name: "Dionysus Golden Feast",
          uuid: "8fb095431fa3ae2d989ec9374f43e872418b9113",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/8fb095431fa3ae2d989ec9374f43e872418b9113.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7701,
          name: "Dionysus Golden Feast Mobile",
          uuid: "e7ef1bf8469ba15e971adebb52906cfe11a2a2fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/e7ef1bf8469ba15e971adebb52906cfe11a2a2fa.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7651,
          name: "Divine Lotus",
          uuid: "8460f438775af5bab954b8d29990d4a60d28bd93",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8460f438775af5bab954b8d29990d4a60d28bd93.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7650,
          name: "Divine Lotus Mobile",
          uuid: "6abac87ca2c1fdedc63a8ec2c7e7d92bd78c4294",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6abac87ca2c1fdedc63a8ec2c7e7d92bd78c4294.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7646,
          name: "Dragon Horn",
          uuid: "b8a7834f7299de4b14a1f3314bf3c9222df8061d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b8a7834f7299de4b14a1f3314bf3c9222df8061d.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7647,
          name: "Dragon Horn Mobile",
          uuid: "12ca567edfbdcd5efae10431e3e99b34a5c26edc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/12ca567edfbdcd5efae10431e3e99b34a5c26edc.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7748,
          name: "Eager Beaver",
          uuid: "f832e6193086ecc7d8890565116c237d7102f86f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/f832e6193086ecc7d8890565116c237d7102f86f.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7749,
          name: "Eager Beavers Mobile",
          uuid: "ea00f554388e58abeb072f8df7aacb3148397562",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/ea00f554388e58abeb072f8df7aacb3148397562.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7588,
          name: "Esqueleto Explosivo",
          uuid: "c6de2e7b712243bad9f1d3b886c2d21cb7e158e5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c6de2e7b712243bad9f1d3b886c2d21cb7e158e5.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7654,
          name: "Esqueleto Explosivo 2",
          uuid: "ebb653bfceee0a8babc79deac3586233e25bf2f4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ebb653bfceee0a8babc79deac3586233e25bf2f4.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7731,
          name: "Esqueleto Explosivo 2 - Reborn",
          uuid: "9575c85754bb45f9849774091779e2d8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/9575c85754bb45f9849774091779e2d8.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7730,
          name: "Esqueleto Explosivo 2 - Reborn Mobile",
          uuid: "30bd24d5a3224dca9fabbdb02c9e8829",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/30bd24d5a3224dca9fabbdb02c9e8829.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7655,
          name: "Esqueleto Explosivo 2 Mobile",
          uuid: "c4cc6c7b301ba1d463bbd6ba4d10d9e75e2e1f05",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c4cc6c7b301ba1d463bbd6ba4d10d9e75e2e1f05.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7634,
          name: "Esqueleto Explosivo Mobile",
          uuid: "cc4cba246706402356b1e0ba209fa3d6bba90cb1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cc4cba246706402356b1e0ba209fa3d6bba90cb1.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7589,
          name: "Flux",
          uuid: "e23b227000c6bf651dce33c1725624c93ae2ab28",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e23b227000c6bf651dce33c1725624c93ae2ab28.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7637,
          name: "Flux Mobile",
          uuid: "da65c8c774c79fe180d02c661bd90fcd64b87f24",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/da65c8c774c79fe180d02c661bd90fcd64b87f24.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7684,
          name: "Fortune Cats Golden Stacks!",
          uuid: "74d7f8396df10b0a411bd60205b0998c5680b566",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/74d7f8396df10b0a411bd60205b0998c5680b566.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7685,
          name: "Fortune Cats Golden Stacks! Mobile",
          uuid: "7ecd71d034f3736c5bded7d311341a04ab795030",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7ecd71d034f3736c5bded7d311341a04ab795030.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7590,
          name: "Frog Grog",
          uuid: "04c9efa97b5a557cd4f0bb2747b53c3afe9b3fbf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/04c9efa97b5a557cd4f0bb2747b53c3afe9b3fbf.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7612,
          name: "Frog Grog Mobile",
          uuid: "b13801d5be5216229532528ee8829d77fd13711a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b13801d5be5216229532528ee8829d77fd13711a.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7591,
          name: "Fruit Warp",
          uuid: "4f659a58d3f4293ec4f15f643fb7e5e094dffb13",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4f659a58d3f4293ec4f15f643fb7e5e094dffb13.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7619,
          name: "Fruit Warp Mobile",
          uuid: "668c1528366eae38f581770e3ace96dcef6899cb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/668c1528366eae38f581770e3ace96dcef6899cb.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7592,
          name: "Full Moon Romance",
          uuid: "69b9703f7183ba302f6820af3473bba463acba79",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/69b9703f7183ba302f6820af3473bba463acba79.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7624,
          name: "Full Moon Romance Mobile",
          uuid: "03432d25e50fd8cc9429d3cd2dcfb0773c747e3b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/03432d25e50fd8cc9429d3cd2dcfb0773c747e3b.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7682,
          name: "Gods of Rock",
          uuid: "63fb005edbb2a97beff43c32b061693af495cae7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/63fb005edbb2a97beff43c32b061693af495cae7.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7683,
          name: "Gods of Rock Mobile",
          uuid: "3540cb2be58adef605b0fb69e9d3f555904b5ece",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3540cb2be58adef605b0fb69e9d3f555904b5ece.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7729,
          name: "Gods of Rock! - Reborn",
          uuid: "3444305d6e5f4e8eaebd89abe0f87b4a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/3444305d6e5f4e8eaebd89abe0f87b4a.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7728,
          name: "Gods of Rock! - Reborn Mobile",
          uuid: "08d0fed933dc42cb8d3121d1c0690d13",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/08d0fed933dc42cb8d3121d1c0690d13.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7706,
          name: "Grand Melee",
          uuid: "4420c852294c4a079fe0a813ef7ca42c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/4420c852294c4a079fe0a813ef7ca42c.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7707,
          name: "Grand Melee Mobile",
          uuid: "7e11e0a4bd7e401f854c4b57f9c43bb5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/7e11e0a4bd7e401f854c4b57f9c43bb5.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7727,
          name: "Hellapeños",
          uuid: "2828040715864ed880e129606b9cbe46",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/2828040715864ed880e129606b9cbe46.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7726,
          name: "Hellapeños Mobile",
          uuid: "8af6f9c2dd914b0fbcd531b08c415c85",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/8af6f9c2dd914b0fbcd531b08c415c85.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7725,
          name: "Hot Potato!",
          uuid: "b4a4e01bd4fd439290c7d5f267000187",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/b4a4e01bd4fd439290c7d5f267000187.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7724,
          name: "Hot Potato! Mobile",
          uuid: "ef0086dd5cae499dbf84fce7f8838783",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/ef0086dd5cae499dbf84fce7f8838783.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7593,
          name: "Jaguar Temple",
          uuid: "0b406bb1eaeb9f456fcfc28310ff337fc59cd962",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0b406bb1eaeb9f456fcfc28310ff337fc59cd962.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7614,
          name: "Jaguar Temple Mobile",
          uuid: "a6f3318306a316c775f4a1890e89780f58ab4a4d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a6f3318306a316c775f4a1890e89780f58ab4a4d.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7704,
          name: "Jiggly Cash",
          uuid: "3e03c178bf28c3ca9eb4c752d004babd74129dfd3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/3e03c178bf28c3ca9eb4c752d004babd74129dfd3.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7705,
          name: "Jiggly Cash Mobile",
          uuid: "b9bd79c4e3abdb86599dd7728c468de467401c69",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/b9bd79c4e3abdb86599dd7728c468de467401c69.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7664,
          name: "Jin Chan's Pond of Riches",
          uuid: "c8e8f5bc0dd247fab13b8764b8ab37a81a283973",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c8e8f5bc0dd247fab13b8764b8ab37a81a283973.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7665,
          name: "Jin Chan's Pond of Riches Mobile",
          uuid: "763e6095393fcee843ba35b03d2c146b3bb304b8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/763e6095393fcee843ba35b03d2c146b3bb304b8.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7740,
          name: "King of the Party",
          uuid: "5d2e72996175487380ba87f7fb367ae6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/5d2e72996175487380ba87f7fb367ae6.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7741,
          name: "King of the Party Mobile",
          uuid: "848a71f436414301a0e3e1eb8965e69a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/848a71f436414301a0e3e1eb8965e69a.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7688,
          name: "Lava Lava",
          uuid: "1ab1739d7e0a2ea77e6173d9e6bc401e1f2c0c0e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1ab1739d7e0a2ea77e6173d9e6bc401e1f2c0c0e.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7689,
          name: "Lava Lava Mobile",
          uuid: "40cb6b5d44424f55eb6771b439a8e7b3786ab939",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/40cb6b5d44424f55eb6771b439a8e7b3786ab939.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7690,
          name: "Lost City Of The Djinn",
          uuid: "14cc688cb03df771307ed03baf06f233b52c547b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/14cc688cb03df771307ed03baf06f233b52c547b.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7691,
          name: "Lost City Of The Djinn Mobile",
          uuid: "516364bd1e82237f439e48721a35e3e3c8083902",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/516364bd1e82237f439e48721a35e3e3c8083902.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7594,
          name: "Luchadora",
          uuid: "38c2de4dfb80c78ab93333a1cd6ece24c016f201",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/38c2de4dfb80c78ab93333a1cd6ece24c016f201.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7618,
          name: "Luchadora Mobile",
          uuid: "52f967f8528812f54fa0069bd004ba9847ad88e7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/52f967f8528812f54fa0069bd004ba9847ad88e7.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7595,
          name: "Magicious",
          uuid: "89664f1a5ae954c746347fe87cac5f85161fa251",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/89664f1a5ae954c746347fe87cac5f85161fa251.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7629,
          name: "Magicious Mobile",
          uuid: "422b80fa92ba086930c7120e79d6e2761a914373",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/422b80fa92ba086930c7120e79d6e2761a914373.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7638,
          name: "Midas Golden Touch",
          uuid: "c7dc903ef69a5b3b6bfd40c521655292fad198de",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c7dc903ef69a5b3b6bfd40c521655292fad198de.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7723,
          name: "Midas Golden Touch - Reborn",
          uuid: "402df013bfd1403e81eed35419ae6f5f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/402df013bfd1403e81eed35419ae6f5f.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7722,
          name: "Midas Golden Touch - Reborn Mobile",
          uuid: "98f2929d2669487688111520823cf570",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/98f2929d2669487688111520823cf570.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7746,
          name: "Midas Golden Touch 2",
          uuid: "266f95206619d2fc25e549432e617a0e66b65880",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/266f95206619d2fc25e549432e617a0e66b65880.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7747,
          name: "Midas Golden Touch 2 Mobile",
          uuid: "d9c9273764dbf95adef24ae7e189a28c7bc9e6d9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/d9c9273764dbf95adef24ae7e189a28c7bc9e6d9.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7721,
          name: "Midas Golden Touch Christmas Edition",
          uuid: "041addcc7a8547f488ae806269d0c04a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/041addcc7a8547f488ae806269d0c04a.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7720,
          name: "Midas Golden Touch Christmas Edition Mobile",
          uuid: "1e2d28a7b507413181752b8975e1dc49",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/1e2d28a7b507413181752b8975e1dc49.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7639,
          name: "Midas Golden Touch Mobile",
          uuid: "7090652a4e151eb1b1701247c6cb967006ff08d2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7090652a4e151eb1b1701247c6cb967006ff08d2.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7596,
          name: "Not Enough Kittens",
          uuid: "8606afd3352dec56dcc116e805897fd24d0158b1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8606afd3352dec56dcc116e805897fd24d0158b1.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7627,
          name: "Not Enough Kittens Mobile",
          uuid: "0229c965d4c73fe298ea2d7658899cb1843f93c5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0229c965d4c73fe298ea2d7658899cb1843f93c5.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7686,
          name: "Odin´s Gamble Mímirs Well",
          uuid: "c731dd41194df1a054471334c1b458270f0e7f5f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c731dd41194df1a054471334c1b458270f0e7f5f.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7687,
          name: "Odin´s Gamble Mímirs Well Mobile",
          uuid: "a4c8b27d85bb2600f8637d417c784b221b5c2acb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a4c8b27d85bb2600f8637d417c784b221b5c2acb.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7719,
          name: "Odin’s Gamble Reborn",
          uuid: "5cb1c91b9e14479ab831ad04e92c903c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/5cb1c91b9e14479ab831ad04e92c903c.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7718,
          name: "Odin’s Gamble Reborn Mobile",
          uuid: "1b314ffc0d384d998f32e267b4660af3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/1b314ffc0d384d998f32e267b4660af3.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7694,
          name: "Phoenix Paradise",
          uuid: "3b08d0371d42a88de00f8015ec3182c3c97c0d05",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3b08d0371d42a88de00f8015ec3182c3c97c0d05.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7695,
          name: "Phoenix Paradise Mobile",
          uuid: "315254401f1b94c4a085ec002f80816e1129a157",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/315254401f1b94c4a085ec002f80816e1129a157.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7597,
          name: "Pink Elephants",
          uuid: "333ee1508c6cf41cbfc6fa2341702f24326638c6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/333ee1508c6cf41cbfc6fa2341702f24326638c6.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7658,
          name: "Pink Elephants 2",
          uuid: "70130e606050673d0eee875789cbb48e0a85d526",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/70130e606050673d0eee875789cbb48e0a85d526.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7717,
          name: "Pink Elephants 2 - Reborn",
          uuid: "53c53c17bc0741639fda33949bd8e083",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/53c53c17bc0741639fda33949bd8e083.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7716,
          name: "Pink Elephants 2 - Reborn Mobile",
          uuid: "94d94aa571c24f2ebfff2e1c6296178c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/94d94aa571c24f2ebfff2e1c6296178c.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7659,
          name: "Pink Elephants 2 Mobile",
          uuid: "db63a93f0e2b1a692fa38288170831334b1a44aa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/db63a93f0e2b1a692fa38288170831334b1a44aa.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7617,
          name: "Pink Elephants Mobile",
          uuid: "ac76df6d87b30557135904ef10d8f1ac36a5bcaa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ac76df6d87b30557135904ef10d8f1ac36a5bcaa.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7680,
          name: "Pyramyth",
          uuid: "60e8344c18ba7f0661ecd9945230b3ce30f40796",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/60e8344c18ba7f0661ecd9945230b3ce30f40796.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7715,
          name: "Pyramyth - Reborn",
          uuid: "e11f4372d0074f3083e72bcb1c9bd5e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/e11f4372d0074f3083e72bcb1c9bd5e0.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7714,
          name: "Pyramyth - Reborn Mobile",
          uuid: "b3f1cf937f6a4e14a7388498da61c9ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/b3f1cf937f6a4e14a7388498da61c9ce.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7681,
          name: "Pyramyth Mobile",
          uuid: "8916659ebe77019a928d094a411eb5dfae3326cd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8916659ebe77019a928d094a411eb5dfae3326cd.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7644,
          name: "Ravens Eye",
          uuid: "ff5af233538f4ea9192d0af5603dec459c545924",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ff5af233538f4ea9192d0af5603dec459c545924.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7645,
          name: "Ravens Eye Mobile",
          uuid: "6752428d291ab44393e380592f0f5a5d7ccf7f33",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6752428d291ab44393e380592f0f5a5d7ccf7f33.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7698,
          name: "Rex the Hunt",
          uuid: "85d90223bfc573b50cc11f0be7cfe08849039c04",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/85d90223bfc573b50cc11f0be7cfe08849039c04.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7699,
          name: "Rex the Hunt Mobile",
          uuid: "10b7606bda7ecef5fca820206cbf387c884d3390",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/10b7606bda7ecef5fca820206cbf387c884d3390.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7648,
          name: "Riders of the Storm",
          uuid: "201ad12e8f0b08cca6cf163de74f8ea2113d1359",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/201ad12e8f0b08cca6cf163de74f8ea2113d1359.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7649,
          name: "Riders of the Storm Mobile",
          uuid: "a94ff48e1f413f7c577341d405c76a530035b1d5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a94ff48e1f413f7c577341d405c76a530035b1d5.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7598,
          name: "Roasty McFry and The Flame Busters",
          uuid: "ced32d78d4b730d0118080351922c4e45e7dac54",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ced32d78d4b730d0118080351922c4e45e7dac54.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7635,
          name: "Roasty McFry and The Flame Busters Mobile",
          uuid: "e02135277788e992095733f8d0c081235cd42139",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e02135277788e992095733f8d0c081235cd42139.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7640,
          name: "Rocket Fellas Inc",
          uuid: "d1d09612a638241aa9b9ecbcfa1b51097a5b2467",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d1d09612a638241aa9b9ecbcfa1b51097a5b2467.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7641,
          name: "Rocket Fellas Inc Mobile",
          uuid: "1062e330e887a706a0592eff60b8a141131545c6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1062e330e887a706a0592eff60b8a141131545c6.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7692,
          name: "Shifting Seas",
          uuid: "8e0893ef584713c45eb84e2e1c0161f016815d03",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8e0893ef584713c45eb84e2e1c0161f016815d03.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7693,
          name: "Shifting Seas Mobile",
          uuid: "9abf698c0dc0f07903b53654c52b9d66ce812bad",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9abf698c0dc0f07903b53654c52b9d66ce812bad.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7599,
          name: "Spectra",
          uuid: "65c449f2885834dd47729194b4aa9881c1e6528c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/65c449f2885834dd47729194b4aa9881c1e6528c.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7623,
          name: "Spectra Mobile",
          uuid: "73b414db21ae994c0f8b3e49d80fb7521c3bd2e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/73b414db21ae994c0f8b3e49d80fb7521c3bd2e3.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7600,
          name: "Sunset Delight",
          uuid: "e1aa3e13622454811a318b0e3e1625be31650641",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e1aa3e13622454811a318b0e3e1625be31650641.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7636,
          name: "Sunset Delight Mobile",
          uuid: "570eeed68ae095613775974b64a74fe65ac88984",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/570eeed68ae095613775974b64a74fe65ac88984.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7744,
          name: "Sword of Arthur",
          uuid: "6221581cc8b9a786d91c5b322f0e7b78d186a5bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/6221581cc8b9a786d91c5b322f0e7b78d186a5bf.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7745,
          name: "Sword of Arthur Mobile",
          uuid: "7e98aaeab1411d6a3428152b1e2b95678c357a48",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/7e98aaeab1411d6a3428152b1e2b95678c357a48.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7652,
          name: "Sword of Khans",
          uuid: "6b32cc5411f5df25bdb177c40d91855bfd0d2680",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6b32cc5411f5df25bdb177c40d91855bfd0d2680.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7653,
          name: "Sword of Khans Mobile",
          uuid: "c7e7fdbf9076b59d016d17420a56cec00a6426c0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c7e7fdbf9076b59d016d17420a56cec00a6426c0.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7708,
          name: "Sword of Shoguns",
          uuid: "fb79f539f5fd47eebcebc2f935c75fac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/fb79f539f5fd47eebcebc2f935c75fac.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7709,
          name: "Sword of Shoguns Mobile",
          uuid: "033ea506e4d74b77ad6f20bc9e4101bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/033ea506e4d74b77ad6f20bc9e4101bf.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7601,
          name: "The Falcon Huntress",
          uuid: "5d31aaaf577d0b59b412a6d80f731ffeed45e6ec",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5d31aaaf577d0b59b412a6d80f731ffeed45e6ec.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7621,
          name: "The Falcon Huntress Mobile",
          uuid: "fda7d66feabfe15af448a0b624dcdf4137cea0e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fda7d66feabfe15af448a0b624dcdf4137cea0e4.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7751,
          name: "The Gunman of Goldwood",
          uuid: "70fd850ad4ca5122672a2299a60aec4901c3f7ba",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/70fd850ad4ca5122672a2299a60aec4901c3f7ba.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7750,
          name: "The Gunman of Goldwood Mobile",
          uuid: "fd852d2b60f42d99300eaa63898687e82d452e37",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/fd852d2b60f42d99300eaa63898687e82d452e37.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7602,
          name: "The Rift",
          uuid: "03c38e2b5fae52b895607c12d9730c9c2dd4befd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/03c38e2b5fae52b895607c12d9730c9c2dd4befd.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7611,
          name: "The Rift Mobile",
          uuid: "4239e52061cdaf41e9f6e65305ae363bb1997a9d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4239e52061cdaf41e9f6e65305ae363bb1997a9d.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7702,
          name: "The Wildos",
          uuid: "c85737f4aeb88b9a4f974167d2ae342d9be116ed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/c85737f4aeb88b9a4f974167d2ae342d9be116ed.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7703,
          name: "The Wildos Mobile",
          uuid: "7310d470c1cf2559eb600efe09d5d9bf1878d677d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/7310d470c1cf2559eb600efe09d5d9bf1878d677d.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7642,
          name: "Tiger Rush",
          uuid: "f4d7908261e82222e534e619524c4b71182646e8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f4d7908261e82222e534e619524c4b71182646e8.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7643,
          name: "Tiger Rush Mobile",
          uuid: "91ba519fec3d3d5dd710678a972f5a402e7d7270",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/91ba519fec3d3d5dd710678a972f5a402e7d7270.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7603,
          name: "Toki Time",
          uuid: "763d26dfb343532fe38bb9987d3be4b748b2431e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/763d26dfb343532fe38bb9987d3be4b748b2431e.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7626,
          name: "Toki Time Mobile",
          uuid: "ba389d71f325862ddaf44dd85f7ee20988f0bdab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ba389d71f325862ddaf44dd85f7ee20988f0bdab.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7713,
          name: "Triple Christmas Gold",
          uuid: "0912700e8a6342c9951e15e038ae8964",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/0912700e8a6342c9951e15e038ae8964.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7712,
          name: "Triple Christmas Gold Mobile",
          uuid: "b33b3b43f2354ff9962c4a2ae42e460e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/b33b3b43f2354ff9962c4a2ae42e460e.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7696,
          name: "Triple Royal Gold",
          uuid: "29db6352d7edc6cd328a78b1dc320aab4e71b10a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/29db6352d7edc6cd328a78b1dc320aab4e71b10a.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7697,
          name: "Triple Royal Gold Mobile",
          uuid: "bb63461c6dfd52265d38dc442123a041bb300a9a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/bb63461c6dfd52265d38dc442123a041bb300a9a.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7604,
          name: "Turning Totems",
          uuid: "02e1e1cdf041f5f3f9339a117e3f160f7d5a543b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/02e1e1cdf041f5f3f9339a117e3f160f7d5a543b.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7610,
          name: "Turning Totems Mobile",
          uuid: "cf6e687d38eb655318bb6d857c96c0eba9a24dd4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cf6e687d38eb655318bb6d857c96c0eba9a24dd4.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7605,
          name: "Well of Wonders",
          uuid: "96e090bd47a462dee886bcd80d73a3fcd0e235f8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/96e090bd47a462dee886bcd80d73a3fcd0e235f8.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7630,
          name: "Well of Wonders Mobile",
          uuid: "a0fac961194fb9921e7868d43051e62c58d3b02b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a0fac961194fb9921e7868d43051e62c58d3b02b.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7606,
          name: "Wild Heist at Peacock Manor",
          uuid: "b21a089898026cd9af59148089dc7e931802cea6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b21a089898026cd9af59148089dc7e931802cea6.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7632,
          name: "Wild Heist at Peacock Manor Mobile",
          uuid: "6f3692e4f7ab5cc48ee5e13e74396d493aeb4921",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6f3692e4f7ab5cc48ee5e13e74396d493aeb4921.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7742,
          name: "Xterminate",
          uuid: "52b86cd8b53c004de590ca554235369e76fafb92",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/52b86cd8b53c004de590ca554235369e76fafb92.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7743,
          name: "Xterminate Mobile",
          uuid: "94e46649fe1e4738d0b85bf617106077ea1da352",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/94e46649fe1e4738d0b85bf617106077ea1da352.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7607,
          name: "Yeti",
          uuid: "2a7344e4807a9e081c81a6d009fc0308ba1bfbdd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2a7344e4807a9e081c81a6d009fc0308ba1bfbdd.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7616,
          name: "Yeti Mobile",
          uuid: "588a7fad7e74bbc1a1723505b6f23d9342df1164",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/588a7fad7e74bbc1a1723505b6f23d9342df1164.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7711,
          name: "Zap Attack!",
          uuid: "408bd9c581a647ab90f9da9fd6f7f579",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/408bd9c581a647ab90f9da9fd6f7f579.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7710,
          name: "Zap Attack! Mobile",
          uuid: "023013db8886490e95c25544e0bf08bc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/023013db8886490e95c25544e0bf08bc.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7608,
          name: "Zoom",
          uuid: "5c637908385afc2ee8f4344f1d7000bf0ecebffd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5c637908385afc2ee8f4344f1d7000bf0ecebffd.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7620,
          name: "Zoom Mobile",
          uuid: "74db1d927ed5d24386c1915e6c04de2a3d52369a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/74db1d927ed5d24386c1915e6c04de2a3d52369a.png",
          type: "slots",
          provider: "Thunderkick",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 158,
      name: "TripleCherry",
      providerId: 26,
      providerName: "TripleCherry",
      games: [
        {
          game_id: 7826,
          name: "3 Hot Fruits",
          uuid: "5ea4787e13f4421d9d27fc30941257a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/5ea4787e13f4421d9d27fc30941257a9.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7819,
          name: "AC DZeus",
          uuid: "d89027e611cf4e358e6065531fb368a3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/d89027e611cf4e358e6065531fb368a3.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7828,
          name: "Ape Frenzy",
          uuid: "5ee69bfc96b94f1eb174627609af6302",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/5ee69bfc96b94f1eb174627609af6302.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7830,
          name: "Babylon Gardens Ancient Wonders",
          uuid: "f2a59c5d5f604fa8817ac07df497eba2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/f2a59c5d5f604fa8817ac07df497eba2.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7784,
          name: "Big Dig Gold",
          uuid: "f5792446d54a4c519084aed833e17b228411d9ad",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/f5792446d54a4c519084aed833e17b228411d9ad.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7818,
          name: "Big Fisherman",
          uuid: "6fe45bac824c4c3887920873617d02a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/6fe45bac824c4c3887920873617d02a9.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7789,
          name: "Big Papa's Burger",
          uuid: "febf6df4660853db2d299bfc336367962de9ea8b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/febf6df4660853db2d299bfc336367962de9ea8b.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7775,
          name: "Board Quest",
          uuid: "53f697203859458fdee42ab22c705b9e877aa21d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/53f697203859458fdee42ab22c705b9e877aa21d.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7778,
          name: "Bounty Seas",
          uuid: "c4014251e41da264268acb28d7bdf7d3281d4576",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c4014251e41da264268acb28d7bdf7d3281d4576.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7827,
          name: "Candy Rush",
          uuid: "9b18a38f95d447cda2f6fff74ca3909b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/9b18a38f95d447cda2f6fff74ca3909b.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7760,
          name: "Carnaval do Rio",
          uuid: "7a0445eec54847b6e605cc5ae83679d59bd4c976",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7a0445eec54847b6e605cc5ae83679d59bd4c976.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7767,
          name: "Catrina, Amor Eterno",
          uuid: "df64ec4afc26954444b5ddd3a8c78f9c35040352",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/df64ec4afc26954444b5ddd3a8c78f9c35040352.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7788,
          name: "Chicken Squad",
          uuid: "6de9ecc9179a42128d291dfb9b4a91cf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/6de9ecc9179a42128d291dfb9b4a91cf.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7771,
          name: "Clover Up",
          uuid: "b4316b8858dc3e115841f10a748d147a9fa00051",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b4316b8858dc3e115841f10a748d147a9fa00051.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7787,
          name: "Crush on Wilds",
          uuid: "bb218370d8ce4ff5b5a1f16fbd5febe3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/bb218370d8ce4ff5b5a1f16fbd5febe3.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7770,
          name: "Dwarf Treasures",
          uuid: "34af147b1ecd342eb20287588cb229ef240aab5f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/34af147b1ecd342eb20287588cb229ef240aab5f.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7817,
          name: "El Chile Caliente",
          uuid: "bffdaf5580ea8060c11fd0b80d710306d6a55bea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/bffdaf5580ea8060c11fd0b80d710306d6a55bea.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7792,
          name: "El Dorado Ritual",
          uuid: "d112d8b941bb418786635e750481db8e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/d112d8b941bb418786635e750481db8e.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7758,
          name: "Elsa's BierHouse",
          uuid: "ede4059dfc128a8502a39fbd3b0bcbb61713bacd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ede4059dfc128a8502a39fbd3b0bcbb61713bacd.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7803,
          name: "Epic Cherry",
          uuid: "8e2de3421e9e48abbe23ec13c0b53f4b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/8e2de3421e9e48abbe23ec13c0b53f4b.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7806,
          name: "Epic Cherry 2",
          uuid: "bb605dd2828c4f089aacc121fc668ec6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/bb605dd2828c4f089aacc121fc668ec6.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7811,
          name: "Epic Cherry 3",
          uuid: "5cd0749b22564ef486651109705422e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/5cd0749b22564ef486651109705422e6.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7812,
          name: "Epic Cherry 4",
          uuid: "10a6ec2bacac41cbb380a641f44efd75",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/10a6ec2bacac41cbb380a641f44efd75.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7815,
          name: "Epic Cherry 5",
          uuid: "12397c5fe88842bb8b9ce8aed6aab164",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/12397c5fe88842bb8b9ce8aed6aab164.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7824,
          name: "Epic Cherry 6",
          uuid: "9d601a10004448a1ae07d4675bf56b57",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/9d601a10004448a1ae07d4675bf56b57.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7807,
          name: "Extra luck",
          uuid: "844213dd209d45ef93053eb0f2c99b6f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/844213dd209d45ef93053eb0f2c99b6f.png",
          type: "scratch card",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7798,
          name: "Far West",
          uuid: "88fdf8407561a84de7240c972e7ad7798f057e15",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/88fdf8407561a84de7240c972e7ad7798f057e15.png",
          type: "scratch card",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7772,
          name: "Fish and Hooks",
          uuid: "cbed45c325e49bf7ad1876dec0e170353537082c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cbed45c325e49bf7ad1876dec0e170353537082c.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7809,
          name: "Forest Richness",
          uuid: "f3eb80fc2dfe424e878c068abcd31abc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/f3eb80fc2dfe424e878c068abcd31abc.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7796,
          name: "Friducha",
          uuid: "bce45d1ba87e91d2120ea1fdae7cd7215202188b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/bce45d1ba87e91d2120ea1fdae7cd7215202188b.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7786,
          name: "Gladius of Rome",
          uuid: "5d58e23cc86b4deb9bf79e2f91976621",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/5d58e23cc86b4deb9bf79e2f91976621.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7781,
          name: "Goal Crash",
          uuid: "b45dce212e0cd95a57f344672e08c8ae943d8ab4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/b45dce212e0cd95a57f344672e08c8ae943d8ab4.png",
          type: "crash",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7794,
          name: "GOAL Crash America",
          uuid: "7b46b1e9f09e26b2a0c15b655c5781b07bab8bf9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/7b46b1e9f09e26b2a0c15b655c5781b07bab8bf9.png",
          type: "crash",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7752,
          name: "Gods Odyssey",
          uuid: "0241d439d26d537bdfb47651e8a35e87decc90ac",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0241d439d26d537bdfb47651e8a35e87decc90ac.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7776,
          name: "Golden Bomblins",
          uuid: "b05df8cbd73b463d8f404c11e550e9e9a54d32d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b05df8cbd73b463d8f404c11e550e9e9a54d32d7.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7795,
          name: "Halloween Mansion",
          uuid: "36ff21c93a0820e643b9f473f215051dd2090f6a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/36ff21c93a0820e643b9f473f215051dd2090f6a.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7810,
          name: "Heroes Dragons",
          uuid: "40b7d63d55674c489d7eec457b7bfdf5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/40b7d63d55674c489d7eec457b7bfdf5.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7805,
          name: "Hiding Clovers",
          uuid: "2577d537879e40a493201bf7b9d4f91d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/2577d537879e40a493201bf7b9d4f91d.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7831,
          name: "Hot Fruits 20",
          uuid: "bd5febdf58cb45b3b73241d8ddf773b9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/bd5febdf58cb45b3b73241d8ddf773b9.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7822,
          name: "Hot Fruits 5",
          uuid: "8ed708cfd6414110ab4fda7e23ea6e71",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/8ed708cfd6414110ab4fda7e23ea6e71.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7777,
          name: "Hunters: Cursed Masks",
          uuid: "45d29dfe4039d742801d89e5a181676d184b56c7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/45d29dfe4039d742801d89e5a181676d184b56c7.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7782,
          name: "Iced Fossils",
          uuid: "b4950bfed3e27239b321541794684701531b70ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/b4950bfed3e27239b321541794684701531b70ff.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7785,
          name: "Jade of Cleopatra",
          uuid: "9dfd77eeeca222d606a22084c8027561715a48e8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/9dfd77eeeca222d606a22084c8027561715a48e8.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7783,
          name: "Joker Wheel",
          uuid: "21bef5f1c5a6413f89710a719130aa50",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/21bef5f1c5a6413f89710a719130aa50.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7763,
          name: "Joker's Kingdom",
          uuid: "cd56c27229eaaec2f15c432d1f873d0a9b613350",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cd56c27229eaaec2f15c432d1f873d0a9b613350.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7829,
          name: "Lotus Geisha",
          uuid: "f585c2a415494697a1200ba1fe8f7c56",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/f585c2a415494697a1200ba1fe8f7c56.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7804,
          name: "Luxury (Scratch)",
          uuid: "62842f320127422e91de1785be0ed5ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/62842f320127422e91de1785be0ed5ff.png",
          type: "scratch card",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7755,
          name: "Mega Chef",
          uuid: "326669a51f3728328909708b93625888cfdbbdde",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/326669a51f3728328909708b93625888cfdbbdde.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7773,
          name: "Mega Chef Christmas Edition",
          uuid: "0456f7acc1321a7e188d241762c066c64894666d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0456f7acc1321a7e188d241762c066c64894666d.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7821,
          name: "Mermaid Princess",
          uuid: "75c25c11c96d42aaaaa371484a5572de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/75c25c11c96d42aaaaa371484a5572de.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7800,
          name: "Millionaire (Scratch)",
          uuid: "54099499960a4977ba7f7cceeae6c048",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/54099499960a4977ba7f7cceeae6c048.png",
          type: "scratch card",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7808,
          name: "MMA Kings",
          uuid: "fb100906492b479788c072a8d2a2c9e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/fb100906492b479788c072a8d2a2c9e0.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7761,
          name: "Monsters Fear Bullets",
          uuid: "e96155a57353b8b4f8d9032d7bb379c31385e89a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e96155a57353b8b4f8d9032d7bb379c31385e89a.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7757,
          name: "Mystic Buffalo",
          uuid: "7c7b28ff2916e3fd36a837c63bbd3f61856852dc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7c7b28ff2916e3fd36a837c63bbd3f61856852dc.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7813,
          name: "Napoleon, The Emperor",
          uuid: "831826c4cad14434ad3efffa45a32c2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/831826c4cad14434ad3efffa45a32c2c.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7823,
          name: "Nazca Mysteries",
          uuid: "c3ea84395067426ba576cba5a2a0f95c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/c3ea84395067426ba576cba5a2a0f95c.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7814,
          name: "Neonpunk",
          uuid: "425e8b0eac4287b9f846ab309c37d4c410ecbd94",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/425e8b0eac4287b9f846ab309c37d4c410ecbd94.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7802,
          name: "Nian Fortune",
          uuid: "08da09e07e1e469fa24773b4ba8629de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/08da09e07e1e469fa24773b4ba8629de.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7754,
          name: "Nile Treasures",
          uuid: "ad04ab3401443dd0de70f96b272f4fb56832e487",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ad04ab3401443dd0de70f96b272f4fb56832e487.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7832,
          name: "Nile Treasures Deluxe",
          uuid: "b2d99628dbc04c908c750bad27a66468",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/b2d99628dbc04c908c750bad27a66468.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7820,
          name: "Noir City",
          uuid: "29b1fedf460642d495b2b7275b04d7e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/29b1fedf460642d495b2b7275b04d7e0.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7768,
          name: "Pinocchio's Journey",
          uuid: "a6145a5c8ad50f872313fd5a5c79185e4fcd5c5d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a6145a5c8ad50f872313fd5a5c79185e4fcd5c5d.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7769,
          name: "Rebel World",
          uuid: "56e5806f78587e6d36b1497e024c975730c3d59a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/56e5806f78587e6d36b1497e024c975730c3d59a.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7756,
          name: "Red Queen in Wonderland",
          uuid: "a2682d79263182624e7983fdfdf50af42ecfe47a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a2682d79263182624e7983fdfdf50af42ecfe47a.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7759,
          name: "Saint Fermin",
          uuid: "59c2389f753579f6ab90d5b6313f01a2057bee3e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/59c2389f753579f6ab90d5b6313f01a2057bee3e.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7797,
          name: "Santa's Workshop",
          uuid: "927f4ffa95876559de0636552df638f96c2cca03",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/927f4ffa95876559de0636552df638f96c2cca03.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7753,
          name: "Seven's Club",
          uuid: "f31a154eced2aeba5496635c01bb4cdf6a161084",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f31a154eced2aeba5496635c01bb4cdf6a161084.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7816,
          name: "Space Robot",
          uuid: "458a5f05b9a94bc2977247956d985efd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/458a5f05b9a94bc2977247956d985efd.jpg",
          type: "crash",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7762,
          name: "StarFall Mission",
          uuid: "279077754f8fd58c2ae7ae2995a7a9f5bd518f2c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/279077754f8fd58c2ae7ae2995a7a9f5bd518f2c.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7791,
          name: "Steam Storm",
          uuid: "2db6a4197f09490986d11bd177e5b4640761adec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/2db6a4197f09490986d11bd177e5b4640761adec.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7801,
          name: "Steamboat Wild",
          uuid: "dc9bb77db4b8456d8715ecadce33d7e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/dc9bb77db4b8456d8715ecadce33d7e6.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7766,
          name: "Sugar Frenzy",
          uuid: "5c709a024b98b1fa8864b6a0d5339a4a8589c1c6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5c709a024b98b1fa8864b6a0d5339a4a8589c1c6.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7774,
          name: "Sunset Highways",
          uuid: "6f704599014e11c19c59ce94f5a4d3da404e6ab5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6f704599014e11c19c59ce94f5a4d3da404e6ab5.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7793,
          name: "The Walking Wild",
          uuid: "d881aa98ec80488dbc008785435d4435",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/d881aa98ec80488dbc008785435d4435.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7764,
          name: "Thunder Rock",
          uuid: "133fc1d8f349557a5d0a1f47e0bac407b7458358",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/133fc1d8f349557a5d0a1f47e0bac407b7458358.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7765,
          name: "Tomoe's Sushi Bar",
          uuid: "773c14f79dd3bcde66978a59e23b57d369ff2848",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/773c14f79dd3bcde66978a59e23b57d369ff2848.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7799,
          name: "Trojan Chickens",
          uuid: "fb731576c95d467da1cfb6975bd2822d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/fb731576c95d467da1cfb6975bd2822d.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7790,
          name: "Wanted WILDS",
          uuid: "d0b68f3bde6f4bc781fa14f6fdf241c9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/d0b68f3bde6f4bc781fa14f6fdf241c9.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7780,
          name: "Wild Cup Soccer",
          uuid: "bbff45fda821aba3611467d234eb3c2e2cd31794",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/bbff45fda821aba3611467d234eb3c2e2cd31794.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7825,
          name: "Wonder Lamp",
          uuid: "5c2498aeeab64186b8f2daf52dcca318",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleCherry/5c2498aeeab64186b8f2daf52dcca318.jpg",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7779,
          name: "Ying Yang Legends",
          uuid: "2d4bdf11b8b2c69d397a54ec6e869a57a6ea49ca",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2d4bdf11b8b2c69d397a54ec6e869a57a6ea49ca.png",
          type: "slots",
          provider: "TripleCherry",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 159,
      name: "TripleProfitsGames",
      providerId: 37,
      providerName: "TripleProfitsGames",
      games: [
        {
          game_id: 7886,
          name: "777 Slot",
          uuid: "53d3f159d90046aabd7cd565e0757d2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/53d3f159d90046aabd7cd565e0757d2c.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7856,
          name: "777 Slot Mobile",
          uuid: "3575497b1e414db1aa0ada067d51ab27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/3575497b1e414db1aa0ada067d51ab27.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7865,
          name: "8 Dragons",
          uuid: "77c6a519899b467985f725eda6aa0f99",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/77c6a519899b467985f725eda6aa0f99.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7835,
          name: "8 Dragons Mobile",
          uuid: "1238addbd3c9411984cff074c4ace225",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/1238addbd3c9411984cff074c4ace225.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7925,
          name: "Amazing Christmas",
          uuid: "22e5e69237fb546a152715a6913e7bb709216856",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/22e5e69237fb546a152715a6913e7bb709216856.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7926,
          name: "Amazing Christmas Mobile",
          uuid: "33a7379cfe59ffabd00582616b83de06d598c04a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/33a7379cfe59ffabd00582616b83de06d598c04a.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7919,
          name: "Ancient Dragons",
          uuid: "787ab22aed72f45634fcf55680982b3f92c1e814",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/787ab22aed72f45634fcf55680982b3f92c1e814.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7920,
          name: "Ancient Dragons Mobile",
          uuid: "24fa3dc60fbedea3783dfff3437ab715bcb53eef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/24fa3dc60fbedea3783dfff3437ab715bcb53eef.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7915,
          name: "Andar Bahar",
          uuid: "f745bbb4e6b6d9b2a7a0c2f5f33749903f3eb4ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/f745bbb4e6b6d9b2a7a0c2f5f33749903f3eb4ef.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7916,
          name: "Andar Bahar Mobile",
          uuid: "b8f3ff4fc94b98a14c74a69aff6c9bc7bf708831",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/b8f3ff4fc94b98a14c74a69aff6c9bc7bf708831.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7876,
          name: "Blessing Mouse",
          uuid: "145bde1b3689457ba7b9f9b0a591f14f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/145bde1b3689457ba7b9f9b0a591f14f.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7846,
          name: "Blessing Mouse Mobile",
          uuid: "f7a7294210484a2d8b350ea9b13b7080",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/f7a7294210484a2d8b350ea9b13b7080.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7863,
          name: "Blooming Riches",
          uuid: "33aa5e4df231446487f532faad89cad2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/33aa5e4df231446487f532faad89cad2.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7833,
          name: "Blooming Riches Mobile",
          uuid: "e7772555c7684331ac218bcf4489a812",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/e7772555c7684331ac218bcf4489a812.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7884,
          name: "Book of Dragons",
          uuid: "14975f91999b440ea5dd9b898e388c3f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/14975f91999b440ea5dd9b898e388c3f.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7854,
          name: "Book of Dragons Mobile",
          uuid: "fcebed86d8874767a39c2a6e2a8dd49d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/fcebed86d8874767a39c2a6e2a8dd49d.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7933,
          name: "Civilization of Bull Bull",
          uuid: "c7b8815a9be04033b25e71af0820683a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/c7b8815a9be04033b25e71af0820683a.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7941,
          name: "Civilization of Bull Bull 2",
          uuid: "79d5b8b034f7286ced0a117f86c4c13ba76681d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/79d5b8b034f7286ced0a117f86c4c13ba76681d3.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7942,
          name: "Civilization of Bull Bull 2 Mobile",
          uuid: "732f61298f2cab27d249eef43adf75487275ca0c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/732f61298f2cab27d249eef43adf75487275ca0c.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7934,
          name: "Civilization of Bull Bull Mobile",
          uuid: "44a6abab6dc4412d811dc53eb1e1f63f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/44a6abab6dc4412d811dc53eb1e1f63f.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7909,
          name: "Diamond Forever",
          uuid: "a79a3f12e499eabed2e1d58e3a609ff6d33a4dc4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/a79a3f12e499eabed2e1d58e3a609ff6d33a4dc4.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7910,
          name: "Diamond Forever Mobile",
          uuid: "b43b35d316fc973a11bda517ddecdb449da8c55a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/b43b35d316fc973a11bda517ddecdb449da8c55a.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7901,
          name: "Dinosaur Island",
          uuid: "496f119595d1cf050790b51b435496951f296f9a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/496f119595d1cf050790b51b435496951f296f9a.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7902,
          name: "Dinosaur Island Mobile",
          uuid: "880351d6126987affc0d660a032208dfc3bd976a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/880351d6126987affc0d660a032208dfc3bd976a.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7879,
          name: "DJ Rock",
          uuid: "36690ef755b14438b041b01d907b8db0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/36690ef755b14438b041b01d907b8db0.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7849,
          name: "DJ Rock Mobile",
          uuid: "19adef23a0ab4a3f810964ddfe84c1b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/19adef23a0ab4a3f810964ddfe84c1b2.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7872,
          name: "Dragon Dynasty",
          uuid: "9c795b11c33d45ff890bef7e398bdce5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/9c795b11c33d45ff890bef7e398bdce5.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7842,
          name: "Dragon Dynasty Mobile",
          uuid: "e17ff626d7874efb9bb8c90de641da52",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/e17ff626d7874efb9bb8c90de641da52.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7888,
          name: "Dragon/Tiger",
          uuid: "543ecfcb13d14129abcad94bef505226",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/543ecfcb13d14129abcad94bef505226.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7858,
          name: "Dragon/Tiger Mobile",
          uuid: "85a00f506c564d1cb500ff948d2274f6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/85a00f506c564d1cb500ff948d2274f6.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7877,
          name: "Five Blessings",
          uuid: "92b657e6535e4fb5ac48dd7363889e8e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/92b657e6535e4fb5ac48dd7363889e8e.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7847,
          name: "Five Blessings Mobile",
          uuid: "db94935978eb4e03af1979131c3336ab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/db94935978eb4e03af1979131c3336ab.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7869,
          name: "Fortune Hot Pot",
          uuid: "d66d3f16ee8e4805aa93db10630dd458",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/d66d3f16ee8e4805aa93db10630dd458.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7839,
          name: "Fortune Hot Pot Mobile",
          uuid: "de103030486e4d989fa340abcbd4e451",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/de103030486e4d989fa340abcbd4e451.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7935,
          name: "Fortune Lions",
          uuid: "6e866b5394c54be392e125c340501e75",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/6e866b5394c54be392e125c340501e75.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7936,
          name: "Fortune Lions Mobile",
          uuid: "4bbd7ade171f409b9f7db1d3ef78d6d3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/4bbd7ade171f409b9f7db1d3ef78d6d3.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7905,
          name: "Fortune Tiger",
          uuid: "765aa04f79d23369a18dc4b5bf4554184267309f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/765aa04f79d23369a18dc4b5bf4554184267309f.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7906,
          name: "Fortune Tiger Mobile",
          uuid: "a1c950fe1303585526481fc9ca13f00a704795df",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a1c950fe1303585526481fc9ca13f00a704795df.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7867,
          name: "Fun Monkey",
          uuid: "2b43b85ff9774fb5b5aa5747e8e68694",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/2b43b85ff9774fb5b5aa5747e8e68694.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7837,
          name: "Fun Monkey Mobile",
          uuid: "a42037b4578b4fe193e49bcf7bcbcb0f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/a42037b4578b4fe193e49bcf7bcbcb0f.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7937,
          name: "Giant Fruits",
          uuid: "2233f219d72f4079bb010b0addf1d29c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/2233f219d72f4079bb010b0addf1d29c.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7938,
          name: "Giant Fruits Mobile",
          uuid: "30364508e3f446f19f0a64a231e89f46",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/30364508e3f446f19f0a64a231e89f46.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7903,
          name: "Golden Dragon Mighty Cash",
          uuid: "03e8c56d03cf6e2746ea9e3800d763494c568f28",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/03e8c56d03cf6e2746ea9e3800d763494c568f28.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7904,
          name: "Golden Dragon Mighty Cash Mobile",
          uuid: "edb6b61a2b2f84b3fc29805dfaabaa1c768c91d6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/edb6b61a2b2f84b3fc29805dfaabaa1c768c91d6.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7895,
          name: "Golden Ox",
          uuid: "694044b532ed4cd68dfa6cfa46f11a55",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/694044b532ed4cd68dfa6cfa46f11a55.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7896,
          name: "Golden Ox Mobile",
          uuid: "52da86dee8c04819b001749a15dfe3de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/52da86dee8c04819b001749a15dfe3de.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7907,
          name: "Golden Stone Age",
          uuid: "fc5d071b3be6ca099f0ed80f607b3981b654c53d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fc5d071b3be6ca099f0ed80f607b3981b654c53d.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7908,
          name: "Golden Stone Age Mobile",
          uuid: "be25eda1ad28936bbbe3d1df7b2642ef68b13d69",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/be25eda1ad28936bbbe3d1df7b2642ef68b13d69.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7885,
          name: "Goldy Piggy",
          uuid: "a63b586ed0644cdfa49b74b9ae35283e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/a63b586ed0644cdfa49b74b9ae35283e.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7855,
          name: "Goldy Piggy Mobile",
          uuid: "e6d4d554f1fa4c6193b7430bb927f79f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/e6d4d554f1fa4c6193b7430bb927f79f.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7871,
          name: "Great Ocean",
          uuid: "444d0d97b9804ca9aec2b9bb72a50881",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/444d0d97b9804ca9aec2b9bb72a50881.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7841,
          name: "Great Ocean Mobile",
          uuid: "a722f58f783942caaf94f70a16403dc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/a722f58f783942caaf94f70a16403dc8.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7864,
          name: "High Way Kings",
          uuid: "df82e9eb90c24a5c983150afd3ad2f38",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/df82e9eb90c24a5c983150afd3ad2f38.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7834,
          name: "High Way Kings Mobile",
          uuid: "1d3dc7ea82bb49a8b939a0e078a9c433",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/1d3dc7ea82bb49a8b939a0e078a9c433.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7893,
          name: "HighWay Kings Deluxe",
          uuid: "e1cc05e57e28ef4604af5830445e374f7804b30b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e1cc05e57e28ef4604af5830445e374f7804b30b.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7894,
          name: "HighWay Kings Deluxe Mobile",
          uuid: "e1f42539bfc176ff25b0f8e4a61f112f7c4bde8f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e1f42539bfc176ff25b0f8e4a61f112f7c4bde8f.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7883,
          name: "Jin Ping Mei",
          uuid: "a0c8c2877a8045ebb65d28745b07778e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/a0c8c2877a8045ebb65d28745b07778e.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7853,
          name: "Jin Ping Mei Mobile",
          uuid: "dc74f54c9bda4c0e8d1cb2767c1f7533",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/dc74f54c9bda4c0e8d1cb2767c1f7533.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7899,
          name: "Journey To The West 2",
          uuid: "2f88863a620747c1a01242f6b801a550",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/2f88863a620747c1a01242f6b801a550.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7900,
          name: "Journey To The West 2 Mobile",
          uuid: "2362497921e44550bea40c8beb298064",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/2362497921e44550bea40c8beb298064.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7887,
          name: "Keno Neon",
          uuid: "bc9a7f86212d4b59967390e1cc2377c1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/bc9a7f86212d4b59967390e1cc2377c1.png",
          type: "keno",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7857,
          name: "Keno Neon Mobile",
          uuid: "c0da69630d284624b48aa3914446634e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/c0da69630d284624b48aa3914446634e.png",
          type: "keno",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7874,
          name: "King of Fruits",
          uuid: "79428cc221f848c7af38e3efc357874b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/79428cc221f848c7af38e3efc357874b.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7844,
          name: "King of Fruits Mobile",
          uuid: "a92ba8770d2b41a78db452e6c712cbd3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/a92ba8770d2b41a78db452e6c712cbd3.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7873,
          name: "King of Seven",
          uuid: "5567013f8c454da2aa238a763edc133e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/5567013f8c454da2aa238a763edc133e.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7843,
          name: "King of Seven Mobile",
          uuid: "31eeaf2bc982414c9481fd48a8c37751",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/31eeaf2bc982414c9481fd48a8c37751.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7913,
          name: "Knight Gambit",
          uuid: "690d3932c4df1da0a73fd8f3303a747aca8f8482",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/690d3932c4df1da0a73fd8f3303a747aca8f8482.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7914,
          name: "Knight Gambit Mobile",
          uuid: "6da1489cfce7d1f785a646b40f498d5bbd39e7d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6da1489cfce7d1f785a646b40f498d5bbd39e7d7.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7882,
          name: "Lost City of Gold",
          uuid: "e8de9074c5c341ff8dc4d4c463706923",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/e8de9074c5c341ff8dc4d4c463706923.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7852,
          name: "Lost City of Gold Mobile",
          uuid: "219658eb9c84475e9b31a6e7df050442",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/219658eb9c84475e9b31a6e7df050442.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7928,
          name: "Lucky 7",
          uuid: "1293c89b679f9d4355d7996107f0677cbb5ac8b0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/1293c89b679f9d4355d7996107f0677cbb5ac8b0.jpg",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7927,
          name: "Lucky 7 Mobile",
          uuid: "817e385317cc66651a105760f4618988a2f3bbda",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/817e385317cc66651a105760f4618988a2f3bbda.jpg",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7921,
          name: "Lucky Fortunes",
          uuid: "40723cca036e408088f3f3142d1b9753",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/40723cca036e408088f3f3142d1b9753.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7922,
          name: "Lucky Fortunes Mobile",
          uuid: "6bf0a3fb55df4fb0ab8720676d1ba534",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/6bf0a3fb55df4fb0ab8720676d1ba534.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7866,
          name: "Lucky Leprechaun",
          uuid: "9379aa3d218945aebdbb84f2c2a57d9b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/9379aa3d218945aebdbb84f2c2a57d9b.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7836,
          name: "Lucky Leprechaun Mobile",
          uuid: "1fbf2172345847c4954b262a6ab892e5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/1fbf2172345847c4954b262a6ab892e5.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7930,
          name: "Lucky Toad",
          uuid: "15bbf1883144b7a2156c29371506fdcc46ab1745",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/15bbf1883144b7a2156c29371506fdcc46ab1745.jpg",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7929,
          name: "Lucky Toad Mobile",
          uuid: "72713eb1a9c7ddc60e7efbaa2837a832d761e5e9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/72713eb1a9c7ddc60e7efbaa2837a832d761e5e9.jpg",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7897,
          name: "Mayan Treasure",
          uuid: "9751713d2f7f435db706ff72a935bd78",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/9751713d2f7f435db706ff72a935bd78.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7898,
          name: "Mayan Treasure Mobile",
          uuid: "a4d2ed3be3284e6c932fc3031168649e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/a4d2ed3be3284e6c932fc3031168649e.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7878,
          name: "Mermaid Treasure",
          uuid: "94922ddd5cc742d386e363178b5eb194",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/94922ddd5cc742d386e363178b5eb194.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7848,
          name: "Mermaid Treasure Mobile",
          uuid: "6ceeb881e71147d1bcd6b0230a26129e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/6ceeb881e71147d1bcd6b0230a26129e.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7870,
          name: "Mischievous Pirates",
          uuid: "02da39affda4404ca44d9ed4049c792c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/02da39affda4404ca44d9ed4049c792c.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7840,
          name: "Mischievous Pirates Mobile",
          uuid: "43b96d6d57f642a1b07dd5931caddd22",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/43b96d6d57f642a1b07dd5931caddd22.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7911,
          name: "Raging Bull",
          uuid: "aacef32128f55b73e8c2907a17464ad7d203b29e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aacef32128f55b73e8c2907a17464ad7d203b29e.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7912,
          name: "Raging Bull Mobile",
          uuid: "6d89591935bec845e32f11623e3b3f284aaea102",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6d89591935bec845e32f11623e3b3f284aaea102.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7932,
          name: "Rise of Thunder",
          uuid: "6429a46e8a36164b6f79fb481e0bc937b475c2ac",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/6429a46e8a36164b6f79fb481e0bc937b475c2ac.jpg",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7931,
          name: "Rise of Thunder Mobile",
          uuid: "3e8ce3fbfa1cbe94f1b9237defd8214404c53afd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/3e8ce3fbfa1cbe94f1b9237defd8214404c53afd.jpg",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7890,
          name: "San Gong",
          uuid: "bccecb01fc5b4e75ac9701dda7ce1a98",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/bccecb01fc5b4e75ac9701dda7ce1a98.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7860,
          name: "San Gong Mobile",
          uuid: "7b3688cf3ef44e9ea4a8eac77240ff9c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/7b3688cf3ef44e9ea4a8eac77240ff9c.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7889,
          name: "Sic-Bo",
          uuid: "556fea6c221c4e36b7d2f65eb29f4dbd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/556fea6c221c4e36b7d2f65eb29f4dbd.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7859,
          name: "Sic-Bo Mobile",
          uuid: "e25c637b1a3a4124be85a8e4feeb15f5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/e25c637b1a3a4124be85a8e4feeb15f5.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7891,
          name: "Super 6 Baccarat",
          uuid: "b1e4fc6796bc4ac4a4f02f218bf2fbd0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/b1e4fc6796bc4ac4a4f02f218bf2fbd0.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7861,
          name: "Super 6 Baccarat Mobile",
          uuid: "5634b9f70f6a47f198a41bbda37bed89",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/5634b9f70f6a47f198a41bbda37bed89.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7892,
          name: "Super Baccarat",
          uuid: "1285e959dbce4cfda8ab8bfc8a6f4d2d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/1285e959dbce4cfda8ab8bfc8a6f4d2d.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7862,
          name: "Super Baccarat Mobile",
          uuid: "a4cc823ce1e448148067d4fe748cd401",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/a4cc823ce1e448148067d4fe748cd401.png",
          type: "table",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7923,
          name: "Super Star",
          uuid: "ebb6aef266494be380f65040b8a6074d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/ebb6aef266494be380f65040b8a6074d.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7924,
          name: "Super Star Mobile",
          uuid: "cd4538e62f7041b4ba6e39d8a913dd4d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/cd4538e62f7041b4ba6e39d8a913dd4d.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7868,
          name: "TPG 777",
          uuid: "a779a2266759487d997de9a38923067a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/a779a2266759487d997de9a38923067a.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7838,
          name: "TPG 777 Mobile",
          uuid: "6ec73c4155844a4fa7b43ab36e52ca6b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/6ec73c4155844a4fa7b43ab36e52ca6b.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7880,
          name: "Vampires Feast",
          uuid: "ff703619d0a642009809c336d0bf7bb9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/ff703619d0a642009809c336d0bf7bb9.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7850,
          name: "Vampires Feast Mobile",
          uuid: "7352e07ddae24357b021351b8d1d562c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/7352e07ddae24357b021351b8d1d562c.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7939,
          name: "Wealth Arrives",
          uuid: "fb68c836cc334b9ea2a14622695808bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/fb68c836cc334b9ea2a14622695808bf.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7940,
          name: "Wealth Arrives Mobile",
          uuid: "5378c2fdf3a843f1a1850210c35dbd2a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/5378c2fdf3a843f1a1850210c35dbd2a.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7917,
          name: "Wild West Glory",
          uuid: "4a644ff84ddb4cad8eb4c194615e62b1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/4a644ff84ddb4cad8eb4c194615e62b1.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7918,
          name: "Wild West Glory Mobile",
          uuid: "e1324de7687046949038c23e72bc206e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/e1324de7687046949038c23e72bc206e.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7875,
          name: "Xian Xia",
          uuid: "349982801d27495d87713749878343b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/349982801d27495d87713749878343b8.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7845,
          name: "Xian Xia Mobile",
          uuid: "6350ab8270dd4ac99a1d1375e5f874ce",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/6350ab8270dd4ac99a1d1375e5f874ce.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7881,
          name: "Ying Cai Shen",
          uuid: "da61078aa535466bb90a450714779363",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/da61078aa535466bb90a450714779363.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7851,
          name: "Ying Cai Shen Mobile",
          uuid: "331b95b867eb4931a63c34fbdb6a19a4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/TripleProfitsGames/331b95b867eb4931a63c34fbdb6a19a4.png",
          type: "slots",
          provider: "TripleProfitsGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 160,
      name: "Turbogames",
      providerId: 48,
      providerName: "Turbogames",
      games: [
        {
          game_id: 2313,
          name: "1Tap Mines",
          uuid: "b07bd46cf3ee4dcab4c1fba2e9889c62",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/b07bd46cf3ee4dcab4c1fba2e9889c62.png",
          type: "instant win",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2310,
          name: "Aero",
          uuid: "a0613ce4d9c2aee3db4f8321741e187cb35f599c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/a0613ce4d9c2aee3db4f8321741e187cb35f599c.png",
          type: "crash",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2292,
          name: "Ball & Ball",
          uuid: "c7a64ae4763b4fd59b4747c5a0bd43f4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/c7a64ae4763b4fd59b4747c5a0bd43f4.png",
          type: "provably fair",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2294,
          name: "Bayraktar",
          uuid: "b672e0cc04ed48de810e2e18a9a47dc8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/b672e0cc04ed48de810e2e18a9a47dc8.png",
          type: "provably fair",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2314,
          name: "Book of Mines",
          uuid: "ad52ceeea4c24a44ae379f189a2e09a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/ad52ceeea4c24a44ae379f189a2e09a1.png",
          type: "instant win",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2309,
          name: "Bubbles",
          uuid: "0f9ba6277de545427dd64656115148eac595ab28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/0f9ba6277de545427dd64656115148eac595ab28.png",
          type: "provably fair",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2285,
          name: "Crash X",
          uuid: "28b9d6467c934b728066ca0af0738c39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/28b9d6467c934b728066ca0af0738c39.png",
          type: "crash",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2296,
          name: "Crash X Football Edition",
          uuid: "85c9146205eed9cd708173e46d9668e71cd3576e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/85c9146205eed9cd708173e46d9668e71cd3576e.png",
          type: "crash",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2316,
          name: "Crystal Poker",
          uuid: "db73bb1b0f784de78ec6607e791a89be",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/db73bb1b0f784de78ec6607e791a89be.png",
          type: "slots",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7944,
          name: "Dice Thrice",
          uuid: "39c28ad7624081fa9d9e322e4404807bfa27fd06",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/39c28ad7624081fa9d9e322e4404807bfa27fd06.png",
          type: "dice",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2286,
          name: "Dice Twice",
          uuid: "400dee13a1a2457f9588aed8b156b67a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/400dee13a1a2457f9588aed8b156b67a.png",
          type: "provably fair",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2301,
          name: "Dog's Street",
          uuid: "478a19128c7a471a9e90a949d1189ef6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/478a19128c7a471a9e90a949d1189ef6.png",
          type: "slots",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2317,
          name: "Donny King",
          uuid: "e8d58e908c4bf8878cad0d2334d9cd11cbcdb509",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/e8d58e908c4bf8878cad0d2334d9cd11cbcdb509.png",
          type: "instant game",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2302,
          name: "Double Roll",
          uuid: "b1bc6fe0368b45a88d46c4102c48b59f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/b1bc6fe0368b45a88d46c4102c48b59f.png",
          type: "slots",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2308,
          name: "Fast Fielder",
          uuid: "bec51570fd1f1c4e632c3ee833bc412453e49447",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/bec51570fd1f1c4e632c3ee833bc412453e49447.png",
          type: "other",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2303,
          name: "Fruit Towers",
          uuid: "ccab808332d0b054d7c121684ee533149053a00f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/ccab808332d0b054d7c121684ee533149053a00f.png",
          type: "slots",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2291,
          name: "Fury Stairs",
          uuid: "2d5cbf3324ce48f5a5df720c16a4e8e0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/2d5cbf3324ce48f5a5df720c16a4e8e0.png",
          type: "provably fair",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2287,
          name: "Hamsta",
          uuid: "f74c230b916945d190ec4f54d6a08da7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/f74c230b916945d190ec4f54d6a08da7.png",
          type: "provably fair",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2304,
          name: "HiLo",
          uuid: "b2011c4fb6bb4d6bb8b202b705edfd2d328d25e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/b2011c4fb6bb4d6bb8b202b705edfd2d328d25e1.png",
          type: "card",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2295,
          name: "JavelinX",
          uuid: "85c4fe9d2b6059339b6d4940b4e6de9af26a06a4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/85c4fe9d2b6059339b6d4940b4e6de9af26a06a4.png",
          type: "slots",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2293,
          name: "Limbo Rider",
          uuid: "556d8ef93573468692ab5c9e935ab1ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/556d8ef93573468692ab5c9e935ab1ef.png",
          type: "crash",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2300,
          name: "Magic Keno",
          uuid: "e0711aa575a8a3c4ac9cd49c48c797b5cab296a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/e0711aa575a8a3c4ac9cd49c48c797b5cab296a1.jpg",
          type: "keno",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2288,
          name: "Mines",
          uuid: "264f6bd9d93e4c98aa669ca8275cc89a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/264f6bd9d93e4c98aa669ca8275cc89a.png",
          type: "provably fair",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2315,
          name: "Multiplayer Mines",
          uuid: "185f0378c4c84501909265f9b502b761",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/185f0378c4c84501909265f9b502b761.png",
          type: "instant win",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2289,
          name: "Neko",
          uuid: "785260eeca2d4c60a22fc9003eb0a006",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/785260eeca2d4c60a22fc9003eb0a006.png",
          type: "provably fair",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7943,
          name: "PumpedX",
          uuid: "d03d99024d4eb4ec4a7650fe136b92a31b85609d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/d03d99024d4eb4ec4a7650fe136b92a31b85609d.png",
          type: "crash",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2297,
          name: "Save the Princess",
          uuid: "09f6692a49a430fd2e33037dc82e82f5d7e0fdb0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/09f6692a49a430fd2e33037dc82e82f5d7e0fdb0.png",
          type: "slots",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2305,
          name: "Spin Strike",
          uuid: "e1ce5c0813df9d5090211559fba2e015c7d5d542",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/e1ce5c0813df9d5090211559fba2e015c7d5d542.png",
          type: "provably fair",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2307,
          name: "Take My Plinko",
          uuid: "6c2a42ea006388718232896f6ad1fa4084ce9a88",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/6c2a42ea006388718232896f6ad1fa4084ce9a88.png",
          type: "other",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2290,
          name: "Towers",
          uuid: "5ec8feec03d44efda9d8d0558bf65b40",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/5ec8feec03d44efda9d8d0558bf65b40.png",
          type: "provably fair",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2312,
          name: "Trading Dice",
          uuid: "f248d480ac5692f1fb17811d0fa11458a3427230",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/f248d480ac5692f1fb17811d0fa11458a3427230.png",
          type: "dice",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2299,
          name: "Turbo Mines",
          uuid: "267242f074513afe257c7b5fb9908e9cd63c7ccf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/267242f074513afe257c7b5fb9908e9cd63c7ccf.png",
          type: "other",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2298,
          name: "Turbo Plinko",
          uuid: "e2d3b1e6bf61857a9f14104b5769275033381637",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e2d3b1e6bf61857a9f14104b5769275033381637.png",
          type: "other",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2311,
          name: "VORTEX",
          uuid: "898d68c263084212ab86357c92a49d05",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/898d68c263084212ab86357c92a49d05.png",
          type: "slots",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2306,
          name: "Wicket Blast",
          uuid: "7fdd8af9b64890abee7debbd851a514e94282963",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Turbogames/7fdd8af9b64890abee7debbd851a514e94282963.png",
          type: "other",
          provider: "Turbogames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 161,
      name: "Yggdrasil",
      providerId: 25,
      providerName: "Yggdrasil",
      games: [
        {
          game_id: 8135,
          name: "10x Rewind",
          uuid: "bea6f0c1f14c6e54635006ca619f73e089e10d7b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/bea6f0c1f14c6e54635006ca619f73e089e10d7b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8136,
          name: "10x Rewind Mobile",
          uuid: "29b30168d0684a0e8567577495959be8eff646e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/29b30168d0684a0e8567577495959be8eff646e1.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8117,
          name: "12 Trojan Mysteries",
          uuid: "bee72be410b3a15398cc80d0cad68a0a59e19100",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bee72be410b3a15398cc80d0cad68a0a59e19100.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8118,
          name: "12 Trojan Mysteries Mobile",
          uuid: "6106656ca4e4d16e596b7b261885481166a79d47",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6106656ca4e4d16e596b7b261885481166a79d47.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8184,
          name: "3021 AD The Bounty Hunter Gigablox",
          uuid: "c4c8c104ebfb4e908b8607b462442970",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/c4c8c104ebfb4e908b8607b462442970.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8183,
          name: "3021 AD The Bounty Hunter Gigablox Mobile",
          uuid: "bf8bcea95be2443c9a3015c642ff03fa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/bf8bcea95be2443c9a3015c642ff03fa.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8236,
          name: "777 Volt GigaBlox",
          uuid: "a3b06e73f9bf62ed30d74e2b2f53b01984d56a42",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/a3b06e73f9bf62ed30d74e2b2f53b01984d56a42.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8235,
          name: "777 Volt GigaBlox Mobile",
          uuid: "bb13bea501978eef0e31c4a928b17c41b1778f2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/bb13bea501978eef0e31c4a928b17c41b1778f2c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8157,
          name: "90k Yeti Gigablox",
          uuid: "09286f1481a8fbc651ac94920397de487ab0a374",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/09286f1481a8fbc651ac94920397de487ab0a374.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8158,
          name: "90k Yeti Gigablox Mobile",
          uuid: "1141b2ae9d5a07f183f4f78a997ecb36d5b83ffa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1141b2ae9d5a07f183f4f78a997ecb36d5b83ffa.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8011,
          name: "9K Yeti",
          uuid: "fbd49f1bcee4175ad5d59fee06387d77389b243d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fbd49f1bcee4175ad5d59fee06387d77389b243d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8012,
          name: "9K Yeti Mobile",
          uuid: "012f94d8fa204b51b49f2f7dcd54069da83fd88b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/012f94d8fa204b51b49f2f7dcd54069da83fd88b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8015,
          name: "Age of Asgard",
          uuid: "de5ec4c9c1f89816dac8c6fcd729e749335558e5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/de5ec4c9c1f89816dac8c6fcd729e749335558e5.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8016,
          name: "Age of Asgard Mobile",
          uuid: "382d24a5cab0436309a13c29114ef629010e7add",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/382d24a5cab0436309a13c29114ef629010e7add.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7981,
          name: "Alchymedes",
          uuid: "34b93f8c31d33f44b4ad9a9c31aa355f94e54f10",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/34b93f8c31d33f44b4ad9a9c31aa355f94e54f10.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7982,
          name: "Alchymedes Mobile",
          uuid: "7297b94ead751ab5358576b02504167dac1538d2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7297b94ead751ab5358576b02504167dac1538d2.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8013,
          name: "Aldo’s Journey",
          uuid: "846c1ef9445bab201151bb77c51ee2f6c81412d9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/846c1ef9445bab201151bb77c51ee2f6c81412d9.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8014,
          name: "Aldo’s Journey Mobile",
          uuid: "936b17d8159f765c4ebe1424c042a4079f4838e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/936b17d8159f765c4ebe1424c042a4079f4838e4.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8234,
          name: "Aphrodite Goes Wild RushingWilds",
          uuid: "19c22588eed82dce96a0b612933c3061fe2a376f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/19c22588eed82dce96a0b612933c3061fe2a376f.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8233,
          name: "Aphrodite Goes Wild RushingWilds Mobile",
          uuid: "2c51887a080bee8f2ebb2823d0c288861f089d16",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/2c51887a080bee8f2ebb2823d0c288861f089d16.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8063,
          name: "Artefacts - Vault of Fortune",
          uuid: "ca7f23420edd7226b2bc308b0ab6613f6c5c8ca3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ca7f23420edd7226b2bc308b0ab6613f6c5c8ca3.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8064,
          name: "Artefacts - Vault of Fortune Mobile",
          uuid: "96bb30dfbd70613b422b99ed0e1aada0aab3371b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/96bb30dfbd70613b422b99ed0e1aada0aab3371b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8057,
          name: "Arthur's Fortune",
          uuid: "2c8fe5cab8ef5eb411421b6b5276f08b1601c05f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2c8fe5cab8ef5eb411421b6b5276f08b1601c05f.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8058,
          name: "Arthur's Fortune Mobile",
          uuid: "9d4567c1b7a5602c8d8603881555cac1f8ff3668",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9d4567c1b7a5602c8d8603881555cac1f8ff3668.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8081,
          name: "Atlantis Megaways",
          uuid: "454988c4498024ea490e292b8699a3f8a63a55d7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/454988c4498024ea490e292b8699a3f8a63a55d7.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8082,
          name: "Atlantis Megaways Mobile",
          uuid: "c3fc165ad93b33dd4ce66f93700a25af8e092946",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c3fc165ad93b33dd4ce66f93700a25af8e092946.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8055,
          name: "AVATARS: GATEWAY GUARDIANS",
          uuid: "62250d15485b87ae498e89c1458f4276ee60d664",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/62250d15485b87ae498e89c1458f4276ee60d664.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8056,
          name: "AVATARS: GATEWAY GUARDIANS Mobile",
          uuid: "3c7940430bf24ecadcd25150ce163c7a669f0178",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3c7940430bf24ecadcd25150ce163c7a669f0178.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8223,
          name: "Badger Miners",
          uuid: "5284a11e16c04a42bc928440c1dd4c97",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/5284a11e16c04a42bc928440c1dd4c97.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8224,
          name: "Badger Miners Mobile",
          uuid: "57c2526b5f4547d4af038b514e43756e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/57c2526b5f4547d4af038b514e43756e.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8017,
          name: "Baron Samedi",
          uuid: "c8432bda44d7dfcd8a3e85c5fd0eb9500caebcf3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c8432bda44d7dfcd8a3e85c5fd0eb9500caebcf3.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8018,
          name: "Baron Samedi Mobile",
          uuid: "3284294d0fec004d4f764a9b149fffff605cb039",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3284294d0fec004d4f764a9b149fffff605cb039.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7983,
          name: "Beauty and the Beast",
          uuid: "4df6eb897db8c4b49ab66aac3839e41a9e34c306",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4df6eb897db8c4b49ab66aac3839e41a9e34c306.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7984,
          name: "Beauty and the Beast Mobile",
          uuid: "c08245e2512ed5461a2ddde7952b09dad7f2e146",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c08245e2512ed5461a2ddde7952b09dad7f2e146.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7973,
          name: "Bicicleta",
          uuid: "e31c263866691698475c73ea6678a7208829a051",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e31c263866691698475c73ea6678a7208829a051.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7974,
          name: "Bicicleta Mobile",
          uuid: "0975c09b099964a4d23101a5a10fc6237cf1c196",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0975c09b099964a4d23101a5a10fc6237cf1c196.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7975,
          name: "Big Blox",
          uuid: "ee91913ce6781ec5c8b4627633ba4a2f11f377a8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ee91913ce6781ec5c8b4627633ba4a2f11f377a8.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7976,
          name: "Big Blox Mobile",
          uuid: "6135d6817f915e8f9aefe7b85cdd1346cf9d7eab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6135d6817f915e8f9aefe7b85cdd1346cf9d7eab.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8085,
          name: "Big Bucks Bandits Megaways",
          uuid: "9a63662fad6619919cbbcf119175bfb8e66206b8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9a63662fad6619919cbbcf119175bfb8e66206b8.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8086,
          name: "Big Bucks Bandits Megaways Mobile",
          uuid: "6755a17bab665584914cd98c696aea0601cc2747",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6755a17bab665584914cd98c696aea0601cc2747.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8059,
          name: "Blood Moon Wilds",
          uuid: "2f31c05628620a80fc70b1898374b02f633033a9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2f31c05628620a80fc70b1898374b02f633033a9.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8060,
          name: "Blood Moon Wilds Mobile",
          uuid: "b049cedc649081f8cbad81f5a1f4181af3f305f8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b049cedc649081f8cbad81f5a1f4181af3f305f8.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8163,
          name: "Boilin’ Pots",
          uuid: "d0534341fe68917fd54e540110d67519b9dbde0e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d0534341fe68917fd54e540110d67519b9dbde0e.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8164,
          name: "Boilin’ Pots Mobile",
          uuid: "77bd28973abf73681a2d400efc1814dea713ba2e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/77bd28973abf73681a2d400efc1814dea713ba2e.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8019,
          name: "Brazil Bomba",
          uuid: "54327c0a50a5676c7b2c3b119028315622550855",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/54327c0a50a5676c7b2c3b119028315622550855.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8020,
          name: "Brazil Bomba Mobile",
          uuid: "7a92dd53d4a027100518cc74c57055e1efdb7d81",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7a92dd53d4a027100518cc74c57055e1efdb7d81.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8093,
          name: "Buster Hammer Carnival",
          uuid: "27db76dd4fb882fc3d03fc5120115881c7adead5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/27db76dd4fb882fc3d03fc5120115881c7adead5.jpeg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8094,
          name: "Buster Hammer Carnival Mobile",
          uuid: "47a3b46a5a39a5233ced4936019900bc8d89699a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/47a3b46a5a39a5233ced4936019900bc8d89699a.jpeg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8173,
          name: "Calavera Crush",
          uuid: "f3bed85bdb761f9dc54b28fe9e2b8104b17ab1c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/f3bed85bdb761f9dc54b28fe9e2b8104b17ab1c2.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8174,
          name: "Calavera Crush Mobile",
          uuid: "e382f368897cb9c675399d5a7ace3cefac89adda",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/e382f368897cb9c675399d5a7ace3cefac89adda.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8197,
          name: "Candyfinity",
          uuid: "c89db19710ca4159a14e41c1ab4c9ce8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/c89db19710ca4159a14e41c1ab4c9ce8.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8198,
          name: "Candyfinity Mobile",
          uuid: "0700dc1606ec4a20a4b373c81ff9298b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/0700dc1606ec4a20a4b373c81ff9298b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8077,
          name: "Carol of The Elves",
          uuid: "eee2e42412681635346c024b585be4f03ed36b6c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/eee2e42412681635346c024b585be4f03ed36b6c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8078,
          name: "Carol of The Elves Mobile",
          uuid: "658e8977f2a24210f30b71ae81f87cc43ed553d6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/658e8977f2a24210f30b71ae81f87cc43ed553d6.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8105,
          name: "Cauldron",
          uuid: "c59e11441e5c16542664f4fcddb020146699adcb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c59e11441e5c16542664f4fcddb020146699adcb.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8106,
          name: "Cauldron Mobile",
          uuid: "40e77b15aa2b8128223d5ddab262b54a54b583df",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/40e77b15aa2b8128223d5ddab262b54a54b583df.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7953,
          name: "Cazino Cosmos",
          uuid: "f2cf78009c36216ac680cc5cd2bd07c38780ea8b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f2cf78009c36216ac680cc5cd2bd07c38780ea8b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7954,
          name: "Cazino Cosmos Mobile",
          uuid: "9b5e2cb6d14341849e4b93086ed476e8d7537c9e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9b5e2cb6d14341849e4b93086ed476e8d7537c9e.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7947,
          name: "Cazino Zeppelin",
          uuid: "e442b1dfb8ab2ca658dec21b2cc8b8cb3bef185a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e442b1dfb8ab2ca658dec21b2cc8b8cb3bef185a.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7948,
          name: "Cazino Zeppelin Mobile",
          uuid: "df08a21459d2f9a7f37c134cc03e4cc4d10ff56f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/df08a21459d2f9a7f37c134cc03e4cc4d10ff56f.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8161,
          name: "Cazino Zeppelin Reloaded",
          uuid: "ae20cab69864994e7af25b4940958e5c27151a06",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ae20cab69864994e7af25b4940958e5c27151a06.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8162,
          name: "Cazino Zeppelin Reloaded Mobile",
          uuid: "6ca0984d8873de1392d9080d88d652c2bcaaf157",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6ca0984d8873de1392d9080d88d652c2bcaaf157.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8175,
          name: "Champion of the Underworld Gigablox Wild Fight",
          uuid: "54aecda7511e885782e8517bbe607c20b3210f28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/54aecda7511e885782e8517bbe607c20b3210f28.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8176,
          name: "Champion of the Underworld Gigablox Wild Fight Mobile",
          uuid: "5b2341a79984b3ffda939aeebbba6f50f7143c90",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/5b2341a79984b3ffda939aeebbba6f50f7143c90.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7949,
          name: "Champions of Rome",
          uuid: "1360829adf8f5348bec6b46e42d1a68f098d315c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1360829adf8f5348bec6b46e42d1a68f098d315c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7950,
          name: "Champions of Rome Mobile",
          uuid: "11cf67c75c2bbf3bd706e589888c7c8347f7a15c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/11cf67c75c2bbf3bd706e589888c7c8347f7a15c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7957,
          name: "Chibeasties",
          uuid: "a0f712e5d82c48d8a11ed1452f4d89ab4ae66de6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a0f712e5d82c48d8a11ed1452f4d89ab4ae66de6.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7958,
          name: "Chibeasties Mobile",
          uuid: "5109e0b2ad727e82c3a33f2f8a94776da47414a1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5109e0b2ad727e82c3a33f2f8a94776da47414a1.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8187,
          name: "Christmas Plaza DoubleMax",
          uuid: "44a67bed40fef485a0189801aa8e82a5f5a90944",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/44a67bed40fef485a0189801aa8e82a5f5a90944.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8188,
          name: "Christmas Plaza DoubleMax Mobile",
          uuid: "a1e127ad8504651e0a7ff50f47190ab660b55618",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/a1e127ad8504651e0a7ff50f47190ab660b55618.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8125,
          name: "Crystal Falls",
          uuid: "814c8ec0352177a2b7ccf15138cff5318e21c541",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/814c8ec0352177a2b7ccf15138cff5318e21c541.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8126,
          name: "Crystal Falls Mobile",
          uuid: "38e6971e93218f369eee62dd722f55fef6a92313",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/38e6971e93218f369eee62dd722f55fef6a92313.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8021,
          name: "Dark Vortex",
          uuid: "1c813754294fc1ab5e17172a506eb2d3aeffc89e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1c813754294fc1ab5e17172a506eb2d3aeffc89e.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8022,
          name: "Dark Vortex Mobile",
          uuid: "09d9f70a062e99ef39f3352431c66e388bc456b2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/09d9f70a062e99ef39f3352431c66e388bc456b2.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8217,
          name: "Defenders of Mystica",
          uuid: "67dc2404f5de47da8513aeb3f4cd0542",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/67dc2404f5de47da8513aeb3f4cd0542.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8218,
          name: "Defenders of Mystica Mobile",
          uuid: "c38f6462f8ed442185c1ce26184e50cc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/c38f6462f8ed442185c1ce26184e50cc.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8095,
          name: "Desperate Dawgs",
          uuid: "1cebcdf14d8a3332319dc71f91fb4ca14fe09b8a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/1cebcdf14d8a3332319dc71f91fb4ca14fe09b8a.jpeg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8096,
          name: "Desperate Dawgs Mobile",
          uuid: "c12c6c773d47731dbfb0f4ed79ae73a1b11787ef",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/c12c6c773d47731dbfb0f4ed79ae73a1b11787ef.jpeg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8201,
          name: "Devour the Weak",
          uuid: "ef0b9aa8318d4118801109d8f18b2b7a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/ef0b9aa8318d4118801109d8f18b2b7a.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8202,
          name: "Devour the Weak Mobile",
          uuid: "e5ffa4db73f9474c803f8cfca9197676",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/e5ffa4db73f9474c803f8cfca9197676.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7977,
          name: "Double Dragons",
          uuid: "dc49dfcefdbf67e4fcd44f0311dcba98e0e131a6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/dc49dfcefdbf67e4fcd44f0311dcba98e0e131a6.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7978,
          name: "Double Dragons Mobile",
          uuid: "b8ad42d447d17234a06174eac1399cb35421493d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b8ad42d447d17234a06174eac1399cb35421493d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8023,
          name: "Dwarf Mine",
          uuid: "8158a91526b93a747e452bab9df9583d5a760997",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8158a91526b93a747e452bab9df9583d5a760997.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8024,
          name: "Dwarf Mine Mobile",
          uuid: "1f7c41c2e144ce3f32a1df2ee3482acaf2c5c11a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1f7c41c2e144ce3f32a1df2ee3482acaf2c5c11a.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8193,
          name: "E-Force",
          uuid: "36ffa37c23f01f458c9ac6f5674fc14a812294bb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/36ffa37c23f01f458c9ac6f5674fc14a812294bb.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8194,
          name: "E-Force Mobile",
          uuid: "cd829feb2205be082e25529626ac8b04997ef36c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/cd829feb2205be082e25529626ac8b04997ef36c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7989,
          name: "Easter Island",
          uuid: "4ad7b1c21fddeb0f42d5dd928b38d2e7cc92b4a0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4ad7b1c21fddeb0f42d5dd928b38d2e7cc92b4a0.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8091,
          name: "Easter Island 2",
          uuid: "51d668e8b2f4319b7cf73c16429dacbca2088bf8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/51d668e8b2f4319b7cf73c16429dacbca2088bf8.jpeg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8092,
          name: "Easter Island 2 Mobile",
          uuid: "a3ebce10b162084f2552dceece9e7259623c4764",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/a3ebce10b162084f2552dceece9e7259623c4764.jpeg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7990,
          name: "Easter Island Mobile",
          uuid: "f148d11588e3f560cc40d96761191846e658fe80",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f148d11588e3f560cc40d96761191846e658fe80.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8089,
          name: "El Dorado Infinity Reels",
          uuid: "be59732495acb1e031108ca194b80fc58d754e93",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/be59732495acb1e031108ca194b80fc58d754e93.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8090,
          name: "El Dorado Infinity Reels Mobile",
          uuid: "947c2378149144db5f20c865cdb2e0575c81ebbd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/947c2378149144db5f20c865cdb2e0575c81ebbd.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8209,
          name: "Enchanted Waters",
          uuid: "c1035270d39f49c9862ec1fc9e396895",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/c1035270d39f49c9862ec1fc9e396895.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8210,
          name: "Enchanted Waters Mobile",
          uuid: "a6879418db2d40eab05d7ac0795fa84e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/a6879418db2d40eab05d7ac0795fa84e.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8215,
          name: "Fatz’s Diner Gigablox",
          uuid: "c0a1eefa640a41ca9e5e054d026eb221",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/c0a1eefa640a41ca9e5e054d026eb221.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8216,
          name: "Fatz’s Diner Gigablox Mobile",
          uuid: "5c098627d498409e9d84a579a90b53c2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/5c098627d498409e9d84a579a90b53c2.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8185,
          name: "Firekick! MultiMax",
          uuid: "0f0420afea201ff8bb3c81cbedb1bab21f9ba0fd",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/0f0420afea201ff8bb3c81cbedb1bab21f9ba0fd.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8186,
          name: "Firekick! MultiMax Mobile",
          uuid: "a8597e9878be1fce9183019e455b6c4c4e7a3f2e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/a8597e9878be1fce9183019e455b6c4c4e7a3f2e.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8182,
          name: "Florageddon",
          uuid: "6ad3e7a1f12441d59fe2323c1c3d34a6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/6ad3e7a1f12441d59fe2323c1c3d34a6.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8181,
          name: "Florageddon Mobile",
          uuid: "bd96dc1e9de448818ddadca300b09a33",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/bd96dc1e9de448818ddadca300b09a33.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8065,
          name: "Football Glory",
          uuid: "9fd6353482f2dacb7955c269f42a9e662adf4b3b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9fd6353482f2dacb7955c269f42a9e662adf4b3b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8066,
          name: "Football Glory Mobile",
          uuid: "1deb9e8fa5df03eb9442d9d584b644ccd705d793",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1deb9e8fa5df03eb9442d9d584b644ccd705d793.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7991,
          name: "Fruitoids",
          uuid: "6e2eb6c14e678c5ede01b5d57fc27fdd341f0669",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6e2eb6c14e678c5ede01b5d57fc27fdd341f0669.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7992,
          name: "Fruitoids Mobile",
          uuid: "02667cc81875afe601cedfcffa7876eb6eba8480",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/02667cc81875afe601cedfcffa7876eb6eba8480.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8113,
          name: "Gargoyle Infinity Reels",
          uuid: "d69a121b132331c0e3b864999844b281e0ca3e6b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d69a121b132331c0e3b864999844b281e0ca3e6b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8114,
          name: "Gargoyle Infinity Reels Mobile",
          uuid: "6d109eddc12eddb9b6e13410853febbe9745934f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6d109eddc12eddb9b6e13410853febbe9745934f.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8107,
          name: "Gator Gold",
          uuid: "3a8589ba602d9eb56103a310fc08a87d68e6ef7c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3a8589ba602d9eb56103a310fc08a87d68e6ef7c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8159,
          name: "Gator Gold Deluxe Gigablox",
          uuid: "ec182dcc12f483e8316dd9cee54b34d7925ae94a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ec182dcc12f483e8316dd9cee54b34d7925ae94a.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8160,
          name: "Gator Gold Deluxe Gigablox Mobile",
          uuid: "1ac5955973607761c539de2eaa8507b066389f76",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1ac5955973607761c539de2eaa8507b066389f76.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8180,
          name: "Gator Gold Gigablox",
          uuid: "66781f0682f04308806cb13590a9d4b6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/66781f0682f04308806cb13590a9d4b6.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8179,
          name: "Gator Gold Gigablox Mobile",
          uuid: "248702a776cc4698b70b3a30a348b436",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/248702a776cc4698b70b3a30a348b436.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8108,
          name: "Gator Gold Mobile",
          uuid: "345b63ef0403f09d0f824de72b46949064eeba2c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/345b63ef0403f09d0f824de72b46949064eeba2c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7993,
          name: "Gem Rocks",
          uuid: "242b725dc91d857ee0f07e04f63024927f28088f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/242b725dc91d857ee0f07e04f63024927f28088f.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7994,
          name: "Gem Rocks Mobile",
          uuid: "2ac267508f3e6f6d2ba9215b7aeeded9d9d530ed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2ac267508f3e6f6d2ba9215b7aeeded9d9d530ed.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8147,
          name: "Gems Infinity Reels",
          uuid: "2ade1f1579ce679b154e0113d202cdb161637a23",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2ade1f1579ce679b154e0113d202cdb161637a23.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8148,
          name: "Gems Infinity Reels Mobile",
          uuid: "a2d10ae97cbc761a1bd565f757219fa4a6155cfa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a2d10ae97cbc761a1bd565f757219fa4a6155cfa.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8211,
          name: "Gigagong Gigablox",
          uuid: "1c85ad3c73ef48dcb25e27b3b7dc90db",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/1c85ad3c73ef48dcb25e27b3b7dc90db.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8212,
          name: "Gigagong Gigablox Mobile",
          uuid: "db21a4277d78491daedf2216889bca45",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/db21a4277d78491daedf2216889bca45.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8195,
          name: "Giganimals Gigablox",
          uuid: "a9f34bb7cec34a79ac63812e3217690f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/a9f34bb7cec34a79ac63812e3217690f.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8196,
          name: "Giganimals Gigablox Mobile",
          uuid: "b8246382fbfe4eccb1b63109af44b58c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/b8246382fbfe4eccb1b63109af44b58c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8083,
          name: "Giza Infinity Reels",
          uuid: "a7b46e4bc9a8d31b5f5e9d77334fcf7624eb6328",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a7b46e4bc9a8d31b5f5e9d77334fcf7624eb6328.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8084,
          name: "Giza Infinity Reels Mobile",
          uuid: "a7c255a84b8a5a00464915808245761c7242ee55",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a7c255a84b8a5a00464915808245761c7242ee55.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8051,
          name: "Golden Chip Roulette",
          uuid: "58bd9c22f7338410ad392ce45e3b8ab0ae88d9f8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/58bd9c22f7338410ad392ce45e3b8ab0ae88d9f8.png",
          type: "roulette",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8052,
          name: "Golden Chip Roulette Mobile",
          uuid: "1228c9c1e6a08dd9c6ba543061ead15f9dc19bbc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1228c9c1e6a08dd9c6ba543061ead15f9dc19bbc.png",
          type: "roulette",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7951,
          name: "Golden Fish Tank",
          uuid: "88b2e1fa9bb4ffd8737aba458973750e7cfcf473",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/88b2e1fa9bb4ffd8737aba458973750e7cfcf473.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8149,
          name: "Golden Fish Tank 2 Gigablox",
          uuid: "b587a450cd62060e26cac4ddf1206e29cbf9c4a5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b587a450cd62060e26cac4ddf1206e29cbf9c4a5.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8150,
          name: "Golden Fish Tank 2 Gigablox Mobile",
          uuid: "2e3d5ab62b6821043fbd9b95eb1bc72d0d5034aa",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2e3d5ab62b6821043fbd9b95eb1bc72d0d5034aa.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7952,
          name: "Golden Fish Tank Mobile",
          uuid: "6eb90ec81460e70b88cb56ab4c6672668bab53ae",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6eb90ec81460e70b88cb56ab4c6672668bab53ae.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8123,
          name: "Golden Gorgon",
          uuid: "1f7705e01d99fe1a0825bfa1988ac3763bab13b3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1f7705e01d99fe1a0825bfa1988ac3763bab13b3.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8124,
          name: "Golden Gorgon Mobile",
          uuid: "6bff9e34a539462cac4be9a7d6a61c79751fd9e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6bff9e34a539462cac4be9a7d6a61c79751fd9e4.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8141,
          name: "Golden Haul Infinity Reels",
          uuid: "8b46c8e0fd6b166c19bde6a5df4058ca650df564",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8b46c8e0fd6b166c19bde6a5df4058ca650df564.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8142,
          name: "Golden Haul Infinity Reels Mobile",
          uuid: "eae55c624f4e95e70e8c35ef83de6c79cbc1840a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/eae55c624f4e95e70e8c35ef83de6c79cbc1840a.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8073,
          name: "Hades Gigablox",
          uuid: "be59b087075d937e63d3921de37e9067084076b5",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/be59b087075d937e63d3921de37e9067084076b5.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8074,
          name: "Hades Gigablox Mobile",
          uuid: "4623253a82daec7434ddf142d32d84b03cd08776",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4623253a82daec7434ddf142d32d84b03cd08776.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8137,
          name: "Hammer of Gods",
          uuid: "12205db1d324ae355b9738c09c4124ae057595ca",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/12205db1d324ae355b9738c09c4124ae057595ca.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8138,
          name: "Hammer of Gods Mobile",
          uuid: "aa21bae5d723d54452405690f8d00283e3791c4d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aa21bae5d723d54452405690f8d00283e3791c4d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7995,
          name: "Hanzo's Dojo",
          uuid: "9b29d1211ec9006c2bf76c09ff7bea858e48b16a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9b29d1211ec9006c2bf76c09ff7bea858e48b16a.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7996,
          name: "Hanzo's Dojo Mobile",
          uuid: "7a60d2f5b6da976b8d1c6422ce8a0034f89e4af2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7a60d2f5b6da976b8d1c6422ce8a0034f89e4af2.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8145,
          name: "Hunters Moon Gigablox",
          uuid: "672a92d8309d14aa0d590fcf7f729b28bb108759",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/672a92d8309d14aa0d590fcf7f729b28bb108759.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8146,
          name: "Hunters Moon Gigablox Mobile",
          uuid: "95b845ce6d91a69be5a2acf9ecaab39fc24ec4e7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/95b845ce6d91a69be5a2acf9ecaab39fc24ec4e7.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8069,
          name: "Hyperburst",
          uuid: "83d531e78f8848242d128baf08281a3b10d8aea4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/83d531e78f8848242d128baf08281a3b10d8aea4.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8070,
          name: "Hyperburst Mobile",
          uuid: "57e54d5fc9b91cea13ee75ca76f700269d0bdd1f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/57e54d5fc9b91cea13ee75ca76f700269d0bdd1f.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8151,
          name: "Hypernova 10K Ways",
          uuid: "efffd86beaa665241ce9aeb37d8b18bd9567d60b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/efffd86beaa665241ce9aeb37d8b18bd9567d60b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8152,
          name: "Hypernova 10K Ways Mobile",
          uuid: "3c4259bdf56b03933377396acc68b78821dd32ca",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3c4259bdf56b03933377396acc68b78821dd32ca.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7963,
          name: "Incinerator",
          uuid: "539b810019b4314ebe28848ab70610f9aeb3fbf1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/539b810019b4314ebe28848ab70610f9aeb3fbf1.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7964,
          name: "Incinerator Mobile",
          uuid: "18eba8a642b40f4939c162ae87ef6595e8b8b6af",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/18eba8a642b40f4939c162ae87ef6595e8b8b6af.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8097,
          name: "Johnan Legendarian",
          uuid: "a7b6488f2e6873187b1befddc342bb65ff7ba0dc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/a7b6488f2e6873187b1befddc342bb65ff7ba0dc.jpeg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8098,
          name: "Johnan Legendarian Mobile",
          uuid: "97f280baec334756393e19600d42de97d3dec071",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/97f280baec334756393e19600d42de97d3dec071.jpeg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7965,
          name: "Jokerizer",
          uuid: "b962a5e7d65f3b1e63311862bdb686acb8d2db19",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b962a5e7d65f3b1e63311862bdb686acb8d2db19.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7966,
          name: "Jokerizer Mobile",
          uuid: "79af38f9a822f1daeb48c413b1837a026591675b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/79af38f9a822f1daeb48c413b1837a026591675b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7997,
          name: "Jungle Books",
          uuid: "649d284819c9d784581351cdc3c9e371b921aa26",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/649d284819c9d784581351cdc3c9e371b921aa26.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7998,
          name: "Jungle Books Mobile",
          uuid: "bce98898a1a4fe3e4c5151baec429d5567e4e60b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bce98898a1a4fe3e4c5151baec429d5567e4e60b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8115,
          name: "Krazy Klimber",
          uuid: "65469eac1cf646b91e77541513f32a3ec43a0211",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/65469eac1cf646b91e77541513f32a3ec43a0211.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8116,
          name: "Krazy Klimber Mobile",
          uuid: "df6b859471c323f9321f81704751d2bf3ae4e105",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/df6b859471c323f9321f81704751d2bf3ae4e105.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7967,
          name: "Legend of the Golden Monkey",
          uuid: "61a818977fa075a8cedb0cb3c20994a3540ff494",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/61a818977fa075a8cedb0cb3c20994a3540ff494.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7968,
          name: "Legend of the Golden Monkey Mobile",
          uuid: "25c1dba3999bd7eaec450496a24cafceea0bc5e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/25c1dba3999bd7eaec450496a24cafceea0bc5e3.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8111,
          name: "Legion Hot 1",
          uuid: "55a7bb30675ed7719d96584421d3d9199798968b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/55a7bb30675ed7719d96584421d3d9199798968b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8112,
          name: "Legion Hot 1 Mobile",
          uuid: "0327b3ea4f3e808f2f3784cf1a49354c1941a87d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0327b3ea4f3e808f2f3784cf1a49354c1941a87d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8053,
          name: "Lightning Joker",
          uuid: "36e35e9b7fa61ab7f088cdde75e2450ac18861d9",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/36e35e9b7fa61ab7f088cdde75e2450ac18861d9.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8054,
          name: "Lightning Joker Mobile",
          uuid: "311371b6417a7e58b7dc964b7d9404bc803f11dc",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/311371b6417a7e58b7dc964b7d9404bc803f11dc.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7999,
          name: "Lucha Maniacs",
          uuid: "500f980d2a5b9dbd73c0feb5353b110c72704984",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/500f980d2a5b9dbd73c0feb5353b110c72704984.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8000,
          name: "Lucha Maniacs Mobile",
          uuid: "6341bb3b538d35feaf09e59b1f6f506ee8dae193",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6341bb3b538d35feaf09e59b1f6f506ee8dae193.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8061,
          name: "Lucky Neko - Gigablox",
          uuid: "0499e2aaae0ef2b44ce4ec2d98bec2fb4ac160e4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0499e2aaae0ef2b44ce4ec2d98bec2fb4ac160e4.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8062,
          name: "Lucky Neko - Gigablox Mobile",
          uuid: "545bb478d7e30d78782d22784fc9b4ee95d21aed",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/545bb478d7e30d78782d22784fc9b4ee95d21aed.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8143,
          name: "Medusa Hot1",
          uuid: "cf8c397a6db7aac280e1d642187c861ffb04b8fb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cf8c397a6db7aac280e1d642187c861ffb04b8fb.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8144,
          name: "Medusa Hot1 Mobile",
          uuid: "3884ff670cdcb4be08354f3748e7968992b506ea",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3884ff670cdcb4be08354f3748e7968992b506ea.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8169,
          name: "MexoMax!",
          uuid: "0cd9bd8be9ecc15db824ed17a1520ae422264032",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0cd9bd8be9ecc15db824ed17a1520ae422264032.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8170,
          name: "MexoMax! Mobile",
          uuid: "fb6f982f126373359d957f1588ced1d96cd394b8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/fb6f982f126373359d957f1588ced1d96cd394b8.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8071,
          name: "Moley Moolah",
          uuid: "cc9f035e881648d8ecc63fdd4f0467f64ee103f0",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cc9f035e881648d8ecc63fdd4f0467f64ee103f0.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8072,
          name: "Moley Moolah Mobile",
          uuid: "ce499e1f4e235033cef5d1e30544907b247faf83",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ce499e1f4e235033cef5d1e30544907b247faf83.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8238,
          name: "Monkeys Go Bananas MultiMax",
          uuid: "022663f4c0df19a6a261898b9e1d43983b509a24",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/022663f4c0df19a6a261898b9e1d43983b509a24.jpg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8237,
          name: "Monkeys Go Bananas MultiMax Mobile",
          uuid: "80027b620b428082664e9e1ad5429d25ba622f26",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/80027b620b428082664e9e1ad5429d25ba622f26.jpg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8025,
          name: "Multifly",
          uuid: "9bc5d1cb83067b6c4892bef6001010621bbeacba",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9bc5d1cb83067b6c4892bef6001010621bbeacba.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8026,
          name: "Multifly Mobile",
          uuid: "41b92e9c7dc718b824f1cfb8047aa7262c1c23a4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/41b92e9c7dc718b824f1cfb8047aa7262c1c23a4.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8049,
          name: "Neon Rush: Splitz",
          uuid: "155faab85f6b73a4b6f9544d20dbe2d92ecfed8f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/155faab85f6b73a4b6f9544d20dbe2d92ecfed8f.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8050,
          name: "Neon Rush: Splitz Mobile",
          uuid: "686124e84dbe2d24b047e1b4745f8ad9ffb29bf6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/686124e84dbe2d24b047e1b4745f8ad9ffb29bf6.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8189,
          name: "Neon Villains Doublemax",
          uuid: "192afe73419c1e1cc87673d691e69c2dd5a53259",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/192afe73419c1e1cc87673d691e69c2dd5a53259.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8190,
          name: "Neon Villains Doublemax Mobile",
          uuid: "096cd0fd2741fdd50c02636bc7afbf0329f28b31",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/096cd0fd2741fdd50c02636bc7afbf0329f28b31.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8232,
          name: "Nice Catch 2",
          uuid: "f9e6cbda9ca341678b9508fe353d57c5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/f9e6cbda9ca341678b9508fe353d57c5.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8231,
          name: "Nice Catch 2 Mobile",
          uuid: "0712e69ec0e1457fa7394b223fe9427a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/0712e69ec0e1457fa7394b223fe9427a.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8203,
          name: "Nice Catch Doublemax",
          uuid: "e8760c35b5ae4e95a2689745c35061a7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/e8760c35b5ae4e95a2689745c35061a7.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8204,
          name: "Nice Catch Doublemax Mobile",
          uuid: "230bfb81034d4cf09a1a7d87a073e880",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/230bfb81034d4cf09a1a7d87a073e880.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7979,
          name: "Nirvana",
          uuid: "97a6d5aee46c58983089335545628a91ab293bfd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/97a6d5aee46c58983089335545628a91ab293bfd.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7980,
          name: "Nirvana Mobile",
          uuid: "b28f98a982e6fce66291e66f1b93db7a819c2af1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b28f98a982e6fce66291e66f1b93db7a819c2af1.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8225,
          name: "Notre-Dame Tales Gigablox",
          uuid: "8ede8de50fc84ae885cadd010f507257",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/8ede8de50fc84ae885cadd010f507257.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8226,
          name: "Notre-Dame Tales Gigablox Mobile",
          uuid: "0607cdac2dc148a082e60b4ca2119d28",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/0607cdac2dc148a082e60b4ca2119d28.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8099,
          name: "Odin Infinity Reels",
          uuid: "db9d1cdea4ea996468dc40697864cf752d155b5c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/db9d1cdea4ea996468dc40697864cf752d155b5c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8100,
          name: "Odin Infinity Reels Mobile",
          uuid: "1458510f05af666b00e5ab1dcf4b460bda7a955f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1458510f05af666b00e5ab1dcf4b460bda7a955f.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8171,
          name: "Of Sabers and Monsters",
          uuid: "999054f4ef2b4cd43a4c320661e837fa2563a949",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/999054f4ef2b4cd43a4c320661e837fa2563a949.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8172,
          name: "Of Sabers and Monsters Mobile",
          uuid: "c4420cef9398e29beae55239860b7e0518d8dd10",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/c4420cef9398e29beae55239860b7e0518d8dd10.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8001,
          name: "Orient Express",
          uuid: "708deae9ffbfa7411eedaefa03ab6646c8e4fda7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/708deae9ffbfa7411eedaefa03ab6646c8e4fda7.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8002,
          name: "Orient Express Mobile",
          uuid: "1fb884edf99800f90dde7706531441c5d3a97ece",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1fb884edf99800f90dde7706531441c5d3a97ece.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8003,
          name: "Penguin City",
          uuid: "ba385779fbb1b36fe1cb4f1ac5025b41e935a057",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ba385779fbb1b36fe1cb4f1ac5025b41e935a057.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8004,
          name: "Penguin City Mobile",
          uuid: "564cad458e4c78b75ae18837f299a880ba41d871",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/564cad458e4c78b75ae18837f299a880ba41d871.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8079,
          name: "Pirates 2: Mutiny",
          uuid: "61b0e50a129c203cb75b75f4f2e42cd0ea58632d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/61b0e50a129c203cb75b75f4f2e42cd0ea58632d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8080,
          name: "Pirates 2: Mutiny Mobile",
          uuid: "8cc6b5b1852b52942d0299dc07d34be85f27e4bf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8cc6b5b1852b52942d0299dc07d34be85f27e4bf.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8045,
          name: "Pirates: Smugglers Paradise",
          uuid: "b90457736ea4aea4af3aa0ee199e78beba828611",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b90457736ea4aea4af3aa0ee199e78beba828611.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8046,
          name: "Pirates: Smugglers Paradise Mobile",
          uuid: "5a77aed0bdc243deba9e835022f7c5d7586de87b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5a77aed0bdc243deba9e835022f7c5d7586de87b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8005,
          name: "Pumpkin Smash",
          uuid: "aec354d7c09176eb8b0e515e34fa41180f3e886d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/aec354d7c09176eb8b0e515e34fa41180f3e886d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8006,
          name: "Pumpkin Smash Mobile",
          uuid: "3517927ef66f0d6c720dc6fa7f1e81a6dfbb0ea8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3517927ef66f0d6c720dc6fa7f1e81a6dfbb0ea8.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8167,
          name: "Pushy Cats",
          uuid: "22162e55f57c350a9225817dda4a623e0a829e33",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/22162e55f57c350a9225817dda4a623e0a829e33.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8168,
          name: "Pushy Cats Mobile",
          uuid: "28df7928a64d851b7f15504afa3075090c2cc714",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/28df7928a64d851b7f15504afa3075090c2cc714.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8230,
          name: "Ragna Ravens Wild Energy",
          uuid: "d8af77c4cd2af97e7df0e26b9a579cc72ba2caf2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/d8af77c4cd2af97e7df0e26b9a579cc72ba2caf2.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8229,
          name: "Ragna Ravens Wild Energy Mobile",
          uuid: "d5fd3509ea0796ad2fba0ec86985d5638183801d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/d5fd3509ea0796ad2fba0ec86985d5638183801d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8199,
          name: "Ragnawolves",
          uuid: "0e73f25df827418c8c36c7db09eac07d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/0e73f25df827418c8c36c7db09eac07d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8200,
          name: "Ragnawolves Mobile",
          uuid: "da30e7509f734252a8531bcdbd7676a8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/da30e7509f734252a8531bcdbd7676a8.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7987,
          name: "Rainbow Ryan",
          uuid: "da5c4bb95452f2bd6fff80d545ec2dd56c7d3293",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/da5c4bb95452f2bd6fff80d545ec2dd56c7d3293.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8228,
          name: "Rainbow Ryan 2",
          uuid: "3f47f9308804852cd1dcf1bd756b7902c3ac99a9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/3f47f9308804852cd1dcf1bd756b7902c3ac99a9.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8227,
          name: "Rainbow Ryan 2 Mobile",
          uuid: "80da1406c43315a65212bb51f15f7fac905be618",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/80da1406c43315a65212bb51f15f7fac905be618.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7988,
          name: "Rainbow Ryan Mobile",
          uuid: "700d96d74959f87c37949710f529cd6c7dd6f3a7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/700d96d74959f87c37949710f529cd6c7dd6f3a7.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8131,
          name: "Raptor Doublemax",
          uuid: "abea0e5903f3ecf14bb808abbf26f0b5fcf6d261",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/abea0e5903f3ecf14bb808abbf26f0b5fcf6d261.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8132,
          name: "Raptor Doublemax Mobile",
          uuid: "3746ff4031b99a96bd99715cdc321e0cf437ef33",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3746ff4031b99a96bd99715cdc321e0cf437ef33.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8087,
          name: "Reel Desire",
          uuid: "82a185e90852437a8f8746f7d67a9c51afde9009",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/82a185e90852437a8f8746f7d67a9c51afde9009.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8088,
          name: "Reel Desire Mobile",
          uuid: "460eb1beee949d28879f0b5eeebc6a194fae4cea",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/460eb1beee949d28879f0b5eeebc6a194fae4cea.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8119,
          name: "Robin Nottingham Raiders",
          uuid: "479c7e2bd5254d63cd90a77b0dd41c7296d96838",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/479c7e2bd5254d63cd90a77b0dd41c7296d96838.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8120,
          name: "Robin Nottingham Raiders Mobile",
          uuid: "d3e655fd3022e294fd189593516daf4ab31a40fe",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d3e655fd3022e294fd189593516daf4ab31a40fe.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8101,
          name: "Robin Sherwood Marauders",
          uuid: "f7e0a9680e1dbf4c7d0676d7bb09078a442e4521",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/f7e0a9680e1dbf4c7d0676d7bb09078a442e4521.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8102,
          name: "Robin Sherwood Marauders Mobile",
          uuid: "a640c27dfc5f0198897f0d5ddbce685d1d77a66b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a640c27dfc5f0198897f0d5ddbce685d1d77a66b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8221,
          name: "Rock Star Santa Multimax",
          uuid: "23f1a55d68d74fc68c999a92f9a9732b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/23f1a55d68d74fc68c999a92f9a9732b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8222,
          name: "Rock Star Santa Multimax Mobile",
          uuid: "103d574531b9436a8aef38d734726b3d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/103d574531b9436a8aef38d734726b3d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8103,
          name: "Royal Dragon Infinity Reels",
          uuid: "ef6b51401cbe14fa5b0d6e03f4754a671e3396c3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ef6b51401cbe14fa5b0d6e03f4754a671e3396c3.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8104,
          name: "Royal Dragon Infinity Reels Mobile",
          uuid: "d392342bf963456cb6205df1d86fbb9c20ad544a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d392342bf963456cb6205df1d86fbb9c20ad544a.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8037,
          name: "Sahara Nights",
          uuid: "1cdc3991c128133b5eca6e939605d119a418dd01",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1cdc3991c128133b5eca6e939605d119a418dd01.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8038,
          name: "Sahara Nights Mobile",
          uuid: "0b3ed2f1305c37d79aa1f2bd95f940ff7abf5dec",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0b3ed2f1305c37d79aa1f2bd95f940ff7abf5dec.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8155,
          name: "Savanna Roar",
          uuid: "66575f1f94d12f440da350ee495afbfab984c847",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/66575f1f94d12f440da350ee495afbfab984c847.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8156,
          name: "Savanna Roar Mobile",
          uuid: "bc96d9b2fa22c8404f81d4a910053fd6bc441e4c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/bc96d9b2fa22c8404f81d4a910053fd6bc441e4c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7969,
          name: "Seasons",
          uuid: "667b0f367cd85400bc259e8f803ec8c3886322ac",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/667b0f367cd85400bc259e8f803ec8c3886322ac.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7970,
          name: "Seasons Mobile",
          uuid: "1a92aedd4630fa6adb4085eb511510515928a9b6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1a92aedd4630fa6adb4085eb511510515928a9b6.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8207,
          name: "Shaker Club",
          uuid: "96eb675a0ebf4104878bc47c25b88317",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/96eb675a0ebf4104878bc47c25b88317.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8208,
          name: "Shaker Club Mobile",
          uuid: "3452aee01b414bd7b39f03e8e0169cdc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/3452aee01b414bd7b39f03e8e0169cdc.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7955,
          name: "Spina Colada",
          uuid: "a9ad4b4482af22a916aab2c328efd6111a0e17f8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/a9ad4b4482af22a916aab2c328efd6111a0e17f8.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7956,
          name: "Spina Colada Mobile",
          uuid: "1926c7956a7e9c1df4705d8fdd18eb359e63468a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1926c7956a7e9c1df4705d8fdd18eb359e63468a.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8205,
          name: "Starfire Fortunes Tophit",
          uuid: "d9b8092bf6d442819a8688e2337b45a1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/d9b8092bf6d442819a8688e2337b45a1.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8206,
          name: "Starfire Fortunes Tophit Mobile",
          uuid: "e6e4341bd4cf4961a0d15f03f2dc9afb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/e6e4341bd4cf4961a0d15f03f2dc9afb.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8109,
          name: "Suncatcher Gigablox",
          uuid: "61bb980963b7533f90bc5ff410952b380a267db6",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/61bb980963b7533f90bc5ff410952b380a267db6.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8110,
          name: "Suncatcher Gigablox Mobile",
          uuid: "ddb31ec851f16331ccffe1f6e4e6de484cf35bd1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ddb31ec851f16331ccffe1f6e4e6de484cf35bd1.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7985,
          name: "Sunny Shores",
          uuid: "59ba36a576bcfc373072cfea7ea17098c2f8a6ab",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/59ba36a576bcfc373072cfea7ea17098c2f8a6ab.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7986,
          name: "Sunny Shores Mobile",
          uuid: "1b47ac1b1522a7f83b203054f77efc9aece2c15e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1b47ac1b1522a7f83b203054f77efc9aece2c15e.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8075,
          name: "Syncronite – Splitz",
          uuid: "2718fff480c5e59ec56bbe08ec51404cf701268c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2718fff480c5e59ec56bbe08ec51404cf701268c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8076,
          name: "Syncronite – Splitz Mobile",
          uuid: "3a2194685cb3c1bbdee296e535337143a655d7c2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/3a2194685cb3c1bbdee296e535337143a655d7c2.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8039,
          name: "Temple Stacks: Splitz",
          uuid: "7876fdf6f8f5a6b9f7a427a04d9461f8849b4711",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7876fdf6f8f5a6b9f7a427a04d9461f8849b4711.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8040,
          name: "Temple Stacks: Splitz Mobile",
          uuid: "b93a53ec6d29f33fed549d2f00aa8155f276c27b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b93a53ec6d29f33fed549d2f00aa8155f276c27b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8127,
          name: "The Bounty Hunter",
          uuid: "740a7aa27a8e5bddc6ba357deae5ec79e746da8c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/740a7aa27a8e5bddc6ba357deae5ec79e746da8c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8128,
          name: "The Bounty Hunter Mobile",
          uuid: "cb4803c7de258ff724ca5bb903a4da2c20a8639c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cb4803c7de258ff724ca5bb903a4da2c20a8639c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7959,
          name: "The Dark Joker Rizes",
          uuid: "604edf301f460be9fd57812ba38e52e5b71decf3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/604edf301f460be9fd57812ba38e52e5b71decf3.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7960,
          name: "The Dark Joker Rizes Mobile",
          uuid: "744c0175d001549857dd85e91eb3ead75f94ff24",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/744c0175d001549857dd85e91eb3ead75f94ff24.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8033,
          name: "The One Armed Bandit",
          uuid: "73d9e98c257bce5fd7f5c22afe7f8c3c5624eef8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/73d9e98c257bce5fd7f5c22afe7f8c3c5624eef8.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8034,
          name: "The One Armed Bandit Mobile",
          uuid: "0a0496c9bd34763d9588475af8b6fc42fa3de280",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/0a0496c9bd34763d9588475af8b6fc42fa3de280.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8043,
          name: "The Royal Family",
          uuid: "c904e756be07652510c35c8b754e0f8932cec62b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c904e756be07652510c35c8b754e0f8932cec62b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8044,
          name: "The Royal Family Mobile",
          uuid: "cee1adff882086dd51edefb397ec2c178a6fc64b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cee1adff882086dd51edefb397ec2c178a6fc64b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8178,
          name: "Thor Infinity Reels",
          uuid: "550708f600814b0398861c31633ef677",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/550708f600814b0398861c31633ef677.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8177,
          name: "Thor Infinity Reels Mobile",
          uuid: "eae3c176dae446d6ba3db88194d24eab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/eae3c176dae446d6ba3db88194d24eab.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8153,
          name: "Tiger Tiger",
          uuid: "6eb0ced6dc65a0bbbf8c965aeefdf14d8f72c429",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6eb0ced6dc65a0bbbf8c965aeefdf14d8f72c429.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8154,
          name: "Tiger Tiger Mobile",
          uuid: "7727d48696fe02d6b3a0e500c5ef07873ac5d320",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7727d48696fe02d6b3a0e500c5ef07873ac5d320.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8121,
          name: "Tiki Infinity Reels Megaways",
          uuid: "8fb64721fa16a69ea74b4e6ee595a4bec65e6052",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/8fb64721fa16a69ea74b4e6ee595a4bec65e6052.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8122,
          name: "Tiki Infinity Reels Megaways Mobile",
          uuid: "c840d92a4312c08aee272737734b532afc8c1a14",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/c840d92a4312c08aee272737734b532afc8c1a14.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8041,
          name: "Time Travel Tigers",
          uuid: "9da2a473563e0db1434bb92ffd000dab46ac7ce1",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9da2a473563e0db1434bb92ffd000dab46ac7ce1.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8042,
          name: "Time Travel Tigers Mobile",
          uuid: "adb63d7afbc46b1e5d667d5ce0ac2961b6ae6b45",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/adb63d7afbc46b1e5d667d5ce0ac2961b6ae6b45.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8133,
          name: "Towering Pays Valhalla",
          uuid: "10bf7c9501c4fee755488a42ef7a1ed061446d55",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/10bf7c9501c4fee755488a42ef7a1ed061446d55.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8134,
          name: "Towering Pays Valhalla Mobile",
          uuid: "5d3c7327bef32e67765414bde0d427803b5c1b7b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5d3c7327bef32e67765414bde0d427803b5c1b7b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8029,
          name: "Trolls Bridge",
          uuid: "cc4095c674463d3cc8eb8a6b99b0ae1d5fcd435b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cc4095c674463d3cc8eb8a6b99b0ae1d5fcd435b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8030,
          name: "Trolls Bridge Mobile",
          uuid: "e6940a9e06eb44c7ec9c44d0b7a10adf32fea88e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e6940a9e06eb44c7ec9c44d0b7a10adf32fea88e.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8007,
          name: "Tuts Twister",
          uuid: "de6463c70d42320fdf98aeceb7162ec3dd205f7b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/de6463c70d42320fdf98aeceb7162ec3dd205f7b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8008,
          name: "Tuts Twister Mobile",
          uuid: "2b3d980971dd2c44451c715962873ef170d338ec",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2b3d980971dd2c44451c715962873ef170d338ec.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8067,
          name: "Valley of the gods 2",
          uuid: "ac8171e26ce4148b549978c1c9b81e6488c77788",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ac8171e26ce4148b549978c1c9b81e6488c77788.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8068,
          name: "Valley of the gods 2 Mobile",
          uuid: "7c3a12a4d89208f3bc86726c30cffff8bd8430b4",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7c3a12a4d89208f3bc86726c30cffff8bd8430b4.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8213,
          name: "Vampire Riches Doublemax",
          uuid: "8ab23fa122ee1ee7e38749a9db322b7ad5c98942",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/8ab23fa122ee1ee7e38749a9db322b7ad5c98942.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8214,
          name: "Vampire Riches Doublemax Mobile",
          uuid: "764d36bd4f55ed3888e3051a5212c6fda706326f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/764d36bd4f55ed3888e3051a5212c6fda706326f.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7945,
          name: "Vikings go Berzerk",
          uuid: "7acd6b35e078e0c0ac89d2341c68e6748f37aeb2",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/7acd6b35e078e0c0ac89d2341c68e6748f37aeb2.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7946,
          name: "Vikings go Berzerk Mobile",
          uuid: "95ce284604f9169915aef1e4902a8b358a8fbaeb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/95ce284604f9169915aef1e4902a8b358a8fbaeb.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8139,
          name: "Vikings go Berzerk Reloaded",
          uuid: "4454aa5e0d15f5e1c52446ddbfc0dfd05d152e0c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4454aa5e0d15f5e1c52446ddbfc0dfd05d152e0c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8140,
          name: "Vikings go Berzerk Reloaded Mobile",
          uuid: "9d02fded9b0e718ef70a23d8b1f07082a7a60c32",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/9d02fded9b0e718ef70a23d8b1f07082a7a60c32.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8240,
          name: "Vikings Go to Egypt Wild Fight",
          uuid: "0c552aabed8d3c3f8a294e80c080047a3fe4a6eb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/0c552aabed8d3c3f8a294e80c080047a3fe4a6eb.jpg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8239,
          name: "Vikings Go to Egypt Wild Fight Mobile",
          uuid: "6bf27fcb2fecf4efcf17d9deac709fdbe183fef5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/6bf27fcb2fecf4efcf17d9deac709fdbe183fef5.jpg",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8009,
          name: "Vikings Go To Hell",
          uuid: "b94cd7b962da7dc238df339eac2113cae692368d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b94cd7b962da7dc238df339eac2113cae692368d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8010,
          name: "Vikings Go To Hell Mobile",
          uuid: "1a000f4821d66b4707aa2d1f0930df1059974dcf",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1a000f4821d66b4707aa2d1f0930df1059974dcf.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8165,
          name: "Vikings go to Valhalla",
          uuid: "2cf8608cf0e8e57e3ea6d058ceafcbb3fafdfe18",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2cf8608cf0e8e57e3ea6d058ceafcbb3fafdfe18.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8166,
          name: "Vikings go to Valhalla Mobile",
          uuid: "cb458c3499ac9699086d87a998440c3a101fcef7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/cb458c3499ac9699086d87a998440c3a101fcef7.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7961,
          name: "Vikings go Wild",
          uuid: "ffeac0e11bc688d2579a460b527ac3d7d03d454d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ffeac0e11bc688d2579a460b527ac3d7d03d454d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7962,
          name: "Vikings go Wild Mobile",
          uuid: "d07fe14cdb441c4e97230f9ce2ade26a937a0e2c",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/d07fe14cdb441c4e97230f9ce2ade26a937a0e2c.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8130,
          name: "Wild Joker Stacks",
          uuid: "504be04931b01e0e4a1e1cab3d7bc9cc5e6a8f9b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/504be04931b01e0e4a1e1cab3d7bc9cc5e6a8f9b.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8129,
          name: "Wild Joker Stacks Mobile",
          uuid: "b31552394132c1effe5000db299e63a7f0776c5a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/b31552394132c1effe5000db299e63a7f0776c5a.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8031,
          name: "Wild Robo Factory",
          uuid: "899643170681a4113283eebbefab87b3cf0f1271",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/899643170681a4113283eebbefab87b3cf0f1271.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8032,
          name: "Wild Robo Factory Mobile",
          uuid: "1abd11f07ab3995a36eec306e7bb73605323be23",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/1abd11f07ab3995a36eec306e7bb73605323be23.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8219,
          name: "Wild Stack Frenzy",
          uuid: "714929bd5a6046ceba0a1b5540a054c6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/714929bd5a6046ceba0a1b5540a054c6.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8220,
          name: "Wild Stack Frenzy Mobile",
          uuid: "a81409c94a43471bb42eb6d0ac166ccf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/a81409c94a43471bb42eb6d0ac166ccf.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8047,
          name: "Wilhelm Tell",
          uuid: "e281a91fa05441f17dc00fa30f7cfbed1b1ac8f3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/e281a91fa05441f17dc00fa30f7cfbed1b1ac8f3.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8048,
          name: "Wilhelm Tell Mobile",
          uuid: "5e141da09ad421fbb72c3a99d12279476424f640",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5e141da09ad421fbb72c3a99d12279476424f640.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7971,
          name: "Winterberries",
          uuid: "6ea13c1f36f8bc84f37a8e03ed6e7fa21b011d04",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/6ea13c1f36f8bc84f37a8e03ed6e7fa21b011d04.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8191,
          name: "Winterberries 2",
          uuid: "3115d46a928c39ea1b608db06a3c02359ed9d7ed",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/3115d46a928c39ea1b608db06a3c02359ed9d7ed.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8192,
          name: "Winterberries 2 Mobile",
          uuid: "1207369a681f0ecdb8160b32b573ec552da331d9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Yggdrasil/1207369a681f0ecdb8160b32b573ec552da331d9.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 7972,
          name: "Winterberries Mobile",
          uuid: "ea873e22f1e1d1d0ff183d32a1c338eb36b93f80",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ea873e22f1e1d1d0ff183d32a1c338eb36b93f80.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8027,
          name: "Wolf Hunters",
          uuid: "4d63571c29dea603264b568203d274451868041d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/4d63571c29dea603264b568203d274451868041d.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8028,
          name: "Wolf Hunters Mobile",
          uuid: "90aea7b526498eceb884eece8c558ccaeb4188e3",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/90aea7b526498eceb884eece8c558ccaeb4188e3.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8035,
          name: "Yokozuna Clash",
          uuid: "5b3fe5f04c609c13b62ef85a6a33cc45d3c8a541",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/5b3fe5f04c609c13b62ef85a6a33cc45d3c8a541.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8036,
          name: "Yokozuna Clash Mobile",
          uuid: "57a5d174d1982280c64f0b268cef590aaa51f1cd",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/57a5d174d1982280c64f0b268cef590aaa51f1cd.png",
          type: "slots",
          provider: "Yggdrasil",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 162,
      name: "ZeusPlay",
      providerId: 41,
      providerName: "ZeusPlay",
      games: [
        {
          game_id: 1984,
          name: "15 Dragon Coins",
          uuid: "8a9373f78ed04951847863cbc23ac232",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/8a9373f78ed04951847863cbc23ac232.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1985,
          name: "15 Dragon Coins Mobile",
          uuid: "31c3d986784048df913e151e1c1dc093",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/31c3d986784048df913e151e1c1dc093.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1894,
          name: "40 Flaming Lines",
          uuid: "cac486de05454850ae2cff0e9ade5beb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/cac486de05454850ae2cff0e9ade5beb.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1895,
          name: "40 Flaming Lines Mobile",
          uuid: "c533b8098c9f480aacd9f4d2e33a62de",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/c533b8098c9f480aacd9f4d2e33a62de.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1974,
          name: "9 Dragon Coins",
          uuid: "45d4996b9a9728b1106de7c2e1aed4ea3edbe74f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/45d4996b9a9728b1106de7c2e1aed4ea3edbe74f.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1975,
          name: "9 Dragon Coins Mobile",
          uuid: "f9abbec24132d4d65384d5b4cf47c9084ea51b92",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/f9abbec24132d4d65384d5b4cf47c9084ea51b92.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1978,
          name: "A Fistful of Coins",
          uuid: "14b302d12a96e366881f497c7366da268a5971e6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/14b302d12a96e366881f497c7366da268a5971e6.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1979,
          name: "A Fistful of Coins Mobile",
          uuid: "ceb1c90106c2e3e512b16769cd08ab468c1f046c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/ceb1c90106c2e3e512b16769cd08ab468c1f046c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1896,
          name: "Abby and the Witch",
          uuid: "ab73586754864d6fbe4adba73cfd4a9d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/ab73586754864d6fbe4adba73cfd4a9d.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1897,
          name: "Abby and the Witch Mobile",
          uuid: "22d901ab86de489b94fb339cd5583d44",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/22d901ab86de489b94fb339cd5583d44.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2004,
          name: "Amun's Book HD 6",
          uuid: "f5f65cf496774005976f7a0bbf1eb388",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/f5f65cf496774005976f7a0bbf1eb388.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2005,
          name: "Amun's Book HD 6 Mobile",
          uuid: "c8658c4ca36944ebb4410449e6fb1407",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/c8658c4ca36944ebb4410449e6fb1407.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1898,
          name: "Amuns Book HD",
          uuid: "7e7c6058aaf548cca99b70e629efba0b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/7e7c6058aaf548cca99b70e629efba0b.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1899,
          name: "Amuns Book HD Mobile",
          uuid: "cbdb05e1c0b1489fbe1988c8c69f5a27",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/cbdb05e1c0b1489fbe1988c8c69f5a27.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1900,
          name: "Arabian Dream",
          uuid: "424c554645e3426da5534e5f5f863036",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/424c554645e3426da5534e5f5f863036.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1901,
          name: "Arabian Dream Mobile",
          uuid: "fed1f95e362a42fd9ea6b00275dff562",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/fed1f95e362a42fd9ea6b00275dff562.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1956,
          name: "Bubbles and Pearls",
          uuid: "12222bd8ff3792559ae5063771a0b69e0c03d84b",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/12222bd8ff3792559ae5063771a0b69e0c03d84b.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1957,
          name: "Bubbles and Pearls Mobile",
          uuid: "ef9fcdb491dec4e8b9956a675bdccfc6e61fd519",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/ef9fcdb491dec4e8b9956a675bdccfc6e61fd519.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1902,
          name: "Butterfly Hot 10",
          uuid: "d559e2d9d8ed437880bde3eef9a0c680",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/d559e2d9d8ed437880bde3eef9a0c680.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1903,
          name: "Butterfly Hot 10 Mobile",
          uuid: "f70d0f170488431b8195a85e8e966223",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/f70d0f170488431b8195a85e8e966223.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1982,
          name: "Candy Land",
          uuid: "c8588cf939708e5331cd481f1904e4b2c86e0c64",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/c8588cf939708e5331cd481f1904e4b2c86e0c64.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1983,
          name: "Candy Land Mobile",
          uuid: "684bffbf935b79dcca4ea1a050460459112e05bf",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/684bffbf935b79dcca4ea1a050460459112e05bf.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1904,
          name: "Captain Nelson Deluxe",
          uuid: "deace492f7e145babe43fcea09bc6fca",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/deace492f7e145babe43fcea09bc6fca.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1905,
          name: "Captain Nelson Deluxe Mobile",
          uuid: "f5e5e675a2bb46bc89681d5d9d0151b7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/f5e5e675a2bb46bc89681d5d9d0151b7.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1976,
          name: "Diamond Freeze",
          uuid: "1c3c88db869d4ee59a22bad5a0c60d5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/1c3c88db869d4ee59a22bad5a0c60d5c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1990,
          name: "Diamond Freeze Dice",
          uuid: "d2beb9979d3b45e6a0de5534a8fc442c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/d2beb9979d3b45e6a0de5534a8fc442c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1991,
          name: "Diamond Freeze Dice Mobile",
          uuid: "1b4dc0dfedc841e5b07e6b486aefa371",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/1b4dc0dfedc841e5b07e6b486aefa371.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1977,
          name: "Diamond Freeze Mobile",
          uuid: "346513d07c8c4db4ab24fce0774fa813",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/346513d07c8c4db4ab24fce0774fa813.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1960,
          name: "Diamond's Fortune",
          uuid: "f479da383d2e450b81306079c089f835",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/f479da383d2e450b81306079c089f835.png",
          type: "craps",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1961,
          name: "Diamond's Fortune Mobile",
          uuid: "039cedcf0cc24a0a98b27e2e54b77378",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/039cedcf0cc24a0a98b27e2e54b77378.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1906,
          name: "Dice Tronic",
          uuid: "c005d667d00d4a809f2a58e04d87a285",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/c005d667d00d4a809f2a58e04d87a285.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1907,
          name: "Dice Tronic Mobile",
          uuid: "08998cc2c0544c0d9b5f2b9f2f271f32",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/08998cc2c0544c0d9b5f2b9f2f271f32.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1908,
          name: "Dice Tronic X",
          uuid: "c62ba603ce684cd8b6cd955ebd99af1e",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/c62ba603ce684cd8b6cd955ebd99af1e.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1909,
          name: "Dice Tronic X Mobile",
          uuid: "4824016d647b407faeca5ae7b595680b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/4824016d647b407faeca5ae7b595680b.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1910,
          name: "Dice Tronic XL",
          uuid: "030dcee26df445598b824bd977a09e07",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/030dcee26df445598b824bd977a09e07.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1911,
          name: "Dice Tronic XL Mobile",
          uuid: "7bc79625f678466d80231e1d05c8bcf8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/7bc79625f678466d80231e1d05c8bcf8.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1912,
          name: "Disco Fever",
          uuid: "c39e0e93842b45b2b28ceab786ac1045",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/c39e0e93842b45b2b28ceab786ac1045.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1913,
          name: "Disco Fever Mobile",
          uuid: "3361227db87b4fa09a7b2f276f3d63ea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/3361227db87b4fa09a7b2f276f3d63ea.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1966,
          name: "Don Mafioso",
          uuid: "aec59abf23ec4243a18dab0a7c4f1f5700b24ea8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/aec59abf23ec4243a18dab0a7c4f1f5700b24ea8.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1967,
          name: "Don Mafioso Mobile",
          uuid: "6e2deb1388bfc2ada62b162659b96bdddb44edfa",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/6e2deb1388bfc2ada62b162659b96bdddb44edfa.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8241,
          name: "Eternal Desire",
          uuid: "b72ce53470c640f09ccfdeceee257847",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/b72ce53470c640f09ccfdeceee257847.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 8242,
          name: "Eternal Desire Mobile",
          uuid: "d6bcdb02cd8841b8b7150c23c085629d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/d6bcdb02cd8841b8b7150c23c085629d.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1890,
          name: "Extra Super Hot BBQ 50",
          uuid: "921620af212d41028a15907309b34774",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/921620af212d41028a15907309b34774.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1891,
          name: "Extra Super Hot BBQ 50 Mobile",
          uuid: "bcc07325037143c083cb0aeb522e425c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/bcc07325037143c083cb0aeb522e425c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1950,
          name: "Fairy's Golden Path",
          uuid: "ec6028c208a64dc1acaf57b4e5410d01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/ec6028c208a64dc1acaf57b4e5410d01.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1951,
          name: "Fairy's Golden Path Mobile",
          uuid: "235f9e56f19d42f1b0c93708bb7e7711",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/235f9e56f19d42f1b0c93708bb7e7711.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1954,
          name: "Farm madness",
          uuid: "24867404e436b2d20d6f2cf58e27dd6b599eb7af",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/24867404e436b2d20d6f2cf58e27dd6b599eb7af.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1980,
          name: "Farm Madness Chistmas Edition",
          uuid: "04bb2dda24314e5c0d0f19ea9a1be46b5e429632",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/04bb2dda24314e5c0d0f19ea9a1be46b5e429632.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1981,
          name: "Farm Madness Chistmas Edition Mobile",
          uuid: "d0d535fdd3c8f8a109e35ccff4e88bd97ac67676",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/d0d535fdd3c8f8a109e35ccff4e88bd97ac67676.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1955,
          name: "Farm madness Mobile",
          uuid: "2e76129d79e936000579c4d77366d887a47c20a7",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/2e76129d79e936000579c4d77366d887a47c20a7.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1914,
          name: "Fire 20 Deluxe",
          uuid: "2616994c830744f8ae46aa5eddb9dc4a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/2616994c830744f8ae46aa5eddb9dc4a.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1915,
          name: "Fire 20 Deluxe Mobile",
          uuid: "834b958e50e640258f88e4e3bbecbc3f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/834b958e50e640258f88e4e3bbecbc3f.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1916,
          name: "Golden Dice 3",
          uuid: "b70baab169654a679ed97a330b7d09e1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/b70baab169654a679ed97a330b7d09e1.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1917,
          name: "Golden Dice 3 Mobile",
          uuid: "71ce67895cf84ae4962ef0bb3f96b351",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/71ce67895cf84ae4962ef0bb3f96b351.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1968,
          name: "Great Buffalo",
          uuid: "c0d29f74a3eccac5822dcaa3e9b4caeafe266822",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/c0d29f74a3eccac5822dcaa3e9b4caeafe266822.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1969,
          name: "Great Buffalo Mobile",
          uuid: "19d345a7c7121aaa0319e03bd1a50e031d3cea09",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/19d345a7c7121aaa0319e03bd1a50e031d3cea09.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1952,
          name: "Haunted Walker",
          uuid: "fc88387d5ed84104a4b2746291b4c0a7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/fc88387d5ed84104a4b2746291b4c0a7.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1953,
          name: "Haunted Walker Mobile",
          uuid: "4bda10994e8f47cfbb4309ac8f7e33b8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/4bda10994e8f47cfbb4309ac8f7e33b8.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2007,
          name: "Highway Rebels",
          uuid: "3c66d18b2db032e0900d68dcb8fb65a377d0985b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/3c66d18b2db032e0900d68dcb8fb65a377d0985b.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2006,
          name: "Highway Rebels Mobile",
          uuid: "0c40b8ade50c4c74063ce6b7ea9a059df5398deb",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/0c40b8ade50c4c74063ce6b7ea9a059df5398deb.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1962,
          name: "Hot 20 Deluxe",
          uuid: "d4afc1424e8f44bc91c67a74f540ccb8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/d4afc1424e8f44bc91c67a74f540ccb8.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1963,
          name: "Hot 20 Deluxe Mobile",
          uuid: "2cd939d6c3e048eb9eb125f8f056c158",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/2cd939d6c3e048eb9eb125f8f056c158.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1918,
          name: "Hot 27 Lines",
          uuid: "db1c8691acd34deab264198a396c00f0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/db1c8691acd34deab264198a396c00f0.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1919,
          name: "Hot 27 Lines Mobile",
          uuid: "faa76e8b78fb4cacb00a9da136e0d509",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/faa76e8b78fb4cacb00a9da136e0d509.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1920,
          name: "Hot 5 Deluxe",
          uuid: "fb880afb41884a0dadc2767f45c70d9a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/fb880afb41884a0dadc2767f45c70d9a.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1921,
          name: "Hot 5 Deluxe Mobile",
          uuid: "2b5a6c09730e4ac5ba3ee3857333a59b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/2b5a6c09730e4ac5ba3ee3857333a59b.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1996,
          name: "Hot Diamonds 100",
          uuid: "1438a509b549472cb12a96313b595c5c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/1438a509b549472cb12a96313b595c5c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2000,
          name: "Hot Diamonds 100 Dice",
          uuid: "4225b9d5099d4ec6a1c588f023eda66d",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/4225b9d5099d4ec6a1c588f023eda66d.png",
          type: "dice",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2001,
          name: "Hot Diamonds 100 Dice Mobile",
          uuid: "696bb92ceccd49e8acedadd0fb6fbe7c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/696bb92ceccd49e8acedadd0fb6fbe7c.png",
          type: "dice",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1997,
          name: "Hot Diamonds 100 Mobile",
          uuid: "5b37f8e1b9444935b39bdfd3b673c4ae",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/5b37f8e1b9444935b39bdfd3b673c4ae.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1970,
          name: "Hot Diamonds 20",
          uuid: "49f243d38d24b4effdbf7593f9da733e0c10dfa6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/49f243d38d24b4effdbf7593f9da733e0c10dfa6.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1971,
          name: "Hot Diamonds 20 Mobile",
          uuid: "8a194923c8c7f4ae8215d01170b210fdc6d6f800",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/8a194923c8c7f4ae8215d01170b210fdc6d6f800.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1972,
          name: "Hot Diamonds 40",
          uuid: "a346412661769dfa98dc78180bffa615e47a1740",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/a346412661769dfa98dc78180bffa615e47a1740.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1973,
          name: "Hot Diamonds 40 Mobile",
          uuid: "ad12ad20eceec8ff54167fb6258d19fe9c0f28f7",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/ad12ad20eceec8ff54167fb6258d19fe9c0f28f7.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1994,
          name: "Hot Diamonds 5",
          uuid: "a459067ab8b94a2f949a4b028def0a96",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/a459067ab8b94a2f949a4b028def0a96.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1998,
          name: "Hot Diamonds 5 Dice",
          uuid: "c95b7dedb2ca48c7afe30288fa76d4a8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/c95b7dedb2ca48c7afe30288fa76d4a8.png",
          type: "dice",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1999,
          name: "Hot Diamonds 5 Dice Mobile",
          uuid: "345e42d45fe547529604d346dfec6cd6",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/345e42d45fe547529604d346dfec6cd6.png",
          type: "dice",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1995,
          name: "Hot Diamonds 5 Mobile",
          uuid: "1a10e16379fd495ebaf080751a00175c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/1a10e16379fd495ebaf080751a00175c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2002,
          name: "I Love Greece",
          uuid: "8abe7d7d305741d38188765051d072f1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/8abe7d7d305741d38188765051d072f1.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2003,
          name: "I Love Greece Mobile",
          uuid: "a459be05a5e5449da5a114636345fec8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/a459be05a5e5449da5a114636345fec8.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1922,
          name: "Infinium",
          uuid: "08d8e5ee21034ec38b3a005c6e769f55",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/08d8e5ee21034ec38b3a005c6e769f55.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1923,
          name: "Infinium Mobile",
          uuid: "2eb5b445ef2c40828e69a179099ae1c3",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/2eb5b445ef2c40828e69a179099ae1c3.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1924,
          name: "Lady of the Forest",
          uuid: "1a78b10bda574eae89a545f657d3794a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/1a78b10bda574eae89a545f657d3794a.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1925,
          name: "Lady of the Forest Mobile",
          uuid: "508e24a97f15473f91c829aa81929aab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/508e24a97f15473f91c829aa81929aab.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1926,
          name: "Le Mystere Du Prince",
          uuid: "eb32f7fdcb0b4efaaece8b4cb9637157",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/eb32f7fdcb0b4efaaece8b4cb9637157.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1927,
          name: "Le Mystere Du Prince Mobile",
          uuid: "22fc5cd16ac64c099931ae64a116aa2c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/22fc5cd16ac64c099931ae64a116aa2c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1928,
          name: "Mad Cubes 25",
          uuid: "d66b71cf8d05451bbc2ecbdcc4a2c8df",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/d66b71cf8d05451bbc2ecbdcc4a2c8df.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1929,
          name: "Mad Cubes 25 Mobile",
          uuid: "53e8b7c3f5124bd78de87e0e8b9e67a5",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/53e8b7c3f5124bd78de87e0e8b9e67a5.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1888,
          name: "Mad cubes 50",
          uuid: "cde530005f684370a34d77696f1e2e77",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/cde530005f684370a34d77696f1e2e77.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1889,
          name: "Mad cubes 50 Mobile",
          uuid: "f932f87db2de4152aa7995ec1e7f0560",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/f932f87db2de4152aa7995ec1e7f0560.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1930,
          name: "Magic Charm",
          uuid: "7f6caa14e3744a4b9f1ffaf5d23a990b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/7f6caa14e3744a4b9f1ffaf5d23a990b.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1931,
          name: "Magic Charm Mobile",
          uuid: "4ee53a181ced4bf0b9c38ab07bfa17e4",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/4ee53a181ced4bf0b9c38ab07bfa17e4.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1932,
          name: "Page of Fortune Deluxe",
          uuid: "8d712e951bee4b71b205c391bb38f8d8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/8d712e951bee4b71b205c391bb38f8d8.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1933,
          name: "Page of Fortune Deluxe Mobile",
          uuid: "2b76277d052a46a581f5ac202b9372b2",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/2b76277d052a46a581f5ac202b9372b2.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1987,
          name: "Ricko Galactico",
          uuid: "6392101124adc544482c070ca325d4feb509c47c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/6392101124adc544482c070ca325d4feb509c47c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1986,
          name: "Ricko Galactico Mobile",
          uuid: "1e8cceafca7bb00ec6ffff5b7e951663718b2f3c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/1e8cceafca7bb00ec6ffff5b7e951663718b2f3c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1934,
          name: "Royal Jewels",
          uuid: "4d02391fec5b43caac226ffdba08499b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/4d02391fec5b43caac226ffdba08499b.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1935,
          name: "Royal Jewels Mobile",
          uuid: "cebb195aa4ca425fa4143919c3f52bb8",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/cebb195aa4ca425fa4143919c3f52bb8.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1936,
          name: "Snake Eyes",
          uuid: "fa284dc594054bc9a5ade4f5874e17fe",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/fa284dc594054bc9a5ade4f5874e17fe.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1937,
          name: "Snake Eyes Mobile",
          uuid: "1c84eccb76914090a30e0ec5c7b8130c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/1c84eccb76914090a30e0ec5c7b8130c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1938,
          name: "Star Frenzy",
          uuid: "2e2b2c0422194851bc1ffc85b3dcd469",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/2e2b2c0422194851bc1ffc85b3dcd469.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1939,
          name: "Star Frenzy Mobile",
          uuid: "359d368e267441ec8b93215b8273c1a0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/359d368e267441ec8b93215b8273c1a0.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1940,
          name: "Super Hot BBQ",
          uuid: "5ce26501c481448da623f357c1c3455f",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/5ce26501c481448da623f357c1c3455f.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1941,
          name: "Super Hot BBQ Mobile",
          uuid: "1eb65a8165d84d44b87dc76d0fddf3ec",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/1eb65a8165d84d44b87dc76d0fddf3ec.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1964,
          name: "The Divine 12th",
          uuid: "70e0df2b2d714e6d84e421e871d274d1",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/70e0df2b2d714e6d84e421e871d274d1.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1965,
          name: "The Divine 12th Mobile",
          uuid: "434e16c1306e4a5f9a903985a444d3a0",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/434e16c1306e4a5f9a903985a444d3a0.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1958,
          name: "The Emperor's Curse",
          uuid: "a1077325e9ac446fbaeded9e76991f39",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/a1077325e9ac446fbaeded9e76991f39.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1959,
          name: "The Emperor's Curse Mobile",
          uuid: "9a1e890f81264107bf1225d7331c643b",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/9a1e890f81264107bf1225d7331c643b.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1942,
          name: "The Explorer's Quest",
          uuid: "c4bec5d512cf4b2482f8a315f2498a46",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/c4bec5d512cf4b2482f8a315f2498a46.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1943,
          name: "The Explorer's Quest Mobile",
          uuid: "550ffc20244147b1aa6bd0784cb0638c",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/550ffc20244147b1aa6bd0784cb0638c.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1944,
          name: "The Lost Temple",
          uuid: "84db9820e74d47839fd9e5399b8c1b0a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/84db9820e74d47839fd9e5399b8c1b0a.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1945,
          name: "The Lost Temple Mobile",
          uuid: "bcf08c0f24aa4f9fbc9b3ffa6b9fdea9",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/bcf08c0f24aa4f9fbc9b3ffa6b9fdea9.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1992,
          name: "The Zodiac 12 Water Edition",
          uuid: "ec09a5faa0544110a22c8203ae479c44",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/ec09a5faa0544110a22c8203ae479c44.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1993,
          name: "The Zodiac 12 Water Edition Mobile",
          uuid: "1f5703028a70427e9a0c098a1a3e537a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/1f5703028a70427e9a0c098a1a3e537a.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1946,
          name: "Trojan Horse Tiny Heroes",
          uuid: "245f36e11e1c445bb34bdc71ddab2dea",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/245f36e11e1c445bb34bdc71ddab2dea.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1947,
          name: "Trojan Horse Tiny Heroes Mobile",
          uuid: "922fdfbea5ef4745a7bc74aada4d24ff",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/922fdfbea5ef4745a7bc74aada4d24ff.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1948,
          name: "Wild Charger",
          uuid: "a4d9e8229a7a49debb737e16e7e90ecc",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/a4d9e8229a7a49debb737e16e7e90ecc.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1949,
          name: "Wild Charger Mobile",
          uuid: "01a526f08ea247519f1588292d6deb76",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/01a526f08ea247519f1588292d6deb76.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1988,
          name: "Wild Pink Cadillac",
          uuid: "691ff2c09c994d1ba6113f8ce4bd9294",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/691ff2c09c994d1ba6113f8ce4bd9294.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1989,
          name: "Wild Pink Cadillac Mobile",
          uuid: "7cca01f54cd34ac58acb4ae64be56e21",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/7cca01f54cd34ac58acb4ae64be56e21.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1892,
          name: "Wilds and Pirates",
          uuid: "8fde19b90b074442b72be14f3cf31e01",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/8fde19b90b074442b72be14f3cf31e01.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1893,
          name: "Wilds and Pirates Mobile",
          uuid: "76c78b4996de437d9d0a371477ead069",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/76c78b4996de437d9d0a371477ead069.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    }
  ],
  bonusGames: [
    {
      id: 3,
      name: "Lucky Lady’s Charm",
      url: "/js-games/lucky_lady/game.html"
    },
    {
      id: 5,
      name: "Fruit Cocktail",
      url: "/js-games/fruit_cocktail/game.html"
    },
    {
      id: 2,
      name: "Book of Ra Classic",
      url: "/js-games/book_of_ra_classic/game.html"
    },
    {
      id: 40,
      name: "Lord of Ocean",
      url: "/js-games/lord_of_ocean/game.html"
    },
    {
      id: 23,
      name: "Sharky",
      url: "/js-games/sharky/game.html"
    },
    {
      id: 19,
      name: "Beetle Mania Deluxe",
      url: "/js-games/beetle_mania/game.html"
    },
    {
      id: 36,
      name: "Book of Ra Deluxe",
      url: "/js-games/book-of-ra-deluxe-2/game.html"
    },
    {
      id: 20,
      name: "Sizzling Hot Deluxe",
      url: "/js-games/sizzling_hot_deluxe/game.html"
    },
    {
      id: 48,
      name: "Diamond 7",
      url: "/js-games/diamond7/game.html"
    },
    {
      id: 18,
      name: "Just Jewels Deluxe",
      url: "/js-games/just_jewels_deluxe/game.html"
    },
    {
      id: 44,
      name: "Lucky Lady’s Charm",
      url: "/js-games/lucky_lady_deluxe/game.html"
    },
    {
      id: 45,
      name: "Queens of Hearts",
      url: "/js-games/queen_of_hearts/game.html"
    }
  ],
  gameHits: [
    {
      game_id: 817,
      name: "3 Coins",
      uuid: "a7be71ce60f34c78b72bb728c6cf2e0c",
      source: "win2power:0",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/a7be71ce60f34c78b72bb728c6cf2e0c.png",
      type: "slots",
      provider: "3Oaks",
      excludeDemoMode: false
    },
    {
      game_id: 802,
      name: "Sticky Piggy",
      uuid: "1e5e042292d240fd827cee79720ddc65",
      source: "win2power:0",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/1e5e042292d240fd827cee79720ddc65.png",
      type: "slots",
      provider: "3Oaks",
      excludeDemoMode: false
    },
    {
      game_id: 2025,
      name: "Valkyries",
      uuid: "2a6ea3df3413a744b84ad2f538d8c8b42afbb708",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/Apparat/2a6ea3df3413a744b84ad2f538d8c8b42afbb708.png",
      type: "slots",
      provider: "Apparat",
      excludeDemoMode: false
    },
    {
      game_id: 942,
      name: "Aviatrix",
      uuid: "9d9b5b34389337d4e43568b4ba2d56be97de447a",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/Aviatrix/9d9b5b34389337d4e43568b4ba2d56be97de447a.png",
      type: "crash",
      provider: "Aviatrix",
      excludeDemoMode: false
    },
    {
      game_id: 2222,
      name: "Book of Gods",
      uuid: "515cbd3d87a3402b8a08f5a452fd64cd",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/BFGames/515cbd3d87a3402b8a08f5a452fd64cd.png",
      type: "slots",
      provider: "BFGames",
      excludeDemoMode: false
    },
    {
      game_id: 2123,
      name: "7 Fruits",
      uuid: "16e052082e546654d9c21cf6c00c9644da9b650e",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/16e052082e546654d9c21cf6c00c9644da9b650e.png",
      type: "slots",
      provider: "Belatra Games",
      excludeDemoMode: false
    },
    {
      game_id: 2506,
      name: "Beer Bonanza",
      uuid: "ac437469dda9d4e0a82059d405540eec85d111d2",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/ac437469dda9d4e0a82059d405540eec85d111d2.png",
      type: "slots",
      provider: "BGaming",
      excludeDemoMode: false
    },
    {
      game_id: 3059,
      name: "777 Deluxe",
      uuid: "515fc4e58e8e4084b46f23d50f355ecb",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/515fc4e58e8e4084b46f23d50f355ecb.png",
      type: "slots",
      provider: "Blueprint",
      excludeDemoMode: false
    },
    {
      game_id: 4134,
      name: "Fire dragon",
      uuid: "1560f2cf9eb84418a45ca610b646fc24",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/FBastards/1560f2cf9eb84418a45ca610b646fc24.png",
      type: "slots",
      provider: "FBastards",
      excludeDemoMode: false
    },
    {
      game_id: 4140,
      name: "Mafia",
      uuid: "925d3b2e85f945d98800e3dacbf0c41b",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/FBastards/925d3b2e85f945d98800e3dacbf0c41b.png",
      type: "slots",
      provider: "FBastards",
      excludeDemoMode: false
    },
    {
      game_id: 127,
      name: "Sizzling 6",
      uuid: "e355fffe8405129a5ef8b6a5e77ff83d0a118de4",
      source: "win2power:0",
      image: "https://r.gamesstatic.com/games/e355fffe8405129a5ef8b6a5e77ff83d0a118de4.jpg",
      type: "slots",
      provider: "Greentube",
      excludeDemoMode: false
    },
    {
      game_id: 124,
      name: "Sizzling Hot Deluxe",
      uuid: "557af416d9831dccc0bd5fdfe47eaa41c60015ad",
      source: "win2power:0",
      image: "https://r.gamesstatic.com/games/557af416d9831dccc0bd5fdfe47eaa41c60015ad.jpg",
      type: "slots",
      provider: "Greentube",
      excludeDemoMode: false
    },
    {
      game_id: 1606,
      name: "Big Bass - Hold & Spinner",
      uuid: "46fd364683164ddbb528466ff23987e1",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/PragmaticPlay/46fd364683164ddbb528466ff23987e1.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    },
    {
      game_id: 1530,
      name: "Big Bass - Keeping it Reel",
      uuid: "d1acf2e3d4bce1feaa8e86c73521b5337322b509",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/PragmaticPlay/d1acf2e3d4bce1feaa8e86c73521b5337322b509.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    },
    {
      game_id: 1288,
      name: "Big Bass Bonanza",
      uuid: "76b3846996214fa09c1f761a0228c032",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/PragmaticPlay/76b3846996214fa09c1f761a0228c032.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    },
    {
      game_id: 1884,
      name: "Big Bass Double Down Deluxe",
      uuid: "bf03ab99344f46b8ab2af03005e722bf",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/PragmaticPlay/bf03ab99344f46b8ab2af03005e722bf.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    },
    {
      game_id: 1382,
      name: "Big Juan",
      uuid: "922337fede347c8bb8e0e273a4f06dc99f04bd9c",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/922337fede347c8bb8e0e273a4f06dc99f04bd9c.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    },
    {
      game_id: 1058,
      name: "The Dog House Megaways",
      uuid: "b3a22c28284e4de69efb358940838cb1",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/PragmaticPlay/b3a22c28284e4de69efb358940838cb1.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    }
  ],
  banners: [
    {
      id: 2,
      name: "Приз за отзывы",
      position: 6,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>VULPIN-розыгрыш среди отзывов! <br>Оставляй свои отзывы в чате или в личном сообщении админу и автоматически участвуй в розыгрыше! Победитель получает бонус - х2 к депозиту на основной счет. <br><br><i>*В акции участвуют все отзывы, отправленные за период с понедельника по понедельник. Один победитель определяется случайным образом во вторник.</i></p>",
      relativeUrl: "review_bonus",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Приз за отзыв комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Приз за отзыв моб.jpeg"
    },
    {
      id: 4,
      name: "Еженедельный розыгрыш",
      position: 7,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>Получи 1000 рублей на основной счет!&nbsp;</p><p>Бонус получает каждый игрок, который за неделю сделал депозитов на сумму более 5000 рублей.&nbsp;</p><p><br></p><p><i>*Сумма ваших депозитов вычисляется за период с понедельника по понедельник, бонус начиcляется по вторникам в виде промокода</i></p>",
      relativeUrl: "1000_rub",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/1000 рублей комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/1000 рубелй моб.jpeg"
    },
    {
      id: 5,
      name: "Шальные ручки",
      position: 5,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>Акция Шальные ручки на VULPIN! Сделай больше всех депозитов за неделю и получи подарок - х2 к депозиту на основной баланс!&nbsp;</p><p><br></p><p><i>*В акции учавствуют все депозиты за период с понедельника по понедельник, один победитель определяется каждый вторник.</i></p>",
      relativeUrl: "lucky_hands",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Шальные ручки комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Шальные ручки моб.jpeg"
    },
    {
      id: 7,
      name: "Приветственный бонус",
      position: 1,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>Получи х2 к первому депозиту на основной счет! Бонус начисляется автоматически сразу после пополнения.&nbsp;<br></p>",
      relativeUrl: "firstdeposit",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Перводеп комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Перводеп моб.jpeg"
    },
    {
      id: 8,
      name: "Кешбек 15%",
      position: 2,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>Получай кешбек 15% к каждому своему пополнению!<br></p><p><i><br></i></p><p><i>*Кешбек начисляется после пополнения: вагер х10, кешаут 3&nbsp;</i></p><p></p>",
      relativeUrl: "cashback_15",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Кешбек 15 комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Кешбек 15 моб.jpeg"
    },
    {
      id: 10,
      name: "Счастливые часы",
      position: 4,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>Супер кешбек в Счастливые часы! Каждый четверг и пятницу лови 20% кешбека к пополнению.&nbsp;</p><p><i><br></i></p><p><i>*Кешбек начисляется после пополнения: вагер х10, кешаут 3</i></p>",
      relativeUrl: "happy_time",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Счастливые часы комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Счастливые часы моб.jpeg"
    },
    {
      id: 11,
      name: "Однорукий бандит",
      position: 3,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        '<p>Принимай участие в чат-игре "Однорукий бандит" и выигрывай бонус - х2 к депозиту на основной счет! Игра регулярно проводится в основном чате, подпишись, чтобы не пропустить!&nbsp;</p>',
      relativeUrl: "banditgame",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Однорукий бандит комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Однорукий бандит моб.jpeg"
    }
  ],
  winnings: {
    changed: "2024-11-13 11:18:09",
    list: [
      {
        gameId: 5772,
        win: 2968
      },
      {
        gameId: 8154,
        win: 2784
      },
      {
        gameId: 3260,
        win: 3525
      },
      {
        gameId: 2776,
        win: 4543
      },
      {
        gameId: 2591,
        win: 1253
      },
      {
        gameId: 4258,
        win: 183
      },
      {
        gameId: 3277,
        win: 2404
      },
      {
        gameId: 2281,
        win: 4531
      },
      {
        gameId: 2586,
        win: 1399
      },
      {
        gameId: 6269,
        win: 4860
      },
      {
        gameId: 994,
        win: 1280
      },
      {
        gameId: 3794,
        win: 796
      },
      {
        gameId: 2255,
        win: 427
      },
      {
        gameId: 1699,
        win: 2110
      },
      {
        gameId: 6238,
        win: 1330
      },
      {
        gameId: 5476,
        win: 3836
      },
      {
        gameId: 805,
        win: 2022
      },
      {
        gameId: 8137,
        win: 4905
      },
      {
        gameId: 3887,
        win: 1420
      },
      {
        gameId: 7475,
        win: 673
      },
      {
        gameId: 142,
        win: 4785
      },
      {
        gameId: 3358,
        win: 2054
      },
      {
        gameId: 6837,
        win: 3163
      },
      {
        gameId: 6911,
        win: 1827
      },
      {
        gameId: 5611,
        win: 3872
      }
    ]
  }
};

export const testBalance = {
  RUB: {
    info: {
      decimal: 0,
      type: "fiat",
      current: false
    },
    balances: {
      main: 0,
      payout: 0,
      bonus: 500.1111111111111,
      depositWager: 0,
      bonusWager: 500.9999999999999
    }
  },
  EUR: {
    info: {
      decimal: 2,
      type: "fiat",
      current: false
    },
    balances: {
      main: 0,
      payout: 0,
      bonus: 0,
      depositWager: 0,
      bonusWager: 0
    }
  },
  UZS: {
    info: {
      decimal: 0,
      type: "fiat",
      current: false
    },
    balances: {
      main: 0,
      payout: 0,
      bonus: 0,
      depositWager: 0,
      bonusWager: 0
    }
  },
  USDT: {
    info: {
      decimal: 2,
      type: "crypto",
      current: true
    },
    balances: {
      main: 0,
      payout: 0,
      bonus: 0,
      depositWager: 0,
      bonusWager: 0
    }
  }
};

export const testPaymentInfo = {
  currency: "RUB",
  userData: "card",
  bankList: {
    phone: ["sber", "alpha", "tbank", "vtb", "sovcom", "rossel", "yasbp"]
  },
  minAmount: 5000,
  maxAmount: 100000,
  taxPercent: 5,
  taxFixed: 0,
  extraTaxAmount: 10000,
  extraTaxPercent: 10
};

export const testDeposit = {
  currency: "RUB",
  minAmount: 101,
  maxAmount: 50002
};

export const testBonus = {
  currency: "RUB",
  registration: {
    toDeposit: true,
    values: {
      amount: 500,
      wager: 2,
      cashout: 3
    }
  },
  firstDeposit: {
    toDeposit: true,
    values: {
      amount: 5001,
      wager: 2,
      cashout: 3,
      minDeposit: 301
    }
  },
  cashback: {
    toDeposit: false,
    values: {
      amount: 5000,
      wager: 10,
      cashout: 3,
      percent: 15,
      minDeposit: 300
    }
  }
};

export const testJackpots = {
  list: {
    currency: "EUR",
    grand: {
      amount: 200,
      minBet: 0,
      minDeposit: 0,
      depositDays: 0,
      drop: true
    },
    major: {
      amount: 0,
      minBet: 0,
      minDeposit: 0,
      depositDays: 0,
      drop: false
    },
    mini: {
      amount: 0,
      minBet: 0,
      minDeposit: 0,
      depositDays: 0,
      drop: false
    }
  }
};
