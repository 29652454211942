import React from "react";
import "./bonus-menu.scss";
import { useTranslation } from "react-i18next";
import CusButton from "../../../mui/custom_button/CusButton";
import imgPresent from "./present.png";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";

export default function BonusBannerMenu() {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch("/");

  const onGo = () => {
    navigate("/promotions");
  };

  return (
    <div className="bonus-menu">
      <h2>{t("Play casino games and get bonuses!")}</h2>
      <CusButton color="blue" text={"Go"} onClick={onGo} />
      <img src={imgPresent} />
      <div className="bonus-menu__gradient" />
    </div>
  );
}
